import { apolloMutate, gql } from 'utils/apollo'
import { isEmpty } from 'utils/isEmpty'

const SET_USER_ID = 'ASSESSMENTS/SET_USER_ID'
const SET_USER_DATA = 'ASSESSMENTS/SET_USER_DATA'
const SET_UPDATE_USER_DATA = 'ASSESSMENTS/SET_UPDATE_USER_DATA'

const SET_ASSESSMENT_NAMES = 'SET_ASSESSMENT_NAMES'
const SET_ASSESSMENT_NAME = 'SET_ASSESSMENT_NAME'
const SET_FILTER_DATES = 'SET_FILTER_DATES'
const AUTO_SAVE = 'ASSESSMENTS/AUTO_SAVE'
const SET_DEMOGRAPHIC_INFO = 'ASSESSMENTS/SET_DEMOGRAPHIC_INFO'
const SET_ASSESSMENT_INITIAL_STATE = 'ASSESSMENTS/SET_ASSESSMENT_INITIAL_STATE'
const SET_PRODUCT_EVENTS = 'ASSESSMENTS/SET_PRODUCT_EVENTS'
const SET_ASSESSMENT_SESSIONS = 'ASSESSMENTS/SET_SESSIONS'
const SET_PRODUCT_ASSESSMENTS = 'ASSESSMENTS/PRODUCT_ASSESSMENTS'
const SET_OPEN_SELECT = 'ASSESSMENTS/SET_OPEN_SELECT'
const SET_PRODUCTS = 'ASSESSMENTS/SET_PRODUCTS'

// filter
const SET_FILTER_USERID = 'ASSESSMENTS/FILTER/SET_USERID'
const SET_FILTER_STATUS = 'ASSESSMENTS/FILTER/SET_PRODUCTS'
const SET_FILTER_CREATED_AT = 'ASSESSMENTS/FILTER/SET_CREATED_AT'
const SET_FILTER_UPDATED_AT = 'ASSESSMENTS/FILTER/SET_UPDATED_AT'
const SET_FILTER_PRODUCT_EVENT_ID = 'ASSESSMENTS/FILTER/PRODUCT_EVENT_ID'
const SAVE_CURRENT_FILTER = 'ASSESSMENTS/FILTER/SAVE_CURRENT_FILTER'
const LOAD_SAVED_FILTER = 'ASSESSMENTS/FILTER/LOAD_SAVED_FILTER'
const RESET_FILTER = 'ASSESSMENTS/FILTER/RESET'

const UPDATE_SESSION = gql`
  mutation UpdateSession($session: UpdateSessionInput!) {
    updateSession(session: $session) {
      id
    }
  }
`
const initialState = {
  userId: null,
  fullName: null,
  email: null,
  isInvitationMode: null,
  userData: [],
  assessmentNames: [],
  selectedAssessmentName: null,
  filterDates: [null, null],
  productEvents: [],
  products: [],
  productAssessments: [],
  sessions: [],
  openSelect: false,
  filter: {},
  savedFilter: {},
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_ID:
      return {
        ...state,
        ...action.payload,
      }
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      }
    case SET_UPDATE_USER_DATA:
      return {
        ...state,
        userData: state.userData.map((user) => {
          if (user.id === action.payload.id) {
            return { ...user, ...action.payload }
          } else {
            return user
          }
        }),
      }
    case SET_ASSESSMENT_NAME:
      return {
        ...state,
        selectedAssessmentName: action.payload,
      }
    case SET_ASSESSMENT_NAMES:
      return {
        ...state,
        assessmentNames: action.payload,
      }

    case SET_FILTER_DATES:
      return {
        ...state,
        filterDates: [...action.payload],
      }
    case SET_PRODUCT_EVENTS:
      return {
        ...state,
        productEvents: action.payload,
      }
    case SET_ASSESSMENT_INITIAL_STATE:
      return {
        ...initialState,
      }

    case SET_DEMOGRAPHIC_INFO:
      return {
        ...state,
        ...action.payload,
      }

    case SET_ASSESSMENT_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      }

    case SET_OPEN_SELECT:
      return {
        ...state,
        openSelect: action.payload,
      }
    case SET_PRODUCT_ASSESSMENTS:
      return {
        ...state,
        productAssessments: action.payload,
      }
    case SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      }

    // filters go under here
    case SET_FILTER_USERID:
      return {
        ...state,
        filter: {
          ...state.filter,
          userId: action.payload,
        },
      }
    case SET_FILTER_STATUS:
      return {
        ...state,
        filter: {
          ...state.filter,
          status: action.payload,
        },
      }
    case SET_FILTER_CREATED_AT:
      return {
        ...state,
        filter: {
          ...state.filter,
          createdAt: action.payload,
        },
      }

    case SET_FILTER_UPDATED_AT:
      return {
        ...state,
        filter: {
          ...state.filter,
          updatedAt: action.payload,
        },
      }

    case SET_FILTER_PRODUCT_EVENT_ID:
      return {
        ...state,
        filter: {
          ...state.filter,
          productEventId: action.payload,
        },
      }

    case SAVE_CURRENT_FILTER:
      return {
        ...state,
        savedFilter: isEmpty(state.filter) ? {} : { ...state.filter },
      }

    case RESET_FILTER:
      return {
        ...state,
        filter: {},
        savedFilter: {},
      }

    case LOAD_SAVED_FILTER:
      return {
        ...state,
        filter: isEmpty(state.savedFilter) ? {} : { ...state.savedFilter },
      }

    default:
      return state
  }
}

export function setAllProductAssessments(payload) {
  return {
    type: SET_PRODUCT_ASSESSMENTS,
    payload,
  }
}

export function setUser(payload) {
  return {
    type: SET_USER_ID,
    payload,
  }
}

export function setAssessmentName(payload) {
  return {
    type: SET_ASSESSMENT_NAME,
    payload,
  }
}

export function setAssessmentNames(payload) {
  return {
    type: SET_ASSESSMENT_NAMES,
    payload,
  }
}

export function setUserData(payload) {
  return {
    type: SET_USER_DATA,
    payload,
  }
}

export function setUpdateUserData(payload) {
  return {
    type: SET_UPDATE_USER_DATA,
    payload,
  }
}

export function setFilterUserId(payload) {
  return {
    type: SET_FILTER_USERID,
    payload,
  }
}

export function setFilterDates(payload) {
  return {
    type: SET_FILTER_DATES,
    payload,
  }
}

export function setProductEvents(payload) {
  return {
    type: SET_PRODUCT_EVENTS,
    payload,
  }
}

// filters
export function setAssessmentFilterStatus(payload) {
  return {
    type: SET_FILTER_STATUS,
    payload,
  }
}
export function setAssessmentFilterCreatedAt(payload) {
  return {
    type: SET_FILTER_CREATED_AT,
    payload,
  }
}
export function setAssessmentFilterUpdatedAt(payload) {
  return {
    type: SET_FILTER_UPDATED_AT,
    payload,
  }
}

export function setAssessmentFilterProductEventId(payload) {
  return {
    type: SET_FILTER_PRODUCT_EVENT_ID,
    payload,
  }
}

/**
 * This function is called when user logs out with the purpose of HIPAA in mind
 */
export function setAssessmentsIntialState() {
  return {
    type: SET_ASSESSMENT_INITIAL_STATE,
  }
}

export function autoSaveAssessment(payload) {
  return async (dispatch) => {
    await apolloMutate({
      mutation: UPDATE_SESSION,
      variables: { ...payload },
    })
    await dispatch(update(AUTO_SAVE))
  }
}

function update(type) {
  return {
    type,
  }
}

export function setDemographicInfo(payload) {
  return {
    type: SET_DEMOGRAPHIC_INFO,
    payload,
  }
}

export function setAssessmentSessions(payload) {
  return {
    type: SET_ASSESSMENT_SESSIONS,
    payload,
  }
}

export function setProducts(payload) {
  return {
    type: SET_PRODUCTS,
    payload,
  }
}

export function setOpenSelect(payload) {
  return {
    type: SET_OPEN_SELECT,
    payload,
  }
}

export function saveCurrentFilter() {
  return {
    type: SAVE_CURRENT_FILTER,
  }
}

export function loadSavedFilter() {
  return {
    type: LOAD_SAVED_FILTER,
  }
}

export function resetFilter() {
  return {
    type: RESET_FILTER,
  }
}
