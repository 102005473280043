/**
 * This card is for clients only..
 */
import React from 'react'
import { DashboardCard, BulletLink } from './DashboardCard2'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { setProductEvents } from 'store/modules/assessments'

const GET_PRODUCT_EVENTS = gql`
  query GetProductEventsForNewActionCard($filter: FilterProductEventsInput) {
    getProductEvents(filter: $filter) {
      id
      metadata
      productId
    }
  }
`

export default function NewActionCard({ ...gridProps }) {
  const dispatch = useDispatch()
  const { sentAssessments = [] } = useSelector((state) => state.sessions)
  const productEvents = useSelector((state) => state.assessments.productEvents)

  // get all product events
  useQuery(GET_PRODUCT_EVENTS, {
    fetchPolicy: 'cache-and-network',
    skip: !!productEvents.length,
    onCompleted: (data) => {
      dispatch(setProductEvents(get(data, 'getProductEvents', [])))
    },
  })

  return (
    <DashboardCard
      {...gridProps}
      dataTestLabel="dashboard-card-action-client"
      id="new-actions-card"
      title="You Have New Action Items"
    >
      {sentAssessments.map((item) => {
        const productEvent = productEvents.find(({ id }) => id === item.productEventId)
        const assessmentName = get(productEvent, 'metadata.assessmentType', [])
        return (
          <BulletLink internalURI={`/assessments/view/${item.user?.id}/${item.id}`}>
            {' '}
            to complete the {assessmentName} assigned by your provider.
          </BulletLink>
        )
      })}
    </DashboardCard>
  )
}
