import React from 'react'
import { Button, Typography } from 'components'

export default function PurchaseCardButton({ children, onClick, ...props }) {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      sx={{ minWidth: '69%', margin: '1rem auto', backgroundColor: 'white', color: 'var(--black)' }}
      color="unyteSecondary"
      size="large"
      {...props}
    >
      <Typography variant="body2" noWrap>
        {children}
      </Typography>
    </Button>
  )
}
