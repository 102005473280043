import React, { useContext } from 'react'
import {
  Grid,
  Stack,
  Typography,
  Card,
  Switch,
  Alert,
  Button,
  Link,
  Box,
  Avatar,
} from '@mui/material'

import { ProviderProfileContext } from '../MyProfile'

import { useSelector } from 'react-redux'
import ClientConnectionsAvatar from 'components/avatar/ClientConnectionAvatar'
import ClientConnectionIcon from 'components/avatar/client_connections_icon.svg'
import { alpha, styled } from '@mui/material/styles'

const PinkSwitch = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.unyteSecondary.main,
    '&:hover': {
      backgroundColor: alpha(theme.palette.unyteSecondary.main, theme.palette.action.hoverOpacity),
    },
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.unyteSecondary.main,
  },
}))

export default function ClientConnectionsBanner({
  providerProfileInfo,
  productPreferences,
  setOpenUnavailableModal,
  handleFirstClick,
  setOpenDialog,
}) {
  const { hasSspProducts, hasFocusProducts, hasRrpProducts } = useSelector((state) => state.ff)
  const { form, setWizardState, setOpenWizard, handleSave, handleCheckBox } = useContext(
    ProviderProfileContext
  )

  const isFirstClicked = !!providerProfileInfo?.gettingStartedClickedAt
  const isSspCertificationCompleted = !!productPreferences?.sspCertification?.completedAt
  const isFocusCertificationCompleted = !!productPreferences?.focusCertification?.completedAt

  const isClientConnectionsDisabled = !(
    (isSspCertificationCompleted || isFocusCertificationCompleted) &&
    (hasSspProducts || hasFocusProducts || hasRrpProducts)
  )

  const onSwitch = (event) => {
    // if you don't have subscription, show modal
    if (!hasSspProducts && !hasFocusProducts) {
      setOpenUnavailableModal(true)
      return
    }

    // if we are unselecting this, we want to save the results
    if (!event?.target?.checked) {
      handleCheckBox('openToNewClients')(event)
      handleSave({ openToNewClients: false })
    } else {
      setWizardState()
      setOpenWizard(event?.target?.checked)
    }
  }

  const onFirstClick = () => {
    setOpenWizard(true)
    setWizardState()
    handleFirstClick()
  }

  // old wizard logic
  const onLearnMore = () => {
    setOpenDialog(true)
  }

  return (
    <Grid item alignItems="stretch" className="w-full">
      <Card
        sx={{
          // card css
          border: '1px solid rgba(0, 0, 0, 0.12)',
          borderRadius: '4px',
          boxShadow: 'none',
          // background css
          backgroundColor: '#edf6f2',
        }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent={{ lg: 'space-around', md: 'space-between' }}
          spacing={2}
          alignItems="center"
        >
          <Box display="flex" flexDirection="row">
            <Box
              sx={{
                margin: 'auto',
                paddingRight: '20px',
                paddingLeft: { md: '20px', lg: 0 },
              }}
            >
              <Avatar
                variant="square"
                src={ClientConnectionIcon}
                alt="client-connection-icon"
                sx={{
                  width: '80px',
                  height: '80px',
                  objectFit: 'cover',
                }}
              />
            </Box>
            <Stack
              direction="column"
              spacing={1}
              sx={{ minWidth: '26rem', width: { xs: '100%', sm: '50%' } }}
            >
              <Box
                sx={{
                  height: '50px',
                  width: '320px',
                  backgroundColor: 'transparent',
                  paddingTop: '0.5rem',
                }}
              >
                <ClientConnectionsAvatar marginRight="auto" height="calc(50px + 0.5rem)" />
              </Box>
              <Typography variant="body1" py={2} noWrap>
                Connecting Clients with Valued Providers{' '}
                <Link
                  sx={{ color: '#ce0093', textDecoration: 'underline', cursor: 'pointer' }}
                  onClick={onLearnMore}
                >
                  Learn More
                </Link>
              </Typography>
            </Stack>
          </Box>

          {!isClientConnectionsDisabled && (
            <Stack direction="column" p={2}>
              {!isFirstClicked && (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  spacing={3}
                  sx={{ height: '100%', pr: 3 }}
                  alignItems="center"
                >
                  <Typography variant="body1" noWrap>
                    Connect me with new clients!
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={onFirstClick}
                    color="primary"
                    className="p-2 w-28"
                  >
                    Get Started
                  </Button>
                </Stack>
              )}
              {isFirstClicked && (
                <Stack direction="column" alignItems="center" sx={{ height: '100%' }} pr={2}>
                  <Typography
                    variant="body1"
                    textAlign="left"
                    noWrap
                    height="calc(0.5rem + 50px)"
                    alignContent="center"
                  >
                    {form.openToNewClients
                      ? 'We are looking for potential new clients for you!'
                      : 'Connect me to new potential clients'}
                  </Typography>
                  <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
                    <Typography>OFF</Typography>
                    <PinkSwitch
                      checked={form.openToNewClients}
                      onChange={onSwitch}
                      disabled={isClientConnectionsDisabled}
                    />
                    <Typography>ON</Typography>
                  </Stack>
                </Stack>
              )}
            </Stack>
          )}

          {isClientConnectionsDisabled && (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ backgroundColor: '#FEFAED', width: '520px' }}
            >
              Sorry! Client Connections requires you to be a certified provider with an active
              subscription.
            </Alert>
          )}
        </Stack>
      </Card>
    </Grid>
  )
}
