import React, { createContext, useState, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { useSnackbar } from 'notistack'

import { Button, FormHelperText, Grid } from 'components'
import { changePassword } from 'store/modules/auth'
import ConfirmPasswordInput from '../../components/input/ConfirmPasswordInput'
import { useNavigate } from 'react-router'
import { ShowProviderProfileWrapper } from './ProfessionalCredentials'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

export const ChangePasswordContext = createContext({ value: {}, setValue: () => {} })

export default function EditContact() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState()
  const [passwords, setPasswords] = useState({
    newPassword: '',
    showNewPassword: false,
    isNewPasswordValid: false,
    confirmPassword: '',
    showConfirmPassword: false,
    isConfirmPasswordValid: false,
    currentPassword: '',
    showCurrentPassword: false,
    isCurrentPasswordValid: false,
  })
  const value = useMemo(() => ({ passwords, setPasswords }), [passwords])

  const onSubmit = async (event) => {
    event.preventDefault()
    setLoading(true)
    try {
      await dispatch(
        changePassword(
          passwords.currentPassword,
          passwords.newPassword,
          passwords.newPasswordConfirmed
        )
      )
      await enqueueSnackbar('Password was updated successfully', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
      navigate(-1)
      setErrors()
    } catch (error) {
      console.error(error)
      setErrors(error.message)
    } finally {
      await setPasswords({
        newPassword: '',
        showNewPassword: false,
        isNewPasswordValid: false,
        confirmPassword: '',
        showConfirmPassword: false,
        isConfirmPasswordValid: false,
        currentPassword: '',
        showCurrentPassword: false,
        isCurrentPasswordValid: false,
      })
      setLoading(false)
    }
  }

  // TODO
  const isValid =
    passwords.isNewPasswordValid &&
    passwords.isConfirmPasswordValid &&
    passwords.isCurrentPasswordValid

  // we should useContext here..
  return (
    <ShowProviderProfileWrapper>
      <Grid container spacing={2} className="px-5">
        <Grid item md={6}>
          <form className="py-4" onSubmit={onSubmit}>
            {errors && (
              <FormHelperText className="mb-2" error>
                {errors}
              </FormHelperText>
            )}
            <ChangePasswordContext.Provider value={value}>
              <ConfirmPasswordInput />
            </ChangePasswordContext.Provider>
            <Grid container justifyContent="flex-end">
              <Button color="primary" disabled={!isValid} loading={loading} type="submit">
                Change Password
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </ShowProviderProfileWrapper>
  )
}
