import React from 'react'
import Avatar from '../components/Avatar'
import SortLabel from '../components/table/SortLabel'
import NameCol from '../components/table/NameCol'
import formatSeconds from 'utils/formatSeconds'
import { Badge, Chip, Typography, Stack, Tooltip } from '@mui/material'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants/formats'
import { get } from 'lodash'
import { useOutletContext } from 'react-router'
import { useSelector } from 'react-redux'

export default () => {
  const { products: allProducts, activeIds = [] } = useOutletContext()
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)

  const showLastSession = (data) => {
    const lastSession = get(data, 'productPreferences.lastSession')
    if (lastSession) {
      const {
        startedAt,
        totalTime,
        position: { start, end },
      } = lastSession

      const startedAtDate =
        new Date(startedAt).getFullYear() === new Date().getFullYear()
          ? format(new Date(startedAt), DATE_FORMATS.dateAndTimeNoYear)
          : format(new Date(startedAt), DATE_FORMATS.dateAndTime)

      return (
        <>
          <Typography variant="body2">{startedAtDate}</Typography>
          <Typography variant="body2">
            <b>Total {formatSeconds(totalTime)}</b> ({start} - {end})
          </Typography>
        </>
      )
    }
    return null
  }

  const showLastProgram = (data) => {
    const lastSession = get(data, 'productPreferences.lastSession')
    if (lastSession) {
      const { productName, productEventTitle } = lastSession

      return (
        <>
          <Typography variant="body2">{productName}</Typography>
          <Typography variant="body2">{productEventTitle}</Typography>
        </>
      )
    }
    return null
  }

  // we need to refactor showPrograms out of function so that it can update when data is updated
  const showPrograms = (data) => {
    const isInPerson = !get(data, 'email', false)

    // has products
    const userProducts = get(data, 'seats', [])?.map(
      ({ productId, status }) =>
        status === 'active' && allProducts?.find(({ id }) => productId === id)
    )
    const sspProducts = userProducts?.filter((product) => product?.category === 'ssp')
    const rrpProducts = userProducts?.filter((product) => product?.category === 'rrp')
    const focusProducts = userProducts?.filter((product) => product?.category === 'focus')

    // has Slots
    const slots = get(data, 'slots', []).filter(({ status }) =>
      ['used', 'assigned'].includes(status)
    )
    const hasAssignedFocusSlot = slots.find(
      ({ category, status }) => category === 'focus' && status === 'assigned'
    )
    const hasUsedFocusSlot = slots.find(
      ({ category, status }) => category === 'focus' && status === 'used'
    )
    const hasFocusSlot = hasAssignedFocusSlot || hasUsedFocusSlot

    const hasAssignedSspSlot = slots.find(
      ({ category, status }) => category === 'ssp' && status === 'assigned'
    )
    const hasUsedSspSlot = slots.find(
      ({ category, status }) => category === 'ssp' && status === 'used'
    )
    const hasSspSlot = hasAssignedSspSlot || hasUsedSspSlot

    const hasAssignedRrpSlot = slots.find(
      ({ category, status }) => category === 'rrp' && status === 'assigned'
    )
    const hasUsedRrpSlot = slots.find(
      ({ category, status }) => category === 'rrp' && status === 'used'
    )
    const hasRrpSlot = hasAssignedRrpSlot || hasUsedRrpSlot

    const userHasSessionForProduct = (productCategory) => {
      if (productCategory === get(data, 'productPreferences.lastSession.productCategory')) {
        return true
      }

      // check for session if last session is of other category
      const productLastSessionKey = `${productCategory}LastSession`
      return (
        productCategory === get(data, `productPreferences.${productLastSessionKey}.productCategory`)
      )
    }

    const getBadgeContent = (
      products,
      hasUsedSlots,
      featureFlag,
      hasAssignedSlot,
      productCategory
    ) => {
      if (featureFlag) {
        if (hasUsedSlots && !isInPerson) return products.length
        if (hasAssignedSlot) {
          if (userHasSessionForProduct(productCategory)) {
            return products.length
          }
          return 'Not Started'
        }
        return null
      }
      return products.length
    }

    const getTooltipTitle = (products, featureFlag) => {
      if (isInPerson && featureFlag) {
        return 'In-Person listening'
      } else if (products.length) {
        return products?.map(({ name, productId }, index) => (
          <div key={`product${productId}-${index}`}>{name}</div>
        ))
      } else {
        return 'No programs assigned'
      }
    }

    const sspBadgeContent = getBadgeContent(
      sspProducts,
      hasUsedSspSlot,
      showNewSubscriptionPlan,
      hasAssignedSspSlot,
      'ssp'
    )
    const focusBadgeContent = getBadgeContent(
      focusProducts,
      hasUsedFocusSlot,
      showNewSubscriptionPlan,
      hasAssignedFocusSlot,
      'focus'
    )
    const rrpBadgeContent = getBadgeContent(
      rrpProducts,
      hasUsedRrpSlot,
      showNewSubscriptionPlan,
      hasAssignedRrpSlot,
      'rrp'
    )

    const sspTooltipTitle = getTooltipTitle(sspProducts, showNewSubscriptionPlan)
    const rrpTooltipTitle = getTooltipTitle(rrpProducts, showNewSubscriptionPlan)
    const focusTooltipTitle = getTooltipTitle(focusProducts, showNewSubscriptionPlan)

    return (
      <Stack direction={{ lg: 'row', md: 'column', sm: 'column' }} spacing={5} mr={1}>
        {(!!(sspProducts?.length && !showNewSubscriptionPlan) || hasSspSlot) && (
          <Tooltip title={sspTooltipTitle} placement="top">
            <Badge
              badgeContent={sspBadgeContent}
              color={hasAssignedSspSlot ? 'greyedOut' : 'primary'}
              sx={{
                '& .MuiBadge-badge': { fontSize: 10, padding: '5px' },
              }}
            >
              <Chip
                label={
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    SSP
                  </Typography>
                }
                sx={{
                  backgroundColor:
                    sspBadgeContent === 'Not Started' ? '#efefef' : 'rgb(110, 231, 183)',
                  width: '4rem',
                }}
              />
            </Badge>
          </Tooltip>
        )}
        {(!!(focusProducts?.length && !showNewSubscriptionPlan) || hasFocusSlot) && (
          <Tooltip title={focusTooltipTitle} placement="top">
            <Badge
              badgeContent={focusBadgeContent}
              color={hasAssignedFocusSlot ? 'greyedOut' : 'primary'}
              sx={{
                '& .MuiBadge-badge': { fontSize: 10, padding: '5px' },
              }}
            >
              <Chip
                label={
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    ILS
                  </Typography>
                }
                sx={{
                  backgroundColor:
                    focusBadgeContent === 'Not Started' ? '#efefef' : 'rgb(147, 197, 253)',
                  width: '4rem',
                }}
              />
            </Badge>
          </Tooltip>
        )}
        {(!!rrpProducts?.length || hasRrpSlot) && (
          <Tooltip title={rrpTooltipTitle} placement="top">
            <Badge
              badgeContent={rrpBadgeContent}
              color={hasAssignedRrpSlot ? 'greyedOut' : 'primary'}
              sx={{
                '& .MuiBadge-badge': { fontSize: 10, padding: '5px' },
              }}
            >
              <Chip
                label={
                  <Typography variant="body2" sx={{ fontWeight: '500' }}>
                    RRP
                  </Typography>
                }
                sx={{
                  backgroundColor:
                    rrpBadgeContent === 'Not Started' ? '#efefef' : 'rgb(110, 231, 183)',
                  width: '4rem',
                }}
              />
            </Badge>
          </Tooltip>
        )}
      </Stack>
    )
  }

  return [
    {
      width: '25%',
      header: ({ onSort, sort }) => <NameCol onSort={onSort} sort={sort} />,
      body: ({ data, isSelected }) => (
        <Avatar
          {...data}
          activeClientsFilter={activeIds?.includes(data.id)}
          isSelected={isSelected}
        />
      ),
    },
    {
      width: '15%',
      header: () => 'Programs',
      body: ({ data }) => showPrograms(data),
    },
    {
      width: '20%',
      header: ({ onSort, sort }) => (
        <SortLabel
          sort={sort}
          title="Last Program"
          sortKey="productPreferences.lastSession.productName"
          onChange={onSort}
        />
      ),
      body: ({ data }) => showLastProgram(data),
    },
    {
      width: '20%',
      header: ({ onSort, sort }) => (
        <SortLabel
          sort={sort}
          title="Last Session"
          sortKey="productPreferences.lastSession.startedAt"
          onChange={onSort}
        />
      ),
      body: ({ data }) => showLastSession(data),
    },
    {
      width: '10%',
      header: ({ onSort, sort }) => (
        <SortLabel sort={sort} title="Last Signed In" sortKey="lastLoginAt" onChange={onSort} />
      ),
      body: ({ data }) =>
        data.lastLoginAt ? (
          <>
            <Typography variant="body2">
              {format(new Date(data.lastLoginAt), DATE_FORMATS.date)}
            </Typography>
            <Typography variant="body2">
              {format(new Date(data.lastLoginAt), DATE_FORMATS.time)}
            </Typography>
          </>
        ) : null,
    },
    {
      width: '10%',
      header: ({ onSort, sort }) => (
        <SortLabel sort={sort} title="Created" sortKey="createdAt" onChange={onSort} />
      ),
      body: ({ data }) => (
        <>
          <Typography variant="body2">
            {format(new Date(data.createdAt), DATE_FORMATS.date)}
          </Typography>
          <Typography variant="body2">
            {format(new Date(data.createdAt), DATE_FORMATS.time)}
          </Typography>
        </>
      ),
    },
  ].filter(Boolean)
}
