import React, { useEffect, useContext, useState } from 'react'
import { ChangePasswordContext } from '../../views/account/ChangePassword'
import Mui5PasswordInput from './Mui5PasswordInput'

export default function ConfirmPasswordInput() {
  // useContext
  const { passwords, setPasswords } = useContext(ChangePasswordContext)
  const [error, setError] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  // destructure passwords
  const {
    currentPassword,
    showCurrentPassword,
    newPassword,
    showNewPassword,
    confirmPassword,
    showConfirmPassword,
  } = passwords

  const setValues = (label) => (value) => {
    setPasswords({ ...passwords, [label]: value })
  }
  const toggle = (label) => () => setPasswords({ ...passwords, [label]: !passwords[label] })

  useEffect(() => {
    if (currentPassword && newPassword && newPassword === currentPassword) {
      setError({
        ...error,
        newPassword: 'New password must be distinct from current password',
      })
    } else if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      setError({
        ...error,
        confirmPassword: 'New passwords must match',
      })
    } else {
      setError({ currentPassword: '', newPassword: '', confirmPassword: '' })
    }

    // eslint-disable-next-line
  }, [currentPassword, newPassword, confirmPassword])

  return (
    <>
      <Mui5PasswordInput
        label="Current Password"
        values={currentPassword}
        setValues={setValues('currentPassword')}
        showPassword={showCurrentPassword}
        setShowPassword={toggle('showCurrentPassword')}
        overrideError={error.currentPassword}
        setValidity={setValues('isCurrentPasswordValid')}
      />
      <Mui5PasswordInput
        label="New Password"
        values={newPassword}
        setValues={setValues('newPassword')}
        showPassword={showNewPassword}
        setShowPassword={toggle('showNewPassword')}
        overrideError={error.newPassword}
        setValidity={setValues('isNewPasswordValid')}
      />
      <Mui5PasswordInput
        label="Confirm Password"
        values={confirmPassword}
        setValues={setValues('confirmPassword')}
        showPassword={showConfirmPassword}
        setShowPassword={toggle('showConfirmPassword')}
        overrideError={error.confirmPassword}
        setValidity={setValues('isConfirmPasswordValid')}
      />
    </>
  )
}
