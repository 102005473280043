import React from 'react'

import { includesSome } from 'utils/includes'
import ROLES from 'utils/constants/roles'
import FILES from 'utils/constants/files'
import ResourceGrid from 'components/ResourceGrid'
import { Grid, Typography } from '@mui/material'

const ALLOWED_ROLES = [...ROLES.PROVIDER_ROLES]
function Tutorial({ roles, gridProps }) {
  const cards = FILES.providerDashboard
  if (!includesSome(roles, ALLOWED_ROLES)) {
    return null
  }

  return (
    <Grid data-test="dashboard-card-tutorial" item {...gridProps}>
      <Typography variant="h4" gutterBottom className="pt-5">
        Tutorial Basics
      </Typography>

      <ResourceGrid gridProps={gridProps} cards={cards} />
    </Grid>
  )
}

export default Tutorial
