import React from 'react'
import { Button, MenuItem, Stack, TextField, Typography } from 'components'

export default function DeclineMatch({
  titleText,
  rejectionReason,
  handleRejectionReasonChange,
  handleOtherRejectionReasonChange,
  cancelReject,
  rejectConnecteeSubmission,
  isDisabledDeclineButton,
  reasons,
  cancelButtonText,
  submitButtonText,
}) {
  return (
    <Stack spacing={2} padding={2} direction="column">
      <Typography variant="h5">{titleText}</Typography>
      <TextField
        select
        className="w-full"
        id="reject-connectee-select-label"
        value={rejectionReason || ''}
        onChange={handleRejectionReasonChange}
        variant="standard"
        label="Please choose a reason"
        SelectProps={{
          renderValue: (selectedValue) => selectedValue,
        }}
      >
        {reasons.map(({ key, additionalInfo }) => (
          <MenuItem key={key} value={key}>
            <Typography fontWeight={500}>{key}:</Typography>
            &nbsp;
            <Typography color="textSecondary">{additionalInfo}</Typography>
          </MenuItem>
        ))}
      </TextField>
      <Stack spacing={2} flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        <TextField
          onChange={handleOtherRejectionReasonChange}
          label={rejectionReason === 'Other' ? 'Other Reason' : 'Additional info'}
        />
      </Stack>
      <Stack spacing={2} flexDirection="row" justifyContent="space-between" alignItems="flex-end">
        <Button variant="contained" color="secondary" onClick={cancelReject}>
          {cancelButtonText}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={rejectConnecteeSubmission}
          disabled={isDisabledDeclineButton}
        >
          {submitButtonText}
        </Button>
      </Stack>
    </Stack>
  )
}
