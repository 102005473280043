const SENT_TO_PROVIDER_DECLINE_REASONS = [
  {
    key: 'Fully Booked or No Availability',
    additionalInfo: 'We have no availability to take on this client at this time.',
  },
  {
    key: "Doesn't Meet our Service Offerings",
    additionalInfo: "The client's needs do not align with our service offerings.",
  },
  {
    key: 'Lack of Specialization',
    additionalInfo: "We lack the necessary expertise to address the client's specific needs.",
  },
  {
    key: 'Geographic Incompatibility',
    additionalInfo: "The client's location is too far for our practice to provide services.",
  },
  {
    key: 'Resource Limitations',
    additionalInfo: "We don't have the staff or tools needed to meet the client's requirements.",
  },
  {
    key: 'Incomplete Client Information',
    additionalInfo: 'The information provided by the client is insufficient to take on the client.',
  },
  {
    key: 'Other',
    additionalInfo: 'I have other reasons to reject this client',
  },
]

// NOTE: same as SENT_TO_PROVIDER but with "Client Unresponsive" before "Other""
const ACCEPTED_DECLINE_REASONS = [
  {
    key: 'Fully Booked or No Availability',
    additionalInfo: 'We have no availability to take on this client at this time.',
  },
  {
    key: "Doesn't Meet our Service Offerings",
    additionalInfo: "The client's needs do not align with our service offerings.",
  },
  {
    key: 'Lack of Specialization',
    additionalInfo: "We lack the necessary expertise to address the client's specific needs.",
  },
  {
    key: 'Geographic Incompatibility',
    additionalInfo: "The client's location is too far for our practice to provide services.",
  },
  {
    key: 'Resource Limitations',
    additionalInfo: "We don't have the staff or tools needed to meet the client's requirements.",
  },
  {
    key: 'Incomplete Client Information',
    additionalInfo: 'The information provided by the client is insufficient to take on the client.',
  },
  {
    key: 'Client Unresponsive',
    additionalInfo: 'The client is not responding to emails or calls.',
  },
  {
    key: 'Other',
    additionalInfo: 'I have other reasons to reject this client',
  },
]

const ENGAGED_DECLINE_REASONS = [
  {
    key: 'Incompatible Needs',
    additionalInfo: "The client's specific needs do not align with our services.",
  },
  {
    key: 'Therapeutic Mismatch',
    additionalInfo:
      "Our therapeutic approach or expertise is not suited to the client's situation.",
  },
  {
    key: 'Ethical or Safety Concerns',
    additionalInfo: 'We have identified ethical or safety concerns that would compromise therapy.',
  },
  {
    key: 'Personality Mismatch',
    additionalInfo:
      "Our therapeutic style does not align with the client's personality or preferences.",
  },
  {
    key: 'Existing Relationship',
    additionalInfo: 'The client already has an established relationship elsewhere.',
  },
  {
    key: 'Resource Limitations',
    additionalInfo:
      "We realize that we lack the staff, tools, or resources to meet the client's needs.",
  },
  {
    key: 'Provider Availability',
    additionalInfo: 'The provider preferred by the client is not available.',
  },
  {
    key: 'Financial Incompatibility',
    additionalInfo: "The client's budget does not match our pricing structure.",
  },
  {
    key: 'Other',
    additionalInfo: 'I have other reasons.',
  },
]

export { SENT_TO_PROVIDER_DECLINE_REASONS, ACCEPTED_DECLINE_REASONS, ENGAGED_DECLINE_REASONS }
