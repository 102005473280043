import React, { useEffect } from 'react'
import { Box } from 'components'

import { useLocation, useNavigate } from 'react-router'

import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react'
import { removeHubspotScripts } from 'components/header/components/HubSpotWidget'

export function SignInWrapper({ children }) {
  const location = useLocation()
  const navigate = useNavigate()

  // context data
  const { route } = useAuthenticator((context) => [context.route])
  let from = location.state?.from?.pathname || '/'
  useEffect(() => {
    if (route === 'authenticated') {
      navigate(from, { replace: true })
    }
  }, [route, navigate, from])

  /**
   * Services cannot be wrapped in try catch - hangs forvere
   */

  useEffect(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove()
    }
    const onLoad = () => {
      if (window.localStorage.getItem('AuthState') !== 'sign-in') {
        window.localStorage.setItem('AuthState', 'sign-in')
      }
      removeHubspotScripts()
      return
    }
    onLoad()
  }, [])

  return (
    <>
      {children}
      <Box
        sx={{
          display: 'none',
        }}
      >
        <Authenticator></Authenticator>
      </Box>
    </>
  )
}

export default SignInWrapper
