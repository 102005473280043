import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useOutletContext } from 'react-router'
import { useMutation, gql } from 'utils/apollo'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

import RRPFreeSampleStoreFront, { ErrorStartingFreeTrialMsg } from './RRPFreeSampleStoreFront'
import { daysLeftInRrpTrial } from '../../utils/formatDate'
import { getRRPFreeSampleState } from '../../utils/permissions/permissionsLogic'
import useGetUser from 'utils/hooks/useGetUser'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'

const START_FREE_SAMPLE = gql`
  mutation ShowRRPFreeSampleMutation {
    acceptRrpSample
  }
`

export default function RRPFreeSampleStoreFrontContainer() {
  const navigate = useNavigate()
  const theme = useTheme()
  const { setMainLayoutTitle } = useOutletContext()

  const [startFreeTrial] = useMutation(START_FREE_SAMPLE)
  useEffect(() => {
    const title = 'RRP Complimentary Trial'
    setMainLayoutTitle(title)
  })

  const org = useSelector((state) => state.organization)
  const { selectedUser: authUser } = useGetUser()

  const [startFreeSampleError, setStartFreeSampleError] = useState('')
  const [rrpTrialState, setRrpTrialState] = useState('')

  useOnValueChange(
    JSON.stringify({
      authUser,
      org,
    }),
    () => {
      const _rrpTrialState = getRRPFreeSampleState({ org, authUser })

      setRrpTrialState(_rrpTrialState)

      if (_rrpTrialState === 'noShow') {
        navigate('/')
      }
    }
  )

  const rrpSampleActivatedAt = authUser.organization?.settings?.rrpSampleActivatedAt

  const daysLeftInTrial = rrpSampleActivatedAt
    ? String(daysLeftInRrpTrial({ rrpSampleActivatedAt }))
    : null

  async function startFreeSample() {
    try {
      await startFreeTrial()
      navigate('/')
    } catch (e) {
      console.log(e)
      setStartFreeSampleError(ErrorStartingFreeTrialMsg(theme))
    }
  }

  function redirctTo(newLocation) {
    navigate(newLocation)
  }

  function openInNewTab(url) {
    window.open(url, '_blank')
  }

  return (
    <RRPFreeSampleStoreFront
      rrpTrialState={rrpTrialState}
      daysLeftInTrial={daysLeftInTrial}
      navigate={navigate}
      openInNewTab={openInNewTab}
      redirctTo={redirctTo}
      startFreeSample={startFreeSample}
      startFreeSampleError={startFreeSampleError}
    />
  )
}
