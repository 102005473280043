import { useEffect, useRef } from "react"

export const useOnValueChange = (value: any, effectCallback: React.EffectCallback) => {
  const val = useRef(value)
  useEffect(() => {
    if (val !== value) {
      val.current = value
      effectCallback()
    }
  }, [value])
}

