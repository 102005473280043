import React, { useState } from 'react'
import MainLayout from 'components/containers/main/Main'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import _roles from 'utils/constants/roles'
import { includesSome } from 'utils/includes'
import { useSnackbar } from 'notistack'
import { get } from 'lodash'
import LoadingPage from 'components/LoadingPage'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

export default function StoreLayout() {
  // set main layout title (eg. Purchase your Safe and Sound Protocol (SSP) Subscription)
  const [mainLayoutTitle, setMainLayoutTitle] = useState('Purchase')
  const authUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  // if you're not a provider or billing user, go to dashboard
  if (!includesSome(authUserRoles, [..._roles.PROVIDER_ROLES, _roles.BILLING])) {
    enqueueSnackbar('Access to this page is not authorized', {
      variant: 'error',
      action: CloseSnackbarAction,
    })
    navigate('/')
  }

  return (
    <LoadingPage text="loading..." loading={loading}>
      <MainLayout title={mainLayoutTitle}>
        <Outlet context={{ setMainLayoutTitle, loading, setLoading }} />
      </MainLayout>
    </LoadingPage>
  )
}
