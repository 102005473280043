/**
 * This product query is for grabbing products for session filter
 */
import { get } from 'lodash'
import { gql, useQuery } from '@apollo/client'
import { useDispatch } from 'react-redux'
import { setProducts } from 'store/modules/assessments'

export const GET_PRODUCTS = gql`
  query GetProductsHook {
    getProducts {
      id
      name
      metadata
    }
  }
`

const GET_ASSESSMENT_PRODUCT_ID = gql`
  query GetAssessmentProductId($filter: FilterProductsInput) {
    getProducts(filter: $filter) {
      id
      name
      metadata
    }
  }
`

export const useGetProducts = () => {
  const { loading, data } = useQuery(GET_PRODUCTS, {
    fetchPolicy: 'cache-and-network',
  })

  return { loading, data: get(data, 'getProducts', []) }
}

// this is used for demographic info
export const useGetProductsAssessments = () => {
  const dispatch = useDispatch()
  useQuery(GET_ASSESSMENT_PRODUCT_ID, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const products = get(data, 'getProducts', [])
      dispatch(setProducts(products))
    },
  })
}
