import React, { useEffect, useRef, ReactNode } from 'react';

interface ScrollableComponentProps {
  autoFocus: boolean;
  children: ReactNode;
}

export const ScrollableComponent: React.FC<ScrollableComponentProps> = ({ autoFocus, children }) => {
  const componentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (autoFocus && componentRef.current) {
      // Scroll the component into view, adjust the behavior and block to your preference
      componentRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [autoFocus]);

  return <div ref={componentRef}>{children}</div>;
};
