import React, { useState } from 'react'
import { random } from 'lodash'
import { Grid, Card, Container } from 'components'

export default function CardWithBg({ children }) {
  const [imageIndex] = useState(random(0, 16))

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{
        minHeight: '100vh',
        display: 'flex',
        backgroundImage: `url(/images/login-backgrounds/background-${imageIndex}-min-full.jpg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Container maxWidth="xs">
        <Card className="p-10">
          <img
            src="/images/myunyte.png"
            alt="Unyte"
            style={{
              display: 'block',
              margin: 'auto',
              width: 100,
              marginBottom: 10,
            }}
          />
          {children}
          <Grid container justifyContent="center">
            <img
              src="/images/unyte-logo.png"
              alt="Unyte"
              style={{
                display: 'block',
                width: '50px',
              }}
            />
          </Grid>
        </Card>
      </Container>
    </Grid>
  )
}
