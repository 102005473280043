// from https://help.sharpspring.com/hc/en-us/articles/115002519551-Retrieving-SharpSpring-Tracking-IDs
export default function getCookie(cookieName) {
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(cookieName) === 0) {
      return c.substring(cookieName.length, c.length)
    }
  }
  return ''
}
