import axios from 'axios'
// import deepTrim from 'utils/deepTrim'

export default axios.create({
  baseURL: process.env.REACT_APP_WEBHOOKS_URL,
  transformRequest: [
    ...axios.defaults.transformRequest,
    function(data, headers) {
      // data = deepTrim(data)
      return data
    },
  ],
})

export const http2 = axios.create({
  baseURL: process.env.REACT_APP_PURCHASE_URL,
  timeout: 29000,
  transformRequest: [
    ...axios.defaults.transformRequest,
    function(data) {
      return data
    },
  ],
})
