import React from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router'
import SuccessDigestEmailNewsletter from './SuccessDigestEmailNewsletter'
import { Box, Typography } from '@mui/material'
import useGetUser from 'utils/hooks/useGetUser'
import ROLES from 'utils/constants/roles'

export default function MyAccount() {
  const navigate = useNavigate()
  const onClick = (link) => () => {
    navigate(link)
  }

  const {
    selectedUser: {
      roles: currentUserRoles,
      hasCompletedSspCertification,
      hasCompletedFocusCertification,
      hasCompletedVoiceProCertification,
    },
  } = useGetUser()
  const isCertifiedProvider =
    currentUserRoles.some((role) => ROLES.PROVIDER_ROLES.includes(role)) &&
    (hasCompletedSspCertification ||
      hasCompletedFocusCertification ||
      hasCompletedVoiceProCertification)
  const isBillingOnly = currentUserRoles.length === 1 && currentUserRoles[0] === ROLES.BILLING
  const isProvider = !!currentUserRoles.find((role) => ROLES.PROVIDER_ROLES.includes(role))

  const showSuccessDigestEmailNewsletter = isCertifiedProvider || isBillingOnly

  return (
    <Stack direction="column" p={4} spacing={4}>
      <Box sx={{ maxWidth: '38rem', border: '1px solid lightgrey', borderRadius: '0.4rem' }}>
        <Stack direction="column" padding="1rem" spacing={2}>
          <Typography variant="h5">Account Changes</Typography>
          <Stack spacing={2} direction="row">
            <Button variant="contained" onClick={onClick('/my/account/change-email')}>
              Change Email
            </Button>
            <Button variant="contained" onClick={onClick('/my/account/change-password')}>
              Change Password
            </Button>
            {// this button should only be shown for providers
            isProvider && (
              <Button
                variant="contained"
                onClick={onClick('/my/account/change-professional-credentials')}
              >
                Change Professional Credentials
              </Button>
            )}
          </Stack>
        </Stack>
      </Box>
      {showSuccessDigestEmailNewsletter && <SuccessDigestEmailNewsletter />}
    </Stack>
  )
}
