import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import useForm from 'components/form/useForm'
import EMAILS from 'utils/constants/emails'
import {
  FormHelperText,
  Button,
  PasswordInput,
  TextField,
  CardContent,
  Link,
  Box,
} from 'components'

import { loginWithoutRedux, showError, setError } from 'store/modules/auth'

import CardWithBg from 'components/CardWithBg'
import { useLocation } from 'react-router'
import { SignInWrapper } from './SignInWrapper'

export function OldSignIn() {
  const dispatch = useDispatch()
  const location = useLocation()

  let from = location.state?.from?.pathname || '/'

  const authError = useSelector((state) => get(state, 'auth.error', null))
  const showErrorMessage = useSelector((state) => get(state, 'auth.showError', false))

  const { form, setFormValue, onSubmit, pending, errors, isValid, trimmedForm } = useForm({
    data: {
      email: '',
      password: '',
    },
    validation: {
      email: {
        required: { msg: 'Email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
        custom: {
          args: {
            fn: (val) => {
              // https://stackoverflow.com/questions/13607921/removing-non-latin-characters-from-a-string
              return !val.replace(/[[\x20-\x7E]/g, '').length
            },
          },
          msg: 'Invalid email: Unknown character.',
        },
      },
      password: {
        required: { msg: 'Password is required.' },
      },
    },
    async onSubmit(e) {
      e.preventDefault()
      try {
        await dispatch(showError(false))
        await dispatch(loginWithoutRedux(trimmedForm))
        // set localstorage that we are logging in...
        await window.localStorage.setItem('AuthState', 'success')
        await window.location.replace(from)
      } catch (error) {
        const defaultMessage = `Sorry but we are unable to sign you in at this time. We are working to resolve this issue. Please try again later or contact ${EMAILS.supportEmail}.`
        const errorsMapping = {
          'Password reset required for the user': 'Password reset required for the user.',
          'User is disabled.':
            'Please contact your Provider to access your account. Thank you. [Provider: the professional who invited you to a MyUnyte account.]',
          'Username cannot be empty': 'Sign in failed. Invalid email or password.',
          'Incorrect username or password.': 'Sign in failed. Invalid email or password.',
          'Null Error': `Sorry, but we were unable to sign in at this time. For reference: "User not found." Please contact ${EMAILS.supportEmail}`,
        }
        const errorMessage = errorsMapping[error?.message] ?? defaultMessage
        await dispatch(setError(errorMessage))
        await dispatch(showError(true))
      }
    },
  })

  const onChange = (type) => async (event) => {
    await setFormValue(type)(event)
    await dispatch(showError(false))
  }

  return (
    <SignInWrapper>
      <CardWithBg>
        <form onSubmit={onSubmit} style={{ maxHeight: '25rem' }}>
          <CardContent className="pt-0">
            <FormHelperText error className="m-0 h-15">
              {showErrorMessage && authError}
            </FormHelperText>

            <TextField
              fullWidth
              id="login-email"
              label="Email*"
              type="email"
              error={errors.email}
              value={form.email}
              onChange={onChange('email')}
              className="h-18"
              sx={{ mb: 2 }}
              disabled={pending}
              data-test="email-field"
            />

            <PasswordInput
              label="Password*"
              id="login-password"
              fullWidth
              error={errors.password}
              value={form.password}
              onChange={onChange('password')}
              disabled={pending}
              style={{ paddingBottom: '1rem' }}
            />
          </CardContent>

          <Box justifyContent="center" display="flex" className="w-full">
            <Button
              type="submit"
              color="primary"
              loading={pending}
              variant="contained"
              className="mx-4 w-full"
              disabled={!isValid}
            >
              Sign In to MyUnyte
            </Button>
          </Box>
          <Box justifyContent="center" display="flex">
            <FormHelperText className="py-5 mx-5">
              <Link
                className={`font-semibold ${pending ? 'text-gray-200' : 'text-link'}`}
                to={pending ? '/sign-in' : '/forgot-password'}
              >
                Forgot My Password
              </Link>
            </FormHelperText>
          </Box>
        </form>
      </CardWithBg>
    </SignInWrapper>
  )
}

export default OldSignIn
