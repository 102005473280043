import React, { useState } from 'react'

// Create a order context
const GTMContext = React.createContext()

export function GTMProvider({ children }) {
  // we need to stick state in here
  const [gtm, setGTM] = useState({})
  return <GTMContext.Provider value={[gtm, setGTM]}>{children}</GTMContext.Provider>
}

export default GTMContext
