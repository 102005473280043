/**
 * This module will house the states of a credit card:
 * - disable: this state determines if credit card is filled in
 * - pending: this state determines when card is being submitted
 */
const SET_DISABLE = 'CREDIT_CARD/SET_DISABLE'
const SET_ERROR = 'CREDIT_CARD/SET_ERROR'
const SET_PENDING = 'CREDIT_CARD/SET_PENDING'
const SET_SHOW_ERROR = 'CREDIT_CARD/SET_SHOW_ERROR'
const SET_MAKE_DEFAULT = 'CREDIT_CARD/SET_MAKE_DEFAULT'

const initialState = {
  disable: false,
  pending: false,
  error: '',
  showError: false,
  makeDefualt: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DISABLE:
      return {
        ...state,
        disable: action.payload,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    case SET_SHOW_ERROR:
      return {
        ...state,
        showError: action.payload,
      }
    case SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      }
    case SET_MAKE_DEFAULT:
      return {
        ...state,
        makeDefault: action.payload,
      }
    default:
      return state
  }
}

export function setDisable(payload) {
  return {
    type: SET_DISABLE,
    payload,
  }
}

export function setError(payload) {
  return {
    type: SET_ERROR,
    payload,
  }
}

export function setShowError(payload) {
  return {
    type: SET_SHOW_ERROR,
    payload,
  }
}

export function setPending(payload) {
  return {
    type: SET_PENDING,
    payload,
  }
}

export function setMakeDefault(payload) {
  return {
    type: SET_MAKE_DEFAULT,
    payload,
  }
}
