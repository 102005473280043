import React, { useEffect } from 'react'
import { useLocation, useOutletContext } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useForm, FormProvider, SubmitHandler, FieldValues } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
  demographicInfoSchema,
  DemographicInfoValues,
  getAgeFromBirthYear,
  getBirthYearFromAge,
  LocationState,
  OutletContextType,
} from './(basic-info)/basic-info-constants'
import { PreviewDemographicInfo } from './(demographic-info)/preview-demographic-info'
import { EditDemographicInfo } from './(demographic-info)/edit-demographic-info'
import useGetAssessmentId from 'views/assessments/utils/useGetAssessmentId'
import { useMutation } from '@apollo/client'
import { UPDATE_DEMOGRAPHIC_INFO } from 'views/assessments/constants/gql'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useSnackbar } from 'notistack'

// export default function DemographicInfoClientsView({selectedClient}) {
export default function DemographicInfoClientsView() {
  const { selectedClient } = useOutletContext<OutletContextType>()
  const { assessmentProductId } = useGetAssessmentId()
  const { enqueueSnackbar } = useSnackbar()

  const location = useLocation()
  const state = location.state as LocationState

  // 1. check productpreferences if they have data use
  const methods = useForm<DemographicInfoValues>({
    defaultValues: {
      id: undefined,
      viewState: state?.editMode ? 'EDIT' : 'PREVIEW',
      gender: '',
      country: '',
      age: undefined,
    },
    resolver: zodResolver(demographicInfoSchema),
  })

  // Update form values when selectedClient changes
  useEffect(() => {
    onReset()
  }, [selectedClient, state?.editMode, assessmentProductId, methods])

  const onReset = () => {
    if (selectedClient && assessmentProductId) {
      const age = selectedClient.birthYear
        ? getAgeFromBirthYear(selectedClient.birthYear)
        : undefined

      methods.reset({
        id: selectedClient.id,
        viewState: 'PREVIEW',
        gender: selectedClient.gender || '',
        country: selectedClient.country || '',
        age,
      })
    }
  }

  const viewState = methods.watch('viewState')
  const [updateDemographicInfo] = useMutation(UPDATE_DEMOGRAPHIC_INFO)
  const onSubmit: SubmitHandler<FieldValues | DemographicInfoValues> = async (data) => {
    try {
      const birthYear = getBirthYearFromAge(data.age)
      await updateDemographicInfo({
        variables: {
          user: {
            id: data.id,
            // country: data.country ?? undefined, did not work..
            country: data.country ? data.country : null,
            gender: data.gender ? data.gender : null,
            birthYear: birthYear ? birthYear : null,
          },
        },
      })
      methods.reset({ ...data, viewState: 'PREVIEW' })
      enqueueSnackbar('Update successful', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
    } catch (error) {
      console.error('Saving Demographic Info', error)
      enqueueSnackbar('Update unsuccessful', {
        variant: 'error',
        action: CloseSnackbarAction,
      })
    }
  }

  return (
    <Grid container>
      <Grid item sm={12} md={12} lg={9} xl={6}>
        <FormProvider {...methods}>
          {viewState === 'PREVIEW' && <PreviewDemographicInfo />}
          {viewState === 'EDIT' && <EditDemographicInfo onSubmit={onSubmit} onReset={onReset} />}
        </FormProvider>
      </Grid>
    </Grid>
  )
}
