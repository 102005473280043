import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Button, CardContent, TextField, FormHelperText, Link, Box } from 'components'

import styles from './ForgotPassword.module.scss'

import { useSnackbar } from 'notistack'
import useForm from 'components/form/useForm'

import { forgotPassword, logout } from 'store/modules/auth'

import CardWithBg from 'components/CardWithBg'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

export function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const {
    form,
    setFormValue,
    onSubmit,
    pending,
    errors,
    setErrors,
    isValid,
    trimmedForm,
  } = useForm({
    data: {
      email: '',
    },
    validation: {
      email: {
        required: { msg: 'Email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
      },
    },
    async onSubmit(e) {
      e.preventDefault()
      try {
        await dispatch(forgotPassword(trimmedForm.email))
      } catch (e) {
        // Ignore all errors
      } finally {
        enqueueSnackbar(
          `If this account exists, an email with password reset instructions was sent to ${trimmedForm.email}`,
          {
            variant: 'success',
            autoHideDuration: 10000,
            action: CloseSnackbarAction,
          }
        )
        setFormValue('email', { path: '' })('')
        setErrors({})
      }
    },
  })

  useEffect(() => {
    dispatch(logout())
    // eslint-disable-next-line
  }, [logout])

  return (
    <CardWithBg>
      <form className={styles.email} onSubmit={onSubmit} style={{ maxHeight: '30rem' }}>
        <CardContent className="pt-0">
          <h2 className={styles.title} style={{ paddingTop: '1rem' }}>
            Reset MyUnyte Password
          </h2>
          <div className={styles.caption}>
            Enter the email address associated with your MyUnyte account, and we’ll email you a link
            to reset your MyUnyte password.
          </div>
          <FormHelperText className="py-2" error>
            {errors.none}
          </FormHelperText>
          <TextField
            fullWidth
            className="h-18"
            error={errors.email}
            id="login-email"
            type="email"
            label="Email*"
            value={form.email}
            onChange={setFormValue('email')}
          />
        </CardContent>

        <Box justifyContent="center" display="flex" className="w-full">
          <Button
            disabled={!isValid}
            type="submit"
            color="primary"
            loading={pending}
            variant="contained"
            className="mx-4 w-full"
          >
            Send Password Reset Link
          </Button>
        </Box>
        <Box justifyContent="center" display="flex">
          <FormHelperText className="py-5 mx-5">
            <Link
              className={`font-semibold ${pending ? 'text-gray-200' : 'text-link'}`}
              to="/sign-in"
            >
              Back to MyUnyte Sign In
            </Link>
          </FormHelperText>
        </Box>
      </form>
    </CardWithBg>
  )
}

export default ForgotPassword
