export default {
  introVideo: {
    sspClient: 'https://player.vimeo.com/video/460355206',
    ilsClient: 'https://player.vimeo.com/video/770794714',
    sspProvider: 'https://player.vimeo.com/video/460353308',
    ilsProvider: 'https://player.vimeo.com/video/770763115',
    activityLibrary: 'https://player.vimeo.com/video/777099127',
  },
  updateVideo: {
    src: 'https://player.vimeo.com/video/460319381',
    href: 'https://integratedlistening.com/ssp-3-pathways-intake-guidelines/',
  },
  oldProviderDashboard: {
    account: 'https://player.vimeo.com/video/411182372',
    track: 'https://player.vimeo.com/video/411182639',
    music: 'https://player.vimeo.com/video/411182782',
  },
  providerDashboard: [
    {
      shortDescription: 'Learn how to create and manage your client accounts.',
      modal: 'manage-account',
      id: 'manage-account',
      title: 'Create and Manage Clients',
      src: 'https://player.vimeo.com/video/411182372',
      img: 'images/dashboard/CreateClient.png',
    },
    {
      shortDescription: `Learn how to track your clients' progress.`,
      modal: 'client-progress',
      id: 'client-progress',
      title: `Track your Clients' progress`,
      src: 'https://player.vimeo.com/video/411182639',
      img: 'images/dashboard/TrackClient.png',
    },
    {
      shortDescription: 'Learn how to enable remote delivery for your client.',
      modal: 'programs',
      id: 'programs',
      title: 'Enable Client Remote Delivery',
      src: 'https://player.vimeo.com/video/411182782',
      img: '/images/dashboard/EnableClient.png',
    },
    {
      shortDescription: 'Learn how to use the Unyte Health app.',
      modal: 'resources',
      id: 'resources',
      title: 'Using the Unyte Health App',
      img: '/images/dashboard/UsingApp.png',
      src: 'https://player.vimeo.com/video/490063308',
    },
  ],
  sspAudioSamples: {
    calm: 'SSP_Audio_Samples/SSP-calm-sample.mp3',
    flow: 'SSP_Audio_Samples/SSP-flow-sample.mp3',
    freely: 'SSP_Audio_Samples/SSP-freely-sample.mp3',
    groove: 'SSP_Audio_Samples/SSP-groove-sample.mp3',
    grooveInstrumental: 'SSP_Audio_Samples/SSP-groove-instrumental-sample.mp3',
    original: 'SSP_Audio_Samples/SSP-original-sample.mp3',
    wonder: 'SSP_Audio_Samples/SSP-wonder-sample.mp3',
  },
  resources: {
    client: {},
    provider: {},
  },
}
