import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
export default ({ user }) => {
  const navigate = useNavigate()
  const onClick = () => {
    navigate(`/dashboard/${user.id}`)
  }
  return (
    <div>
      <Button onClick={onClick} className="m-1" data-test="view-client-dashboard">
        View Client Dashboard
      </Button>
    </div>
  )
}
