const ROLES = {
  ADMIN: 'admin',
  ADMIN_NO_CLIENTS: 'admin_no_clients',
  PROVIDER_ALL_CLIENTS: 'provider_all_clients',
  PROVIDER: 'provider',
  CLIENT: 'client',
  BILLING: 'billing',
}

export default {
  ...ROLES,

  ALL_ROLES: [
    ROLES.ADMIN,
    ROLES.ADMIN_NO_CLIENTS,
    ROLES.PROVIDER_ALL_CLIENTS,
    ROLES.PROVIDER,
    ROLES.CLIENT,
    ROLES.BILLING,
  ],
  ADMIN_ROLES: [ROLES.ADMIN, ROLES.ADMIN_NO_CLIENTS],
  PROVIDER_ROLES: [ROLES.PROVIDER_ALL_CLIENTS, ROLES.PROVIDER],
  CLIENT_ROLES: [ROLES.CLIENT],
}
