/**
 * Component will look like this:
 * Total | Completed | In Progress | Sent | Completion Rate
 */
import { Card, Grid, Stack, CircularProgress, Typography, Box } from '@mui/material'
import React, { useState } from 'react'
import { useOutletContext } from 'react-router'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import PieChart from './reports/PieChart'

interface KpiProps {
  totalCount: number
  completedCount: number
  inProgressCount: number
  sentCount: number
  inProgressByClientCount: number
  inProgressByProviderCount: number
}

const CustomCard = ({ ...props }) => <Card elevation={0} variant="outlined" {...props} />

interface KPILayoutProps {
  title: string
  value: number | string
  color?: string
  loading?: boolean
  dataTest?: string
}
const KPILayout: React.FC<KPILayoutProps> = ({ title, value, loading, color, dataTest }) => {
  return (
    <Stack direction="column" spacing={3} justifyContent="center" alignItems="center">
      <Stack alignItems="center" direction="row" spacing={2}>
        {color && (
          <span
            style={{
              backgroundColor: color,
              borderRadius: '9999px',
              aspectRatio: '1/1',
              width: '1rem',
              height: '1rem',
            }}
          ></span>
        )}
        <Stack alignItems="center" direction="column">
          {loading && <CircularProgress data-test="loading-spinner" />}
          {!loading && (
            <Typography fontSize="2.5rem" textAlign="center" component="span" data-test={dataTest}>
              {value}
            </Typography>
          )}
          <Typography
            variant="body1"
            sx={{
              display: 'flex',
              alignItems: 'center' /* Vertically center the contents */,
              justifyContent:
                'start' /* Align contents to the start, keeping the bullet and text close together */,
              gap: '0.5rem',
            }}
            textAlign="center"
          >
            {title}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
const CustomGridItem = ({ children, ...props }) => (
  <Grid item xs={3} {...props}>
    <Box sx={{ height: '4rem' }} />
    {children}
    <Box sx={{ height: '4rem' }} />
  </Grid>
)

// default start with everything loading
export function AssessmentsKPIs() {
  const { kpi, data }: { kpi: KpiProps; loading: boolean; data: [] } = useOutletContext()
  const [loading, setLoading] = useState<boolean | undefined>(true)

  useOnValueChange(JSON.stringify({ kpi }), () => {
    const minKpi = Math.min(
      kpi.sentCount,
      kpi.totalCount,
      kpi.inProgressByClientCount,
      kpi.inProgressByProviderCount,
      kpi.completedCount
    )
    // wait for at least 1 second because dynamic is too fast for test caess
    setTimeout(() => {
      if (minKpi !== -1) {
        setLoading(false)
      }
    }, 1000)
  })

  return (
    // height is hardcoded here to prevent flickering when loading data
    <Grid container direction="row" px={2}>
      <Grid
        item
        container
        component={CustomCard}
        xs={12}
        sm={12}
        md={9}
        direction="row"
        justifyContent="space-between"
      >
        <CustomGridItem container justifyContent="center" alignItems="center">
          {loading && <CircularProgress />}
          {!loading && (
            <PieChart
              values={[
                kpi.completedCount,
                kpi.inProgressByProviderCount + kpi.inProgressByClientCount,
                kpi.sentCount,
              ]}
            />
          )}
        </CustomGridItem>
        <CustomGridItem>
          <KPILayout
            title="Completed"
            value={kpi.completedCount}
            color="var(--completedGreen)"
            loading={loading}
            dataTest="kpi-completed-count"
          />
        </CustomGridItem>
        <CustomGridItem>
          <KPILayout
            title="In Progress"
            value={kpi.inProgressByProviderCount + kpi.inProgressByClientCount}
            color="var(--orange)"
            loading={loading}
            dataTest="kpi-in-progress-count"
          />
        </CustomGridItem>
        <CustomGridItem>
          <KPILayout
            title="Sent"
            value={kpi.sentCount}
            color="var(--dark-blue)"
            loading={loading}
            dataTest="kpi-sent-count"
          />
        </CustomGridItem>
      </Grid>
      <Grid
        item
        container
        sx={{
          display: { xs: 'none', sm: 'none', md: 'inherit' },
          height: '100%',
        }}
        sm={3}
        alignContent="stretch"
        direction="column"
      >
        <Card
          elevation={0}
          variant="outlined"
          sx={{
            marginLeft: '1rem',
            height: '100%',
          }}
        >
          <Grid
            item
            sm={12}
            sx={{
              display: { xs: 'none', sm: 'grid' },
              px: 2,
            }}
            direction="column"
            container
          >
            <Box sx={{ height: '4rem' }} />
            <KPILayout
              title="Assessment Completion Rate"
              value={`${Math.round((kpi.completedCount / kpi.totalCount) * 100) || 0}%`}
              loading={loading}
            />
            <Stack
              sx={{
                color: 'var(--yellow)',
                height: '4rem',
                px: 1,
                pb: '0.5rem',
                display: 'flex',
              }}
              flexDirection="row"
              alignItems="center"
              justifyContent="center"
            >
              {(Math.round((kpi.completedCount / kpi.totalCount) * 100) || 0) < 70 &&
                !!data.length && (
                  <Typography
                    sx={{
                      color: 'var(--yellow)',
                    }}
                    textAlign="center"
                    variant="caption"
                  >
                    Kindly remind clients to complete assessments promptly.
                  </Typography>
                )}
            </Stack>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}