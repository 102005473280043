import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles({
  loading: {
    '--shine': 'white',
    '--background': 'var(--gray)',
    backgroundImage: `linear-gradient(
      90deg,
      var(--background) 0px,
      var(--shine) 40px,
      var(--background) 80px
    )`,
    backgroundSize: '500px',
    animation: `$shine 2s infinite linear`,
  },
  '@keyframes shine': {
    from: {
      backgroundPosition: '0%',
    },
    to: {
      backgroundPosition: '1000px',
    },
  },
})

export default function Loading({
  columns = 1,
  rows = 1,
  width = ['300px'],
  height = ['15px'],
  justify = 'flex-start',
  alignItems = 'stretch',
}) {
  const classes = useStyles()

  return (
    <Grid container direction="column">
      {Array.from({ length: rows }, (_, i) => (
        <Grid
          item
          container
          direction="row"
          className="p-1"
          justifyContent={justify}
          alignItems={alignItems}
          key={`loading-grid-${i}`}
        >
          {Array.from({ length: columns }, (_, j) => (
            <img
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
              className={`m-1 ${classes.loading}`}
              alt=""
              height={height[i]}
              width={width[j]}
              key={`loading-img-${j}`}
            />
          ))}
        </Grid>
      ))}
    </Grid>
  )
}
