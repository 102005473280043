import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { gql } from '@apollo/client'
import { useMutation } from 'utils/apollo'
import { get } from 'lodash'

import {
  Button,
  CardContent,
  CardActions,
  TextField,
  FormHelperText,
  PasswordInput,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useSnackbar } from 'notistack'

import queryString from 'query-string'
import { verifyEmailChange } from 'utils/verifyEmailChange'
import formValidation from 'utils/constants/formValidation'
import { login, authenticateUser, logout, showError, setError } from 'store/modules/auth'
import useForm from 'components/form/useForm'

import CardWithBg from 'components/CardWithBg'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const UPDATE_USER_EMAIL = gql`
  mutation {
    updateUserEmail {
      newEmail
      changeEmailUuid
    }
  }
`

const useStyles = makeStyles({
  title: {
    display: 'block',
    width: '100%',
    margin: '0 auto',
    color: 'rgb(72, 72, 72)',
    fontWeight: 300,
    marginBottom: '20px',
  },
})

export function ChangeEmailVerification() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const showErrorMessage = useSelector((state) => get(state, 'auth.showError', false))
  const loadedAuth = useSelector((state) => get(state, 'auth.loadedAuth', false))
  const signInFlag = useSelector((state) => get(state, 'auth.signInFlag', false))
  const [loading, setLoading] = useState(false)
  const [emailChangeComplete, setEmailChangeComplete] = useState(false)
  const [updateUserEmail] = useMutation(UPDATE_USER_EMAIL, {
    async update(cache) {
      cache.reset()
    },
  })
  const queryURL = queryString.parse(window.location.search)
  const errorMessage = `Verification failed. Invalid password.`
  const handleUpdateUserEmail = async () => {
    try {
      await updateUserEmail({})
    } catch (error) {
      console.log(`Error while trying to update user email: ${error}`)
    } finally {
      setEmailChangeComplete(true)
    }
  }
  const handleVerifyEmailChange = async () => {
    const { emailChangeVerifiedResult } = await dispatch(
      verifyEmailChange({
        newEmail: queryURL.newEmail,
        changeEmailUuid: queryURL.changeEmailUuid,
      })
    )
    if (!emailChangeVerifiedResult) {
      dispatch(logout())
      enqueueSnackbar(`Could not verify email change.`, {
        variant: 'error',
        action: CloseSnackbarAction,
      })
      navigate('/sign-in')
    }
  }
  const { form, setFormValue, onSubmit, pending, errors, isValid, trimmedForm } = useForm({
    data: {
      email: queryURL.currentEmail.replace(' ', '+'),
      newEmail: queryURL.newEmail.replace(' ', '+'),
      password: '',
    },
    validation: {
      email: { ...formValidation.emailValidation },
      password: { ...formValidation.verifyPasswordValidation },
    },
    async onSubmit(e) {
      e.preventDefault()
      setLoading(true)
      try {
        if (!trimmedForm.email || !trimmedForm.password) {
          await dispatch(setError('Incorrect username or password'))
        } else {
          await dispatch(authenticateUser(trimmedForm))
          handleUpdateUserEmail()
        }
      } catch (error) {
        console.log(`Authentication error: ${error}`)
        await dispatch(showError(true))
        setLoading(false)
      }
    },
  })
  const onCancel = async () => {
    await dispatch(logout())
    navigate('/sign-in')
  }

  useEffect(() => {
    if (loadedAuth) {
      form.email = form.newEmail
      dispatch(login(trimmedForm))
      if (signInFlag && emailChangeComplete) {
        enqueueSnackbar(`Email successfully changed`, {
          variant: 'success',
          action: CloseSnackbarAction,
        })
        navigate('/')
      }
    }
    // eslint-disable-next-line
  }, [loadedAuth, signInFlag])

  useEffect(() => {
    dispatch(logout())
    handleVerifyEmailChange()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <CardWithBg>
      <form onSubmit={onSubmit} autoComplete="off">
        <CardContent>
          <h1 className={classes.title}>Verify Email Change</h1>
          <FormHelperText className="py-2" error>
            {errors.none}
          </FormHelperText>
          <FormHelperText error className="m-0 h-15">
            {showErrorMessage && errorMessage}
          </FormHelperText>
          <TextField
            disabled
            label="Current Email*"
            className="mb-3"
            fullWidth
            value={form.email}
            onChange={setFormValue('email')}
            error={errors.email}
          />
          <TextField
            disabled
            label="New Email*"
            className="mb-3"
            fullWidth
            value={form.newEmail}
            onChange={setFormValue('newEmail')}
            error={errors.newEmail}
          />
          <FormHelperText className="mb-3">Please enter your password:</FormHelperText>
          <PasswordInput
            disabled={pending || loading}
            label="Password*"
            className="mb-3"
            fullWidth
            value={form.password}
            onChange={setFormValue('password')}
            error={errors.password}
          />
        </CardContent>
        <CardActions>
          <Button color="secondary" disabled={pending || loading} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" loading={pending || loading} disabled={!isValid}>
            Verify Email Change
          </Button>
        </CardActions>
      </form>
    </CardWithBg>
  )
}

export default ChangeEmailVerification
