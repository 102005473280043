import React from 'react'
import { Link } from 'react-router-dom'

import MaterialButton from '@mui/material/Button'
import { CircularProgress } from 'components'

import styles from './Button.module.scss'

function LinkWrapper({ href, ...props }) {
  return <Link to={href} {...props} />
}

export default function Button({ loading, children, href, disabled, ...rest }) {
  const component = href ? LinkWrapper : undefined
  return (
    <MaterialButton
      href={href}
      component={component}
      disabled={loading || disabled}
      style={{ textTransform: 'capitalize' }}
      {...rest}
    >
      <React.Fragment>
        {children}
        {loading && <CircularProgress size={24} className={styles.loading} />}
      </React.Fragment>
    </MaterialButton>
  )
}
