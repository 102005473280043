import React from 'react'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'

export default function BackButton({ ...props }) {
  const navigate = useNavigate()

  const onClick = () => {
    navigate(-1)
  }
  return (
    <Button
      {...props}
      onClick={onClick}
      variant="outlined"
      startIcon={<ArrowBackIosIcon sx={{ display: { xs: 'none', sm: 'block' } }} />}
    >
      Back
    </Button>
  )
}
