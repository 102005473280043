/**
 * The purchase layout is used in accessories, shipping, and confirm page of internal purchase
 * - child component should be in a card format
 */
import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useNavigationType } from 'react-router-dom'
import { Grid, Container, Card } from 'components'

import Cart from '../../new-purchase/components/Cart'
import MainLayout from 'components/containers/main/Main'
import { ALL_SSP_SKUS, ALL_FOCUS_SKUS } from 'utils/constants/prices'
import { isEmpty } from '../../../utils/isEmpty'
import { removeCoupon } from 'store/modules/new-purchase'
import LoadingPage from '../../../components/LoadingPage'
import { client, AUTH_CREATE_PURCHASE } from 'utils/apollo/purchaseService/client'
import { useMutation } from '@apollo/client'
import { extendData } from 'store/modules/new-purchase'
import { useSnackbar } from 'notistack'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

export default function PurchaseLayout({ loading, loadingText, title, children, isConfirmPage }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const navigationType = useNavigationType()
  const { enqueueSnackbar } = useSnackbar()

  // use reduxData to see what category onboarding product is in
  const { uuid } = useSelector((state) => state.newPurchase.data)
  const { onboardingProducts = [] } = useSelector((state) => state.newPurchase.data)
  const hasSsp = ALL_SSP_SKUS.some((key) => onboardingProducts.includes(key))
  const hasFocus = ALL_FOCUS_SKUS.some((key) => onboardingProducts.includes(key))

  // if newPurchase is an empty object, we know user has restarted the page..
  const newPurchase = useSelector((state) => get(state, 'newPurchase.data', {}))
  useEffect(() => {
    if (isEmpty(newPurchase)) {
      navigate('/')
      return
    }
    dispatch(removeCoupon())
    // eslint-disable-next-line
  }, [])

  // if user resets page, redirect to store
  useEffect(() => {
    if (navigationType === 'POP' && onboardingProducts?.length === 0) {
      navigate('/store')
    }
    // eslint-disable-next-line
  }, [])

  const [createPurchase, { loading: loadingCreatePurchase }] = useMutation(AUTH_CREATE_PURCHASE, {
    client,
    variables: { params: { data: {} } },
  })

  useEffect(() => {
    const getUuid = async () => {
      const internalPurchaseData = await createPurchase()
      const newUuid = get(internalPurchaseData, 'data.auth_create_purchase.uuid', null)
      if (newUuid) {
        await dispatch(extendData({ uuid: newUuid }))
      } else {
        enqueueSnackbar('Purchase cannot be completed, please try again later..', {
          variant: 'error',
          action: CloseSnackbarAction,
        })
      }
    }

    if (!uuid) {
      getUuid()
    }

    // eslint-disable-next-line
  }, [uuid])

  return (
    <LoadingPage
      loading={loading || loadingCreatePurchase}
      text={(loadingCreatePurchase && 'initializing order...') || loadingText}
    >
      <MainLayout title={title}>
        <Container maxWidth="lg" className="bg-white h-full">
          <Grid container justifyContent="center">
            <Grid className="p-5" item xs={12} md={7}>
              {children}
            </Grid>
            <Grid className="p-5" item xs={12} md={5}>
              <Card variant="outlined">
                <Cart
                  isConfirmPage={isConfirmPage}
                  hideBackground
                  hasSsp={hasSsp}
                  hasFocus={hasFocus}
                  hasAccessory={!hasSsp || !hasFocus}
                />
              </Card>
            </Grid>
          </Grid>
        </Container>
      </MainLayout>
    </LoadingPage>
  )
}
