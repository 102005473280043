import CreateUser from '../components/CreateUser'
import ProfessionalCredentials from '../components/ProfessionalCredentials'
import Confirm from '../components/Confirm'
import Accessories from '../components/Accessories'
import ShippingAddress from 'components/ecommerce/shipping/ShippingAddress'
import {
  ALL_CERTIFICATION_SKUS,
  FOCUS_SYSTEM_SKUS,
  PROMO_SKUS,
  PRODUCTS,
  ALL_FOCUS_SUBSCRIPTIONS_SKUS,
} from 'utils/constants/prices'
import NotFound from 'views/404'
import { useSelector } from 'react-redux'

export default function useGetSteps() {
  // grab skus from redux..
  const reduxData = useSelector((state) => state.newPurchase.data)
  const skus = reduxData.onboardingProducts || []
  const hasAddons =
    reduxData.selectedAddOns &&
    Object.keys(reduxData.selectedAddOns).length &&
    Object.keys(reduxData.selectedAddOns).some((key) => !!reduxData.selectedAddOns[key])

  const hasOnlyCertification = skus.every((sku) => ALL_CERTIFICATION_SKUS.includes(sku))
  const hasFocusSystem = FOCUS_SYSTEM_SKUS.some((key) => skus.includes(key))
  const hasFocusSubscription = ALL_FOCUS_SUBSCRIPTIONS_SKUS.some((key) => skus.includes(key))

  const hasPhysicalProduct = skus.some(
    (sku) =>
      PRODUCTS[sku] &&
      (PRODUCTS[sku].type === 'physicalProduct' || PRODUCTS[sku].isPromoWithShipping)
  )

  const hasCertificationAndPromo = skus.every((sku) =>
    [...ALL_CERTIFICATION_SKUS, ...PROMO_SKUS].includes(sku)
  )

  // the first page the user lands on will be CreateUser
  const steps = [
    {
      component: CreateUser,
      label: 'Contact Info',
      isActive: true,
    },
    {
      component: ProfessionalCredentials,
      label: 'Professional Info',
      isActive: true,
    },
    {
      component: Accessories,
      label: 'Accessories',
      isActive:
        // if you have a promo with certificaiton, don't show accessories
        !hasCertificationAndPromo &&
        // Currently don't show accessories to ils products
        !hasFocusSubscription,
    },
    {
      component: ShippingAddress,
      label: 'Shipping Address',
      isActive: (hasAddons && !hasOnlyCertification) || hasFocusSystem || hasPhysicalProduct,
    },
    { component: Confirm, label: 'Confirm Purchase', isActive: true },
    { component: NotFound, label: 'Not Found', isActive: true },
  ]
  return steps
}
