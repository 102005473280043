import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import { get } from 'lodash'
import ROLES from 'utils/constants/roles'
import { includesSome } from 'utils/includes'
import { setUser } from 'store/modules/assessments'
import { useNavigate } from 'react-router'

export default ({ user }) => {
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const currentUserIsProvider = includesSome(currentUserRoles, [
    ROLES.PROVIDER,
    ROLES.PROVIDER_ALL_CLIENTS,
  ])
  const hasAssessmentProducts = useSelector((state) => !!state.ff.hasAssessmentProducts)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  // disable this button
  if (!hasAssessmentProducts || !currentUserIsProvider) {
    return null
  }

  const onClick = () => {
    dispatch(
      setUser({
        userId: user.id,
        ...user,
      })
    )
    navigate(`/assessments/${user.id}`, { state: { hideGettingStarted: true } })
  }

  return (
    <Button className="m-1" onClick={onClick} data-test="view-assessment">
      Assessments
    </Button>
  )
}
