import React, { useState } from 'react'
import { useMutation } from 'utils/apollo'
import { gql } from '@apollo/client'
import {
  TextField,
  Button,
  Grid,
  FormHelperText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material'

import { useStateWatch, useUserForm } from 'hooks'
import { useSnackbar } from 'notistack'

import Preview, { PreviewSlot } from './components/PreviewUserDetails'
import SelectCountry from 'components/ecommerce/shipping/SelectCountry'
import SelectState from 'components/ecommerce/shipping/SelectState'
import AgeDropdown from '../../../../components/AgeDropdown'
import EMAILS from 'utils/constants/emails'
import { parseISO } from 'date-fns'
import { selectUser, setData } from 'store/modules/clients'
import { useDispatch, useSelector } from 'react-redux'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { verifyFields } from 'utils/constants/updateUserValidFields'
import { get } from 'lodash'
import { useOutletContext } from 'react-router'

const DATE_OPTIONS = { day: '2-digit', month: 'short', year: 'numeric' }

function formatDate(dateString) {
  const date = new Date(dateString)
  return date.toLocaleString('en-GB', DATE_OPTIONS)
}
function transformSlot(slot) {
  const slotCategory = slot.category === 'focus' ? 'ILS' : slot.category.toUpperCase()
  if (!slot.usedAt) {
    return {
      label: `${slotCategory} License`,
      value: `assigned: ${formatDate(slot.assignedAt)}`,
    }
  } else {
    // similar to how BE calculates expiry time. If expiredAt doesn't exist, calculating here until expiredAt date is backfilled
    const getExpiryDateInOneYear = (usedDate) => {
      const expiredAtDate = new Date(usedDate)
      // Add one full year
      expiredAtDate.setFullYear(expiredAtDate.getFullYear() + 1)
      // Subtract one day
      expiredAtDate.setDate(expiredAtDate.getDate() - 1)
      // Set time to 23:59:59.000
      expiredAtDate.setHours(23, 59, 59, 0)

      return expiredAtDate
    }

    const expiredAtText = slot.expiredAt
      ? formatDate(slot.expiredAt)
      : slot.usedAt
      ? formatDate(getExpiryDateInOneYear(formatDate(slot.usedAt)))
      : ''

    return {
      label: `${slotCategory} License`,
      value: `started: ${formatDate(slot.usedAt)}, ends: ${expiredAtText}`,
    }
  }
}

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      dob
    }
  }
`

const Wrapper = ({ children, gridKey }) => (
  <Grid className="py-5" key={`${gridKey}-1`}>
    <Grid container spacing={2} key={`${gridKey}-2`}>
      {children}
    </Grid>
  </Grid>
)

const isEmpty = (obj) => {
  const keys = Object.keys(obj).filter((key) => obj[key])
  return keys?.length === 0
}

export default function UserDetails({
  editableFields,
  previewFields,
  buttons,
  user,
  onUpdate,
  edit,
  setEdit,
  form: _form,
}) {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const [showError, setShowError] = useState(false)
  const allClientsData = useSelector((data) => data.clients.data)
  const { refetchClientsTableAndSlots } = useOutletContext()
  const [updateUser, { error: updateError }] = useMutation(UPDATE_USER, {
    onCompleted: (data, clientOptions) => {
      const dob = get(data, 'updateUser.dob', undefined)
      const variables = get(clientOptions, 'variables.user', {})
      const newClientsData = allClientsData.map((client) =>
        client.id !== variables.id
          ? client
          : {
              ...client,
              ...variables,
              dob,
            }
      )
      dispatch(setData(newClientsData))
      setEdit(false)
      dispatch(selectUser())
      setErrors({})
      setShowError(false)
      refetchClientsTableAndSlots()
      enqueueSnackbar('Update successful', {
        variant: 'success',
        action: CloseSnackbarAction,
      })
    },
  })
  const [editableFieldsKeys] = useStateWatch(
    () => Object.values(editableFields).map(({ key }) => key),
    [editableFields]
  )

  const {
    form,
    setFormValue,
    setFormData,
    onSubmit,
    pending,
    errors,
    isValid,
    trimmedForm,
    setErrors,
  } = useUserForm({
    user: _form
      ? { ..._form, dob: _form['dob'] ? parseISO(_form['dob']) : undefined }
      : { ...user, dob: user['dob'] ? parseISO(user['dob']) : undefined },
    fields: [...editableFieldsKeys, 'birthYear'], // adding additional fields here for DOB logic
    async onSubmit() {
      setShowError(true)
      if (isEmpty(errors)) {
        const newForm = Object.keys(trimmedForm).reduce((accumulator, k) => {
          if (k === 'age') {
            return accumulator
          }
          return trimmedForm[k]
            ? { ...accumulator, [k]: trimmedForm[k] }
            : { ...accumulator, [k]: null }
        }, {})

        verifyFields(newForm) &&
          (await updateUser({ variables: { user: { id: user.id, ...newForm } } }))
      }
    },
  })
  for (const property in form) {
    if (!form[property]) {
      form[property] = ''
    }
  }

  // we want to sort ssp first then ils second..
  const previewSlotData = user.slots
    ?.filter(({ status }) => ['used', 'assigned'].includes(status))
    .sort((a, b) => {
      if (a.category < b.category) {
        return 1
      }
      if (a.category > b.category) {
        return -1
      }
      return 0
    })
    .map((slot) => transformSlot(slot))

  if (!edit) {
    return (
      <Wrapper gridKey={`grid-${user.id}`} key={`userDetails-wrapper-${user.id}`}>
        <Grid container direction={{ sm: 'row', xs: 'row-reverse' }} width="100%">
          <Preview data={previewFields} />
          {showNewSubscriptionPlan && <PreviewSlot data={previewSlotData} />}
        </Grid>
        <Grid pt={1} pl={3} container>
          <Button
            color="primary"
            data-test="edit-client-button"
            loading={pending}
            onClick={() => {
              setEdit(true)
            }}
          >
            {user.roles.includes('client') ? 'Edit Client' : 'Edit Contact'}
          </Button>
          {buttons.map((CustomButton, index) => (
            <CustomButton
              key={`userdetails-button-${index + 1}`}
              user={user}
              updateUser={async (...args) => {
                try {
                  const res = await updateUser(...args)
                  return res
                } catch (e) {
                  console.error(e)
                  enqueueSnackbar('Server error, try again', {
                    variant: 'error',
                    action: CloseSnackbarAction,
                  })
                }
              }}
              onUpdate={onUpdate}
              setEdit={setEdit}
            />
          ))}
        </Grid>
      </Wrapper>
    )
  }
  const onCancel = () => {
    setEdit(false)
    setFormData({ ...user, dob: user['dob'] ? parseISO(user['dob']) : undefined })
  }

  return (
    <Wrapper gridKey={`grid-${user.id}`} key={`userDetails-wrapper-${user.id}`}>
      {editableFields.map(({ key, label }, index) => {
        if (key === 'gender') {
          return (
            <Grid item my={1} sm={4} key={`editableFields-${index}`}>
              <FormControl fullWidth>
                <InputLabel id="select-gender-input-label">Gender</InputLabel>
                <Select
                  label="Gender"
                  data-test="client-gender-select"
                  labelId="select-gender-label"
                  id="select-gender-id"
                  value={form['gender']}
                  onChange={setFormValue('gender')}
                >
                  <MenuItem data-test="client-gender-select-female" value={'Woman/Girl'}>
                    Woman/Girl
                  </MenuItem>
                  <MenuItem value={'Man/Boy'}>Man/Boy</MenuItem>
                  <MenuItem value={'Non-Binary/Non-Conforming'}>Non-Binary/Non-Conforming</MenuItem>
                  <MenuItem value={'Prefer not to respond'}>Prefer not to respond</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          )
        }

        if (key === 'dob') {
          return (
            <Grid container className="w-full" item sm={12} md={8} key={`editableFields-${index}`}>
              <AgeDropdown
                isClientDetails
                label={label}
                key={key}
                formKey={key}
                errors={errors}
                form={form}
                setFormValue={setFormValue}
                setFormData={setFormData}
                setErrors={setErrors}
              />
            </Grid>
          )
        }

        if (key === 'country') {
          return (
            <Grid item sm={4} key={`editableFields-${index}`}>
              <SelectCountry
                formClassName="my-2 w-full"
                key={key}
                showFullName
                value={form['country']}
                onChange={(event) =>
                  setFormData({ ...form, country: event.target.value, state: null })
                }
                label={'Country'}
              />
            </Grid>
          )
        }

        if (key === 'state') {
          return (
            <Grid item my={1} sm={4} key={`editableFields-${index}`}>
              <SelectState
                key={key}
                showFullStateName
                country={form['country']}
                onChange={setFormValue('state')}
                value={form['state']}
                label={'State/Province/Region'}
                disabled={!form['country']}
              />
            </Grid>
          )
        }

        if (['firstName', 'lastName'].includes(key)) {
          return (
            <Grid item xs={12} sm={6} key={`editableFields-${index}`}>
              <TextField
                fullWidth
                label={label}
                key={key}
                error={errors[key]}
                value={form[key]}
                onChange={setFormValue(key)}
                data-test={`client-${key}-field`}
              />
            </Grid>
          )
        }

        return (
          <Grid item sm={4} key={`editableFields-${index}`}>
            <TextField
              fullWidth
              size="medium"
              label={label}
              key={key}
              id={key}
              error={errors[key]}
              value={form[key]}
              onChange={setFormValue(key)}
              data-test={`client-${key}-field`}
            />
          </Grid>
        )
      })}

      {showError && (!isEmpty(errors) || updateError?.message) && (
        <Grid container key="userdetails-errors">
          <FormHelperText error>
            There was an error updating client Information.
            <br />
            {(!isEmpty(errors) && Object.values(errors)[0]) || updateError?.message}
            <br />
            For assistance, please contact our Client Success team at{' '}
            <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
              {EMAILS.supportEmail}
            </a>
            .
          </FormHelperText>
        </Grid>
      )}

      <Grid className="mt-2" container key="userdetails-buttons">
        <Button
          data-test="save-user-details-button"
          key="userdetails-button-save"
          className="m-1"
          color="primary"
          disabled={!isValid}
          loading={pending}
          onClick={async () => {
            await onSubmit()
          }}
        >
          Save
        </Button>
        <Button
          key="userdetails-button-cancel"
          className="m-1"
          color="secondary"
          disabled={pending}
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Grid>
    </Wrapper>
  )
}
