/**
 * This is currently only used in update client, the fields in form are already filtered with the query
 *
 */
const ALLOWED_IN_UPDATE_FIELDS = [
  'id',
  'address1',
  'address2',
  'birthYear',
  'city',
  'country',
  'dob',
  'firstName',
  'gender',
  'isArchived',
  'isSuspended',
  'lastName',
  'phone',
  'productPreferences',
  'professionalCredentials',
  'providerId',
  'providerProfileInfo',
  'roles',
  'state',
  'timezone',
  'zip',
]

/**
 * Check if all keys in form is valid, and throw an error to sentry if we get invalid object
 */
export const verifyFields = (form) => {
  if (typeof form !== 'object') {
    console.error(`invalid object found`)
    return false
  }

  return Object.keys(form).every((key) => ALLOWED_IN_UPDATE_FIELDS.includes(key))
}
