import React from 'react'
import moment from 'utils/moment'
import { useSelector, useDispatch } from 'react-redux'
import { setForm, setEdit } from 'store/modules/admins'

import UserDetails from '../components/details/UserDetails'
import formats from 'utils/constants/formats'
import { SuspendButton, ArchiveButton } from '../components/buttons'

const ProviderDetails = ({ user, onUpdate }) => {
  const dispatch = useDispatch()
  const form = useSelector((state) => state.admins.form)
  const edit = useSelector((state) => state.admins.edit)

  return (
    <UserDetails
      user={user}
      edit={edit}
      setEdit={(edit) => {
        dispatch(setForm(null))
        dispatch(setEdit(edit))
      }}
      form={form}
      onFormChange={(form) => {
        if (!edit) {
          return
        }
        dispatch(setForm(form))
      }}
      onUpdate={onUpdate}
      previewFields={[
        {
          label: 'Address',
          value: [user.country, user.city, user.state, user.address1, user.address2, user.zip]
            .map((val) => val || '')
            .join(' ')
            .trim(),
        },
        { label: 'Phone', value: user.phone },
        {
          label: 'Date of Birth',
          value: user.dob && moment.withoutTimezone(user.dob).format(formats.date),
        },
        { label: 'Roles', value: user.roles.join(', ') },
      ]}
      editableFields={[
        { key: 'firstName', label: 'First Name*' },
        { key: 'lastName', label: 'Last Name*' },
        { key: 'dob', label: 'Date of Birth' },
        { key: 'address1', label: 'Address 1' },
        { key: 'address2', label: 'Address 2' },
        { key: 'city', label: 'City' },
        { key: 'state', label: 'State' },
        { key: 'zip', label: 'Zip' },
        { key: 'country', label: 'Country' },
        { key: 'phone', label: 'Phone' },
      ]}
      buttons={[
        // EditButton (always visible)
        ArchiveButton,
        SuspendButton,
      ]}
    />
  )
}

export default ProviderDetails
