import React, { useContext, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Grid,
  Link as MuiLink,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { Link, useOutletContext } from 'react-router-dom'
import { Typography } from 'views/dashboard/components/cards/DashboardCard2'
import { get } from 'lodash'
import { ProviderProfileContext } from '../MyProfile'

const PROFILE_FIELD_COLUMNS = [
  {
    title: 'Basic Info',
    fields: [
      {
        label: 'Location (state)',
        checkboxKey: 'showState',
        editState: 'basicInfo',
        fieldKey: 'addresses',
      },
      {
        label: 'Website',
        checkboxKey: 'showWebsite',
        editState: 'basicInfo',
        fieldKey: 'website',
        autoFocusId: 'Website',
      },
      {
        label: 'Bio',
        checkboxKey: 'showBio',
        editState: 'basicInfo',
        fieldKey: 'bio',
        autoFocusId: 'Bio',
      },
    ],
  },
  {
    title: 'Professional Info',
    fields: [
      {
        label: 'Modalities',
        checkboxKey: 'showModalities',
        editState: 'professionalInfo',
        fieldKey: 'modalities',
        autoFocusId: 'I use the following modalities',
      },
      {
        label: 'Clinical Specialties',
        checkboxKey: 'showClinicalSpecialties',
        editState: 'professionalInfo',
        fieldKey: 'clinicalSpecialties',
        autoFocusId: 'I offer support in these clinical specialties in my practice',
      },
      {
        label: 'Population Served',
        checkboxKey: 'showPopulation',
        editState: 'professionalInfo',
        fieldKey: 'population',
        autoFocusId: 'I specialize in working with',
      },
      {
        label: 'Languages',
        checkboxKey: 'showLanguagesSpoken',
        editState: 'professionalInfo',
        fieldKey: 'languagesSpoken',
        autoFocusId: 'I speak the following languages',
      },
    ],
  },
]

export default function CommunityProfileInfo({ form, handleSave, editStates, setEditStates }) {
  const [editForm, setEditForm] = useState({
    showModalities: form.showModalities,
    showClinicalSpecialties: form.showClinicalSpecialties,
    showPopulation: form.showPopulation,
    showLanguagesSpoken: form.showLanguagesSpoken,
    showState: form.showState,
    showWebsite: form.showWebsite,
    showBio: form.showBio,
  })
  const [isEditMode, setIsEditMode] = useState(false)

  const { setAutoFocusLabelName } = useOutletContext()
  const { setWizardState } = useContext(ProviderProfileContext)
  const toggleEditMode = () => setIsEditMode(!isEditMode)

  const handleChange = (checkboxKey) => (event) => {
    setEditForm({ ...editForm, [checkboxKey]: event.target.checked })
  }

  const handleSubmit = () => {
    handleSave(editForm)
    toggleEditMode()
  }

  const handleCancel = () => {
    setEditForm({
      showModalities: form.showModalities,
      showClinicalSpecialties: form.showClinicalSpecialties,
      showPopulation: form.showPopulation,
      showLanguagesSpoken: form.showLanguagesSpoken,
      showState: form.showState,
      showWebsite: form.showWebsite,
      showBio: form.showBio,
    })
    toggleEditMode()
  }

  const handleAddInfo = (state, _fieldName) => () => {
    setEditStates({ ...editStates, [state]: true })
    const fieldName = _fieldName === 'Location (state)' ? 'Address' : _fieldName
    setAutoFocusLabelName(fieldName)
    if (_fieldName === 'Location (state)') {
      setWizardState('BASIC_INFO/EDIT_0')
    }
  }

  const hasJoinedTheCommunity = useSelector((state) =>
    get(state, 'auth.user.providerProfileInfo.betterModeMemberId', false)
  )

  return (
    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
      <Card variant="outlined" className="h-full" elevation={0}>
        <Stack direction="column" p={2} spacing={2}>
          <img src="/images/community/logo.png" alt="CommunityLogo" style={{ maxWidth: '320px' }} />
          {!hasJoinedTheCommunity && (
            <Stack direction="column" spacing={2}>
              <Typography variant="body2">
                Unyte Community is a private space for certified providers to collaborate with
                peers, explore live events, exchange knowledge and much more!
              </Typography>
              <Typography variant="body2">
                See{' '}
                <Link style={{ color: 'initial' }} to="/community">
                  Community
                </Link>{' '}
                in the left menu to join.
              </Typography>
            </Stack>
          )}

          {hasJoinedTheCommunity && (
            <>
              <Typography variant="body2">
                Display this information on my Unyte Community profile:
              </Typography>
              <Stack direction="row" alignItems="flex-start" justifyContent="center" spacing={2}>
                {PROFILE_FIELD_COLUMNS.map((column) => (
                  <FormGroup key={column.title}>
                    <Typography variant="body1" sx={{ fontWeight: '500 !important' }}>
                      {column.title}
                    </Typography>
                    {column.fields.map(
                      ({ label, checkboxKey, editState, fieldKey, autoFocusId }) => (
                        <Stack direction="row" alignItems="flex-start" key={checkboxKey}>
                          <ProfileFieldCheckbox
                            form={form}
                            isEditMode={isEditMode}
                            editForm={editForm}
                            handleChange={handleChange}
                            handleAddInfo={handleAddInfo}
                            checkboxKey={checkboxKey}
                            label={label}
                            editState={editState}
                            fieldKey={fieldKey}
                            autoFocusId={autoFocusId}
                          />
                        </Stack>
                      )
                    )}
                  </FormGroup>
                ))}
              </Stack>
              <div>
                {!isEditMode && <Button onClick={toggleEditMode}>Edit Fields to Display</Button>}
                {isEditMode && (
                  <Stack direction="row" justifyContent="space-around" p={2}>
                    <Button color="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button onClick={handleSubmit}>Save</Button>
                  </Stack>
                )}
              </div>
            </>
          )}
        </Stack>
      </Card>
    </Grid>
  )
}

function ProfileFieldCheckbox({
  form,
  isEditMode,
  editForm,
  handleChange,
  handleAddInfo,
  checkboxKey,
  label,
  editState,
  fieldKey,
  autoFocusId,
}) {
  const fieldIsEmpty = !form[fieldKey]?.length

  return (
    <FormControlLabel
      disabled={fieldIsEmpty || !isEditMode}
      control={<Checkbox checked={editForm[checkboxKey]} onChange={handleChange(checkboxKey)} />}
      label={
        <>
          {label}
          {fieldIsEmpty && (
            <Typography variant="body1" pl={1} component="span">
              <MuiLink color="inherit" onClick={handleAddInfo(editState, autoFocusId)} href="#">
                add info
              </MuiLink>
            </Typography>
          )}
        </>
      }
    />
  )
}
