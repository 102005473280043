import React, { useState } from 'react'
import { Button } from '@mui/material'
import ManageSeatsModal from '../modals/ManageSeats'
import { useSelector } from 'react-redux'

export default function ManageSeatsButton({ user, onUpdate }) {
  const [showModal, setModalState] = useState(false)
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  return (
    <>
      <Button
        data-test="manage-remote-delivery"
        className="m-1"
        onClick={() => setModalState(true)}
        disabled={user.isSuspended}
      >
        {showNewSubscriptionPlan ? 'Manage Delivery' : 'Manage Remote Delivery'}
      </Button>
      <ManageSeatsModal
        open={showModal}
        user={user}
        onClose={() => {
          setModalState(false)
        }}
        refetchSeats={onUpdate}
      />
    </>
  )
}
