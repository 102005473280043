import React, { useState } from 'react'
import { Button, Stack, Typography, Tooltip, Checkbox, Link } from '@mui/material'
import LoadingPage from 'components/LoadingPage'
import MainLayout from 'components/containers/main/Main'
import CommunityBackgroundImage from './community_background_image.svg'
import { useMutation } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { UPDATE_PROVIDER_PROFILE_INFO } from './queries'
import { extendUser } from 'store/modules/auth'
import Community from './Community'

export default function JoinToMyUnyteCommunity() {
  const [loading, setLoading] = useState(false)
  const [isCommunityTosAccepted, setIsCommunityTosAccepted] = useState(false)
  const user = useSelector((state) => state?.auth?.user)
  const dispatch = useDispatch()

  const [updateProviderProfileInfo] = useMutation(UPDATE_PROVIDER_PROFILE_INFO)

  const handleJoin = async () => {
    setLoading(true)
    const newProviderProfileInfo = { ...user.providerProfileInfo, isCommunityTosAccepted: true }
    await updateProviderProfileInfo({
      variables: {
        user: {
          id: user.id,
          providerProfileInfo: newProviderProfileInfo,
        },
      },
      onCompleted: () => {
        dispatch(
          extendUser({
            ...user,
            providerProfileInfo: newProviderProfileInfo,
          })
        )
        setLoading(false)
      },
    })

    return <Community />
  }

  return (
    <LoadingPage text="loading..." loading={loading}>
      <MainLayout title="Community">
        <Stack
          alignItems="center"
          style={{
            backgroundImage: `url(${CommunityBackgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            height: 'calc(100vh - 150px)', // subtract header from main layout to remove scrolling
          }}
          pt={6}
        >
          <Stack direction="column" spacing={2} sx={{ maxWidth: '48rem' }} alignItems="center">
            <Typography variant="h3" align="center" fontWeight={500}>
              Join Your Community!
            </Typography>
            <Typography variant="body1" align="center">
              Unyte Community is a private space for certified providers to collaborate
              <br />
              with peers, explore live events, exchange knowledge and much more!
            </Typography>
            <Stack direction="row" justifyContent="center" alignItems="center" pt={8} spacing={2}>
              <Checkbox
                color="primary"
                checked={isCommunityTosAccepted}
                onClick={() => setIsCommunityTosAccepted(!isCommunityTosAccepted)}
              />
              <Stack direction="column">
                <Typography variant="body2">
                  I accept the{' '}
                  <Link
                    href="https://integratedlistening.com/about/community-terms-of-use"
                    target="_blank"
                    rel="noreferrer"
                    color="inherit"
                  >
                    Unyte Community Terms of Use
                  </Link>
                </Typography>
                <Typography variant="body2">
                  and the{' '}
                  <Link
                    href="https://integratedlistening.com/about/community-privacy-notice"
                    target="_blank"
                    rel="noreferrer"
                    color="inherit"
                  >
                    Unyte Community Privacy Notice
                  </Link>
                  .
                </Typography>
              </Stack>
            </Stack>
            {!isCommunityTosAccepted && (
              <Tooltip title="Please check the checkbox above in order to join.">
                <span>
                  <Button
                    color="primary"
                    variant="contanined"
                    onClick={handleJoin}
                    disabled
                    sx={{
                      '&.Mui-disabled': {
                        background: '#acd8c4',
                        color: '#fff',
                      },
                    }}
                  >
                    Join Unyte Community
                  </Button>
                </span>
              </Tooltip>
            )}
            {isCommunityTosAccepted && (
              <Button variant="contained" onClick={handleJoin}>
                Join Unyte Community
              </Button>
            )}
          </Stack>
        </Stack>
      </MainLayout>
    </LoadingPage>
  )
}
