import React from 'react'
import { SearchInput, Switch } from 'components'
import { useStateWatch } from 'hooks'

const Search = ({
  checked: _checked = false,
  onSwitchChange = () => null,

  search: _search = '',
  onSearchChange = () => null,

  placeholder,
  uncheckedText,
  hideSwitch,
  width = '40%',
}) => {
  const [search, setSearch] = useStateWatch(() => _search, [_search])
  const [checked, setChecked] = useStateWatch(() => _checked, [_checked])

  const Label = ({ text }) => {
    return <span style={{ fontSize: '0.85rem' }}>{text}</span>
  }

  return (
    <>
      {!hideSwitch && (
        <>
          <Label text={uncheckedText} />
          <Switch
            color="primary"
            checked={checked}
            onChange={() => {
              setChecked(!checked)
              onSwitchChange(!checked)
            }}
          />
        </>
      )}
      <SearchInput
        value={search}
        onChange={(e) => {
          const val = e.target.value
          setSearch(val)
          onSearchChange(val)
        }}
        placeholder={placeholder}
        width={width}
      />
    </>
  )
}

export const UsersSearch = (props) => (
  <Search
    placeholder="Search by first name, last name, or email."
    checkedText="Current"
    uncheckedText="Show Archived"
    {...props}
  />
)

export const InvitationsSearch = (props) => (
  <Search
    placeholder="Search by email"
    checkedText="Pending"
    uncheckedText="Show Accepted"
    {...props}
  />
)

export default Search
