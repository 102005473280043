/**
 * In this file we will dynamically choose outlined input or normal input
 * - mainly for passwords
 */

import { Input, OutlinedInput } from '@mui/material'
import React from 'react'

export default function CustomInput({ children, ...props }) {
  return process.env.REACT_APP_TEXTFIELD_VARIANT === 'outlined' ? (
    <OutlinedInput {...props}>{children}</OutlinedInput>
  ) : (
    <Input {...props}>{children}</Input>
  )
}
