import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default () => {
  const location = useLocation()

  useEffect(() => {
    if (!process.env.REACT_APP_MOUSEFLOW_PROJECT_ID) return

    // if mouseflow isn't initiated, initiate..
    window._mfq = window._mfq || []
    let mf = document.createElement('script')
    mf.type = 'text/javascript'
    mf.defer = true
    mf.src = `//cdn.mouseflow.com/projects/${process.env.REACT_APP_MOUSEFLOW_PROJECT_ID}.js`
    document.getElementsByTagName('head')[0].appendChild(mf)

    // listen to location changes and update
    window._mfq.push(['newPageView', location.pathname])

    // eslint-disable-next-line
  }, [location])

  return null
}
