const IN_PROGRESS = 'In Progress'
const SENT_TO_CLIENT = 'Sent to Client'
const IN_PROGRESS_BY_CLIENT = 'In Progress by Client'
const COMPLETED = 'Completed'

export const ASSESSMENT_STATUS = {
  IN_PROGRESS,
  SENT_TO_CLIENT,
  IN_PROGRESS_BY_CLIENT,
  COMPLETED,

  allStatuses: [IN_PROGRESS, SENT_TO_CLIENT, IN_PROGRESS_BY_CLIENT, COMPLETED],
  isSent: (status) => [SENT_TO_CLIENT, IN_PROGRESS_BY_CLIENT].includes(status),
  isInProgress: (status) => status === IN_PROGRESS,
  isInProgressByClient: (status) => status === IN_PROGRESS_BY_CLIENT,
  isCompleted: (status) => status === COMPLETED,
}
