import React from 'react'
import moment from 'utils/moment'
import { Active, Stack, Typography } from 'components'
import formatMoney from 'views/new-purchase/utils/formatMoney'
import LINKS from 'utils/constants/links'
import DATE_FORMAT from 'utils/constants/formats'
import {
  ALL_FOCUS_SUBSCRIPTIONS_SKUS,
  ALL_RRP_SUBSCRIPTION_SKUS,
  ALL_SSP_SUBSCRIPTION_SKUS,
} from 'utils/constants/prices'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const skuToPlanSlotsAmount = {
  sspYearToYear_Yearly: 25,
  sspMonthToMonth_Yearly: 25,
  sspMonthToMonth_3Monthly: 6,
  focusYearToYear_Yearly: 25,
  focusMonthToMonth_Yearly: 25,
  rrpYearToYear_Yearly: 25,
  rrpMonthToMonth_Yearly: 25,
  rrpMonthToMonth_3Monthly: 6,
}

const ChangeOrCancel = () => (
  <a
    href={LINKS.billing.changeOrCancel}
    className="underline text-link font-normal"
    target="_blank"
    rel="noopener noreferrer"
  >
    change
  </a>
)

export default function PlanInformation({
  subscription,
  showNewSSPSubscriptionPlan,
  showNewFocusSubscriptionPlan,
}) {
  const {
    currentProductStartedDate,
    currentPlanRenewalDate,
    amount,
    interval,
    sku,
    cancelAtDate,
  } = subscription

  const hasSspSubscription = ALL_SSP_SUBSCRIPTION_SKUS.includes(sku)
  const hasFocusSubscription = ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(sku)
  const hasRrpSubscription = ALL_RRP_SUBSCRIPTION_SKUS.includes(sku)

  const activeClients = () => {
    if (hasSspSubscription) {
      if (showNewSSPSubscriptionPlan === true) {
        return (
          <>
            <Typography variant="body2">
              Your base plan includes {skuToPlanSlotsAmount[subscription.sku]} client licenses per
              subscription term.
            </Typography>
          </>
        )
      }
      return (
        <>
          <Typography variant="body2">
            Your plan includes 5 <Active strong /> clients.
          </Typography>
          <Typography variant="body2">
            Additional <Active strong /> clients are billed at $10 per <Active strong /> client.
          </Typography>
        </>
      )
    } else if (hasFocusSubscription) {
      if (showNewFocusSubscriptionPlan === true) {
        return (
          <>
            <Typography variant="body2">
              Your base plan includes {skuToPlanSlotsAmount[subscription.sku]} client licenses per
              subscription term.
            </Typography>
          </>
        )
      }
      return (
        <Typography variant="body2">
          Your plan includes 50 ILS program <Active product="focus" strong /> per subscription term.
        </Typography>
      )
    } else if (hasRrpSubscription) {
      return (
        <>
          <Typography variant="body2">
            Your base plan includes {skuToPlanSlotsAmount[subscription.sku]} client licenses per
            subscription term.
          </Typography>
        </>
      )
    }
  }

  const getTitle = ({ hasSspSubscription, hasFocusSubscription, hasRrpSubscription }) => {
    if (hasSspSubscription) {
      return 'Safe and Sound Protocol (SSP) Subscription'
    } else if (hasFocusSubscription) {
      return 'Integrated Listening System (ILS) Subscription'
    } else if (hasRrpSubscription) {
      return 'Rest and Restore Protocol™ (RRP) Subscription'
    }
  }
  const title = getTitle({ hasSspSubscription, hasFocusSubscription, hasRrpSubscription })

  const user = useSelector((state) => get(state, 'auth.user', {}))
  const { firstName, lastName, email } = user
  const hubspotPopulatedForm = `${LINKS.billing.hubspotCancellationRequestForm}?firstname=${firstName}&lastname=${lastName}&email=${email}`

  return (
    <Stack className="pb-6" direction="column">
      <Typography variant="h5">{title}</Typography>
      <Typography variant="body2">
        Your plan started {moment(currentProductStartedDate, 'MM/DD/YYYY').format(DATE_FORMAT.date)}
        .
      </Typography>
      {currentPlanRenewalDate && !cancelAtDate && (
        <Typography variant="body2">
          Your plan automatically renews on{' '}
          {moment(currentPlanRenewalDate, 'MM/DD/YYYY').format(DATE_FORMAT.date)}.
        </Typography>
      )}
      <Typography variant="body2">
        You are billed at {formatMoney(amount / 100)}/{interval} + tax.
      </Typography>
      {activeClients()}
      {cancelAtDate && (
        <Typography variant="body2">
          Your subscription will cancel on{' '}
          {moment(cancelAtDate, 'MM/DD/YYYY').format(DATE_FORMAT.date)}
        </Typography>
      )}
      <Typography variant="body2">
        How do I <ChangeOrCancel /> my subscription?
      </Typography>
      <Typography variant="body2">
        To submit your request to cancel your subscription please complete{' '}
        <a
          href={hubspotPopulatedForm}
          className="underline text-link font-normal"
          target="_blank"
          rel="noopener noreferrer"
        >
          this form
        </a>
        .
      </Typography>
      <Typography variant="body2">
        Your term end date is noted above - cancellations will be processed to take effect at the
        end of the subscription term.
      </Typography>
    </Stack>
  )
}
