// we need to export here, because data.ts (js compatible) is not data.d.ts (not js compatible)
export interface HeadCellsProps {
  id: string
  numeric: boolean
  disablePadding: boolean
  label: string
  hideIcon?: boolean
}

export interface EnhancedTableHeadProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void
  order: 'asc' | 'desc' // This replaces PropTypes.oneOf
  orderBy: string
  headCells: HeadCellsProps[]
}

// table head cells
export const HEAD_CELLS = [
  { id: 'fullName', numeric: false, disablePadding: false, label: 'Client' },
  { id: 'sortAssessmentName', numeric: false, disablePadding: false, label: 'Assessment' },
  { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created Date' },
  { id: 'updatedAt', numeric: false, disablePadding: false, label: 'Last Updated' },
  { id: 'score', numeric: false, disablePadding: false, label: 'Score', hideIcon: true },
  { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
] as HeadCellsProps[]
