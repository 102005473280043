import React from 'react'
import { Container, Grid } from '@mui/material'
import SessionsChart from './client/SessionsChart'
import { useOutletContext } from 'react-router'

export default function ProviderClientDashboard() {
  const { selectedUser } = useOutletContext()
  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} alignItems="stretch" m={0}>
        <SessionsChart user={selectedUser} />
      </Grid>
    </Container>
  )
}
