import React from 'react'
import ClientConnectionIcon from './unyte-logo-clientconnections.svg'
import { Avatar } from '@mui/material'

export default function ClientConnectionsAvatar({ marginRight, height = '100%' }) {
  return (
    <Avatar
      variant="square"
      src={ClientConnectionIcon}
      alt="client-connection-icon"
      sx={{
        width: '100%',
        backgroundColor: 'transparent',
        aspectRatio: '6.75 / 1',
        objectFit: 'cover',
        marginRight,
        height,
      }}
    />
  )
}
