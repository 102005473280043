import React from 'react'
import { useSelector } from 'react-redux'

export const ClientName = () => {
  const assessmentUserFullName = useSelector((state) => state.assessments.fullName)
  const assessmentUserEmail = useSelector((state) => state.assessments.email)

  const name = assessmentUserEmail
    ? `${assessmentUserFullName} (${assessmentUserEmail})`
    : assessmentUserFullName
  return (
    <>
      {name ? ' | ' : ''}
      <span className={'text-link font-semibold'}>{name}</span>
    </>
  )
}

export const AssessmentsGettingStartedTitle = () => {
  return (
    <div>
      Getting Started with{' '}
      <span style={{ fontWeight: 500, color: 'var(--green)' }}>Unyte Assessments</span>!
    </div>
  )
}

export default function AssessmentsTitle() {
  return <div data-test="assessment-title">Assessments</div>
}
