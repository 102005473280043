import React, { useState } from 'react'
import { Button } from 'components'
import AddIcon from '@mui/icons-material/Add'

import AddCard from './AddCard'

export default function AddPaymentMethod({ refetch, buttonProps, isCardsEmpty }) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Button
        disableElevation
        className="mx-2 my-5"
        variant="contained"
        color="primary"
        startIcon={<AddIcon />}
        onClick={() => setOpen(true)}
        {...buttonProps}
      >
        Add Payment Method
      </Button>
      <AddCard setOpen={setOpen} open={open} refetch={refetch} isCardsEmpty={isCardsEmpty} />
    </>
  )
}
