import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { matchPath } from 'react-router-dom'

export const ACTIONS = {
  [ASSESSMENT_STATUS.COMPLETED]: { message: 'Assessment saved successfully', variant: 'success' },
  [ASSESSMENT_STATUS.IN_PROGRESS]: { message: 'Assessment saved successfully', variant: 'success' },
  [ASSESSMENT_STATUS.SENT_TO_CLIENT]: { message: 'Assessment sent to client', variant: 'success' },
  [ASSESSMENT_STATUS.IN_PROGRESS_BY_CLIENT]: {
    message: 'Assessment in progress by client',
    variant: 'success',
  },
  error: { message: 'Something went wrong - please try again later', variant: 'error' },
}

export const tabs = [
  {
    text: 'Assessments',
    url: '/assessments',
    pathPattern: '/assessments/:userId',
    state: { hideGettingStarted: true },
    exact: true,
    isActive: (pathname = '') => {
      return (
        !matchPath({ path: '/assessments/reports/*' }, pathname) &&
        matchPath({ path: '/assessments/*' }, pathname)
      )
    },
  },
]

export const NEW_UPDATE_VIDEO = '//player.vimeo.com/video/610039729'
export const RESOURCE_INTRO_VIDEO = '//player.vimeo.com/video/649132437'
export const ASSESSMENT_BEST_PRACTICES_VIDEO = '//player.vimeo.com/video/838105256'

export const ASSESSMENTS_EMAIL = 'assessments@unyte.com'

export const PAGELOADER_TIMER = 1250

export const SURVEY_CSS = {
  body: 'stack',
  question: {
    mainRoot: 'mainRoot pageRoot bbcss--mainRoot',
    title: 'custom--h5 MuiTypography-h5',
    header: 'questionHeader',
    content: 'sv-question__content w-full',
  },
  navigationButton: 'contained-button',
  navigation: {
    next: 'contained-button ml-auto',
    prev: 'contained-button mr-auto',
    complete: 'contained-button ml-auto',
  },
  page: {
    root: 'sv-page sv-page__body w-full',
    title: 'my-5 custom--h4 MuiTypography-h4',
  },
  rating: {
    item: 'outlined-button', // striped from css of outlined button
    selected: 'contained-button',
    itemText: 'MuiTypography-button text-center',
  },
  footer: 'w-full p-0 m-0 ',
  progress: 'sv-progress sv-body__progres w-full',
  progressTextUnderBar: 'progressMargin',
}
export const INTAKE_CSS = {
  navigation: {
    next: 'intake--nav-button floatRight',
    prev: 'intake--nav-button floatLeft',
    complete: 'intake--nav-button floatRight',
  },
  rating: {
    root: 'ratingContainer',
    item: 'MuiButtonBase-root MuiButton-root MuiButton-outlined buttonOutlined customButton',
    itemText: 'MuiTypography-button',
  },
  page: {
    root: 'intake--pageRoot',
    title: 'custom--h5 MuiTypography-h5',
  },
  panel: {
    description: 'MuiTypography-body1 text-gray-400',
  },
  checkbox: {
    controlLabel: 'intake--checkbox-controlLabel',
    label: 'intake--checkbox-label',
  },
  matrixdropdown: {
    cell: 'intake-flex-center',
  },
  footer: 'p-0 m-0',
  progressTextUnderBar: 'progressMargin',
  matrixdynamic: {
    headerCell: 'p-0',
    actionsCell: 'intake-flex-center',
  },
}

export const COMPLETED_CSS = {
  panel: {
    title: 'completed_panel',
    description: 'completed_panel_description',
  },
  question: {
    title: 'completed_question',
  },
  rating: {
    itemText: 'completed_rating',
    selected: 'completed_selected',
  },
  row: 'completed_row',
}
