const SELECT_USER = 'CLIENTS/SELECT_USER'
const SET_EDIT = 'CLIENTS/SET_EDIT'
const SET_FORM = 'CLIENTS/SET_FORM'
const SET_DATA = 'CLIENTS/SET_DATA'
const SET_SESSIONS = 'CLIENTS/SET_SESSIONS'
const RESET_CLIENTS = 'CLIENTS/RESET_CLIENTS'
const SET_SLOTS = 'CLIENTS/SET_SLOTS'

const initialState = {
  selectedUser: null,
  edit: false,
  form: null,
  data: [],
  sessions: [],
  availableSspSlots: 0,
  availableRrpSlots: 0,
  availableFocusSlots: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload,
      }
    case SET_EDIT:
      return {
        ...state,
        edit: action.payload,
      }
    case SET_FORM:
      return {
        ...state,
        form: action.payload,
      }
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case SET_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      }
    case SET_SLOTS:
      return {
        ...state,
        availableSspSlots: action.payload.availableSspSlots,
        availableRrpSlots: action.payload.availableRrpSlots,
        availableFocusSlots: action.payload.availableFocusSlots,
      }
    case RESET_CLIENTS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function selectUser(payload) {
  return {
    type: SELECT_USER,
    payload,
  }
}

export function setEdit(payload) {
  return {
    type: SET_EDIT,
    payload,
  }
}
export function setForm(payload) {
  return {
    type: SET_FORM,
    payload,
  }
}
export function setData(payload) {
  return {
    type: SET_DATA,
    payload,
  }
}
export function setSession(payload) {
  return {
    type: SET_SESSIONS,
    payload,
  }
}
export function resetClients() {
  return {
    type: RESET_CLIENTS,
  }
}

export function setClientsSlots(payload) {
  return {
    type: SET_SLOTS,
    payload,
  }
}
