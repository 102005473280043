import React from 'react'
import moment from 'moment'
import { Button } from 'components'

export default function({ seedDate, setFormValue }) {
  const contactInfoPrefix = localStorage.getItem(`contactInfoPrefix`)
  if (!(contactInfoPrefix && process.env.REACT_APP_STAGE === 'test')) {
    return null
  }
  const fillOutForm = () => {
    const contactInfoPrefix = localStorage.getItem(`contactInfoPrefix`)
    const date = moment(seedDate)
    const accountPrefix = `${contactInfoPrefix}+${date.format('DDMMMYYHmm')}`

    const overrideValues = {
      adminFirstName: accountPrefix,
      adminLastName: accountPrefix,
      adminEmail: `${accountPrefix}@unyte.com`.toLowerCase(),
    }
    setFormValue('', 'target.value', overrideValues)()
  }
  return (
    <Button type="button" variant="contained" color="primary" onClick={fillOutForm}>
      Fill in test data
    </Button>
  )
}
