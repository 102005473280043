import { combineReducers, createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from '@redux-devtools/extension'
import * as Sentry from '@sentry/react'

import thunk from 'redux-thunk'

import app from './modules/app'
import auth from './modules/auth'
import clients from './modules/clients'
import providers from './modules/providers'
import admins from './modules/admins'
import newPurchase from './modules/new-purchase'
import ff from './modules/ff'
import updateNotification from './modules/updateNotification'
import creditCard from './modules/credit-card'
import assessments from './modules/assessments'
import resources from './modules/resources'
import organization from './modules/organization'
import sessions from './modules/sessions'
import activeClients from './modules/activeClients'
import seats from './modules/seats'
import licenses from './modules/licenses'
import hubspot from './modules/hubspot'

// libraries for saving to localstorage
import { loadState, saveState } from '../utils/hooks/useLocalStorage'
import throttle from 'lodash/throttle'

// load local state into redux
const persistedState = loadState()
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => ({
    type: action.type,
  }),
  stateTransformer: (state) => ({
    uuid: state?.auth?.user?.uuid,
    assessments: { id: state?.assessments?.id },
  }),
})

// do not have import - this will load old state
// do not have persist - this will load old state
const composedEnhancers = composeWithDevTools({
  features: {
    pause: process.env.REACT_APP_STAGE === 'test', // start/pause recording of dispatched actions
    lock: process.env.REACT_APP_STAGE === 'test', // lock/unlock dispatching actions and side effects
    export: process.env.REACT_APP_STAGE === 'test', // export history of actions in a file
    jump: process.env.REACT_APP_STAGE === 'test', // jump back and forth (time travelling)
    skip: process.env.REACT_APP_STAGE === 'test', // skip (cancel) actions
    reorder: process.env.REACT_APP_STAGE === 'test', // drag and drop actions in the history list
    dispatch: process.env.REACT_APP_STAGE === 'test', // dispatch custom actions or action creators
    test: process.env.REACT_APP_STAGE === 'test', // generate tests for the selected actions
  },
  maxAge: process.env.REACT_APP_STAGE === 'test' ? 50 : 2, // need 2 because 1 is for initializing
})

export const store = createStore(
  combineReducers({
    ff,
    auth,
    app,
    clients,
    providers,
    admins,
    newPurchase,
    updateNotification,
    creditCard,
    assessments,
    resources,
    organization,
    sessions,
    activeClients,
    seats,
    licenses,
    hubspot,
  }),
  persistedState,
  composedEnhancers(applyMiddleware(thunk), sentryReduxEnhancer)
)

store.subscribe(
  throttle(() => {
    saveState({
      resources: store.getState().resources,
      updateNotification: store.getState().updateNotification,
      newPurchase: { data: { ...store.getState().newPurchase.data } },
    })
  }, 1000)
)
