import React from 'react'
import { Outlet, matchPath } from 'react-router'
import MainLayout from 'components/containers/main/Main'

export default function Admins() {
  const tabs = [
    {
      text: 'Admins',
      url: '/admins',
      isActive: (pathname) => matchPath({ path: '/admins' }, pathname),
    },
    {
      text: 'Pending Invitations',
      url: '/admins/pending-invitations',
      isActive: (pathname) => matchPath({ path: '/admins/pending-invitations' }, pathname),
    },
  ]
  return (
    <MainLayout title="Admins" tabs={tabs}>
      <Outlet />
    </MainLayout>
  )
}
