import React from 'react'

import SortLabel from './SortLabel'

import { Menu, MenuItem } from '@mui/material'

export default ({ onSort, sort }) => {
  const [clientsEl, setClientsEl] = React.useState(null)
  const labels = {
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
  }
  const [key] = sort[0] || []
  const nameFilterApplied = Object.keys(labels).includes(key)
  return (
    <React.Fragment>
      <div onClick={(e) => setClientsEl(e.currentTarget)}>
        <SortLabel
          sort={sort}
          title={nameFilterApplied ? `Name (${labels[key]})` : 'Name'}
          sortKey={nameFilterApplied ? key : undefined}
        >
          &nbsp;
        </SortLabel>
      </div>
      <Menu
        anchorEl={clientsEl}
        keepMounted
        open={!!clientsEl}
        onClick={() => setClientsEl(null)}
        onClose={() => setClientsEl(null)}
      >
        <MenuItem>
          <SortLabel sort={sort} title="First name" sortKey="firstName" onChange={onSort} />
        </MenuItem>
        <MenuItem>
          <SortLabel sort={sort} title="Last name" sortKey="lastName" onChange={onSort} />
        </MenuItem>
        <MenuItem>
          <SortLabel sort={sort} title="Email" sortKey="email" onChange={onSort} />
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}
