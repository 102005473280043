import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import { Grid, Typography } from 'components'
import makeStyles from '@mui/styles/makeStyles'

import MainLayout from 'components/containers/main/Main'
import { isRrpCertified } from 'utils/permissions/permissionsLogic'
import ProductCard from './ProductCard'
import PathwayCards from './PathwayCards'
import PlaylistCards from './PlaylistCards'
import { isClient, showSspPathwayCards } from '../../utils/permissions/permissionsLogic'

const useStyles = makeStyles({
  grid: {
    justifyContent: 'left',
    padding: 20,
    paddingTop: 15,
  },
  sectionDescription: {
    textAlign: 'left',
    margin: '0 0 0 10px',
  },
  pathwayTitle: {
    fontSize: '1.3rem',
    fontWeight: 400,
    textAlign: 'left',
    margin: '20px 0 0 10px',
  },
  sspProductTitle: {
    marginLeft: '10px',
  },
  productTitle: {
    margin: '40px 0 10px 10px',
  },
})

function ProductCards({ expandedCard, setExpandedCard, productData }) {
  return productData
    ?.sort((a, b) => a.order - b.order)
    .map(({ name, description, metadata, id }, index) => (
      <ProductCard
        name={name}
        id={id}
        key={id}
        description={description}
        metadata={metadata}
        index={index}
        cardType={'product'}
        expandedCard={expandedCard}
        setExpandedCard={setExpandedCard}
      />
    ))
}

export default function Products() {
  const classes = useStyles()
  const data = useSelector((state) => state.seats.products)
  const organization = useSelector((state) => state.organization)
  const authUser = useSelector((state) => state?.auth?.user)
  const rrpCertified = isRrpCertified({ authUser })
  const showSspPathway = showSspPathwayCards({ authUser, organization })
  const [expandedCard, setExpandedCard] = useState('')

  const sspCards = data?.filter((item) => item?.category === 'ssp')
  const ilsCards = data?.filter((item) => item?.category === 'focus')
  const clientRole = isClient({ authUser })
  const rrpCards = data?.filter((item) => item?.category === 'rrp' && (clientRole || rrpCertified))

  const cardsToDisplay = [...sspCards, ...ilsCards, ...rrpCards]

  if (!cardsToDisplay.length && clientRole) {
    return (
      <MainLayout title="Programs" className={classes.grid}>
        <Grid container className="p-5">
          <div>
            <Typography gutterBottom variant="body2" className="py-2">
              Sorry but you currently don't have access to any available programs.
            </Typography>
            <Typography gutterBottom variant="body2" className="py-2">
              Please contact your Provider for more details.
            </Typography>
          </div>
        </Grid>
      </MainLayout>
    )
  } else {
    return (
      <MainLayout title="Programs" className={classes.grid}>
        <Grid container className="p-5">
          {sspCards.length > 0 && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography className={classes.sspProductTitle} variant="h5">
                  Safe and Sound Protocol (SSP)
                </Typography>
              </Grid>
              {showSspPathway && (
                <>
                  <Grid item xs={12} md={12} lg={12}>
                    <Typography className={classes.pathwayTitle} fontWeight="fontWeightBold">
                      Pathways
                    </Typography>
                    <Typography className={classes.sectionDescription} variant="body2">
                      Below are the three SSP pathways you can use with clients. Each pathway has
                      unique qualities, recommended uses and offers options to work with client’s
                      varied nervous systems. Refer to your training knowledge when selecting a
                      pathway.
                    </Typography>
                  </Grid>
                  <PathwayCards />
                </>
              )}
              <Grid item xs={12} md={12} lg={12}>
                <Typography className={classes.pathwayTitle}>Playlists</Typography>
                <Typography className={classes.sectionDescription} variant="body2">
                  A collection of songs from various genres that underlie the SSP filtration
                  algorithm.
                </Typography>
              </Grid>
              <PlaylistCards
                clientRole={clientRole}
                expandedCard={expandedCard}
                setExpandedCard={setExpandedCard}
              />
            </>
          )}
          {ilsCards.length > 0 && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography className={classes.productTitle} variant="h5">
                  Integrated Listening Systems (ILS)
                </Typography>
              </Grid>
              <ProductCards
                expandedCard={expandedCard}
                setExpandedCard={setExpandedCard}
                productData={ilsCards}
              />
            </>
          )}
          {rrpCards.length > 0 && (
            <>
              <Grid item xs={12} md={12} lg={12}>
                <Typography className={classes.productTitle} variant="h5">
                  Rest and Restore Protocol (RRP)
                </Typography>
              </Grid>
              <ProductCards
                expandedCard={expandedCard}
                setExpandedCard={setExpandedCard}
                productData={rrpCards}
              />
            </>
          )}
        </Grid>
      </MainLayout>
    )
  }
}
