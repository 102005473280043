import React from 'react'
import Typography from '@mui/material/Typography'
import { Box, Stack } from 'components'
import CircularProgress from '@mui/material/CircularProgress'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

interface StatusProps {
  status: string
  progress: number
}

export const Status = ({ status, progress }: StatusProps) => {
  const { isInProgress, isCompleted, isSent, isInProgressByClient } = ASSESSMENT_STATUS

  if (isInProgressByClient(status) || isInProgress(status)) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box sx={{ position: 'relative', display: 'inline-flex', width: '32px', height: '32px' }}>
          {/* Gray circle as the background */}
          <CircularProgress
            variant="determinate"
            value={100} // Always full to act as a background
            size="2rem"
            sx={{ color: '#e0e0e0', position: 'absolute', zIndex: 1 }} // gray background, positioned absolutely
          />
          {/* Your original progress indicator on top */}
          <CircularProgress
            variant="determinate"
            value={progress}
            size="2rem"
            sx={{ color: '#E76F51', position: 'absolute', zIndex: 2 }} // Original color, positioned absolutely on top of the gray circle
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 3, // Ensure text appears on top of both circles
              width: '32px',
            }}
          >
            <Typography
              variant="caption"
              fontSize="0.6rem"
              component="div"
              sx={{ color: '#E76F51' }}
            >{`${Math.round(progress)}%`}</Typography>
          </Box>
        </Box>
        <Typography variant="body2" sx={{ color: '#E76F51' }}>
          {isInProgressByClient(status) ? 'In Progress by Client' : 'In Progress by Provider'}
        </Typography>
      </Stack>
    )
  } else if (isSent(status)) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <AccessTimeIcon sx={{ color: '#3784AD' }} fontSize="large" />
        <Typography sx={{ color: '#3784AD' }} variant="body2">
          Sent to client
        </Typography>
      </Stack>
    )
  } else if (isCompleted(status)) {
    return (
      <Stack direction="row" alignItems="center" spacing={1}>
        <CheckCircleOutlineIcon sx={{ color: '#65C2B8' }} fontSize="large" />
        <Typography sx={{ color: '#65C2B8' }} variant="body2">
          Completed
        </Typography>
      </Stack>
    )
  }

  return null
}
