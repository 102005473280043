/**
 * Message content for clients page when organization has converted from old plan to new plan
 */
import React from 'react'
import {
    Grid,
    Typography,
    ImageListItem
} from 'components'


interface HowClientLicensesWorkMessageProps {
    licenseText: string
}

export const HowClientLicensesWorkMessage: React.FC<HowClientLicensesWorkMessageProps> = ({
    licenseText
}) => {
    return (
        <Grid container>
        <Grid item container xs={12} sm={10} md={9} m="auto">
          <Typography variant="body1" my={2} fontWeight={500}>
            A client requires a license in order to use {licenseText}.
          </Typography>
          <Typography variant="body1" gutterBottom>
            When you assign a program, a license also gets assigned to the client.
          </Typography>
          <Typography variant="body1" mb={2}>
            Once a client listens, the license is "in use" and belongs to that client for 12 months:
          </Typography>
          <ImageListItem sx={{ height: '300', width: '100%'}}>
            <img
              src="/images/dashboard/HowALicenseWork.png"
              alt="how-a-license-work"
              loading="lazy"
            />
          </ImageListItem>
          <Typography variant="body1" mt={2}>
            If the client does not use the program at all, the license can later be unassigned.
          </Typography>
        </Grid>
      </Grid>
    )
}