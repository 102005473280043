const SET_CURRENT_SESSION = 'UPDATE_NOTIFICATION/SET_CURRENT_SESSION'
const SET_SAVE_CLOSE_SESSION = 'UPDATE_NOTIFICATION/SET_SAVE_CLOSE_SESSION'

// any states below that are true means that we saved it and it won't show again
const initialState = {
  currentSession: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_SESSION:
      return {
        ...state,
        currentSession: true,
      }
    case SET_SAVE_CLOSE_SESSION:
      return {
        ...state,
        [action.payload]: true,
      }
    default:
      return state
  }
}

export function setSaveCloseSession(payload) {
  return {
    type: SET_SAVE_CLOSE_SESSION,
    payload,
  }
}

export function setCloseCurrentSession() {
  return {
    type: SET_CURRENT_SESSION,
  }
}
