/**
 * Custom My Unyte Components will now start with Prefix `MyUnyte`
 */
import React from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { Tooltip, TextField } from '@mui/material'

export default function MyUnyteDatePicker({
  label,
  value = null,
  onChange = () => null,
  setErrors = () => null,
  errors,
  formKey,
}) {
  const onError = (event) => {
    if (event) {
      setErrors({ ...errors, [formKey]: event })
    } else {
      setErrors({ ...errors, [formKey]: undefined })
    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DesktopDatePicker
        onError={onError}
        label={label}
        disableFuture
        value={value}
        onChange={onChange}
        renderInput={(params) => {
          return (
            <Tooltip title="mm/dd/yyyy" disableInteractive>
              <TextField data-test="client-dob-select" {...params} fullWidth />
            </Tooltip>
          )
        }}
      />
    </LocalizationProvider>
  )
}
