import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import ProgressBar from './ProgressBar'
import { Grid, Card, Container } from 'components'
import Cart from './Cart'

import makeStyles from '@mui/styles/makeStyles'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { removeCoupon } from 'store/modules/new-purchase'
import LoadingPage from '../../../components/LoadingPage'
import PurchaseFooter from './PurchaseFooter'

function useWidth() {
  const theme = useTheme()
  const keys = [...theme.breakpoints.keys].reverse()
  return keys.reduce((output, key) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const matches = useMediaQuery(theme.breakpoints.up(key))
    return !output && matches ? key : output
  }, null)
}

const useStyles = makeStyles({
  card: {
    padding: 20,
  },
})

export default ({
  children,
  width = useWidth(),
  disableCartEdit,
  hideCartEdit,
  hideProgress,
  isConfirmPage,
  isPurchaseComplete,
  loading,
  loadingText,
  next,
  step,
}) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  useEffect(() => {
    if (!isPurchaseComplete) {
      dispatch(removeCoupon())
    }
    // eslint-disable-next-line
  }, [])

  if (['sm', 'xs'].includes(width)) {
    return (
      <LoadingPage loading={loading} text={loadingText} backgroundColor="#eef7f3">
        <div style={{ width: '100%', height: '50px', backgroundColor: '#008080' }} />
        <ProgressBar hideProgress={hideProgress} />
        <Container maxWidth="lg">
          <Grid container spacing={3} className="p-3">
            <Grid item xs={12}>
              <Cart
                disabled={disableCartEdit}
                hideEdit={hideCartEdit}
                isConfirmPage={isConfirmPage}
                isPurchaseComplete={isPurchaseComplete}
                next={next}
                step={step}
              />
            </Grid>
            <Grid item xs={12}>
              <Card className={classes.card} elevation={0}>
                {children}
              </Card>
            </Grid>
          </Grid>
        </Container>
        <PurchaseFooter />
      </LoadingPage>
    )
  } else {
    return (
      <LoadingPage loading={loading} text={loadingText} backgroundColor="#eef7f3">
        <div style={{ width: '100%', height: '50px', backgroundColor: '#008080' }} />
        <ProgressBar hideProgress={hideProgress} />
        <Container maxWidth="lg">
          <Grid container className="py-3">
            <Grid item md={7} className="pl-5 pr-3">
              <Card className={classes.card} elevation={0}>
                {children}
              </Card>
            </Grid>
            <Grid item md={5} className="pr-5 pl-3">
              <Cart
                disabled={disableCartEdit}
                hideEdit={hideCartEdit}
                isConfirmPage={isConfirmPage}
                isPurchaseComplete={isPurchaseComplete}
                next={next}
                step={step}
              />
            </Grid>
          </Grid>
        </Container>
        <PurchaseFooter />
      </LoadingPage>
    )
  }
}
