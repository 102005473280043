import React from 'react'
import { IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from 'notistack'

export default function CloseSnackbarAction({ key }) {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton
      aria-label="close-snackbar"
      onClick={() => {
        closeSnackbar(key)
      }}
      color="inherit"
      className="text-white"
      size="large"
    >
      <CloseIcon />
    </IconButton>
  )
}
