/**
 * This card is shown when provider purchases subscription internally
 */
import React from 'react'
import { DashboardCard } from './DashboardCard2'
import { Typography } from 'components'

export default function ProcessingCard() {
  return (
    <DashboardCard
      data-test="dashboard-card-focus-headphone-guidelines"
      title="System Processing"
      id="payment-processing-card"
    >
      <Typography variant="body2" gutterBottom py={1}>
        Thank you for your recent purchase. Please wait while we add these services to your account.
      </Typography>
    </DashboardCard>
  )
}
