/**
 * This popup shows up when a new plan provider enters client page
 */
import React from 'react'
import { useSelector } from 'react-redux'
import { DoNotShowAgainDialog } from 'components/DoNotShowAgainDialog'
import { OrganizationConvertedToNewPlanMessage } from './content/OrganizationConvertedToNewPlanMessage'
import { HowClientLicensesWorkMessage } from './content/HowClientLicensesWorkMessage'
import { multipleProductsText } from 'utils/multipleProductsText'

export default function ProgramsAndClientLicenses() {
  // get redux states
  const {
    hasSspProducts,
    hasFocusProducts,
    hasRrpProducts,
    showSubscriptionSlotsAssignedMessage,
  } = useSelector((state) => state.ff)
  const userId = useSelector((state) => state.auth?.user?.id)

  const { separatedByOr, separatedBySlash } = multipleProductsText({
    hasSspProducts,
    hasFocusProducts,
    hasRrpProducts,
  })

  return (
    <DoNotShowAgainDialog
      id={`programs-and-client-licenses-${userId}`}
      title={
        showSubscriptionSlotsAssignedMessage
          ? 'Important information about your subscription!'
          : 'Programs and client licenses'
      }
    >
      {!showSubscriptionSlotsAssignedMessage && (
        <HowClientLicensesWorkMessage licenseText={separatedByOr} />
      )}
      {showSubscriptionSlotsAssignedMessage && (
        <OrganizationConvertedToNewPlanMessage productText={separatedBySlash} />
      )}
    </DoNotShowAgainDialog>
  )
}
