import React from 'react'
import moment from 'utils/moment'
import { useSelector, useDispatch } from 'react-redux'
import { setForm, setEdit } from 'store/modules/clients'
import { get } from 'lodash'

import formats from 'utils/constants/formats'
import UserDetails from '../components/details/UserDetails'

import {
  ManageSeatsButton,
  ViewDashboardButton,
  ViewAssessmentsButton,
  SuspendButton,
  ArchiveButton,
  CopyUUID,
} from '../components/buttons'

export default ({ user, onUpdate }) => {
  const dispatch = useDispatch()
  const form = useSelector((state) => state.clients.form)
  const edit = useSelector((state) => state.clients.edit)

  const currentYear = new Date().getFullYear()
  const age = currentYear - user.birthYear

  const dobPreview = user.dob
    ? `${
        user.dob
          ? moment.withoutTimezone(user.dob).format(formats.date)
          : moment.withoutTimezone(`${user.birthYear}-01-01`).format(formats.date)
      }${age ? ` (Age in ${new Date().getFullYear()}: ${age} Years)` : ''}`
    : null
  const birthYearPreview = user.birthYear
    ? `Age in ${new Date().getFullYear()}: ${age} Years`
    : null

  const preview = dobPreview || birthYearPreview || ''
  const previewFields = [
    {
      label: 'Address',
      value: [user.country, user.city, user.state, user.address1, user.address2, user.zip]
        .map((val) => val || '')
        .join(' ')
        .trim(),
    },
    { label: 'Phone', value: user.phone },
    {
      label: 'Date of Birth',
      value: preview,
    },
    { label: 'Provider', value: get(user, 'provider.fullName') },
    { label: 'Gender', value: get(user, 'gender') },
  ]
  return (
    <UserDetails
      user={user}
      edit={edit}
      setEdit={(edit) => {
        dispatch(setForm(null))
        dispatch(setEdit(edit))
      }}
      form={form}
      onFormChange={(form) => {
        if (!edit) {
          return
        }
        dispatch(setForm(form))
      }}
      onUpdate={onUpdate}
      previewFields={previewFields}
      editableFields={[
        { key: 'firstName', label: 'First Name*' },
        { key: 'lastName', label: 'Last Name*' },
        { key: 'dob', label: 'Date of Birth (mm/dd/yyyy)' },
        { key: 'gender', label: 'Gender' },
        { key: 'address1', label: 'Address 1' },
        { key: 'address2', label: 'Address 2' },
        { key: 'country', label: 'Country' },
        { key: 'state', label: 'State' },
        { key: 'city', label: 'City' },
        { key: 'zip', label: 'Zip' },
        { key: 'phone', label: 'Phone' },
      ]}
      buttons={[
        ViewDashboardButton,
        ManageSeatsButton,
        ViewAssessmentsButton,
        ArchiveButton,
        SuspendButton,
        CopyUUID,
      ]}
    />
  )
}
