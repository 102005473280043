import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Outlet, matchPath } from 'react-router'

export default function Providers() {
  const tabs = [
    {
      text: 'Providers',
      url: '/providers',
      isActive: (pathname) => matchPath({ path: '/providers' }, pathname),
    },
    {
      text: 'Pending Invitations',
      url: '/providers/pending-invitations',
      isActive: (pathname) => matchPath({ path: '/providers/pending-invitations' }, pathname),
    },
  ]
  return (
    <MainLayout title="Providers" tabs={tabs}>
      <Outlet />
    </MainLayout>
  )
}
