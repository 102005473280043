/**
 * constants for scoring:
 * - all scoring must have itemResponses & scoringInterpretation
 * - this is more like a JSON file, so is not converted to ts
 */
import { get } from 'lodash'

export const SCORING_GUIDE = [
  {
    assessmentName: ['Adverse Childhood Experience for Adults (ACE)'],
    itemResponses: [
      { Score: 0, Response: ['No'] },
      { Score: 1, Response: ['Yes'] },
    ],
    scoringInterpretation: {
      interpretation: [
        { Range: '0-3', 'Scoring Interpretation': ['Insignificant score'], color: '#2A9D8F' },
        {
          Range: '4-10',
          'Scoring Interpretation': [
            'Clinically significant and linked to a higher risk of chronic disease',
          ],
          color: '#E76F51',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 4) {
        return { interpretation: 'Insignificant score', color: 'rgb(42, 157, 143)' }
      }
      if (score <= 10) {
        return {
          interpretation: 'Clinically significant and linked to a higher risk of chronic disease',
          color: 'rgb(231, 111, 81)',
        }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
  {
    assessmentName: ['Brain Body Center Sensory Scales (BBCSS) - Auditory'],
    itemResponses: [
      { Score: 4, Response: ['Almost Always'] },
      { Score: 3, Response: ['Frequently/Often'] },
      { Score: 2, Response: ['Sometimes/Occasionally'] },
      { Score: 1, Response: ['Almost Never'] },
      { Score: '-', Response: ['Not Sure/Not Applicable (do not score)'] },
    ],
    scoringInterpretation: {
      tableDescription:
        "The auditory domain of the BBCSS consists of two subscales. Considering the resulting mean score in each subscale, (1) indicates 'low impairment/problem' and (4) indicates 'high problem/impairment'.",
      interpretation: [
        {
          Subscales: 'Auditory Hypersensitivity',
          Definition: [
            'Sensitivity for sounds (largely broadband noise or high and low frequency sounds sharing acoustic features with evolutionary distress or predator sounds)',
          ],
          Items: '1, 2, 3, 5, 7, 10, 12, 13, 14',
        },
        {
          Subscales: 'Auditory Hyposensitivity to Voices',
          Definition: [
            'Reduced sensitivity for speech or voices (Higher scores = greater reduction)',
          ],
          Items: '4, 6, 8, 9, 11',
        },
      ],
    },
  },
  {
    assessmentName: [
      'Brain Body Center Sensory Scales (BBCSS) - Full',
      'Brain Body Center Sensory Scales (BBCSS) - Full (Child)',
    ],
    itemResponses: [
      { Score: 4, Response: ['Almost Always'] },
      { Score: 3, Response: ['Frequently/Often'] },
      { Score: 2, Response: ['Sometimes/Occasionally'] },
      { Score: 1, Response: ['Almost Never'] },
      { Score: '-', Response: ['Not Sure/Not Applicable (do not score)'] },
    ],
    scoringInterpretation: {
      tableDescription:
        "The BBCSS has eight subscales. Considering the resulting mean score in each subscale, (1) indicates 'low impairment/problem' and (4) indicates 'high problem/impairment'.",
      interpretation: [
        {
          Subscales: 'Auditory Hypersensitivity',
          Definition: [
            'Sensitivity for sounds (largely broadband noise or high and low frequency sounds sharing acoustic features with evolutionary distress or predator sounds) ',
          ],
          Items: '1, 2, 3, 5, 7, 10, 12, 13, 14',
        },
        {
          Subscales: 'Auditory Hyposensitivity to Voices',
          Definition: ['Reduced sensitivity for speech or voices'],
          Items: '4, 6, 8, 9, 11',
        },
        {
          Subscales: 'Visual Hypersensitivity',
          Definition: ['Sensitivity to light and movement'],
          Items: '15, 16, 17, 18, 19, 20, 21, 22, 23, 24',
        },
        {
          Subscales: 'Tactile Hypersensitivity',
          Definition: ['Sensitivity to non-social touch'],
          Items: '25, 26, 27, 28, 29, 30, 31, 32, 36, 37',
        },
        {
          Subscales: 'Affiliative Touch Aversion',
          Definition: ['Negative response to social touch'],
          Items: '33, 34, 35',
        },
        {
          Subscales: 'Selective Eating',
          Definition: ['Selectivity in food choices (i.e. picky eating)'],
          Items: '38, 39, 40, 41, 49, 50',
        },
        {
          Subscales: 'Ingestive Problems',
          Definition: ['Problems with swallowing and retaining food'],
          Items: '42, 43, 48',
        },
        {
          Subscales: 'Digestive Problems',
          Definition: ['Problems with digestion'],
          Items: '44, 45, 46, 47',
        },
      ],
    },
  },

  {
    assessmentName: ['Body Perception Questionnaire Short Form (BPQ-SF)'],
    itemResponses: [
      { Score: 1, Response: ['Never'] },
      { Score: 2, Response: ['Occasionally'] },
      { Score: 3, Response: ['Sometimes'] },
      { Score: 4, Response: ['Usually'] },
      { Score: 5, Response: ['Always'] },
    ],
    scoringInterpretation: {
      // the capitalization here matters
      tableDescription:
        'The BPQ-SF has two subscales. Scoring interpretation of the BPQ-SF should be considered by subscale.',
      interpretation: [
        {
          Subscales: 'Body Awareness',
          Definition: [
            'Scores of 79 or higher (80th percentile) suggest more awareness of bodily sensations related to autonomic nervous system functions.',
            'Scores of 79 or lower indicate less awareness of bodily sensations related to autonomic nervous system functions.',
          ],
          Items: '1 - 26',
        },
        {
          Subscales: 'Autonomic Nervous System Symptoms',
          Definition: [
            'Scores of 42 or lower (80th percentile) suggest that your autonomic stress response system activates only occasionally.',
            'Scores higher than 42 suggest that your autonomic stress response system may be active often. This may be normal if this is a time of high stress for you.',
          ],
          Items: '27 - 46',
        },
      ],
    },
  },
  {
    assessmentName: ['Body Perception Questionnaire Autonomic Symptoms Short Form (BPQ20-ANS)'],
    itemResponses: [
      { Score: 1, Response: ['Never'] },
      { Score: 2, Response: ['Occasionally'] },
      { Score: 3, Response: ['Sometimes'] },
      { Score: 4, Response: ['Usually'] },
      { Score: 5, Response: ['Always'] },
    ],
    scoringInterpretation: {
      tableDescription:
        'The BPQ-20ANS consists of the Autonomic Nervous System Symptoms subscale only.',
      interpretation: [
        {
          'BPQ20-ANS': 'Autonomic Nervous System Symptoms',
          Definition: [
            'Subscale scores of 42 or lower (80th percentile) suggest that your autonomic stress response system activates only occasionally.',
            'Subscale scores higher than 42 suggest that your autonomic stress response system may be active often. This may be normal if this is a time of high stress for you.',
          ],
        },
      ],
    },
  },

  {
    assessmentName: ['Generalised Anxiety Disorder (GAD-7)'],
    itemResponses: [
      { Score: 0, Response: ['Not at all'] },
      { Score: 1, Response: ['Several Days'] },
      { Score: 2, Response: ['More than half the days'] },
      { Score: 3, Response: ['Nearly Every Day'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Range: '0-4',
          'Symptom Severity': ['Minimal or no anxiety symptoms'],
          color: '#2A9D8F',
        },
        {
          Range: '5-9',
          'Symptom Severity': ['Mild anxiety symptoms'],
          color: '#E9C46A',
        },
        {
          Range: '10-14',
          'Symptom Severity': ['Moderate anxiety symptoms'],
          color: '#F4A261',
        },
        {
          Range: '15-21',
          'Symptom Severity': ['Severe anxiety symptoms'],
          color: '#E76F51',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 4) {
        return { interpretation: 'Minimal or no anxiety symptoms', color: 'rgb(42, 157, 143)' }
      }
      if (score <= 9) {
        return { interpretation: 'Mild anxiety symptoms', color: 'rgb(233, 196, 106)' }
      }
      if (score <= 14) {
        return { interpretation: 'Moderate anxiety symptoms', color: 'rgb(244, 162, 97)' }
      }
      if (score <= 21) {
        return { interpretation: 'Severe anxiety symptoms', color: 'rgb(231, 111, 81)' }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
  {
    assessmentName: ['Patient Health Questionnaire (PHQ-9)'],
    itemResponses: [
      { Score: 0, Response: ['Not at all'] },
      { Score: 1, Response: ['Several Days'] },
      { Score: 2, Response: ['More than half the days'] },
      { Score: 3, Response: ['Nearly Every Day'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Range: '0-4',
          'Symptom Severity': ['Minimal or no depression symptoms'],
          color: 'rgb(38, 70, 83)',
        },
        {
          Range: '5-9',
          'Symptom Severity': ['Mild depression symptoms'],
          color: 'rgb(42, 157, 143)',
        },
        {
          Range: '10-14',
          'Symptom Severity': ['Moderate depression symptoms'],
          color: 'rgb(233, 196, 106)',
        },
        {
          Range: '15-19',
          'Symptom Severity': ['Moderately severe depression symptoms'],
          color: 'rgb(244, 162, 97)',
        },
        {
          Range: '20-27',
          'Symptom Severity': ['Severe depression symptoms'],
          color: 'rgb(231, 111, 81)',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 4) {
        return { interpretation: 'Minimal or no depression symptoms', color: 'rgb(38, 70, 83)' }
      }
      if (score <= 9) {
        return { interpretation: 'Mild depression symptoms', color: 'rgb(42, 157, 143)' }
      }
      if (score <= 14) {
        return { interpretation: 'Moderate depression symptoms', color: 'rgb(233, 196, 106)' }
      }
      if (score <= 19) {
        return {
          interpretation: 'Moderately severe depression symptoms',
          color: 'rgb(244, 162, 97)',
        }
      }
      if (score <= 27) {
        return { interpretation: 'Severe depression symptoms', color: 'rgb(231, 111, 81)' }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
  {
    assessmentName: [
      'PTSD Checklist for DSM-5 (PCL-5) - Week',
      'PTSD Checklist for DSM-5 (PCL-5) - Month',
    ],
    itemResponses: [
      { Score: 0, Response: ['Not at all'] },
      { Score: 1, Response: ['A little bit'] },
      { Score: 2, Response: ['Moderately'] },
      { Score: 3, Response: ['Quite a bit'] },
      { Score: 4, Response: ['Extremely'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Range: '0-32',
          'Symptom Severity': ['Below clinical threshold'],
          color: '#2A9D8F',
        },
        {
          Range: '33-80',
          'Symptom Severity': ['Above clinical threshold'],
          color: '#F4A261',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 32) {
        return { interpretation: 'Below clinical threshold', color: 'rgb(42, 157, 143)' }
      }
      if (score <= 80) {
        return { interpretation: 'Above clinical threshold', color: 'rgb(244, 162, 97)' }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
  {
    assessmentName: ['Pediatric Symptom Checklist (PSC) - Caregiver'],
    itemResponses: [
      { Score: 0, Response: ['Never'] },
      { Score: 1, Response: ['Sometimes'] },
      { Score: 2, Response: ['Often'] },
    ],
    scoringInterpretation: {
      tableDescription: 'For ages 4 - 5',
      interpretation: [
        {
          Range: '0-24',
          'Symptom Severity': ['Not impaired'],
        },
        {
          Range: '25-70',
          'Symptom Severity': ['Psychological impairment'],
        },
      ],
    },
    scoringInterpretation2: {
      tableDescription: 'For ages 6 - 16',
      interpretation: [
        {
          Range: '0-27',
          'Symptom Severity': ['Not impaired'],
        },
        {
          Range: '28-70',
          'Symptom Severity': ['Psychological impairment'],
        },
      ],
    },
  },
  {
    assessmentName: ['The Developmental Coordination Disorder Questionnaire (DCDQ)'],
    itemResponses: [
      { Score: 1, Response: ['Not at all like your child'] },
      { Score: 2, Response: ['A bit like your child'] },
      { Score: 3, Response: ['Moderately like your child'] },
      { Score: 4, Response: ['Quite a bit like your child'] },
      { Score: 5, Response: ['Extremely like your child'] },
    ],
    scoringInterpretation: {
      tableDescription:
        "Using the child's chronological age at the time the questionnaire was completed, find the appropriate age grouping on the left column of the table. To compute the chronological age, subtract the child's D.O.B. (year, month, day) from the date the DCDQ was completed (year, month, day).",

      interpretation: [
        {
          'Age Group (based on Chronological Age)': '5 years, 0 months to 7 years, 11 months',
          'Indication of, or Suspect for, DCD': ['15 - 46'],
          'Probably not DCD': '47 - 75',
        },
        {
          'Age Group (based on Chronological Age)': '8 years, 0 months to 9 years, 11 months',
          'Indication of, or Suspect for, DCD': ['15 - 55'],
          'Probably not DCD': '56 - 75',
        },
        {
          'Age Group (based on Chronological Age)': '10 years, 0 months to 15 years',
          'Indication of, or Suspect for, DCD': ['15 - 57'],
          'Probably not DCD': '58 - 75',
        },
      ],
    },
  },
  {
    assessmentName: ['Neuroception of Psychological Safety Scale (NPSS)'],
    itemResponses: [
      { Score: 1, Response: ['Strongly Disagree'] },
      { Score: 2, Response: ['Disagree'] },
      { Score: 3, Response: ['Neither Agree nor Disagree'] },
      { Score: 4, Response: ['Agree'] },
      { Score: 5, Response: ['Strongly Agree'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Subscale: 'Social Engagement',
          Description: [
            'Higher scores reflect the evaluation of the environment as non-threatening and safe for social engagement.',
          ],
          Items: '1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14',
        },
        {
          Subscale: 'Compassion',
          Description: [
            'Higher scores reflect the ability to be compassionate and feel connected, empathetic, caring, and wanting to help others.',
          ],
          Items: '15, 16, 17, 18, 19, 20, 21',
        },
        {
          Subscale: 'Bodily Sensations',
          Description: [
            'Higher scores reflect internal sensations of the body in a state of calm capturing the feeling of relaxation in the face and the body, steady heartbeat and breath, and settled stomach.',
          ],

          Items: '22, 23, 24, 25, 26, 27, 28, 29',
        },
      ],
    },
  },
  {
    assessmentName: ['Athens Insomnia Scale (AIS)'],
    itemResponses: [
      { Score: 0, Response: ['Minimal prevalence/severity'] },
      { Score: 1, Response: ['Mild sleep difficulties'] },
      { Score: 2, Response: ['Moderate sleep difficulties'] },
      { Score: 3, Response: ['Heightened sleep difficulties'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Range: '0 - 5',
          'Symptom Severity': ["Doesn't indicate sleep difficulty"],
          color: '#2A9D8F',
        },
        {
          Range: '6 - 24',
          'Symptom Severity': [
            'Indicates sleep difficulty (may correspond to a diagnosis of insomnia)',
          ],
          color: '#E76F51',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 5) {
        return { interpretation: "Doesn't indicate sleep difficulty", color: 'rgb(42, 157, 143)' }
      }
      if (score <= 24) {
        return {
          interpretation: 'Indicates sleep difficulty (may correspond to a diagnosis of insomnia)',
          color: 'rgb(231, 111, 81)',
        }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
  {
    assessmentName: ['Child and Adolescent Trauma Screen (CATS) (Adolescent) - Month'],
    itemResponses: [
      { Score: 0, Response: ['Never'] },
      { Score: 1, Response: ['Once in a while'] },
      { Score: 2, Response: ['Half the time'] },
      { Score: 3, Response: ['Almost always'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Range: '0 - 20',
          'Symptom Severity': ['Non-clinically relevant level of symptoms'],
          color: '#2A9D8F',
        },
        {
          Range: '21 or higher',
          'Symptom Severity': ['Clinical relevant level of symptoms'],
          color: '#E76F51',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 20) {
        return {
          interpretation: 'Non-clinically relevant level of symptoms',
          color: 'rgb(42, 157, 143)',
        }
      }
      if (score >= 21) {
        return {
          interpretation: 'Non-clinically relevant level of symptoms',
          color: 'rgb(231, 111, 81)',
        }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
  {
    assessmentName: ['Child and Adolescent Trauma Screen (CATS) (Child) - Month'],
    itemResponses: [
      { Score: 0, Response: ['Never'] },
      { Score: 1, Response: ['Once in a while'] },
      { Score: 2, Response: ['Half the time'] },
      { Score: 3, Response: ['Almost always'] },
    ],
    scoringInterpretation: {
      interpretation: [
        {
          Range: '0 - 15',
          'Symptom Severity': ['Non-clinically relevant level of symptoms'],
          color: '#2A9D8F',
        },
        {
          Range: '16 or higher',
          'Symptom Severity': ['Clinical relevant level of symptoms'],
          color: '#E76F51',
        },
      ],
    },
    getInterpretation: (score) => {
      if (score <= 15) {
        return {
          interpretation: 'Non-clinically relevant level of symptoms',
          color: 'rgb(42, 157, 143)',
        }
      }
      if (score >= 16) {
        return {
          interpretation: 'Clinical relevant level of symptoms',
          color: 'rgb(231, 111, 81)',
        }
      }
      return { interpretation: '', color: '#f5f5f5' }
    },
  },
]

export const getTotalScoreFromSubScore = (assessment, subScore) => {
  const subScales = get(assessment, 'metadata.subScales', null)
  const variant = get(assessment, 'metadata.variant', null)
  const assessmentName = get(assessment, 'productName', null)
  const searchTerm = `${assessmentName}${variant ? ` - ${variant}` : ''}`
  const totalName = `Total for ${searchTerm}`
  const totalScoreName = !subScales ? searchTerm : totalName
  const maximumScore = get(assessment, 'metadata.maximumScore', 0)

  return { score: subScore[totalScoreName], maximumScore, subScaleScores: subScore }
}
