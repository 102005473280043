const SET_DATA = 'LICENSES/SET_DATA'
const RESET_LICENSES = 'LICENSES/RESET_LICENSES'

const initialState = {
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case RESET_LICENSES:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function setLicensesData(payload) {
  return {
    type: SET_DATA,
    payload,
  }
}

export function resetLicenses() {
  return {
    type: RESET_LICENSES,
  }
}
