import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { SuspendButton, ArchiveButton, CopyUUID } from '../components/buttons'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { Button, Box, Stack, Typography, Card, CardContent } from 'components'
import { BoundingInfoBox } from '../components/details/components/PreviewUserDetails'
import { useQuery } from '@apollo/client'
import { formatSeconds } from 'utils'
import { GET_TOTAL_INTERATION_TIME } from './constants/constants'

function formatDate(dateString) {
  const date = new Date(dateString)
  return date.toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })
}

function transformSlot(slot) {
  const categoryLabel = slot.category === 'focus' ? 'ILS' : slot.category.toUpperCase()
  const values = !slot.usedAt
    ? [
        {
          label: 'Assigned',
          value: formatDate(slot.assignedAt),
        },
      ]
    : [
        {
          label: 'Started',
          value: formatDate(slot.usedAt),
        },
        {
          label: 'Ends',
          value: formatDate(slot.expiredAt),
        },
      ]

  return { categoryLabel: `${categoryLabel} License`, values }
}

const ListeningOverview = ({ userId }) => {
  const [listeningOverviewDetails, setListeningOverviewDetails] = useState({
    totalTime: 0,
    totalSessions: 0,
  })

  // 1. get total time
  useQuery(GET_TOTAL_INTERATION_TIME, {
    variables: {
      filter: {
        userIds: userId,
      },
    },
    skip: !userId,
    onCompleted: (data) => {
      const sessions = get(data, 'getSessions', []).filter(({ totalInteractionTime }) =>
        Boolean(totalInteractionTime)
      )
      const totalSessions = sessions.length
      const totalTime = sessions.reduce((total, item) => total + item.totalInteractionTime, 0)
      setListeningOverviewDetails((prev) => ({ ...prev, totalTime, totalSessions }))
    },
  })

  const hasNoSessions = listeningOverviewDetails.totalSessions === 0

  return (
    <Box sx={{ display: 'inline-flex', maxWidth: '100%' }}>
      <Card
        variant="outlined"
        sx={{
          backgroundColor: '#F2F2F2 !important',
          borderColor: '#E4E4E4',
        }}
        elevation={0}
      >
        <CardContent>
          <Typography fontWeight={500} mb={2} variant="body2">
            Listening Overview
          </Typography>
          {!hasNoSessions && (
            <Stack spacing={2} direction="row">
              <Stack direction="column">
                <Typography variant="subtitle2">Total Time</Typography>
                <Typography variant="body2">
                  {formatSeconds(listeningOverviewDetails.totalTime)}
                </Typography>
              </Stack>
              <Stack direction="column">
                <Typography variant="subtitle2">Total Sessions</Typography>
                <Typography variant="body2">{listeningOverviewDetails.totalSessions}</Typography>
              </Stack>
            </Stack>
          )}
          {hasNoSessions && (
            <Typography variant="body2">The Client hasn't started listening.</Typography>
          )}
        </CardContent>
      </Card>
    </Box>
  )
}

export default ({ user, onUpdate }) => {
  const navigate = useNavigate()
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const { setSelectedClient, updateUser } = useOutletContext()
  console.log({ user })

  // we want to sort ssp first then ils second..
  const slotsData = user.slots
    ?.filter(({ status }) => ['used', 'assigned'].includes(status))
    .sort((a, b) => {
      if (a.category < b.category) {
        return 1
      }
      if (a.category > b.category) {
        return -1
      }
      return 0
    })
    .map((slot) => transformSlot(slot))

  const age = user.birthYear ? `${new Date().getFullYear() - user.birthYear} years old` : ''
  const clientDetails = [
    { label: 'Age in 2024', value: age || '' },
    { label: 'Gender', value: get(user, 'gender') || '' },
    { label: 'Country', value: get(user, 'country') || '' },
    { label: 'Provider', value: get(user, 'provider.fullName') || '' },
  ]

  const redirectUser = async (url, user, editMode = false) => {
    setSelectedClient(user)
    navigate(url, { state: { editMode } })
  }

  const actions = [
    {
      label: 'Manage Delivery',
      isEnabled: () => true,
      onClick: () => redirectUser(`/clients/${user.id}/manage-delivery`, user),
    },
    {
      label: 'Administer Assessment',
      isEnabled: () => true,
      onClick: () => {
        navigate('/assessments/select', { state: { navigationClientId: user.id } })
      },
    },
    {
      label: 'View Client Dashboard',
      isEnabled: () => true,
      onClick: () => redirectUser(`/clients/${user.id}/dashboard`, user),
    },
    {
      label: 'Edit Client Details',
      isEnabled: () => true,
      onClick: () => redirectUser(`/clients/${user.id}`, user),
    },
    {
      label: 'View Pending Invitation',
      isEnabled: (user) => user.isInvitationMode,
      onClick: () => {
        navigate('/clients/pending-invitations', {
          state: { firstName: user.firstName, lastName: user.lastName },
        })
      },
    },
  ].filter(({ isEnabled }) => isEnabled(user))

  return (
    <Box p={2}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
        <ListeningOverview userId={user.id} />
        <BoundingInfoBox label="Client Details" list={clientDetails} />
        {showNewSubscriptionPlan &&
          slotsData.map(({ categoryLabel, values }, index) => (
            <BoundingInfoBox
              key={`new-subscription-info-box-${index}`}
              label={categoryLabel}
              list={values}
            />
          ))}
      </Stack>

      <Box mt={3}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <Box>
            {actions.map(({ label, onClick }, index) => (
              <Button key={index} onClick={onClick}>
                {label}
              </Button>
            ))}
            <CopyUUID user={user} />
          </Box>
          <Box>
            {!user.isInvitationMode && <ArchiveButton user={user} updateUser={updateUser} />}
            <SuspendButton user={user} updateUser={updateUser} onUpdate={onUpdate} />
          </Box>
        </Stack>
      </Box>
    </Box>
  )
}
