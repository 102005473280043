import {
  Button as MuiButton,
  Card,
  CardHeader,
  Stack,
  Tooltip,
  Typography,
  CardMedia,
} from '@mui/material'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle'

const Button = ({ children, ...props }) => {
  return (
    <MuiButton
      sx={{ flexGrow: 1, flexBasis: 0, height: '90px', maxWidth: '90px', borderRadius: 25 }}
      {...props}
    >
      {children}
    </MuiButton>
  )
}

export default function Question({
  id,
  scrollTo,
  question,
  description,
  high,
  low,
  onScoreSelection,
  score,
  tooltip,
}) {
  const circleIconSx = (selectID) => {
    const isSelected = score[id] === selectID
    const baseStyle = { transform: 'scale(2)' }

    return isSelected ? { ...baseStyle, border: '2px solid #000', borderRadius: 20 } : baseStyle
  }

  const questionTitle = (
    <Stack direction="column">
      <Stack direction="row">
        {question}
        <Tooltip
          title={
            <Typography fontSize={14} color="white">
              {tooltip}
            </Typography>
          }
        >
          <CardMedia
            className="ml-2"
            component="img"
            image="/images/resources/titration-tool/info-icon.png"
            sx={{ height: '100%', width: 20 }}
          />
        </Tooltip>
      </Stack>
      <Typography variant="body1" mt={1}>
        {description}
      </Typography>
    </Stack>
  )

  return (
    <Card elevation={0}>
      <CardHeader title={questionTitle} />
      <Stack direction="row" justifyContent="space-between">
        <Button
          onClick={onScoreSelection(id, 1)}
          variant="text"
          href={`#${scrollTo}`}
          sx={{ marginLeft: 8, borderRadius: 25, width: 90 }}
        >
          <CircleIcon htmlColor="#00bf63" sx={circleIconSx(1)} />
        </Button>
        <Button onClick={onScoreSelection(id, 2)} variant="text" href={`#${scrollTo}`}>
          <CircleIcon htmlColor="##7ed957" sx={circleIconSx(2)} />
        </Button>
        <Button onClick={onScoreSelection(id, 3)} variant="text" href={`#${scrollTo}`}>
          <CircleIcon htmlColor="#5ce1e6" sx={circleIconSx(3)} />
        </Button>
        <Button onClick={onScoreSelection(id, 4)} variant="text" href={`#${scrollTo}`}>
          <CircleIcon htmlColor="#39b6ff" sx={circleIconSx(4)} />
        </Button>
        <Button
          onClick={onScoreSelection(id, 5)}
          variant="text"
          href={`#${scrollTo}`}
          sx={{ marginRight: 8, borderRadius: 25, width: 90 }}
        >
          <CircleIcon htmlColor="#5371ff" sx={circleIconSx(5)} />
        </Button>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Typography
          className="ml-3"
          color="primary"
          sx={{ fontWeight: 500, width: 200 }}
          textAlign="center"
        >
          {low}
        </Typography>
        <Typography
          className="mr-3"
          color="primary"
          sx={{ fontWeight: 500, width: 200 }}
          textAlign="center"
        >
          {high}
        </Typography>
      </Stack>
    </Card>
  )
}
