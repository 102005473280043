import React, { useState } from 'react'
import formats from 'utils/constants/formats'
import { Card, Grid, FormControlLabel, Checkbox } from 'components'
import moment from 'utils/moment'

import FilterListIcon from '@mui/icons-material/FilterList'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

import DatePicker from './DatePicker'
import { useSelector } from 'react-redux'

export default function Filter({
  dateFrom: _dateFrom,
  dateTo: _dateTo,
  setFilter = () => null,
  form,
  setForm,
}) {
  const [showFilters, setShowFilters] = useState(false)
  const products = useSelector((state) => state.seats.products)

  return (
    <Card className="px-5 py-2 mb-2">
      <Grid
        style={{ cursor: 'pointer' }}
        container
        justifyContent="space-between"
        alignItems="center"
        onClick={() => setShowFilters(!showFilters)}
      >
        <Grid>
          <FilterListIcon />
        </Grid>
        <Grid>
          <Grid container alignItems="center">
            <CalendarTodayIcon className="mr-2" />
            {moment.withoutTimezone(form.date[0] || _dateFrom).format(formats.date)} -{' '}
            {moment.withoutTimezone(form.date[1] || _dateTo).format(formats.date)}
          </Grid>
        </Grid>
      </Grid>

      {showFilters && (
        <Grid className="pt-4" container justifyContent="space-between">
          <Grid xs={4}>
            {products
              .filter((product) => product.category !== 'assessment')
              .map(({ id, name }) => (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={(form.selectedProducts || []).includes(id)}
                        onChange={async () => {
                          const selectedProducts = form.selectedProducts.includes(id)
                            ? form.selectedProducts.filter((_id) => _id !== id)
                            : form.selectedProducts.concat(id)
                          await setForm({ ...form, selectedProducts })
                          await setFilter({
                            productIds: selectedProducts,
                            startedAt: {
                              gtEq: form.date[0] ? form.date[0].format() : null,
                              ltEq: form.date[1] ? form.date[1].format() : null,
                            },
                          })
                        }}
                        value={id}
                      />
                    }
                    label={name}
                  />
                </div>
              ))}
          </Grid>
          <Grid>
            <DatePicker
              variant="static"
              orientation="landscape"
              value={[
                moment.withoutTimezone(form.date[0] || _dateFrom).startOf('day'),
                moment.withoutTimezone(form.date[1] || _dateTo).startOf('day'),
              ]}
              disableToolbar={true}
              onChange={async (date) => {
                await setForm({ ...form, date })
                await setFilter({
                  productIds: form.selectedProducts,
                  startedAt: {
                    gtEq: date[0].format(),
                    ltEq: date[1].format(),
                  },
                })
              }}
              autoOk={true}
            />
          </Grid>
        </Grid>
      )}
    </Card>
  )
}
