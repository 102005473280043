import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch } from 'react-redux'

import { Grid, Card, CardHeader, CardContent, Link } from 'components'
import Stepper from 'components/Stepper'
import { setIntroVideoState, setProduct } from 'store/modules/app'

import ROLES from 'utils/constants/roles'
import { includesSome } from 'utils/includes'

const ALLOWED_ROLES = [...ROLES.PROVIDER_ROLES, ROLES.CLIENT]

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
  link: {
    color: '#616161',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  text: {
    fontWeight: 400,
  },
  connector: {
    height: 8,
  },
}))

/**
 * TODO: UW-1375: Update SSP related copy
 * https://unytehealth.atlassian.net/browse/UW-1375
 */
export default function GettingStarted({ gridProps, roles, data }) {
  const classes = useStyles()
  const dispatch = useDispatch()

  if (!includesSome(roles, ALLOWED_ROLES)) {
    return null
  }
  const handleIntroVideoClick = () => {
    dispatch(setIntroVideoState(true))
    dispatch(setProduct(data.product))
  }

  const type = data.product === 'Focus' ? 'ILS' : data.product

  const providerSteps = [
    type !== 'RRP' && (
      <div className={classes.text}>
        Watch this short{' '}
        <span className={classes.link} onClick={handleIntroVideoClick}>
          {type} intro video
        </span>
        .
      </div>
    ),
    <div className={classes.text}>
      Click on{' '}
      <Link to="/clients" className={classes.link}>
        Clients
      </Link>{' '}
      in the left menu and add a Client.
    </div>,
    <div className={classes.text}>
      Install the Unyte Health app on your iOS or Android device and sign in with your MyUnyte
      account info.
    </div>,
    <div className={classes.text}>
      Check out the{' '}
      <Link className={classes.link} to="/resources">
        Resources
      </Link>{' '}
      available to you.
    </div>,
  ].filter(Boolean)

  const clientSteps = [
    <div className={classes.text}>
      Watch this short{' '}
      <span className={classes.link} onClick={handleIntroVideoClick}>
        intro video
      </span>
      .
    </div>,
    <div className={classes.text}>
      Install the Unyte Health app on your iOS or Android device and sign in using your MyUnyte
      account info.
    </div>,
    <div className={classes.text}>
      Consult your {type} provider for next steps. Do not start the{' '}
      {data.product === 'Focus'
        ? 'Integrated Listening System (ILS)'
        : 'Safe and Sound Protocol (SSP)'}{' '}
      without your provider's instructions.
    </div>,
    <div className={classes.text}>
      Check out the{' '}
      <Link className={classes.link} to="/resources">
        Resources
      </Link>{' '}
      available to you.
    </div>,
    <div className={classes.text}>Breathe.</div>,
  ]

  const steps = includesSome(roles, ROLES.PROVIDER_ROLES) ? providerSteps : clientSteps

  return (
    <Grid item {...gridProps} key="getting-started-card">
      <Card
        data-test={`dashboard-card-getting-started-${data.product}`}
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          title={`Getting Started with ${type}`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Stepper steps={steps} backgroundColor="#edf2f7" connector={null} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export const ProviderGettingStarted = ({ gridProps, type }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const handleIntroVideoClick = () => {
    dispatch(setIntroVideoState(true))
    dispatch(setProduct(type))
  }

  const steps = [
    type !== 'RRP' && (
      <div className={classes.text}>
        Watch this short{' '}
        <span className={classes.link} onClick={handleIntroVideoClick}>
          {type} intro video
        </span>
        .
      </div>
    ),
    <div className={classes.text}>
      Click on{' '}
      <Link to="/clients" className={classes.link}>
        Clients
      </Link>{' '}
      in the left menu and add a Client.
    </div>,
    <div className={classes.text}>
      Install the Unyte Health app on your iOS or Android device and sign in with your MyUnyte
      account info.
    </div>,
    <div className={classes.text}>
      Check out the{' '}
      <Link className={classes.link} to="/resources">
        Resources
      </Link>{' '}
      available to you.
    </div>,
  ].filter(Boolean)

  return (
    <Grid item {...gridProps} key="getting-started-card">
      <Card
        data-test={`dashboard-card-getting-started-${type}`}
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          title={`Getting Started with ${type}`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Stepper steps={steps} backgroundColor="#edf2f7" connector={null} />
        </CardContent>
      </Card>
    </Grid>
  )
}

export const ClientGettingStarted = ({ gridProps, type }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleIntroVideoClick = () => {
    dispatch(setIntroVideoState(true))
    dispatch(setProduct(type))
  }

  const fullNameMap = {
    ILS: 'the Integrated Listening System (ILS)',
    RRP: 'Rest and Restore Protocol™ (RRP)',
    SSP: 'the Safe and Sound Protocol (SSP)',
  }
  const steps = [
    type !== 'RRP' && (
      <div className={classes.text}>
        Watch this short{' '}
        <span className={classes.link} onClick={handleIntroVideoClick}>
          intro video
        </span>
        .
      </div>
    ),
    <div className={classes.text}>
      Install the Unyte Health app on your iOS or Android device and sign in using your MyUnyte
      account info.
    </div>,
    <div className={classes.text}>
      Consult your {type} provider for next steps. Do not start {fullNameMap[type]} without your
      provider's instructions.
    </div>,
    type !== 'RRP' && (
      <div className={classes.text}>
        Check out the{' '}
        <Link className={classes.link} to="/resources">
          Resources
        </Link>{' '}
        available to you.
      </div>
    ),
    <div className={classes.text}>Breathe.</div>,
  ].filter(Boolean)

  return (
    <Grid item {...gridProps} key="getting-started-card">
      <Card
        data-test={`dashboard-card-getting-started-${type}`}
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          title={`Getting Started with ${type}`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Stepper steps={steps} backgroundColor="#edf2f7" connector={null} />
        </CardContent>
      </Card>
    </Grid>
  )
}
