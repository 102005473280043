import React, { useEffect } from 'react'
import {
  Button,
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material'
import { TITRATION_RECOMMENDATIONS_TABLES } from 'utils/constants/titrationToolSurvey'

export default function Recommendations({
  complexity,
  onPrevious,
  printLayoutEnabled,
  setHidePrintButton,
}) {
  const { rate, title, caption, colour } = complexity

  useEffect(() => {
    setHidePrintButton(false)
  }, [])

  return (
    <>
      <Stack direction="column" textAlign="center">
        <Typography variant="h5" color={colour}>
          {title}
        </Typography>
        <Typography pb={2} fontStyle="italic">
          {caption}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Stack pl={2} direction="column" sx={{ width: '65%' }}>
          {TITRATION_RECOMMENDATIONS_TABLES.map(({ header, body }, index1) => (
            <TableContainer key={`titration-table-${header}-${index1}`} className="pb-5">
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={2} sx={{ paddingLeft: 0 }}>
                      <Typography
                        variant="button"
                        sx={{ fontSize: '20px', fontWeight: 500 }}
                        color="primary"
                      >
                        {header}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  {body[rate].map(({ cellCategory, cellValue }, index2) => (
                    <TableRow key={`titration-table-row-${cellCategory}-${index2}`}>
                      <TableCell width="25%" sx={{ backgroundColor: '#eef8f3' }}>
                        <Typography
                          variant="caption"
                          sx={{ fontSize: '15px', fontWeight: 500 }}
                          color="primary"
                        >
                          {cellCategory}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body1">{cellValue}</Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ))}
        </Stack>

        <Card
          sx={{ border: '1px solid #aecede', borderRadius: 2, width: '30%', alignSelf: 'center' }}
        >
          <Typography m={2} fontStyle="italic">
            Remember, it is important that the SSP is titrated according to the individual's nervous
            system, which is constantly changing in response to neuroceptive cues. The way you
            titrate a client's SSP program may change over time - remember that SSP is a dynamic and
            integrative process.
          </Typography>
          <Typography m={2} fontStyle="italic">
            This tool is only a guide, designed to help you think through some of the important
            factors to consider when preparing for SSP Delivery. Use your professional skills of
            observation, inquiry, psychoeducation, and co-regulation to tune into your client's
            nervous system state and titrate the SSP accordingly.
          </Typography>
          <Typography m={2} fontStyle="italic">
            If you do not meet this Provider Readiness criteria please refer to an appropriate
            qualifying professional.
          </Typography>
        </Card>
      </Stack>

      <Stack direction="row" justifyContent="center">
        <Button
          href="https://docs.google.com/forms/d/1SncwXNIkv7GdZgkG3fOgIS7s8U6jomtZXrY6BvQn_zE/edit"
          target="_blank"
          size="large"
          variant="outlined"
        >
          Provide Feedback
        </Button>
      </Stack>
      {!printLayoutEnabled && (
        <Button variant="contained" onClick={onPrevious}>
          Previous
        </Button>
      )}
    </>
  )
}
