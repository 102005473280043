import { Typography } from 'components'
import React from 'react'

export const InternationalMessage = () => (
  <Typography variant="body1" className="pb-5 px-5">
    <span className="font-medium">Please note:</span> International shipments outside the USA may be
    subject to import duties and taxes as determined by the import laws of your country.
    <br />
    <br />
    You are responsible for all import taxes and duties.
  </Typography>
)
