/**
 * This file will is used to store organization information into redux
 *
 * redux format
 * {
 * hasOrgSspCertificationCompleted
 * hasOrgFocusCertificationCompleted
 * hasOrgSspCertificationEnrolled
 * hasOrgFocusCertificationEnrolled
 * }
 */

import { useEffect } from 'react'
import { useQuery, gql } from '@apollo/client'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

import roles from '../utils/constants/roles'
import {
  ALL_FOCUS_SUBSCRIPTIONS_SKUS,
  ALL_SSP_SUBSCRIPTION_SKUS,
  ALL_RRP_SUBSCRIPTION_SKUS,
} from 'utils/constants/prices'
import { setOrganizationValue } from '../store/modules/organization'
import { setFF } from 'store/modules/ff'
import { STATUSES_IN_PROGRESS, EXPIRED } from 'views/connections/shared/constants'
import { GET_ACTIVE_CONNECTEE_MATCH } from 'views/connections/shared/queries'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'

const GET_PRODUCTS_STATUS = gql`
  query GetOrganizationInfoProductStatus {
    getOrganization {
      id
      name
      productsStatus
      organizationProfileInfo
      shippingAddress
      settings
    }
  }
`
const GET_USERS_PRODUCT_PREFERENCES = gql`
  query GetOrganizationProviders($filter: FilterUsersInput) {
    getUsers(filter: $filter) {
      id
      roles
      productPreferences
    }
  }
`

export default function GetOrganizationInfo() {
  const dispatch = useDispatch()
  const {
    hasPaidSspSubscription,
    hasOrgSspCertificationEnrolled,
    hasOrgSspCertificationCompleted,
    hasOrgRrpCertificationEnrolled,
    hasOrgRrpCertificationCompleted,
    hasOrgFocusCertificationEnrolled,
    hasOrgFocusCertificationCompleted,
    isProcessingPayment,
  } = useSelector((state) => get(state, 'organization', {}))
  const showProcessingCard = useSelector((state) => get(state, 'ff.showProcessingCard', false))
  const hasAssessmentProducts = useSelector((state) =>
    get(state, 'ff.hasAssessmentProducts', false)
  )
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isLoggedIn = useSelector((state) => get(state, 'auth.user', null))
  const isBillingUser = currentUserRoles?.some((role) => roles.BILLING === role)

  // also skip if you are not logged in..
  const { refetch: refetchOrgInfo } = useQuery(GET_USERS_PRODUCT_PREFERENCES, {
    fetchPolicy: 'cache-and-network',
    // skip if you are not in billing
    variables: { filter: { anyRoles: ['provider', 'provider_all_clients'] } },
    skip: !isBillingUser || !isLoggedIn,
    onCompleted: (data) => {
      // check if there exist a user with a completed certification
      const hasOrgSspCertificationCompleted = data?.getUsers.some(
        ({ productPreferences }) => !!productPreferences?.sspCertification?.completedAt
      )
      const hasOrgRrpCertificationCompleted = data?.getUsers.some(
        ({ productPreferences }) => !!productPreferences?.rrpCertification?.completedAt
      )
      const hasOrgFocusCertificationCompleted = data?.getUsers.some(
        ({ productPreferences }) => !!productPreferences?.focusCertification?.completedAt
      )
      const hasOrgSspCertificationEnrolled = data?.getUsers.some(
        ({ productPreferences }) => !!productPreferences?.sspCertification?.enrolledAt
      )
      const hasOrgRrpCertificationEnrolled = data?.getUsers.some(
        ({ productPreferences }) => !!productPreferences?.rrpCertification?.enrolledAt
      )
      const hasOrgFocusCertificationEnrolled = data?.getUsers.some(
        ({ productPreferences }) => !!productPreferences?.focusCertification?.enrolledAt
      )

      // check how many providers are in org
      const numberOfProviders = get(data, 'getUsers', []).length
      dispatch(
        setOrganizationValue({
          hasOrgSspCertificationCompleted,
          hasOrgRrpCertificationCompleted,
          hasOrgFocusCertificationCompleted,
          hasOrgSspCertificationEnrolled,
          hasOrgRrpCertificationEnrolled,
          hasOrgFocusCertificationEnrolled,
          refetch: refetchOrgInfo,
          loadedOrganization: true,
          numberOfProviders,
        })
      )
    },
  })

  const handleOnCompleted = async (data) => {
    const organizationProfileInfo = get(data, 'getOrganization.organizationProfileInfo', {})
    const name = get(data, 'getOrganization.name', '')
    const shippingAddress = get(data, 'getOrganization.shippingAddress', '')
    const products = get(data, 'getOrganization.productsStatus', {})
    const id = get(data, 'getOrganization.id', {})

    const hasPaidSspSubscription = Object.keys(products).some(
      (key) => ALL_SSP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'paid'
    )
    const hasUnpaidSspSubscription = Object.keys(products).some(
      (key) => ALL_SSP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'unpaid'
    )
    const hasPastDueSspSubscription = Object.keys(products).some(
      (key) => ALL_SSP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'past_due'
    )
    const hasPendingSspSubscription = Object.keys(products).some(
      (key) =>
        ALL_SSP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'pending_certification'
    )

    const hasPaidRrpSubscription = Object.keys(products).some(
      (key) => ALL_RRP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'paid'
    )
    const hasUnpaidRrpSubscription = Object.keys(products).some(
      (key) => ALL_RRP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'unpaid'
    )
    const hasPastDueRrpSubscription = Object.keys(products).some(
      (key) => ALL_RRP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'past_due'
    )
    const hasPendingRrpSubscription = Object.keys(products).some(
      (key) =>
        ALL_RRP_SUBSCRIPTION_SKUS.includes(key) && products[key].status === 'pending_certification'
    )

    // ILS
    const hasFocusSubscription = Object.keys(products).some((key) =>
      ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(key)
    )
    const hasPaidFocusSubscription = Object.keys(products).some(
      (key) => ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(key) && products[key].status === 'paid'
    )
    const hasUnpaidFocusSubscription = Object.keys(products).some(
      (key) => ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(key) && products[key].status === 'unpaid'
    )
    const hasPastDueFocusSubscription = Object.keys(products).some(
      (key) => ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(key) && products[key].status === 'past_due'
    )
    const hasPendingFocusSubscription = Object.keys(products).some(
      (key) =>
        ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(key) &&
        products[key].status === 'pending_certification'
    )

    // only check subscriptions,
    const sspSubscriptions = Object.keys(products).filter((key) =>
      ALL_SSP_SUBSCRIPTION_SKUS.includes(key)
    )

    const focusSubscriptions = Object.keys(products).filter((key) =>
      ALL_FOCUS_SUBSCRIPTIONS_SKUS.includes(key)
    )
    const rrpSubscriptions = Object.keys(products).filter((key) =>
      ALL_RRP_SUBSCRIPTION_SKUS.includes(key)
    )

    // if you have one canceled and one active subscription, you should not be seeing no products card
    const hasAllCanceledSspSubscription =
      !!sspSubscriptions.length &&
      sspSubscriptions.every((key) => products[key].status === 'canceled')
    const hasAllCanceledFocusSubscription =
      !!focusSubscriptions.length &&
      focusSubscriptions.every((key) => products[key].status === 'canceled')
    const hasAllCanceledRrpSubscription =
      !!rrpSubscriptions.length &&
      rrpSubscriptions.every((key) => products[key].status === 'canceled')

    const isProcessingPayment = get(data, 'getOrganization.settings.processingPurchase', false)

    await dispatch(
      setOrganizationValue({
        products,
        hasPaidSspSubscription,
        hasUnpaidSspSubscription,
        hasPastDueSspSubscription,
        hasPendingSspSubscription,
        hasPaidRrpSubscription,
        hasUnpaidRrpSubscription,
        hasPastDueRrpSubscription,
        hasPendingRrpSubscription,
        hasAssessmentProducts,
        hasFocusSubscription,
        hasPaidFocusSubscription,
        hasUnpaidFocusSubscription,
        hasPastDueFocusSubscription,
        hasPendingFocusSubscription,
        organizationProfileInfo,
        name,
        shippingAddress,
        id,
        hasAllCanceledFocusSubscription,
        hasAllCanceledSspSubscription,
        hasAllCanceledRrpSubscription,
        isProcessingPayment,
        hasOrgSspCertificationCompleted,
        hasOrgRrpCertificationCompleted,
        hasOrgFocusCertificationCompleted,
      })
    )
  }

  const { refetch: refetchProductStatus, stopPolling } = useQuery(GET_PRODUCTS_STATUS, {
    fetchPolicy: 'no-cache',
    skip: hasPaidSspSubscription || !isLoggedIn,
    pollInterval: 1000, // 1s
    onCompleted: (data) => {
      handleOnCompleted(data)
    },
  })

  const isProvider = currentUserRoles?.some((role) => roles.PROVIDER_ROLES.includes(role))
  const providerProfileInfo = useSelector((state) =>
    get(state, 'auth.user.providerProfileInfo', {})
  )
  const tags = useSelector((state) => get(state, 'auth.user.tags', []))
  const clientConnectionsEnabled =
    isProvider &&
    providerProfileInfo.openToNewClients &&
    tags.some((tag) => tag.value === 'has_client_connection')

  useQuery(GET_ACTIVE_CONNECTEE_MATCH, {
    fetchPolicy: 'cache-and-network',
    skip: !clientConnectionsEnabled,
    variables: {
      sort: [['createdAt', 'DESC']],
      limit: 1,
      filter: {
        status: [...STATUSES_IN_PROGRESS, EXPIRED],
      },
    },
    onCompleted: (data) => {
      if (data.getConnecteeMatches.connecteeMatches.length) {
        const lastMatch = data.getConnecteeMatches.connecteeMatches[0]

        if (
          STATUSES_IN_PROGRESS.includes(lastMatch.status) ||
          (lastMatch.status === EXPIRED && providerProfileInfo.showExpiredConnecteeCards)
        ) {
          dispatch(setFF({ showClientConnectionsBadge: true }))
        }
      }
    },
  })

  useEffect(() => {
    const refetchOrgInfoOnFocus = () =>
      isLoggedIn &&
      ((hasOrgSspCertificationEnrolled && !hasOrgSspCertificationCompleted) ||
        (hasOrgRrpCertificationEnrolled && !hasOrgRrpCertificationCompleted) ||
        (hasOrgFocusCertificationEnrolled && !hasOrgFocusCertificationCompleted) ||
        isProcessingPayment) &&
      refetchOrgInfo()

    window.addEventListener('focus', refetchOrgInfoOnFocus)
    return () => {
      window.removeEventListener('focus', refetchOrgInfoOnFocus)
    }
    // eslint-disable-next-line
  }, [
    hasOrgSspCertificationEnrolled,
    hasOrgSspCertificationCompleted,
    hasOrgRrpCertificationEnrolled,
    hasOrgRrpCertificationCompleted,
    hasOrgFocusCertificationEnrolled,
    hasOrgFocusCertificationCompleted,
    isLoggedIn,
  ])

  useEffect(() => {
    const refetchProductStatusOnFocus = () => {
      if (isLoggedIn && !hasPaidSspSubscription && isProcessingPayment) {
        refetchProductStatus().then(({ data }) => {
          handleOnCompleted(data)
        })
      }
    }
    window.addEventListener('focus', refetchProductStatusOnFocus)
    return () => {
      window.removeEventListener('focus', refetchProductStatusOnFocus)
    }
    // eslint-disable-next-line
  }, [hasPaidSspSubscription, isLoggedIn])

  useOnValueChange(JSON.stringify({ isProcessingPayment, showProcessingCard }), async () => {
    if (!showProcessingCard || !isProcessingPayment) {
      await stopPolling()
    }
  })

  return null
}
