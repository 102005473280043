/**
 * Given a Date Object, format into this format:
 * - Jun 24, 2024
 */

const DATE_OPTIONS: Intl.DateTimeFormatOptions = { day: '2-digit', month: 'short', year: 'numeric' }

export const formatDate = (date: Date) => {
  return date.toLocaleString('en-US', DATE_OPTIONS)
}

// a and b are javascript Date objects
export function dateDiffInDays(a, b) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
}

export function daysLeftInRrpTrial({ rrpSampleActivatedAt }) {
  const RRP_TRIAL_LENGTH_DAYS = 30
  const trialStartDate = new Date(rrpSampleActivatedAt)
  const todaysDate = new Date()
  
  return RRP_TRIAL_LENGTH_DAYS - dateDiffInDays(trialStartDate, todaysDate)
}

export function isLessThanXDaysAgo({ date, interval }) {
  const dateToExamine = new Date(date)
  const todaysDate = new Date()

  const difference = dateDiffInDays(dateToExamine, todaysDate)
  if (difference < interval) return true
  return false
}

export function incrementDateByXDays({ date, increment }) {
  var dateObj = new Date(date);
  var day = dateObj.getTime() + (increment * 24 * 60 * 60 * 1000);
  dateObj.setTime(day)
  return dateObj.toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"short", day:"numeric"}) 
}
