import React from 'react'
import { get } from 'lodash'
import formats from 'utils/constants/formats'
import moment from 'utils/moment'

import MainLayout from 'components/containers/main/Main'

import { useQuery } from 'utils/apollo'

import { gql } from '@apollo/client'

import { UnyteTable } from 'components'

const GET_LICENSES = gql`
  query($filter: FilterLicensesInput) {
    getLicenses(filter: $filter) {
      id
      createdAt
      isActive
      product {
        id
        name
      }
    }
  }
`

function LicensesTable() {
  const { loading, data: response } = useQuery(GET_LICENSES)
  const data = get(response, 'getLicenses', [])

  return (
    <div className="px-5 pt-5">
      <UnyteTable
        pending={loading}
        data={!loading ? data : []}
        columns={[
          {
            header: () => 'Programs',
            body: ({ data }) => data.product.name,
          },
          {
            header: () => 'Active',
            body: ({ data }) => (data.isActive ? 'True' : 'False'),
          },
          {
            header: () => 'Created At',
            body: ({ data }) => moment(data.createdAt).format(formats.date),
          },
        ]}
      />
    </div>
  )
}

export default function Licenses() {
  return (
    <MainLayout title="Licenses">
      <LicensesTable />
    </MainLayout>
  )
}
