import React from 'react'
import { Stepper, Step, StepLabel, Stack, Typography, Grid } from 'components'
import { useSelector } from 'react-redux'
import useGetSteps from '../utils/useGetSteps'

export default function HorizontalLabelPositionBelowStepper({ hideProgress }) {
  const activeStep = useSelector((state) => state.newPurchase.step)

  const steps = useGetSteps().filter(
    ({ isActive, label }) => Boolean(isActive) && Boolean(label) && label !== 'Not Found'
  )

  return (
    <Grid
      container
      maxWidth="100%"
      sx={{ backgroundColor: '#fff' }}
      direction={{ xs: 'column', md: 'row' }}
    >
      <Grid item xs={4}>
        <Stack
          direction="row"
          p={2}
          spacing={2}
          alignItems="center"
          justifyContent={{ xs: 'center', md: 'flex-start' }}
        >
          <img src="/images/unyte-logo.png" alt="Logo" style={{ width: '100px' }} />
          <Typography variant="body1" pt="10px" color="#a7adaa" fontSize="20px">
            Secure Checkout
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={4} m={2}>
        {!hideProgress && (
          <Stepper py={6} activeStep={activeStep} alternativeLabel>
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </Grid>
      <Grid item xs={4} />
    </Grid>
  )
}
