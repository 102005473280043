const globalStyles = `
    @media print {
      html,
      body {
        height: initial !important;
        width: 1280px !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
        margin: 5mm !important;
        padding: 0 0 20px 0 !important;
      }
    }

    @media print {
      table {
        page-break-inside: auto;
        width: auto !important;
      }
    }

    @media all {
      div.sv-row {
        display: none;
      }
    }
  
    @media print {
      div.sv-row {
        display: block !important;
        page-break-before: auto;
      }

      .sv-page {
        width: 95% !important;
        margin-right: 5mm;
      }
    }
  }
  `

export default globalStyles
