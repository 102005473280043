/**
 * This component will be used in the progress bar, and the getting started app
 */
import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Stepper, Step, StepLabel, StepConnector } from 'components'

const useStyles = makeStyles({
  root: (props) => ({
    width: '100%',
    backgroundColor: props.backgroundColor || '#FFF',
  }),
  stepLabel: {
    minHeight: 48,
  },
})

/**
 * We add StepContent here to provide spacing
 * If there is no activeStep, then everything is active (progress bar vs steps)
 */
export default ({
  steps,
  activeStep,
  orientation = 'vertical',
  backgroundColor,
  connector = <StepConnector />,
  styleChange,
}) => {
  const classes = useStyles({ backgroundColor })

  const active = !activeStep

  return (
    <Stepper
      activeStep={activeStep}
      orientation={orientation}
      className={`${classes.root} p-0 `}
      connector={connector}
    >
      {steps.map((label, index) => (
        <Step key={index} active={active} className="pt-2 pb-2">
          <StepLabel className={classes.stepLabel} sx={styleChange}>
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
