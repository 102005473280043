import { Button, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'

export default function Information({ videoURL, videoTitle, content, onNext, setHidePrintButton }) {
  useEffect(() => {
    setHidePrintButton(true)
  }, [])

  return (
    <>
      <Stack direction="column">
        {videoURL && (
          <iframe
            title={videoTitle}
            src={videoURL}
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
            style={{ width: '600px', height: '350px', alignSelf: 'center' }}
          />
        )}
        <Stack direction="row-reverse" m="1rem" pr="2rem">
          <Button variant="contained" onClick={onNext}>
            Continue
          </Button>
        </Stack>
        <Stack direction="column" width={'70%'} pt="1rem" alignSelf="center">
          {content.map((point, index) => (
            <Typography key={`titration-tool-info-${index}`} variant="body1" align="center" mb={2}>
              {point}
            </Typography>
          ))}
        </Stack>
      </Stack>
    </>
  )
}
