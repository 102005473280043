import React from 'react'
import { Chip, Button, CardContent, Stack, CardActions } from '@mui/material'
import { getClientStatus } from './basic-info-constants'
import { useFormContext } from 'react-hook-form'
import { CardContentItem, PreviewCard } from '../components/preview-card'

export const PreviewBasicInfo: React.FC = () => {
  const { watch, setValue } = useFormContext()
  const statuses = getClientStatus(watch('email'), watch('isInvitationMode'))

  const fullName =
    watch('firstName') && watch('lastName') ? `${watch('firstName')} ${watch('lastName')}` : ''

  const handleClick = (buttonType: string) => () => {
    setValue('viewState', 'EDIT')
    if (buttonType === 'enable-remote-access') {
      setValue('isRemoteAccessEnabled', true)
    }
  }

  return (
    <PreviewCard title="Basic Info" uuid={watch('uuid')}>
      <CardContent>
        <Stack spacing={2}>
          <CardContentItem label="Full Name" value={fullName} fontWeight={500} />
          <CardContentItem label="Email" value={watch('email') || 'no remote access'}>
            <Stack spacing={1} py={{ xs: 1, sm: 0 }}>
              {statuses.map((status) => (
                <Chip
                  key={`${status.label}-chip`}
                  label={status.label}
                  color="primary"
                  sx={{
                    backgroundColor: status.color,
                  }}
                />
              ))}
            </Stack>
          </CardContentItem>
        </Stack>
      </CardContent>
      <CardActions>
        <Button onClick={handleClick('edit-basic-info')} key="edit-button">
          Edit Basic Info
        </Button>

        {!watch('isRemoteAccessEnabled') && (
          <Button onClick={handleClick('enable-remote-access')} key="enable-remote-access-button">
            Enable Remote Access
          </Button>
        )}
      </CardActions>
    </PreviewCard>
  )
}
