import { clone } from 'lodash'

const deepTrim = (_obj) => {
  let obj = clone(_obj)

  for (var prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      if (typeof obj[prop] == 'string') {
        const isEmail = prop === 'email' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(obj[prop])
        obj[prop] = isEmail
          ? obj[prop]
              .trim()
              .toLowerCase()
              .replace(/‏+/, '')
              .replace(/\u202c+/, '')
          : obj[prop]
              .trim()
              .replace(/‏+/, '')
              .replace(/\u202c+/, '')
      } else if (typeof obj[prop] == 'object') {
        obj[prop] = deepTrim(obj[prop])
      }
    }
  }

  return obj
}

export default deepTrim

export const trimString = (str, type) => {
  const isEmail = type === 'email' || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str)
  return isEmail
    ? str
        .trim()
        .toLowerCase()
        .replace(/‏+/, '')
        .replace(/\u202c+/, '')
    : str
        .trim()
        .replace(/‏+/, '')
        .replace(/\u202c+/, '')
}
