import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

import { InputAdornment, IconButton, FormControl, FormHelperText, InputLabel } from 'components'
import CustomInput from './CustomInput'

export default function PasswordInput({
  label,
  onChange,
  error,
  value,
  className,
  style = {},
  disabled,
  ...props
}) {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <FormControl className={className} style={{ width: '100%', ...style }}>
      <InputLabel variant="outlined" error={!!error} sx={{ backgroundColor: 'white' }}>
        {label}
      </InputLabel>
      <CustomInput
        label="Password"
        disabled={disabled}
        style={{ marginBottom: 0 }}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setShowPassword(!showPassword)}
              onMouseDown={(e) => e.preventDefault()}
              data-test="show-password-button"
              size="large"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        {...props}
        error={!!error}
        onChange={(e) => onChange({ ...e, target: { ...e.target, value: e.target.value } })}
      />
      {error && (
        <FormHelperText data-test="password-error" error={!!error}>
          {error}
        </FormHelperText>
      )}
    </FormControl>
  )
}
