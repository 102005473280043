import { useState, useEffect } from 'react'

export default (getter, watchArgs = []) => {
  const [val, setVal] = useState(getter())
  useEffect(() => {
    setVal(getter())
    // eslint-disable-next-line
  }, [...watchArgs])

  return [val, setVal]
}
