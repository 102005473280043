/**
 * Message content for clients page when organization has converted from old plan to new plan
 */
import React from 'react'
import {
    Stack,
    Typography,
    Box,
    Link,
} from 'components'
import Brightness1Icon from '@mui/icons-material/Brightness1'

interface OrganizationConvertedToNewPlanMessageProps {
    productText: string
}

export const OrganizationConvertedToNewPlanMessage: React.FC<OrganizationConvertedToNewPlanMessageProps> = ({
    productText
}) => {
    const showActiveClientsMessage = productText.toLowerCase().includes('ssp')
    return (
        <Stack direction="column" spacing={2} px={8} mt={4}>
            <Typography variant="body1">
                Your subscription has been changed to a simpler licensed-based model.
                <br />
                { showActiveClientsMessage &&
                <Typography variant="body1">
                    Good news! You will no longer incur active clients charges for more than 5 active clients per month.
                </Typography>
                }
            </Typography>
            <Typography
                align="center"
                variant="body1"
                textTransform="capitalize"
                className='text-orange'
            >
                <strong>ACTION REQUIRED</strong> FOR <strong>IN-PERSON CLIENTS</strong>
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center" pl={1}>
                <Brightness1Icon className='text-orange' sx={{fontSize: '0.875rem'}} />
                <Typography variant="body1">
                    If you have <strong>in-person clients</strong>:
                </Typography>
            </Stack>
            <Stack direction="column" pl={4}>
                <Typography variant="body1">
                    <strong>BEFORE: in-person clients</strong> were automatically assigned {productText}.
                </Typography>
                <Typography variant="body1">
                    <strong>NOW:</strong> you{' '}
                    <strong>
                        <u>must</u>
                    </strong>{' '}
                    manually assign {productText} to your current{' '}
                    <strong>in-person clients.</strong>
                </Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" pl={1}>
                <Brightness1Icon className='text-orange' sx={{fontSize: '0.875rem'}} />
                <Typography variant="body1">
                    If you have <strong>remote clients</strong>:
                </Typography>
            </Stack>
            <Stack direction="column" pl={4}>
                <Typography variant="body1">
                    We have automatically assigned licenses to your currently active{' '}
                    <strong>remote clients.</strong>
                </Typography>
                <Typography variant="body1">Their listening experience will not be interrupted.</Typography>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center" pl={1} pt={3}>
                <Brightness1Icon className='icon-green' sx={{fontSize: '0.875rem'}} />
                <Typography variant="body1">
                    <Link to="/billing/how-client-licenses-work" className="text-link">
                        Here's How Client Licensing Works
                    </Link>
                    .
                </Typography>
            </Stack>
            <Box pt={3}>
                <Typography variant="body1">
                    For more help with this change, please{' '}
                    <a
                        className="text-link"
                        target="_blank"
                        rel="noreferrer"
                        href="https://connect.unyte.com/meetings/unyte/success-mu"
                    >
                        book a call
                    </a>{' '}
                    or contact us at{' '}
                    <a
                        className="text-link"
                        target="_blank"
                        rel="noreferrer"
                        href="mailto:success@unyte.com"
                    >
                        success@unyte.com
                    </a>
                </Typography>
            </Box>
        </Stack>
    )
}