import React, { useState, useRef } from 'react'
import { DatePicker } from '@material-ui/pickers'
import moment from 'utils/moment'

export default function DateRangePicker({
  value = [],
  onChange = () => null,
  labelFunc,
  format,
  emptyLabel,
  autoOk,
  onClose,
  ...props
}) {
  const MIN_DATE = moment('11.01.17')
  const MAX_DATE = moment()

  const [begin, setBegin] = useState(value[0])
  const [end, setEnd] = useState(value[1])
  const [hover, setHover] = useState(null)
  const picker = useRef()

  const min = Math.min(begin, end || hover)
  const max = Math.max(begin, end || hover)

  return (
    <DatePicker
      {...props}
      value={null}
      disableFuture={true}
      renderDay={(day, selectedDate, dayInCurrentMonth, dayComponent) => {
        const isDisabled = day < MIN_DATE || day > MAX_DATE
        const style = {
          width: 36,
          height: 36,
          margin: 0,
          borderRadius: 0,
          transition: 0,
          backgroundColor: isDisabled ? '#eee' : '#fff',
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          color: '#000',
        }

        if (day >= min && day <= max) {
          style.backgroundColor = `rgba(3, 155, 229, ${day === min || day === max ? '1' : '.5'}`
          style.color = 'white'
        }

        return React.cloneElement(dayComponent, {
          onClick: (e) => {
            e.stopPropagation()
            let selectedDay = day
            if (selectedDay < MIN_DATE) {
              selectedDay = MIN_DATE
            }
            if (selectedDay > MAX_DATE) {
              selectedDay = MAX_DATE
            }

            if (!begin) setBegin(selectedDay)
            else if (!end) {
              setEnd(selectedDay)
              if (autoOk) {
                onChange([begin, selectedDay].sort((a, b) => (a > b ? 1 : -1)))
              }
            } else {
              setBegin(selectedDay)
              setEnd(null)
            }
          },
          onMouseEnter: (e) => {
            let selectedDay = day
            if (selectedDay < MIN_DATE) {
              selectedDay = MIN_DATE
            }
            if (selectedDay > MAX_DATE) {
              selectedDay = MAX_DATE
            }
            setHover(selectedDay)
          },
          style,
          disabled: day < MIN_DATE,
        })
      }}
      onChange={() => null}
      ref={picker}
    />
  )
}
