import React from 'react'
import { includesSome } from 'utils/includes'
import { Grid, Card, CardHeader, CardContent, Typography, Link, Box } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import ROLES from 'utils/constants/roles'
import { useSelector } from 'react-redux'

const useStyles = makeStyles({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
  link: {
    color: '#616161',
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    margin: 'auto',
  },
  action: {
    fontWeight: 500,
  },
})

/**
 * This card is displayed in the dashboard when a user has status past due
 * type can be SSP or ILS
 */
export default function PastDueCard({ gridProps, type }) {
  const classes = useStyles()
  const { roles } = useSelector((state) => state.auth.user)
  const isBilling = includesSome(roles, [ROLES.BILLING])

  return (
    <Grid item {...gridProps}>
      <Card
        data-test={`dashboard-card-${type.toLowerCase()}-subscription-past-due`}
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          title={`${type} Subscription Payment Overdue`}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Box display="flex" p={0.5}>
            <div className="w-3 m-auto">
              <div className={`${classes.circle} text-orange`} />
            </div>
            <Typography variant="body2" className="py-1 px-3 w-full">
              Your {type} subscription payment is now overdue.
            </Typography>
          </Box>
          {isBilling && (
            <Typography variant="body2" className="py-1">
              Please review and pay any outstanding invoices under{' '}
              <Link to="/billing" className="text-link">
                View Billing
              </Link>{' '}
              to ensure access to the {type} is not interrupted for you and your clients.
            </Typography>
          )}
          {!isBilling && (
            <Typography variant="body2" className="py-1">
              Please request that your plan administrator review and pay any outstanding invoices.
            </Typography>
          )}
          <Typography variant="body2" className="py-1">
            For assistance, please contact our Client Success team at{' '}
            <a className="text-link" href="mailto:success@unyte.com">
              success@unyte.com
            </a>
            .
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
