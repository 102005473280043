import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import CloseSnackbarAction from './CloseSnackbarAction'

export default () => {
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    const timer = setTimeout(
      () => {
        if (document.hasFocus()) {
          enqueueSnackbar('Page will be reloaded in 1 minute', { action: CloseSnackbarAction })
          setInterval(() => {
            // setting to true here will refresh the cache
            window.location.reload(true)
          }, 60 * 1000)
        } else {
          window.location.reload(true)
        }
      },
      moment()
        .add(1, 'day')
        .startOf('day') - moment()
    )
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [])

  return null
}
