/**
 * Products here are displayed in a grid
 */
import React from 'react'
import { Grid, Card, CardContent, Typography, IconButton } from 'components'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useSelector } from 'react-redux'
import useCart from '../../new-purchase/utils/useCart'

export default function ClientLicenseProduct({ product: { SKU, PriceTier1, Name, testName } }) {
  // if quantity > 1 show cart
  const { clientLicenses = {} } = useSelector((state) => state.newPurchase.data)
  const quantity = clientLicenses[SKU] || 0
  const { handleEditClientLicenseProducts } = useCart()

  const onAdd = () => {
    handleEditClientLicenseProducts(SKU, 'add')
  }

  const onSubtract = () => {
    if (quantity > 0) {
      handleEditClientLicenseProducts(SKU, 'subtract')
    }
  }

  return (
    <Grid item xs={12} md={6} lg={4} key={SKU} data-test={testName}>
      <Card elevation={0} variant="outlined" className="w-full">
        <CardContent>
          <Typography gutterBottom variant="body1" component="h2">
            {Name}
          </Typography>
        </CardContent>
        <Grid
          aria-label="card-actions"
          container
          justifyContent="space-around"
          alignItems="center"
          className="pb-3"
        >
          <Grid item xs={4} className="m-auto">
            <Typography variant="h5" component="h2" className="w-10 m-auto" align="center">
              ${PriceTier1}
            </Typography>
          </Grid>
          <Grid item xs={8} container direction="row" justifyContent="center">
            <IconButton
              color="primary"
              aria-label="subtract product"
              component="span"
              onClick={onSubtract}
              size="large"
            >
              <RemoveIcon />
            </IconButton>
            <Typography variant="h5" component="h2" className="w-10 my-auto" align="center">
              {quantity}
            </Typography>
            <IconButton
              color="primary"
              aria-label="add product"
              component="span"
              onClick={onAdd}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}
