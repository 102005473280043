import React from 'react'
import { Typography } from '@mui/material'
import EMAILS from '../../../utils/constants/emails'

export default function ContactAdministratorMessage() {
  return (
    <Typography
      variant={'subtitle1'}
      color={'red'}
      sx={{
        padding: '20px',
      }}
    >
      Please ask your plan administrator to purchase any Unyte Health products or subscriptions. For
      additional assistance, please contact our Client Success team at{' '}
      <a href={`mailto:${EMAILS.supportEmail}`} className="text-link">
        {EMAILS.supportEmail}
      </a>
      .
    </Typography>
  )
}
