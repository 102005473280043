import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
  TextField,
} from 'components'
import { makeStyles } from '@mui/styles'
import CloseIcon from '@mui/icons-material/Close'

const useStyles = makeStyles({
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#9e9e9e',
  },
})

function DialogTitleWithClose(props) {
  const { children, classes, onClose, ...other } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
}

export default function LeaveCommentDialog({
  isOpen,
  handleClose,
  onCommentInput,
  onSubmit,
  buttonText = 'Submit',
}) {
  const classes = useStyles()

  return (
    <Dialog fullWidth maxWidth={'sm'} open={isOpen} onClose={handleClose}>
      <DialogTitleWithClose id="comment-dialog-title" classes={classes} onClose={handleClose}>
        <Typography variant="h5" color="primary">
          Please tell us more about why you felt that way
        </Typography>
      </DialogTitleWithClose>
      <DialogContent>
        <DialogContentText>
          <TextField
            inputProps={{ maxLength: 1000 }}
            label="Leave your comment here"
            multiline
            onChange={onCommentInput}
            rows={5}
            variant="outlined"
          />
        </DialogContentText>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            {buttonText}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
