import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { logout } from '../../store/modules/auth'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useQuery as _useQuery } from '@apollo/client'

export * from '@apollo/client'

export const useQuery = (...args) => {
  const { error, ...rest } = _useQuery(...args)
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  useEffect(() => {
    if (error) {
      if (error.message === 'Observable cancelled prematurely') {
        console.warn(error)
      } else if (error.message === 'User not found') {
        dispatch(logout())
        navigate('/sign-in')
        enqueueSnackbar('User not found, please sign in again', {
          variant: 'error',
          action: CloseSnackbarAction,
        })
      } else {
        console.error(JSON.stringify(error))
        const HAS_INTERNAL_SERVER_ERROR =
          error.graphQLErrors[0]?.extensions?.code === 'INTERNAL_SERVER_ERROR'
        if (HAS_INTERNAL_SERVER_ERROR) {
          enqueueSnackbar('Oops! Something went wrong. Please try again later.', {
            variant: 'warning',
            action: CloseSnackbarAction,
          })
        } else {
          enqueueSnackbar('Server error, try again', {
            variant: 'error',
            action: CloseSnackbarAction,
          })
        }
      }
    }
  }, [error, enqueueSnackbar, navigate, dispatch])

  return { error, ...rest }
}
