/**
 * All math calculations for cart is calculated here
 */
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import {
  ALL_VALID_SKUS,
  BUNDLE_PROMO_CODES,
  PRODUCTS,
  BUNDLE_PROMO_SKUS,
  INTERNAL_PURCHASE_PROMOS,
} from 'utils/constants/prices'
import { useDispatch } from 'react-redux'
import { extendData } from 'store/modules/new-purchase'

// we duplicate the state in redux and localStorage because user chooses items from external site
export default () => {
  // redux
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.newPurchase.data)
  const products = useSelector((state) => get(state, 'newPurchase.data.products', []))
  const organization = useSelector((state) => get(state, 'organization', []))

  // parse redux
  const skus = reduxData.onboardingProducts
  const selectedShipstationRate = reduxData.selectedServiceCode || '' // we will only use redux data
  const onboardingProducts = skus.map((sku) => PRODUCTS[sku])
  const isInternalPurchaseRedux = reduxData.isInternalPurchase
  const selectedAddOns = reduxData.selectedAddOns
  const selectedAddOnsArray = products
    .map(({ SKU, PriceTier1, Name, Weight, WeightUnits }) => ({
      name: Name,
      price: PriceTier1,
      quantity: get(selectedAddOns, SKU, 0),
      weight: Weight,
      weightUnits: WeightUnits,
      sku: SKU,
      tax: 0,
      total: get(selectedAddOns, SKU, 0) * PriceTier1,
    }))
    .filter(({ quantity }) => Boolean(quantity))

  const clientLicenses = reduxData.clientLicenses
  const clientLicensesArray = products
    .map(({ SKU, PriceTier1, Name }) => ({
      name: Name,
      price: PriceTier1,
      quantity: get(clientLicenses, SKU, 0),
      sku: SKU,
      tax: 0,
      total: get(clientLicenses, SKU, 0) * PriceTier1,
    }))
    .filter(({ quantity }) => Boolean(quantity))

  const shipstationRates = (reduxData.shipstationRates || [{ serviceCode: '' }]).filter(
    ({ serviceCode }) => selectedShipstationRate && serviceCode === selectedShipstationRate
  )

  // handles adding and subtracting, will also remove shipping when price changes
  function handleEditProducts(SKU, type) {
    let newQuantity = get(selectedAddOns, SKU, 0)

    if (type === 'add') {
      newQuantity = newQuantity + 1
    } else if (type === 'subtract') {
      newQuantity = Math.max(newQuantity - 1, 0)
    }

    // if all items have 0 quantity, then also remove shipping
    const newSelectedAddOns = { ...selectedAddOns, [SKU]: newQuantity }
    const newSelectedAddOnsArray = products.map(
      ({ SKU, PriceTier1, Name, Weight, WeightUnits }) => ({
        name: Name,
        price: PriceTier1,
        weight: Weight,
        weightUnits: WeightUnits,
        sku: SKU,
        tax: 0,
        total: get(selectedAddOns, SKU, 0) * PriceTier1,
        quantity: newQuantity,
      })
    )
    const totalWeight = newSelectedAddOnsArray.reduce(
      (totalweight, { weight, sku }) => totalweight + weight * (newSelectedAddOns[sku] || 0),
      0
    )

    // if total weight exceeds 150 lbs, then don't add
    if (totalWeight <= 150) {
      dispatch(
        extendData({
          selectedAddOns: newSelectedAddOns,
          selectedAddOnsArray: newSelectedAddOnsArray,
          selectedServiceCode: '',
        })
      )
    }

    // remove all shipping information if cart is empty
    const emptyCart = Object.keys(newSelectedAddOns).every((key) => newSelectedAddOns[key] === 0)
    if (emptyCart) {
      extendData({
        selectedServiceCode: '',
      })
    }
  }

  function handleEditClientLicenseProducts(SKU, type) {
    let newQuantity = get(clientLicenses, SKU, 0)
    if (type === 'add') {
      newQuantity = newQuantity + 1
    } else if (type === 'subtract') {
      newQuantity = Math.max(newQuantity - 1, 0)
    }

    const newClientLicenses = { ...clientLicenses, [SKU]: newQuantity }

    const newClientLicenseProductsArray = products.map(({ SKU, PriceTier1, Name }) => ({
      name: Name,
      price: PriceTier1,
      sku: SKU,
      tax: 0,
      total: get(clientLicenses, SKU, 0) * PriceTier1,
      quantity: newQuantity,
    }))

    dispatch(
      extendData({
        clientLicenses: newClientLicenses,
        clientLicensesArray: newClientLicenseProductsArray,
      })
    )
  }

  // if onboardingProducts is incorrect, return
  const checkSkus = () =>
    onboardingProducts.every((product) => product && ALL_VALID_SKUS.includes(product.sku))

  if (!checkSkus()) {
    return {
      subscriptions: [],
      chargedProducts: [],
      subtotalCharge: 0,
      totalCharged: 0,
      shipstationRates: [],
      skus: [],
      selectedAddOns: {},
      handleEditProducts,
      handleEditClientLicenseProducts,
      selectedAddOnsArray: [],
      clientLicensesArray: [],
      weight: {
        value: 0,
        units: 'lb',
      },
      discount: 0,
      tax: 0,
      promoDetails: [],
    }
  }

  // why are we filtering this then displaying it...
  const subscriptions = onboardingProducts.filter(
    ({ type, price }) => type === 'subscription' && price
  )
  const chargedProducts = onboardingProducts.filter(({ type }) =>
    ['digitalProduct', 'physicalProduct', 'clientLicense'].includes(type)
  )

  // PROMOS
  // check for promo and add its according prices (there should only be one promo)
  const promo = onboardingProducts.filter(({ type }) => type === 'promo')
  const checkPromo = !!promo.length
  const promoSku = get(promo, '[0].sku')
  const promoSkus = (checkPromo && BUNDLE_PROMO_SKUS[promoSku]) || []
  const promoDetails = promoSkus.map((sku) => PRODUCTS[sku])
  const totalPromo = promoDetails.reduce((accumulator, { price }) => accumulator + price, 0)

  // Show Add-ons with quantity more than 1 in cart
  const totalChargedAddons = products.reduce(
    (sum, { SKU, PriceTier1 }) =>
      sum + PriceTier1 * get(selectedAddOns, SKU, 0) + PriceTier1 * get(clientLicenses, SKU, 0), // it will only be one or the other
    0
  )

  const totalChargedShipping =
    shipstationRates &&
    shipstationRates.reduce((sum, { shipmentCost, otherCost }) => sum + shipmentCost + otherCost, 0)

  // set the discount if certification exists in discount array
  const couponAmountOff = Math.round(get(reduxData, 'coupon.data.amountOff', 0) / 100)
  const couponPercentOff = get(reduxData, 'coupon.data.percentOff', 0)

  const totalWeight =
    selectedAddOnsArray.reduce(
      (totalweight, { weight, quantity }) => totalweight + weight * quantity,
      0
    ) + onboardingProducts.reduce((totalWeight, { weight }) => totalWeight + weight, 0)

  // calculate tax (it is in cents)
  const tax = (reduxData.totalTax || 0) / 100

  // calculate discounts
  const calculateChargesAndPromoDiscounts = (
    purchasedSkus,
    isInternalPurchase = isInternalPurchaseRedux
  ) => {
    const onboardingProducts = purchasedSkus.map((sku) => PRODUCTS[sku])
    const discountPromoCodes = isInternalPurchase ? INTERNAL_PURCHASE_PROMOS : BUNDLE_PROMO_CODES
    const validBundlePromos = discountPromoCodes.filter((bundlePromoCode) => {
      const hasRequiredExcludedSku =
        bundlePromoCode.requiredExcludedSkus &&
        bundlePromoCode.requiredExcludedSkus.some((key) => purchasedSkus.includes(key))

      if (bundlePromoCode.requiredAnyExistingSkus?.length) {
        let hasRequiredExistingSkus = false
        bundlePromoCode.requiredAnyExistingSkus.forEach((sku) => {
          if (
            ['pending_certification', 'paid', 'past_due'].includes(
              organization.products[sku]?.status
            )
          ) {
            hasRequiredExistingSkus = true
          }
        })
        if (!hasRequiredExistingSkus) {
          return false
        }
      }
      const requiredSomeSkus = !bundlePromoCode.requiredSomeSkus
        ? true
        : bundlePromoCode.requiredSomeSkus?.some((key) => purchasedSkus.includes(key))
      return (
        bundlePromoCode.requiredSkus.every((key) => purchasedSkus.includes(key)) &&
        requiredSomeSkus &&
        !hasRequiredExcludedSku
      )
    })

    const bundleDiscountItems = validBundlePromos.map((promo) => ({
      discountName: promo?.couponName,
      discountAmount: promo?.discountAmount,
      shouldApplyToSubscription: promo?.shouldApplyToSubscription,
      requiredSkus: promo?.requiredSkus,
      applyToSku: promo?.applyToSku,
    }))

    const bundleDiscount = bundleDiscountItems.reduce((totalDiscount, promo) => {
      return totalDiscount + (promo.shouldApplyToSubscription ? 0 : promo?.discountAmount)
    }, 0)

    const applySubscriptionDiscountToImmediateChargePrice = (sku, immediateChargePrice) => {
      let discount = 0
      for (const internalPromo of validBundlePromos) {
        if (
          internalPromo.shouldApplyToSubscription &&
          !internalPromo.shouldExcludeDiscountFromCartItems &&
          internalPromo.requiredSkus.includes(sku)
        )
          discount += internalPromo.discountAmount
      }
      return immediateChargePrice - discount
    }

    const totalImmediateChargePrice = onboardingProducts
      .map(({ sku, immediateChargePrice }) => ({ sku, immediateChargePrice }))
      .reduce(
        (sum, { sku, immediateChargePrice }) =>
          sum + applySubscriptionDiscountToImmediateChargePrice(sku, immediateChargePrice),
        0
      )

    const subtotalCharge =
      totalChargedAddons + totalChargedShipping + totalImmediateChargePrice + totalPromo

    const totalChargedAfterBundleDiscount =
      totalChargedAddons +
      totalChargedShipping +
      totalImmediateChargePrice +
      totalPromo -
      bundleDiscount

    return {
      subtotalCharge,
      totalChargedAfterBundleDiscount,
      shippingCost: totalChargedShipping,
      bundleDiscount,
      bundleDiscountItems,
    }
  }

  const chargedAmountsAndPromoDiscounts = calculateChargesAndPromoDiscounts(skus)

  const getTotalDiscountFromCoupon = () => {
    const skusCouponAppliesTo = get(reduxData, 'coupon.data.appliesTo', [])
    if (skusCouponAppliesTo.length) {
      return skusCouponAppliesTo.reduce((totalDiscount, discountedSku) => {
        const product = onboardingProducts.find((product) => product.sku === discountedSku)
        const couponDiscount = product
          ? (couponPercentOff / 100) * product.price ||
            (couponAmountOff > product.price ? 0 : couponAmountOff)
          : 0
        return totalDiscount + couponDiscount
      }, 0)
    } else {
      return couponAmountOff
        ? couponAmountOff
        : chargedAmountsAndPromoDiscounts.totalChargedAfterBundleDiscount * (couponPercentOff / 100)
    }
  }

  const wasCouponDiscountApplied = couponAmountOff || couponPercentOff
  const couponDiscountItems = wasCouponDiscountApplied
    ? [
        {
          discountName: 'Promotion Code Discount',
          discountAmount: getTotalDiscountFromCoupon(),
          isCouponDiscount: true,
        },
      ]
    : []

  const couponDiscount = get(couponDiscountItems, '[0].discountAmount', 0)

  const totalCharged =
    chargedAmountsAndPromoDiscounts.totalChargedAfterBundleDiscount - couponDiscount + tax
  const discount = chargedAmountsAndPromoDiscounts.bundleDiscount + couponDiscount

  const discountItems = [
    ...chargedAmountsAndPromoDiscounts.bundleDiscountItems,
    ...couponDiscountItems,
  ]

  return {
    subscriptions,
    chargedProducts,
    subtotalCharge: chargedAmountsAndPromoDiscounts.subtotalCharge,
    totalCharged,
    shipstationRates,
    skus: [],
    selectedAddOns,
    handleEditProducts,
    handleEditClientLicenseProducts,
    selectedAddOnsArray,
    clientLicensesArray,
    // shipping
    weight: {
      value: Math.max(totalWeight, 1),
      units: 'lb',
    },
    discountItems,
    discount,
    tax,
    promoDetails,
    shippingCost: chargedAmountsAndPromoDiscounts.shippingCost,
    calculateChargesAndPromoDiscounts,
  }
}
