const SET_OPEN = 'HUBSPOT/OPEN'
const SET_LOADED = 'HUBSPOT/LOAD'
const SET_UNREAD_COUNT = 'HUBSPOT/UNREAD_COUNT'

const initialState = {
  openHubspot: false,
  loaded: false,
  unreadCount: 0,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN:
      return {
        ...state,
        openHubspot: action.payload,
      }
    case SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      }
    case SET_UNREAD_COUNT:
      return {
        ...state,
        unreadCount: action.payload,
      }
    default:
      return state
  }
}

export function setOpenHubspot(payload) {
  return {
    type: SET_OPEN,
    payload,
  }
}

export function setLoaded(payload) {
  return {
    type: SET_LOADED,
    payload,
  }
}

export function setUnreadCount(payload) {
  return {
    type: SET_UNREAD_COUNT,
    payload,
  }
}
