// NOTE: If on the spreadsheet any of these fields are not available, set the field to 'N/A'
/* Regulating Activities Fields (Keys) Documentation:
 {
    activityName: regulating activity card title,
    activityVideoSrc: source of the regulating activity video,
    category: regulating activity category,
    steps: [ARRAY], steps of the activity
    rationale: [ARRAY] replaced modifications for the regulating activity
  }
*/

export default [
  {
    activityName: '4-part Box Breath',
    activityVideoSrc: '//player.vimeo.com/video/889262346',
    category: 'Breathing',
    additionalDescription: '*Do not hold your breath if you are pregnant or feel lightheaded.',
    steps: [
      'Slowly exhale, emptying your lungs.',
      'Inhale slowly and deeply through your nose for the count of four until your lungs are full.',
      'Hold your breath for a count of four (lungs full).',
      'Exhale for a count of four.',
      'Hold your breath for a count of four (lungs empty).',
      'Repeat three to four times.',
    ],
    rationale: [
      'Voluntary rhythmic breathing activates the vagus and its medullary nucleus, regulating autonomic stress-reactivity.',
      'Loizzo, J. J. (2018). Can embodied contemplative practices accelerate resilience training and trauma recovery?. Frontiers in human neuroscience, 12, 134.',
    ],
  },
  {
    activityName: 'Diaphragmatic Breathing',
    activityVideoSrc: '//player.vimeo.com/video/889263296',
    category: 'Breathing',
    steps: [
      'Sit comfortably with your shoulders, head and neck relaxed.',
      'Place one hand on your chest and the other just below your rib cage.',
      'Breathe in slowly, and feel a slight rise in your chest, a slight belly rise, and lateral expansion of your lower ribs as your diaphragm moves downward.',
      'Exhale slowly through your nose or pursed lips.',
      'Continue this pattern for several minutes.',
    ],
    rationale: [
      'Diaphragmatic breathing has demonstrated positive effects on attention, affect and autonomic regulation, as indicated by cortisol levels.',
      'Ma, X., Yue, Z. Q., Gong, Z. Q., Zhang, H., Duan, N. Y., Shi, Y. T., Wei, G. X., & Li, Y. F. (2017). The Effect of Diaphragmatic Breathing on Attention, Negative Affect and Stress in Healthy Adults. Frontiers in psychology, 8, 874. https://doi.org/10.3389/fpsyg.2017.00874',
    ],
  },
  {
    activityName: 'Extended Exhale',
    activityVideoSrc: '//player.vimeo.com/video/889271039',
    category: 'Breathing',
    steps: [
      'Find a comfortable position.',
      'Inhale and exhale slowly for several rounds of breath.',
      'Begin extending your exhale to twice the length of your inhale (e.g. two seconds in, four seconds out).',
      'Maintaining a 1:2 ratio, gradually extend your breath to your comfort (e.g. four seconds in, eight seconds out).',
      'Continue this pattern for several minutes.',
    ],
    rationale: [
      'Extended exhale breathing has been found to increase HRV and engage the parasympathetic nervous system.',
      'Bae, D., Matthews, J., Chen, J. J., & Mah, L. (2021). Increased exhalation to inhalation ratio during breathing enhances high-frequency heart rate variability in healthy adults. Psychophysiology, 58(11), e13905. https://doi.org/10.1111/psyp.13905',
    ],
  },
  {
    activityName: 'Yawning',
    activityVideoSrc: '//player.vimeo.com/video/889270998',
    category: 'Breathing',
    steps: [
      'To trigger a deep yawn, do six or seven fake yawns and eventually a real yawn will emerge.',
      'Keep going, and by the 10th or 12th yawn, you may feel changes.',
      {
        icon: 'leaf',
        text:
          "Do you feel relaxed, highly alert and completely present in your body? (Your eyes might start watering and your nose might run — that's normal.)",
      },
    ],
    rationale: [
      "Yawning stimulates a part of the brain called the precuneus. This part of the brain plays a key role in self-reflection and memory retrieval. Yawning supports our sense of being more self-aware and decreases anxiety. It also supports regulation of the brain's temperature and metabolism, bringing a sense of increased cognitive awareness and relaxation.",
      'Newberg, A., & Waldman, M. R. (2006). Why we believe what we believe: Uncovering our biological need for meaning, spirituality, and truth. Simon and Schuster.',
    ],
  },
  {
    activityName: 'Sensory Orientation',
    activityVideoSrc: '//player.vimeo.com/video/889263330',
    category: 'Mindfulness',
    steps: [
      'Bring attention to your body.',
      'Feel your feet on the ground or your seat on the chair.',
      'Promote body awareness through all the senses. What can you see? Take in sounds from around the room and inside your body. Can you feel the fabric of your clothing touching your body? What smells and tastes do you perceive?',
      'Notice and orient your awareness, alternating between inside and outside the body.',
    ],
    rationale: [
      'Practicing orientation (connecting to your environment using the senses) helps to shift attention to the present moment, away from disruptive or repressed thoughts to more pleasant sensations in body and sensory experience.',
      'Hanscom, D. A. (2021). Plan A - Thrive and Survive Covid-19 (2nd ed.). Vertus Press, Inc.',
    ],
  },
  {
    activityName: 'Visualization',
    activityVideoSrc: '//player.vimeo.com/video/889271124',
    category: 'Mindfulness',
    steps: [
      'Find yourself in a comfortable position and allow your body to feel grounded in your space.',
      'Take a deep breath in, and as you exhale, start to visualize yourself in nature.',
      {
        icon: 'leaf',
        text:
          "What do you see? Perhaps you see the fall leaves rustling in the wind. Perhaps you see the waves slowly rolling onto the shore. Maybe it's the next mountain peak off in the distance. What else do you see in this safe place?",
      },
      {
        icon: 'empty',
        text:
          'Know that this is a place just for you. It is a safe, warm and peaceful setting that brings you comfort. Using your breath, you can return here anytime.',
      },
    ],
    rationale: [
      'Spending time in nature is one approach that has demonstrated anxiolytic effects. However, spending time in nature may not always be possible, as may be the case in indoor therapeutic settings. Alternatively, nature-based guided imagery has been found to be effective for reducing anxiety symptoms.',
      'Nguyen, J., & Brymer, E. (2018). Nature-Based Guided Imagery as an Intervention for State Anxiety. Frontiers in psychology, 9, 1858. https://doi.org/10.3389/fpsyg.2018.01858',
    ],
  },
  {
    activityName: 'Ear Massage',
    activityVideoSrc: '//player.vimeo.com/video/889263364',
    category: 'Movement',
    steps: [
      'Begin by bringing awareness to the ears. One at a time, assess the tension in them by gently pulling the ear away from the head at different points. Notice if one side feels more elastic or stiff compared to the other.',
      'Place the index finger in the hollow above the ridge that is above the ear canal and gently massage the area in little circles, and visualize the skin sliding over the bone.',
      'Bring your finger to the ear canal and softly press toward the back of the head while making small circles with your finger.',
      'Repeat on the other ear, and then reassess the ears by gently pulling them and noting any changes in stiffness.',
    ],
    rationale: [
      'The auricular branch of the vagus nerve supplies sensory innervation to the skin of the outer ear. By providing sensory input to these parts of the ear, the vagus is stimulated to increase vagal tone and initiate a relaxation response through parasympathetic activity.',
      'Rong, P. et al., (2020). Auricular vagus nerve acupressure for patients with emotional distress under the COVID-19 pandemic: a smartphone-based, randomized controlled trial. https://doi.org/10.2196/preprints.25001',
    ],
  },
  {
    activityName: 'Joint Circles',
    activityVideoSrc: '//player.vimeo.com/video/889263434',
    category: 'Movement',
    steps: [
      {
        title: true,
        text: 'Head and Neck',
      },
      'Start by sitting or standing in a comfortable position.',
      'Tilt your head toward your right shoulder. Slowly tuck your chin to your chest, then bring your left ear toward your left shoulder. Rotate your head backwards until your chin shines up to the sky.',
      {
        title: true,
        text: 'Wrists',
      },
      'Start by sitting or standing in a comfortable position.',
      'Bring your hands in front of you with your palms up. Make gentle circles with your wrists, releasing any tension you feel in your joints.',
      {
        title: true,
        text: 'Shoulders',
      },
      'Start by sitting or standing in a comfortable position.',
      'Bring both shoulders up toward your ears, then let your shoulder blades come together, and relax as you lower your shoulders down your back.',
      {
        title: true,
        text: 'Hips',
      },
      'Stand and place your hands on your hips. Move your hips in a circular motion.',
      {
        title: true,
        text: 'Ankles',
      },
      'Sitting in a chair, lift your feet off the floor. Make gentle circles with your ankles. ',
      {
        icon: 'leaf',
        text: 'Repeat five circles in each direction on each joint.',
      },
    ],
    rationale: [
      'Active range of motion through the joint requires opposing muscles to contract and relax in a coordinated movement throughout the joint. Joint circles can improve proprioceptive awareness.',
      'Friemert, B., Bach, C., Schwarz, W., Gerngross, H., & Schmidt, R. (2006). Benefits of active motion for joint position sense. Knee surgery, sports traumatology, arthroscopy : official journal of the ESSKA, 14(6), 564–570. https://doi.org/10.1007/s00167-005-0004-7',
    ],
  },
  {
    activityName: 'Releasing the Neck',
    activityVideoSrc: '//player.vimeo.com/video/889263396',
    category: 'Movement',
    steps: [
      'Roll your shoulders up, back and down, and direct your gaze forward.',
      'Slowly begin tipping your right ear toward your right shoulder without turning your head.',
      'Shift your attention to the left side of your neck. When you feel it fully lengthened, bring your eye gaze to the right and take four to six deep breaths while noticing the sensation in the left side of your neck.',
      'Return your head and gaze to the starting position, and repeat on the left side.',
    ],
    rationale: [
      'The vagus nerve runs behind the sternocleidomastoid muscle and in front of the scalenes, which tend to be two of the tightest muscles in the neck, and can cause irritation to the vagus nerve. Stretching out these muscles relieves pressure that may compromise vagus nerve function.',
      'Ozel Asliyuce, Y., Berberoglu, U., & Ulger, O. (2020). Is cervical region tightness related to vagal function and stomach symptoms?. Medical hypotheses, 142, 109819. https://doi.org/10.1016/j.mehy.2020.109819',
    ],
  },
  {
    activityName: 'Self-touch',
    activityVideoSrc: '//player.vimeo.com/video/889271021',
    category: 'Movement',
    steps: [
      'Give yourself a hug while gently rocking side to side.',
      'Place your hand on your heart, pushing downwards to give yourself grounding pressure.',
    ],
    rationale: [
      'Touch (from the self or an attuned other) releases oxytocin in the brain, stimulating the release of feel-good hormones in the brain and thus lowering stress hormones. A nonverbal cue of safety, touch can be especially effective, because it is a direct, palpable experience of support. As Dr. Porges has said, feeling “safe in the arms of another” is among the most powerful routes to re-establishing an internal sense of well-being.',
      'Procyk, S. M. (2020). The Magic of Polyvagal Theory: Inviting Vulnerability and Facilitating Safety through Coregulation, Touch, and Micro Interactions (Doctoral dissertation, Pacifica Graduate Institute).',
    ],
  },
  {
    activityName: 'Yoga',
    activityVideoSrc: '//player.vimeo.com/video/889271077',
    category: 'Movement',
    steps: [
      {
        title: true,
        text: 'Legs Up the Wall (Slight Inversion)',
      },
      'Sit with your right side against the wall, with bent knees and your feet drawn in toward your hips.',
      'Swing your legs up against the wall as you turn to lie flat on your back.',
      'Place your hips against the wall or slightly away. Place your arms in any comfortable position. Stay in this position for up to 20 minutes.',
      'To release the pose, draw your knees into your chest and roll onto your right side.',
      'Rest for a few moments before slowly moving into an upright position.',
      {
        title: true,
        text: 'Knees to Chest',
      },
      'Lie down on your back and pull both knees up to your chest.',
      'Slowly pull the knees toward the shoulders until you feel a gentle stretch in your lower back.',
      'Stay in knees to chest pose for 30 seconds to one minute.',
      {
        title: true,
        text: "Child's Pose",
      },
      'Kneel on the floor with your toes together and your knees hip width apart. Slowly walk your arms forward, laying your torso between your legs.',
      {
        title: true,
        text: 'Cat-Cow Pose',
      },
      'Begin with your hands and knees on the floor, a neutral spine and your core muscles engaged.',
      'Align your knees under your hips and your wrists under your shoulders.',
      'On your inhale, arch your back, let your belly relax and go loose. Lift your head and tailbone up toward the sky without putting any unnecessary pressure on your neck. This is the cow pose.',
      'On the exhale, round your spine up toward the ceiling in spinal flexion, pulling your belly button up toward your spine. Tuck your chin toward your chest and let your neck release. This is the cat pose.',
      'Continue flowing back and forth between cat pose and cow pose, and connect your breath to each movement — inhale for cow pose and exhale on cat pose.',
    ],
    rationale: [
      'Physical activity participation has been found to decrease levels of anxiety and depression. Yoga and mindful movement has been found to increase focus, social connectedness and relaxation.',
      'Zhu, X., Haegele, J. A., & Healy, S. (2019). Movement and mental health: Behavioral correlates of anxiety and depression among children of 6–17 years old in the US. Mental Health and Physical Activity, 16, 60-65.',
    ],
  },
  {
    activityName: 'Singing and Chanting',
    activityVideoSrc: '//player.vimeo.com/video/889270955',
    category: 'Vocalization',
    steps: [
      'Take a deep breath in and feel your lungs fill with air.',
      'In unison or independently, exhale as you chant Ōṁ (aum), or sing your favorite phrase of music.',
      'Continue for one to three minutes.',
    ],
    rationale: [
      'Singing or chanting increases blood flow to the brain, and supports regulation of the heart and nervous system, particularly when performed at a rate of five to six breaths per minute. Group synchronization increases social cohesion leading to increased positive mood and focused attention and thus decreasing ruminating thoughts. Chanting stimulates glossopharyngeal cranial nerve and auditory feedback that cross-activates the ventral vagal complex.',
      'Perry, G., Polito, V., & Thompson, W. F. (2016). Chanting meditation improves mood and social cohesion. International Conference on Music Perception and Cognition; The Society for Music Perception and Cognition (SMPC), 324-327.',
      'Nester, J. (2020). Breathe: The new science of a lost art. (1st ed.). Riverhead Books, New York.',
    ],
  },
]
