import React, { Fragment, useState, useEffect, useRef } from 'react'

import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton, Grid } from 'components'
import ExpandIcon from '@mui/icons-material/ExpandMore'
import CollapseIcon from '@mui/icons-material/ExpandLess'

import styles from './Table.module.scss'

export default function UnyteTable({
  data = [],
  columns = [],
  detailsComponent,
  loading,
  onLoadMore = () => null,
  sort = [],
  onSort = () => null,
  selectedRow: _selectedRow,
  onSelectRow = () => null,
  rowQuantity = 5,
  highlightDetails,
  hideTableHead = false,
  // we have to move fetch outside of useOutletContext because it is only used in clients but `UnyteTable` is used in many places
  allDataFetched = true,
  fetch = null,
  setLoading = () => {},
  queryVars,
  currentOffset,
}) {
  const showLoader = !data.length && loading
  const Details = detailsComponent
  const [selectedRow, selectRow] = useState(null)

  useEffect(() => {
    selectRow(_selectedRow)
  }, [_selectedRow])

  const ref = useRef(null)
  useEffect(() => {
    let observerRefValue = null
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (entry.isIntersecting && !loading && !allDataFetched && fetch) {
          await setLoading(true)
          await fetch({ ...queryVars, offset: currentOffset })
          await observerRefValue.current?.focus()
        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    )
    if (ref.current && !loading && !allDataFetched) {
      observer.observe(ref.current)
      observerRefValue = ref.current
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue)
      }
    }
    // eslint-disable-next-line
  }, [allDataFetched, loading])

  return (
    <div style={{ overflowX: 'auto' }}>
      <Table>
        {!hideTableHead && (
          <TableHead>
            <TableRow key="tablehead">
              {columns.map(({ header: Cell, width }, i) => (
                <TableCell key={`tablehead${i}`} style={{ width, backgroundColor: '#ffffff' }}>
                  <Cell sort={sort} onSort={onSort} />
                </TableCell>
              ))}
              {!!detailsComponent && (
                <TableCell style={{ width: '20', backgroundColor: '#ffffff' }} />
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody data-test="table-body">
          {!data.length && !loading && (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <Grid container justifyContent="center" className="fill-width p-5">
                  No data to display
                </Grid>
              </TableCell>
            </TableRow>
          )}

          {!showLoader &&
            data.map((row, rowIndex) => {
              const isSelected = selectedRow === rowIndex
              return (
                <Fragment key={`data${rowIndex}`}>
                  <TableRow
                    data-test={`table-row${rowIndex}`}
                    hover={true}
                    selected={isSelected}
                    className={styles.row}
                    onClick={() => {
                      if (!detailsComponent) {
                        return
                      }
                      const selected = isSelected ? null : rowIndex
                      selectRow(selected)
                      onSelectRow(selected)
                    }}
                  >
                    {columns.map(({ body: Cell = () => null }, i) => (
                      <TableCell key={`data${rowIndex}${i}`}>
                        <Cell data={row} />
                      </TableCell>
                    ))}
                    {!!detailsComponent && (
                      <TableCell>
                        {
                          <>
                            {isSelected ? (
                              <CollapseIcon
                                style={{ position: 'relative', right: '20px' }}
                              ></CollapseIcon>
                            ) : (
                              <ExpandIcon
                                style={{ position: 'relative', right: '20px' }}
                              ></ExpandIcon>
                            )}
                          </>
                        }
                      </TableCell>
                    )}
                  </TableRow>

                  {!!detailsComponent && isSelected && (
                    <TableRow className={highlightDetails && 'bg-table-highlighted'}>
                      <TableCell colSpan={columns.length + 1}>
                        <Details data={row} />
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              )
            })}
          {loading &&
            Array.from(new Array(rowQuantity)).map((_, index) => (
              <TableRow key={`loading${index}`}>
                {columns.map((col, cellIndex) => (
                  <TableCell key={`loading${index}${cellIndex}`}>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                ))}
                {!!detailsComponent && (
                  <TableCell>
                    <Skeleton variant="rectangular" />
                  </TableCell>
                )}
              </TableRow>
            ))}
          {!loading && !allDataFetched && <TableRow ref={ref} id="observer-ref-id" />}
        </TableBody>
      </Table>
    </div>
  )
}
