const allStates = {
  AF: [
    { subCode: 'BAL', name: 'Balkh' },
    { subCode: 'BAM', name: 'B\u0101my\u0101n' },
    { subCode: 'BDG', name: 'B\u0101dgh\u012bs' },
    { subCode: 'BDS', name: 'Badakhsh\u0101n' },
    { subCode: 'BGL', name: 'Baghl\u0101n' },
    { subCode: 'DAY', name: 'D\u0101ykund\u012b' },
    { subCode: 'FRA', name: 'Far\u0101h' },
    { subCode: 'FYB', name: 'F\u0101ry\u0101b' },
    { subCode: 'GHA', name: 'Ghazn\u012b' },
    { subCode: 'GHO', name: 'Gh\u014dr' },
    { subCode: 'HEL', name: 'Helmand' },
    { subCode: 'HER', name: 'Her\u0101t' },
    { subCode: 'JOW', name: 'Jowzj\u0101n' },
    { subCode: 'KAB', name: 'K\u0101bul' },
    { subCode: 'KAN', name: 'Kandah\u0101r' },
    { subCode: 'KAP', name: 'K\u0101p\u012bs\u0101' },
    { subCode: 'KDZ', name: 'Kunduz' },
    { subCode: 'KHO', name: 'Kh\u014dst' },
    { subCode: 'KNR', name: 'Kunar' },
    { subCode: 'LAG', name: 'Laghm\u0101n' },
    { subCode: 'LOG', name: 'L\u014dgar' },
    { subCode: 'NAN', name: 'Nangarh\u0101r' },
    { subCode: 'NIM', name: 'N\u012bmr\u014dz' },
    { subCode: 'NUR', name: 'N\u016brist\u0101n' },
    { subCode: 'PAN', name: 'Panjshayr' },
    { subCode: 'PAR', name: 'Parw\u0101n' },
    { subCode: 'PIA', name: 'Paktiy\u0101' },
    { subCode: 'PKA', name: 'Pakt\u012bk\u0101' },
    { subCode: 'SAM', name: 'Samang\u0101n' },
    { subCode: 'SAR', name: 'Sar-e Pul' },
    { subCode: 'TAK', name: 'Takh\u0101r' },
    { subCode: 'URU', name: 'Uruzg\u0101n' },
    { subCode: 'WAR', name: 'Wardak' },
    { subCode: 'ZAB', name: 'Z\u0101bul' },
  ],
  AX: [{ subCode: 'AX', name: '\u00c5land Islands' }],
  AL: [
    { subCode: '01', name: 'Berat' },
    { subCode: '02', name: 'Durr\u00ebs' },
    { subCode: '03', name: 'Elbasan' },
    { subCode: '04', name: 'Fier' },
    { subCode: '05', name: 'Gjirokast\u00ebr' },
    { subCode: '06', name: 'Kor\u00e7\u00eb' },
    { subCode: '07', name: 'Kuk\u00ebs' },
    { subCode: '08', name: 'Lezh\u00eb' },
    { subCode: '09', name: 'Dib\u00ebr' },
    { subCode: '10', name: 'Shkod\u00ebr' },
    { subCode: '11', name: 'Tiran\u00eb' },
    { subCode: '12', name: 'Vlor\u00eb' },
    { subCode: 'BR', name: 'Berat' },
    { subCode: 'BU', name: 'Bulqiz\u00eb' },
    { subCode: 'DI', name: 'Dib\u00ebr' },
    { subCode: 'DL', name: 'Delvin\u00eb' },
    { subCode: 'DR', name: 'Durr\u00ebs' },
    { subCode: 'DV', name: 'Devoll' },
    { subCode: 'EL', name: 'Elbasan' },
    { subCode: 'ER', name: 'Kolonj\u00eb' },
    { subCode: 'FR', name: 'Fier' },
    { subCode: 'GJ', name: 'Gjirokast\u00ebr' },
    { subCode: 'GR', name: 'Gramsh' },
    { subCode: 'HA', name: 'Has' },
    { subCode: 'KA', name: 'Kavaj\u00eb' },
    { subCode: 'KB', name: 'Kurbin' },
    { subCode: 'KC', name: 'Ku\u00e7ov\u00eb' },
    { subCode: 'KO', name: 'Kor\u00e7\u00eb' },
    { subCode: 'KR', name: 'Kruj\u00eb' },
    { subCode: 'KU', name: 'Kuk\u00ebs' },
    { subCode: 'LB', name: 'Librazhd' },
    { subCode: 'LE', name: 'Lezh\u00eb' },
    { subCode: 'LU', name: 'Lushnj\u00eb' },
    { subCode: 'MK', name: 'Mallakast\u00ebr' },
    { subCode: 'MM', name: 'Mal\u00ebsi e Madhe' },
    { subCode: 'MR', name: 'Mirdit\u00eb' },
    { subCode: 'MT', name: 'Mat' },
    { subCode: 'PG', name: 'Pogradec' },
    { subCode: 'PQ', name: 'Peqin' },
    { subCode: 'PR', name: 'P\u00ebrmet' },
    { subCode: 'PU', name: 'Puk\u00eb' },
    { subCode: 'SH', name: 'Shkod\u00ebr' },
    { subCode: 'SK', name: 'Skrapar' },
    { subCode: 'SR', name: 'Sarand\u00eb' },
    { subCode: 'TE', name: 'Tepelen\u00eb' },
    { subCode: 'TP', name: 'Tropoj\u00eb' },
    { subCode: 'TR', name: 'Tiran\u00eb' },
    { subCode: 'VL', name: 'Vlor\u00eb' },
  ],
  DZ: [
    { subCode: '01', name: 'Adrar' },
    { subCode: '02', name: 'Chlef' },
    { subCode: '03', name: 'Laghouat' },
    { subCode: '04', name: 'Oum el Bouaghi' },
    { subCode: '05', name: 'Batna' },
    { subCode: '06', name: 'B\u00e9ja\u00efa' },
    { subCode: '07', name: 'Biskra' },
    { subCode: '08', name: 'B\u00e9char' },
    { subCode: '09', name: 'Blida' },
    { subCode: '10', name: 'Bouira' },
    { subCode: '11', name: 'Tamanrasset' },
    { subCode: '12', name: 'T\u00e9bessa' },
    { subCode: '13', name: 'Tlemcen' },
    { subCode: '14', name: 'Tiaret' },
    { subCode: '15', name: 'Tizi Ouzou' },
    { subCode: '16', name: 'Alger' },
    { subCode: '17', name: 'Djelfa' },
    { subCode: '18', name: 'Jijel' },
    { subCode: '19', name: 'S\u00e9tif' },
    { subCode: '20', name: 'Sa\u00efda' },
    { subCode: '21', name: 'Skikda' },
    { subCode: '22', name: 'Sidi Bel Abb\u00e8s' },
    { subCode: '23', name: 'Annaba' },
    { subCode: '24', name: 'Guelma' },
    { subCode: '25', name: 'Constantine' },
    { subCode: '26', name: 'M\u00e9d\u00e9a' },
    { subCode: '27', name: 'Mostaganem' },
    { subCode: '28', name: 'Msila' },
    { subCode: '29', name: 'Mascara' },
    { subCode: '30', name: 'Ouargla' },
    { subCode: '31', name: 'Oran' },
    { subCode: '32', name: 'El Bayadh' },
    { subCode: '33', name: 'Illizi' },
    { subCode: '34', name: 'Bordj Bou Arr\u00e9ridj' },
    { subCode: '35', name: 'Boumerd\u00e8s' },
    { subCode: '36', name: 'El Tarf' },
    { subCode: '37', name: 'Tindouf' },
    { subCode: '38', name: 'Tissemsilt' },
    { subCode: '39', name: 'El Oued' },
    { subCode: '40', name: 'Khenchela' },
    { subCode: '41', name: 'Souk Ahras' },
    { subCode: '42', name: 'Tipaza' },
    { subCode: '43', name: 'Mila' },
    { subCode: '44', name: 'A\u00efn Defla' },
    { subCode: '45', name: 'Naama' },
    { subCode: '46', name: 'A\u00efn T\u00e9mouchent' },
    { subCode: '47', name: 'Gharda\u00efa' },
    { subCode: '48', name: 'Relizane' },
  ],
  AS: [{ subCode: 'AS', name: 'American Samoa' }],
  AD: [
    { subCode: '02', name: 'Canillo' },
    { subCode: '03', name: 'Encamp' },
    { subCode: '04', name: 'La Massana' },
    { subCode: '05', name: 'Ordino' },
    { subCode: '06', name: 'Sant Juli\u00e0 de L\u00f2ria' },
    { subCode: '07', name: 'Andorra la Vella' },
    { subCode: '08', name: 'Escaldes-Engordany' },
  ],
  AO: [
    { subCode: 'BGO', name: 'Bengo' },
    { subCode: 'BGU', name: 'Benguela' },
    { subCode: 'BIE', name: 'Bi\u00e9' },
    { subCode: 'CAB', name: 'Cabinda' },
    { subCode: 'CCU', name: 'Kuando Kubango' },
    { subCode: 'CNN', name: 'Cunene' },
    { subCode: 'CNO', name: 'Kwanza Norte' },
    { subCode: 'CUS', name: 'Kwanza Sul' },
    { subCode: 'HUA', name: 'Huambo' },
    { subCode: 'HUI', name: 'Hu\u00edla' },
    { subCode: 'LNO', name: 'Lunda Norte' },
    { subCode: 'LSU', name: 'Lunda Sul' },
    { subCode: 'LUA', name: 'Luanda' },
    { subCode: 'MAL', name: 'Malange' },
    { subCode: 'MOX', name: 'Moxico' },
    { subCode: 'NAM', name: 'Namibe' },
    { subCode: 'UIG', name: 'U\u00edge' },
    { subCode: 'ZAI', name: 'Zaire' },
  ],
  AI: [{ subCode: 'AI', name: 'Anguilla' }],
  AQ: [{ subCode: 'AQ', name: 'Antarctica' }],
  AG: [
    { subCode: '03', name: 'Saint George' },
    { subCode: '04', name: 'Saint John' },
    { subCode: '05', name: 'Saint Mary' },
    { subCode: '06', name: 'Saint Paul' },
    { subCode: '07', name: 'Saint Peter' },
    { subCode: '08', name: 'Saint Philip' },
    { subCode: '10', name: 'Barbuda' },
    { subCode: '11', name: 'Redonda' },
  ],
  AR: [
    { subCode: 'A', name: 'Salta' },
    { subCode: 'B', name: 'Buenos Aires' },
    { subCode: 'C', name: 'Ciudad Aut\u00f3noma de Buenos Aires' },
    { subCode: 'D', name: 'San Luis' },
    { subCode: 'E', name: 'Entre R\u00edos' },
    { subCode: 'F', name: 'La Rioja' },
    { subCode: 'G', name: 'Santiago del Estero' },
    { subCode: 'H', name: 'Chaco' },
    { subCode: 'J', name: 'San Juan' },
    { subCode: 'K', name: 'Catamarca' },
    { subCode: 'L', name: 'La Pampa' },
    { subCode: 'M', name: 'Mendoza' },
    { subCode: 'N', name: 'Misiones' },
    { subCode: 'P', name: 'Formosa' },
    { subCode: 'Q', name: 'Neuqu\u00e9n' },
    { subCode: 'R', name: 'R\u00edo Negro' },
    { subCode: 'S', name: 'Santa Fe' },
    { subCode: 'T', name: 'Tucum\u00e1n' },
    { subCode: 'U', name: 'Chubut' },
    { subCode: 'V', name: 'Tierra del Fuego' },
    { subCode: 'W', name: 'Corrientes' },
    { subCode: 'X', name: 'C\u00f3rdoba' },
    { subCode: 'Y', name: 'Jujuy' },
    { subCode: 'Z', name: 'Santa Cruz' },
  ],
  AM: [
    { subCode: 'AG', name: 'Aragac\u0323otn' },
    { subCode: 'AR', name: 'Ararat' },
    { subCode: 'AV', name: 'Armavir' },
    { subCode: 'ER', name: 'Erevan' },
    { subCode: 'GR', name: "Ge\u0121ark'unik'" },
    { subCode: 'KT', name: "Kotayk'" },
    { subCode: 'LO', name: 'Lo\u1e59i' },
    { subCode: 'SH', name: '\u0160irak' },
    { subCode: 'SU', name: "Syunik'" },
    { subCode: 'TV', name: 'Tavu\u0161' },
    { subCode: 'VD', name: 'Vayo\u0107 Jor' },
  ],
  AW: [{ subCode: 'AW', name: 'Aruba' }],
  AU: [
    { subCode: 'ACT', name: 'Australian Capital Territory' },
    { subCode: 'NSW', name: 'New South Wales' },
    { subCode: 'NT', name: 'Northern Territory' },
    { subCode: 'QLD', name: 'Queensland' },
    { subCode: 'SA', name: 'South Australia' },
    { subCode: 'TAS', name: 'Tasmania' },
    { subCode: 'VIC', name: 'Victoria' },
    { subCode: 'WA', name: 'Western Australia' },
  ],
  AT: [
    { subCode: '1', name: 'Burgenland' },
    { subCode: '2', name: 'K\u00e4rnten' },
    { subCode: '3', name: 'Nieder\u00f6sterreich' },
    { subCode: '4', name: 'Ober\u00f6sterreich' },
    { subCode: '5', name: 'Salzburg' },
    { subCode: '6', name: 'Steiermark' },
    { subCode: '7', name: 'Tirol' },
    { subCode: '8', name: 'Vorarlberg' },
    { subCode: '9', name: 'Wien' },
  ],
  AZ: [
    { subCode: 'ABS', name: 'Ab\u015feron' },
    { subCode: 'AGA', name: 'A\u011fstafa' },
    { subCode: 'AGC', name: 'A\u011fcab\u04d9di' },
    { subCode: 'AGM', name: 'A\u011fdam' },
    { subCode: 'AGS', name: 'A\u011fda\u015f' },
    { subCode: 'AGU', name: 'A\u011fsu' },
    { subCode: 'AST', name: 'Astara' },
    { subCode: 'BA', name: 'Bak\u0131' },
    { subCode: 'BAB', name: 'Bab\u0259k' },
    { subCode: 'BAL', name: 'Balak\u0259n' },
    { subCode: 'BAR', name: 'B\u0259rd\u0259' },
    { subCode: 'BEY', name: 'Beyl\u0259qan' },
    { subCode: 'BIL', name: 'Bil\u0259suvar' },
    { subCode: 'CAB', name: 'C\u0259bray\u0131l' },
    { subCode: 'CAL', name: 'C\u0259lilabad' },
    { subCode: 'CUL', name: 'Culfa' },
    { subCode: 'DAS', name: 'Da\u015fk\u0259s\u0259n' },
    { subCode: 'FUZ', name: 'F\u00fczuli' },
    { subCode: 'GAD', name: 'G\u0259d\u0259b\u0259y' },
    { subCode: 'GA', name: 'G\u04d9nc\u04d9' },
    { subCode: 'GOR', name: 'Goranboy' },
    { subCode: 'GOY', name: 'G\u00f6y\u00e7ay' },
    { subCode: 'GYG', name: 'G\u00f6yg\u00f6l' },
    { subCode: 'HAC', name: 'Hac\u0131qabul' },
    { subCode: 'IMI', name: '\u0130mi\u015fli' },
    { subCode: 'ISM', name: '\u0130smay\u0131ll\u0131' },
    { subCode: 'KAL', name: 'K\u0259lb\u0259c\u0259r' },
    { subCode: 'KAN', name: 'K\u01ddng\u01ddrli' },
    { subCode: 'KUR', name: 'K\u00fcrd\u0259mir' },
    { subCode: 'LAC', name: 'La\u00e7\u0131n' },
    { subCode: 'LA', name: 'L\u04d9nk\u04d9ran' },
    { subCode: 'LAN', name: 'L\u0259nk\u0259ran' },
    { subCode: 'LER', name: 'Lerik' },
    { subCode: 'MAS', name: 'Masall\u0131' },
    { subCode: 'MI', name: 'Ming\u04d9\u00e7evir' },
    { subCode: 'NA', name: 'Naftalan' },
    { subCode: 'NEF', name: 'Neft\u00e7ala' },
    { subCode: 'NV', name: 'Nax\u00e7\u0131van' },
    { subCode: 'NX', name: 'Nax\u00e7\u0131van' },
    { subCode: 'OGU', name: 'O\u011fuz' },
    { subCode: 'ORD', name: 'Ordubad' },
    { subCode: 'QAB', name: 'Q\u04d9b\u04d9l\u04d9' },
    { subCode: 'QAX', name: 'Qax' },
    { subCode: 'QAZ', name: 'Qazax' },
    { subCode: 'QBA', name: 'Quba' },
    { subCode: 'QBI', name: 'Qubadl\u0131' },
    { subCode: 'QOB', name: 'Qobustan' },
    { subCode: 'QUS', name: 'Qusar' },
    { subCode: 'SAB', name: 'Sabirabad' },
    { subCode: 'SAD', name: 'S\u04d9d\u04d9r\u04d9k' },
    { subCode: 'SAH', name: '\u015eahbuz' },
    { subCode: 'SAK', name: '\u015e\u04d9ki' },
    { subCode: 'SAL', name: 'Salyan' },
    { subCode: 'SAR', name: '\u015e\u04d9rur' },
    { subCode: 'SA', name: '\u015e\u04d9ki' },
    { subCode: 'SAT', name: 'Saatl\u0131' },
    { subCode: 'SBN', name: '\u015eabran' },
    { subCode: 'SIY', name: 'Siy\u04d9z\u04d9n' },
    { subCode: 'SKR', name: '\u015e\u04d9mkir' },
    { subCode: 'SMI', name: '\u015eamax\u0131' },
    { subCode: 'SM', name: 'Sumqay\u0131t' },
    { subCode: 'SMX', name: 'Samux' },
    { subCode: 'SR', name: '\u015eirvan' },
    { subCode: 'SUS', name: '\u015eu\u015fa' },
    { subCode: 'TAR', name: 'T\u04d9rt\u04d9r' },
    { subCode: 'TOV', name: 'Tovuz' },
    { subCode: 'UCA', name: 'Ucar' },
    { subCode: 'XAC', name: 'Xa\u00e7maz' },
    { subCode: 'XA', name: 'Xank\u04d9ndi' },
    { subCode: 'XCI', name: 'Xocal\u0131' },
    { subCode: 'XIZ', name: 'X\u0131z\u0131' },
    { subCode: 'XVD', name: 'Xocav\u04d9nd' },
    { subCode: 'YAR', name: 'Yard\u0131ml\u0131' },
    { subCode: 'YEV', name: 'Yevlax' },
    { subCode: 'YE', name: 'Yevlax' },
    { subCode: 'ZAN', name: 'Z\u04d9ngilan' },
    { subCode: 'ZAQ', name: 'Zaqatala' },
    { subCode: 'ZAR', name: 'Z\u04d9rdab' },
  ],
  BS: [
    { subCode: 'AK', name: 'Acklins' },
    { subCode: 'BI', name: 'Bimini' },
    { subCode: 'BP', name: 'Black Point' },
    { subCode: 'BY', name: 'Berry Islands' },
    { subCode: 'CE', name: 'Central Eleuthera' },
    { subCode: 'CI', name: 'Cat Island' },
    { subCode: 'CK', name: 'Crooked Island and Long Cay' },
    { subCode: 'CO', name: 'Central Abaco' },
    { subCode: 'CS', name: 'Central Andros' },
    { subCode: 'EG', name: 'East Grand Bahama' },
    { subCode: 'EX', name: 'Exuma' },
    { subCode: 'FP', name: 'City of Freeport' },
    { subCode: 'GC', name: 'Grand Cay' },
    { subCode: 'HI', name: 'Harbour Island' },
    { subCode: 'HT', name: 'Hope Town' },
    { subCode: 'IN', name: 'Inagua' },
    { subCode: 'LI', name: 'Long Island' },
    { subCode: 'MC', name: 'Mangrove Cay' },
    { subCode: 'MG', name: 'Mayaguana' },
    { subCode: 'MI', name: "Moore's Island" },
    { subCode: 'NE', name: 'North Eleuthera' },
    { subCode: 'NO', name: 'North Abaco' },
    { subCode: 'NS', name: 'North Andros' },
    { subCode: 'RC', name: 'Rum Cay' },
    { subCode: 'RI', name: 'Ragged Island' },
    { subCode: 'SA', name: 'South Andros' },
    { subCode: 'SE', name: 'South Eleuthera' },
    { subCode: 'SO', name: 'South Abaco' },
    { subCode: 'SS', name: 'San Salvador' },
    { subCode: 'SW', name: 'Spanish Wells' },
    { subCode: 'WG', name: 'West Grand Bahama' },
  ],
  BH: [
    { subCode: '13', name: 'Al Man\u0101mah' },
    { subCode: '14', name: 'Al Jan\u016bb\u012byah' },
    { subCode: '15', name: 'Al Mu\u1e29arraq' },
    { subCode: '16', name: 'Al Wus\u0163\u00e1' },
    { subCode: '17', name: 'Ash Sham\u0101l\u012byah' },
  ],
  BD: [
    { subCode: '01', name: 'Bandarban' },
    { subCode: '02', name: 'Barguna' },
    { subCode: '03', name: 'Bogra' },
    { subCode: '04', name: 'Brahmanbaria' },
    { subCode: '05', name: 'Bagerhat' },
    { subCode: '06', name: 'Barisal' },
    { subCode: '07', name: 'Bhola' },
    { subCode: '08', name: 'Comilla' },
    { subCode: '09', name: 'Chandpur' },
    { subCode: '10', name: 'Chittagong' },
    { subCode: '11', name: "Cox's Bazar" },
    { subCode: '12', name: 'Chuadanga' },
    { subCode: '13', name: 'Dhaka' },
    { subCode: '14', name: 'Dinajpur' },
    { subCode: '15', name: 'Faridpur' },
    { subCode: '16', name: 'Feni' },
    { subCode: '17', name: 'Gopalganj' },
    { subCode: '18', name: 'Gazipur' },
    { subCode: '19', name: 'Gaibandha' },
    { subCode: '20', name: 'Habiganj' },
    { subCode: '21', name: 'Jamalpur' },
    { subCode: '22', name: 'Jessore' },
    { subCode: '23', name: 'Jhenaidah' },
    { subCode: '24', name: 'Jaipurhat' },
    { subCode: '25', name: 'Jhalakati' },
    { subCode: '26', name: 'Kishoreganj' },
    { subCode: '27', name: 'Khulna' },
    { subCode: '28', name: 'Kurigram' },
    { subCode: '29', name: 'Khagrachari' },
    { subCode: '30', name: 'Kushtia' },
    { subCode: '31', name: 'Lakshmipur' },
    { subCode: '32', name: 'Lalmonirhat' },
    { subCode: '33', name: 'Manikganj' },
    { subCode: '34', name: 'Mymensingh' },
    { subCode: '35', name: 'Munshiganj' },
    { subCode: '36', name: 'Madaripur' },
    { subCode: '37', name: 'Magura' },
    { subCode: '38', name: 'Moulvibazar' },
    { subCode: '39', name: 'Meherpur' },
    { subCode: '40', name: 'Narayanganj' },
    { subCode: '41', name: 'Netrakona' },
    { subCode: '42', name: 'Narsingdi' },
    { subCode: '43', name: 'Narail' },
    { subCode: '44', name: 'Natore' },
    { subCode: '45', name: 'Nawabganj' },
    { subCode: '46', name: 'Nilphamari' },
    { subCode: '47', name: 'Noakhali' },
    { subCode: '48', name: 'Naogaon' },
    { subCode: '49', name: 'Pabna' },
    { subCode: '50', name: 'Pirojpur' },
    { subCode: '51', name: 'Patuakhali' },
    { subCode: '52', name: 'Panchagarh' },
    { subCode: '53', name: 'Rajbari' },
    { subCode: '54', name: 'Rajshahi' },
    { subCode: '55', name: 'Rangpur' },
    { subCode: '56', name: 'Rangamati' },
    { subCode: '57', name: 'Sherpur' },
    { subCode: '58', name: 'Satkhira' },
    { subCode: '59', name: 'Sirajganj' },
    { subCode: '60', name: 'Sylhet' },
    { subCode: '61', name: 'Sunamganj' },
    { subCode: '62', name: 'Shariatpur' },
    { subCode: '63', name: 'Tangail' },
    { subCode: '64', name: 'Thakurgaon' },
    { subCode: 'A', name: 'Barisal' },
    { subCode: 'B', name: 'Chittagong' },
    { subCode: 'C', name: 'Dhaka' },
    { subCode: 'D', name: 'Khulna' },
    { subCode: 'E', name: 'Rajshahi' },
    { subCode: 'F', name: 'Rangpur' },
    { subCode: 'G', name: 'Sylhet' },
  ],
  BB: [
    { subCode: '01', name: 'Christ Church' },
    { subCode: '02', name: 'Saint Andrew' },
    { subCode: '03', name: 'Saint George' },
    { subCode: '04', name: 'Saint James' },
    { subCode: '05', name: 'Saint John' },
    { subCode: '06', name: 'Saint Joseph' },
    { subCode: '07', name: 'Saint Lucy' },
    { subCode: '08', name: 'Saint Michael' },
    { subCode: '09', name: 'Saint Peter' },
    { subCode: '10', name: 'Saint Philip' },
    { subCode: '11', name: 'Saint Thomas' },
  ],
  BY: [
    { subCode: 'BR', name: 'Bresckaja voblas\u0107' },
    { subCode: 'HM', name: 'Gorod Minsk' },
    { subCode: 'HO', name: "Gomel'skaja oblast'" },
    { subCode: 'HR', name: "Grodnenskaja oblast'" },
    { subCode: 'MA', name: 'Mahilio\u016dskaja voblas\u0107' },
    { subCode: 'MI', name: "Minskaja oblast'" },
    { subCode: 'VI', name: 'Viciebskaja voblas\u0107' },
  ],
  BE: [
    { subCode: 'BRU', name: 'Brussels Hoofdstedelijk Gewest' },
    { subCode: 'VAN', name: 'Antwerpen' },
    { subCode: 'VBR', name: 'Vlaams-Brabant' },
    { subCode: 'VLG', name: 'Vlaams Gewest' },
    { subCode: 'VLI', name: 'Limburg' },
    { subCode: 'VOV', name: 'Oost-Vlaanderen' },
    { subCode: 'VWV', name: 'West-Vlaanderen' },
    { subCode: 'WAL', name: 'wallonne, R\u00e9gion' },
    { subCode: 'WBR', name: 'Brabant wallon' },
    { subCode: 'WHT', name: 'Hainaut' },
    { subCode: 'WLG', name: 'Li\u00e8ge' },
    { subCode: 'WLX', name: 'Luxembourg' },
    { subCode: 'WNA', name: 'Namur' },
  ],
  BZ: [
    { subCode: 'BZ', name: 'Belize' },
    { subCode: 'CY', name: 'Cayo' },
    { subCode: 'CZL', name: 'Corozal' },
    { subCode: 'OW', name: 'Orange Walk' },
    { subCode: 'SC', name: 'Stann Creek' },
    { subCode: 'TOL', name: 'Toledo' },
  ],
  BJ: [
    { subCode: 'AK', name: 'Atakora' },
    { subCode: 'AL', name: 'Alibori' },
    { subCode: 'AQ', name: 'Atlantique' },
    { subCode: 'BO', name: 'Borgou' },
    { subCode: 'CO', name: 'Collines' },
    { subCode: 'DO', name: 'Donga' },
    { subCode: 'KO', name: 'Kouffo' },
    { subCode: 'LI', name: 'Littoral' },
    { subCode: 'MO', name: 'Mono' },
    { subCode: 'OU', name: 'Ou\u00e9m\u00e9' },
    { subCode: 'PL', name: 'Plateau' },
    { subCode: 'ZO', name: 'Zou' },
  ],
  BM: [{ subCode: 'BM', name: 'Bermuda' }],
  BT: [
    { subCode: '11', name: 'Paro' },
    { subCode: '12', name: 'Chhukha' },
    { subCode: '13', name: 'Ha' },
    { subCode: '14', name: 'Samtse' },
    { subCode: '15', name: 'Thimphu' },
    { subCode: '21', name: 'Tsirang' },
    { subCode: '22', name: 'Dagana' },
    { subCode: '23', name: 'Punakha' },
    { subCode: '24', name: 'Wangdue Phodrang' },
    { subCode: '31', name: 'Sarpang' },
    { subCode: '32', name: 'Trongsa' },
    { subCode: '33', name: 'Bumthang' },
    { subCode: '34', name: 'Zhemgang' },
    { subCode: '41', name: 'Trashigang' },
    { subCode: '42', name: 'Monggar' },
    { subCode: '43', name: 'Pemagatshel' },
    { subCode: '44', name: 'Lhuentse' },
    { subCode: '45', name: 'Samdrup Jongkha' },
    { subCode: 'GA', name: 'Gasa' },
    { subCode: 'TY', name: 'Trashi Yangtse' },
  ],
  BO: [
    { subCode: 'B', name: 'El Beni' },
    { subCode: 'C', name: 'Cochabamba' },
    { subCode: 'H', name: 'Chuquisaca' },
    { subCode: 'L', name: 'La Paz' },
    { subCode: 'N', name: 'Pando' },
    { subCode: 'O', name: 'Oruro' },
    { subCode: 'P', name: 'Potos\u00ed' },
    { subCode: 'S', name: 'Santa Cruz' },
    { subCode: 'T', name: 'Tarija' },
  ],
  BQ: [
    { subCode: 'BO', name: 'Bonaire' },
    { subCode: 'SA', name: 'Saba' },
    { subCode: 'SE', name: 'Sint Eustatius' },
  ],
  BA: [
    { subCode: '01', name: 'Unsko-sanska \u017eupanija' },
    { subCode: '02', name: 'Posavska \u017eupanija' },
    { subCode: '03', name: 'Tuzlanska \u017eupanija' },
    { subCode: '04', name: 'Zeni\u010dko-dobojska \u017eupanija' },
    { subCode: '05', name: 'Bosansko-podrinjska \u017eupanija' },
    { subCode: '06', name: 'Srednjobosanska \u017eupanija' },
    { subCode: '07', name: 'Hercegova\u010dko-neretvanska \u017eupanija' },
    { subCode: '08', name: 'Zapadnohercegova\u010dka \u017eupanija' },
    { subCode: '09', name: 'Kanton Sarajevo' },
    { subCode: '10', name: 'Kanton br. 10' },
    { subCode: 'BIH', name: 'Federacija Bosne i Hercegovine' },
    { subCode: 'BRC', name: 'Br\u010dko distrikt' },
    { subCode: 'SRP', name: 'Republika Srpska' },
  ],
  BW: [
    { subCode: 'CE', name: 'Central' },
    { subCode: 'CH', name: 'Chobe' },
    { subCode: 'FR', name: 'Francistown' },
    { subCode: 'GA', name: 'Gaborone' },
    { subCode: 'GH', name: 'Ghanzi' },
    { subCode: 'JW', name: 'Jwaneng' },
    { subCode: 'KG', name: 'Kgalagadi' },
    { subCode: 'KL', name: 'Kgatleng' },
    { subCode: 'KW', name: 'Kweneng' },
    { subCode: 'LO', name: 'Lobatse' },
    { subCode: 'NE', name: 'North East' },
    { subCode: 'NW', name: 'North West' },
    { subCode: 'SE', name: 'South East' },
    { subCode: 'SO', name: 'Southern' },
    { subCode: 'SP', name: 'Selibe Phikwe' },
    { subCode: 'ST', name: 'Sowa Town' },
  ],
  BV: [{ subCode: 'BV', name: 'Bouvet Island' }],
  IO: [{ subCode: 'IO', name: 'British Indian Ocean Territory' }],
  BN: [
    { subCode: 'BE', name: 'Belait' },
    { subCode: 'BM', name: 'Brunei-Muara' },
    { subCode: 'TE', name: 'Temburong' },
    { subCode: 'TU', name: 'Tutong' },
  ],
  BG: [
    { subCode: '01', name: 'Blagoevgrad' },
    { subCode: '02', name: 'Burgas' },
    { subCode: '03', name: 'Varna' },
    { subCode: '04', name: 'Veliko Tarnovo' },
    { subCode: '05', name: 'Vidin' },
    { subCode: '06', name: 'Vratsa' },
    { subCode: '07', name: 'Gabrovo' },
    { subCode: '08', name: 'Dobrich' },
    { subCode: '09', name: 'Kardzhali' },
    { subCode: '10', name: 'Kyustendil' },
    { subCode: '11', name: 'Lovech' },
    { subCode: '12', name: 'Montana' },
    { subCode: '13', name: 'Pazardzhik' },
    { subCode: '14', name: 'Pernik' },
    { subCode: '15', name: 'Pleven' },
    { subCode: '16', name: 'Plovdiv' },
    { subCode: '17', name: 'Razgrad' },
    { subCode: '18', name: 'Ruse' },
    { subCode: '19', name: 'Silistra' },
    { subCode: '20', name: 'Sliven' },
    { subCode: '21', name: 'Smolyan' },
    { subCode: '22', name: 'Sofia' },
    { subCode: '23', name: 'Sofia' },
    { subCode: '24', name: 'Stara Zagora' },
    { subCode: '25', name: 'Targovishte' },
    { subCode: '26', name: 'Haskovo' },
    { subCode: '27', name: 'Shumen' },
    { subCode: '28', name: 'Yambol' },
  ],
  BF: [
    { subCode: '01', name: 'Boucle du Mouhoun' },
    { subCode: '02', name: 'Cascades' },
    { subCode: '03', name: 'Centre' },
    { subCode: '04', name: 'Centre-Est' },
    { subCode: '05', name: 'Centre-Nord' },
    { subCode: '06', name: 'Centre-Ouest' },
    { subCode: '07', name: 'Centre-Sud' },
    { subCode: '08', name: 'Est' },
    { subCode: '09', name: 'Hauts-Bassins' },
    { subCode: '10', name: 'Nord' },
    { subCode: '11', name: 'Plateau-Central' },
    { subCode: '12', name: 'Sahel' },
    { subCode: '13', name: 'Sud-Ouest' },
    { subCode: 'BAL', name: 'Bal\u00e9' },
    { subCode: 'BAM', name: 'Bam' },
    { subCode: 'BAN', name: 'Banwa' },
    { subCode: 'BAZ', name: 'Baz\u00e8ga' },
    { subCode: 'BGR', name: 'Bougouriba' },
    { subCode: 'BLG', name: 'Boulgou' },
    { subCode: 'BLK', name: 'Boulkiemd\u00e9' },
    { subCode: 'COM', name: 'Como\u00e9' },
    { subCode: 'GAN', name: 'Ganzourgou' },
    { subCode: 'GNA', name: 'Gnagna' },
    { subCode: 'GOU', name: 'Gourma' },
    { subCode: 'HOU', name: 'Houet' },
    { subCode: 'IOB', name: 'Ioba' },
    { subCode: 'KAD', name: 'Kadiogo' },
    { subCode: 'KEN', name: 'K\u00e9n\u00e9dougou' },
    { subCode: 'KMD', name: 'Komondjari' },
    { subCode: 'KMP', name: 'Kompienga' },
    { subCode: 'KOP', name: 'Koulp\u00e9logo' },
    { subCode: 'KOS', name: 'Kossi' },
    { subCode: 'KOT', name: 'Kouritenga' },
    { subCode: 'KOW', name: 'Kourw\u00e9ogo' },
    { subCode: 'LER', name: 'L\u00e9raba' },
    { subCode: 'LOR', name: 'Loroum' },
    { subCode: 'MOU', name: 'Mouhoun' },
    { subCode: 'NAM', name: 'Namentenga' },
    { subCode: 'NAO', name: 'Nahouri' },
    { subCode: 'NAY', name: 'Nayala' },
    { subCode: 'NOU', name: 'Noumbiel' },
    { subCode: 'OUB', name: 'Oubritenga' },
    { subCode: 'OUD', name: 'Oudalan' },
    { subCode: 'PAS', name: 'Passor\u00e9' },
    { subCode: 'PON', name: 'Poni' },
    { subCode: 'SEN', name: 'S\u00e9no' },
    { subCode: 'SIS', name: 'Sissili' },
    { subCode: 'SMT', name: 'Sanmatenga' },
    { subCode: 'SNG', name: 'Sangui\u00e9' },
    { subCode: 'SOM', name: 'Soum' },
    { subCode: 'SOR', name: 'Sourou' },
    { subCode: 'TAP', name: 'Tapoa' },
    { subCode: 'TUI', name: 'Tui' },
    { subCode: 'YAG', name: 'Yagha' },
    { subCode: 'YAT', name: 'Yatenga' },
    { subCode: 'ZIR', name: 'Ziro' },
    { subCode: 'ZON', name: 'Zondoma' },
    { subCode: 'ZOU', name: 'Zoundw\u00e9ogo' },
  ],
  BI: [
    { subCode: 'BB', name: 'Bubanza' },
    { subCode: 'BL', name: 'Bujumbura Rural' },
    { subCode: 'BM', name: 'Bujumbura Mairie' },
    { subCode: 'BR', name: 'Bururi' },
    { subCode: 'CA', name: 'Cankuzo' },
    { subCode: 'CI', name: 'Cibitoke' },
    { subCode: 'GI', name: 'Gitega' },
    { subCode: 'KI', name: 'Kirundo' },
    { subCode: 'KR', name: 'Karuzi' },
    { subCode: 'KY', name: 'Kayanza' },
    { subCode: 'MA', name: 'Makamba' },
    { subCode: 'MU', name: 'Muramvya' },
    { subCode: 'MW', name: 'Mwaro' },
    { subCode: 'MY', name: 'Muyinga' },
    { subCode: 'NG', name: 'Ngozi' },
    { subCode: 'RT', name: 'Rutana' },
    { subCode: 'RY', name: 'Ruyigi' },
  ],
  KH: [
    { subCode: '10', name: 'Kr\u00e2ch\u00e9h' },
    { subCode: '11', name: 'M\u00f4nd\u00f3l Kiri' },
    { subCode: '12', name: 'Phnom Penh' },
    { subCode: '13', name: 'Pre\u0103h Vih\u00e9ar' },
    { subCode: '14', name: 'Prey Veaeng' },
    { subCode: '15', name: 'Pousaat' },
    { subCode: '16', name: 'Rotanak Kiri' },
    { subCode: '17', name: 'Siem Reab' },
    { subCode: '18', name: 'Kr\u014fng Pre\u0103h Sihanouk' },
    { subCode: '19', name: 'Sto\u0115ng Tr\u00eang' },
    { subCode: '1', name: 'B\u00e2nt\u00e9ay M\u00e9anchey' },
    { subCode: '20', name: 'Svaay Rieng' },
    { subCode: '21', name: 'Taakaev' },
    { subCode: '22', name: '\u014etd\u00e2r M\u00e9anchey' },
    { subCode: '23', name: 'Krong Kaeb' },
    { subCode: '24', name: 'Kr\u014fng Pail\u012dn' },
    { subCode: '2', name: 'Baat Dambang' },
    { subCode: '3', name: 'Kampong Chaam' },
    { subCode: '4', name: 'Kampong Chhnang' },
    { subCode: '5', name: 'K\u00e2mp\u00f3ng Sp\u0153' },
    { subCode: '6', name: 'K\u00e2mp\u00f3ng Thum' },
    { subCode: '7', name: 'Kampot' },
    { subCode: '8', name: 'Kandaal' },
    { subCode: '9', name: 'Kaoh Kong' },
  ],
  CM: [
    { subCode: 'AD', name: 'Adamaoua' },
    { subCode: 'CE', name: 'Centre' },
    { subCode: 'EN', name: 'Extr\u00eame-Nord' },
    { subCode: 'ES', name: 'East' },
    { subCode: 'LT', name: 'Littoral' },
    { subCode: 'NO', name: 'Nord' },
    { subCode: 'NW', name: 'Nord-Ouest' },
    { subCode: 'OU', name: 'Ouest' },
    { subCode: 'SU', name: 'South' },
    { subCode: 'SW', name: 'South-West' },
  ],
  CA: [
    { subCode: 'AB', name: 'Alberta' },
    { subCode: 'BC', name: 'British Columbia' },
    { subCode: 'MB', name: 'Manitoba' },
    { subCode: 'NB', name: 'New Brunswick' },
    { subCode: 'NL', name: 'Newfoundland and Labrador' },
    { subCode: 'NS', name: 'Nova Scotia' },
    { subCode: 'NT', name: 'Northwest Territories' },
    { subCode: 'NU', name: 'Nunavut' },
    { subCode: 'ON', name: 'Ontario' },
    { subCode: 'PE', name: 'Prince Edward Island' },
    { subCode: 'QC', name: 'Quebec' },
    { subCode: 'SK', name: 'Saskatchewan' },
    { subCode: 'YT', name: 'Yukon' },
  ],
  CV: [
    { subCode: 'B', name: 'Ilhas de Barlavento' },
    { subCode: 'BR', name: 'Brava' },
    { subCode: 'BV', name: 'Boa Vista' },
    { subCode: 'CA', name: 'Santa Catarina' },
    { subCode: 'CF', name: 'Santa Catarina do Fogo' },
    { subCode: 'CR', name: 'Santa Cruz' },
    { subCode: 'MA', name: 'Maio' },
    { subCode: 'MO', name: 'Mosteiros' },
    { subCode: 'PA', name: 'Paul' },
    { subCode: 'PN', name: 'Porto Novo' },
    { subCode: 'PR', name: 'Praia' },
    { subCode: 'RB', name: 'Ribeira Brava' },
    { subCode: 'RG', name: 'Ribeira Grande' },
    { subCode: 'RS', name: 'Ribeira Grande de Santiago' },
    { subCode: 'SD', name: 'S\u00e3o Domingos' },
    { subCode: 'SF', name: 'S\u00e3o Filipe' },
    { subCode: 'S', name: 'Ilhas de Sotavento' },
    { subCode: 'SL', name: 'Sal' },
    { subCode: 'SM', name: 'S\u00e3o Miguel' },
    { subCode: 'SO', name: 'S\u00e3o Louren\u00e7o dos \u00d3rg\u00e3os' },
    { subCode: 'SS', name: 'S\u00e3o Salvador do Mundo' },
    { subCode: 'SV', name: 'S\u00e3o Vicente' },
    { subCode: 'TA', name: 'Tarrafal' },
    { subCode: 'TS', name: 'Tarrafal de S\u00e3o Nicolau' },
  ],
  KY: [{ subCode: 'KY', name: 'Cayman Islands' }],
  CF: [
    { subCode: 'AC', name: 'Ouham' },
    { subCode: 'BB', name: 'Bam\u00efng\u00ef-Bangoran' },
    { subCode: 'BGF', name: 'Bang\u00ee' },
    { subCode: 'BK', name: 'Basse-Kotto' },
    { subCode: 'HK', name: 'Haute-Kotto' },
    { subCode: 'HM', name: 'Haut-Mbomou' },
    { subCode: 'HS', name: 'Haute-Sangha / Mamb\u00e9r\u00e9-Kad\u00e9\u00ef' },
    { subCode: 'KB', name: 'G\u00efr\u00efb\u00efng\u00ef' },
    { subCode: 'KG', name: 'Kem\u00f6-G\u00efr\u00efb\u00efng\u00ef' },
    { subCode: 'LB', name: 'Lobaye' },
    { subCode: 'MB', name: 'Mbomou' },
    { subCode: 'MP', name: '\u00d6mb\u00ebl\u00e4-P\u00f6k\u00f6' },
    { subCode: 'NM', name: 'Nana-Mamb\u00e9r\u00e9' },
    { subCode: 'OP', name: 'Ouham-Pend\u00e9' },
    { subCode: 'SE', name: 'Sang\u00e4' },
    { subCode: 'UK', name: 'Ouaka' },
    { subCode: 'VK', name: 'Vakaga' },
  ],
  TD: [
    { subCode: 'BA', name: 'Al Ba\u0163\u1e29ah' },
    { subCode: 'BG', name: 'Ba\u1e29r al Ghaz\u0101l' },
    { subCode: 'BO', name: 'Borkou' },
    { subCode: 'CB', name: 'Chari-Baguirmi' },
    { subCode: 'EE', name: 'Ennedi-Est' },
    { subCode: 'EO', name: 'Ennedi-Ouest' },
    { subCode: 'GR', name: 'Gu\u00e9ra' },
    { subCode: 'HL', name: 'Hadjer Lamis' },
    { subCode: 'KA', name: 'Kanem' },
    { subCode: 'LC', name: 'Al Bu\u1e29ayrah' },
    { subCode: 'LO', name: 'Logone-Occidental' },
    { subCode: 'LR', name: 'Logone-Oriental' },
    { subCode: 'MA', name: 'Mandoul' },
    { subCode: 'MC', name: 'Moyen-Chari' },
    { subCode: 'ME', name: 'Mayo-Kebbi-Est' },
    { subCode: 'MO', name: 'Mayo-Kebbi-Ouest' },
    { subCode: 'ND', name: 'Mad\u012bnat Injam\u012bn\u0101' },
    { subCode: 'OD', name: 'Ouadda\u00ef' },
    { subCode: 'SA', name: 'Salamat' },
    { subCode: 'SI', name: 'Sila' },
    { subCode: 'TA', name: 'Tandjil\u00e9' },
    { subCode: 'TI', name: 'Tibast\u012b' },
    { subCode: 'WF', name: 'Wadi Fira' },
  ],
  CL: [
    { subCode: 'AI', name: 'Ays\u00e9n' },
    { subCode: 'AN', name: 'Antofagasta' },
    { subCode: 'AP', name: 'Arica y Parinacota' },
    { subCode: 'AR', name: 'Araucan\u00eda' },
    { subCode: 'AT', name: 'Atacama' },
    { subCode: 'BI', name: 'Biob\u00edo' },
    { subCode: 'CO', name: 'Coquimbo' },
    { subCode: 'LI', name: "Libertador General Bernardo O'Higgins" },
    { subCode: 'LL', name: 'Los Lagos' },
    { subCode: 'LR', name: 'Los R\u00edos' },
    { subCode: 'MA', name: 'Magallanes' },
    { subCode: 'ML', name: 'Maule' },
    { subCode: 'RM', name: 'Regi\u00f3n Metropolitana de Santiago' },
    { subCode: 'TA', name: 'Tarapac\u00e1' },
    { subCode: 'VS', name: 'Valpara\u00edso' },
  ],
  CN: [
    { subCode: '11', name: 'Beijing' },
    { subCode: '12', name: 'Tianjin' },
    { subCode: '13', name: 'Hebei' },
    { subCode: '14', name: 'Shanxi' },
    { subCode: '15', name: 'Nei Mongol' },
    { subCode: '21', name: 'Liaoning' },
    { subCode: '22', name: 'Jilin' },
    { subCode: '23', name: 'Heilongjiang' },
    { subCode: '31', name: 'Shanghai' },
    { subCode: '32', name: 'Jiangsu' },
    { subCode: '33', name: 'Zhejiang' },
    { subCode: '34', name: 'Anhui' },
    { subCode: '35', name: 'Fujian' },
    { subCode: '36', name: 'Jiangxi' },
    { subCode: '37', name: 'Shandong' },
    { subCode: '41', name: 'Henan' },
    { subCode: '42', name: 'Hubei' },
    { subCode: '43', name: 'Hunan' },
    { subCode: '44', name: 'Guangdong' },
    { subCode: '45', name: 'Guangxi' },
    { subCode: '46', name: 'Hainan' },
    { subCode: '50', name: 'Chongqing' },
    { subCode: '51', name: 'Sichuan' },
    { subCode: '52', name: 'Guizhou' },
    { subCode: '53', name: 'Yunnan' },
    { subCode: '54', name: 'Xizang' },
    { subCode: '61', name: 'Shaanxi' },
    { subCode: '62', name: 'Gansu' },
    { subCode: '63', name: 'Qinghai' },
    { subCode: '64', name: 'Ningxia' },
    { subCode: '65', name: 'Xinjiang' },
    { subCode: '71', name: 'Taiwan' },
    { subCode: '91', name: 'Hong Kong' },
    { subCode: '92', name: 'Aomen' },
  ],
  CX: [{ subCode: 'CX', name: 'Christmas Island' }],
  CC: [{ subCode: 'CC', name: 'Cocos  Islands' }],
  CO: [
    { subCode: 'AMA', name: 'Amazonas' },
    { subCode: 'ANT', name: 'Antioquia' },
    { subCode: 'ARA', name: 'Arauca' },
    { subCode: 'ATL', name: 'Atl\u00e1ntico' },
    { subCode: 'BOL', name: 'Bol\u00edvar' },
    { subCode: 'BOY', name: 'Boyac\u00e1' },
    { subCode: 'CAL', name: 'Caldas' },
    { subCode: 'CAQ', name: 'Caquet\u00e1' },
    { subCode: 'CAS', name: 'Casanare' },
    { subCode: 'CAU', name: 'Cauca' },
    { subCode: 'CES', name: 'Cesar' },
    { subCode: 'CHO', name: 'Choc\u00f3' },
    { subCode: 'COR', name: 'C\u00f3rdoba' },
    { subCode: 'CUN', name: 'Cundinamarca' },
    { subCode: 'DC', name: 'Distrito Capital de Bogot\u00e1' },
    { subCode: 'GUA', name: 'Guain\u00eda' },
    { subCode: 'GUV', name: 'Guaviare' },
    { subCode: 'HUI', name: 'Huila' },
    { subCode: 'LAG', name: 'La Guajira' },
    { subCode: 'MAG', name: 'Magdalena' },
    { subCode: 'MET', name: 'Meta' },
    { subCode: 'NAR', name: 'Nari\u00f1o' },
    { subCode: 'NSA', name: 'Norte de Santander' },
    { subCode: 'PUT', name: 'Putumayo' },
    { subCode: 'QUI', name: 'Quind\u00edo' },
    { subCode: 'RIS', name: 'Risaralda' },
    { subCode: 'SAN', name: 'Santander' },
    { subCode: 'SAP', name: 'San Andr\u00e9s, Providencia y Santa Catalina' },
    { subCode: 'SUC', name: 'Sucre' },
    { subCode: 'TOL', name: 'Tolima' },
    { subCode: 'VAC', name: 'Valle del Cauca' },
    { subCode: 'VAU', name: 'Vaup\u00e9s' },
    { subCode: 'VID', name: 'Vichada' },
  ],
  KM: [
    { subCode: 'A', name: 'Andjou\u00e2n' },
    { subCode: 'G', name: 'Andjaz\u00eedja' },
    { subCode: 'M', name: 'Moh\u00e9li' },
  ],
  CG: [
    { subCode: '11', name: 'Bouenza' },
    { subCode: '12', name: 'Pool' },
    { subCode: '13', name: 'Sangha' },
    { subCode: '14', name: 'Plateaux' },
    { subCode: '15', name: 'Cuvette-Ouest' },
    { subCode: '16', name: 'Pointe-Noire' },
    { subCode: '2', name: 'L\u00e9koumou' },
    { subCode: '5', name: 'Kouilou' },
    { subCode: '7', name: 'Likouala' },
    { subCode: '8', name: 'Cuvette' },
    { subCode: '9', name: 'Niari' },
    { subCode: 'BZV', name: 'Brazzaville' },
  ],
  CD: [
    { subCode: 'BC', name: 'Bas-Congo' },
    { subCode: 'BN', name: 'Bandundu' },
    { subCode: 'EQ', name: '\u00c9quateur' },
    { subCode: 'KA', name: 'Katanga' },
    { subCode: 'KE', name: 'Kasai-Oriental' },
    { subCode: 'KN', name: 'Kinshasa' },
    { subCode: 'KW', name: 'Kasai-Occidental' },
    { subCode: 'MA', name: 'Maniema' },
    { subCode: 'NK', name: 'Nord-Kivu' },
    { subCode: 'OR', name: 'Orientale' },
    { subCode: 'SK', name: 'Sud-Kivu' },
  ],
  CK: [{ subCode: 'CK', name: 'Cook Islands' }],
  CR: [
    { subCode: 'A', name: 'Alajuela' },
    { subCode: 'C', name: 'Cartago' },
    { subCode: 'G', name: 'Guanacaste' },
    { subCode: 'H', name: 'Heredia' },
    { subCode: 'L', name: 'Lim\u00f3n' },
    { subCode: 'P', name: 'Puntarenas' },
    { subCode: 'SJ', name: 'San Jos\u00e9' },
  ],
  CI: [
    { subCode: '01', name: 'Lagunes' },
    { subCode: '02', name: 'Haut-Sassandra' },
    { subCode: '03', name: 'Savanes' },
    { subCode: '04', name: 'Vall\u00e9e du Bandama' },
    { subCode: '05', name: 'Moyen-Como\u00e9' },
    { subCode: '06', name: '18 Montagnes' },
    { subCode: '07', name: 'Lacs' },
    { subCode: '08', name: 'Zanzan' },
    { subCode: '09', name: 'Bas-Sassandra' },
    { subCode: '10', name: 'Dengu\u00e9l\u00e9' },
    { subCode: '11', name: 'Nzi-Como\u00e9' },
    { subCode: '12', name: 'Marahou\u00e9' },
    { subCode: '13', name: 'Sud-Como\u00e9' },
    { subCode: '14', name: 'Worodougou' },
    { subCode: '15', name: 'Sud-Bandama' },
    { subCode: '16', name: 'Agn\u00e9bi' },
    { subCode: '17', name: 'Bafing' },
    { subCode: '18', name: 'Fromager' },
    { subCode: '19', name: 'Moyen-Cavally' },
  ],
  HR: [
    { subCode: '01', name: 'Zagreba\u010dka \u017eupanija' },
    { subCode: '02', name: 'Krapinsko-zagorska \u017eupanija' },
    { subCode: '03', name: 'Sisa\u010dko-moslava\u010dka \u017eupanija' },
    { subCode: '04', name: 'Karlova\u010dka \u017eupanija' },
    { subCode: '05', name: 'Vara\u017edinska \u017eupanija' },
    { subCode: '06', name: 'Koprivni\u010dko-kri\u017eeva\u010dka \u017eupanija' },
    { subCode: '07', name: 'Bjelovarsko-bilogorska \u017eupanija' },
    { subCode: '08', name: 'Primorsko-goranska \u017eupanija' },
    { subCode: '09', name: 'Li\u010dko-senjska \u017eupanija' },
    { subCode: '10', name: 'Viroviti\u010dko-podravska \u017eupanija' },
    { subCode: '11', name: 'Po\u017ee\u0161ko-slavonska \u017eupanija' },
    { subCode: '12', name: 'Brodsko-posavska \u017eupanija' },
    { subCode: '13', name: 'Zadarska \u017eupanija' },
    { subCode: '14', name: 'Osje\u010dko-baranjska \u017eupanija' },
    { subCode: '15', name: '\u0160ibensko-kninska \u017eupanija' },
    { subCode: '16', name: 'Vukovarsko-srijemska \u017eupanija' },
    { subCode: '17', name: 'Splitsko-dalmatinska \u017eupanija' },
    { subCode: '18', name: 'Istarska \u017eupanija' },
    { subCode: '19', name: 'Dubrova\u010dko-neretvanska \u017eupanija' },
    { subCode: '20', name: 'Me\u0111imurska \u017eupanija' },
    { subCode: '21', name: 'Grad Zagreb' },
  ],
  CW: [{ subCode: 'CW', name: 'Cura\u00e7ao' }],
  CY: [
    { subCode: '01', name: 'Lefko\u015fa' },
    { subCode: '02', name: 'Lemesos' },
    { subCode: '03', name: 'Larnaka' },
    { subCode: '04', name: 'Ammochostos' },
    { subCode: '05', name: 'Baf' },
    { subCode: '06', name: 'Girne' },
  ],
  CZ: [
    { subCode: '101', name: 'Praha 1' },
    { subCode: '102', name: 'Praha 2' },
    { subCode: '103', name: 'Praha 3' },
    { subCode: '104', name: 'Praha 4' },
    { subCode: '105', name: 'Praha 5' },
    { subCode: '106', name: 'Praha 6' },
    { subCode: '107', name: 'Praha 7' },
    { subCode: '108', name: 'Praha 8' },
    { subCode: '109', name: 'Praha 9' },
    { subCode: '10A', name: 'Praha 10' },
    { subCode: '10B', name: 'Praha 11' },
    { subCode: '10C', name: 'Praha 12' },
    { subCode: '10D', name: 'Praha 13' },
    { subCode: '10E', name: 'Praha 14' },
    { subCode: '10F', name: 'Praha 15' },
    { subCode: '201', name: 'Bene\u0161ov' },
    { subCode: '202', name: 'Beroun' },
    { subCode: '203', name: 'Kladno' },
    { subCode: '204', name: 'Kol\u00edn' },
    { subCode: '205', name: 'Kutn\u00e1 Hora' },
    { subCode: '206', name: 'M\u011bln\u00edk' },
    { subCode: '207', name: 'Mlad\u00e1 Boleslav' },
    { subCode: '208', name: 'Nymburk' },
    { subCode: '209', name: 'Praha-v\u00fdchod' },
    { subCode: '20A', name: 'Praha-z\u00e1pad' },
    { subCode: '20B', name: 'P\u0159\u00edbram' },
    { subCode: '20C', name: 'Rakovn\u00edk' },
    { subCode: '311', name: '\u010cesk\u00e9 Bud\u011bjovice' },
    { subCode: '312', name: '\u010cesk\u00fd Krumlov' },
    { subCode: '313', name: 'Jind\u0159ich\u016fv Hradec' },
    { subCode: '314', name: 'P\u00edsek' },
    { subCode: '315', name: 'Prachatice' },
    { subCode: '316', name: 'Strakonice' },
    { subCode: '317', name: 'T\u00e1bor' },
    { subCode: '321', name: 'Doma\u017elice' },
    { subCode: '322', name: 'Klatovy' },
    { subCode: '323', name: 'Plze\u0148-m\u011bsto' },
    { subCode: '324', name: 'Plze\u0148-jih' },
    { subCode: '325', name: 'Plze\u0148-sever' },
    { subCode: '326', name: 'Rokycany' },
    { subCode: '327', name: 'Tachov' },
    { subCode: '411', name: 'Cheb' },
    { subCode: '412', name: 'Karlovy Vary' },
    { subCode: '413', name: 'Sokolov' },
    { subCode: '421', name: 'D\u011b\u010d\u00edn' },
    { subCode: '422', name: 'Chomutov' },
    { subCode: '423', name: 'Litom\u011b\u0159ice' },
    { subCode: '424', name: 'Louny' },
    { subCode: '425', name: 'Most' },
    { subCode: '426', name: 'Teplice' },
    { subCode: '427', name: '\u00dast\u00ed nad Labem' },
    { subCode: '511', name: '\u010cesk\u00e1 L\u00edpa' },
    { subCode: '512', name: 'Jablonec nad Nisou' },
    { subCode: '513', name: 'Liberec' },
    { subCode: '514', name: 'Semily' },
    { subCode: '521', name: 'Hradec Kr\u00e1lov\u00e9' },
    { subCode: '522', name: 'Ji\u010d\u00edn' },
    { subCode: '523', name: 'N\u00e1chod' },
    { subCode: '524', name: 'Rychnov nad Kn\u011b\u017enou' },
    { subCode: '525', name: 'Trutnov' },
    { subCode: '531', name: 'Chrudim' },
    { subCode: '532', name: 'Pardubice' },
    { subCode: '533', name: 'Svitavy' },
    { subCode: '534', name: '\u00dast\u00ed nad Orlic\u00ed' },
    { subCode: '611', name: 'Havl\u00ed\u010dk\u016fv Brod' },
    { subCode: '612', name: 'Jihlava' },
    { subCode: '613', name: 'Pelh\u0159imov' },
    { subCode: '614', name: 'T\u0159eb\u00ed\u010d' },
    { subCode: '615', name: "\u017dd'\u00e1r nad S\u00e1zavou" },
    { subCode: '621', name: 'Blansko' },
    { subCode: '622', name: 'Brno-m\u011bsto' },
    { subCode: '623', name: 'Brno-venkov' },
    { subCode: '624', name: 'B\u0159eclav' },
    { subCode: '625', name: 'Hodon\u00edn' },
    { subCode: '626', name: 'Vy\u0161kov' },
    { subCode: '627', name: 'Znojmo' },
    { subCode: '711', name: 'Jesen\u00edk' },
    { subCode: '712', name: 'Olomouc' },
    { subCode: '713', name: 'Prost\u0115jov' },
    { subCode: '714', name: 'P\u0159erov' },
    { subCode: '715', name: '\u0160umperk' },
    { subCode: '721', name: 'Krom\u0115\u0159\u00ed\u017e' },
    { subCode: '722', name: 'Uhersk\u00e9 Hradi\u0161t\u0115' },
    { subCode: '723', name: 'Vset\u00edn' },
    { subCode: '724', name: 'Zl\u00edn' },
    { subCode: '801', name: 'Brunt\u00e1l' },
    { subCode: '802', name: 'Fr\u00fddek M\u00edstek' },
    { subCode: '803', name: 'Karvin\u00e1' },
    { subCode: '804', name: 'Nov\u00fd Ji\u010d\u00edn' },
    { subCode: '805', name: 'Opava' },
    { subCode: '806', name: 'Ostrava m\u011bsto' },
    { subCode: 'JC', name: 'Jiho\u010desk\u00fd kraj' },
    { subCode: 'JM', name: 'Jihomoravsk\u00fd kraj' },
    { subCode: 'KA', name: 'Karlovarsk\u00fd kraj' },
    { subCode: 'KR', name: 'Kr\u00e1lov\u00e9hradeck\u00fd kraj' },
    { subCode: 'LI', name: 'Libereck\u00fd kraj' },
    { subCode: 'MO', name: 'Moravskoslezsk\u00fd kraj' },
    { subCode: 'OL', name: 'Olomouck\u00fd kraj' },
    { subCode: 'PA', name: 'Pardubick\u00fd kraj' },
    { subCode: 'PL', name: 'Plze\u0148sk\u00fd kraj' },
    { subCode: 'PR', name: 'Praha, hlavn\u00ed me\u0161to' },
    { subCode: 'ST', name: 'St\u0159edo\u010desk\u00fd kraj' },
    { subCode: 'US', name: '\u00dasteck\u00fd kraj' },
    { subCode: 'VY', name: 'Vyso\u010dina' },
    { subCode: 'ZL', name: 'Zl\u00ednsk\u00fd kraj' },
  ],
  DK: [
    { subCode: '81', name: 'Nordjylland' },
    { subCode: '82', name: 'Midtjylland' },
    { subCode: '83', name: 'Syddanmark' },
    { subCode: '84', name: 'Hovedstaden' },
    { subCode: '85', name: 'Sj\u00e6lland' },
  ],
  DJ: [
    { subCode: 'AR', name: 'Arta' },
    { subCode: 'AS', name: 'Ali Sabieh' },
    { subCode: 'DI', name: 'Dikh\u012bl' },
    { subCode: 'DJ', name: 'Djibouti' },
    { subCode: 'OB', name: 'Obock' },
    { subCode: 'TA', name: 'Tadjourah' },
  ],
  DM: [
    { subCode: '02', name: 'Saint Andrew' },
    { subCode: '03', name: 'Saint David' },
    { subCode: '04', name: 'Saint George' },
    { subCode: '05', name: 'Saint John' },
    { subCode: '06', name: 'Saint Joseph' },
    { subCode: '07', name: 'Saint Luke' },
    { subCode: '08', name: 'Saint Mark' },
    { subCode: '09', name: 'Saint Patrick' },
    { subCode: '10', name: 'Saint Paul' },
    { subCode: '11', name: 'Saint Peter' },
  ],
  DO: [
    { subCode: '01', name: 'Distrito Nacional' },
    { subCode: '02', name: 'Azua' },
    { subCode: '03', name: 'Baoruco' },
    { subCode: '04', name: 'Barahona' },
    { subCode: '05', name: 'Dajab\u00f3n' },
    { subCode: '06', name: 'Duarte' },
    { subCode: '07', name: 'La Estrelleta' },
    { subCode: '08', name: 'El Seibo' },
    { subCode: '09', name: 'Espaillat' },
    { subCode: '10', name: 'Independencia' },
    { subCode: '11', name: 'La Altagracia' },
    { subCode: '12', name: 'La Romana' },
    { subCode: '13', name: 'La Vega' },
    { subCode: '14', name: 'Mar\u00eda Trinidad S\u00e1nchez' },
    { subCode: '15', name: 'Monte Cristi' },
    { subCode: '16', name: 'Pedernales' },
    { subCode: '17', name: 'Peravia' },
    { subCode: '18', name: 'Puerto Plata' },
    { subCode: '19', name: 'Hermanas Mirabal' },
    { subCode: '20', name: 'Saman\u00e1' },
    { subCode: '21', name: 'San Crist\u00f3bal' },
    { subCode: '22', name: 'San Juan' },
    { subCode: '23', name: 'San Pedro de Macor\u00eds' },
    { subCode: '24', name: 'S\u00e1nchez Ram\u00edrez' },
    { subCode: '25', name: 'Santiago' },
    { subCode: '26', name: 'Santiago Rodr\u00edguez' },
    { subCode: '27', name: 'Valverde' },
    { subCode: '28', name: 'Monse\u00f1or Nouel' },
    { subCode: '29', name: 'Monte Plata' },
    { subCode: '30', name: 'Hato Mayor' },
    { subCode: '31', name: 'San Jos\u00e9 de Ocoa' },
    { subCode: '32', name: 'Santo Domingo' },
    { subCode: '33', name: 'Cibao Nordeste' },
    { subCode: '34', name: 'Cibao Noroeste' },
    { subCode: '35', name: 'Cibao Norte' },
    { subCode: '36', name: 'Cibao Sur' },
    { subCode: '37', name: 'El Valle' },
    { subCode: '38', name: 'Enriquillo' },
    { subCode: '39', name: 'Higuamo' },
    { subCode: '40', name: 'Ozama' },
    { subCode: '41', name: 'Valdesia' },
    { subCode: '42', name: 'Yuma' },
  ],
  EC: [
    { subCode: 'A', name: 'Azuay' },
    { subCode: 'B', name: 'Bol\u00edvar' },
    { subCode: 'C', name: 'Carchi' },
    { subCode: 'D', name: 'Orellana' },
    { subCode: 'E', name: 'Esmeraldas' },
    { subCode: 'F', name: 'Ca\u00f1ar' },
    { subCode: 'G', name: 'Guayas' },
    { subCode: 'H', name: 'Chimborazo' },
    { subCode: 'I', name: 'Imbabura' },
    { subCode: 'L', name: 'Loja' },
    { subCode: 'M', name: 'Manab\u00ed' },
    { subCode: 'N', name: 'Napo' },
    { subCode: 'O', name: 'El Oro' },
    { subCode: 'P', name: 'Pichincha' },
    { subCode: 'R', name: 'Los R\u00edos' },
    { subCode: 'SD', name: 'Santo Domingo de los Ts\u00e1chilas' },
    { subCode: 'SE', name: 'Santa Elena' },
    { subCode: 'S', name: 'Morona-Santiago' },
    { subCode: 'T', name: 'Tungurahua' },
    { subCode: 'U', name: 'Sucumb\u00edos' },
    { subCode: 'W', name: 'Gal\u00e1pagos' },
    { subCode: 'X', name: 'Cotopaxi' },
    { subCode: 'Y', name: 'Pastaza' },
    { subCode: 'Z', name: 'Zamora-Chinchipe' },
  ],
  EG: [
    { subCode: 'ALX', name: 'Al Iskandar\u012byah' },
    { subCode: 'ASN', name: 'Asw\u0101n' },
    { subCode: 'AST', name: 'Asy\u016b\u0163' },
    { subCode: 'BA', name: 'Al Ba\u1e29r al A\u1e29mar' },
    { subCode: 'BH', name: 'Al Bu\u1e29ayrah' },
    { subCode: 'BNS', name: 'Ban\u012b Suwayf' },
    { subCode: 'C', name: 'Al Q\u0101hirah' },
    { subCode: 'DK', name: 'Ad Daqahl\u012byah' },
    { subCode: 'DT', name: 'Dumy\u0101\u0163' },
    { subCode: 'FYM', name: 'Al Fayy\u016bm' },
    { subCode: 'GH', name: 'Al Gharb\u012byah' },
    { subCode: 'GZ', name: 'Al J\u012bzah' },
    { subCode: 'IS', name: "Al Ism\u0101'\u012bl\u012byah" },
    { subCode: 'JS', name: "Jan\u016bb S\u012bn\u0101'" },
    { subCode: 'KB', name: 'Al Qaly\u016bb\u012byah' },
    { subCode: 'KFS', name: 'Kafr ash Shaykh' },
    { subCode: 'KN', name: 'Qin\u0101' },
    { subCode: 'LX', name: 'Al Uq\u015fur' },
    { subCode: 'MN', name: 'Al Miny\u0101' },
    { subCode: 'MNF', name: 'Al Min\u016bf\u012byah' },
    { subCode: 'MT', name: 'Ma\u0163r\u016b\u1e29' },
    { subCode: 'PTS', name: 'B\u016br Sa\u2018\u012bd' },
    { subCode: 'SHG', name: 'S\u016bh\u0101j' },
    { subCode: 'SHR', name: 'Ash Sharq\u012byah' },
    { subCode: 'SIN', name: "Sham\u0101l S\u012bn\u0101'" },
    { subCode: 'SUZ', name: 'As Suways' },
    { subCode: 'WAD', name: 'Al W\u0101d\u012b al Jad\u012bd' },
  ],
  SV: [
    { subCode: 'AH', name: 'Ahuachap\u00e1n' },
    { subCode: 'CA', name: 'Caba\u00f1as' },
    { subCode: 'CH', name: 'Chalatenango' },
    { subCode: 'CU', name: 'Cuscatl\u00e1n' },
    { subCode: 'LI', name: 'La Libertad' },
    { subCode: 'MO', name: 'Moraz\u00e1n' },
    { subCode: 'PA', name: 'La Paz' },
    { subCode: 'SA', name: 'Santa Ana' },
    { subCode: 'SM', name: 'San Miguel' },
    { subCode: 'SO', name: 'Sonsonate' },
    { subCode: 'SS', name: 'San Salvador' },
    { subCode: 'SV', name: 'San Vicente' },
    { subCode: 'UN', name: 'La Uni\u00f3n' },
    { subCode: 'US', name: 'Usulut\u00e1n' },
  ],
  GQ: [
    { subCode: 'AN', name: 'Annob\u00f3n' },
    { subCode: 'BN', name: 'Bioko Nord' },
    { subCode: 'BS', name: 'Bioko Sud' },
    { subCode: 'C', name: 'Regi\u00e3o Continental' },
    { subCode: 'CS', name: 'Centro Sud' },
    { subCode: 'I', name: 'Regi\u00e3o Insular' },
    { subCode: 'KN', name: 'Ki\u00e9-Ntem' },
    { subCode: 'LI', name: 'Litoral' },
    { subCode: 'WN', name: 'Wele-Nzas' },
  ],
  ER: [
    { subCode: 'AN', name: 'Ansab\u0101' },
    { subCode: 'DK', name: 'Debubawi K\u2019eyy\u012d\u1e25 Ba\u1e25ri' },
    { subCode: 'DU', name: 'Al Jan\u016bb\u012b' },
    { subCode: 'GB', name: 'Gash-Barka' },
    { subCode: 'MA', name: 'Al Awsa\u0163' },
    { subCode: 'SK', name: 'Semienawi K\u2019eyy\u012d\u1e25 Ba\u1e25ri' },
  ],
  EE: [
    { subCode: '37', name: 'Harjumaa' },
    { subCode: '39', name: 'Hiiumaa' },
    { subCode: '44', name: 'Ida-Virumaa' },
    { subCode: '49', name: 'J\u00f5gevamaa' },
    { subCode: '51', name: 'J\u00e4rvamaa' },
    { subCode: '57', name: 'L\u00e4\u00e4nemaa' },
    { subCode: '59', name: 'L\u00e4\u00e4ne-Virumaa' },
    { subCode: '65', name: 'P\u00f5lvamaa' },
    { subCode: '67', name: 'P\u00e4rnumaa' },
    { subCode: '70', name: 'Raplamaa' },
    { subCode: '74', name: 'Saaremaa' },
    { subCode: '78', name: 'Tartumaa' },
    { subCode: '82', name: 'Valgamaa' },
    { subCode: '84', name: 'Viljandimaa' },
    { subCode: '86', name: 'V\u00f5rumaa' },
  ],
  ET: [
    { subCode: 'AA', name: 'Addis Ababa' },
    { subCode: 'AF', name: 'Afar' },
    { subCode: 'AM', name: 'Amara' },
    { subCode: 'BE', name: 'Benshangul-Gumaz' },
    { subCode: 'DD', name: 'Dire Dawa' },
    { subCode: 'GA', name: 'Gamb\u0113la Hizboch' },
    { subCode: 'HA', name: 'Harari People' },
    { subCode: 'OR', name: 'Oromia' },
    { subCode: 'SN', name: 'Southern Nations, Nationalities and Peoples' },
    { subCode: 'SO', name: 'Somali' },
    { subCode: 'TI', name: 'Tigrai' },
  ],
  FK: [{ subCode: 'FK', name: 'Falkland Islands' }],
  FO: [{ subCode: 'FO', name: 'Faroe Islands' }],
  FJ: [
    { subCode: '01', name: 'Ba' },
    { subCode: '02', name: 'Bua' },
    { subCode: '03', name: 'Cakaudrove' },
    { subCode: '04', name: 'Kadavu' },
    { subCode: '05', name: 'Lau' },
    { subCode: '06', name: 'Lomaiviti' },
    { subCode: '07', name: 'Macuata' },
    { subCode: '08', name: 'Nadroga and Navosa' },
    { subCode: '09', name: 'Naitasiri' },
    { subCode: '10', name: 'Namosi' },
    { subCode: '11', name: 'Ra' },
    { subCode: '12', name: 'Rewa' },
    { subCode: '13', name: 'Serua' },
    { subCode: '14', name: 'Tailevu' },
    { subCode: 'C', name: 'Central' },
    { subCode: 'E', name: 'Eastern' },
    { subCode: 'N', name: 'Northern' },
    { subCode: 'R', name: 'Rotuma' },
    { subCode: 'W', name: 'Western' },
  ],
  FI: [
    { subCode: '01', name: 'Ahvenanmaan maakunta' },
    { subCode: '02', name: 'Etel\u00e4-Karjala' },
    { subCode: '03', name: 'Etel\u00e4-Pohjanmaa' },
    { subCode: '04', name: 'Etel\u00e4-Savo' },
    { subCode: '05', name: 'Kainuu' },
    { subCode: '06', name: 'Egentliga Tavastland' },
    { subCode: '07', name: 'Keski-Pohjanmaa' },
    { subCode: '08', name: 'Keski-Suomi' },
    { subCode: '09', name: 'Kymenlaakso' },
    { subCode: '10', name: 'Lappi' },
    { subCode: '11', name: 'Birkaland' },
    { subCode: '12', name: '\u00d6sterbotten' },
    { subCode: '13', name: 'Norra Karelen' },
    { subCode: '14', name: 'Norra \u00d6sterbotten' },
    { subCode: '15', name: 'Norra Savolax' },
    { subCode: '16', name: 'P\u00e4ij\u00e4nne-Tavastland' },
    { subCode: '17', name: 'Satakunda' },
    { subCode: '18', name: 'Nyland' },
    { subCode: '19', name: 'Egentliga Finland' },
  ],
  FR: [
    { subCode: '01', name: 'Ain' },
    { subCode: '02', name: 'Aisne' },
    { subCode: '03', name: 'Allier' },
    { subCode: '04', name: 'Alpes-de-Haute-Provence' },
    { subCode: '05', name: 'Hautes-Alpes' },
    { subCode: '06', name: 'Alpes-Maritimes' },
    { subCode: '07', name: 'Ard\u00e8che' },
    { subCode: '08', name: 'Ardennes' },
    { subCode: '09', name: 'Ari\u00e8ge' },
    { subCode: '10', name: 'Aube' },
    { subCode: '11', name: 'Aude' },
    { subCode: '12', name: 'Aveyron' },
    { subCode: '13', name: 'Bouches-du-Rh\u00f4ne' },
    { subCode: '14', name: 'Calvados' },
    { subCode: '15', name: 'Cantal' },
    { subCode: '16', name: 'Charente' },
    { subCode: '17', name: 'Charente-Maritime' },
    { subCode: '18', name: 'Cher' },
    { subCode: '19', name: 'Corr\u00e8ze' },
    { subCode: '21', name: "C\u00f4te-d'Or" },
    { subCode: '22', name: "C\u00f4tes-d'Armor" },
    { subCode: '23', name: 'Creuse' },
    { subCode: '24', name: 'Dordogne' },
    { subCode: '25', name: 'Doubs' },
    { subCode: '26', name: 'Dr\u00f4me' },
    { subCode: '27', name: 'Eure' },
    { subCode: '28', name: 'Eure-et-Loir' },
    { subCode: '29', name: 'Finist\u00e8re' },
    { subCode: '2A', name: 'Corse-du-Sud' },
    { subCode: '2B', name: 'Haute-Corse' },
    { subCode: '30', name: 'Gard' },
    { subCode: '31', name: 'Haute-Garonne' },
    { subCode: '32', name: 'Gers' },
    { subCode: '33', name: 'Gironde' },
    { subCode: '34', name: 'H\u00e9rault' },
    { subCode: '35', name: 'Ille-et-Vilaine' },
    { subCode: '36', name: 'Indre' },
    { subCode: '37', name: 'Indre-et-Loire' },
    { subCode: '38', name: 'Is\u00e8re' },
    { subCode: '39', name: 'Jura' },
    { subCode: '40', name: 'Landes' },
    { subCode: '41', name: 'Loir-et-Cher' },
    { subCode: '42', name: 'Loire' },
    { subCode: '43', name: 'Haute-Loire' },
    { subCode: '44', name: 'Loire-Atlantique' },
    { subCode: '45', name: 'Loiret' },
    { subCode: '46', name: 'Lot' },
    { subCode: '47', name: 'Lot-et-Garonne' },
    { subCode: '48', name: 'Loz\u00e8re' },
    { subCode: '49', name: 'Maine-et-Loire' },
    { subCode: '50', name: 'Manche' },
    { subCode: '51', name: 'Marne' },
    { subCode: '52', name: 'Haute-Marne' },
    { subCode: '53', name: 'Mayenne' },
    { subCode: '54', name: 'Meurthe-et-Moselle' },
    { subCode: '55', name: 'Meuse' },
    { subCode: '56', name: 'Morbihan' },
    { subCode: '57', name: 'Moselle' },
    { subCode: '58', name: 'Ni\u00e8vre' },
    { subCode: '59', name: 'Nord' },
    { subCode: '60', name: 'Oise' },
    { subCode: '61', name: 'Orne' },
    { subCode: '62', name: 'Pas-de-Calais' },
    { subCode: '63', name: 'Puy-de-D\u00f4me' },
    { subCode: '64', name: 'Pyr\u00e9n\u00e9es-Atlantiques' },
    { subCode: '65', name: 'Hautes-Pyr\u00e9n\u00e9es' },
    { subCode: '66', name: 'Pyr\u00e9n\u00e9es-Orientales' },
    { subCode: '67', name: 'Bas-Rhin' },
    { subCode: '68', name: 'Haut-Rhin' },
    { subCode: '69', name: 'Rh\u00f4ne' },
    { subCode: '70', name: 'Haute-Sa\u00f4ne' },
    { subCode: '71', name: 'Sa\u00f4ne-et-Loire' },
    { subCode: '72', name: 'Sarthe' },
    { subCode: '73', name: 'Savoie' },
    { subCode: '74', name: 'Haute-Savoie' },
    { subCode: '75', name: 'Paris' },
    { subCode: '76', name: 'Seine-Maritime' },
    { subCode: '77', name: 'Seine-et-Marne' },
    { subCode: '78', name: 'Yvelines' },
    { subCode: '79', name: 'Deux-S\u00e8vres' },
    { subCode: '80', name: 'Somme' },
    { subCode: '81', name: 'Tarn' },
    { subCode: '82', name: 'Tarn-et-Garonne' },
    { subCode: '83', name: 'Var' },
    { subCode: '84', name: 'Vaucluse' },
    { subCode: '85', name: 'Vend\u00e9e' },
    { subCode: '86', name: 'Vienne' },
    { subCode: '87', name: 'Haute-Vienne' },
    { subCode: '88', name: 'Vosges' },
    { subCode: '89', name: 'Yonne' },
    { subCode: '90', name: 'Territoire de Belfort' },
    { subCode: '91', name: 'Essonne' },
    { subCode: '92', name: 'Hauts-de-Seine' },
    { subCode: '93', name: 'Seine-Saint-Denis' },
    { subCode: '94', name: 'Val-de-Marne' },
    { subCode: '95', name: "Val-d'Oise" },
    { subCode: 'A', name: 'Alsace' },
    { subCode: 'B', name: 'Aquitaine' },
    { subCode: 'BL', name: 'Saint-Barth\u00e9lemy' },
    { subCode: 'C', name: 'Auvergne' },
    { subCode: 'CP', name: 'Clipperton' },
    { subCode: 'D', name: 'Bourgogne' },
    { subCode: 'E', name: 'Bretagne' },
    { subCode: 'F', name: 'Centre' },
    { subCode: 'G', name: 'Champagne-Ardenne' },
    { subCode: 'GF', name: 'Guyane' },
    { subCode: 'GP', name: 'Guadeloupe' },
    { subCode: 'H', name: 'Corse' },
    { subCode: 'I', name: 'Franche-Comt\u00e9' },
    { subCode: 'J', name: '\u00cele-de-France' },
    { subCode: 'K', name: 'Languedoc-Roussillon' },
    { subCode: 'L', name: 'Limousin' },
    { subCode: 'MF', name: 'Saint-Martin' },
    { subCode: 'M', name: 'Lorraine' },
    { subCode: 'MQ', name: 'Martinique' },
    { subCode: 'NC', name: 'Nouvelle-Cal\u00e9donie' },
    { subCode: 'N', name: 'Midi-Pyr\u00e9n\u00e9es' },
    { subCode: 'O', name: 'Nord-Pas-de-Calais' },
    { subCode: 'P', name: 'Basse-Normandie' },
    { subCode: 'PF', name: 'Polyn\u00e9sie fran\u00e7aise' },
    { subCode: 'PM', name: 'Saint-Pierre-et-Miquelon' },
    { subCode: 'Q', name: 'Haute-Normandie' },
    { subCode: 'RE', name: 'La R\u00e9union' },
    { subCode: 'R', name: 'Pays-de-la-Loire' },
    { subCode: 'S', name: 'Picardie' },
    { subCode: 'TF', name: 'Terres australes fran\u00e7aises' },
    { subCode: 'T', name: 'Poitou-Charentes' },
    { subCode: 'U', name: "Provence-Alpes-C\u00f4te-d'Azur" },
    { subCode: 'V', name: 'Rh\u00f4ne-Alpes' },
    { subCode: 'WF', name: 'Wallis-et-Futuna' },
    { subCode: 'YT', name: 'Mayotte' },
  ],
  GF: [{ subCode: 'GF', name: 'French Guiana' }],
  PF: [{ subCode: 'PF', name: 'French Polynesia' }],
  TF: [{ subCode: 'TF', name: 'French Southern Territories' }],
  GA: [
    { subCode: '1', name: 'Estuaire' },
    { subCode: '2', name: 'Haut-Ogoou\u00e9' },
    { subCode: '3', name: 'Moyen-Ogoou\u00e9' },
    { subCode: '4', name: 'Ngouni\u00e9' },
    { subCode: '5', name: 'Nyanga' },
    { subCode: '6', name: 'Ogoou\u00e9-Ivindo' },
    { subCode: '7', name: 'Ogoou\u00e9-Lolo' },
    { subCode: '8', name: 'Ogoou\u00e9-Maritime' },
    { subCode: '9', name: 'Woleu-Ntem' },
  ],
  GM: [
    { subCode: 'B', name: 'Banjul' },
    { subCode: 'L', name: 'Lower River' },
    { subCode: 'M', name: 'Central River' },
    { subCode: 'N', name: 'North Bank' },
    { subCode: 'U', name: 'Upper River' },
    { subCode: 'W', name: 'Western' },
  ],
  GE: [
    { subCode: 'AB', name: 'Abkhazia' },
    { subCode: 'AJ', name: 'Ajaria' },
    { subCode: 'GU', name: 'Guria' },
    { subCode: 'IM', name: 'Imereti' },
    { subCode: 'KA', name: "K'akheti" },
    { subCode: 'KK', name: 'Kvemo Kartli' },
    { subCode: 'MM', name: 'Mtskheta-Mtianeti' },
    { subCode: 'RL', name: "Rach'a-Lechkhumi-Kvemo Svaneti" },
    { subCode: 'SJ', name: 'Samtskhe-Javakheti' },
    { subCode: 'SK', name: 'Shida Kartli' },
    { subCode: 'SZ', name: 'Samegrelo-Zemo Svaneti' },
    { subCode: 'TB', name: 'Tbilisi' },
  ],
  DE: [
    { subCode: 'BB', name: 'Brandenburg' },
    { subCode: 'BE', name: 'Berlin' },
    { subCode: 'BW', name: 'Baden-W\u00fcrttemberg' },
    { subCode: 'BY', name: 'Bayern' },
    { subCode: 'HB', name: 'Bremen' },
    { subCode: 'HE', name: 'Hessen' },
    { subCode: 'HH', name: 'Hamburg' },
    { subCode: 'MV', name: 'Mecklenburg-Vorpommern' },
    { subCode: 'NI', name: 'Niedersachsen' },
    { subCode: 'NW', name: 'Nordrhein-Westfalen' },
    { subCode: 'RP', name: 'Rheinland-Pfalz' },
    { subCode: 'SH', name: 'Schleswig-Holstein' },
    { subCode: 'SL', name: 'Saarland' },
    { subCode: 'SN', name: 'Sachsen' },
    { subCode: 'ST', name: 'Sachsen-Anhalt' },
    { subCode: 'TH', name: 'Th\u00fcringen' },
  ],
  GH: [
    { subCode: 'AA', name: 'Greater Accra' },
    { subCode: 'AH', name: 'Ashanti' },
    { subCode: 'BA', name: 'Brong-Ahafo' },
    { subCode: 'CP', name: 'Central' },
    { subCode: 'EP', name: 'Eastern' },
    { subCode: 'NP', name: 'Northern' },
    { subCode: 'TV', name: 'Volta' },
    { subCode: 'UE', name: 'Upper East' },
    { subCode: 'UW', name: 'Upper West' },
    { subCode: 'WP', name: 'Western' },
  ],
  GI: [{ subCode: 'GI', name: 'Gibraltar' }],
  GR: [
    { subCode: '01', name: 'Aitoloakarnan\u00eda' },
    { subCode: '03', name: 'Voiot\u00eda' },
    { subCode: '04', name: '\u00c9vvoia' },
    { subCode: '05', name: 'Evrytan\u00eda' },
    { subCode: '06', name: 'Fthi\u00f3tida' },
    { subCode: '07', name: 'Fok\u00edda' },
    { subCode: '11', name: 'Argol\u00edda' },
    { subCode: '12', name: 'Arkad\u00eda' },
    { subCode: '13', name: 'Acha\u1e2fa' },
    { subCode: '14', name: 'Ile\u00eda' },
    { subCode: '15', name: 'Korinth\u00eda' },
    { subCode: '16', name: 'Lakon\u00eda' },
    { subCode: '17', name: 'Messin\u00eda' },
    { subCode: '21', name: 'Z\u00e1kynthos' },
    { subCode: '22', name: 'K\u00e9rkyra' },
    { subCode: '23', name: 'Kefallin\u00eda' },
    { subCode: '24', name: 'Lefk\u00e1da' },
    { subCode: '31', name: '\u00c1rta' },
    { subCode: '32', name: 'Thesprot\u00eda' },
    { subCode: '33', name: 'Io\u00e1nnina' },
    { subCode: '34', name: 'Pr\u00e9veza' },
    { subCode: '41', name: 'Kard\u00edtsa' },
    { subCode: '42', name: 'L\u00e1risa' },
    { subCode: '43', name: 'Magnis\u00eda' },
    { subCode: '44', name: 'Tr\u00edkala' },
    { subCode: '51', name: 'Greven\u00e1' },
    { subCode: '52', name: 'Dr\u00e1ma' },
    { subCode: '53', name: 'Imath\u00eda' },
    { subCode: '54', name: 'Thessalon\u00edki' },
    { subCode: '55', name: 'Kav\u00e1la' },
    { subCode: '56', name: 'Kastori\u00e1' },
    { subCode: '57', name: 'Kilk\u00eds' },
    { subCode: '58', name: 'Koz\u00e1ni' },
    { subCode: '59', name: 'P\u00e9lla' },
    { subCode: '61', name: 'Pier\u00eda' },
    { subCode: '62', name: 'S\u00e9rres' },
    { subCode: '63', name: 'Fl\u00f3rina' },
    { subCode: '64', name: 'Chalkidik\u00ed' },
    { subCode: '69', name: '\u00c1gion \u00d3ros' },
    { subCode: '71', name: '\u00c9vros' },
    { subCode: '72', name: 'X\u00e1nthi' },
    { subCode: '73', name: 'Rod\u00f3pi' },
    { subCode: '81', name: 'Dodek\u00e1nisa' },
    { subCode: '82', name: 'Kykl\u00e1des' },
    { subCode: '83', name: 'L\u00e9svos' },
    { subCode: '84', name: 'S\u00e1mos' },
    { subCode: '85', name: 'Ch\u00edos' },
    { subCode: '91', name: 'Irakle\u00edo' },
    { subCode: '92', name: 'Las\u00edthi' },
    { subCode: '93', name: 'Reth\u00fdmnis' },
    { subCode: '94', name: 'Chani\u00e1' },
    { subCode: 'A1', name: 'Attik\u00ed' },
    { subCode: 'A', name: 'Anatolik\u00ed Makedon\u00eda kai Thr\u00e1ki' },
    { subCode: 'B', name: 'Kentrik\u00ed Makedon\u00eda' },
    { subCode: 'C', name: 'Dytik\u00ed Makedon\u00eda' },
    { subCode: 'D', name: '\u00cdpeiros' },
    { subCode: 'E', name: 'Thessal\u00eda' },
    { subCode: 'F', name: 'Ion\u00eda N\u00edsia' },
    { subCode: 'G', name: 'Dytik\u00ed Ell\u00e1da' },
    { subCode: 'H', name: 'Stere\u00e1 Ell\u00e1da' },
    { subCode: 'I', name: 'Attik\u00ed' },
    { subCode: 'J', name: 'Peloponn\u00edsos' },
    { subCode: 'K', name: 'Vore\u00edo Aiga\u00edo' },
    { subCode: 'L', name: 'Not\u00edo Aiga\u00edo' },
    { subCode: 'M', name: 'Kr\u00edt\u00ed' },
  ],
  GL: [
    { subCode: 'KU', name: 'Kommune Kujalleq' },
    { subCode: 'QA', name: 'Qaasuitsup Kommunia' },
    { subCode: 'QE', name: 'Qeqqata Kommunia' },
    { subCode: 'SM', name: 'Kommuneqarfik Sermersooq' },
  ],
  GD: [
    { subCode: '01', name: 'Saint Andrew' },
    { subCode: '02', name: 'Saint David' },
    { subCode: '03', name: 'Saint George' },
    { subCode: '04', name: 'Saint John' },
    { subCode: '05', name: 'Saint Mark' },
    { subCode: '06', name: 'Saint Patrick' },
    { subCode: '10', name: 'Southern Grenadine Islands' },
  ],
  GP: [{ subCode: 'GP', name: 'Guadeloupe' }],
  GU: [{ subCode: 'GU', name: 'Guam' }],
  GT: [
    { subCode: 'AV', name: 'Alta Verapaz' },
    { subCode: 'BV', name: 'Baja Verapaz' },
    { subCode: 'CM', name: 'Chimaltenango' },
    { subCode: 'CQ', name: 'Chiquimula' },
    { subCode: 'ES', name: 'Escuintla' },
    { subCode: 'GU', name: 'Guatemala' },
    { subCode: 'HU', name: 'Huehuetenango' },
    { subCode: 'IZ', name: 'Izabal' },
    { subCode: 'JA', name: 'Jalapa' },
    { subCode: 'JU', name: 'Jutiapa' },
    { subCode: 'PE', name: 'Pet\u00e9n' },
    { subCode: 'PR', name: 'El Progreso' },
    { subCode: 'QC', name: 'Quich\u00e9' },
    { subCode: 'QZ', name: 'Quetzaltenango' },
    { subCode: 'RE', name: 'Retalhuleu' },
    { subCode: 'SA', name: 'Sacatep\u00e9quez' },
    { subCode: 'SM', name: 'San Marcos' },
    { subCode: 'SO', name: 'Solol\u00e1' },
    { subCode: 'SR', name: 'Santa Rosa' },
    { subCode: 'SU', name: 'Suchitep\u00e9quez' },
    { subCode: 'TO', name: 'Totonicap\u00e1n' },
    { subCode: 'ZA', name: 'Zacapa' },
  ],
  GG: [{ subCode: 'GG', name: 'Guernsey' }],
  GW: [
    { subCode: 'BA', name: 'Bafat\u00e1' },
    { subCode: 'BL', name: 'Bolama' },
    { subCode: 'BM', name: 'Biombo' },
    { subCode: 'BS', name: 'Bissau' },
    { subCode: 'CA', name: 'Cacheu' },
    { subCode: 'GA', name: 'Gab\u00fa' },
    { subCode: 'L', name: 'Leste' },
    { subCode: 'N', name: 'Norte' },
    { subCode: 'OI', name: 'Oio' },
    { subCode: 'QU', name: 'Quinara' },
    { subCode: 'S', name: 'Sul' },
    { subCode: 'TO', name: 'Tombali' },
  ],
  GN: [
    { subCode: 'B', name: 'Bok\u00e9' },
    { subCode: 'BE', name: 'Beyla' },
    { subCode: 'BF', name: 'Boffa' },
    { subCode: 'BK', name: 'Bok\u00e9' },
    { subCode: 'C', name: 'Conakry' },
    { subCode: 'CO', name: 'Coyah' },
    { subCode: 'DB', name: 'Dabola' },
    { subCode: 'DI', name: 'Dinguiraye' },
    { subCode: 'D', name: 'Kindia' },
    { subCode: 'DL', name: 'Dalaba' },
    { subCode: 'DU', name: 'Dubr\u00e9ka' },
    { subCode: 'FA', name: 'Faranah' },
    { subCode: 'F', name: 'Faranah' },
    { subCode: 'FO', name: 'For\u00e9cariah' },
    { subCode: 'FR', name: 'Fria' },
    { subCode: 'GA', name: 'Gaoual' },
    { subCode: 'GU', name: 'Gu\u00e9k\u00e9dou' },
    { subCode: 'KA', name: 'Kankan' },
    { subCode: 'KB', name: 'Koubia' },
    { subCode: 'KD', name: 'Kindia' },
    { subCode: 'KE', name: 'K\u00e9rouan\u00e9' },
    { subCode: 'K', name: 'Kankan' },
    { subCode: 'KN', name: 'Koundara' },
    { subCode: 'KO', name: 'Kouroussa' },
    { subCode: 'KS', name: 'Kissidougou' },
    { subCode: 'LA', name: 'Lab\u00e9' },
    { subCode: 'LE', name: 'L\u00e9louma' },
    { subCode: 'L', name: 'Lab\u00e9' },
    { subCode: 'LO', name: 'Lola' },
    { subCode: 'MC', name: 'Macenta' },
    { subCode: 'MD', name: 'Mandiana' },
    { subCode: 'ML', name: 'Mali' },
    { subCode: 'M', name: 'Mamou' },
    { subCode: 'MM', name: 'Mamou' },
    { subCode: 'N', name: 'Nz\u00e9r\u00e9kor\u00e9' },
    { subCode: 'NZ', name: 'Nz\u00e9r\u00e9kor\u00e9' },
    { subCode: 'PI', name: 'Pita' },
    { subCode: 'SI', name: 'Siguiri' },
    { subCode: 'TE', name: 'T\u00e9lim\u00e9l\u00e9' },
    { subCode: 'TO', name: 'Tougu\u00e9' },
    { subCode: 'YO', name: 'Yomou' },
  ],
  GY: [
    { subCode: 'BA', name: 'Barima-Waini' },
    { subCode: 'CU', name: 'Cuyuni-Mazaruni' },
    { subCode: 'DE', name: 'Demerara-Mahaica' },
    { subCode: 'EB', name: 'East Berbice-Corentyne' },
    { subCode: 'ES', name: 'Essequibo Islands-West Demerara' },
    { subCode: 'MA', name: 'Mahaica-Berbice' },
    { subCode: 'PM', name: 'Pomeroon-Supenaam' },
    { subCode: 'PT', name: 'Potaro-Siparuni' },
    { subCode: 'UD', name: 'Upper Demerara-Berbice' },
    { subCode: 'UT', name: 'Upper Takutu-Upper Essequibo' },
  ],
  HT: [
    { subCode: 'AR', name: 'Artibonite' },
    { subCode: 'CE', name: 'Centre' },
    { subCode: 'GA', name: 'Grandans' },
    { subCode: 'ND', name: 'N\u00f2' },
    { subCode: 'NE', name: 'N\u00f2d\u00e8s' },
    { subCode: 'NI', name: 'Nip' },
    { subCode: 'NO', name: 'N\u00f2dw\u00e8s' },
    { subCode: 'OU', name: 'Lw\u00e8s' },
    { subCode: 'SD', name: 'Sid' },
    { subCode: 'SE', name: 'Sid\u00e8s' },
  ],
  HM: [{ subCode: 'HM', name: 'Heard & McDonald Islands' }],
  HN: [
    { subCode: 'AT', name: 'Atl\u00e1ntida' },
    { subCode: 'CH', name: 'Choluteca' },
    { subCode: 'CL', name: 'Col\u00f3n' },
    { subCode: 'CM', name: 'Comayagua' },
    { subCode: 'CP', name: 'Cop\u00e1n' },
    { subCode: 'CR', name: 'Cort\u00e9s' },
    { subCode: 'EP', name: 'El Para\u00edso' },
    { subCode: 'FM', name: 'Francisco Moraz\u00e1n' },
    { subCode: 'GD', name: 'Gracias a Dios' },
    { subCode: 'IB', name: 'Islas de la Bah\u00eda' },
    { subCode: 'IN', name: 'Intibuc\u00e1' },
    { subCode: 'LE', name: 'Lempira' },
    { subCode: 'LP', name: 'La Paz' },
    { subCode: 'OC', name: 'Ocotepeque' },
    { subCode: 'OL', name: 'Olancho' },
    { subCode: 'SB', name: 'Santa B\u00e1rbara' },
    { subCode: 'VA', name: 'Valle' },
    { subCode: 'YO', name: 'Yoro' },
  ],
  HK: [{ subCode: 'HK', name: 'Hong Kong' }],
  HU: [
    { subCode: 'BA', name: 'Baranya' },
    { subCode: 'BC', name: 'B\u00e9k\u00e9scsaba' },
    { subCode: 'BE', name: 'B\u00e9k\u00e9s' },
    { subCode: 'BK', name: 'B\u00e1cs-Kiskun' },
    { subCode: 'BU', name: 'Budapest' },
    { subCode: 'BZ', name: 'Borsod-Aba\u00faj-Zempl\u00e9n' },
    { subCode: 'CS', name: 'Csongr\u00e1d' },
    { subCode: 'DE', name: 'Debrecen' },
    { subCode: 'DU', name: 'Duna\u00fajv\u00e1ros' },
    { subCode: 'EG', name: 'Eger' },
    { subCode: 'ER', name: '\u00c9rd' },
    { subCode: 'FE', name: 'Fej\u00e9r' },
    { subCode: 'GS', name: 'Gy\u0151r-Moson-Sopron' },
    { subCode: 'GY', name: 'Gy\u0151r' },
    { subCode: 'HB', name: 'Hajd\u00fa-Bihar' },
    { subCode: 'HE', name: 'Heves' },
    { subCode: 'HV', name: 'H\u00f3dmez\u0151v\u00e1s\u00e1rhely' },
    { subCode: 'JN', name: 'J\u00e1sz-Nagykun-Szolnok' },
    { subCode: 'KE', name: 'Kom\u00e1rom-Esztergom' },
    { subCode: 'KM', name: 'Kecskem\u00e9t' },
    { subCode: 'KV', name: 'Kaposv\u00e1r' },
    { subCode: 'MI', name: 'Miskolc' },
    { subCode: 'NK', name: 'Nagykanizsa' },
    { subCode: 'NO', name: 'N\u00f3gr\u00e1d' },
    { subCode: 'NY', name: 'Ny\u00edregyh\u00e1za' },
    { subCode: 'PE', name: 'Pest' },
    { subCode: 'PS', name: 'P\u00e9cs' },
    { subCode: 'SD', name: 'Szeged' },
    { subCode: 'SF', name: 'Sz\u00e9kesfeh\u00e9rv\u00e1r' },
    { subCode: 'SH', name: 'Szombathely' },
    { subCode: 'SK', name: 'Szolnok' },
    { subCode: 'SN', name: 'Sopron' },
    { subCode: 'SO', name: 'Somogy' },
    { subCode: 'SS', name: 'Szeksz\u00e1rd' },
    { subCode: 'ST', name: 'Salg\u00f3tarj\u00e1n' },
    { subCode: 'SZ', name: 'Szabolcs-Szatm\u00e1r-Bereg' },
    { subCode: 'TB', name: 'Tatab\u00e1nya' },
    { subCode: 'TO', name: 'Tolna' },
    { subCode: 'VA', name: 'Vas' },
    { subCode: 'VE', name: 'Veszpr\u00e9m' },
    { subCode: 'VM', name: 'Veszpr\u00e9m' },
    { subCode: 'ZA', name: 'Zala' },
    { subCode: 'ZE', name: 'Zalaegerszeg' },
  ],
  IS: [
    { subCode: '0', name: 'Reykjav\u00edk' },
    { subCode: '1', name: 'H\u00f6fu\u00f0borgarsv\u00e6\u00f0i utan Reykjav\u00edkur' },
    { subCode: '2', name: 'Su\u00f0urnes' },
    { subCode: '3', name: 'Vesturland' },
    { subCode: '4', name: 'Vestfir\u00f0ir' },
    { subCode: '5', name: 'Nor\u00f0urland vestra' },
    { subCode: '6', name: 'Nor\u00f0urland eystra' },
    { subCode: '7', name: 'Austurland' },
    { subCode: '8', name: 'Su\u00f0urland' },
  ],
  IN: [
    { subCode: 'AN', name: 'Andaman and Nicobar Islands' },
    { subCode: 'AP', name: 'Andhra Pradesh' },
    { subCode: 'AR', name: 'Arunachal Pradesh' },
    { subCode: 'AS', name: 'Assam' },
    { subCode: 'BR', name: 'Bihar' },
    { subCode: 'CH', name: 'Chandigarh' },
    { subCode: 'CT', name: 'Chhattisgarh' },
    { subCode: 'DD', name: 'Daman and Diu' },
    { subCode: 'DL', name: 'Delhi' },
    { subCode: 'DN', name: 'Dadra and Nagar Haveli' },
    { subCode: 'GA', name: 'Goa' },
    { subCode: 'GJ', name: 'Gujarat' },
    { subCode: 'HP', name: 'Himachal Pradesh' },
    { subCode: 'HR', name: 'Haryana' },
    { subCode: 'JH', name: 'Jharkhand' },
    { subCode: 'JK', name: 'Jammu and Kashmir' },
    { subCode: 'KA', name: 'Karnataka' },
    { subCode: 'KL', name: 'Kerala' },
    { subCode: 'LD', name: 'Lakshadweep' },
    { subCode: 'MH', name: 'Maharashtra' },
    { subCode: 'ML', name: 'Meghalaya' },
    { subCode: 'MN', name: 'Manipur' },
    { subCode: 'MP', name: 'Madhya Pradesh' },
    { subCode: 'MZ', name: 'Mizoram' },
    { subCode: 'NL', name: 'Nagaland' },
    { subCode: 'OR', name: 'Odisha' },
    { subCode: 'PB', name: 'Punjab' },
    { subCode: 'PY', name: 'Puducherry' },
    { subCode: 'RJ', name: 'Rajasthan' },
    { subCode: 'SK', name: 'Sikkim' },
    { subCode: 'TG', name: 'Telangana' },
    { subCode: 'TN', name: 'Tamil Nadu' },
    { subCode: 'TR', name: 'Tripura' },
    { subCode: 'UP', name: 'Uttar Pradesh' },
    { subCode: 'UT', name: 'Uttarakhand' },
    { subCode: 'WB', name: 'West Bengal' },
  ],
  ID: [
    { subCode: 'AC', name: 'Aceh' },
    { subCode: 'BA', name: 'Bali' },
    { subCode: 'BB', name: 'Bangka Belitung' },
    { subCode: 'BE', name: 'Bengkulu' },
    { subCode: 'BT', name: 'Banten' },
    { subCode: 'GO', name: 'Gorontalo' },
    { subCode: 'JA', name: 'Jambi' },
    { subCode: 'JB', name: 'Jawa Barat' },
    { subCode: 'JI', name: 'Jawa Timur' },
    { subCode: 'JK', name: 'Jakarta Raya' },
    { subCode: 'JT', name: 'Jawa Tengah' },
    { subCode: 'JW', name: 'Jawa' },
    { subCode: 'KA', name: 'Kalimantan' },
    { subCode: 'KB', name: 'Kalimantan Barat' },
    { subCode: 'KI', name: 'Kalimantan Timur' },
    { subCode: 'KR', name: 'Kepulauan Riau' },
    { subCode: 'KS', name: 'Kalimantan Selatan' },
    { subCode: 'KT', name: 'Kalimantan Tengah' },
    { subCode: 'KU', name: 'Kalimantan Utara' },
    { subCode: 'LA', name: 'Lampung' },
    { subCode: 'MA', name: 'Maluku' },
    { subCode: 'ML', name: 'Maluku' },
    { subCode: 'MU', name: 'Maluku Utara' },
    { subCode: 'NB', name: 'Nusa Tenggara Barat' },
    { subCode: 'NT', name: 'Nusa Tenggara Timur' },
    { subCode: 'NU', name: 'Nusa Tenggara' },
    { subCode: 'PA', name: 'Papua' },
    { subCode: 'PB', name: 'Papua Barat' },
    { subCode: 'PP', name: 'Papua' },
    { subCode: 'RI', name: 'Riau' },
    { subCode: 'SA', name: 'Sulawesi Utara' },
    { subCode: 'SB', name: 'Sumatera Barat' },
    { subCode: 'SG', name: 'Sulawesi Tenggara' },
    { subCode: 'SL', name: 'Sulawesi' },
    { subCode: 'SM', name: 'Sumatera' },
    { subCode: 'SN', name: 'Sulawesi Selatan' },
    { subCode: 'SR', name: 'Sulawesi Barat' },
    { subCode: 'SS', name: 'Sumatera Selatan' },
    { subCode: 'ST', name: 'Sulawesi Tengah' },
    { subCode: 'SU', name: 'Sumatera Utara' },
    { subCode: 'YO', name: 'Yogyakarta' },
  ],
  IQ: [
    { subCode: 'AN', name: 'Al Anb\u0101r' },
    { subCode: 'AR', name: 'Arb\u012bl' },
    { subCode: 'BA', name: 'Al Ba\u015frah' },
    { subCode: 'BB', name: 'B\u0101bil' },
    { subCode: 'BG', name: 'Baghd\u0101d' },
    { subCode: 'DA', name: 'Dah\u016bk' },
    { subCode: 'DI', name: 'Diy\u0101l\u00e1' },
    { subCode: 'DQ', name: 'Dh\u012b Q\u0101r' },
    { subCode: 'KA', name: "Karbal\u0101'" },
    { subCode: 'KI', name: 'Kirk\u016bk' },
    { subCode: 'MA', name: 'Mays\u0101n' },
    { subCode: 'MU', name: 'Al Muthann\u00e1' },
    { subCode: 'NA', name: 'An Najaf' },
    { subCode: 'NI', name: 'N\u012bnaw\u00e1' },
    { subCode: 'QA', name: 'Al Q\u0101dis\u012byah' },
    { subCode: 'SD', name: '\u015eal\u0101\u1e29 ad D\u012bn' },
    { subCode: 'SU', name: 'As Sulaym\u0101n\u012byah' },
    { subCode: 'WA', name: 'W\u0101si\u0163' },
  ],
  IE: [
    { subCode: 'C', name: 'Connacht' },
    { subCode: 'CE', name: 'An Cl\u00e1r' },
    { subCode: 'CN', name: 'An Cabh\u00e1n' },
    { subCode: 'CO', name: 'Corcaigh' },
    { subCode: 'CW', name: 'Carlow' },
    { subCode: 'D', name: 'Baile \u00c1tha Cliath' },
    { subCode: 'DL', name: 'Donegal' },
    { subCode: 'G', name: 'Gaillimh' },
    { subCode: 'KE', name: 'Cill Dara' },
    { subCode: 'KK', name: 'Cill Chainnigh' },
    { subCode: 'KY', name: 'Ciarra\u00ed' },
    { subCode: 'LD', name: 'An Longfort' },
    { subCode: 'LH', name: 'Louth' },
    { subCode: 'LK', name: 'Limerick' },
    { subCode: 'L', name: 'Laighin' },
    { subCode: 'LM', name: 'Leitrim' },
    { subCode: 'LS', name: 'Laois' },
    { subCode: 'M', name: 'An Mhumhain' },
    { subCode: 'MH', name: 'An Mh\u00ed' },
    { subCode: 'MN', name: 'Monaghan' },
    { subCode: 'MO', name: 'Maigh Eo' },
    { subCode: 'OY', name: 'Offaly' },
    { subCode: 'RN', name: 'Ros Com\u00e1in' },
    { subCode: 'SO', name: 'Sligeach' },
    { subCode: 'TA', name: 'Tiobraid \u00c1rann' },
    { subCode: 'U', name: 'Ulaidh' },
    { subCode: 'WD', name: 'Port L\u00e1irge' },
    { subCode: 'WH', name: 'An Iarmh\u00ed' },
    { subCode: 'WW', name: 'Cill Mhant\u00e1in' },
    { subCode: 'WX', name: 'Loch Garman' },
  ],
  IM: [{ subCode: 'IM', name: 'Isle of Main' }],
  IL: [
    { subCode: 'D', name: 'Southern District' },
    { subCode: 'HA', name: 'Haifa' },
    { subCode: 'JM', name: 'Jerusalem' },
    { subCode: 'M', name: 'Central District' },
    { subCode: 'TA', name: 'Tel Aviv' },
    { subCode: 'Z', name: 'Northern District' },
  ],
  IT: [
    { subCode: '21', name: 'Piemonte' },
    { subCode: '23', name: "Val d'Aoste" },
    { subCode: '25', name: 'Lombardia' },
    { subCode: '32', name: 'Trentino-Alto Adige' },
    { subCode: '34', name: 'Veneto' },
    { subCode: '36', name: 'Friuli-Venezia Giulia' },
    { subCode: '42', name: 'Liguria' },
    { subCode: '45', name: 'Emilia-Romagna' },
    { subCode: '52', name: 'Toscana' },
    { subCode: '55', name: 'Umbria' },
    { subCode: '57', name: 'Marche' },
    { subCode: '62', name: 'Lazio' },
    { subCode: '65', name: 'Abruzzo' },
    { subCode: '67', name: 'Molise' },
    { subCode: '72', name: 'Campania' },
    { subCode: '75', name: 'Puglia' },
    { subCode: '77', name: 'Basilicata' },
    { subCode: '78', name: 'Calabria' },
    { subCode: '82', name: 'Sicilia' },
    { subCode: '88', name: 'Sardegna' },
    { subCode: 'AG', name: 'Agrigento' },
    { subCode: 'AL', name: 'Alessandria' },
    { subCode: 'AN', name: 'Ancona' },
    { subCode: 'AO', name: 'Aosta' },
    { subCode: 'AP', name: 'Ascoli Piceno' },
    { subCode: 'AQ', name: "L'Aquila" },
    { subCode: 'AR', name: 'Arezzo' },
    { subCode: 'AT', name: 'Asti' },
    { subCode: 'AV', name: 'Avellino' },
    { subCode: 'BA', name: 'Bari' },
    { subCode: 'BG', name: 'Bergamo' },
    { subCode: 'BI', name: 'Biella' },
    { subCode: 'BL', name: 'Belluno' },
    { subCode: 'BN', name: 'Benevento' },
    { subCode: 'BO', name: 'Bologna' },
    { subCode: 'BR', name: 'Brindisi' },
    { subCode: 'BS', name: 'Brescia' },
    { subCode: 'BT', name: 'Barletta-Andria-Trani' },
    { subCode: 'BZ', name: 'Bolzano' },
    { subCode: 'CA', name: 'Cagliari' },
    { subCode: 'CB', name: 'Campobasso' },
    { subCode: 'CE', name: 'Caserta' },
    { subCode: 'CH', name: 'Chieti' },
    { subCode: 'CI', name: 'Carbonia-Iglesias' },
    { subCode: 'CL', name: 'Caltanissetta' },
    { subCode: 'CN', name: 'Cuneo' },
    { subCode: 'CO', name: 'Como' },
    { subCode: 'CR', name: 'Cremona' },
    { subCode: 'CS', name: 'Cosenza' },
    { subCode: 'CT', name: 'Catania' },
    { subCode: 'CZ', name: 'Catanzaro' },
    { subCode: 'EN', name: 'Enna' },
    { subCode: 'FC', name: 'Forl\u00ec-Cesena' },
    { subCode: 'FE', name: 'Ferrara' },
    { subCode: 'FG', name: 'Foggia' },
    { subCode: 'FI', name: 'Firenze' },
    { subCode: 'FM', name: 'Fermo' },
    { subCode: 'FR', name: 'Frosinone' },
    { subCode: 'GE', name: 'Genova' },
    { subCode: 'GO', name: 'Gorizia' },
    { subCode: 'GR', name: 'Grosseto' },
    { subCode: 'IM', name: 'Imperia' },
    { subCode: 'IS', name: 'Isernia' },
    { subCode: 'KR', name: 'Crotone' },
    { subCode: 'LC', name: 'Lecco' },
    { subCode: 'LE', name: 'Lecce' },
    { subCode: 'LI', name: 'Livorno' },
    { subCode: 'LO', name: 'Lodi' },
    { subCode: 'LT', name: 'Latina' },
    { subCode: 'LU', name: 'Lucca' },
    { subCode: 'MB', name: 'Monza e Brianza' },
    { subCode: 'MC', name: 'Macerata' },
    { subCode: 'ME', name: 'Messina' },
    { subCode: 'MI', name: 'Milano' },
    { subCode: 'MN', name: 'Mantova' },
    { subCode: 'MO', name: 'Modena' },
    { subCode: 'MS', name: 'Massa-Carrara' },
    { subCode: 'MT', name: 'Matera' },
    { subCode: 'NA', name: 'Napoli' },
    { subCode: 'NO', name: 'Novara' },
    { subCode: 'NU', name: 'Nuoro' },
    { subCode: 'OG', name: 'Ogliastra' },
    { subCode: 'OR', name: 'Oristano' },
    { subCode: 'OT', name: 'Olbia-Tempio' },
    { subCode: 'PA', name: 'Palermo' },
    { subCode: 'PC', name: 'Piacenza' },
    { subCode: 'PD', name: 'Padova' },
    { subCode: 'PE', name: 'Pescara' },
    { subCode: 'PG', name: 'Perugia' },
    { subCode: 'PI', name: 'Pisa' },
    { subCode: 'PN', name: 'Pordenone' },
    { subCode: 'PO', name: 'Prato' },
    { subCode: 'PR', name: 'Parma' },
    { subCode: 'PT', name: 'Pistoia' },
    { subCode: 'PU', name: 'Pesaro e Urbino' },
    { subCode: 'PV', name: 'Pavia' },
    { subCode: 'PZ', name: 'Potenza' },
    { subCode: 'RA', name: 'Ravenna' },
    { subCode: 'RC', name: 'Reggio Calabria' },
    { subCode: 'RE', name: 'Reggio Emilia' },
    { subCode: 'RG', name: 'Ragusa' },
    { subCode: 'RI', name: 'Rieti' },
    { subCode: 'RM', name: 'Roma' },
    { subCode: 'RN', name: 'Rimini' },
    { subCode: 'RO', name: 'Rovigo' },
    { subCode: 'SA', name: 'Salerno' },
    { subCode: 'SI', name: 'Siena' },
    { subCode: 'SO', name: 'Sondrio' },
    { subCode: 'SP', name: 'La Spezia' },
    { subCode: 'SR', name: 'Siracusa' },
    { subCode: 'SS', name: 'Sassari' },
    { subCode: 'SV', name: 'Savona' },
    { subCode: 'TA', name: 'Taranto' },
    { subCode: 'TE', name: 'Teramo' },
    { subCode: 'TN', name: 'Trento' },
    { subCode: 'TO', name: 'Torino' },
    { subCode: 'TP', name: 'Trapani' },
    { subCode: 'TR', name: 'Terni' },
    { subCode: 'TS', name: 'Trieste' },
    { subCode: 'TV', name: 'Treviso' },
    { subCode: 'UD', name: 'Udine' },
    { subCode: 'VA', name: 'Varese' },
    { subCode: 'VB', name: 'Verbano-Cusio-Ossola' },
    { subCode: 'VC', name: 'Vercelli' },
    { subCode: 'VE', name: 'Venezia' },
    { subCode: 'VI', name: 'Vicenza' },
    { subCode: 'VR', name: 'Verona' },
    { subCode: 'VS', name: 'Medio Campidano' },
    { subCode: 'VT', name: 'Viterbo' },
    { subCode: 'VV', name: 'Vibo Valentia' },
  ],
  JM: [
    { subCode: '01', name: 'Kingston' },
    { subCode: '02', name: 'Saint Andrew' },
    { subCode: '03', name: 'Saint Thomas' },
    { subCode: '04', name: 'Portland' },
    { subCode: '05', name: 'Saint Mary' },
    { subCode: '06', name: 'Saint Ann' },
    { subCode: '07', name: 'Trelawny' },
    { subCode: '08', name: 'Saint James' },
    { subCode: '09', name: 'Hanover' },
    { subCode: '10', name: 'Westmoreland' },
    { subCode: '11', name: 'Saint Elizabeth' },
    { subCode: '12', name: 'Manchester' },
    { subCode: '13', name: 'Clarendon' },
    { subCode: '14', name: 'Saint Catherine' },
  ],
  JP: [
    { subCode: '01', name: 'Hokkaido' },
    { subCode: '02', name: 'Aomori' },
    { subCode: '03', name: 'Iwate' },
    { subCode: '04', name: 'Miyagi' },
    { subCode: '05', name: 'Akita' },
    { subCode: '06', name: 'Yamagata' },
    { subCode: '07', name: 'Fukushima' },
    { subCode: '08', name: 'Ibaraki' },
    { subCode: '09', name: 'Tochigi' },
    { subCode: '10', name: 'Gunma' },
    { subCode: '11', name: 'Saitama' },
    { subCode: '12', name: 'Chiba' },
    { subCode: '13', name: 'T\u00f4ky\u00f4' },
    { subCode: '14', name: 'Kanagawa' },
    { subCode: '15', name: 'Niigata' },
    { subCode: '16', name: 'Toyama' },
    { subCode: '17', name: 'Ishikawa' },
    { subCode: '18', name: 'Fukui' },
    { subCode: '19', name: 'Yamanashi' },
    { subCode: '20', name: 'Nagano' },
    { subCode: '21', name: 'Gifu' },
    { subCode: '22', name: 'Shizuoka' },
    { subCode: '23', name: 'Aichi' },
    { subCode: '24', name: 'Mie' },
    { subCode: '25', name: 'Shiga' },
    { subCode: '26', name: 'Kyoto' },
    { subCode: '27', name: 'Osaka' },
    { subCode: '28', name: 'Hyogo' },
    { subCode: '29', name: 'Nara' },
    { subCode: '30', name: 'Wakayama' },
    { subCode: '31', name: 'Tottori' },
    { subCode: '32', name: 'Shimane' },
    { subCode: '33', name: 'Okayama' },
    { subCode: '34', name: 'Hiroshima' },
    { subCode: '35', name: 'Yamaguchi' },
    { subCode: '36', name: 'Tokushima' },
    { subCode: '37', name: 'Kagawa' },
    { subCode: '38', name: 'Ehime' },
    { subCode: '39', name: 'Kochi' },
    { subCode: '40', name: 'Fukuoka' },
    { subCode: '41', name: 'Saga' },
    { subCode: '42', name: 'Nagasaki' },
    { subCode: '43', name: 'Kumamoto' },
    { subCode: '44', name: 'Oita' },
    { subCode: '45', name: 'Miyazaki' },
    { subCode: '46', name: 'Kagoshima' },
    { subCode: '47', name: 'Okinawa' },
  ],
  JE: [{ subCode: 'JE', name: 'Jersey' }],
  JO: [
    { subCode: 'AJ', name: '\u02bdAjl\u016bn' },
    { subCode: 'AM', name: '\u2018Amm\u0101n' },
    { subCode: 'AQ', name: 'Al \u02bdAqabah' },
    { subCode: 'AT', name: 'A\u0163 \u0162af\u012blah' },
    { subCode: 'AZ', name: "Az Zarq\u0101'" },
    { subCode: 'BA', name: "Al Balq\u0101'" },
    { subCode: 'IR', name: 'Irbid' },
    { subCode: 'JA', name: 'Jarash' },
    { subCode: 'KA', name: 'Al Karak' },
    { subCode: 'MA', name: 'Al Mafraq' },
    { subCode: 'MD', name: 'M\u0101dab\u0101' },
    { subCode: 'MN', name: "Ma'\u0101n" },
  ],
  KZ: [
    { subCode: 'AKM', name: "Akmolinskaja oblast'" },
    { subCode: 'AKT', name: "Aktjubinskaja oblast'" },
    { subCode: 'ALA', name: 'Almaty' },
    { subCode: 'ALM', name: "Almatinskaja oblast'" },
    { subCode: 'AST', name: 'Astana' },
    { subCode: 'ATY', name: 'Atyra\u016b oblysy' },
    { subCode: 'KAR', name: "Karagandinskaja oblast'" },
    { subCode: 'KUS', name: "Kostanajskaja oblast'" },
    { subCode: 'KZY', name: "Kyzylordinskaja oblast'" },
    { subCode: 'MAN', name: 'Mangghysta\u016b oblysy' },
    { subCode: 'PAV', name: 'Pavlodar oblysy' },
    { subCode: 'SEV', name: "Severo-Kazahstanskaja oblast'" },
    { subCode: 'VOS', name: 'Shyghys Qazaqstan oblysy' },
    { subCode: 'YUZ', name: "Ju\u017eno-Kazahstanskaja oblast'" },
    { subCode: 'ZAP', name: 'Batys Qazaqstan oblysy' },
    { subCode: 'ZHA', name: "\u017dambylskaja oblast'" },
  ],
  KE: [
    { subCode: '01', name: 'Baringo' },
    { subCode: '02', name: 'Bomet' },
    { subCode: '03', name: 'Bungoma' },
    { subCode: '04', name: 'Busia' },
    { subCode: '05', name: 'Elgeyo/Marakwet' },
    { subCode: '06', name: 'Embu' },
    { subCode: '07', name: 'Garissa' },
    { subCode: '08', name: 'Homa Bay' },
    { subCode: '09', name: 'Isiolo' },
    { subCode: '10', name: 'Kajiado' },
    { subCode: '11', name: 'Kakamega' },
    { subCode: '12', name: 'Kericho' },
    { subCode: '13', name: 'Kiambu' },
    { subCode: '14', name: 'Kilifi' },
    { subCode: '15', name: 'Kirinyaga' },
    { subCode: '16', name: 'Kisii' },
    { subCode: '17', name: 'Kisumu' },
    { subCode: '18', name: 'Kitui' },
    { subCode: '19', name: 'Kwale' },
    { subCode: '20', name: 'Laikipia' },
    { subCode: '21', name: 'Lamu' },
    { subCode: '22', name: 'Machakos' },
    { subCode: '23', name: 'Makueni' },
    { subCode: '24', name: 'Mandera' },
    { subCode: '25', name: 'Marsabit' },
    { subCode: '26', name: 'Meru' },
    { subCode: '27', name: 'Migori' },
    { subCode: '28', name: 'Mombasa' },
    { subCode: '29', name: "Murang'a" },
    { subCode: '30', name: 'Nairobi City' },
    { subCode: '31', name: 'Nakuru' },
    { subCode: '32', name: 'Nandi' },
    { subCode: '33', name: 'Narok' },
    { subCode: '34', name: 'Nyamira' },
    { subCode: '35', name: 'Nyandarua' },
    { subCode: '36', name: 'Nyeri' },
    { subCode: '37', name: 'Samburu' },
    { subCode: '38', name: 'Siaya' },
    { subCode: '39', name: 'Taita/Taveta' },
    { subCode: '40', name: 'Tana River' },
    { subCode: '41', name: 'Tharaka-Nithi' },
    { subCode: '42', name: 'Trans Nzoia' },
    { subCode: '43', name: 'Turkana' },
    { subCode: '44', name: 'Uasin Gishu' },
    { subCode: '45', name: 'Vihiga' },
    { subCode: '46', name: 'Wajir' },
    { subCode: '47', name: 'West Pokot' },
  ],
  KI: [
    { subCode: 'G', name: 'Gilbert Islands' },
    { subCode: 'L', name: 'Line Islands' },
    { subCode: 'P', name: 'Phoenix Islands' },
  ],
  KR: [
    { subCode: '11', name: "Seoul-T'\u01d4kpy\u01d2lshi" },
    { subCode: '26', name: "Busan Gwang'yeogsi" },
    { subCode: '27', name: "Daegu Gwang'yeogsi" },
    { subCode: '28', name: "Inch'\u01d2n-Kwangy\u01d2kshi" },
    { subCode: '29', name: "Gwangju Gwang'yeogsi" },
    { subCode: '30', name: "Daejeon Gwang'yeogsi" },
    { subCode: '31', name: "Ulsan Gwang'yeogsi" },
    { subCode: '41', name: 'Gyeonggido' },
    { subCode: '42', name: "Gang'weondo" },
    { subCode: '43', name: "Ch'ungch'\u01d2ngbuk-do" },
    { subCode: '44', name: "Ch'ungch'\u01d2ngnam-do" },
    { subCode: '45', name: 'Ch\u01d2llabuk-do' },
    { subCode: '46', name: 'Ch\u01d2llanam-do' },
    { subCode: '47', name: 'Gyeongsangbugdo' },
    { subCode: '48', name: 'Gyeongsangnamdo' },
    { subCode: '49', name: 'Cheju-do' },
    { subCode: '50', name: 'Sejong' },
  ],
  KW: [
    { subCode: 'AH', name: 'Al A\u1e29mad\u012b' },
    { subCode: 'FA', name: 'Al Farw\u0101n\u012byah' },
    { subCode: 'HA', name: '\u1e28awall\u012b' },
    { subCode: 'JA', name: 'Al Jahr\u0101\u2019' },
    { subCode: 'KU', name: 'Al Kuwayt' },
    { subCode: 'MU', name: 'Mub\u0101rak al Kab\u012br' },
  ],
  KG: [
    { subCode: 'B', name: 'Batken' },
    { subCode: 'C', name: 'Ch\u00fc' },
    { subCode: 'GB', name: 'Bishkek' },
    { subCode: 'GO', name: 'Gorod O\u0161' },
    { subCode: 'J', name: "D\u017ealal-Abadskaja oblast'" },
    { subCode: 'N', name: 'Naryn' },
    { subCode: 'O', name: 'Osh' },
    { subCode: 'T', name: 'Talas' },
    { subCode: 'Y', name: "Issyk-Kul'skaja oblast'" },
  ],
  LA: [
    { subCode: 'AT', name: 'Attapu' },
    { subCode: 'BK', name: 'Bok\u00e8o' },
    { subCode: 'BL', name: 'Bolikhamxai' },
    { subCode: 'CH', name: 'Champasak' },
    { subCode: 'HO', name: 'Houaphan' },
    { subCode: 'KH', name: 'Khammouan' },
    { subCode: 'LM', name: 'Louang Namtha' },
    { subCode: 'LP', name: 'Louangphabang' },
    { subCode: 'OU', name: 'Oudomsai' },
    { subCode: 'PH', name: 'Ph\u00f4ngsali' },
    { subCode: 'SL', name: 'Salavan' },
    { subCode: 'SV', name: 'Savannakh\u00e9t' },
    { subCode: 'VI', name: 'Vientiane' },
    { subCode: 'VT', name: 'Vientiane' },
    { subCode: 'XA', name: 'Sayaboury' },
    { subCode: 'XE', name: 'S\u00e9kong' },
    { subCode: 'XI', name: 'Xiangkhouang' },
    { subCode: 'XN', name: 'Xais\u00f4mboun' },
  ],
  LV: [
    { subCode: '001', name: 'Aglonas novads' },
    { subCode: '002', name: 'Aizkraukles novads' },
    { subCode: '003', name: 'Aizputes novads' },
    { subCode: '004', name: 'Akn\u012bstes novads' },
    { subCode: '005', name: 'Alojas novads' },
    { subCode: '006', name: 'Alsungas novads' },
    { subCode: '007', name: 'Al\u016bksnes novads' },
    { subCode: '008', name: 'Amatas novads' },
    { subCode: '009', name: 'Apes novads' },
    { subCode: '010', name: 'Auces novads' },
    { subCode: '011', name: '\u0100da\u017eu novads' },
    { subCode: '012', name: 'Bab\u012btes novads' },
    { subCode: '013', name: 'Baldones novads' },
    { subCode: '014', name: 'Baltinavas novads' },
    { subCode: '015', name: 'Balvu novads' },
    { subCode: '016', name: 'Bauskas novads' },
    { subCode: '017', name: 'Bever\u012bnas novads' },
    { subCode: '018', name: 'Broc\u0113nu novads' },
    { subCode: '019', name: 'Burtnieku novads' },
    { subCode: '020', name: 'Carnikavas novads' },
    { subCode: '021', name: 'Cesvaines novads' },
    { subCode: '022', name: 'C\u0113su novads' },
    { subCode: '023', name: 'Ciblas novads' },
    { subCode: '024', name: 'Dagdas novads' },
    { subCode: '025', name: 'Daugavpils novads' },
    { subCode: '026', name: 'Dobeles novads' },
    { subCode: '027', name: 'Dundagas novads' },
    { subCode: '028', name: 'Durbes novads' },
    { subCode: '029', name: 'Engures novads' },
    { subCode: '030', name: '\u0112rg\u013cu novads' },
    { subCode: '031', name: 'Garkalnes novads' },
    { subCode: '032', name: 'Grobi\u0146as novads' },
    { subCode: '033', name: 'Gulbenes novads' },
    { subCode: '034', name: 'Iecavas novads' },
    { subCode: '035', name: 'Ik\u0161\u0137iles novads' },
    { subCode: '036', name: 'Il\u016bkstes novads' },
    { subCode: '037', name: 'In\u010dukalna novads' },
    { subCode: '038', name: 'Jaunjelgavas novads' },
    { subCode: '039', name: 'Jaunpiebalgas novads' },
    { subCode: '040', name: 'Jaunpils novads' },
    { subCode: '041', name: 'Jelgavas novads' },
    { subCode: '042', name: 'J\u0113kabpils novads' },
    { subCode: '043', name: 'Kandavas novads' },
    { subCode: '044', name: 'K\u0101rsavas novads' },
    { subCode: '045', name: 'Koc\u0113nu novads' },
    { subCode: '046', name: 'Kokneses novads' },
    { subCode: '047', name: 'Kr\u0101slavas novads' },
    { subCode: '048', name: 'Krimuldas novads' },
    { subCode: '049', name: 'Krustpils novads' },
    { subCode: '050', name: 'Kuld\u012bgas novads' },
    { subCode: '051', name: '\u0136eguma novads' },
    { subCode: '052', name: '\u0136ekavas novads' },
    { subCode: '053', name: 'Lielv\u0101rdes novads' },
    { subCode: '054', name: 'Limba\u017eu novads' },
    { subCode: '055', name: 'L\u012bgatnes novads' },
    { subCode: '056', name: 'L\u012bv\u0101nu novads' },
    { subCode: '057', name: 'Lub\u0101nas novads' },
    { subCode: '058', name: 'Ludzas novads' },
    { subCode: '059', name: 'Madonas novads' },
    { subCode: '060', name: 'Mazsalacas novads' },
    { subCode: '061', name: 'M\u0101lpils novads' },
    { subCode: '062', name: 'M\u0101rupes novads' },
    { subCode: '063', name: 'M\u0113rsraga novads' },
    { subCode: '064', name: 'Nauk\u0161\u0113nu novads' },
    { subCode: '065', name: 'Neretas novads' },
    { subCode: '066', name: 'N\u012bcas novads' },
    { subCode: '067', name: 'Ogres novads' },
    { subCode: '068', name: 'Olaines novads' },
    { subCode: '069', name: 'Ozolnieku novads' },
    { subCode: '070', name: 'P\u0101rgaujas novads' },
    { subCode: '071', name: 'P\u0101vilostas novads' },
    { subCode: '072', name: 'P\u013cavi\u0146u novads' },
    { subCode: '073', name: 'Prei\u013cu novads' },
    { subCode: '074', name: 'Priekules novads' },
    { subCode: '075', name: 'Prieku\u013cu novads' },
    { subCode: '076', name: 'Raunas novads' },
    { subCode: '077', name: 'R\u0113zeknes novads' },
    { subCode: '078', name: 'Riebi\u0146u novads' },
    { subCode: '079', name: 'Rojas novads' },
    { subCode: '080', name: 'Ropa\u017eu novads' },
    { subCode: '081', name: 'Rucavas novads' },
    { subCode: '082', name: 'Rug\u0101ju novads' },
    { subCode: '083', name: 'Rund\u0101les novads' },
    { subCode: '084', name: 'R\u016bjienas novads' },
    { subCode: '085', name: 'Salas novads' },
    { subCode: '086', name: 'Salacgr\u012bvas novads' },
    { subCode: '087', name: 'Salaspils novads' },
    { subCode: '088', name: 'Saldus novads' },
    { subCode: '089', name: 'Saulkrastu novads' },
    { subCode: '090', name: 'S\u0113jas novads' },
    { subCode: '091', name: 'Siguldas novads' },
    { subCode: '092', name: 'Skr\u012bveru novads' },
    { subCode: '093', name: 'Skrundas novads' },
    { subCode: '094', name: 'Smiltenes novads' },
    { subCode: '095', name: 'Stopi\u0146u novads' },
    { subCode: '096', name: 'Stren\u010du novads' },
    { subCode: '097', name: 'Talsu novads' },
    { subCode: '098', name: 'T\u0113rvetes novads' },
    { subCode: '099', name: 'Tukuma novads' },
    { subCode: '100', name: 'Vai\u0146odes novads' },
    { subCode: '101', name: 'Valkas novads' },
    { subCode: '102', name: 'Varak\u013c\u0101nu novads' },
    { subCode: '103', name: 'V\u0101rkavas novads' },
    { subCode: '104', name: 'Vecpiebalgas novads' },
    { subCode: '105', name: 'Vecumnieku novads' },
    { subCode: '106', name: 'Ventspils novads' },
    { subCode: '107', name: 'Vies\u012btes novads' },
    { subCode: '108', name: 'Vi\u013cakas novads' },
    { subCode: '109', name: 'Vi\u013c\u0101nu novads' },
    { subCode: '110', name: 'Zilupes novads' },
    { subCode: 'DGV', name: 'Daugavpils' },
    { subCode: 'JEL', name: 'Jelgava' },
    { subCode: 'JKB', name: 'J\u0113kabpils' },
    { subCode: 'JUR', name: 'J\u016brmala' },
    { subCode: 'LPX', name: 'Liep\u0101ja' },
    { subCode: 'REZ', name: 'R\u0113zekne' },
    { subCode: 'RIX', name: 'R\u012bga' },
    { subCode: 'VEN', name: 'Ventspils' },
    { subCode: 'VMR', name: 'Valmiera' },
  ],
  LB: [
    { subCode: 'AK', name: 'Aakk\u00e2r' },
    { subCode: 'AS', name: 'Ash Shim\u0101l' },
    { subCode: 'BA', name: 'Bayr\u016bt' },
    { subCode: 'BH', name: 'Baalbek-Hermel' },
    { subCode: 'BI', name: 'Al Biq\u0101\u2018' },
    { subCode: 'JA', name: 'Al Jan\u016bb' },
    { subCode: 'JL', name: 'Jabal Lubn\u0101n' },
    { subCode: 'NA', name: 'An Naba\u0163\u012byah' },
  ],
  LS: [
    { subCode: 'A', name: 'Maseru' },
    { subCode: 'B', name: 'Butha-Buthe' },
    { subCode: 'C', name: 'Leribe' },
    { subCode: 'D', name: 'Berea' },
    { subCode: 'E', name: 'Mafeteng' },
    { subCode: 'F', name: "Mohale's Hoek" },
    { subCode: 'G', name: 'Quthing' },
    { subCode: 'H', name: "Qacha's Nek" },
    { subCode: 'J', name: 'Mokhotlong' },
    { subCode: 'K', name: 'Thaba-Tseka' },
  ],
  LR: [
    { subCode: 'BG', name: 'Bong' },
    { subCode: 'BM', name: 'Bomi' },
    { subCode: 'CM', name: 'Grand Cape Mount' },
    { subCode: 'GB', name: 'Grand Bassa' },
    { subCode: 'GG', name: 'Grand Gedeh' },
    { subCode: 'GK', name: 'Grand Kru' },
    { subCode: 'GP', name: 'Gbarpolu' },
    { subCode: 'LO', name: 'Lofa' },
    { subCode: 'MG', name: 'Margibi' },
    { subCode: 'MO', name: 'Montserrado' },
    { subCode: 'MY', name: 'Maryland' },
    { subCode: 'NI', name: 'Nimba' },
    { subCode: 'RG', name: 'River Gee' },
    { subCode: 'RI', name: 'Rivercess' },
    { subCode: 'SI', name: 'Sinoe' },
  ],
  LY: [
    { subCode: 'BA', name: 'Bangh\u0101z\u012b' },
    { subCode: 'BU', name: 'Al Bu\u0163n\u0101n' },
    { subCode: 'DR', name: 'Darnah' },
    { subCode: 'GT', name: 'Gh\u0101t' },
    { subCode: 'JA', name: 'Al Jabal al Akh\u1e11ar' },
    { subCode: 'JG', name: 'Al Jabal al Gharb\u012b' },
    { subCode: 'JI', name: 'Al Jif\u0101rah' },
    { subCode: 'JU', name: 'Al Jufrah' },
    { subCode: 'KF', name: 'Al Kufrah' },
    { subCode: 'MB', name: 'Al Marqab' },
    { subCode: 'MI', name: 'Mi\u015fr\u0101tah' },
    { subCode: 'MJ', name: 'Al Marj' },
    { subCode: 'MQ', name: 'Murzuq' },
    { subCode: 'NL', name: 'N\u0101l\u016bt' },
    { subCode: 'NQ', name: 'An Nuq\u0101\u0163 al Khams' },
    { subCode: 'SB', name: 'Sabh\u0101' },
    { subCode: 'SR', name: 'Surt' },
    { subCode: 'TB', name: '\u0162ar\u0101bulus' },
    { subCode: 'WA', name: 'Al W\u0101\u1e29\u0101t' },
    { subCode: 'WD', name: 'W\u0101d\u012b al \u1e28ay\u0101t' },
    { subCode: 'WS', name: 'W\u0101d\u012b ash Sh\u0101\u0163i\u2019' },
    { subCode: 'ZA', name: 'Az Z\u0101wiyah' },
  ],
  LI: [
    { subCode: '01', name: 'Balzers' },
    { subCode: '02', name: 'Eschen' },
    { subCode: '03', name: 'Gamprin' },
    { subCode: '04', name: 'Mauren' },
    { subCode: '05', name: 'Planken' },
    { subCode: '06', name: 'Ruggell' },
    { subCode: '07', name: 'Schaan' },
    { subCode: '08', name: 'Schellenberg' },
    { subCode: '09', name: 'Triesen' },
    { subCode: '10', name: 'Triesenberg' },
    { subCode: '11', name: 'Vaduz' },
  ],
  LT: [
    { subCode: '01', name: 'Akmen\u0117' },
    { subCode: '02', name: 'Alytaus miestas' },
    { subCode: '03', name: 'Alytus' },
    { subCode: '04', name: 'Anyk\u0161\u010diai' },
    { subCode: '05', name: 'Bir\u0161tono' },
    { subCode: '06', name: 'Bir\u017eai' },
    { subCode: '07', name: 'Druskininkai' },
    { subCode: '08', name: 'Elektr\u00e9nai' },
    { subCode: '09', name: 'Ignalina' },
    { subCode: '10', name: 'Jonava' },
    { subCode: '11', name: 'Joni\u0161kis' },
    { subCode: '12', name: 'Jurbarkas' },
    { subCode: '13', name: 'Kai\u0161iadorys' },
    { subCode: '14', name: 'Kalvarijos' },
    { subCode: '15', name: 'Kauno miestas' },
    { subCode: '16', name: 'Kaunas' },
    { subCode: '17', name: 'Kazl\u0173 R\u016bdos' },
    { subCode: '18', name: 'K\u0117dainiai' },
    { subCode: '19', name: 'Kelm\u0117' },
    { subCode: '20', name: 'Klaip\u0117dos miestas' },
    { subCode: '21', name: 'Klaip\u0117da' },
    { subCode: '22', name: 'Kretinga' },
    { subCode: '23', name: 'Kupi\u0161kis' },
    { subCode: '24', name: 'Lazdijai' },
    { subCode: '25', name: 'Marijampol\u0117' },
    { subCode: '26', name: 'Ma\u017eeikiai' },
    { subCode: '27', name: 'Mol\u0117tai' },
    { subCode: '28', name: 'Neringa' },
    { subCode: '29', name: 'Pag\u00e9giai' },
    { subCode: '30', name: 'Pakruojis' },
    { subCode: '31', name: 'Palangos miestas' },
    { subCode: '32', name: 'Panev\u0117\u017eio miestas' },
    { subCode: '33', name: 'Panev\u0117\u017eys' },
    { subCode: '34', name: 'Pasvalys' },
    { subCode: '35', name: 'Plung\u0117' },
    { subCode: '36', name: 'Prienai' },
    { subCode: '37', name: 'Radvili\u0161kis' },
    { subCode: '38', name: 'Raseiniai' },
    { subCode: '39', name: 'Rietavo' },
    { subCode: '40', name: 'Roki\u0161kis' },
    { subCode: '41', name: '\u0160akiai' },
    { subCode: '42', name: '\u0160al\u010dininkai' },
    { subCode: '43', name: '\u0160iauli\u0173 miestas' },
    { subCode: '44', name: '\u0160iauliai' },
    { subCode: '45', name: '\u0160ilal\u0117' },
    { subCode: '46', name: '\u0160ilut\u0117' },
    { subCode: '47', name: '\u0160irvintos' },
    { subCode: '48', name: 'Skuodas' },
    { subCode: '49', name: '\u0160ven\u010dionys' },
    { subCode: '50', name: 'Taurag\u0117' },
    { subCode: '51', name: 'Tel\u0161iai' },
    { subCode: '52', name: 'Trakai' },
    { subCode: '53', name: 'Ukmerg\u0117' },
    { subCode: '54', name: 'Utena' },
    { subCode: '55', name: 'Var\u0117na' },
    { subCode: '56', name: 'Vilkavi\u0161kis' },
    { subCode: '57', name: 'Vilniaus miestas' },
    { subCode: '58', name: 'Vilnius' },
    { subCode: '59', name: 'Visaginas' },
    { subCode: '60', name: 'Zarasai' },
    { subCode: 'AL', name: 'Alytaus apskritis' },
    { subCode: 'KL', name: 'Klaip\u0117dos apskritis' },
    { subCode: 'KU', name: 'Kauno apskritis' },
    { subCode: 'MR', name: 'Marijampol\u0117s apskritis' },
    { subCode: 'PN', name: 'Panev\u0117\u017eio apskritis' },
    { subCode: 'SA', name: '\u0160iauli\u0173 apskritis' },
    { subCode: 'TA', name: 'Taurag\u0117s apskritis' },
    { subCode: 'TE', name: 'Tel\u0161i\u0173 apskritis' },
    { subCode: 'UT', name: 'Utenos apskritis' },
    { subCode: 'VL', name: 'Vilniaus apskritis' },
  ],
  LU: [
    { subCode: 'D', name: 'Diekirch' },
    { subCode: 'G', name: 'Grevenmacher' },
    { subCode: 'L', name: 'L\u00ebtzebuerg' },
  ],
  MO: [{ subCode: 'MO', name: 'Macau' }],
  MK: [
    { subCode: '01', name: 'Aerodrom \u2020' },
    { subCode: '02', name: 'Ara\u010dinovo' },
    { subCode: '03', name: 'Berovo' },
    { subCode: '04', name: 'Bitola' },
    { subCode: '05', name: 'Bogdanci' },
    { subCode: '06', name: 'Bogovinje' },
    { subCode: '07', name: 'Bosilovo' },
    { subCode: '08', name: 'Brvenica' },
    { subCode: '09', name: 'Butel \u2020' },
    { subCode: '10', name: 'Valandovo' },
    { subCode: '11', name: 'Vasilevo' },
    { subCode: '12', name: 'Vev\u010dani' },
    { subCode: '13', name: 'Veles' },
    { subCode: '14', name: 'Vinica' },
    { subCode: '15', name: 'Vrane\u0161tica' },
    { subCode: '16', name: 'Vrap\u010di\u0161te' },
    { subCode: '17', name: 'Gazi Baba \u2020' },
    { subCode: '18', name: 'Gevgelija' },
    { subCode: '19', name: 'Gostivar' },
    { subCode: '20', name: 'Gradsko' },
    { subCode: '21', name: 'Debar' },
    { subCode: '22', name: 'Debarca' },
    { subCode: '23', name: 'Del\u010devo' },
    { subCode: '24', name: 'Demir Kapija' },
    { subCode: '25', name: 'Demir Hisar' },
    { subCode: '26', name: 'Dojran' },
    { subCode: '27', name: 'Dolneni' },
    { subCode: '28', name: 'Drugovo' },
    { subCode: '29', name: 'Gjor\u010de Petrov \u2020' },
    { subCode: '30', name: '\u017delino' },
    { subCode: '31', name: 'Zajas' },
    { subCode: '32', name: 'Zelenikovo' },
    { subCode: '33', name: 'Zrnovci' },
    { subCode: '34', name: 'Ilinden' },
    { subCode: '35', name: 'Jegunovce' },
    { subCode: '36', name: 'Kavadarci' },
    { subCode: '37', name: 'Karbinci' },
    { subCode: '38', name: 'Karpo\u0161 \u2020' },
    { subCode: '39', name: 'Kisela Voda \u2020' },
    { subCode: '40', name: 'Ki\u010devo' },
    { subCode: '41', name: 'Kon\u010de' },
    { subCode: '42', name: 'Ko\u010dani' },
    { subCode: '43', name: 'Kratovo' },
    { subCode: '44', name: 'Kriva Palanka' },
    { subCode: '45', name: 'Krivoga\u0161tani' },
    { subCode: '46', name: 'Kru\u0161evo' },
    { subCode: '47', name: 'Kumanovo' },
    { subCode: '48', name: 'Lipkovo' },
    { subCode: '49', name: 'Lozovo' },
    { subCode: '50', name: 'Mavrovo i Rostu\u0161a' },
    { subCode: '51', name: 'Makedonska Kamenica' },
    { subCode: '52', name: 'Makedonski Brod' },
    { subCode: '53', name: 'Mogila' },
    { subCode: '54', name: 'Negotino' },
    { subCode: '55', name: 'Novaci' },
    { subCode: '56', name: 'Novo Selo' },
    { subCode: '57', name: 'Oslomej' },
    { subCode: '58', name: 'Ohrid' },
    { subCode: '59', name: 'Petrovec' },
    { subCode: '60', name: 'Peh\u010devo' },
    { subCode: '61', name: 'Plasnica' },
    { subCode: '62', name: 'Prilep' },
    { subCode: '63', name: 'Probi\u0161tip' },
    { subCode: '64', name: 'Radovi\u0161' },
    { subCode: '65', name: 'Rankovce' },
    { subCode: '66', name: 'Resen' },
    { subCode: '67', name: 'Rosoman' },
    { subCode: '68', name: 'Saraj \u2020' },
    { subCode: '69', name: 'Sveti Nikole' },
    { subCode: '70', name: 'Sopi\u0161te' },
    { subCode: '71', name: 'Staro Nagori\u010dane' },
    { subCode: '72', name: 'Struga' },
    { subCode: '73', name: 'Strumica' },
    { subCode: '74', name: 'Studeni\u010dani' },
    { subCode: '75', name: 'Tearce' },
    { subCode: '76', name: 'Tetovo' },
    { subCode: '77', name: 'Centar \u2020' },
    { subCode: '78', name: 'Centar \u017dupa' },
    { subCode: '79', name: '\u010cair \u2020' },
    { subCode: '80', name: '\u010ca\u0161ka' },
    { subCode: '81', name: '\u010ce\u0161inovo-Oble\u0161evo' },
    { subCode: '82', name: '\u010cu\u010der Sandevo' },
    { subCode: '83', name: '\u0160tip' },
    { subCode: '84', name: '\u0160uto Orizari \u2020' },
  ],
  MG: [
    { subCode: 'A', name: 'Toamasina' },
    { subCode: 'D', name: 'Antsiranana' },
    { subCode: 'F', name: 'Fianarantsoa' },
    { subCode: 'M', name: 'Mahajanga' },
    { subCode: 'T', name: 'Antananarivo' },
    { subCode: 'U', name: 'Toliara' },
  ],
  MW: [
    { subCode: 'BA', name: 'Balaka' },
    { subCode: 'BL', name: 'Blantyre' },
    { subCode: 'C', name: 'Central Region' },
    { subCode: 'CK', name: 'Chikwawa' },
    { subCode: 'CR', name: 'Chiradzulu' },
    { subCode: 'CT', name: 'Chitipa' },
    { subCode: 'DE', name: 'Dedza' },
    { subCode: 'DO', name: 'Dowa' },
    { subCode: 'KR', name: 'Karonga' },
    { subCode: 'KS', name: 'Kasungu' },
    { subCode: 'LI', name: 'Lilongwe' },
    { subCode: 'LK', name: 'Likoma' },
    { subCode: 'MC', name: 'Mchinji' },
    { subCode: 'MG', name: 'Mangochi' },
    { subCode: 'MH', name: 'Machinga' },
    { subCode: 'MU', name: 'Mulanje' },
    { subCode: 'MW', name: 'Mwanza' },
    { subCode: 'MZ', name: 'Mzimba' },
    { subCode: 'NB', name: 'Nkhata Bay' },
    { subCode: 'N', name: 'Chakumpoto' },
    { subCode: 'NE', name: 'Neno' },
    { subCode: 'NI', name: 'Ntchisi' },
    { subCode: 'NK', name: 'Nkhotakota' },
    { subCode: 'NS', name: 'Nsanje' },
    { subCode: 'NU', name: 'Ntcheu' },
    { subCode: 'PH', name: 'Phalombe' },
    { subCode: 'RU', name: 'Rumphi' },
    { subCode: 'SA', name: 'Salima' },
    { subCode: 'S', name: 'Chakumwera' },
    { subCode: 'TH', name: 'Thyolo' },
    { subCode: 'ZO', name: 'Zomba' },
  ],
  MY: [
    { subCode: '01', name: 'Johor' },
    { subCode: '02', name: 'Kedah' },
    { subCode: '03', name: 'Kelantan' },
    { subCode: '04', name: 'Melaka' },
    { subCode: '05', name: 'Negeri Sembilan' },
    { subCode: '06', name: 'Pahang' },
    { subCode: '07', name: 'Pulau Pinang' },
    { subCode: '08', name: 'Perak' },
    { subCode: '09', name: 'Perlis' },
    { subCode: '10', name: 'Selangor' },
    { subCode: '11', name: 'Terengganu' },
    { subCode: '12', name: 'Sabah' },
    { subCode: '13', name: 'Sarawak' },
    { subCode: '14', name: 'Wilayah Persekutuan Kuala Lumpur' },
    { subCode: '15', name: 'Wilayah Persekutuan Labuan' },
    { subCode: '16', name: 'Wilayah Persekutuan Putrajaya' },
  ],
  MV: [
    { subCode: '00', name: 'Alifu Dhaalu' },
    { subCode: '01', name: 'Addu Atholhu' },
    { subCode: '02', name: 'Alifu Alifu' },
    { subCode: '03', name: 'Faadhippolhu' },
    { subCode: '04', name: 'Felidhu Atholhu' },
    { subCode: '05', name: 'Hadhdhunmathi' },
    { subCode: '07', name: 'Haa Alifu' },
    { subCode: '08', name: 'Kolhumadulu' },
    { subCode: '12', name: 'Meemu' },
    { subCode: '13', name: 'Maalhosmadulu Uthuruburi' },
    { subCode: '14', name: 'Faafu' },
    { subCode: '17', name: 'Dhaalu' },
    { subCode: '20', name: 'Baa' },
    { subCode: '23', name: 'Haa Dhaalu' },
    { subCode: '24', name: 'Miladhunmadulu Uthuruburi' },
    { subCode: '25', name: 'Miladhunmadulu Dhekunuburi' },
    { subCode: '26', name: 'Kaafu' },
    { subCode: '27', name: 'Gaafu Alifu' },
    { subCode: '28', name: 'Gaafu Dhaalu' },
    { subCode: '29', name: 'Fuvammulah' },
    { subCode: 'CE', name: 'Central' },
    { subCode: 'MLE', name: 'Maale' },
    { subCode: 'NC', name: 'Medhu-Uthuru' },
    { subCode: 'NO', name: 'North' },
    { subCode: 'SC', name: 'Medhu-Dhekunu' },
    { subCode: 'SU', name: 'Dhekunu' },
    { subCode: 'UN', name: 'Mathi-Uthuru' },
    { subCode: 'US', name: 'Mathi-Dhekunu' },
  ],
  ML: [
    { subCode: '1', name: 'Kayes' },
    { subCode: '2', name: 'Koulikoro' },
    { subCode: '3', name: 'Sikasso' },
    { subCode: '4', name: 'S\u00e9gou' },
    { subCode: '5', name: 'Mopti' },
    { subCode: '6', name: 'Tombouctou' },
    { subCode: '7', name: 'Gao' },
    { subCode: '8', name: 'Kidal' },
    { subCode: 'BKO', name: 'Bamako' },
  ],
  MT: [
    { subCode: '01', name: 'Attard' },
    { subCode: '02', name: 'Balzan' },
    { subCode: '03', name: 'Birgu' },
    { subCode: '04', name: 'Birkirkara' },
    { subCode: '05', name: 'Bir\u017cebbu\u0121a' },
    { subCode: '06', name: 'Bormla' },
    { subCode: '07', name: 'Dingli' },
    { subCode: '08', name: 'Fgura' },
    { subCode: '09', name: 'Floriana' },
    { subCode: '10', name: 'Fontana' },
    { subCode: '11', name: 'Gudja' },
    { subCode: '12', name: 'G\u017cira' },
    { subCode: '13', name: 'G\u0127ajnsielem' },
    { subCode: '14', name: 'G\u0127arb' },
    { subCode: '15', name: 'G\u0127arg\u0127ur' },
    { subCode: '16', name: 'G\u0127asri' },
    { subCode: '17', name: 'G\u0127axaq' },
    { subCode: '18', name: '\u0126amrun' },
    { subCode: '19', name: 'Iklin' },
    { subCode: '20', name: 'Isla' },
    { subCode: '21', name: 'Kalkara' },
    { subCode: '22', name: 'Ker\u010bem' },
    { subCode: '23', name: 'Kirkop' },
    { subCode: '24', name: 'Lija' },
    { subCode: '25', name: 'Luqa' },
    { subCode: '26', name: 'Marsa' },
    { subCode: '27', name: 'Marsaskala' },
    { subCode: '28', name: 'Marsaxlokk' },
    { subCode: '29', name: 'Mdina' },
    { subCode: '30', name: 'Mellie\u0127a' },
    { subCode: '31', name: 'M\u0121arr' },
    { subCode: '32', name: 'Mosta' },
    { subCode: '33', name: 'Mqabba' },
    { subCode: '34', name: 'Msida' },
    { subCode: '35', name: 'Mtarfa' },
    { subCode: '36', name: 'Munxar' },
    { subCode: '37', name: 'Nadur' },
    { subCode: '38', name: 'Naxxar' },
    { subCode: '39', name: 'Paola' },
    { subCode: '40', name: 'Pembroke' },
    { subCode: '41', name: 'Piet\u00e0' },
    { subCode: '42', name: 'Qala' },
    { subCode: '43', name: 'Qormi' },
    { subCode: '44', name: 'Qrendi' },
    { subCode: '45', name: 'Rabat Gozo' },
    { subCode: '46', name: 'Rabat Malta' },
    { subCode: '47', name: 'Safi' },
    { subCode: '48', name: 'Saint Julian\u2019s' },
    { subCode: '49', name: 'Saint John' },
    { subCode: '50', name: 'Saint Lawrence' },
    { subCode: '51', name: 'Saint Paul\u2019s Bay' },
    { subCode: '52', name: 'Sannat' },
    { subCode: '53', name: 'Saint Lucia\u2019s' },
    { subCode: '54', name: 'Santa Venera' },
    { subCode: '55', name: 'Si\u0121\u0121iewi' },
    { subCode: '56', name: 'Sliema' },
    { subCode: '57', name: 'Swieqi' },
    { subCode: '58', name: 'Ta\u2019 Xbiex' },
    { subCode: '59', name: 'Tarxien' },
    { subCode: '60', name: 'Valletta' },
    { subCode: '61', name: 'Xag\u0127ra' },
    { subCode: '62', name: 'Xewkija' },
    { subCode: '63', name: 'Xg\u0127ajra' },
    { subCode: '64', name: '\u017babbar' },
    { subCode: '65', name: '\u017bebbu\u0121 Gozo' },
    { subCode: '66', name: '\u017bebbu\u0121 Malta' },
    { subCode: '67', name: '\u017bejtun' },
    { subCode: '68', name: '\u017burrieq' },
  ],
  MH: [
    { subCode: 'ALK', name: 'Ailuk' },
    { subCode: 'ALL', name: 'Ailinglaplap' },
    { subCode: 'ARN', name: 'Arno' },
    { subCode: 'AUR', name: 'Aur' },
    { subCode: 'EBO', name: 'Ebon' },
    { subCode: 'ENI', name: 'Enewetak and Ujelang' },
    { subCode: 'JAB', name: 'Jabat' },
    { subCode: 'JAL', name: 'Jaluit' },
    { subCode: 'KIL', name: 'Bikini and Kili' },
    { subCode: 'KWA', name: 'Kwajalein' },
    { subCode: 'LAE', name: 'Lae' },
    { subCode: 'LIB', name: 'Lib' },
    { subCode: 'LIK', name: 'Likiep' },
    { subCode: 'L', name: 'Ralik chain' },
    { subCode: 'MAJ', name: 'Majuro' },
    { subCode: 'MAL', name: 'Maloelap' },
    { subCode: 'MEJ', name: 'Mejit' },
    { subCode: 'MIL', name: 'Mili' },
    { subCode: 'NMK', name: 'Namdrik' },
    { subCode: 'NMU', name: 'Namu' },
    { subCode: 'RON', name: 'Rongelap' },
    { subCode: 'T', name: 'Ratak chain' },
    { subCode: 'UJA', name: 'Ujae' },
    { subCode: 'UTI', name: 'Utrik' },
    { subCode: 'WTH', name: 'Wotho' },
    { subCode: 'WTJ', name: 'Wotje' },
  ],
  MQ: [{ subCode: 'MQ', name: 'Martinique' }],
  MR: [
    { subCode: '01', name: 'Hodh ech Chargui' },
    { subCode: '02', name: 'Hodh el Gharbi' },
    { subCode: '03', name: 'Assaba' },
    { subCode: '04', name: 'Gorgol' },
    { subCode: '05', name: 'Brakna' },
    { subCode: '06', name: 'Trarza' },
    { subCode: '07', name: 'Adrar' },
    { subCode: '08', name: 'Dakhlet Nou\u00e2dhibou' },
    { subCode: '09', name: 'Tagant' },
    { subCode: '10', name: 'Guidimaka' },
    { subCode: '11', name: 'Tiris Zemmour' },
    { subCode: '12', name: 'Inchiri' },
    { subCode: 'NKC', name: 'Nouakchott' },
  ],
  MU: [
    { subCode: 'AG', name: 'Agalega Islands' },
    { subCode: 'BL', name: 'Black River' },
    { subCode: 'BR', name: 'Beau Bassin-Rose Hill' },
    { subCode: 'CC', name: 'Cargados Carajos Shoals' },
    { subCode: 'CU', name: 'Curepipe' },
    { subCode: 'FL', name: 'Flacq' },
    { subCode: 'GP', name: 'Grand Port' },
    { subCode: 'MO', name: 'Moka' },
    { subCode: 'PA', name: 'Pamplemousses' },
    { subCode: 'PL', name: 'Port Louis' },
    { subCode: 'PU', name: 'Port Louis' },
    { subCode: 'PW', name: 'Plaines Wilhems' },
    { subCode: 'QB', name: 'Quatre Bornes' },
    { subCode: 'RO', name: 'Rodrigues Island' },
    { subCode: 'RR', name: 'Rivi\u00e8re du Rempart' },
    { subCode: 'SA', name: 'Savanne' },
    { subCode: 'VP', name: 'Vacoas-Phoenix' },
  ],
  YT: [{ subCode: 'YT', name: 'Mayotte' }],
  MX: [
    { subCode: 'AGU', name: 'Aguascalientes' },
    { subCode: 'BCN', name: 'Baja California' },
    { subCode: 'BCS', name: 'Baja California Sur' },
    { subCode: 'CAM', name: 'Campeche' },
    { subCode: 'CHH', name: 'Chihuahua' },
    { subCode: 'CHP', name: 'Chiapas' },
    { subCode: 'COA', name: 'Coahuila de Zaragoza' },
    { subCode: 'COL', name: 'Colima' },
    { subCode: 'CMX', name: 'Ciudad de M\u00e9xico' },
    { subCode: 'DUR', name: 'Durango' },
    { subCode: 'GRO', name: 'Guerrero' },
    { subCode: 'GUA', name: 'Guanajuato' },
    { subCode: 'HID', name: 'Hidalgo' },
    { subCode: 'JAL', name: 'Jalisco' },
    { subCode: 'MEX', name: 'M\u00e9xico' },
    { subCode: 'MIC', name: 'Michoac\u00e1n de Ocampo' },
    { subCode: 'MOR', name: 'Morelos' },
    { subCode: 'NAY', name: 'Nayarit' },
    { subCode: 'NLE', name: 'Nuevo Le\u00f3n' },
    { subCode: 'OAX', name: 'Oaxaca' },
    { subCode: 'PUE', name: 'Puebla' },
    { subCode: 'QUE', name: 'Quer\u00e9taro' },
    { subCode: 'ROO', name: 'Quintana Roo' },
    { subCode: 'SIN', name: 'Sinaloa' },
    { subCode: 'SLP', name: 'San Luis Potos\u00ed' },
    { subCode: 'SON', name: 'Sonora' },
    { subCode: 'TAB', name: 'Tabasco' },
    { subCode: 'TAM', name: 'Tamaulipas' },
    { subCode: 'TLA', name: 'Tlaxcala' },
    { subCode: 'VER', name: 'Veracruz de Ignacio de la Llave' },
    { subCode: 'YUC', name: 'Yucat\u00e1n' },
    { subCode: 'ZAC', name: 'Zacatecas' },
  ],
  FM: [
    { subCode: 'KSA', name: 'Kosrae' },
    { subCode: 'PNI', name: 'Pohnpei' },
    { subCode: 'TRK', name: 'Chuuk' },
    { subCode: 'YAP', name: 'Yap' },
  ],
  MD: [
    { subCode: 'AN', name: 'Anenii Noi' },
    { subCode: 'BA', name: 'B\u0103l\u0163i' },
    { subCode: 'BD', name: 'Bender' },
    { subCode: 'BR', name: 'Briceni' },
    { subCode: 'BS', name: 'Basarabeasca' },
    { subCode: 'CA', name: 'Cahul' },
    { subCode: 'CL', name: 'C\u0103l\u0103ra\u015fi' },
    { subCode: 'CM', name: 'Cimi\u015flia' },
    { subCode: 'CR', name: 'Criuleni' },
    { subCode: 'CS', name: 'C\u0103u\u015feni' },
    { subCode: 'CT', name: 'Cantemir' },
    { subCode: 'CU', name: 'Chi\u015fin\u0103u' },
    { subCode: 'DO', name: 'Dondu\u015feni' },
    { subCode: 'DR', name: 'Drochia' },
    { subCode: 'DU', name: 'Dub\u0103sari' },
    { subCode: 'ED', name: 'Edine\u0163' },
    { subCode: 'FA', name: 'F\u0103le\u015fti' },
    { subCode: 'FL', name: 'Flore\u015fti' },
    { subCode: 'GA', name: 'G\u0103g\u0103uzia, Unitatea teritorial\u0103 autonom\u0103' },
    { subCode: 'GL', name: 'Glodeni' },
    { subCode: 'HI', name: 'H\u00eence\u015fti' },
    { subCode: 'IA', name: 'Ialoveni' },
    { subCode: 'LE', name: 'Leova' },
    { subCode: 'NI', name: 'Nisporeni' },
    { subCode: 'OC', name: 'Ocni\u0163a' },
    { subCode: 'OR', name: 'Orhei' },
    { subCode: 'RE', name: 'Rezina' },
    { subCode: 'RI', name: 'R\u00ee\u015fcani' },
    { subCode: 'SD', name: '\u015eold\u0103ne\u015fti' },
    { subCode: 'SI', name: 'S\u00eengerei' },
    { subCode: 'SN', name: 'St\u00eenga Nistrului, unitatea teritorial\u0103 din' },
    { subCode: 'SO', name: 'Soroca' },
    { subCode: 'ST', name: 'Str\u0103\u015feni' },
    { subCode: 'SV', name: '\u015etefan Vod\u0103' },
    { subCode: 'TA', name: 'Taraclia' },
    { subCode: 'TE', name: 'Telene\u015fti' },
    { subCode: 'UN', name: 'Ungheni' },
  ],
  MC: [
    { subCode: 'CL', name: 'La Colle' },
    { subCode: 'CO', name: 'La Condamine' },
    { subCode: 'FO', name: 'Fontvieille' },
    { subCode: 'GA', name: 'La Gare' },
    { subCode: 'JE', name: 'Jardin Exotique' },
    { subCode: 'LA', name: 'Larvotto' },
    { subCode: 'MA', name: 'Malbousquet' },
    { subCode: 'MC', name: 'Monte-Carlo' },
    { subCode: 'MG', name: 'Moneghetti' },
    { subCode: 'MO', name: 'Monaco-Ville' },
    { subCode: 'MU', name: 'Moulins' },
    { subCode: 'PH', name: 'Port-Hercule' },
    { subCode: 'SD', name: 'Sainte-D\u00e9vote' },
    { subCode: 'SO', name: 'La Source' },
    { subCode: 'SP', name: 'Sp\u00e9lugues' },
    { subCode: 'SR', name: 'Saint-Roman' },
    { subCode: 'VR', name: 'Vallon de la Rousse' },
  ],
  MN: [
    { subCode: '035', name: 'Orhon' },
    { subCode: '037', name: 'Darhan uul' },
    { subCode: '039', name: 'Hentiy' },
    { subCode: '041', name: 'H\u00f6vsg\u00f6l' },
    { subCode: '043', name: 'Hovd' },
    { subCode: '046', name: 'Uvs' },
    { subCode: '047', name: 'T\u00f6v' },
    { subCode: '049', name: 'Selenge' },
    { subCode: '051', name: 'S\u00fchbaatar' },
    { subCode: '053', name: '\u00d6mn\u00f6gov\u012d' },
    { subCode: '055', name: '\u00d6v\u00f6rhangay' },
    { subCode: '057', name: 'Dzavhan' },
    { subCode: '059', name: 'Dundgov\u012d' },
    { subCode: '061', name: 'Dornod' },
    { subCode: '063', name: 'Dornogov\u012d' },
    { subCode: '064', name: 'Gov\u012d-S\u00fcmber' },
    { subCode: '065', name: 'Gov\u012d-Altay' },
    { subCode: '067', name: 'Bulgan' },
    { subCode: '069', name: 'Bayanhongor' },
    { subCode: '071', name: 'Bayan-\u00d6lgiy' },
    { subCode: '073', name: 'Arhangay' },
    { subCode: '1', name: 'Ulaanbaatar' },
  ],
  ME: [
    { subCode: '01', name: 'Andrijevica' },
    { subCode: '02', name: 'Bar' },
    { subCode: '03', name: 'Berane' },
    { subCode: '04', name: 'Bijelo Polje' },
    { subCode: '05', name: 'Budva' },
    { subCode: '06', name: 'Cetinje' },
    { subCode: '07', name: 'Danilovgrad' },
    { subCode: '08', name: 'Herceg-Novi' },
    { subCode: '09', name: 'Kola\u0161in' },
    { subCode: '10', name: 'Kotor' },
    { subCode: '11', name: 'Mojkovac' },
    { subCode: '12', name: 'Nik\u0161i\u0107' },
    { subCode: '13', name: 'Plav' },
    { subCode: '14', name: 'Pljevlja' },
    { subCode: '15', name: 'Plu\u017eine' },
    { subCode: '16', name: 'Podgorica' },
    { subCode: '17', name: 'Ro\u017eaje' },
    { subCode: '18', name: '\u0160avnik' },
    { subCode: '19', name: 'Tivat' },
    { subCode: '20', name: 'Ulcinj' },
    { subCode: '21', name: '\u017dabljak' },
    { subCode: '22', name: 'Gusinje' },
    { subCode: '23', name: 'Petnjica' },
  ],
  MS: [{ subCode: 'MS', name: 'Montserrat' }],
  MA: [
    { subCode: '01', name: 'Tanger-T\u00e9touan' },
    { subCode: '02', name: 'Gharb-Chrarda-Beni Hssen' },
    { subCode: '03', name: 'Taza-Al Hoceima-Taounate' },
    { subCode: '04', name: "L'Oriental" },
    { subCode: '05', name: 'F\u00e8s-Boulemane' },
    { subCode: '06', name: 'Mekn\u00e8s-Tafilalet' },
    { subCode: '07', name: 'Rabat-Sal\u00e9-Zemmour-Zaer' },
    { subCode: '08', name: 'Grand Casablanca' },
    { subCode: '09', name: 'Chaouia-Ouardigha' },
    { subCode: '10', name: 'Doukkala-Abda' },
    { subCode: '11', name: 'Marrakech-Tensift-Al Haouz' },
    { subCode: '12', name: 'Tadla-Azilal' },
    { subCode: '13', name: 'Sous-Massa-Draa' },
    { subCode: '14', name: 'Guelmim-Es Semara' },
    { subCode: '15', name: 'La\u00e2youne-Boujdour-Sakia el Hamra' },
    { subCode: '16', name: 'Oued ed Dahab-Lagouira' },
    { subCode: 'AGD', name: 'Agadir-Ida-Outanane' },
    { subCode: 'AOU', name: 'Aousserd' },
    { subCode: 'ASZ', name: 'Assa-Zag' },
    { subCode: 'AZI', name: 'Azilal' },
    { subCode: 'BEM', name: 'Beni Mellal' },
    { subCode: 'BER', name: 'Berkane' },
    { subCode: 'BES', name: 'Ben Slimane' },
    { subCode: 'BOD', name: 'Boujdour' },
    { subCode: 'BOM', name: 'Boulemane' },
    { subCode: 'CAS', name: 'Casablanca' },
    { subCode: 'CHE', name: 'Chefchaouen' },
    { subCode: 'CHI', name: 'Chichaoua' },
    { subCode: 'CHT', name: 'Chtouka-Ait Baha' },
    { subCode: 'ERR', name: 'Errachidia' },
    { subCode: 'ESI', name: 'Essaouira' },
    { subCode: 'ESM', name: 'Es Smara' },
    { subCode: 'FAH', name: 'Fahs-Beni Makada' },
    { subCode: 'FES', name: 'F\u00e8s-Dar-Dbibegh' },
    { subCode: 'FIG', name: 'Figuig' },
    { subCode: 'GUE', name: 'Guelmim' },
    { subCode: 'HAJ', name: 'El Hajeb' },
    { subCode: 'HAO', name: 'Al Haouz' },
    { subCode: 'HOC', name: 'Al Hoce\u00efma' },
    { subCode: 'IFR', name: 'Ifrane' },
    { subCode: 'INE', name: 'Inezgane-Ait Melloul' },
    { subCode: 'JDI', name: 'El Jadida' },
    { subCode: 'JRA', name: 'Jrada' },
    { subCode: 'KEN', name: 'K\u00e9nitra' },
    { subCode: 'KES', name: 'Kelaat es Sraghna' },
    { subCode: 'KHE', name: 'Khemisset' },
    { subCode: 'KHN', name: 'Khenifra' },
    { subCode: 'KHO', name: 'Khouribga' },
    { subCode: 'LAA', name: 'La\u00e2youne' },
    { subCode: 'LAR', name: 'Larache' },
    { subCode: 'MED', name: 'M\u00e9diouna' },
    { subCode: 'MEK', name: 'Mekn\u00e8s' },
    { subCode: 'MMD', name: 'Marrakech-Medina' },
    { subCode: 'MMN', name: 'Marrakech-Menara' },
    { subCode: 'MOH', name: 'Mohammadia' },
    { subCode: 'MOU', name: 'Moulay Yacoub' },
    { subCode: 'NAD', name: 'Nador' },
    { subCode: 'NOU', name: 'Nouaceur' },
    { subCode: 'OUA', name: 'Ouarzazate' },
    { subCode: 'OUD', name: 'Oued ed Dahab' },
    { subCode: 'OUJ', name: 'Oujda-Angad' },
    { subCode: 'RAB', name: 'Rabat' },
    { subCode: 'SAF', name: 'Safi' },
    { subCode: 'SAL', name: 'Sal\u00e9' },
    { subCode: 'SEF', name: 'Sefrou' },
    { subCode: 'SET', name: 'Settat' },
    { subCode: 'SIK', name: 'Sidi Kacem' },
    { subCode: 'SKH', name: 'Skhirate-T\u00e9mara' },
    { subCode: 'SYB', name: 'Sidi Youssef Ben Ali' },
    { subCode: 'TAI', name: 'Taourirt' },
    { subCode: 'TAO', name: 'Taounate' },
    { subCode: 'TAR', name: 'Taroudant' },
    { subCode: 'TAT', name: 'Tata' },
    { subCode: 'TAZ', name: 'Taza' },
    { subCode: 'TET', name: 'T\u00e9touan' },
    { subCode: 'TIZ', name: 'Tiznit' },
    { subCode: 'TNG', name: 'Tanger-Assilah' },
    { subCode: 'TNT', name: 'Tan-Tan' },
    { subCode: 'ZAG', name: 'Zagora' },
  ],
  MZ: [
    { subCode: 'A', name: 'Niassa' },
    { subCode: 'B', name: 'Manica' },
    { subCode: 'G', name: 'Gaza' },
    { subCode: 'I', name: 'Inhambane' },
    { subCode: 'L', name: 'Maputo' },
    { subCode: 'MPM', name: 'Maputo' },
    { subCode: 'N', name: 'Nampula' },
    { subCode: 'P', name: 'Cabo Delgado' },
    { subCode: 'Q', name: 'Zamb\u00e9zia' },
    { subCode: 'S', name: 'Sofala' },
    { subCode: 'T', name: 'Tete' },
  ],
  NA: [
    { subCode: 'CA', name: 'Zambezi' },
    { subCode: 'ER', name: 'Erongo' },
    { subCode: 'HA', name: 'Hardap' },
    { subCode: 'KA', name: 'Karas' },
    { subCode: 'KE', name: 'Kavango East' },
    { subCode: 'KH', name: 'Khomas' },
    { subCode: 'KU', name: 'Kunene' },
    { subCode: 'KW', name: 'Kavango West' },
    { subCode: 'OD', name: 'Otjozondjupa' },
    { subCode: 'OH', name: 'Omaheke' },
    { subCode: 'ON', name: 'Oshana' },
    { subCode: 'OS', name: 'Omusati' },
    { subCode: 'OT', name: 'Oshikoto' },
    { subCode: 'OW', name: 'Ohangwena' },
  ],
  NR: [
    { subCode: '01', name: 'Aiwo' },
    { subCode: '02', name: 'Anabar' },
    { subCode: '03', name: 'Anetan' },
    { subCode: '04', name: 'Anibare' },
    { subCode: '05', name: 'Baiti' },
    { subCode: '06', name: 'Boe' },
    { subCode: '07', name: 'Buada' },
    { subCode: '08', name: 'Denigomodu' },
    { subCode: '09', name: 'Ewa' },
    { subCode: '10', name: 'Ijuw' },
    { subCode: '11', name: 'Meneng' },
    { subCode: '12', name: 'Nibok' },
    { subCode: '13', name: 'Uaboe' },
    { subCode: '14', name: 'Yaren' },
  ],
  NP: [
    { subCode: '1', name: 'Central' },
    { subCode: '2', name: 'Madhya Pashchimanchal' },
    { subCode: '3', name: 'Pashchimanchal' },
    { subCode: '4', name: 'Eastern' },
    { subCode: '5', name: 'Far Western' },
    { subCode: 'BA', name: 'Bagmati' },
    { subCode: 'BH', name: 'Bheri' },
    { subCode: 'DH', name: 'Dhawalagiri' },
    { subCode: 'GA', name: 'Gandaki' },
    { subCode: 'JA', name: 'Janakpur' },
    { subCode: 'KA', name: 'Karnali' },
    { subCode: 'KO', name: 'Kosi' },
    { subCode: 'LU', name: 'Lumbini' },
    { subCode: 'MA', name: 'Mahakali' },
    { subCode: 'ME', name: 'Mechi' },
    { subCode: 'NA', name: 'Narayani' },
    { subCode: 'RA', name: 'Rapti' },
    { subCode: 'SA', name: 'Sagarmatha' },
    { subCode: 'SE', name: 'Seti' },
  ],
  NL: [
    { subCode: 'AW', name: 'Aruba' },
    { subCode: 'BQ1', name: 'Bonaire' },
    { subCode: 'BQ2', name: 'Saba' },
    { subCode: 'BQ3', name: 'Sint Eustatius' },
    { subCode: 'CW', name: 'Cura\u00e7ao' },
    { subCode: 'DR', name: 'Drenthe' },
    { subCode: 'FL', name: 'Flevoland' },
    { subCode: 'FR', name: 'Frysl\u00e2n' },
    { subCode: 'GE', name: 'Gelderland' },
    { subCode: 'GR', name: 'Groningen' },
    { subCode: 'LI', name: 'Limburg' },
    { subCode: 'NB', name: 'Noord-Brabant' },
    { subCode: 'NH', name: 'Noord-Holland' },
    { subCode: 'OV', name: 'Overijssel' },
    { subCode: 'SX', name: 'Sint Maarten' },
    { subCode: 'UT', name: 'Utrecht' },
    { subCode: 'ZE', name: 'Zeeland' },
    { subCode: 'ZH', name: 'Zuid-Holland' },
  ],
  NC: [{ subCode: 'NC', name: 'New Caledonia' }],
  NZ: [
    { subCode: 'AUK', name: 'Auckland' },
    { subCode: 'BOP', name: 'Bay of Plenty' },
    { subCode: 'CAN', name: 'Canterbury' },
    { subCode: 'CIT', name: 'Chatham Islands Territory' },
    { subCode: 'GIS', name: 'Gisborne District' },
    { subCode: 'HKB', name: "Hawke's Bay" },
    { subCode: 'MBH', name: 'Marlborough District' },
    { subCode: 'MWT', name: 'Manawatu-Wanganui' },
    { subCode: 'N', name: 'North Island' },
    { subCode: 'NSN', name: 'Nelson City' },
    { subCode: 'NTL', name: 'Northland' },
    { subCode: 'OTA', name: 'Otago' },
    { subCode: 'S', name: 'South Island' },
    { subCode: 'STL', name: 'Southland' },
    { subCode: 'TAS', name: 'Tasman District' },
    { subCode: 'TKI', name: 'Taranaki' },
    { subCode: 'WGN', name: 'Wellington' },
    { subCode: 'WKO', name: 'Waikato' },
    { subCode: 'WTC', name: 'West Coast' },
  ],
  NI: [
    { subCode: 'AN', name: 'Atl\u00e1ntico Norte' },
    { subCode: 'AS', name: 'Atl\u00e1ntico Sur' },
    { subCode: 'BO', name: 'Boaco' },
    { subCode: 'CA', name: 'Carazo' },
    { subCode: 'CI', name: 'Chinandega' },
    { subCode: 'CO', name: 'Chontales' },
    { subCode: 'ES', name: 'Estel\u00ed' },
    { subCode: 'GR', name: 'Granada' },
    { subCode: 'JI', name: 'Jinotega' },
    { subCode: 'LE', name: 'Le\u00f3n' },
    { subCode: 'MD', name: 'Madriz' },
    { subCode: 'MN', name: 'Managua' },
    { subCode: 'MS', name: 'Masaya' },
    { subCode: 'MT', name: 'Matagalpa' },
    { subCode: 'NS', name: 'Nueva Segovia' },
    { subCode: 'RI', name: 'Rivas' },
    { subCode: 'SJ', name: 'R\u00edo San Juan' },
  ],
  NG: [
    { subCode: 'AB', name: 'Abia' },
    { subCode: 'AD', name: 'Adamawa' },
    { subCode: 'AK', name: 'Akwa Ibom' },
    { subCode: 'AN', name: 'Anambra' },
    { subCode: 'BA', name: 'Bauchi' },
    { subCode: 'BE', name: 'Benue' },
    { subCode: 'BO', name: 'Borno' },
    { subCode: 'BY', name: 'Bayelsa' },
    { subCode: 'CR', name: 'Cross River' },
    { subCode: 'DE', name: 'Delta' },
    { subCode: 'EB', name: 'Ebonyi' },
    { subCode: 'ED', name: 'Edo' },
    { subCode: 'EK', name: 'Ekiti' },
    { subCode: 'EN', name: 'Enugu' },
    { subCode: 'FC', name: 'Abuja Federal Capital Territory' },
    { subCode: 'GO', name: 'Gombe' },
    { subCode: 'IM', name: 'Imo' },
    { subCode: 'JI', name: 'Jigawa' },
    { subCode: 'KD', name: 'Kaduna' },
    { subCode: 'KE', name: 'Kebbi' },
    { subCode: 'KN', name: 'Kano' },
    { subCode: 'KO', name: 'Kogi' },
    { subCode: 'KT', name: 'Katsina' },
    { subCode: 'KW', name: 'Kwara' },
    { subCode: 'LA', name: 'Lagos' },
    { subCode: 'NA', name: 'Nasarawa' },
    { subCode: 'NI', name: 'Niger' },
    { subCode: 'OG', name: 'Ogun' },
    { subCode: 'ON', name: 'Ondo' },
    { subCode: 'OS', name: 'Osun' },
    { subCode: 'OY', name: 'Oyo' },
    { subCode: 'PL', name: 'Plateau' },
    { subCode: 'RI', name: 'Rivers' },
    { subCode: 'SO', name: 'Sokoto' },
    { subCode: 'TA', name: 'Taraba' },
    { subCode: 'YO', name: 'Yobe' },
    { subCode: 'ZA', name: 'Zamfara' },
  ],
  NE: [
    { subCode: '1', name: 'Agadez' },
    { subCode: '2', name: 'Diffa' },
    { subCode: '3', name: 'Dosso' },
    { subCode: '4', name: 'Maradi' },
    { subCode: '5', name: 'Tahoua' },
    { subCode: '6', name: 'Tillab\u00e9ri' },
    { subCode: '7', name: 'Zinder' },
    { subCode: '8', name: 'Niamey' },
  ],
  NU: [{ subCode: 'NU', name: 'Niue' }],
  NF: [{ subCode: 'NF', name: 'Norfolk Island' }],
  MP: [{ subCode: 'MP', name: 'Northern Mariana Islands' }],
  NO: [
    { subCode: '01', name: '\u00d8stfold' },
    { subCode: '02', name: 'Akershus' },
    { subCode: '03', name: 'Oslo' },
    { subCode: '04', name: 'Hedmark' },
    { subCode: '05', name: 'Oppland' },
    { subCode: '06', name: 'Buskerud' },
    { subCode: '07', name: 'Vestfold' },
    { subCode: '08', name: 'Telemark' },
    { subCode: '09', name: 'Aust-Agder' },
    { subCode: '10', name: 'Vest-Agder' },
    { subCode: '11', name: 'Rogaland' },
    { subCode: '12', name: 'Hordaland' },
    { subCode: '14', name: 'Sogn og Fjordane' },
    { subCode: '15', name: 'M\u00f8re og Romsdal' },
    { subCode: '16', name: 'S\u00f8r-Tr\u00f8ndelag' },
    { subCode: '17', name: 'Nord-Tr\u00f8ndelag' },
    { subCode: '18', name: 'Nordland' },
    { subCode: '19', name: 'Romsa' },
    { subCode: '20', name: 'Finnmark' },
    { subCode: '21', name: 'Svalbard' },
    { subCode: '22', name: 'Jan Mayen' },
  ],
  OM: [
    { subCode: 'BA', name: 'Al B\u0101\u0163inah' },
    { subCode: 'BU', name: 'Al Buraym\u012b' },
    { subCode: 'DA', name: 'Ad D\u0101khil\u012byah' },
    { subCode: 'MA', name: 'Masqa\u0163' },
    { subCode: 'MU', name: 'Musandam' },
    { subCode: 'SH', name: 'Ash Sharq\u012byah' },
    { subCode: 'WU', name: 'Al Wus\u0163\u00e1' },
    { subCode: 'ZA', name: 'Az\u0327 Z\u0327\u0101hirah' },
    { subCode: 'ZU', name: 'Z\u0327uf\u0101r' },
  ],
  PK: [
    { subCode: 'BA', name: 'Balochistan' },
    { subCode: 'GB', name: 'Gilgit-Baltist\u0101n' },
    { subCode: 'IS', name: 'Isl\u0101m\u0101b\u0101d' },
    { subCode: 'JK', name: 'Azad Kashmir' },
    { subCode: 'KP', name: 'Kha\u012bbar Pakht\u016bnkhw\u0101' },
    { subCode: 'PB', name: 'Panj\u0101b' },
    { subCode: 'SD', name: 'Sindh' },
    { subCode: 'TA', name: 'Federally Administered Tribal Areas' },
  ],
  PW: [
    { subCode: '002', name: 'Aimeliik' },
    { subCode: '004', name: 'Airai' },
    { subCode: '010', name: 'Angaur' },
    { subCode: '050', name: 'Hatobohei' },
    { subCode: '100', name: 'Kayangel' },
    { subCode: '150', name: 'Koror' },
    { subCode: '212', name: 'Melekeok' },
    { subCode: '214', name: 'Ngaraard' },
    { subCode: '218', name: 'Ngarchelong' },
    { subCode: '222', name: 'Ngardmau' },
    { subCode: '224', name: 'Ngatpang' },
    { subCode: '226', name: 'Ngchesar' },
    { subCode: '227', name: 'Ngeremlengui' },
    { subCode: '228', name: 'Ngiwal' },
    { subCode: '350', name: 'Peleliu' },
    { subCode: '370', name: 'Sonsorol' },
  ],
  PA: [
    { subCode: '10', name: 'Panam\u00e1 Oeste' },
    { subCode: '1', name: 'Bocas del Toro' },
    { subCode: '2', name: 'Cocl\u00e9' },
    { subCode: '3', name: 'Col\u00f3n' },
    { subCode: '4', name: 'Chiriqu\u00ed' },
    { subCode: '5', name: 'Dari\u00e9n' },
    { subCode: '6', name: 'Herrera' },
    { subCode: '7', name: 'Los Santos' },
    { subCode: '8', name: 'Panam\u00e1' },
    { subCode: '9', name: 'Veraguas' },
    { subCode: 'EM', name: 'Ember\u00e1' },
    { subCode: 'KY', name: 'Kuna Yala' },
    { subCode: 'NB', name: 'Ng\u00f6be-Bugl\u00e9' },
  ],
  PG: [
    { subCode: 'CPK', name: 'Chimbu' },
    { subCode: 'CPM', name: 'Central' },
    { subCode: 'EBR', name: 'East New Britain' },
    { subCode: 'EHG', name: 'Eastern Highlands' },
    { subCode: 'EPW', name: 'Enga' },
    { subCode: 'ESW', name: 'East Sepik' },
    { subCode: 'GPK', name: 'Gulf' },
    { subCode: 'HLA', name: 'Hela' },
    { subCode: 'JWK', name: 'Jiwaka' },
    { subCode: 'MBA', name: 'Milne Bay' },
    { subCode: 'MPL', name: 'Morobe' },
    { subCode: 'MPM', name: 'Madang' },
    { subCode: 'MRL', name: 'Manus' },
    { subCode: 'NCD', name: 'National Capital District' },
    { subCode: 'NIK', name: 'New Ireland' },
    { subCode: 'NPP', name: 'Northern' },
    { subCode: 'NSB', name: 'Bougainville' },
    { subCode: 'SAN', name: 'West Sepik' },
    { subCode: 'SHM', name: 'Southern Highlands' },
    { subCode: 'WBK', name: 'West New Britain' },
    { subCode: 'WHM', name: 'Western Highlands' },
    { subCode: 'WPD', name: 'Western' },
  ],
  PY: [
    { subCode: '10', name: 'Alto Paran\u00e1' },
    { subCode: '11', name: 'Central' },
    { subCode: '12', name: '\u00d1eembuc\u00fa' },
    { subCode: '13', name: 'Amambay' },
    { subCode: '14', name: 'Canindey\u00fa' },
    { subCode: '15', name: 'Presidente Hayes' },
    { subCode: '16', name: 'Alto Paraguay' },
    { subCode: '19', name: 'Boquer\u00f3n' },
    { subCode: '1', name: 'Concepci\u00f3n' },
    { subCode: '2', name: 'San Pedro' },
    { subCode: '3', name: 'Cordillera' },
    { subCode: '4', name: 'Guair\u00e1' },
    { subCode: '5', name: 'Caaguaz\u00fa' },
    { subCode: '6', name: 'Caazap\u00e1' },
    { subCode: '7', name: 'Itap\u00faa' },
    { subCode: '8', name: 'Misiones' },
    { subCode: '9', name: 'Paraguar\u00ed' },
    { subCode: 'ASU', name: 'Asunci\u00f3n' },
  ],
  PE: [
    { subCode: 'AMA', name: 'Amarumayu' },
    { subCode: 'ANC', name: 'Ancash' },
    { subCode: 'APU', name: 'Apur\u00edmac' },
    { subCode: 'ARE', name: 'Arequipa' },
    { subCode: 'AYA', name: 'Ayacucho' },
    { subCode: 'CAJ', name: 'Cajamarca' },
    { subCode: 'CAL', name: 'El Callao' },
    { subCode: 'CUS', name: 'Cusco' },
    { subCode: 'HUC', name: 'Hu\u00e1nuco' },
    { subCode: 'HUV', name: 'Huancavelica' },
    { subCode: 'ICA', name: 'Ica' },
    { subCode: 'JUN', name: 'Hunin' },
    { subCode: 'LAL', name: 'La Libertad' },
    { subCode: 'LAM', name: 'Lambayeque' },
    { subCode: 'LIM', name: 'Lima' },
    { subCode: 'LMA', name: 'Lima hatun llaqta' },
    { subCode: 'LOR', name: 'Loreto' },
    { subCode: 'MDD', name: 'Madre de Dios' },
    { subCode: 'MOQ', name: 'Moquegua' },
    { subCode: 'PAS', name: 'Pasco' },
    { subCode: 'PIU', name: 'Piura' },
    { subCode: 'PUN', name: 'Puno' },
    { subCode: 'SAM', name: 'San Martin' },
    { subCode: 'TAC', name: 'Tacna' },
    { subCode: 'TUM', name: 'Tumbes' },
    { subCode: 'UCA', name: 'Ucayali' },
  ],
  PH: [
    { subCode: '00', name: 'National Capital Region' },
    { subCode: '01', name: 'Ilocos' },
    { subCode: '02', name: 'Cagayan Valley' },
    { subCode: '03', name: 'Central Luzon' },
    { subCode: '05', name: 'Bicol' },
    { subCode: '06', name: 'Rehiyon ng Kanlurang Bisaya' },
    { subCode: '07', name: 'Central Visayas' },
    { subCode: '08', name: 'Eastern Visayas' },
    { subCode: '09', name: 'Rehiyon ng Tangway ng Sambuwangga' },
    { subCode: '10', name: 'Northern Mindanao' },
    { subCode: '11', name: 'Davao' },
    { subCode: '12', name: 'Rehiyon ng Soccsksargen' },
    { subCode: '13', name: 'Caraga' },
    { subCode: '14', name: 'Autonomous Region in Muslim Mindanao' },
    { subCode: '15', name: 'Cordillera Administrative Region' },
    { subCode: '40', name: 'Calabarzon' },
    { subCode: '41', name: 'Mimaropa' },
    { subCode: 'ABR', name: 'Abra' },
    { subCode: 'AGN', name: 'Agusan del Norte' },
    { subCode: 'AGS', name: 'Agusan del Sur' },
    { subCode: 'AKL', name: 'Aklan' },
    { subCode: 'ALB', name: 'Albay' },
    { subCode: 'ANT', name: 'Antike' },
    { subCode: 'APA', name: 'Apayao' },
    { subCode: 'AUR', name: 'Aurora' },
    { subCode: 'BAN', name: 'Bataan' },
    { subCode: 'BAS', name: 'Basilan' },
    { subCode: 'BEN', name: 'Benget' },
    { subCode: 'BIL', name: 'Biliran' },
    { subCode: 'BOH', name: 'Bohol' },
    { subCode: 'BTG', name: 'Batangas' },
    { subCode: 'BTN', name: 'Batanes' },
    { subCode: 'BUK', name: 'Bukidnon' },
    { subCode: 'BUL', name: 'Bulacan' },
    { subCode: 'CAG', name: 'Cagayan' },
    { subCode: 'CAM', name: 'Camiguin' },
    { subCode: 'CAN', name: 'Camarines Norte' },
    { subCode: 'CAP', name: 'Capiz' },
    { subCode: 'CAS', name: 'Camarines Sur' },
    { subCode: 'CAT', name: 'Catanduanes' },
    { subCode: 'CAV', name: 'Cavite' },
    { subCode: 'CEB', name: 'Cebu' },
    { subCode: 'COM', name: 'Compostela Valley' },
    { subCode: 'DAO', name: 'Davao Oriental' },
    { subCode: 'DAS', name: 'Davao del Sur' },
    { subCode: 'DAV', name: 'Davao del Norte' },
    { subCode: 'DIN', name: 'Dinagat Islands' },
    { subCode: 'EAS', name: 'Eastern Samar' },
    { subCode: 'GUI', name: 'Gimaras' },
    { subCode: 'IFU', name: 'Ifugao' },
    { subCode: 'ILI', name: 'Iloilo' },
    { subCode: 'ILN', name: 'Hilagang Iloko' },
    { subCode: 'ILS', name: 'Ilocos Sur' },
    { subCode: 'ISA', name: 'Isabela' },
    { subCode: 'KAL', name: 'Kalinga' },
    { subCode: 'LAG', name: 'Laguna' },
    { subCode: 'LAN', name: 'Hilagang Lanaw' },
    { subCode: 'LAS', name: 'Lanao del Sur' },
    { subCode: 'LEY', name: 'Leyte' },
    { subCode: 'LUN', name: 'La Union' },
    { subCode: 'MAD', name: 'Marinduke' },
    { subCode: 'MAG', name: 'Magindanaw' },
    { subCode: 'MAS', name: 'Masbate' },
    { subCode: 'MDC', name: 'Kanlurang Mindoro' },
    { subCode: 'MDR', name: 'Mindoro Oriental' },
    { subCode: 'MOU', name: 'Lalawigang Bulubundukin' },
    { subCode: 'MSC', name: 'Kanlurang Misamis' },
    { subCode: 'MSR', name: 'Misamis Oriental' },
    { subCode: 'NCO', name: 'Cotabato' },
    { subCode: 'NEC', name: 'Kanlurang Negros' },
    { subCode: 'NER', name: 'Negros Oriental' },
    { subCode: 'NSA', name: 'Hilagang Samar' },
    { subCode: 'NUE', name: 'Nueva Ecija' },
    { subCode: 'NUV', name: 'Nueva Vizcaya' },
    { subCode: 'PAM', name: 'Pampanga' },
    { subCode: 'PAN', name: 'Pangasinan' },
    { subCode: 'PLW', name: 'Palawan' },
    { subCode: 'QUE', name: 'Keson' },
    { subCode: 'QUI', name: 'Kirino' },
    { subCode: 'RIZ', name: 'Risal' },
    { subCode: 'ROM', name: 'Romblon' },
    { subCode: 'SAR', name: 'Sarangani' },
    { subCode: 'SCO', name: 'South Cotabato' },
    { subCode: 'SIG', name: 'Sikihor' },
    { subCode: 'SLE', name: 'Katimogang Leyte' },
    { subCode: 'SLU', name: 'Sulu' },
    { subCode: 'SOR', name: 'Sorsogon' },
    { subCode: 'SUK', name: 'Sultan Kudarat' },
    { subCode: 'SUN', name: 'Hilagang Surigaw' },
    { subCode: 'SUR', name: 'Surigao del Sur' },
    { subCode: 'TAR', name: 'Tarlac' },
    { subCode: 'TAW', name: 'Tawi-Tawi' },
    { subCode: 'WSA', name: 'Samar' },
    { subCode: 'ZAN', name: 'Hilagang Sambuwangga' },
    { subCode: 'ZAS', name: 'Timog Sambuwangga' },
    { subCode: 'ZMB', name: 'Sambales' },
    { subCode: 'ZSI', name: 'Sambuwangga Sibugay' },
  ],
  PN: [{ subCode: 'PN', name: 'Pitcairn' }],
  PL: [
    { subCode: 'DS', name: 'Dolno\u015bl\u0105skie' },
    { subCode: 'KP', name: 'Kujawsko-pomorskie' },
    { subCode: 'LB', name: 'Lubuskie' },
    { subCode: 'LD', name: '\u0141\u00f3dzkie' },
    { subCode: 'LU', name: 'Lubelskie' },
    { subCode: 'MA', name: 'Ma\u0142opolskie' },
    { subCode: 'MZ', name: 'Mazowieckie' },
    { subCode: 'OP', name: 'Opolskie' },
    { subCode: 'PD', name: 'Podlaskie' },
    { subCode: 'PK', name: 'Podkarpackie' },
    { subCode: 'PM', name: 'Pomorskie' },
    { subCode: 'SK', name: '\u015awi\u0119tokrzyskie' },
    { subCode: 'SL', name: '\u015al\u0105skie' },
    { subCode: 'WN', name: 'Warmi\u0144sko-mazurskie' },
    { subCode: 'WP', name: 'Wielkopolskie' },
    { subCode: 'ZP', name: 'Zachodniopomorskie' },
  ],
  PT: [
    { subCode: '01', name: 'Aveiro' },
    { subCode: '02', name: 'Beja' },
    { subCode: '03', name: 'Braga' },
    { subCode: '04', name: 'Bragan\u00e7a' },
    { subCode: '05', name: 'Castelo Branco' },
    { subCode: '06', name: 'Coimbra' },
    { subCode: '07', name: '\u00c9vora' },
    { subCode: '08', name: 'Faro' },
    { subCode: '09', name: 'Guarda' },
    { subCode: '10', name: 'Leiria' },
    { subCode: '11', name: 'Lisboa' },
    { subCode: '12', name: 'Portalegre' },
    { subCode: '13', name: 'Porto' },
    { subCode: '14', name: 'Santar\u00e9m' },
    { subCode: '15', name: 'Set\u00fabal' },
    { subCode: '16', name: 'Viana do Castelo' },
    { subCode: '17', name: 'Vila Real' },
    { subCode: '18', name: 'Viseu' },
    { subCode: '20', name: 'Regi\u00e3o Aut\u00f3noma dos A\u00e7ores' },
    { subCode: '30', name: 'Regi\u00e3o Aut\u00f3noma da Madeira' },
  ],
  PR: [{ subCode: 'PR', name: 'Puerto Rico' }],
  QA: [
    { subCode: 'DA', name: 'Ad Daw\u1e29ah' },
    { subCode: 'KH', name: 'Al Khawr wa adh Dhakh\u012brah' },
    { subCode: 'MS', name: 'Ash Sham\u0101l' },
    { subCode: 'RA', name: 'Ar Rayy\u0101n' },
    { subCode: 'US', name: 'Umm \u015eal\u0101l' },
    { subCode: 'WA', name: 'Al Wakrah' },
    { subCode: 'ZA', name: 'Az\u0327 Z\u0327a\u2018\u0101yin' },
  ],
  RE: [{ subCode: 'RE', name: 'Reunion' }],
  RO: [
    { subCode: 'AB', name: 'Alba' },
    { subCode: 'AG', name: 'Arge\u015f' },
    { subCode: 'AR', name: 'Arad' },
    { subCode: 'B', name: 'Bucure\u015fti' },
    { subCode: 'BC', name: 'Bac\u0103u' },
    { subCode: 'BH', name: 'Bihor' },
    { subCode: 'BN', name: 'Bistri\u0163a-N\u0103s\u0103ud' },
    { subCode: 'BR', name: 'Br\u0103ila' },
    { subCode: 'BT', name: 'Boto\u015fani' },
    { subCode: 'BV', name: 'Bra\u015fov' },
    { subCode: 'BZ', name: 'Buz\u0103u' },
    { subCode: 'CJ', name: 'Cluj' },
    { subCode: 'CL', name: 'C\u0103l\u0103ra\u015fi' },
    { subCode: 'CS', name: 'Cara\u015f-Severin' },
    { subCode: 'CT', name: 'Constan\u0163a' },
    { subCode: 'CV', name: 'Covasna' },
    { subCode: 'DB', name: 'D\u00e2mbovi\u0163a' },
    { subCode: 'DJ', name: 'Dolj' },
    { subCode: 'GJ', name: 'Gorj' },
    { subCode: 'GL', name: 'Gala\u0163i' },
    { subCode: 'GR', name: 'Giurgiu' },
    { subCode: 'HD', name: 'Hunedoara' },
    { subCode: 'HR', name: 'Harghita' },
    { subCode: 'IF', name: 'Ilfov' },
    { subCode: 'IL', name: 'Ialomi\u0163a' },
    { subCode: 'IS', name: 'Ia\u015fi' },
    { subCode: 'MH', name: 'Mehedin\u0163i' },
    { subCode: 'MM', name: 'Maramure\u015f' },
    { subCode: 'MS', name: 'Mure\u015f' },
    { subCode: 'NT', name: 'Neam\u0163' },
    { subCode: 'OT', name: 'Olt' },
    { subCode: 'PH', name: 'Prahova' },
    { subCode: 'SB', name: 'Sibiu' },
    { subCode: 'SJ', name: 'S\u0103laj' },
    { subCode: 'SM', name: 'Satu Mare' },
    { subCode: 'SV', name: 'Suceava' },
    { subCode: 'TL', name: 'Tulcea' },
    { subCode: 'TM', name: 'Timi\u015f' },
    { subCode: 'TR', name: 'Teleorman' },
    { subCode: 'VL', name: 'V\u00e2lcea' },
    { subCode: 'VN', name: 'Vrancea' },
    { subCode: 'VS', name: 'Vaslui' },
  ],
  RW: [
    { subCode: '01', name: 'Ville de Kigal' },
    { subCode: '02', name: 'Est' },
    { subCode: '03', name: 'Nord' },
    { subCode: '04', name: 'Ouest' },
    { subCode: '05', name: 'Sud' },
  ],
  SH: [
    { subCode: 'AC', name: 'Ascension' },
    { subCode: 'HL', name: 'Saint Helena' },
    { subCode: 'TA', name: 'Tristan da Cunha' },
  ],
  KN: [
    { subCode: '01', name: 'Christ Church Nichola Town' },
    { subCode: '02', name: 'Saint Anne Sandy Point' },
    { subCode: '03', name: 'Saint George Basseterre' },
    { subCode: '04', name: 'Saint George Gingerland' },
    { subCode: '05', name: 'Saint James Windward' },
    { subCode: '06', name: 'Saint John Capisterre' },
    { subCode: '07', name: 'Saint John Figtree' },
    { subCode: '08', name: 'Saint Mary Cayon' },
    { subCode: '09', name: 'Saint Paul Capisterre' },
    { subCode: '10', name: 'Saint Paul Charlestown' },
    { subCode: '11', name: 'Saint Peter Basseterre' },
    { subCode: '12', name: 'Saint Thomas Lowland' },
    { subCode: '13', name: 'Saint Thomas Middle Island' },
    { subCode: '15', name: 'Trinity Palmetto Point' },
    { subCode: 'K', name: 'Saint Kitts' },
    { subCode: 'N', name: 'Nevis' },
  ],
  LC: [
    { subCode: '01', name: 'Anse la Raye' },
    { subCode: '02', name: 'Castries' },
    { subCode: '03', name: 'Choiseul' },
    { subCode: '05', name: 'Dennery' },
    { subCode: '06', name: 'Gros Islet' },
    { subCode: '07', name: 'Laborie' },
    { subCode: '08', name: 'Micoud' },
    { subCode: '10', name: 'Soufri\u00e8re' },
    { subCode: '11', name: 'Vieux Fort' },
    { subCode: '12', name: 'Canaries' },
  ],
  VC: [
    { subCode: '01', name: 'Charlotte' },
    { subCode: '02', name: 'Saint Andrew' },
    { subCode: '03', name: 'Saint David' },
    { subCode: '04', name: 'Saint George' },
    { subCode: '05', name: 'Saint Patrick' },
    { subCode: '06', name: 'Grenadines' },
  ],
  WS: [
    { subCode: 'AA', name: "A'ana" },
    { subCode: 'AL', name: 'Aiga-i-le-Tai' },
    { subCode: 'AT', name: 'Atua' },
    { subCode: 'FA', name: "Fa'asaleleaga" },
    { subCode: 'GE', name: "Gaga'emauga" },
    { subCode: 'GI', name: 'Gagaifomauga' },
    { subCode: 'PA', name: 'Palauli' },
    { subCode: 'SA', name: "Satupa'itea" },
    { subCode: 'TU', name: 'Tuamasaga' },
    { subCode: 'VF', name: "Va'a-o-Fonoti" },
    { subCode: 'VS', name: 'Vaisigano' },
  ],
  SM: [
    { subCode: '01', name: 'Acquaviva' },
    { subCode: '02', name: 'Chiesanuova' },
    { subCode: '03', name: 'Domagnano' },
    { subCode: '04', name: 'Faetano' },
    { subCode: '05', name: 'Fiorentino' },
    { subCode: '06', name: 'Borgo Maggiore' },
    { subCode: '07', name: 'San Marino' },
    { subCode: '08', name: 'Montegiardino' },
    { subCode: '09', name: 'Serravalle' },
  ],
  ST: [
    { subCode: 'P', name: 'Pr\u00edncipe' },
    { subCode: 'S', name: 'S\u00e3o Tom\u00e9' },
  ],
  SA: [
    { subCode: '01', name: 'Ar Riy\u0101\u1e11' },
    { subCode: '02', name: 'Makkah al Mukarramah' },
    { subCode: '03', name: 'Al Mad\u012bnah al Munawwarah' },
    { subCode: '04', name: 'Ash Sharq\u012byah' },
    { subCode: '05', name: 'Al Qa\u015f\u012bm' },
    { subCode: '06', name: "\u1e28\u0101'il" },
    { subCode: '07', name: 'Tab\u016bk' },
    { subCode: '08', name: 'Al \u1e28ud\u016bd ash Sham\u0101l\u012byah' },
    { subCode: '09', name: 'J\u0101z\u0101n' },
    { subCode: '10', name: 'Najr\u0101n' },
    { subCode: '11', name: 'Al B\u0101\u1e29ah' },
    { subCode: '12', name: 'Al Jawf' },
    { subCode: '14', name: "'As\u012br" },
  ],
  SN: [
    { subCode: 'DB', name: 'Diourbel' },
    { subCode: 'DK', name: 'Dakar' },
    { subCode: 'FK', name: 'Fatick' },
    { subCode: 'KA', name: 'Kaffrine' },
    { subCode: 'KD', name: 'Kolda' },
    { subCode: 'KE', name: 'K\u00e9dougou' },
    { subCode: 'KL', name: 'Kaolack' },
    { subCode: 'LG', name: 'Louga' },
    { subCode: 'MT', name: 'Matam' },
    { subCode: 'SE', name: 'S\u00e9dhiou' },
    { subCode: 'SL', name: 'Saint-Louis' },
    { subCode: 'TC', name: 'Tambacounda' },
    { subCode: 'TH', name: 'Thi\u00e8s' },
    { subCode: 'ZG', name: 'Ziguinchor' },
  ],
  RS: [
    { subCode: '00', name: 'Beograd' },
    { subCode: '01', name: 'Severnoba\u010dki okrug' },
    { subCode: '02', name: 'Srednjebanatski okrug' },
    { subCode: '03', name: 'Severnobanatski okrug' },
    { subCode: '04', name: 'Ju\u017enobanatski okrug' },
    { subCode: '05', name: 'Zapadnoba\u010dki okrug' },
    { subCode: '06', name: 'Ju\u017enoba\u010dki okrug' },
    { subCode: '07', name: 'Sremski okrug' },
    { subCode: '08', name: 'Ma\u010dvanski okrug' },
    { subCode: '09', name: 'Kolubarski okrug' },
    { subCode: '10', name: 'Podunavski okrug' },
    { subCode: '11', name: 'Brani\u010devski okrug' },
    { subCode: '12', name: '\u0160umadijski okrug' },
    { subCode: '13', name: 'Pomoravski okrug' },
    { subCode: '14', name: 'Borski okrug' },
    { subCode: '15', name: 'Zaje\u010darski okrug' },
    { subCode: '16', name: 'Zlatiborski okrug' },
    { subCode: '17', name: 'Moravi\u010dki okrug' },
    { subCode: '18', name: 'Ra\u0161ki okrug' },
    { subCode: '19', name: 'Rasinski okrug' },
    { subCode: '20', name: 'Ni\u0161avski okrug' },
    { subCode: '21', name: 'Topli\u010dki okrug' },
    { subCode: '22', name: 'Pirotski okrug' },
    { subCode: '23', name: 'Jablani\u010dki okrug' },
    { subCode: '24', name: 'P\u010dinjski okrug' },
    { subCode: '25', name: 'Kosovski okrug' },
    { subCode: '26', name: 'Pe\u0107ki okrug' },
    { subCode: '27', name: 'Prizrenski okrug' },
    { subCode: '28', name: 'Kosovsko-Mitrova\u010dki okrug' },
    { subCode: '29', name: 'Kosovsko-Pomoravski okrug' },
    { subCode: 'KM', name: 'Kosovo-Metohija' },
    { subCode: 'VO', name: 'Vojvodina' },
  ],
  SC: [
    { subCode: '01', name: 'Anse aux Pins' },
    { subCode: '02', name: 'Ans Bwalo' },
    { subCode: '03', name: 'Anse Etoile' },
    { subCode: '04', name: 'Au Cap' },
    { subCode: '05', name: 'Anse Royale' },
    { subCode: '06', name: 'Baie Lazare' },
    { subCode: '07', name: 'Baie Sainte-Anne' },
    { subCode: '08', name: 'Beau Vallon' },
    { subCode: '09', name: 'Bel Air' },
    { subCode: '10', name: 'Bel Ombre' },
    { subCode: '11', name: 'Cascade' },
    { subCode: '12', name: 'Glacis' },
    { subCode: '13', name: "Grand'Anse Mah\u00e9" },
    { subCode: '14', name: "Grand'Anse Praslin" },
    { subCode: '15', name: 'Ladig' },
    { subCode: '16', name: 'English River' },
    { subCode: '17', name: 'Mon Bikston' },
    { subCode: '18', name: 'Mon Fleri' },
    { subCode: '19', name: 'Plaisance' },
    { subCode: '20', name: 'Pointe La Rue' },
    { subCode: '21', name: 'Porglo' },
    { subCode: '22', name: 'Saint Louis' },
    { subCode: '23', name: 'Takamaka' },
    { subCode: '24', name: 'Lemamel' },
    { subCode: '25', name: 'Roche Ca\u00efman' },
  ],
  GS: [{ subCode: 'GS', name: 'S.Georgia & S.Sandwich Islands' }],
  SG: [
    { subCode: '01', name: 'Central Singapore' },
    { subCode: '02', name: 'North East' },
    { subCode: '03', name: 'North West' },
    { subCode: '04', name: 'South East' },
    { subCode: '05', name: 'South West' },
  ],
  SK: [
    { subCode: 'BC', name: 'Banskobystrick\u00fd kraj' },
    { subCode: 'BL', name: 'Bratislavsk\u00fd kraj' },
    { subCode: 'KI', name: 'Ko\u0161ick\u00fd kraj' },
    { subCode: 'NI', name: 'Nitriansky kraj' },
    { subCode: 'PV', name: 'Pre\u0161ovsk\u00fd kraj' },
    { subCode: 'TA', name: 'Trnavsk\u00fd kraj' },
    { subCode: 'TC', name: 'Tren\u010diansky kraj' },
    { subCode: 'ZI', name: '\u017dilinsk\u00fd kraj' },
  ],
  SI: [
    { subCode: '001', name: 'Ajdov\u0161\u010dina' },
    { subCode: '002', name: 'Beltinci' },
    { subCode: '003', name: 'Bled' },
    { subCode: '004', name: 'Bohinj' },
    { subCode: '005', name: 'Borovnica' },
    { subCode: '006', name: 'Bovec' },
    { subCode: '007', name: 'Brda' },
    { subCode: '008', name: 'Brezovica' },
    { subCode: '009', name: 'Bre\u017eice' },
    { subCode: '010', name: 'Ti\u0161ina' },
    { subCode: '011', name: 'Celje' },
    { subCode: '012', name: 'Cerklje na Gorenjskem' },
    { subCode: '013', name: 'Cerknica' },
    { subCode: '014', name: 'Cerkno' },
    { subCode: '015', name: '\u010cren\u0161ovci' },
    { subCode: '016', name: '\u010crna na Koro\u0161kem' },
    { subCode: '017', name: '\u010crnomelj' },
    { subCode: '018', name: 'Destrnik' },
    { subCode: '019', name: 'Diva\u010da' },
    { subCode: '020', name: 'Dobrepolje' },
    { subCode: '021', name: 'Dobrova-Polhov Gradec' },
    { subCode: '022', name: 'Dol pri Ljubljani' },
    { subCode: '023', name: 'Dom\u017eale' },
    { subCode: '024', name: 'Dornava' },
    { subCode: '025', name: 'Dravograd' },
    { subCode: '026', name: 'Duplek' },
    { subCode: '027', name: 'Gorenja vas-Poljane' },
    { subCode: '028', name: 'Gori\u0161nica' },
    { subCode: '029', name: 'Gornja Radgona' },
    { subCode: '030', name: 'Gornji Grad' },
    { subCode: '031', name: 'Gornji Petrovci' },
    { subCode: '032', name: 'Grosuplje' },
    { subCode: '033', name: '\u0160alovci' },
    { subCode: '034', name: 'Hrastnik' },
    { subCode: '035', name: 'Hrpelje-Kozina' },
    { subCode: '036', name: 'Idrija' },
    { subCode: '037', name: 'Ig' },
    { subCode: '038', name: 'Ilirska Bistrica' },
    { subCode: '039', name: 'Ivan\u010dna Gorica' },
    { subCode: '040', name: 'Izola' },
    { subCode: '041', name: 'Jesenice' },
    { subCode: '042', name: 'Jur\u0161inci' },
    { subCode: '043', name: 'Kamnik' },
    { subCode: '044', name: 'Kanal' },
    { subCode: '045', name: 'Kidri\u010devo' },
    { subCode: '046', name: 'Kobarid' },
    { subCode: '047', name: 'Kobilje' },
    { subCode: '048', name: 'Ko\u010devje' },
    { subCode: '049', name: 'Komen' },
    { subCode: '050', name: 'Koper' },
    { subCode: '051', name: 'Kozje' },
    { subCode: '052', name: 'Kranj' },
    { subCode: '053', name: 'Kranjska Gora' },
    { subCode: '054', name: 'Kr\u0161ko' },
    { subCode: '055', name: 'Kungota' },
    { subCode: '056', name: 'Kuzma' },
    { subCode: '057', name: 'La\u0161ko' },
    { subCode: '058', name: 'Lenart' },
    { subCode: '059', name: 'Lendava' },
    { subCode: '060', name: 'Litija' },
    { subCode: '061', name: 'Ljubljana' },
    { subCode: '062', name: 'Ljubno' },
    { subCode: '063', name: 'Ljutomer' },
    { subCode: '064', name: 'Logatec' },
    { subCode: '065', name: 'Lo\u0161ka Dolina' },
    { subCode: '066', name: 'Lo\u0161ki Potok' },
    { subCode: '067', name: 'Lu\u010de' },
    { subCode: '068', name: 'Lukovica' },
    { subCode: '069', name: 'Maj\u0161perk' },
    { subCode: '070', name: 'Maribor' },
    { subCode: '071', name: 'Medvode' },
    { subCode: '072', name: 'Menge\u0161' },
    { subCode: '073', name: 'Metlika' },
    { subCode: '074', name: 'Me\u017eica' },
    { subCode: '075', name: 'Miren-Kostanjevica' },
    { subCode: '076', name: 'Mislinja' },
    { subCode: '077', name: 'Morav\u010de' },
    { subCode: '078', name: 'Moravske Toplice' },
    { subCode: '079', name: 'Mozirje' },
    { subCode: '080', name: 'Murska Sobota' },
    { subCode: '081', name: 'Muta' },
    { subCode: '082', name: 'Naklo' },
    { subCode: '083', name: 'Nazarje' },
    { subCode: '084', name: 'Nova Gorica' },
    { subCode: '085', name: 'Novo Mesto' },
    { subCode: '086', name: 'Odranci' },
    { subCode: '087', name: 'Ormo\u017e' },
    { subCode: '088', name: 'Osilnica' },
    { subCode: '089', name: 'Pesnica' },
    { subCode: '090', name: 'Piran' },
    { subCode: '091', name: 'Pivka' },
    { subCode: '092', name: 'Pod\u010detrtek' },
    { subCode: '093', name: 'Podvelka' },
    { subCode: '094', name: 'Postojna' },
    { subCode: '095', name: 'Preddvor' },
    { subCode: '096', name: 'Ptuj' },
    { subCode: '097', name: 'Puconci' },
    { subCode: '098', name: 'Ra\u010de-Fram' },
    { subCode: '099', name: 'Rade\u010de' },
    { subCode: '100', name: 'Radenci' },
    { subCode: '101', name: 'Radlje ob Dravi' },
    { subCode: '102', name: 'Radovljica' },
    { subCode: '103', name: 'Ravne na Koro\u0161kem' },
    { subCode: '104', name: 'Ribnica' },
    { subCode: '105', name: 'Roga\u0161ovci' },
    { subCode: '106', name: 'Roga\u0161ka Slatina' },
    { subCode: '107', name: 'Rogatec' },
    { subCode: '108', name: 'Ru\u0161e' },
    { subCode: '109', name: 'Semi\u010d' },
    { subCode: '110', name: 'Sevnica' },
    { subCode: '111', name: 'Se\u017eana' },
    { subCode: '112', name: 'Slovenj Gradec' },
    { subCode: '113', name: 'Slovenska Bistrica' },
    { subCode: '114', name: 'Slovenske Konjice' },
    { subCode: '115', name: 'Star\u0161e' },
    { subCode: '116', name: 'Sveti Jurij' },
    { subCode: '117', name: '\u0160en\u010dur' },
    { subCode: '118', name: '\u0160entilj' },
    { subCode: '119', name: '\u0160entjernej' },
    { subCode: '120', name: '\u0160entjur' },
    { subCode: '121', name: '\u0160kocjan' },
    { subCode: '122', name: '\u0160kofja Loka' },
    { subCode: '123', name: '\u0160kofljica' },
    { subCode: '124', name: '\u0160marje pri Jel\u0161ah' },
    { subCode: '125', name: '\u0160martno ob Paki' },
    { subCode: '126', name: '\u0160o\u0161tanj' },
    { subCode: '127', name: '\u0160tore' },
    { subCode: '128', name: 'Tolmin' },
    { subCode: '129', name: 'Trbovlje' },
    { subCode: '130', name: 'Trebnje' },
    { subCode: '131', name: 'Tr\u017ei\u010d' },
    { subCode: '132', name: 'Turni\u0161\u010de' },
    { subCode: '133', name: 'Velenje' },
    { subCode: '134', name: 'Velike La\u0161\u010de' },
    { subCode: '135', name: 'Videm' },
    { subCode: '136', name: 'Vipava' },
    { subCode: '137', name: 'Vitanje' },
    { subCode: '138', name: 'Vodice' },
    { subCode: '139', name: 'Vojnik' },
    { subCode: '140', name: 'Vrhnika' },
    { subCode: '141', name: 'Vuzenica' },
    { subCode: '142', name: 'Zagorje ob Savi' },
    { subCode: '143', name: 'Zavr\u010d' },
    { subCode: '144', name: 'Zre\u010de' },
    { subCode: '146', name: '\u017delezniki' },
    { subCode: '147', name: '\u017diri' },
    { subCode: '148', name: 'Benedikt' },
    { subCode: '149', name: 'Bistrica ob Sotli' },
    { subCode: '150', name: 'Bloke' },
    { subCode: '151', name: 'Braslov\u010de' },
    { subCode: '152', name: 'Cankova' },
    { subCode: '153', name: 'Cerkvenjak' },
    { subCode: '154', name: 'Dobje' },
    { subCode: '155', name: 'Dobrna' },
    { subCode: '156', name: 'Dobrovnik' },
    { subCode: '157', name: 'Dolenjske Toplice' },
    { subCode: '158', name: 'Grad' },
    { subCode: '159', name: 'Hajdina' },
    { subCode: '160', name: 'Ho\u010de-Slivnica' },
    { subCode: '161', name: 'Hodo\u0161' },
    { subCode: '162', name: 'Horjul' },
    { subCode: '163', name: 'Jezersko' },
    { subCode: '164', name: 'Komenda' },
    { subCode: '165', name: 'Kostel' },
    { subCode: '166', name: 'Kri\u017eevci' },
    { subCode: '167', name: 'Lovrenc na Pohorju' },
    { subCode: '168', name: 'Markovci' },
    { subCode: '169', name: 'Miklav\u017e na Dravskem Polju' },
    { subCode: '170', name: 'Mirna Pe\u010d' },
    { subCode: '171', name: 'Oplotnica' },
    { subCode: '172', name: 'Podlehnik' },
    { subCode: '173', name: 'Polzela' },
    { subCode: '174', name: 'Prebold' },
    { subCode: '175', name: 'Prevalje' },
    { subCode: '176', name: 'Razkri\u017eje' },
    { subCode: '177', name: 'Ribnica na Pohorju' },
    { subCode: '178', name: 'Selnica ob Dravi' },
    { subCode: '179', name: 'Sodra\u017eica' },
    { subCode: '180', name: 'Sol\u010dava' },
    { subCode: '181', name: 'Sveta Ana' },
    { subCode: '182', name: 'Sveti Andra\u017e v Slovenskih Goricah' },
    { subCode: '183', name: '\u0160empeter-Vrtojba' },
    { subCode: '184', name: 'Tabor' },
    { subCode: '185', name: 'Trnovska Vas' },
    { subCode: '186', name: 'Trzin' },
    { subCode: '187', name: 'Velika Polana' },
    { subCode: '188', name: 'Ver\u017eej' },
    { subCode: '189', name: 'Vransko' },
    { subCode: '190', name: '\u017dalec' },
    { subCode: '191', name: '\u017detale' },
    { subCode: '192', name: '\u017dirovnica' },
    { subCode: '193', name: '\u017du\u017eemberk' },
    { subCode: '194', name: '\u0160martno pri Litiji' },
    { subCode: '195', name: 'Apa\u010de' },
    { subCode: '196', name: 'Cirkulane' },
    { subCode: '197', name: 'Kosanjevica na Krki' },
    { subCode: '198', name: 'Makole' },
    { subCode: '199', name: 'Mokronog-Trebelno' },
    { subCode: '200', name: 'Polj\u010dane' },
    { subCode: '201', name: 'Ren\u010de-Vogrsko' },
    { subCode: '202', name: 'Sredi\u0161\u010de ob Dravi' },
    { subCode: '203', name: 'Stra\u017ea' },
    { subCode: '204', name: 'Sveta Trojica v Slovenskih Goricah' },
    { subCode: '205', name: 'Sveti Toma\u017e' },
    { subCode: '206', name: '\u0160marje\u0161ke Toplice' },
    { subCode: '207', name: 'Gorje' },
    { subCode: '208', name: 'Log-Dragomer' },
    { subCode: '209', name: 'Re\u010dica ob Savinji' },
    { subCode: '210', name: 'Sveti Jurij v Slovenskih Goricah' },
    { subCode: '211', name: '\u0160entrupert' },
    { subCode: '212', name: 'Mirna' },
  ],
  SB: [
    { subCode: 'CE', name: 'Central' },
    { subCode: 'CH', name: 'Choiseul' },
    { subCode: 'CT', name: 'Capital Territory' },
    { subCode: 'GU', name: 'Guadalcanal' },
    { subCode: 'IS', name: 'Isabel' },
    { subCode: 'MK', name: 'Makira-Ulawa' },
    { subCode: 'ML', name: 'Malaita' },
    { subCode: 'RB', name: 'Rennell and Bellona' },
    { subCode: 'TE', name: 'Temotu' },
    { subCode: 'WE', name: 'Western' },
  ],
  SO: [
    { subCode: 'AW', name: 'Awdal' },
    { subCode: 'BK', name: 'Bakool' },
    { subCode: 'BN', name: 'Banaadir' },
    { subCode: 'BR', name: 'Bari' },
    { subCode: 'BY', name: 'Bay' },
    { subCode: 'GA', name: 'Galguduud' },
    { subCode: 'GE', name: 'Gedo' },
    { subCode: 'HI', name: 'Hiiraan' },
    { subCode: 'JD', name: 'Jubbada Dhexe' },
    { subCode: 'JH', name: 'Jubbada Hoose' },
    { subCode: 'MU', name: 'Mudug' },
    { subCode: 'NU', name: 'Nugaal' },
    { subCode: 'SA', name: 'Sanaag' },
    { subCode: 'SD', name: 'Shabeellaha Dhexe' },
    { subCode: 'SH', name: 'Shabeellaha Hoose' },
    { subCode: 'SO', name: 'Sool' },
    { subCode: 'TO', name: 'Togdheer' },
    { subCode: 'WO', name: 'Woqooyi Galbeed' },
  ],
  ZA: [
    { subCode: 'EC', name: 'Eastern Cape' },
    { subCode: 'FS', name: 'Foreisetata' },
    { subCode: 'GT', name: 'Gauteng' },
    { subCode: 'LP', name: 'Limpopo' },
    { subCode: 'MP', name: 'iMpumalanga' },
    { subCode: 'NC', name: 'iTlhagwini-Kapa' },
    { subCode: 'NL', name: 'GaZulu-Natala' },
    { subCode: 'NW', name: 'Bokone Bophirima' },
    { subCode: 'WC', name: 'iTjhingalanga-Kapa' },
  ],
  ES: [
    { subCode: 'A', name: 'Alacant*' },
    { subCode: 'AB', name: 'Albacete' },
    { subCode: 'AL', name: 'Almer\u00eda' },
    { subCode: 'AN', name: 'Andaluc\u00eda' },
    { subCode: 'AR', name: 'Arag\u00f3n' },
    { subCode: 'AS', name: 'Asturias, Principado de' },
    { subCode: 'AV', name: '\u00c1vila' },
    { subCode: 'BA', name: 'Badajoz' },
    { subCode: 'B', name: 'Barcelona' },
    { subCode: 'BI', name: 'Bizkaia*' },
    { subCode: 'BU', name: 'Burgos' },
    { subCode: 'CA', name: 'C\u00e1diz' },
    { subCode: 'C', name: 'A Coru\u00f1a' },
    { subCode: 'CB', name: 'Cantabria' },
    { subCode: 'CC', name: 'C\u00e1ceres' },
    { subCode: 'CE', name: 'Ceuta' },
    { subCode: 'CL', name: 'Castilla y Le\u00f3n' },
    { subCode: 'CM', name: 'Castilla-La Mancha' },
    { subCode: 'CN', name: 'Canarias' },
    { subCode: 'CO', name: 'C\u00f3rdoba' },
    { subCode: 'CR', name: 'Ciudad Real' },
    { subCode: 'CS', name: 'Castell\u00f3*' },
    { subCode: 'CT', name: 'Catalunya' },
    { subCode: 'CU', name: 'Cuenca' },
    { subCode: 'EX', name: 'Extremadura' },
    { subCode: 'GA', name: 'Galicia' },
    { subCode: 'GC', name: 'Las Palmas' },
    { subCode: 'GI', name: 'Girona' },
    { subCode: 'GR', name: 'Granada' },
    { subCode: 'GU', name: 'Guadalajara' },
    { subCode: 'H', name: 'Huelva' },
    { subCode: 'HU', name: 'Huesca' },
    { subCode: 'IB', name: 'Illes Balears' },
    { subCode: 'J', name: 'Ja\u00e9n' },
    { subCode: 'LE', name: 'Le\u00f3n' },
    { subCode: 'L', name: 'Lleida' },
    { subCode: 'LO', name: 'La Rioja' },
    { subCode: 'LU', name: 'Lugo' },
    { subCode: 'MA', name: 'M\u00e1laga' },
    { subCode: 'MC', name: 'Murcia, Regi\u00f3n de' },
    { subCode: 'MD', name: 'Madrid, Comunidad de' },
    { subCode: 'ML', name: 'Melilla' },
    { subCode: 'M', name: 'Madrid' },
    { subCode: 'MU', name: 'Murcia' },
    { subCode: 'NA', name: 'Nafarroa*' },
    { subCode: 'NC', name: 'Nafarroako Foru Komunitatea*' },
    { subCode: 'O', name: 'Asturias' },
    { subCode: 'OR', name: 'Ourense' },
    { subCode: 'PM', name: 'Balears' },
    { subCode: 'PO', name: 'Pontevedra' },
    { subCode: 'P', name: 'Palencia' },
    { subCode: 'PV', name: 'Euskal Herria' },
    { subCode: 'RI', name: 'La Rioja' },
    { subCode: 'SA', name: 'Salamanca' },
    { subCode: 'S', name: 'Cantabria' },
    { subCode: 'SE', name: 'Sevilla' },
    { subCode: 'SG', name: 'Segovia' },
    { subCode: 'SO', name: 'Soria' },
    { subCode: 'SS', name: 'Gipuzkoa*' },
    { subCode: 'TE', name: 'Teruel' },
    { subCode: 'TF', name: 'Santa Cruz de Tenerife' },
    { subCode: 'TO', name: 'Toledo' },
    { subCode: 'T', name: 'Tarragona' },
    { subCode: 'VA', name: 'Valladolid' },
    { subCode: 'VC', name: 'Valenciana, Comunidad' },
    { subCode: 'VI', name: '\u00c1lava' },
    { subCode: 'V', name: 'Valencia' },
    { subCode: 'ZA', name: 'Zamora' },
    { subCode: 'Z', name: 'Zaragoza' },
  ],
  LK: [
    { subCode: '11', name: 'Colombo' },
    { subCode: '12', name: 'Gampaha' },
    { subCode: '13', name: 'Kalutara' },
    { subCode: '1', name: 'Basn\u0101hira pa\u1e37\u0101ta' },
    { subCode: '21', name: 'Kandy' },
    { subCode: '22', name: 'Matale' },
    { subCode: '23', name: 'Nuvara \u0114liya' },
    { subCode: '2', name: 'Central Province' },
    { subCode: '31', name: 'G\u0101lla' },
    { subCode: '32', name: 'Matara' },
    { subCode: '33', name: 'Amp\u0101nt\u014d\u1e6d\u1e6dai' },
    { subCode: '3', name: 'Daku\u1e47u pa\u1e37\u0101ta' },
    { subCode: '41', name: 'Jaffna' },
    { subCode: '42', name: 'Kilinochchi' },
    { subCode: '43', name: 'Mannar' },
    { subCode: '44', name: 'Vavuniya' },
    { subCode: '45', name: 'Mulativ' },
    { subCode: '4', name: 'Northern Province' },
    { subCode: '51', name: 'Batticaloa' },
    { subCode: '52', name: 'Ampara' },
    { subCode: '53', name: 'Tiruk\u014f\u1e47amalai' },
    { subCode: '5', name: 'Eastern Province' },
    { subCode: '61', name: 'Kuru\u1e47\u00e6gala' },
    { subCode: '62', name: 'Puttalam' },
    { subCode: '6', name: 'North Western Province' },
    { subCode: '71', name: 'Anuradhapura' },
    { subCode: '72', name: 'P\u014fla\u1e49\u1e49a\u1e5buvai' },
    { subCode: '7', name: 'North Central Province' },
    { subCode: '81', name: 'Badulla' },
    { subCode: '82', name: 'Monaragala' },
    { subCode: '8', name: '\u016av\u0101 m\u0101k\u0101\u1e47am' },
    { subCode: '91', name: 'Iratti\u1e49apuri' },
    { subCode: '92', name: 'K\u00e6galla' },
    { subCode: '9', name: 'Chappirakamuva m\u0101k\u0101\u1e47am' },
  ],
  BL: [{ subCode: 'BL', name: 'St. Barth\u00e9lemy' }],
  SX: [{ subCode: 'SX', name: 'St. Maarten' }],
  MF: [{ subCode: 'MF', name: 'St. Martin' }],
  PM: [{ subCode: 'PM', name: 'St. Pierre & Miquelon' }],
  SR: [
    { subCode: 'BR', name: 'Brokopondo' },
    { subCode: 'CM', name: 'Commewijne' },
    { subCode: 'CR', name: 'Coronie' },
    { subCode: 'MA', name: 'Marowijne' },
    { subCode: 'NI', name: 'Nickerie' },
    { subCode: 'PM', name: 'Paramaribo' },
    { subCode: 'PR', name: 'Para' },
    { subCode: 'SA', name: 'Saramacca' },
    { subCode: 'SI', name: 'Sipaliwini' },
    { subCode: 'WA', name: 'Wanica' },
  ],
  SJ: [{ subCode: 'SJ', name: 'Svalbard & Jan Mayen Islands' }],
  SZ: [
    { subCode: 'HH', name: 'Hhohho' },
    { subCode: 'LU', name: 'Lubombo' },
    { subCode: 'MA', name: 'Manzini' },
    { subCode: 'SH', name: 'Shiselweni' },
  ],
  SE: [
    { subCode: 'AB', name: 'Stockholms l\u00e4n' },
    { subCode: 'AC', name: 'V\u00e4sterbottens l\u00e4n' },
    { subCode: 'BD', name: 'Norrbottens l\u00e4n' },
    { subCode: 'C', name: 'Uppsala l\u00e4n' },
    { subCode: 'D', name: 'S\u00f6dermanlands l\u00e4n' },
    { subCode: 'E', name: '\u00d6sterg\u00f6tlands l\u00e4n' },
    { subCode: 'F', name: 'J\u00f6nk\u00f6pings l\u00e4n' },
    { subCode: 'G', name: 'Kronobergs l\u00e4n' },
    { subCode: 'H', name: 'Kalmar l\u00e4n' },
    { subCode: 'I', name: 'Gotlands l\u00e4n' },
    { subCode: 'K', name: 'Blekinge l\u00e4n' },
    { subCode: 'M', name: 'Sk\u00e5ne l\u00e4n' },
    { subCode: 'N', name: 'Hallands l\u00e4n' },
    { subCode: 'O', name: 'V\u00e4stra G\u00f6talands l\u00e4n' },
    { subCode: 'S', name: 'V\u00e4rmlands l\u00e4n' },
    { subCode: 'T', name: '\u00d6rebro l\u00e4n' },
    { subCode: 'U', name: 'V\u00e4stmanlands l\u00e4n' },
    { subCode: 'W', name: 'Dalarnas l\u00e4n' },
    { subCode: 'X', name: 'G\u00e4vleborgs l\u00e4n' },
    { subCode: 'Y', name: 'V\u00e4sternorrlands l\u00e4n' },
    { subCode: 'Z', name: 'J\u00e4mtlands l\u00e4n' },
  ],
  CH: [
    { subCode: 'AG', name: 'Aargau' },
    { subCode: 'AI', name: 'Appenzell Innerrhoden' },
    { subCode: 'AR', name: 'Appenzell Ausserrhoden' },
    { subCode: 'BE', name: 'Bern' },
    { subCode: 'BL', name: 'Basel-Landschaft' },
    { subCode: 'BS', name: 'Basel-Stadt' },
    { subCode: 'FR', name: 'Freiburg' },
    { subCode: 'GE', name: 'Gen\u00e8ve' },
    { subCode: 'GL', name: 'Glarus' },
    { subCode: 'GR', name: 'Graub\u00fcnden' },
    { subCode: 'JU', name: 'Jura' },
    { subCode: 'LU', name: 'Luzern' },
    { subCode: 'NE', name: 'Neuch\u00e2tel' },
    { subCode: 'NW', name: 'Nidwalden' },
    { subCode: 'OW', name: 'Obwalden' },
    { subCode: 'SG', name: 'Sankt Gallen' },
    { subCode: 'SH', name: 'Schaffhausen' },
    { subCode: 'SO', name: 'Solothurn' },
    { subCode: 'SZ', name: 'Schwyz' },
    { subCode: 'TG', name: 'Thurgau' },
    { subCode: 'TI', name: 'Ticino' },
    { subCode: 'UR', name: 'Uri' },
    { subCode: 'VD', name: 'Vaud' },
    { subCode: 'VS', name: 'Valais' },
    { subCode: 'ZG', name: 'Zug' },
    { subCode: 'ZH', name: 'Z\u00fcrich' },
  ],
  TW: [
    { subCode: 'CHA', name: 'Changhua' },
    { subCode: 'CYI', name: 'Chiayi' },
    { subCode: 'CYQ', name: 'Chiayi' },
    { subCode: 'HSQ', name: 'Hsinchu' },
    { subCode: 'HSZ', name: 'Hsinchu' },
    { subCode: 'HUA', name: 'Hualien' },
    { subCode: 'ILA', name: 'Ilan' },
    { subCode: 'KEE', name: 'Keelung' },
    { subCode: 'KHH', name: 'Kaohsiung' },
    { subCode: 'KHQ', name: 'Kaohsiung' },
    { subCode: 'MIA', name: 'Miaoli' },
    { subCode: 'NAN', name: 'Nantou' },
    { subCode: 'PEN', name: 'Penghu' },
    { subCode: 'PIF', name: 'Pingtung' },
    { subCode: 'TAO', name: 'Taoyuan' },
    { subCode: 'TNN', name: 'Tainan' },
    { subCode: 'TNQ', name: 'Tainan' },
    { subCode: 'TPE', name: 'Taipei' },
    { subCode: 'TPQ', name: 'Taipei' },
    { subCode: 'TTT', name: 'Taitung' },
    { subCode: 'TXG', name: 'Taichung' },
    { subCode: 'TXQ', name: 'Taichung' },
    { subCode: 'YUN', name: 'Yunlin' },
  ],
  TJ: [
    { subCode: 'DU', name: 'Dushanbe' },
    { subCode: 'GB', name: 'K\u016dhistoni Badakhshon' },
    { subCode: 'KT', name: 'Khatlon' },
    { subCode: 'SU', name: 'Sughd' },
  ],
  TZ: [
    { subCode: '01', name: 'Arusha' },
    { subCode: '02', name: 'Dar es Salaam' },
    { subCode: '03', name: 'Dodoma' },
    { subCode: '04', name: 'Iringa' },
    { subCode: '05', name: 'Kagera' },
    { subCode: '06', name: 'Kaskazini Pemba' },
    { subCode: '07', name: 'Kaskazini Unguja' },
    { subCode: '08', name: 'Kigoma' },
    { subCode: '09', name: 'Kilimanjaro' },
    { subCode: '10', name: 'Kusini Pemba' },
    { subCode: '11', name: 'Kusini Unguja' },
    { subCode: '12', name: 'Lindi' },
    { subCode: '13', name: 'Mara' },
    { subCode: '14', name: 'Mbeya' },
    { subCode: '15', name: 'Mjini Magharibi' },
    { subCode: '16', name: 'Morogoro' },
    { subCode: '17', name: 'Mtwara' },
    { subCode: '18', name: 'Mwanza' },
    { subCode: '19', name: 'Coast' },
    { subCode: '20', name: 'Rukwa' },
    { subCode: '21', name: 'Ruvuma' },
    { subCode: '22', name: 'Shinyanga' },
    { subCode: '23', name: 'Singida' },
    { subCode: '24', name: 'Tabora' },
    { subCode: '25', name: 'Tanga' },
    { subCode: '26', name: 'Manyara' },
    { subCode: '27', name: 'Geita' },
    { subCode: '28', name: 'Katavi' },
    { subCode: '29', name: 'Njombe' },
    { subCode: '30', name: 'Simiyu' },
  ],
  TH: [
    { subCode: '10', name: 'Krung Thep Maha Nakhon' },
    { subCode: '11', name: 'Samut Prakan' },
    { subCode: '12', name: 'Nonthaburi' },
    { subCode: '13', name: 'Pathum Thani' },
    { subCode: '14', name: 'Phra Nakhon Si Ayutthaya' },
    { subCode: '15', name: 'Ang Thong' },
    { subCode: '16', name: 'Lop Buri' },
    { subCode: '17', name: 'Sing Buri' },
    { subCode: '18', name: 'Chai Nat' },
    { subCode: '19', name: 'Saraburi' },
    { subCode: '20', name: 'Chon Buri' },
    { subCode: '21', name: 'Rayong' },
    { subCode: '22', name: 'Chanthaburi' },
    { subCode: '23', name: 'Trat' },
    { subCode: '24', name: 'Chachoengsao' },
    { subCode: '25', name: 'Prachin Buri' },
    { subCode: '26', name: 'Nakhon Nayok' },
    { subCode: '27', name: 'Sa Kaeo' },
    { subCode: '30', name: 'Nakhon Ratchasima' },
    { subCode: '31', name: 'Buri Ram' },
    { subCode: '32', name: 'Surin' },
    { subCode: '33', name: 'Si Sa Ket' },
    { subCode: '34', name: 'Ubon Ratchathani' },
    { subCode: '35', name: 'Yasothon' },
    { subCode: '36', name: 'Chaiyaphum' },
    { subCode: '37', name: 'Amnat Charoen' },
    { subCode: '38', name: 'Bueng Kan' },
    { subCode: '39', name: 'Nong Bua Lam Phu' },
    { subCode: '40', name: 'Khon Kaen' },
    { subCode: '41', name: 'Udon Thani' },
    { subCode: '42', name: 'Loei' },
    { subCode: '43', name: 'Nong Khai' },
    { subCode: '44', name: 'Maha Sarakham' },
    { subCode: '45', name: 'Roi Et' },
    { subCode: '46', name: 'Kalasin' },
    { subCode: '47', name: 'Sakon Nakhon' },
    { subCode: '48', name: 'Nakhon Phanom' },
    { subCode: '49', name: 'Mukdahan' },
    { subCode: '50', name: 'Chiang Mai' },
    { subCode: '51', name: 'Lamphun' },
    { subCode: '52', name: 'Lampang' },
    { subCode: '53', name: 'Uttaradit' },
    { subCode: '54', name: 'Phrae' },
    { subCode: '55', name: 'Nan' },
    { subCode: '56', name: 'Phayao' },
    { subCode: '57', name: 'Chiang Rai' },
    { subCode: '58', name: 'Mae Hong Son' },
    { subCode: '60', name: 'Nakhon Sawan' },
    { subCode: '61', name: 'Uthai Thani' },
    { subCode: '62', name: 'Kamphaeng Phet' },
    { subCode: '63', name: 'Tak' },
    { subCode: '64', name: 'Sukhothai' },
    { subCode: '65', name: 'Phitsanulok' },
    { subCode: '66', name: 'Phichit' },
    { subCode: '67', name: 'Phetchabun' },
    { subCode: '70', name: 'Ratchaburi' },
    { subCode: '71', name: 'Kanchanaburi' },
    { subCode: '72', name: 'Suphan Buri' },
    { subCode: '73', name: 'Nakhon Pathom' },
    { subCode: '74', name: 'Samut Sakhon' },
    { subCode: '75', name: 'Samut Songkhram' },
    { subCode: '76', name: 'Phetchaburi' },
    { subCode: '77', name: 'Prachuap Khiri Khan' },
    { subCode: '80', name: 'Nakhon Si Thammarat' },
    { subCode: '81', name: 'Krabi' },
    { subCode: '82', name: 'Phangnga' },
    { subCode: '83', name: 'Phuket' },
    { subCode: '84', name: 'Surat Thani' },
    { subCode: '85', name: 'Ranong' },
    { subCode: '86', name: 'Chumphon' },
    { subCode: '90', name: 'Songkhla' },
    { subCode: '91', name: 'Satun' },
    { subCode: '92', name: 'Trang' },
    { subCode: '93', name: 'Phatthalung' },
    { subCode: '94', name: 'Pattani' },
    { subCode: '95', name: 'Yala' },
    { subCode: '96', name: 'Narathiwat' },
    { subCode: 'S', name: 'Phatthaya' },
  ],
  TL: [
    { subCode: 'AL', name: 'Aileu' },
    { subCode: 'AN', name: 'Ainaro' },
    { subCode: 'BA', name: 'Baucau' },
    { subCode: 'BO', name: 'Bobonaro' },
    { subCode: 'CO', name: 'Cova Lima' },
    { subCode: 'DI', name: 'D\u00edli' },
    { subCode: 'ER', name: 'Ermera' },
    { subCode: 'LA', name: 'Laut\u00e9m' },
    { subCode: 'LI', name: 'Likis\u00e1' },
    { subCode: 'MF', name: 'Manufahi' },
    { subCode: 'MT', name: 'Manatuto' },
    { subCode: 'OE', name: 'Oecussi' },
    { subCode: 'VI', name: 'Vikeke' },
  ],
  TG: [
    { subCode: 'C', name: 'Centre' },
    { subCode: 'K', name: 'Kara' },
    { subCode: 'M', name: 'Maritime' },
    { subCode: 'P', name: 'Plateaux' },
    { subCode: 'S', name: 'Savannes' },
  ],
  TK: [{ subCode: 'TK', name: 'Tokelau' }],
  TO: [
    { subCode: '01', name: "'Eua" },
    { subCode: '02', name: "Ha'apai" },
    { subCode: '03', name: 'Niuas' },
    { subCode: '04', name: 'Tongatapu' },
    { subCode: '05', name: "Vava'u" },
  ],
  TT: [
    { subCode: 'ARI', name: 'Arima' },
    { subCode: 'CHA', name: 'Chaguanas' },
    { subCode: 'CTT', name: 'Couva-Tabaquite-Talparo' },
    { subCode: 'DMN', name: 'Diego Martin' },
    { subCode: 'ETO', name: 'Eastern Tobago' },
    { subCode: 'PED', name: 'Penal-Debe' },
    { subCode: 'POS', name: 'Port of Spain' },
    { subCode: 'PRT', name: 'Princes Town' },
    { subCode: 'PTF', name: 'Point Fortin' },
    { subCode: 'RCM', name: 'Rio Claro-Mayaro' },
    { subCode: 'SFO', name: 'San Fernando' },
    { subCode: 'SGE', name: 'Sangre Grande' },
    { subCode: 'SIP', name: 'Siparia' },
    { subCode: 'SJL', name: 'San Juan-Laventille' },
    { subCode: 'TUP', name: 'Tunapuna-Piarco' },
    { subCode: 'WTO', name: 'Western Tobago' },
  ],
  TN: [
    { subCode: '11', name: 'Tunis' },
    { subCode: '12', name: 'Ariana' },
    { subCode: '13', name: 'Ben Arous' },
    { subCode: '14', name: 'La Manouba' },
    { subCode: '21', name: 'Nabeul' },
    { subCode: '22', name: 'Zaghouan' },
    { subCode: '23', name: 'Bizerte' },
    { subCode: '31', name: 'B\u00e9ja' },
    { subCode: '32', name: 'Jendouba' },
    { subCode: '33', name: 'Le Kef' },
    { subCode: '34', name: 'Siliana' },
    { subCode: '41', name: 'Kairouan' },
    { subCode: '42', name: 'Kasserine' },
    { subCode: '43', name: 'Sidi Bouzid' },
    { subCode: '51', name: 'Sousse' },
    { subCode: '52', name: 'Monastir' },
    { subCode: '53', name: 'Mahdia' },
    { subCode: '61', name: 'Sfax' },
    { subCode: '71', name: 'Gafsa' },
    { subCode: '72', name: 'Tozeur' },
    { subCode: '73', name: 'Kebili' },
    { subCode: '81', name: 'Gab\u00e8s' },
    { subCode: '82', name: 'Medenine' },
    { subCode: '83', name: 'Tataouine' },
  ],
  TR: [
    { subCode: '01', name: 'Adana' },
    { subCode: '02', name: 'Ad\u0131yaman' },
    { subCode: '03', name: 'Afyonkarahisar' },
    { subCode: '04', name: 'A\u011fr\u0131' },
    { subCode: '05', name: 'Amasya' },
    { subCode: '06', name: 'Ankara' },
    { subCode: '07', name: 'Antalya' },
    { subCode: '08', name: 'Artvin' },
    { subCode: '09', name: 'Ayd\u0131n' },
    { subCode: '10', name: 'Bal\u0131kesir' },
    { subCode: '11', name: 'Bilecik' },
    { subCode: '12', name: 'Bing\u00f6l' },
    { subCode: '13', name: 'Bitlis' },
    { subCode: '14', name: 'Bolu' },
    { subCode: '15', name: 'Burdur' },
    { subCode: '16', name: 'Bursa' },
    { subCode: '17', name: '\u00c7anakkale' },
    { subCode: '18', name: '\u00c7ank\u0131r\u0131' },
    { subCode: '19', name: '\u00c7orum' },
    { subCode: '20', name: 'Denizli' },
    { subCode: '21', name: 'Diyarbak\u0131r' },
    { subCode: '22', name: 'Edirne' },
    { subCode: '23', name: 'Elaz\u0131\u011f' },
    { subCode: '24', name: 'Erzincan' },
    { subCode: '25', name: 'Erzurum' },
    { subCode: '26', name: 'Eski\u015fehir' },
    { subCode: '27', name: 'Gaziantep' },
    { subCode: '28', name: 'Giresun' },
    { subCode: '29', name: 'G\u00fcm\u00fc\u015fhane' },
    { subCode: '30', name: 'Hakk\u00e2ri' },
    { subCode: '31', name: 'Hatay' },
    { subCode: '32', name: 'Isparta' },
    { subCode: '33', name: 'Mersin' },
    { subCode: '34', name: '\u0130stanbul' },
    { subCode: '35', name: '\u0130zmir' },
    { subCode: '36', name: 'Kars' },
    { subCode: '37', name: 'Kastamonu' },
    { subCode: '38', name: 'Kayseri' },
    { subCode: '39', name: 'K\u0131rklareli' },
    { subCode: '40', name: 'K\u0131r\u015fehir' },
    { subCode: '41', name: 'Kocaeli' },
    { subCode: '42', name: 'Konya' },
    { subCode: '43', name: 'K\u00fctahya' },
    { subCode: '44', name: 'Malatya' },
    { subCode: '45', name: 'Manisa' },
    { subCode: '46', name: 'Kahramanmara\u015f' },
    { subCode: '47', name: 'Mardin' },
    { subCode: '48', name: 'Mu\u011fla' },
    { subCode: '49', name: 'Mu\u015f' },
    { subCode: '50', name: 'Nev\u015fehir' },
    { subCode: '51', name: 'Ni\u011fde' },
    { subCode: '52', name: 'Ordu' },
    { subCode: '53', name: 'Rize' },
    { subCode: '54', name: 'Sakarya' },
    { subCode: '55', name: 'Samsun' },
    { subCode: '56', name: 'Siirt' },
    { subCode: '57', name: 'Sinop' },
    { subCode: '58', name: 'Sivas' },
    { subCode: '59', name: 'Tekirda\u011f' },
    { subCode: '60', name: 'Tokat' },
    { subCode: '61', name: 'Trabzon' },
    { subCode: '62', name: 'Tunceli' },
    { subCode: '63', name: '\u015eanl\u0131urfa' },
    { subCode: '64', name: 'U\u015fak' },
    { subCode: '65', name: 'Van' },
    { subCode: '66', name: 'Yozgat' },
    { subCode: '67', name: 'Zonguldak' },
    { subCode: '68', name: 'Aksaray' },
    { subCode: '69', name: 'Bayburt' },
    { subCode: '70', name: 'Karaman' },
    { subCode: '71', name: 'K\u0131r\u0131kkale' },
    { subCode: '72', name: 'Batman' },
    { subCode: '73', name: '\u015e\u0131rnak' },
    { subCode: '74', name: 'Bart\u0131n' },
    { subCode: '75', name: 'Ardahan' },
    { subCode: '76', name: 'I\u011fd\u0131r' },
    { subCode: '77', name: 'Yalova' },
    { subCode: '78', name: 'Karab\u00fck' },
    { subCode: '79', name: 'Kilis' },
    { subCode: '80', name: 'Osmaniye' },
    { subCode: '81', name: 'D\u00fczce' },
  ],
  TM: [
    { subCode: 'A', name: 'Ahal' },
    { subCode: 'B', name: 'Balkan' },
    { subCode: 'D', name: 'Da\u015foguz' },
    { subCode: 'L', name: 'Lebap' },
    { subCode: 'M', name: 'Mary' },
    { subCode: 'S', name: 'A\u015fgabat' },
  ],
  TC: [{ subCode: 'TC', name: 'Turks & Caicos Islands' }],
  TV: [
    { subCode: 'FUN', name: 'Funafuti' },
    { subCode: 'NIT', name: 'Niutao' },
    { subCode: 'NKF', name: 'Nukufetau' },
    { subCode: 'NKL', name: 'Nukulaelae' },
    { subCode: 'NMA', name: 'Nanumea' },
    { subCode: 'NMG', name: 'Nanumanga' },
    { subCode: 'NUI', name: 'Nui' },
    { subCode: 'VAI', name: 'Vaitupu' },
  ],
  UG: [
    { subCode: '101', name: 'Kalangala' },
    { subCode: '102', name: 'Kampala' },
    { subCode: '103', name: 'Kiboga' },
    { subCode: '104', name: 'Luwero' },
    { subCode: '105', name: 'Masaka' },
    { subCode: '106', name: 'Mpigi' },
    { subCode: '107', name: 'Mubende' },
    { subCode: '108', name: 'Mukono' },
    { subCode: '109', name: 'Nakasongola' },
    { subCode: '110', name: 'Rakai' },
    { subCode: '111', name: 'Sembabule' },
    { subCode: '112', name: 'Kayunga' },
    { subCode: '113', name: 'Wakiso' },
    { subCode: '114', name: 'Mityana' },
    { subCode: '115', name: 'Nakaseke' },
    { subCode: '116', name: 'Lyantonde' },
    { subCode: '117', name: 'Buikwe' },
    { subCode: '118', name: 'Bukomansibi' },
    { subCode: '119', name: 'Butambala' },
    { subCode: '120', name: 'Buvuma' },
    { subCode: '121', name: 'Gomba' },
    { subCode: '122', name: 'Kalungu' },
    { subCode: '123', name: 'Kyankwanzi' },
    { subCode: '124', name: 'Lwengo' },
    { subCode: '201', name: 'Bugiri' },
    { subCode: '202', name: 'Busia' },
    { subCode: '203', name: 'Iganga' },
    { subCode: '204', name: 'Jinja' },
    { subCode: '205', name: 'Kamuli' },
    { subCode: '206', name: 'Kapchorwa' },
    { subCode: '207', name: 'Katakwi' },
    { subCode: '208', name: 'Kumi' },
    { subCode: '209', name: 'Mbale' },
    { subCode: '210', name: 'Pallisa' },
    { subCode: '211', name: 'Soroti' },
    { subCode: '212', name: 'Tororo' },
    { subCode: '213', name: 'Kaberamaido' },
    { subCode: '214', name: 'Mayuge' },
    { subCode: '215', name: 'Sironko' },
    { subCode: '216', name: 'Amuria' },
    { subCode: '217', name: 'Budaka' },
    { subCode: '218', name: 'Bukwa' },
    { subCode: '219', name: 'Butaleja' },
    { subCode: '220', name: 'Kaliro' },
    { subCode: '221', name: 'Manafwa' },
    { subCode: '222', name: 'Namutumba' },
    { subCode: '223', name: 'Bududa' },
    { subCode: '224', name: 'Bukedea' },
    { subCode: '225', name: 'Bulambuli' },
    { subCode: '226', name: 'Buyende' },
    { subCode: '227', name: 'Kibuku' },
    { subCode: '228', name: 'Kween' },
    { subCode: '229', name: 'Luuka' },
    { subCode: '230', name: 'Namayingo' },
    { subCode: '231', name: 'Ngora' },
    { subCode: '232', name: 'Serere' },
    { subCode: '301', name: 'Adjumani' },
    { subCode: '302', name: 'Apac' },
    { subCode: '303', name: 'Arua' },
    { subCode: '304', name: 'Gulu' },
    { subCode: '305', name: 'Kitgum' },
    { subCode: '306', name: 'Kotido' },
    { subCode: '307', name: 'Lira' },
    { subCode: '308', name: 'Moroto' },
    { subCode: '309', name: 'Moyo' },
    { subCode: '310', name: 'Nebbi' },
    { subCode: '311', name: 'Nakapiripirit' },
    { subCode: '312', name: 'Pader' },
    { subCode: '313', name: 'Yumbe' },
    { subCode: '314', name: 'Amolatar' },
    { subCode: '315', name: 'Kaabong' },
    { subCode: '316', name: 'Koboko' },
    { subCode: '317', name: 'Abim' },
    { subCode: '318', name: 'Dokolo' },
    { subCode: '319', name: 'Amuru' },
    { subCode: '320', name: 'Maracha' },
    { subCode: '321', name: 'Oyam' },
    { subCode: '322', name: 'Agago' },
    { subCode: '323', name: 'Alebtong' },
    { subCode: '324', name: 'Amudat' },
    { subCode: '325', name: 'Buhweju' },
    { subCode: '326', name: 'Kole' },
    { subCode: '327', name: 'Lamwo' },
    { subCode: '328', name: 'Napak' },
    { subCode: '329', name: 'Nwoya' },
    { subCode: '330', name: 'Otuke' },
    { subCode: '331', name: 'Zombo' },
    { subCode: '401', name: 'Bundibugyo' },
    { subCode: '402', name: 'Bushenyi' },
    { subCode: '403', name: 'Hoima' },
    { subCode: '404', name: 'Kabale' },
    { subCode: '405', name: 'Kabarole' },
    { subCode: '406', name: 'Kasese' },
    { subCode: '407', name: 'Kibaale' },
    { subCode: '408', name: 'Kisoro' },
    { subCode: '409', name: 'Masindi' },
    { subCode: '410', name: 'Mbarara' },
    { subCode: '411', name: 'Ntungamo' },
    { subCode: '412', name: 'Rukungiri' },
    { subCode: '413', name: 'Kamwenge' },
    { subCode: '414', name: 'Kanungu' },
    { subCode: '415', name: 'Kyenjojo' },
    { subCode: '416', name: 'Ibanda' },
    { subCode: '417', name: 'Isingiro' },
    { subCode: '418', name: 'Kiruhura' },
    { subCode: '419', name: 'Buliisa' },
    { subCode: '420', name: 'Kiryandongo' },
    { subCode: '421', name: 'Kyegegwa' },
    { subCode: '422', name: 'Mitooma' },
    { subCode: '423', name: 'Ntoroko' },
    { subCode: '424', name: 'Rubirizi' },
    { subCode: '425', name: 'Sheema' },
    { subCode: 'C', name: 'Central' },
    { subCode: 'E', name: 'Eastern' },
    { subCode: 'N', name: 'Northern' },
    { subCode: 'W', name: 'Western' },
  ],
  UA: [
    { subCode: '30', name: 'Kyiv' },
    { subCode: '05', name: 'Vinnytska oblast' },
    { subCode: '07', name: 'Volynska oblast' },
    { subCode: '09', name: 'Luhanska oblast' },
    { subCode: '12', name: 'Dnipropetrovska oblast' },
    { subCode: '14', name: 'Donetska oblast' },
    { subCode: '18', name: 'Zhytomyrska oblast' },
    { subCode: '21', name: 'Zakarpatska oblast' },
    { subCode: '23', name: 'Zaporizka oblast' },
    { subCode: '26', name: 'Ivano-Frankivska oblast' },
    { subCode: '32', name: 'Kyivska oblast' },
    { subCode: '35', name: 'Kirovohradska oblast' },
    // { subCode: '40', name: 'Sevastopol' },
    // { subCode: '43', name: 'Avtonomna Respublika Krym' },
    { subCode: '46', name: 'Lvivska oblast' },
    { subCode: '48', name: 'Mykolaivska oblast' },
    { subCode: '51', name: 'Odeska oblast' },
    { subCode: '53', name: 'Poltavska oblast' },
    { subCode: '56', name: 'Rivnenska oblast' },
    { subCode: '59', name: 'Sumska oblast' },
    { subCode: '61', name: 'Ternopilska oblast' },
    { subCode: '63', name: 'Kharkivska oblast' },
    { subCode: '65', name: 'Khersonska oblast' },
    { subCode: '68', name: 'Khmelnytska oblast' },
    { subCode: '71', name: 'Cherkaska oblast' },
    { subCode: '74', name: 'Chernihivska oblast' },
    { subCode: '77', name: 'Chernivetska oblast' },
  ],
  AE: [
    { subCode: 'AJ', name: "'Ajm\u0101n" },
    { subCode: 'AZ', name: 'Ab\u016b Z\u0327aby' },
    { subCode: 'DU', name: 'Dubayy' },
    { subCode: 'FU', name: 'Al Fujayrah' },
    { subCode: 'RK', name: "Ra's al Khaymah" },
    { subCode: 'SH', name: 'Ash Sh\u0101riqah' },
    { subCode: 'UQ', name: 'Umm al Qaywayn' },
  ],
  GB: [
    { subCode: 'ABD', name: 'Aberdeenshire' },
    { subCode: 'ABE', name: 'Aberdeen City' },
    { subCode: 'AGB', name: 'Argyll and Bute' },
    { subCode: 'AGY', name: 'Isle of Anglesey' },
    { subCode: 'ANS', name: 'Angus' },
    { subCode: 'ANT', name: 'Antrim' },
    { subCode: 'ARD', name: 'Ards' },
    { subCode: 'ARM', name: 'Armagh' },
    { subCode: 'BAS', name: 'Bath and North East Somerset' },
    { subCode: 'BBD', name: 'Blackburn with Darwen' },
    { subCode: 'BDF', name: 'Bedford' },
    { subCode: 'BDG', name: 'Barking and Dagenham' },
    { subCode: 'BEN', name: 'Brent' },
    { subCode: 'BEX', name: 'Bexley' },
    { subCode: 'BFS', name: 'Belfast' },
    { subCode: 'BGE', name: 'Bridgend' },
    { subCode: 'BGW', name: 'Blaenau Gwent' },
    { subCode: 'BIR', name: 'Birmingham' },
    { subCode: 'BKM', name: 'Buckinghamshire' },
    { subCode: 'BLA', name: 'Ballymena' },
    { subCode: 'BLY', name: 'Ballymoney' },
    { subCode: 'BMH', name: 'Bournemouth' },
    { subCode: 'BNB', name: 'Banbridge' },
    { subCode: 'BNE', name: 'Barnet' },
    { subCode: 'BNH', name: 'Brighton and Hove' },
    { subCode: 'BNS', name: 'Barnsley' },
    { subCode: 'BOL', name: 'Bolton' },
    { subCode: 'BPL', name: 'Blackpool' },
    { subCode: 'BRC', name: 'Bracknell Forest' },
    { subCode: 'BRD', name: 'Bradford' },
    { subCode: 'BRY', name: 'Bromley' },
    { subCode: 'BST', name: 'Bristol, City of' },
    { subCode: 'BUR', name: 'Bury' },
    { subCode: 'CAM', name: 'Cambridgeshire' },
    { subCode: 'CAY', name: 'Caerphilly' },
    { subCode: 'CBF', name: 'Central Bedfordshire' },
    { subCode: 'CGN', name: 'Ceredigion' },
    { subCode: 'CGV', name: 'Craigavon' },
    { subCode: 'CHE', name: 'Cheshire East' },
    { subCode: 'CHW', name: 'Cheshire West and Chester' },
    { subCode: 'CKF', name: 'Carrickfergus' },
    { subCode: 'CKT', name: 'Cookstown' },
    { subCode: 'CLD', name: 'Calderdale' },
    { subCode: 'CLK', name: 'Clackmannanshire' },
    { subCode: 'CLR', name: 'Coleraine' },
    { subCode: 'CMA', name: 'Cumbria' },
    { subCode: 'CMD', name: 'Camden' },
    { subCode: 'CMN', name: 'Carmarthenshire' },
    { subCode: 'CON', name: 'Cornwall' },
    { subCode: 'COV', name: 'Coventry' },
    { subCode: 'CRF', name: 'Cardiff' },
    { subCode: 'CRY', name: 'Croydon' },
    { subCode: 'CSR', name: 'Castlereagh' },
    { subCode: 'CWY', name: 'Conwy' },
    { subCode: 'DAL', name: 'Darlington' },
    { subCode: 'DBY', name: 'Derbyshire' },
    { subCode: 'DEN', name: 'Denbighshire' },
    { subCode: 'DER', name: 'Derby' },
    { subCode: 'DEV', name: 'Devon' },
    { subCode: 'DGN', name: 'Dungannon and South Tyrone' },
    { subCode: 'DGY', name: 'Dumfries and Galloway' },
    { subCode: 'DNC', name: 'Doncaster' },
    { subCode: 'DND', name: 'Dundee City' },
    { subCode: 'DOR', name: 'Dorset' },
    { subCode: 'DOW', name: 'Down' },
    { subCode: 'DRY', name: 'Derry' },
    { subCode: 'DUD', name: 'Dudley' },
    { subCode: 'DUR', name: 'Durham County' },
    { subCode: 'EAL', name: 'Ealing' },
    { subCode: 'EAW', name: 'England and Wales' },
    { subCode: 'EAY', name: 'East Ayrshire' },
    { subCode: 'EDH', name: 'Edinburgh, City of' },
    { subCode: 'EDU', name: 'East Dunbartonshire' },
    { subCode: 'ELN', name: 'East Lothian' },
    { subCode: 'ELS', name: 'Eilean Siar' },
    { subCode: 'ENF', name: 'Enfield' },
    { subCode: 'ENG', name: 'England' },
    { subCode: 'ERW', name: 'East Renfrewshire' },
    { subCode: 'ERY', name: 'East Riding of Yorkshire' },
    { subCode: 'ESS', name: 'Essex' },
    { subCode: 'ESX', name: 'East Sussex' },
    { subCode: 'FAL', name: 'Falkirk' },
    { subCode: 'FER', name: 'Fermanagh' },
    { subCode: 'FIF', name: 'Fife' },
    { subCode: 'FLN', name: 'Flintshire' },
    { subCode: 'GAT', name: 'Gateshead' },
    { subCode: 'GBN', name: 'Great Britain' },
    { subCode: 'GLG', name: 'Glasgow City' },
    { subCode: 'GLS', name: 'Gloucestershire' },
    { subCode: 'GRE', name: 'Greenwich' },
    { subCode: 'GWN', name: 'Gwynedd' },
    { subCode: 'HAL', name: 'Halton' },
    { subCode: 'HAM', name: 'Hampshire' },
    { subCode: 'HAV', name: 'Havering' },
    { subCode: 'HCK', name: 'Hackney' },
    { subCode: 'HEF', name: 'Herefordshire' },
    { subCode: 'HIL', name: 'Hillingdon' },
    { subCode: 'HLD', name: 'Highland' },
    { subCode: 'HMF', name: 'Hammersmith and Fulham' },
    { subCode: 'HNS', name: 'Hounslow' },
    { subCode: 'HPL', name: 'Hartlepool' },
    { subCode: 'HRT', name: 'Hertfordshire' },
    { subCode: 'HRW', name: 'Harrow' },
    { subCode: 'HRY', name: 'Haringey' },
    { subCode: 'IOS', name: 'Isles of Scilly' },
    { subCode: 'IOW', name: 'Isle of Wight' },
    { subCode: 'ISL', name: 'Islington' },
    { subCode: 'IVC', name: 'Inverclyde' },
    { subCode: 'KEC', name: 'Kensington and Chelsea' },
    { subCode: 'KEN', name: 'Kent' },
    { subCode: 'KHL', name: 'Kingston upon Hull' },
    { subCode: 'KIR', name: 'Kirklees' },
    { subCode: 'KTT', name: 'Kingston upon Thames' },
    { subCode: 'KWL', name: 'Knowsley' },
    { subCode: 'LAN', name: 'Lancashire' },
    { subCode: 'LBH', name: 'Lambeth' },
    { subCode: 'LCE', name: 'Leicester' },
    { subCode: 'LDS', name: 'Leeds' },
    { subCode: 'LEC', name: 'Leicestershire' },
    { subCode: 'LEW', name: 'Lewisham' },
    { subCode: 'LIN', name: 'Lincolnshire' },
    { subCode: 'LIV', name: 'Liverpool' },
    { subCode: 'LMV', name: 'Limavady' },
    { subCode: 'LND', name: 'London, City of' },
    { subCode: 'LRN', name: 'Larne' },
    { subCode: 'LSB', name: 'Lisburn' },
    { subCode: 'LUT', name: 'Luton' },
    { subCode: 'MAN', name: 'Manchester' },
    { subCode: 'MDB', name: 'Middlesbrough' },
    { subCode: 'MDW', name: 'Medway' },
    { subCode: 'MFT', name: 'Magherafelt' },
    { subCode: 'MIK', name: 'Milton Keynes' },
    { subCode: 'MLN', name: 'Midlothian' },
    { subCode: 'MON', name: 'Monmouthshire' },
    { subCode: 'MRT', name: 'Merton' },
    { subCode: 'MRY', name: 'Moray' },
    { subCode: 'MTY', name: 'Merthyr Tydfil' },
    { subCode: 'MYL', name: 'Moyle' },
    { subCode: 'NAY', name: 'North Ayrshire' },
    { subCode: 'NBL', name: 'Northumberland' },
    { subCode: 'NDN', name: 'North Down' },
    { subCode: 'NEL', name: 'North East Lincolnshire' },
    { subCode: 'NET', name: 'Newcastle upon Tyne' },
    { subCode: 'NFK', name: 'Norfolk' },
    { subCode: 'NGM', name: 'Nottingham' },
    { subCode: 'NIR', name: 'Northern Ireland' },
    { subCode: 'NLK', name: 'North Lanarkshire' },
    { subCode: 'NLN', name: 'North Lincolnshire' },
    { subCode: 'NSM', name: 'North Somerset' },
    { subCode: 'NTA', name: 'Newtownabbey' },
    { subCode: 'NTH', name: 'Northamptonshire' },
    { subCode: 'NTL', name: 'Neath Port Talbot' },
    { subCode: 'NTT', name: 'Nottinghamshire' },
    { subCode: 'NTY', name: 'North Tyneside' },
    { subCode: 'NWM', name: 'Newham' },
    { subCode: 'NWP', name: 'Newport' },
    { subCode: 'NYK', name: 'North Yorkshire' },
    { subCode: 'NYM', name: 'Newry and Mourne' },
    { subCode: 'OLD', name: 'Oldham' },
    { subCode: 'OMH', name: 'Omagh' },
    { subCode: 'ORK', name: 'Orkney Islands' },
    { subCode: 'OXF', name: 'Oxfordshire' },
    { subCode: 'PEM', name: 'Pembrokeshire' },
    { subCode: 'PKN', name: 'Perth and Kinross' },
    { subCode: 'PLY', name: 'Plymouth' },
    { subCode: 'POL', name: 'Poole' },
    { subCode: 'POR', name: 'Portsmouth' },
    { subCode: 'POW', name: 'Powys' },
    { subCode: 'PTE', name: 'Peterborough' },
    { subCode: 'RCC', name: 'Redcar and Cleveland' },
    { subCode: 'RCH', name: 'Rochdale' },
    { subCode: 'RCT', name: 'Rhondda, Cynon, Taff' },
    { subCode: 'RDB', name: 'Redbridge' },
    { subCode: 'RDG', name: 'Reading' },
    { subCode: 'RFW', name: 'Renfrewshire' },
    { subCode: 'RIC', name: 'Richmond upon Thames' },
    { subCode: 'ROT', name: 'Rotherham' },
    { subCode: 'RUT', name: 'Rutland' },
    { subCode: 'SAW', name: 'Sandwell' },
    { subCode: 'SAY', name: 'South Ayrshire' },
    { subCode: 'SCB', name: 'Scottish Borders, The' },
    { subCode: 'SCT', name: 'Scotland' },
    { subCode: 'SFK', name: 'Suffolk' },
    { subCode: 'SFT', name: 'Sefton' },
    { subCode: 'SGC', name: 'South Gloucestershire' },
    { subCode: 'SHF', name: 'Sheffield' },
    { subCode: 'SHN', name: 'St. Helens' },
    { subCode: 'SHR', name: 'Shropshire' },
    { subCode: 'SKP', name: 'Stockport' },
    { subCode: 'SLF', name: 'Salford' },
    { subCode: 'SLG', name: 'Slough' },
    { subCode: 'SLK', name: 'South Lanarkshire' },
    { subCode: 'SND', name: 'Sunderland' },
    { subCode: 'SOL', name: 'Solihull' },
    { subCode: 'SOM', name: 'Somerset' },
    { subCode: 'SOS', name: 'Southend-on-Sea' },
    { subCode: 'SRY', name: 'Surrey' },
    { subCode: 'STB', name: 'Strabane' },
    { subCode: 'STE', name: 'Stoke-on-Trent' },
    { subCode: 'STG', name: 'Stirling' },
    { subCode: 'STH', name: 'Southampton' },
    { subCode: 'STN', name: 'Sutton' },
    { subCode: 'STS', name: 'Staffordshire' },
    { subCode: 'STT', name: 'Stockton-on-Tees' },
    { subCode: 'STY', name: 'South Tyneside' },
    { subCode: 'SWA', name: 'Swansea' },
    { subCode: 'SWD', name: 'Swindon' },
    { subCode: 'SWK', name: 'Southwark' },
    { subCode: 'TAM', name: 'Tameside' },
    { subCode: 'TFW', name: 'Telford and Wrekin' },
    { subCode: 'THR', name: 'Thurrock' },
    { subCode: 'TOB', name: 'Torbay' },
    { subCode: 'TOF', name: 'Torfaen' },
    { subCode: 'TRF', name: 'Trafford' },
    { subCode: 'TWH', name: 'Tower Hamlets' },
    { subCode: 'UKM', name: 'United Kingdom' },
    { subCode: 'VGL', name: 'Vale of Glamorgan, The' },
    { subCode: 'WAR', name: 'Warwickshire' },
    { subCode: 'WBK', name: 'West Berkshire' },
    { subCode: 'WDU', name: 'West Dunbartonshire' },
    { subCode: 'WFT', name: 'Waltham Forest' },
    { subCode: 'WGN', name: 'Wigan' },
    { subCode: 'WIL', name: 'Wiltshire' },
    { subCode: 'WKF', name: 'Wakefield' },
    { subCode: 'WLL', name: 'Walsall' },
    { subCode: 'WLN', name: 'West Lothian' },
    { subCode: 'WLS', name: 'Wales' },
    { subCode: 'WLV', name: 'Wolverhampton' },
    { subCode: 'WND', name: 'Wandsworth' },
    { subCode: 'WNM', name: 'Windsor and Maidenhead' },
    { subCode: 'WOK', name: 'Wokingham' },
    { subCode: 'WOR', name: 'Worcestershire' },
    { subCode: 'WRL', name: 'Wirral' },
    { subCode: 'WRT', name: 'Warrington' },
    { subCode: 'WRX', name: 'Wrexham' },
    { subCode: 'WSM', name: 'Westminster' },
    { subCode: 'WSX', name: 'West Sussex' },
    { subCode: 'YOR', name: 'York' },
    { subCode: 'ZET', name: 'Shetland Islands' },
  ],
  UM: [
    { subCode: '67', name: 'Johnston Atoll' },
    { subCode: '71', name: 'Midway Islands' },
    { subCode: '76', name: 'Navassa Island' },
    { subCode: '79', name: 'Wake Island' },
    { subCode: '81', name: 'Baker Island' },
    { subCode: '84', name: 'Howland Island' },
    { subCode: '86', name: 'Jarvis Island' },
    { subCode: '89', name: 'Kingman Reef' },
    { subCode: '95', name: 'Palmyra Atoll' },
  ],
  US: [
    { subCode: 'AK', name: 'Alaska' },
    { subCode: 'AL', name: 'Alabama' },
    { subCode: 'AR', name: 'Arkansas' },
    { subCode: 'AS', name: 'American Samoa' },
    { subCode: 'AZ', name: 'Arizona' },
    { subCode: 'CA', name: 'California' },
    { subCode: 'CO', name: 'Colorado' },
    { subCode: 'CT', name: 'Connecticut' },
    { subCode: 'DC', name: 'District of Columbia' },
    { subCode: 'DE', name: 'Delaware' },
    { subCode: 'FL', name: 'Florida' },
    { subCode: 'GA', name: 'Georgia' },
    { subCode: 'GU', name: 'Guam' },
    { subCode: 'HI', name: 'Hawaii' },
    { subCode: 'IA', name: 'Iowa' },
    { subCode: 'ID', name: 'Idaho' },
    { subCode: 'IL', name: 'Illinois' },
    { subCode: 'IN', name: 'Indiana' },
    { subCode: 'KS', name: 'Kansas' },
    { subCode: 'KY', name: 'Kentucky' },
    { subCode: 'LA', name: 'Louisiana' },
    { subCode: 'MA', name: 'Massachusetts' },
    { subCode: 'MD', name: 'Maryland' },
    { subCode: 'ME', name: 'Maine' },
    { subCode: 'MI', name: 'Michigan' },
    { subCode: 'MN', name: 'Minnesota' },
    { subCode: 'MO', name: 'Missouri' },
    { subCode: 'MP', name: 'Northern Mariana Islands' },
    { subCode: 'MS', name: 'Mississippi' },
    { subCode: 'MT', name: 'Montana' },
    { subCode: 'NC', name: 'North Carolina' },
    { subCode: 'ND', name: 'North Dakota' },
    { subCode: 'NE', name: 'Nebraska' },
    { subCode: 'NH', name: 'New Hampshire' },
    { subCode: 'NJ', name: 'New Jersey' },
    { subCode: 'NM', name: 'New Mexico' },
    { subCode: 'NV', name: 'Nevada' },
    { subCode: 'NY', name: 'New York' },
    { subCode: 'OH', name: 'Ohio' },
    { subCode: 'OK', name: 'Oklahoma' },
    { subCode: 'OR', name: 'Oregon' },
    { subCode: 'PA', name: 'Pennsylvania' },
    { subCode: 'PR', name: 'Puerto Rico' },
    { subCode: 'RI', name: 'Rhode Island' },
    { subCode: 'SC', name: 'South Carolina' },
    { subCode: 'SD', name: 'South Dakota' },
    { subCode: 'TN', name: 'Tennessee' },
    { subCode: 'TX', name: 'Texas' },
    { subCode: 'UM', name: 'United States Minor Outlying Islands' },
    { subCode: 'UT', name: 'Utah' },
    { subCode: 'VA', name: 'Virginia' },
    { subCode: 'VI', name: 'Virgin Islands, U.S.' },
    { subCode: 'VT', name: 'Vermont' },
    { subCode: 'WA', name: 'Washington' },
    { subCode: 'WI', name: 'Wisconsin' },
    { subCode: 'WV', name: 'West Virginia' },
    { subCode: 'WY', name: 'Wyoming' },
  ],
  UY: [
    { subCode: 'AR', name: 'Artigas' },
    { subCode: 'CA', name: 'Canelones' },
    { subCode: 'CL', name: 'Cerro Largo' },
    { subCode: 'CO', name: 'Colonia' },
    { subCode: 'DU', name: 'Durazno' },
    { subCode: 'FD', name: 'Florida' },
    { subCode: 'FS', name: 'Flores' },
    { subCode: 'LA', name: 'Lavalleja' },
    { subCode: 'MA', name: 'Maldonado' },
    { subCode: 'MO', name: 'Montevideo' },
    { subCode: 'PA', name: 'Paysand\u00fa' },
    { subCode: 'RN', name: 'R\u00edo Negro' },
    { subCode: 'RO', name: 'Rocha' },
    { subCode: 'RV', name: 'Rivera' },
    { subCode: 'SA', name: 'Salto' },
    { subCode: 'SJ', name: 'San Jos\u00e9' },
    { subCode: 'SO', name: 'Soriano' },
    { subCode: 'TA', name: 'Tacuaremb\u00f3' },
    { subCode: 'TT', name: 'Treinta y Tres' },
  ],
  UZ: [
    { subCode: 'AN', name: 'Andijon' },
    { subCode: 'BU', name: 'Buxoro' },
    { subCode: 'FA', name: 'Farg\u2018ona' },
    { subCode: 'JI', name: 'Jizzax' },
    { subCode: 'NG', name: 'Namangan' },
    { subCode: 'NW', name: 'Navoiy' },
    { subCode: 'QA', name: 'Qashqadaryo' },
    { subCode: 'QR', name: 'Qoraqalpog\u2018iston Respublikasi' },
    { subCode: 'SA', name: 'Samarqand' },
    { subCode: 'SI', name: 'Sirdaryo' },
    { subCode: 'SU', name: 'Surxondaryo' },
    { subCode: 'TK', name: 'Toshkent' },
    { subCode: 'TO', name: 'Toshkent' },
    { subCode: 'XO', name: 'Xorazm' },
  ],
  VU: [
    { subCode: 'MAP', name: 'Malampa' },
    { subCode: 'PAM', name: 'P\u00e9nama' },
    { subCode: 'SAM', name: 'Sanma' },
    { subCode: 'SEE', name: 'Sh\u00e9fa' },
    { subCode: 'TAE', name: 'Taf\u00e9a' },
    { subCode: 'TOB', name: 'Torba' },
  ],
  VA: [{ subCode: 'VA', name: 'Vatican City' }],
  VN: [
    { subCode: '01', name: 'Lai Ch\u00e2u' },
    { subCode: '02', name: 'L\u00e0o Cai' },
    { subCode: '03', name: 'H\u00e0 Giang' },
    { subCode: '04', name: 'Cao B\u1eb1ng' },
    { subCode: '05', name: 'S\u01a1n La' },
    { subCode: '06', name: 'Y\u00ean B\u00e1i' },
    { subCode: '07', name: 'Tuy\u00ean Quang' },
    { subCode: '09', name: 'L\u1ea1ng S\u01a1n' },
    { subCode: '13', name: 'Qu\u1ea3ng Ninh' },
    { subCode: '14', name: 'H\u00f2a B\u00ecnh' },
    { subCode: '18', name: 'Ninh B\u00ecnh' },
    { subCode: '20', name: 'Th\u00e1i B\u00ecnh' },
    { subCode: '21', name: 'Thanh H\u00f3a' },
    { subCode: '22', name: 'Ngh\u1ec7 An' },
    { subCode: '23', name: 'H\u00e0 T\u0129nh' },
    { subCode: '24', name: 'Qu\u1ea3ng B\u00ecnh' },
    { subCode: '25', name: 'Qu\u1ea3ng Tr\u1ecb' },
    { subCode: '26', name: 'Th\u1eeba Thi\u00ean-Hu\u1ebf' },
    { subCode: '27', name: 'Qu\u1ea3ng Nam' },
    { subCode: '28', name: 'Kon Tum' },
    { subCode: '29', name: 'Qu\u1ea3ng Ng\u00e3i' },
    { subCode: '30', name: 'Gia Lai' },
    { subCode: '31', name: 'B\u00ecnh \u0110\u1ecbnh' },
    { subCode: '32', name: 'Ph\u00fa Y\u00ean' },
    { subCode: '33', name: '\u0110\u1eafk L\u1eafk' },
    { subCode: '34', name: 'Kh\u00e1nh H\u00f2a' },
    { subCode: '35', name: 'L\u00e2m \u00d0\u1ed3ng' },
    { subCode: '36', name: 'Ninh Thu\u1eadn' },
    { subCode: '37', name: 'T\u00e2y Ninh' },
    { subCode: '39', name: '\u00d0\u1ed3ng Nai' },
    { subCode: '40', name: 'B\u00ecnh Thu\u1eadn' },
    { subCode: '41', name: 'Long An' },
    { subCode: '43', name: 'B\u00e0 R\u1ecba - V\u0169ng T\u00e0u' },
    { subCode: '44', name: 'An Giang' },
    { subCode: '45', name: '\u00d0\u1ed3ng Th\u00e1p' },
    { subCode: '46', name: 'Ti\u1ec1n Giang' },
    { subCode: '47', name: 'Ki\u1ebfn Giang' },
    { subCode: '49', name: 'V\u0129nh Long' },
    { subCode: '50', name: 'B\u1ebfn Tre' },
    { subCode: '51', name: 'Tr\u00e0 Vinh' },
    { subCode: '52', name: 'S\u00f3c Tr\u0103ng' },
    { subCode: '53', name: 'B\u1eafc K\u1ea1n' },
    { subCode: '54', name: 'B\u1eafc Giang' },
    { subCode: '55', name: 'B\u1ea1c Li\u00eau' },
    { subCode: '56', name: 'B\u1eafc Ninh' },
    { subCode: '57', name: 'B\u00ecnh D\u01b0\u01a1ng' },
    { subCode: '58', name: 'B\u00ecnh Ph\u01b0\u1edbc' },
    { subCode: '59', name: 'C\u00e0 Mau' },
    { subCode: '61', name: 'H\u1ea3i D\u01b0\u01a1ng' },
    { subCode: '63', name: 'H\u00e0 Nam' },
    { subCode: '66', name: 'H\u01b0ng Y\u00ean' },
    { subCode: '67', name: 'Nam \u00d0\u1ecbnh' },
    { subCode: '68', name: 'Ph\u00fa Th\u1ecd' },
    { subCode: '69', name: 'Th\u00e1i Nguy\u00ean' },
    { subCode: '70', name: 'V\u0129nh Ph\u00fac' },
    { subCode: '71', name: '\u0110i\u1ec7n Bi\u00ean' },
    { subCode: '72', name: '\u0110\u1eafk N\u00f4ng' },
    { subCode: '73', name: 'H\u1eadu Giang' },
    { subCode: 'CT', name: 'Can Tho' },
    { subCode: 'DN', name: 'Da Nang' },
    { subCode: 'HN', name: 'Ha Noi' },
    { subCode: 'HP', name: 'Hai Phong' },
    { subCode: 'SG', name: 'Ho Chi Minh' },
  ],
  VG: [{ subCode: 'VG', name: 'Virgin Islands' }],
  VI: [{ subCode: 'VI', name: 'Virgin Islands' }],
  WF: [{ subCode: 'WF', name: 'Wallis & Futuna Islands' }],
  EH: [{ subCode: 'EH', name: 'Western Sahara' }],
  YE: [
    { subCode: 'AB', name: 'Aby\u0101n' },
    { subCode: 'AD', name: "'Adan" },
    { subCode: 'AM', name: "'Amr\u0101n" },
    { subCode: 'BA', name: "Al Bay\u1e11\u0101'" },
    { subCode: 'DA', name: "A\u1e11 \u1e10\u0101li'" },
    { subCode: 'DH', name: 'Dham\u0101r' },
    { subCode: 'HD', name: '\u1e28a\u1e11ramawt' },
    { subCode: 'HJ', name: '\u1e28ajjah' },
    { subCode: 'HU', name: 'Al \u1e28udaydah' },
    { subCode: 'IB', name: 'Ibb' },
    { subCode: 'JA', name: 'Al Jawf' },
    { subCode: 'LA', name: 'La\u1e29ij' },
    { subCode: 'MA', name: "Ma'rib" },
    { subCode: 'MR', name: 'Al Mahrah' },
    { subCode: 'MW', name: 'Al Ma\u1e29w\u012bt' },
    { subCode: 'RA', name: 'Raymah' },
    { subCode: 'SA', name: 'Am\u0101nat al \u2018\u0100\u015fimah' },
    { subCode: 'SD', name: '\u015e\u0101\u02bbdah' },
    { subCode: 'SH', name: 'Shabwah' },
    { subCode: 'SN', name: "\u015ean\u02bb\u0101'" },
    { subCode: 'TA', name: 'T\u0101\u02bbizz' },
  ],
  ZM: [
    { subCode: '01', name: 'Western' },
    { subCode: '02', name: 'Central' },
    { subCode: '03', name: 'Eastern' },
    { subCode: '04', name: 'Luapula' },
    { subCode: '05', name: 'Northern' },
    { subCode: '06', name: 'North-Western' },
    { subCode: '07', name: 'Southern' },
    { subCode: '08', name: 'Copperbelt' },
    { subCode: '09', name: 'Lusaka' },
    { subCode: '10', name: 'Muchinga' },
  ],
  ZW: [
    { subCode: 'BU', name: 'Bulawayo' },
    { subCode: 'HA', name: 'Harare' },
    { subCode: 'MA', name: 'Manicaland' },
    { subCode: 'MC', name: 'Mashonaland Central' },
    { subCode: 'ME', name: 'Mashonaland East' },
    { subCode: 'MI', name: 'Midlands' },
    { subCode: 'MN', name: 'Matabeleland North' },
    { subCode: 'MS', name: 'Matabeleland South' },
    { subCode: 'MV', name: 'Masvingo' },
    { subCode: 'MW', name: 'Mashonaland West' },
  ],
  BR: [
    { subCode: 'AC', name: 'Acre' },
    { subCode: 'AL', name: 'Alagoas' },
    { subCode: 'AP', name: 'Amapá' },
    { subCode: 'AM', name: 'Amazonas' },
    { subCode: 'BA', name: 'Bahia' },
    { subCode: 'CE', name: 'Ceará' },
    { subCode: 'DF', name: 'Distrito Federal' },
    { subCode: 'ES', name: 'Espírito Santo' },
    { subCode: 'GO', name: 'Goiás' },
    { subCode: 'MA', name: 'Maranhão' },
    { subCode: 'MT', name: 'Mato Grosso' },
    { subCode: 'MS', name: 'Mato Grosso do Sul' },
    { subCode: 'MG', name: 'Minas Gerais' },
    { subCode: 'PA', name: 'Pará' },
    { subCode: 'PB', name: 'Paraíba' },
    { subCode: 'PR', name: 'Paraná' },
    { subCode: 'PE', name: 'Pernambuco' },
    { subCode: 'PI', name: 'Piauí' },
    { subCode: 'RJ', name: 'Rio de Janeiro' },
    { subCode: 'RN', name: 'Rio Grande do Norte' },
    { subCode: 'RS', name: 'Rio Grande do Sul' },
    { subCode: 'RO', name: 'Rondônia' },
    { subCode: 'RR', name: 'Roraima' },
    { subCode: 'SC', name: 'Santa Catarina' },
    { subCode: 'SP', name: 'São Paulo' },
    { subCode: 'SE', name: 'Sergipe' },
    { subCode: 'TO', name: 'Tocantins ' },
  ],
}

const allCountries = [
  { code: 'US', name: 'United States of America' },
  { code: 'CA', name: 'Canada' },
  { code: 'AX', name: 'Åland Islands' },
  { code: 'AL', name: 'Albania' },
  { code: 'DZ', name: 'Algeria' },
  { code: 'AS', name: 'American Samoa' },
  { code: 'AD', name: 'Andorra' },
  { code: 'AO', name: 'Angola' },
  { code: 'AI', name: 'Anguilla' },
  { code: 'AQ', name: 'Antarctica' },
  { code: 'AG', name: 'Antigua and Barbuda' },
  { code: 'AR', name: 'Argentina' },
  { code: 'AM', name: 'Armenia' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AU', name: 'Australia' },
  { code: 'AT', name: 'Austria' },
  { code: 'AZ', name: 'Azerbaijan' },
  { code: 'BS', name: 'Bahamas' },
  { code: 'BH', name: 'Bahrain' },
  { code: 'BD', name: 'Bangladesh' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BY', name: 'Belarus', isNotShippable: true },
  { code: 'BE', name: 'Belgium' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'BO', name: 'Bolivia (Plurinational State of)' },
  { code: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
  { code: 'BA', name: 'Bosnia and Herzegovina' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BV', name: 'Bouvet Island' },
  { code: 'BR', name: 'Brazil', isNotShippable: true },
  { code: 'IO', name: 'British Indian Ocean Territory' },
  { code: 'BN', name: 'Brunei Darussalam' },
  { code: 'BG', name: 'Bulgaria' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'CV', name: 'Cabo Verde' },
  { code: 'KH', name: 'Cambodia' },
  { code: 'CM', name: 'Cameroon' },
  { code: 'KY', name: 'Cayman Islands' },
  { code: 'CF', name: 'Central African Republic', isNotShippable: true },
  { code: 'TD', name: 'Chad' },
  { code: 'CL', name: 'Chile' },
  { code: 'CN', name: 'China' },
  { code: 'CX', name: 'Christmas Island' },
  { code: 'CC', name: 'Cocos (Keeling) Islands' },
  { code: 'CO', name: 'Colombia' },
  { code: 'KM', name: 'Comoros' },
  { code: 'CG', name: 'Congo', isNotShippable: true },
  { code: 'CD', name: 'Congo, Democratic Republic of the', isNotShippable: true },
  { code: 'CK', name: 'Cook Islands' },
  { code: 'CR', name: 'Costa Rica' },
  { code: 'CI', name: "Côte d'Ivoire" },
  { code: 'HR', name: 'Croatia' },
  { code: 'CW', name: 'Curaçao' },
  { code: 'CY', name: 'Cyprus' },
  { code: 'CZ', name: 'Czechia' },
  { code: 'DK', name: 'Denmark' },
  { code: 'DJ', name: 'Djibouti' },
  { code: 'DM', name: 'Dominica' },
  { code: 'DO', name: 'Dominican Republic' },
  { code: 'EC', name: 'Ecuador' },
  { code: 'EG', name: 'Egypt' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'GQ', name: 'Equatorial Guinea' },
  { code: 'ER', name: 'Eritrea', isNotShippable: true },
  { code: 'EE', name: 'Estonia' },
  { code: 'SZ', name: 'Eswatini' },
  { code: 'ET', name: 'Ethiopia' },
  { code: 'FK', name: 'Falkland Islands (Malvinas)' },
  { code: 'FO', name: 'Faroe Islands' },
  { code: 'FJ', name: 'Fiji' },
  { code: 'FI', name: 'Finland' },
  { code: 'FR', name: 'France' },
  { code: 'GF', name: 'French Guiana' },
  { code: 'PF', name: 'French Polynesia' },
  { code: 'TF', name: 'French Southern Territories' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambia' },
  { code: 'GE', name: 'Georgia' },
  { code: 'DE', name: 'Germany' },
  { code: 'GH', name: 'Ghana' },
  { code: 'GI', name: 'Gibraltar' },
  { code: 'GR', name: 'Greece' },
  { code: 'GL', name: 'Greenland' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GP', name: 'Guadeloupe' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GG', name: 'Guernsey' },
  { code: 'GN', name: 'Guinea' },
  { code: 'GW', name: 'Guinea-Bissau' },
  { code: 'GY', name: 'Guyana' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HM', name: 'Heard Island and McDonald Islands' },
  { code: 'VA', name: 'Holy See' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'HU', name: 'Hungary' },
  { code: 'IS', name: 'Iceland' },
  { code: 'IN', name: 'India', isNotShippable: true },
  { code: 'ID', name: 'Indonesia' },
  { code: 'IQ', name: 'Iraq', isNotShippable: true },
  { code: 'IE', name: 'Ireland' },
  { code: 'IM', name: 'Isle of Man' },
  { code: 'IL', name: 'Israel' },
  { code: 'IT', name: 'Italy' },
  { code: 'JM', name: 'Jamaica' },
  { code: 'JP', name: 'Japan' },
  { code: 'JE', name: 'Jersey' },
  { code: 'JO', name: 'Jordan' },
  { code: 'KZ', name: 'Kazakhstan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'KR', name: 'Korea, Republic of' },
  { code: 'KW', name: 'Kuwait' },
  { code: 'KG', name: 'Kyrgyzstan' },
  { code: 'LA', name: "Lao People's Democratic Republic" },
  { code: 'LV', name: 'Latvia' },
  { code: 'LB', name: 'Lebanon' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LR', name: 'Liberia' },
  { code: 'LY', name: 'Libya', isNotShippable: true },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Lithuania' },
  { code: 'LU', name: 'Luxembourg' },
  { code: 'MO', name: 'Macao' },
  { code: 'MG', name: 'Madagascar' },
  { code: 'MW', name: 'Malawi' },
  { code: 'MY', name: 'Malaysia' },
  { code: 'MV', name: 'Maldives' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Islands' },
  { code: 'MQ', name: 'Martinique' },
  { code: 'MR', name: 'Mauritania' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'MX', name: 'Mexico' },
  { code: 'FM', name: 'Micronesia (Federated States of)' },
  { code: 'MD', name: 'Moldova, Republic of' },
  { code: 'MC', name: 'Monaco' },
  { code: 'MN', name: 'Mongolia' },
  { code: 'ME', name: 'Montenegro' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MA', name: 'Morocco' },
  { code: 'MZ', name: 'Mozambique' },
  { code: 'NA', name: 'Namibia' },
  { code: 'NR', name: 'Nauru' },
  { code: 'NP', name: 'Nepal' },
  { code: 'NL', name: 'Netherlands' },
  { code: 'NC', name: 'New Caledonia' },
  { code: 'NZ', name: 'New Zealand' },
  { code: 'NI', name: 'Nicaragua', isNotShippable: true },
  { code: 'NE', name: 'Niger' },
  { code: 'NG', name: 'Nigeria' },
  { code: 'NU', name: 'Niue' },
  { code: 'NF', name: 'Norfolk Island' },
  { code: 'MK', name: 'North Macedonia' },
  { code: 'MP', name: 'Northern Mariana Islands' },
  { code: 'NO', name: 'Norway' },
  { code: 'OM', name: 'Oman' },
  { code: 'PK', name: 'Pakistan', isNotShippable: true },
  { code: 'PW', name: 'Palau' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua New Guinea' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PH', name: 'Philippines' },
  { code: 'PN', name: 'Pitcairn' },
  { code: 'PL', name: 'Poland' },
  { code: 'PT', name: 'Portugal' },
  { code: 'PR', name: 'Puerto Rico' },
  { code: 'QA', name: 'Qatar' },
  { code: 'RE', name: 'Réunion' },
  { code: 'RO', name: 'Romania' },
  { code: 'RW', name: 'Rwanda' },
  { code: 'BL', name: 'Saint Barthélemy' },
  { code: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
  { code: 'KN', name: 'Saint Kitts and Nevis' },
  { code: 'LC', name: 'Saint Lucia' },
  { code: 'MF', name: 'Saint Martin (French part)' },
  { code: 'PM', name: 'Saint Pierre and Miquelon' },
  { code: 'VC', name: 'Saint Vincent and the Grenadines' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'ST', name: 'Sao Tome and Principe' },
  { code: 'SA', name: 'Saudi Arabia' },
  { code: 'SN', name: 'Senegal' },
  { code: 'RS', name: 'Serbia' },
  { code: 'SC', name: 'Seychelles' },
  { code: 'SG', name: 'Singapore' },
  { code: 'SX', name: 'Sint Maarten (Dutch part)' },
  { code: 'SK', name: 'Slovakia' },
  { code: 'SI', name: 'Slovenia' },
  { code: 'SB', name: 'Solomon Islands' },
  { code: 'SO', name: 'Somalia', isNotShippable: true },
  { code: 'ZA', name: 'South Africa' },
  { code: 'GS', name: 'South Georgia and the South Sandwich Islands' },
  { code: 'ES', name: 'Spain' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'SR', name: 'Suriname' },
  { code: 'SJ', name: 'Svalbard and Jan Mayen' },
  { code: 'SE', name: 'Sweden' },
  { code: 'CH', name: 'Switzerland' },
  { code: 'TW', name: 'Taiwan, Province of China' },
  { code: 'TJ', name: 'Tajikistan' },
  { code: 'TZ', name: 'Tanzania, United Republic of' },
  { code: 'TH', name: 'Thailand' },
  { code: 'TL', name: 'Timor-Leste' },
  { code: 'TG', name: 'Togo' },
  { code: 'TK', name: 'Tokelau' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad and Tobago' },
  { code: 'TN', name: 'Tunisia' },
  { code: 'TR', name: 'Turkey' },
  { code: 'TM', name: 'Turkmenistan' },
  { code: 'TC', name: 'Turks and Caicos Islands' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukraine' },
  { code: 'AE', name: 'United Arab Emirates' },
  { code: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland' },
  { code: 'UM', name: 'United States Minor Outlying Islands' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'UZ', name: 'Uzbekistan' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VN', name: 'Viet Nam' },
  { code: 'VG', name: 'Virgin Islands (British)' },
  { code: 'VI', name: 'Virgin Islands (U.S.)' },
  { code: 'WF', name: 'Wallis and Futuna' },
  { code: 'EH', name: 'Western Sahara' },
  { code: 'YE', name: 'Yemen', isNotShippable: true },
  { code: 'ZM', name: 'Zambia' },
  { code: 'ZW', name: 'Zimbabwe', isNotShippable: true },
]

/**
 * Given the ISO state code, return the full state name
 *   - this is for sending orders into dear
 *
 * @param {str} code the country code
 * @return {str} state name
 */
export function getFullStateName(countryCode, stateCode) {
  const state = allStates[countryCode].filter(({ subCode }) => subCode === stateCode)
  return state[0].name
}

/**
 * Return a list of all regions (ISO-3166-2) for the corresponding country code
 *
 * @param {str} code the country code
 * @return {list} a list of all sub regions for the country
 */
export function getStates(code) {
  return allStates[code]
}

/**
 * Return a list of all countries with their ISO-3166 code
 *
 * @return {list} list of all countries
 */
export function getCountries() {
  return allCountries
}
