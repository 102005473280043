import React from 'react'
import { Card, Typography, Stack, CardHeader, Tooltip, Button } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Info } from '@mui/icons-material'

interface PreviewCardProps {
  title: string
  contents?: React.ReactNode[]
  actions?: React.ReactNode[]
  uuid?: string
  tooltipTitle?: string
}
interface CardContentProps {
  label: string
  value: React.ReactNode
  // css props
  fontWeight?: number
}

// boilerplate for cards in client details
export const PreviewCard: React.FC<PreviewCardProps> = ({
  title,
  tooltipTitle,
  uuid,
  children,
}) => {
  return (
    <Card variant="outlined" sx={{ p: 2, m: 2 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center" width="100%" height="2rem">
            <span>{title}</span>
            {tooltipTitle && (
              <Tooltip
                title={
                  <Typography variant="body2" color="#FFF">
                    {tooltipTitle}
                  </Typography>
                }
              >
                <Info sx={{ ml: 1, height: '100%', width: 30, color: '#058181' }} />
              </Tooltip>
            )}
          </Stack>
        }
        action={
          uuid && (
            <Button size="small" endIcon={<ContentCopyIcon color="primary" />} color="info">
              Client ID: {uuid}
            </Button>
          )
        }
      />
      {children}
    </Card>
  )
}

/**
 * Only `preview-basic-info` uses children value
 */
export const CardContentItem: React.FC<CardContentProps> = ({
  label,
  value,
  children,
  fontWeight,
}) => {
  return (
    <Stack direction="row" key={label} alignItems="center">
      <Typography variant="body2" sx={{ minWidth: '8rem' }}>
        {label}
      </Typography>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography variant="body2" sx={{ width: '100%', fontWeight }}>
          {value}
        </Typography>
        {children}
      </Stack>
    </Stack>
  )
}
