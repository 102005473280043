import React from 'react'
import { Dialog, DialogContent, DialogTitle, Grid, Button } from 'components'

export default ({ title, content, open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: 'var(--green)',
          textAlign: 'center',
          color: '#fff',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent className="p-0">
        <Grid>{content}</Grid>
        {onClose && (
          <Grid container justifyContent="center" className="pb-2">
            <Button color="secondary" onClick={onClose} data-test="close-intro-modal">
              Close
            </Button>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
