import React from 'react'
import { IconButton, InputBase } from '@mui/material'

import withStyles from '@mui/styles/withStyles'

import { Search } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'

const Input = withStyles(() => ({
  root: {
    borderRadius: '4px',
    backgroundColor: '#eef7f3',
    marginRight: '16px',
  },
  input: {
    fontSize: 14,
    padding: '13px 16px 13px 16px',
  },
}))(InputBase)

export default ({ onChange, width, ...props }) => {
  const handleClearSearch = () => {
    const clearVal = { target: { value: '' } }
    if (props.value !== '') {
      onChange(clearVal)
    }
  }
  return (
    <Input
      {...props}
      autoComplete="off"
      startAdornment={
        <IconButton aria-label="search" disabled>
          <Search fontSize="small" />
        </IconButton>
      }
      endAdornment={
        <IconButton aria-label="clear-search" onClick={handleClearSearch}>
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      onChange={(e) => onChange({ ...e, target: { ...e.target, value: e.target.value } })}
      sx={{ width }}
    />
  )
}
