const hhmmss = (val, format = 'hh mm ss') => {
  let minutes = Math.floor(val / 60)
  let secs = val % 60
  let hours = Math.floor(minutes / 60)
  minutes = minutes % 60

  secs === 0 ? (format = format.replace('ss', '')) : (format = format.replace('s', secs))
  minutes === 0 && (hours === 0 || secs === 0)
    ? (format = format.replace('mm', ''))
    : (format = format.replace('m', minutes))
  hours === 0 ? (format = format.replace('hh', '')) : (format = format.replace('h', hours))

  return format.trim()
}

export default hhmmss
