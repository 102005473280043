/**
 * This is the generic card for dashboard
 */
import React from 'react'
import {
  Grid,
  Card,
  CardHeader,
  CardContent,
  Typography as MuiTypography,
  Box,
  Link,
} from 'components'

export const Typography = ({ children, ...props }) => {
  return (
    <MuiTypography variant="body2" {...props}>
      {children}
    </MuiTypography>
  )
}

// components
export const BulletLink = ({ externalURI, internalURI, children }) => {
  const uri = externalURI || internalURI || ''
  return (
    <>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            flexShrink: 0,
            color: '#008080',
            margin: '1rem',
          }}
        />
        <Typography variant="body2">
          <span>Click</span>
          <Link
            className="text-link underlined px-1 font-medium"
            to={uri}
            target={externalURI ? '_blank' : ''}
          >
            here
          </Link>
          <span>{children}</span>
        </Typography>
      </Box>
    </>
  )
}

interface DashboardCardProps {
  id: string
  dataTestLabel?: string
  title: string
  gridProps?: any // Specify a more specific type if you know the structure of gridProps
  children: React.ReactNode
}

export const DashboardCard: React.FC<DashboardCardProps> = ({
  id,
  dataTestLabel,
  title,
  gridProps,
  children,
}) => {
  return (
    <Grid item {...gridProps} key={id}>
      <Card
        sx={{
          height: '100%',
          backgroundColor: '#edf2f7',
        }}
        elevation={0}
        data-test={dataTestLabel}
      >
        <CardHeader title={title} titleTypographyProps={{ variant: 'h5' }} />
        <CardContent data-test="dashboard-card-body2">{children}</CardContent>
      </Card>
    </Grid>
  )
}
