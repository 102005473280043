import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Container, Typography } from 'components'
import { useSelector } from 'react-redux'
import EMAILS from 'utils/constants/emails'
import { BILLING_TABS } from './tabs'

export default function FocusClientLicenses() {
  const {
    hasSspProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
    })
  )

  return (
    <MainLayout title="My Billing" tabs={tabs}>
      <Container className="pt-5">
        <Typography className="pb-5" variant="body1">
          Your ILS subscription includes up to 50 client licenses. A license is consumed when a
          client listens to any length of an ILS program. Your client license count resets on
          subscription renewal.
        </Typography>
        <Typography className="pb-5" variant="body1">
          For assistance or questions about client licenses, please contact{' '}
          <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
            {EMAILS.supportEmail}
          </a>
          .
        </Typography>
        <Typography variant="body1">Coming soon - details of your client license usage.</Typography>
      </Container>
    </MainLayout>
  )
}
