/**
 * This Password Input is created with exapmle:
 * https://mui.com/material-ui/react-text-field/#input-adornments
 *
 * This is a 2 part component:
 * - this part contains input validation
 * - Confirm Password contains matching validation
 */
import React, { useState, useEffect } from 'react'
import IconButton from '@mui/material/IconButton'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import CustomInput from './CustomInput'

export default function Mui5PasswordInput({
  values,
  setValues,
  label,
  showPassword,
  setShowPassword,
  overrideError,
  setValidity,
  ...props
}) {
  const [error, setErrors] = useState('')

  const handleChange = (event) => setValues(event.target.value)
  const handleMouseDownPassword = (event) => {
    event.preventDefault()
  }

  useEffect(() => {
    if (values.length < 1) {
      setErrors(overrideError)
      setValidity(false)
    } else if (values.length < 8) {
      setErrors(
        'Password must be 8 characters or longer and contain at least one number and lowercase letter.'
      )
      setValidity(false)
    } else if (!/[0-9]/.test(values)) {
      setErrors(
        'Password must be 8 characters or longer and contain at least one number and lowercase letter.'
      )
      setValidity(false)
    } else if (!/[a-z]/.test(values)) {
      setErrors(
        'Password must be 8 characters or longer and contain at least one number and lowercase letter.'
      )
      setValidity(false)
    } else {
      setErrors(overrideError)
      if (overrideError === '') {
        setValidity(true)
      } else {
        setValidity(false)
      }
    }

    // eslint-disable-next-line
  }, [values, overrideError])

  return (
    <FormControl error={!!error} sx={{ width: '100%', marginBottom: '1rem' }}>
      <InputLabel required htmlFor={`input-label-${label}`}>
        {label}
      </InputLabel>
      <CustomInput
        {...props}
        label={label}
        required
        autoComplete="new-password"
        id={`input-${label}`}
        type={showPassword ? 'text' : 'password'}
        value={values}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={setShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
      {error && <FormHelperText id={`helper-text-${label}`}>{error}</FormHelperText>}
    </FormControl>
  )
}
