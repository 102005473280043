import React from 'react'
import { useOutletContext } from 'react-router'
import { FooterCard } from './components/cards'
import { CLIENT_CARDS } from './constants/cards2'
import { Container, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

export default function ClientDashboard() {
  const {
    isClient,
    products,
    hasAssessmentProducts,
    hasSspSessions,
    hasSspProducts,
    hasFocusProducts,
    hasFocusSessions,
    loading,
    hasRrpProducts,
    hasRrpSessions,
  } = useOutletContext()
  const { sentAssessments } = useSelector((state) => state.sessions)
  const user = useSelector((state) => get(state, 'auth.user', {}))

  if (!isClient || loading) {
    return null
  }

  const clientCards = CLIENT_CARDS.filter(({ isActive }) =>
    isActive({
      hasAssessmentProducts,
      hasSspProducts,
      hasSspSessions,
      products,
      sentAssessments,
      hasFocusProducts,
      hasFocusSessions,
      hasRrpProducts,
      hasRrpSessions,
    })
  )

  return (
    <Container maxWidth="xl">
      <Grid container spacing={2} m={0}>
        {clientCards?.map(({ Card, gridProps, ...props }) => (
          <Card {...props} gridProps={{ xs: 12, sm: 4, md: 4, lg: 4, ...gridProps }} user={user} />
        ))}
      </Grid>

      {/* show the download image when user has no sessions but has products*/}
      <FooterCard
        showDownloadImage={
          (hasFocusProducts || hasRrpProducts || hasSspProducts) &&
          !(hasSspSessions || hasFocusSessions || hasRrpSessions)
        }
      />
    </Container>
  )
}
