/**
 * Source: https://usehooks.com/useLocalStorage/
 */
import { useState } from 'react'

// Hook
export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window?.localStorage?.getItem(key)

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value

      // Save state
      setStoredValue(valueToStore)

      // Save to local storage
      window?.localStorage?.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return [storedValue, setValue]
}

// Save localstorage with redux
export const loadState = () => {
  try {
    const _serializedState = JSON.parse(window?.localStorage?.getItem('state'))
    if (_serializedState === null) {
      return undefined
    }

    // initial state
    const serializedState = {
      newPurchase: {
        ..._serializedState.newPurchase,

        step: 0,
        data: {
          selectedAddOns: {},
          clientLicenses: {},
          onboardingProducts: [],
          selectedServiceCode: '',
          serviceCode: '',
          shipstationRates: [],
        },
      },
      updateNotification: {
        ..._serializedState.updateNotification,
        currentSession: false,
      },
      resources: {
        ..._serializedState.resources,
      },
    }

    return serializedState
  } catch (err) {
    return undefined
  }
}

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    window?.localStorage?.setItem('state', serializedState)
  } catch (err) {
    // Ignore write errors.
    console.error('unable to save state to localstorage', err, state)
  }
}
