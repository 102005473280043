//imports
import React, { useState } from 'react'
import {
  Button,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useOutletContext } from 'react-router'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { useMutation, gql } from '@apollo/client'

// custom imports
import {
  saveCurrentFilter,
  setAssessmentFilterProductEventId,
  setAssessmentName,
  setFilterUserId,
  setUser,
} from 'store/modules/assessments'

// gql
const DELETE_ASSESSMENT = gql`
  mutation Mutation($deleteSessionId: Int!) {
    deleteSession(id: $deleteSessionId)
  }
`

// components
export default function AssessmentDetails({ data = {} }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { hasSspProducts, hasFocusProducts } = useSelector((state) => state.ff)
  const {
    setLoadingStateWithTimer,
    setLoadingMessage,
    onLoadKPI,
    loadAssessments,
    getLoadAssessmentVariables,
    transformAssessmentsAndSave,
  } = useOutletContext()

  const { isInProgress, isCompleted } = ASSESSMENT_STATUS
  const variant = get(data, 'metadata.variant', '')
  const userId = get(data, 'userId', null)
  const status = get(data, 'data.status', '')
  const id = get(data, 'id', null)
  const isIntakeForm = get(data, 'metadata.isIntakeForm', false)

  // this is triggered when a user clicks on view response or continue assessment
  // 'View Responses' : 'Continue Assessment'}
  const handleUpdateAssessment = () => {
    // add user email to redux
    dispatch(
      setUser({
        ...data.user,
        userId: data.user?.id,
      })
    )
    dispatch(saveCurrentFilter())
    navigate(`/assessments/update/${userId}/${id}`)
  }

  const handleSummaryClick = () => {
    dispatch(saveCurrentFilter())
    dispatch(
      setUser({
        ...data.user,
        userId: data.user?.id,
      })
    )
    const productName = variant
      ? `${get(data, 'productName', '')} - ${variant}`
      : get(data, 'productName', '')
    const productEventId = get(data, 'productEventId', undefined)
    dispatch(setAssessmentName(productName))
    productEventId && dispatch(setAssessmentFilterProductEventId(productEventId))
    dispatch(setFilterUserId(data.user?.id))
    navigate(`/assessments/reports/${userId}`)
  }

  const [confirmModal, setConfirmModal] = useState(false)
  const [deleteAssessment] = useMutation(DELETE_ASSESSMENT)
  const handleDelete = async () => {
    try {
      setConfirmModal(false)
      const variables = getLoadAssessmentVariables()
      await setLoadingStateWithTimer(true)
      await setLoadingMessage('deleting assessment...')
      await deleteAssessment({ variables: { deleteSessionId: data.id } })

      onLoadKPI()
      const response = await loadAssessments({ variables })
      await transformAssessmentsAndSave(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingStateWithTimer(false)
    }
  }

  const handleOpenDeleteDialog = () => {
    setConfirmModal(true)
  }

  return (
    <>
      <Dialog open={confirmModal} onClose={() => setConfirmModal(false)}>
        <DialogTitle
          sx={{
            backgroundColor: '#008080',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography pt={5}>
            Are you sure you want to permanently delete this assessment?
          </Typography>

          <Typography pt={2}>This action cannot be undone.</Typography>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={() => setConfirmModal(false)}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => handleDelete()}>
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Stack spacing={3} py={1} alignItems="flex-start">
        <Stack direction="row" spacing={1}>
          {(hasSspProducts || hasFocusProducts) && (
            <Button mt={3} onClick={handleUpdateAssessment}>
              {!isInProgress(status) ? 'View Responses' : 'Continue Assessment'}
            </Button>
          )}
          {isCompleted(status) && !isIntakeForm && (
            <Button onClick={handleSummaryClick}>View Summary</Button>
          )}
          {!isCompleted(status) && (
            <Button mt={3} onClick={handleOpenDeleteDialog} color="secondary">
              Delete Assessment
            </Button>
          )}
        </Stack>
      </Stack>
    </>
  )
}
