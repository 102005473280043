import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '../components'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { extendData } from 'store/modules/new-purchase'
import EMAILS from 'utils/constants/emails'
import { get } from 'lodash'
import useGTM from 'utils/hooks/useGTM'
import ROLES from '../utils/constants/roles'
import { includesSome } from '../utils/includes'
import { showRRPStoreFront } from 'utils/permissions/permissionsLogic'

const useStyles = makeStyles({
  container: {
    maxWidth: '1080px',
    margin: '0 auto',
  },
  cardActions: {
    margin: '0 auto',
  },
  card: {
    minHeight: '330px',
  },
  description: {
    minHeight: '61px',
  },
})
export default function Store() {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { setMainLayoutTitle, loading, setLoading } = useOutletContext()

  const org = useSelector((state) => get(state, 'organization', {}))
  const ff = useSelector((state) => state.ff)
  const authUser = useSelector((state) => get(state, 'auth.user', {}))

  const {
    hasOrgSspCertificationEnrolled,
    hasOrgSspCertificationCompleted,
    hasOrgRrpCertificationCompleted,
    hasOrgFocusCertificationEnrolled,
    hasOrgFocusCertificationCompleted,
    hasPaidSspSubscription,
    hasPastDueSspSubscription,
    hasPendingSspSubscription,
    hasUnpaidSspSubscription,
    hasPaidRrpSubscription,
    hasUnpaidRrpSubscription,
    hasPastDueRrpSubscription,
    hasPendingRrpSubscription,
    hasPaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasPendingFocusSubscription,
    hasDisabledSspSubscription,
    hasDisabledFocusSubscription,
    hasUnpaidFocusSubscription,
  } = org
  const { showNewSubscriptionPlan } = ff

  const { roles: userRoles, productPreferences: userProductPreferences } = authUser

  const isProviderRole = includesSome(userRoles, ROLES.PROVIDER_ROLES)
  const isBillingRole = userRoles.includes(ROLES.BILLING)
  const isBillingOnlyRole = userRoles.includes(ROLES.BILLING) && !isProviderRole

  const isSspPrecertification =
    userProductPreferences.sspCertification?.enrolledAt &&
    !userProductPreferences.sspCertification?.completedAt
  const hasActiveSspSub =
    hasPaidSspSubscription || hasPastDueSspSubscription || hasPendingSspSubscription

  const isFocusPrecertification =
    userProductPreferences.focusCertification?.enrolledAt &&
    !userProductPreferences.focusCertification?.completedAt
  const hasActiveSspSubscription =
    (hasPaidSspSubscription || hasPastDueSspSubscription || hasPendingSspSubscription) &&
    !hasUnpaidSspSubscription
  const hasActiveRrpSubscription =
    (hasPaidRrpSubscription || hasPastDueRrpSubscription || hasPendingRrpSubscription) &&
    !hasUnpaidRrpSubscription
  const hasActiveFocusSubscription =
    (hasPaidFocusSubscription || hasPastDueFocusSubscription || hasPendingFocusSubscription) &&
    !hasUnpaidFocusSubscription

  const storeItems = [
    {
      title: 'Safe and Sound Protocol',
      description: (
        <>
          <Box>
            Purchase Foundational SSP Training or an SSP Subscription.
            <br />
            {isBillingOnlyRole &&
              hasOrgSspCertificationEnrolled &&
              !hasOrgSspCertificationCompleted && (
                <>
                  Please contact{' '}
                  <a className="text-link text-semibold" href={`mailto:${EMAILS.supportEmail}`}>
                    support
                  </a>{' '}
                  for more information
                </>
              )}
          </Box>
        </>
      ), // contact support to know why this option is disabled..

      button: 'get started',
      img: '/images/store/header-sciencesafe-275x150.png',
      link: '/store/ssp',
      isActive:
        !(hasPastDueSspSubscription || hasUnpaidSspSubscription) &&
        !hasDisabledSspSubscription &&
        ((isProviderRole &&
          !isSspPrecertification &&
          !(userProductPreferences.sspCertification?.completedAt && hasActiveSspSub)) || // provider or provider+billing to see purchase options
        isBillingOnlyRole || // show to billing only user with untrained providers
          (userRoles.includes(ROLES.BILLING) &&
            hasOrgSspCertificationCompleted &&
            !hasActiveSspSub)), // billing to purchase sub for providers
      // disable button when user do not have completed certification, because they should do that first
      disableButton:
        isBillingOnlyRole && hasOrgSspCertificationEnrolled && !hasOrgSspCertificationCompleted,
      testName: 'ssp-store-card',
    },
    {
      title: 'Integrated Listening System',
      description: (
        <>
          <Box>
            Purchase Foundational ILS Training or the ILS Subscription.
            <br />
            {isBillingOnlyRole &&
              hasOrgFocusCertificationEnrolled &&
              !hasOrgFocusCertificationCompleted && (
                <>
                  Please contact{' '}
                  <a
                    className="text-link text-semibold"
                    href={`mailto:${EMAILS.supportEmail}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    support
                  </a>{' '}
                  for more information
                </>
              )}{' '}
          </Box>
        </>
      ), // contact support to know why this option is disabled..
      button: 'get started',
      img: '/images/store/edu-275x150.jpg',
      link: '/store/focus',
      isActive:
        !(hasPastDueFocusSubscription || hasUnpaidFocusSubscription) &&
        !hasDisabledFocusSubscription &&
        ((isProviderRole &&
          !isFocusPrecertification &&
          !(
            userProductPreferences.focusCertification?.completedAt && hasActiveFocusSubscription
          )) ||
          isBillingOnlyRole ||
          (userRoles.includes(ROLES.BILLING) &&
            hasOrgFocusCertificationCompleted &&
            !hasActiveFocusSubscription)),
      // disable button when user do not have commpleted certification, because they should do that first
      disableButton:
        isBillingOnlyRole && hasOrgFocusCertificationEnrolled && !hasOrgFocusCertificationCompleted,
      testName: 'ils-store-card',
    },
    {
      title: 'Rest and Restore Protocol',
      description: (
        <>
          <Box>Purchase RRP Training or an RRP Subscription.</Box>
        </>
      ),
      button: 'get started',
      img: '/images/store/rrp-store-card.jpg',
      link: '/store/rrp',
      isActive: showRRPStoreFront({ org, ff, authUser }),
      disableButton: false,
      testName: 'rrp-store-card',
    },
    {
      title: 'Accessories',
      description: 'Purchase accessories.',
      button: 'get started',
      img: '/images/store/accessories-v2.png',
      link: '/purchase/accessories',
      isActive:
        userRoles.includes(ROLES.BILLING) &&
        (userProductPreferences.focusCertification || userProductPreferences.sspCertification),
      testName: 'accessories-store-card',
    },
    {
      title: 'Client Licenses',
      description: 'Purchase additional Client Licenses.',
      button: 'get started',
      img: '/images/store/clientLicenses.png',
      link: '/store/client-licenses',
      isActive:
        userRoles.includes(ROLES.BILLING) &&
        showNewSubscriptionPlan &&
        ((hasActiveSspSubscription && userProductPreferences.sspCertification?.completedAt) ||
          (hasActiveFocusSubscription && userProductPreferences.focusCertification?.completedAt) ||
          (hasActiveRrpSubscription && userProductPreferences.rrpCertification?.completedAt) ||
          (isBillingOnlyRole &&
            ((hasOrgFocusCertificationCompleted && hasActiveFocusSubscription) ||
              (hasOrgSspCertificationCompleted && hasActiveSspSubscription) ||
              (hasOrgRrpCertificationCompleted && hasActiveRrpSubscription)))),
      testName: 'licenses-store-card',
    },
  ].filter(({ isActive }) => !!isActive)

  const onClick = (link) => () => {
    navigate(link)
  }

  // going back to storefront will reset redux store
  useEffect(() => {
    dispatch(
      extendData({
        onboardingProducts: [],
        selectedAddOns: {},
        products: [],
        selectedServiceCode: '',
        totalTax: 0,
      })
    )
    // eslint-disable-next-line
  }, [])

  // bandaid loading
  const { pageView } = useGTM()
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 1000)

    // on load - add virtual page view to GTM
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - My Unyte Store',
    })
    setMainLayoutTitle('Purchase')
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={3}
      className={classes.container}
    >
      {!loading && !isBillingRole && (
        <Grid item>
          <Typography variant="body1" component="p" pt={10}>
            Please contact your plan administrator to make any additional purchases.
          </Typography>
        </Grid>
      )}
      {!loading &&
        isBillingRole &&
        storeItems.map(({ button, title, description, img, link, disableButton, testName }) => (
          <Grid item lg={4} xs={12} sm={6} zeroMinWidth>
            <Card className={classes.card} data-test={testName}>
              <CardMedia component="img" alt={title} height="140" image={img} title={title} />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p"
                  className={classes.description}
                >
                  {description}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  className="m-auto"
                  color="primary"
                  onClick={onClick(link)}
                  disabled={disableButton}
                >
                  {button}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
    </Grid>
  )
}
