/**
 * Refactored do not show again dialog to its own component since its being used frequently
 */
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import useLocalStorage from 'utils/hooks/useLocalStorage'

export const DoNotShowAgainDialog = ({ id, title, children }) => {
  const [state, setState] = useState({ open: true, checked: false })
  const [localStorageState, setLocalStorageState] = useLocalStorage(id, false)

  // on load hide or show modal
  useEffect(() => {
    if (localStorageState === true) {
      setState({ ...state, open: false })
    }
  }, [localStorageState])

  const handleClose = () => {
    if (state.checked) {
      setLocalStorageState(true)
    }
    setState({ ...state, open: false })
  }

  const handleCheckbox = (event) => {
    setState({ ...state, checked: event.target.checked })
  }

  if (!state?.open) {
    return null
  }

  return (
    <Dialog open={state?.open} maxWidth="md" fullWidth>
      <DialogTitle color="white" sx={{ backgroundColor: 'var(--green)' }} align="center">
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="center" width="100%" alignItems="center">
          <Grid item xs={9}>
            <FormControl>
              <FormControlLabel
                label="Do not show again"
                control={<Checkbox checked={state.checked} onChange={handleCheckbox} />}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Button color="primary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
