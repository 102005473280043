import React from 'react'
import { Button, Dialog, DialogContent, DialogTitle as MuiDialogTitle } from 'components'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'

export default function AssessmentConfirmDialog({
  dialogOpen,
  dialogTitle,
  dialogContentText,
  cancelOnClick,
  cancelText,
  confirmOnClick,
  confirmText,
}) {
  return (
    <>
      <Dialog
        open={dialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <MuiDialogTitle id="alert-dialog-title">{dialogTitle}</MuiDialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{dialogContentText}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelOnClick} color="primary">
            {cancelText}
          </Button>
          <Button
            data-test="assessment-confirm-dialog-button"
            onClick={confirmOnClick}
            color="primary"
          >
            {confirmText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
