import React from 'react'
import { Card, Stack, Table, TableBody, TableCell, TableRow, Typography } from 'components'

export default function ClientContactInfo({ connecteeSubmission }) {
  return (
    <Card>
      <Stack direction="column" padding={2}>
        <Typography variant="h5">Potential client's contact info:</Typography>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell width="50%">
                <Typography>
                  {connecteeSubmission.firstName} {connecteeSubmission.lastName}
                </Typography>
              </TableCell>
              <TableCell width="50%">
                <Typography>{connecteeSubmission.phone}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <Typography>{connecteeSubmission.email}</Typography>
              </TableCell>
            </TableRow>
            {!!connecteeSubmission.howToContact && (
              <TableRow>
                <TableCell>
                  <Typography>{connecteeSubmission.howToContact}</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Stack>
    </Card>
  )
}
