import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, FormHelperText, PasswordInput, CardContent, CardActions } from 'components'

import styles from './ResetPassword.module.scss'

import { logout, resetPassword } from 'store/modules/auth'

import CardWithBg from 'components/CardWithBg'
import { useNavigate, useParams } from 'react-router'

export default function ResetPassword() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { code, encodedEmail } = useParams()

  const [errors, setErrors] = useState()
  const [form, setForm] = useState({
    password: '',
    confirmedPassword: '',
  })
  const [status, setStatus] = useState({
    pending: false,
    open: false,
    showPassword: false,
  })
  const [redirect, setRedirect] = useState(false)

  const decodedEmail = decodeURIComponent(encodedEmail)

  useEffect(() => {
    dispatch(logout())
    // eslint-disable-next-line
  }, [logout])

  const onSubmit = async (e) => {
    e.preventDefault()

    try {
      setErrors()
      setStatus({ ...status, pending: true })
      await dispatch(resetPassword(form.password, form.confirmedPassword, decodedEmail, code))
      setStatus({ ...status, pending: false })
      setRedirect(true)
    } catch (error) {
      setStatus({ ...status, pending: false })
      setErrors(JSON.stringify(error.message))
    }
  }

  const errorCodes = [
    `"Invalid verification code provided, please try again."`,
    `"Reset link has expired, click 'Resend Link' for a new link."`,
  ]
  const customError = errorCodes.includes(errors) ? (
    <>
      Sorry but this reset password link has expired. Please request a new link{' '}
      <a href="/forgot-password">here</a>.
    </>
  ) : (
    errors
  )

  const onClick = () => {
    navigate('/sign-in')
  }

  return (
    <CardWithBg>
      {redirect ? (
        <>
          {' '}
          <div className={styles.resetSuccessTitle}>
            Password for <b>{decodedEmail}</b> successfully reset
          </div>
          <div className={styles.caption}>
            <Button color="primary" onClick={onClick}>
              Sign In to MyUnyte
            </Button>
          </div>{' '}
        </>
      ) : (
        <>
          <form className={styles.email} onSubmit={onSubmit}>
            <CardContent>
              <h1 className={styles.resetTitle} style={{ paddingTop: '1rem' }}>
                Reset MyUnyte Password
              </h1>
              <div className={styles.caption}>
                Enter your new MyUnyte password for: <b>{decodedEmail}</b>{' '}
              </div>

              <div className={styles.caption}>
                Password must be 8 characters or longer and contain at least one number and
                lowercase letter.
              </div>

              <PasswordInput
                id="standard-basic"
                label="New Password"
                fullWidth
                className="mb-5"
                value={form.password}
                onChange={(e) => setForm({ ...form, password: e.target.value })}
              />
              <PasswordInput
                id="standard-basic"
                label="Confirm New Password"
                fullWidth
                className="mb-2"
                value={form.confirmedPassword}
                onChange={(e) => setForm({ ...form, confirmedPassword: e.target.value })}
              />
              <FormHelperText error>{customError}</FormHelperText>
            </CardContent>

            <CardActions>
              <Button type="submit" color="primary" loading={status.pending}>
                Reset Password
              </Button>
            </CardActions>
          </form>
        </>
      )}
    </CardWithBg>
  )
}
