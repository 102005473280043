// src/PieChart.tsx
import React from 'react'
import { Box, Typography, Tooltip } from '@mui/material'

// utils
export const getPosition = (radius: number, angle: number): string => {
  const radians = (angle * Math.PI) / 180
  const xPos = radius * Math.cos(radians) * 1 + 50
  const yPos = radius * Math.sin(radians) * 1 + 50
  return `${xPos}% ${yPos}%`
}

export const getClipPath = (angle: number): string => {
  if (angle <= 45) {
    return `polygon(50% 50%, 100% 50%, ${getPosition(200, angle)})`
  } else if (angle <= 90) {
    return `polygon(50% 50%, 100% 50%, 100% 100%, ${getPosition(200, angle)})`
  } else if (angle <= 135) {
    return `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, ${getPosition(200, angle)})`
  } else if (angle <= 180) {
    return `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, ${getPosition(200, angle)})`
  } else if (angle <= 225) {
    return `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, ${getPosition(
      200,
      angle
    )})`
  } else if (angle <= 270) {
    return `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, ${getPosition(
      200,
      angle
    )})`
  } else if (angle <= 315) {
    return `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, ${getPosition(
      200,
      angle
    )})`
  } else {
    return `polygon(50% 50%, 100% 50%, 100% 100%, 50% 100%, 0 100%, 0 50%, 0 0, 50% 0, 100% 0, ${getPosition(
      200,
      angle
    )})`
  }
}

interface PieChartProps {
  values: number[]
}

const PieChart: React.FC<PieChartProps> = ({ values }) => {
  const total = values.reduce((acc, value) => acc + value, 0)
  const angles = values.map((value) => (value / total) * 360)

  const slices = angles.map((angle, index) => {
    const startAngle = angles.slice(0, index).reduce((acc, value) => acc + value, 0)
    const clipPath = getClipPath(angle)
    const label = [
      `${values[index]} Completed`,
      `${values[index]} In Progress`,
      `${values[index]} Sent`,
    ][index % 3]
    const backgroundColor = ['var(--completedGreen)', 'var(--orange)', 'var(--dark-blue)'][
      index % 3
    ]

    return (
      <Tooltip title={label}>
        <Box
          key={index}
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            clipPath,
            backgroundColor,
            transform: `rotate(${startAngle - 90}deg)`,
          }}
        />
      </Tooltip>
    )
  })

  return (
    <Box
      sx={{
        position: 'relative',
        width: 125,
        height: 125,
        borderRadius: '9999px',
        overflow: 'hidden',
      }}
    >
      {total !== 0 && slices}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'white',
          borderRadius: '50%',
          width: '60%',
          height: '60%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          padding: '35%',
        }}
      >
        <Typography variant="h5" fontSize="2.5rem" textAlign="center">
          {total}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            display: 'flex',
            alignItems: 'center' /* Vertically center the contents */,
            justifyContent:
              'start' /* Align contents to the start, keeping the bullet and text close together */,
            gap: '0.5rem',
          }}
          textAlign="center"
        >
          Total
        </Typography>
      </Box>
    </Box>
  )
}

export default PieChart
