import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setMenuState } from 'store/modules/app'
import spaceToChars from 'utils/spaceToChars'

import { NavLink, useNavigate, useLocation, matchPath } from 'react-router-dom'
import { get } from 'lodash'

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import MenuIcon from '@mui/icons-material/Menu'

import styles from './Header.module.scss'

import { Grid, Button } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import isValidProfessionalCredentials from '../../utils/isValidProfessionalCredentials'

import ROLES from 'utils/constants/roles'
import {
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Stack,
  IconButton,
  Box,
  Card,
  Link,
  Divider,
} from '@mui/material'
import Typography from '@mui/material/Typography'

// icons
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import Logout from '@mui/icons-material/Logout'
import { otherServices } from '../../views/auth/services'
import { ReactComponent as ClientConnectionIcon } from 'components/avatar/client_connections_icon.svg'

import links from 'utils/constants/links'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'
import ProviderHelpButton from './components/ProviderHelpButton'
import { IGNORED_SUBSCRIPTION_SKUS } from 'views/community/utils/canAccessCommunity'

const useStyles = makeStyles({
  container: {
    backgroundColor: 'rgb(255, 255, 255)',
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '5px',
    marginBottom: '10px',
    color: 'rgb(0, 0, 0)',
    fontWeight: '500',
  },
})

export function stringToColor(string) {
  let hash = 0
  let i

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff
    color += `00${value.toString(16)}`.slice(-2)
  }
  /* eslint-enable no-bitwise */

  return color
}

export function stringAvatar(name, css) {
  const arrayName = name.split(' ').filter(Boolean)
  const lastName = arrayName.pop()
  const firstName = arrayName.join(' ')
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...css,
    },
    children: `${firstName[0] || ''}${lastName[0] || ''}`,
  }
}

function HeaderUserMenu({ currentUserRoles }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const user = useSelector((state) => get(state, 'auth.user', {}))
  const isProvider = currentUserRoles.some((role) => ROLES.PROVIDER_ROLES.includes(role))
  const isBilling = currentUserRoles.includes(ROLES.BILLING)
  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  if (!user) {
    return null
  }

  return (
    <>
      <Button>
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          onClick={handleClick}
        >
          <Avatar
            {...stringAvatar(`${user.firstName} ${user.lastName}`)}
            src={user.avatarThumb}
          ></Avatar>
          <Typography variant="body2" sx={{ display: { xs: 'none', sm: 'block' } }}>
            {user.firstName}
          </Typography>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Stack>
      </Button>

      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Typography m={1} mt={0} variant="body2" sx={{ color: '#8e8e8e' }}>
          {user.email}
        </Typography>
        {isClient && (
          <MenuItem
            onClick={() => {
              navigate('/account')
              handleClose()
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <Typography variant="body2">My Account</Typography>
          </MenuItem>
        )}
        {(isProvider || isBilling) && (
          <MenuItem
            onClick={() => {
              navigate('/my/profile')
              handleClose()
            }}
          >
            <ListItemIcon>
              <AccountCircleIcon />
            </ListItemIcon>
            <Typography variant="body2">My Profile</Typography>
          </MenuItem>
        )}
        {isProvider &&
        !isBilling && ( // provider only should direct to client licenses page
            <MenuItem
              onClick={() => {
                navigate('/billing/client-licenses')
                handleClose()
              }}
            >
              <ListItemIcon>
                <CreditCardIcon />
              </ListItemIcon>
              <Typography variant="body2">My Billing</Typography>
            </MenuItem>
          )}
        {isBilling && (
          <MenuItem
            onClick={() => {
              navigate('/billing')
              handleClose()
            }}
          >
            <ListItemIcon>
              <CreditCardIcon />
            </ListItemIcon>
            <Typography variant="body2">My Billing</Typography>
          </MenuItem>
        )}
        <MenuItem
          onClick={async () => {
            // when a user signs out we don't want to retain the history
            // navigate back to dashboard on next sign in
            await dispatch(otherServices.signOut())
            await navigate('/sign-in', { replace: true })
          }}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <Typography variant="body2">Sign Out</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default function Header({
  title,
  tabs,
  disabledTabs,
  hideTabs,
  hideHeader,
  subheaderContent,
}) {
  const dispatch = useDispatch()
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const { showNewSubscriptionPlan, hasSspProducts, hasRrpProducts, hasFocusProducts } = useSelector(
    (state) => state.ff
  )

  const {
    selectedUser: {
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
    },
  } = useGetUserWithoutRefresh()
  const organizationName = useSelector((state) => get(state, 'organization.name'))
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const email = useSelector((state) => get(state, 'auth.user.email', null))
  const userId = useSelector((state) => get(state, 'auth.user.id', null))
  const professionalCredentials = useSelector((state) =>
    get(state, 'auth.user.professionalCredentials', null)
  )
  const otherProfessionalCredentials = useSelector((state) =>
    get(state, 'auth.user.otherProfessionalCredentials', null)
  )
  const hasBillingRole = currentUserRoles.includes(ROLES.BILLING)
  const hasClientRole = currentUserRoles.includes(ROLES.CLIENT)
  const showGoToProductsButton =
    !hasClientRole &&
    (hasBillingRole ||
      isValidProfessionalCredentials({
        professionalCredentials,
        otherProfessionalCredentials,
      }))

  const organizationProductsStatus = useSelector((state) => get(state, 'organization.products', {}))
  const showReferButton =
    !Object.keys(organizationProductsStatus).some((key) =>
      IGNORED_SUBSCRIPTION_SKUS.includes(key)
    ) &&
    !hasClientRole &&
    process.env.REACT_APP_STAGE !== 'prod'

  const onClick = () => {
    navigate('/store')
  }

  const showClientConnectionNotifications = useSelector((state) =>
    get(state, 'auth.user.providerProfileInfo.openToNewClients', false)
  )
  const handleClientConnections = () => {
    navigate('/my/profile')
  }

  const { availableSspSlots, availableRrpSlots, availableFocusSlots } = useSelector(
    (state) => state.clients
  )
  const isClientsPage = location?.pathname.includes('/clients')
  // Adding the possibility to customize an active tab found
  const activeTab = [...tabs, ...disabledTabs].find((tab) => tab.isActive(location.pathname))

  return (
    <div className={`${classes.container} ${hideHeader && 'pt-8 pb-5'}`}>
      {!hideHeader && (
        <Grid container justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center">
            <IconButton
              sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}
              onClick={() => dispatch(setMenuState(true))}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="body1" className="text-gray-400" ml={{ sm: 2, md: 0 }}>
              {organizationName}
            </Typography>
          </Stack>

          <Stack>
            <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
              {showClientConnectionNotifications && (
                <IconButton
                  sx={{
                    width: '60px',
                    height: '60px',
                    opacity: matchPath({ path: '/my/profile' }, location.pathname) ? 0.25 : 1,
                  }}
                  onClick={handleClientConnections}
                  disabled={!!matchPath({ path: '/my/profile' }, location.pathname)}
                >
                  <ClientConnectionIcon />
                </IconButton>
              )}
              {!hasClientRole && <ProviderHelpButton userId={userId} email={email} />}

              {hasClientRole && (
                <Button
                  aria-label="go-to-support"
                  variant="contained"
                  color="primary"
                  data-test="help-button"
                  component="a"
                  href={links.support.homepageUrl}
                  target="_blank"
                >
                  <Typography variant="button">Help</Typography>
                </Button>
              )}
              {showGoToProductsButton && (
                <Button
                  onClick={onClick}
                  aria-label="go to products"
                  variant="outlined"
                  color="primary"
                  data-test="store-button"
                >
                  <Typography variant="button">store</Typography>
                </Button>
              )}
              {showReferButton && (
                <Button
                  onClick={() => navigate('/provider-referral-program')}
                  aria-label="Make a new referral"
                  variant="outlined"
                  color="primary"
                  data-test="provider-referral-program-button"
                >
                  <Typography variant="button">refer</Typography>
                </Button>
              )}
              <HeaderUserMenu currentUserRoles={currentUserRoles} />
            </Stack>
            {showNewSubscriptionPlan && isClientsPage && (
              <Box sx={{ position: 'relative' }} mt={1}>
                <Box sx={{ position: 'absolute', right: 0 }}>
                  <Card
                    elevation={0}
                    sx={{
                      backgroundColor: '#eef7f3',
                      width: '14rem',
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      paddingLeft: '1rem',
                      paddingRight: '1rem',
                    }}
                  >
                    {hasSspProducts && hasCompletedSspCertification && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Available SSP Licenses:</Typography>
                        <Typography variant="body2" fontWeight={500}>
                          {availableSspSlots}
                        </Typography>
                      </Stack>
                    )}
                    {hasFocusProducts && hasCompletedFocusCertification && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Available ILS Licenses:</Typography>
                        <Typography variant="body2" fontWeight={500}>
                          {availableFocusSlots}
                        </Typography>
                      </Stack>
                    )}
                    {hasRrpProducts && hasCompletedRrpCertification && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="body2">Available RRP Licenses:</Typography>
                        <Typography variant="body2" fontWeight={500}>
                          {availableRrpSlots}
                        </Typography>
                      </Stack>
                    )}

                    <Link href="\billing\client-licenses" variant="caption">
                      More details
                    </Link>
                  </Card>
                </Box>
              </Box>
            )}
          </Stack>
        </Grid>
      )}

      {(activeTab?.pageTitle || title) && (
        <Typography variant="h4" mt={0} mb={3}>
          {activeTab?.pageTitle || title}
        </Typography>
      )}

      {subheaderContent}

      {!hideTabs && !hideHeader && (
        <>
          <Typography variant="body1">
            {tabs.map(({ text, url, state, isActive = () => false }, i) => (
              <NavLink
                key={i}
                to={url}
                state={state}
                data-test={`header-nav-link-${spaceToChars(text, '-')}`}
                className={styles.tab + (isActive(location.pathname) ? ` ${styles.active}` : '')}
              >
                {text}
              </NavLink>
            ))}
          </Typography>
          {!!tabs.length && <Divider />}
        </>
      )}
    </div>
  )
}
