import { matchPath } from 'react-router'

const tabs = [
  {
    text: 'Profile',
    url: '/account',
    isEnabled: () => true,
    isActive: (pathname) => matchPath({ path: '/account' }, pathname),
  },
  {
    text: 'Professional Credentials',
    url: '/professional-credentials',
    isEnabled: ({ isProvider }) => isProvider,
    isActive: (pathname) => matchPath({ path: '/professional-credentials' }, pathname),
  },
  {
    text: 'Change Email',
    url: '/change-email',
    isEnabled: ({ isProvider, isBilling }) => isProvider || isBilling,
    isActive: (pathname) => matchPath({ path: '/change-email' }, pathname),
  },
  {
    text: 'Change Password',
    url: '/change-password',
    isEnabled: () => true,
    isActive: (pathname) => matchPath({ path: '/change-password' }, pathname),
  },
]

export default tabs
