import http, { http2 } from 'utils/http'
import { get, uniqBy } from 'lodash'
import { setError, setShowError } from './credit-card'

const SET_STEP = 'NEW_PURCHASE/SET_STEP'
const EXTEND_DATA = 'NEW_PURCHASE/EXTEND_DATA'
const SET_QUANTITY = 'NEW_PURCHASE/SET_QUANTITY'
const SET_SHIPPING = 'NEW_PURCHASE/SET_SHIPPING'
const SET_COUPONCODE = 'NEW_PURCHASE/SET_COUPONCODE'
const RESET_STORE = 'NEW_PURCHASE/RESET_STORE'

const initialState = {
  step: 0,
  data: {
    selectedAddOns: {},
    onboardingProducts: [],
    selectedServiceCode: '',
    serviceCode: '',
    shipstationRates: [],
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STEP:
      return {
        ...state,
        step: action.payload,
      }
    case EXTEND_DATA:
      return {
        ...state,
        data: { ...state.data, ...action.payload },
      }
    case SET_QUANTITY: {
      const products = state.data.products.map((product) => ({
        ...product,
        quantity: action.payload.SKU === product.SKU ? action.payload.newQuantity : 0,
      }))

      return {
        ...state,
        data: {
          ...state.data,
          products,
        },
      }
    }

    case SET_SHIPPING:
      return {
        ...state,
        data: {
          ...state.data,
          shipping: { action },
        },
      }
    case SET_COUPONCODE:
      return {
        ...state,
        data: {
          ...state.data,
          couponCode: action.payload,
        },
      }

    case RESET_STORE:
      return { ...initialState }
    default:
      return state
  }
}

export function setStep(payload) {
  return {
    type: SET_STEP,
    payload,
  }
}

export function extendData(payload) {
  return {
    type: EXTEND_DATA,
    payload,
  }
}

export function setQuantity(payload) {
  return {
    type: SET_QUANTITY,
    payload,
  }
}
export function setShipping(payload) {
  return {
    type: SET_SHIPPING,
    payload,
  }
}
export function setCouponCode(payload) {
  return {
    type: SET_COUPONCODE,
    payload,
  }
}

export function resetStore() {
  return {
    type: RESET_STORE,
  }
}

export function initiateOnboarding(payload) {
  return async () => {
    const res = await http.post('/onboarding-initiated', {
      onboardingOrganization: payload,
    })
    const { stripeCustomerId, stripeClientSecret, uuid } = get(
      res,
      'data.onboardingOrganization',
      {}
    )
    return { stripeCustomerId, stripeClientSecret, uuid }
  }
}

export function addPaymentInfo(payload) {
  return async () => {
    const result = await http.post('/onboarding-payment-info-updated', payload)
    if (!get(result, 'data.onboardingOrganization.isReadyForOnboarding')) {
      throw new Error({ isReadyForOnboarding: false })
    }
  }
}

/**
 * Get available quotes for given region, and save to store
 * @param {object} payload address data
 */
export function getShipstationQuotes({
  toCountry,
  toPostalCode,
  toState,
  toCity,
  weight,
  email,
  firstName,
  lastName,
  products,
}) {
  return async (dispatch) => {
    try {
      const result = await http.post('/get-shipstation-quotes', {
        toCountry,
        toPostalCode,
        toState,
        toCity,
        weight,
        metadata: {
          email,
          firstName,
          lastName,
          products,
        },
      })
      const data = get(result, 'data.data', [])
      if (data.length) {
        await dispatch(extendData({ shipstationError: false }))
        await dispatch(extendData({ shipstationRates: uniqBy(data, 'serviceCode') }))
        return { data, error: false }
      } else {
        await dispatch(extendData({ shipstationError: true }))
        await dispatch(extendData({ shipstationRates: [] }))
        return { data: {}, error: true }
      }
    } catch (error) {
      await dispatch(extendData({ shipstationError: true }))
      console.error('getShipstationQuotes', error)
      return { data: {}, error: true }
    }
  }
}

/**
 * Get available products from dear and save to store with quantity 0
 */
export function getDearProducts({ productCategories }) {
  return async (dispatch) => {
    try {
      const { data } = await http.post('/get-dear-products', { productCategories })
      const products = get(data, 'data.Products', [])
      const errors = get(data, 'data.errors', [])
      await dispatch(extendData({ products }))
      await dispatch(extendData({ getDearProductsErrors: errors }))
    } catch (error) {
      // possible network errors are: Network Error or anything containing 5XX (eg 500)
      const status = get(error, 'response.status', 200)
      if (status >= 500) {
        console.error('getdearproduct', error)
      }

      // 400 error will be shown by below
      const message = get(error, 'response.data.errors', []).join('; ')
      await dispatch(extendData({ getDearProductsErrors: message }))
    }
  }
}

// to do add coupon code request here
export function getCoupon({ promoCode, cartProducts, callGTM, callback }) {
  return async (dispatch) => {
    try {
      /**
       * 1. call api endpoint
       * 2. parse discounts
       * 3. save to redux
       */
      if (promoCode) {
        const { data } = await http.post('/coupons', {
          promoCode,
          products: cartProducts,
        })

        let isValid = get(data, 'data.valid', false)
        const validProducts = get(data, 'data.appliesTo', [])
        if (isValid && validProducts.length) {
          isValid = false
          cartProducts.forEach((product) => {
            isValid = isValid || validProducts.some((validProduct) => validProduct === product.sku)
          })
        }

        let disqualifyingSkuError = ''
        const disqualifyingSkus = get(data, 'data.disqualifyingSkus', [])
        if (isValid && disqualifyingSkus.length) {
          for (const product of cartProducts) {
            isValid =
              isValid &&
              !disqualifyingSkus.some((disqualifyingSku) => disqualifyingSku === product.sku)
            if (!isValid) {
              disqualifyingSkuError =
                'Promo code could not be applied due to disqualifying items in the cart'
              break
            }
          }
        }

        const requireAnyTraining = get(data, 'data.metadata.requiredAnyTraining')
        if (isValid && requireAnyTraining) {
          isValid = cartProducts
            .map((prod) => prod.sku)
            .some((product) => requireAnyTraining.split(',').includes(product))
          if (!isValid) {
            disqualifyingSkuError =
              'Promo code could not be applied due to incorrect training selected'
          }
        }

        const requireAnySubscription = get(data, 'data.metadata.requiredAnySubscription')
        if (isValid && requireAnySubscription) {
          isValid = cartProducts
            .map((prod) => prod.sku)
            .some((product) => requireAnySubscription.split(',').includes(product))
          if (!isValid) {
            disqualifyingSkuError =
              'Promo code could not be applied due to no subscription or incorrect subscription selected'
          }
        }

        if (isValid) {
          await dispatch(extendData({ coupon: data, couponError: '', appliedPromoCode: promoCode }))
          await callGTM({ promoCode })
        } else {
          await dispatch(
            extendData({
              coupon: null,
              couponError: disqualifyingSkuError || 'Promo code is not valid',
              appliedPromoCode: '',
            })
          )
        }
        callback && (await callback())
      }
    } catch (error) {
      // no discount
      const errorMessage = get(error, 'response.data.errors[0]', 'Failed to retrieve promo code')
      await dispatch(extendData({ coupon: null, couponError: errorMessage }))
    }
  }
}

export function removeCoupon() {
  return async (dispatch) => {
    await dispatch(
      extendData({ coupon: null, couponCode: '', couponError: '', appliedPromoCode: '' })
    )
  }
}

/**
 * Create a purchase using the purchase service
 * Note: these fields don't need to be checked as they are validated in frontend
 */
export function createPurchase({ email, firstName, lastName, organizationName, BIData }) {
  return async (dispatch) => {
    try {
      // if we already had uuid, skip
      const params = {
        email,
        first_name: firstName,
        last_name: lastName,
        organization_name: organizationName,
        data: BIData,
      }
      const response = await http2.post('/', {
        operationName: null,
        variables: { params },
        query: `mutation CREATE_PURCHASE($params: CreatePurchaseInput!)
      {
        create_purchase(params: $params)
        {
          uuid
          stripe_invoice
          stripe_invoice_id
        }
      }`,
      })
      const data = get(response, 'data.data.create_purchase')
      const error = get(response, 'data.errors[0].message', '')
      if (error) {
        await dispatch(setError(`${error}. Code: create_purchase`))
        await dispatch(setShowError(true))
      }
      await dispatch(extendData({ ...data }))

      // return data, if error even though we successfully returned data then show generic error
      return {
        data,
        error,
      }
    } catch (error) {
      console.error('createPurchase error', error)
      if (error.status >= 500) {
        await dispatch(setError(`${error.message}. Code: server_error`))
        await dispatch(setShowError(true))
      }
      return {
        data: {},
        error,
      }
    }
  }
}

/**
 * Update a purchase using the purchase service
 * @params uuid str
 * @params params json {
          items: [{sku:"SYS003", quantity: 1}, {sku:"ACC069", quantity: 2}]
          billing_address: {
            postal_code: String
          }
          shipping_address: {
            address1: String
            address2: String
            city: String
            state: String
            country: String
            postal_code: String
          }
        }
 */
export function updatePurchase({
  uuid,
  items,
  coupon_id,
  promotion_code,
  billing_address,
  shipping_address,
  shipment_method,
  stripe_payment_method_id,
  professional_credentials,
}) {
  return async (dispatch) => {
    try {
      const params = {
        items,
        coupon_id,
        promotion_code,
        billing_address,
        shipping_address,
        shipment_method,
        stripe_payment_method_id,
        professional_credentials,
      }
      const response = await http2.post('/', {
        operationName: null,
        variables: { params, uuid },
        query: `mutation UPDATE_PURCHASE($uuid: String!, $params: UpdatePurchaseInput!)
        {
          update_purchase(
            uuid: $uuid,
            params: $params
          ) {
            email
            stripe_invoice
            stripe_invoice_id
          }
        }`,
      })
      const data = get(response, 'data.data.update_purchase')
      const error = get(response, 'data.errors[0].message', null)
      const tax = get(data, 'stripe_invoice.tax', 0)
      const taxEnabled = get(data, 'stripe_invoice.automatic_tax.enabled', null) === true
      const taxComplete = get(data, 'stripe_invoice.automatic_tax.status', null) === 'complete'
      if (error) {
        await dispatch(setError(`${error}. Code: update_purchase`))
        await dispatch(setShowError(true))
      }

      if (taxEnabled && !taxComplete) {
        await dispatch(
          setError(
            `Unable to calculate tax. Please verify your zip code and try again. Code: update_purchase`
          )
        )
        await dispatch(setShowError(true))
      }

      await dispatch(
        extendData({
          ...data,
          totalTax: tax,
          updateError: error || (taxEnabled && !taxComplete),
        })
      )

      // return data, if error even though we successfully returned data then show generic error
      return { data, error }
    } catch (error) {
      console.error('updatePurchase error', error)
      if (error.status >= 500) {
        await dispatch(setError(`${error}. Code: server_error`))
        await dispatch(setShowError(true))
      }
    }
  }
}

/**
 * finalize a purchase using the purchase service
 * @params uuid str
 * @params stripePaymentMethodId str
 */
export function finalizePurchase({ uuid, stripePaymentMethodId }) {
  return async (dispatch) => {
    try {
      const params = { stripe_payment_method_id: stripePaymentMethodId }
      const response = await http2.post('/', {
        operationName: null,
        variables: { uuid, params },
        query: `mutation FINALIZE_PURCHASE($uuid: String!, $params: FinalizePurchaseInput!)
        {
          finalize_purchase(
            uuid: $uuid,
            params: $params
          ) {
            email
            stripe_invoice
            stripe_invoice_id
          }
        }`,
      })
      const data = get(response, 'data.data.finalize_purchase', {})
      const error = get(response, 'data.errors[0].message', '')
      if (error) {
        await dispatch(setError(`${error}. Code: finalize_purchase`))
        await dispatch(setShowError(true))
      }

      // return data, if error even though we successfully returned data then show generic error
      return { data, error }
    } catch (error) {
      console.error('finalize purchase', error)
      if (error.status >= 500) {
        await dispatch(setError(`${error}. Code: server_error`))
        await dispatch(setShowError(true))
      }
    }
  }
}

/**
 * get stripe setup intent client secret every time user updates credit card element
 */
export function getStripeSetupIntentClientSecret({ uuid }) {
  return async (dispatch) => {
    try {
      // if we already had uuid, skip
      const response = await http2.post('/', {
        operationName: null,
        variables: { uuid },
        query: `query GET_STRIPE_SETUP_INTENT_CLIENT_SECRET($uuid: String!)
          {
            get_stripe_setup_intent_client_secret(uuid: $uuid)
          }`,
      })
      const stripeSetupIntentClientSecret = get(
        response,
        'data.data.get_stripe_setup_intent_client_secret.stripe_setup_intent_client_secret',
        null
      )

      const error = get(response, 'data.errors[0].message', null)

      if (error) {
        await dispatch(setError(`${error}. Code: get_stripe_setup_intent_client_secret`))
        await dispatch(setShowError(true))
      } else {
        return {
          data: stripeSetupIntentClientSecret,
          error: !stripeSetupIntentClientSecret && 'Unable to retrieve Stripe client secret',
        }
      }
    } catch (error) {
      console.error('get stripe setup intent query', error)
      if (error.status >= 500) {
        await dispatch(setError(`${error}. Code: server_error`))
        await dispatch(setShowError(true))
      }
    }
  }
}
