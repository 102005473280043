import React, { useEffect, useState } from 'react'
import {
  Grid,
  Card,
  Button,
  CardHeader,
  CardContent,
  Typography,
  Divider,
  FormHelperText,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import { getDearProducts } from 'store/modules/new-purchase'
import Product from 'views/new-purchase/components/Product'
import useCart from 'views/new-purchase/utils/useCart'
import LINKS from 'utils/constants/links'
import { useNavigate } from 'react-router-dom'
import {
  ALL_SSP_SKUS,
  ALL_RRP_SKUS,
  FOCUS_SYSTEM_SKUS,
  PRODUCTS,
} from '../../utils/constants/prices'
import PurchaseLayout from './components/PurchaseLayout'
import useGTM from '../../utils/hooks/useGTM'
import EMAILS from 'utils/constants/emails'
import { setFF } from '../../store/modules/ff'

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
  },
})

export default function Products() {
  const dispatch = useDispatch()
  const classes = useStyles()
  const [stateloading, setStateLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [showError, setShowError] = useState(false)
  const navigate = useNavigate()
  const { selectedAddOnsArray } = useCart()

  // use reduxData to see what category onboarding product is in
  const { onboardingProducts = [], products = [], selectedAddOns = {} } = useSelector(
    (state) => state.newPurchase.data
  )

  const hasSsp = ALL_SSP_SKUS.some((key) => onboardingProducts.includes(key))
  const hasRrp = ALL_RRP_SKUS.some((key) => onboardingProducts.includes(key))
  const hasFocus = FOCUS_SYSTEM_SKUS.some((key) => onboardingProducts.includes(key))

  const title = (hasSsp || hasRrp) && 'Headphones'

  // fetch for accessories
  const accessoryCategories = [].concat.apply(
    [],
    onboardingProducts.map((sku) => PRODUCTS[sku].accessoryCategories)
  )
  useEffect(() => {
    if (!products.length) {
      const fetchDearProducts = async () => {
        await setStateLoading(true)
        await setLoadingText('fetching accessories...')
        await dispatch(getDearProducts({ productCategories: accessoryCategories }))
        await setStateLoading(false)
      }
      fetchDearProducts()
    }
    pageView({
      pageUrl: window.document.location.href,
      pageTitle: 'Internal Purchase - Select accessories',
    })
    // eslint-disable-next-line
  }, [])

  const onBack = () => {
    navigate(-1)
  }

  const { gtm, addToCart, pageView } = useGTM({})

  const loading = stateloading

  const onContinue = async () => {
    setStateLoading(true)
    setLoadingText('creating order...')
    try {
      if (hasAddOns) {
        const selectedAddOnsArray = products
          .map(({ SKU, PriceTier1, Name }) => ({
            item_id: SKU,
            item_name: Name,
            price: PriceTier1,
            quantity: get(selectedAddOns, SKU, 0),
            discount: 0,
          }))
          .filter(({ quantity }) => Boolean(quantity))
        const gtmItems = gtm?.items || []
        const items = [...selectedAddOnsArray, ...gtmItems]
        const value = items.reduce((sum, item) => sum + (item.price - item.discount), 0)
        addToCart({ items, value })
      }

      // manually set ssp flag (to account for quick cancellations)
      if (hasSsp) {
        await dispatch(setFF({ hasSspProducts: false }))
      }
      navigate(route)
    } catch (error) {
      setShowError(true)
      setErrorMessage(error.message)
      console.error(error)
    } finally {
      setStateLoading(false)
    }
  }
  const hasAddOns = !!selectedAddOnsArray.length
  const route = ((hasAddOns || hasFocus) && '/purchase/shipping') || '/purchase/confirm'

  return (
    <PurchaseLayout title="Accessories" loading={loading} loadingText={loadingText}>
      <Card variant="outlined" className="w-full" sx={{ padding: '20px' }}>
        <CardHeader
          title="Please select any optional accessories"
          classes={{ title: classes.cardHeader }}
        />
        <CardContent>
          <Typography gutterBottom variant="body1">
            Optionally add any of these accessories to your order. Shipping costs (if any) will be
            calculated at the next step.
          </Typography>
          {showError && (
            <FormHelperText className="my-2" error={true}>
              There was an issue creating your purchase. For assistance, please contact our Client
              Success team at{' '}
              <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
                {EMAILS.supportEmail}
              </a>
              . For reference: {errorMessage}
            </FormHelperText>
          )}
        </CardContent>
        <div className="pb-5 px-5">
          <Grid container justifyContent="space-between" alignItems="center" className="p-5">
            <Button color="primary" className="w-25" onClick={onBack}>
              Back
            </Button>
            <Button color="primary" variant="contained" onClick={onContinue}>
              {!selectedAddOnsArray.length ? 'No Thanks' : 'Continue'}
            </Button>
          </Grid>
          <Divider />
        </div>
        {!!products.length && (
          <Grid container direction="row">
            <CardHeader title={title} classes={{ title: classes.cardHeader }} />
            {(accessoryCategories.includes('ssp') || accessoryCategories.includes('rrp')) && (
              <CardContent key="products">
                <Typography gutterBottom variant="body1">
                  {accessoryCategories.includes('ssp') &&
                    'We recommend over-the-ear (circumaural) headphones for their enclosed nature and the immersive sound experience when using them.'}
                  {accessoryCategories.includes('rrp') &&
                    'We recommend using good quality headphones when listening to RRP.'}
                </Typography>
                <Typography gutterBottom variant="body1">
                  You can use your own headphones or purchase Unyte Health headphones. See
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-1 text-link"
                    href={hasRrp ? LINKS.support.rrpHeadphonesUrl : LINKS.support.sspHeadphonesUrl}
                  >
                    here
                  </a>
                  for other headphones options.
                </Typography>
                {accessoryCategories.includes('rrp') && (
                  <Typography gutterBottom variant="body1">
                    * Unyte headphones may be used for SSP and RRP listening.
                  </Typography>
                )}
              </CardContent>
            )}
            {products.map((product, index) => (
              <Product key={`product${index}`} product={product} index={index} />
            ))}
          </Grid>
        )}
      </Card>
    </PurchaseLayout>
  )
}
