import React from 'react'
import { TextField } from '@mui/material'

export default function({ error, onChange, ...props }) {
  return (
    <TextField
      fullWidth
      error={!!error}
      helperText={error}
      {...props}
      onChange={(e) => onChange({ ...e, target: { ...e.target, value: e.target.value } })}
    />
  )
}
