/**
 * This button should always be used in
 */
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { resetFilter } from 'store/modules/assessments'

/**
 * This button is used in:
 * - Select Page
 * - Viewing completed Assessments
 *   - Reports
 *   - Summary
 */
export const AssessmentBackButton = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // we want to run this syncronously so that we don't get a double load..
  const handleClick = async () => {
    await dispatch(resetFilter())
    await navigate('/assessments')
  }

  return (
    <Box sx={{ p: 2 }}>
      <Button
        onClick={handleClick}
        color="info"
        variant="outlined"
        size="small"
        data-test="exit-assessment-button"
        sx={{ px: 4 }}
      >
        <Typography noWrap variant="body2">
          Back to Assessments
        </Typography>
      </Button>
    </Box>
  )
}
