/**
 * This utils file are for errors from graphql
 */

interface GraphQLError {
  message: string
  locations?: { line: number; column: number }[]
  path?: string[]
  extensions?: any // 'any' can be replaced with a more specific type based on your usage
}

const maskValidationMessage = (error: GraphQLError, origin: string): string => {
  const message = error.message
  if (message === 'Validation error: email format is invalid') {
    return 'Please enter a valid email address.'
  } else if (message.includes('Validation')) {
    return message?.replace('Validation error: ', '')
  } else {
    console.error(origin, error)
    return message
  }
}

export { maskValidationMessage }
