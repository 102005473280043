import React from 'react'
import { Divider, Stack, Typography } from '@mui/material'
import { formatDate } from '../../utils/formatDate'

interface PrintHeaderProps {
  clientName: string
  assessmentName: string
  pageNo: string
  pageCurrent: number
  pageMax: number
}

export const PrintHeader: React.FC<PrintHeaderProps> = ({
  clientName,
  assessmentName,
  pageCurrent,
  pageMax,
}) => {
  const today = new Date()

  return (
    <Stack direction="row" justifyContent="space-between" p={3} alignItems="center">
      <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
        <Typography variant="caption">
          Printed Date
          <br />
          {formatDate(today)}
        </Typography>
        {/* we can't use default divider here as it does not show up in print */}
        <Typography variant="caption">
          Client
          <br />
          {clientName}
        </Typography>
        <Typography variant="caption">
          Assessment Short Name/Title
          <br />
          {assessmentName}
        </Typography>
      </Stack>
      <Typography variant="caption">
        Page {pageCurrent} of {pageMax}
      </Typography>
    </Stack>
  )
}
