import React from 'react'
import { Box, Typography } from 'components'
import { DashboardCard } from './DashboardCard2'
import links from 'utils/constants/links'

export default function FocusHeadphoneGuidelines({ gridProps }) {
  if (process.env.REACT_APP_STAGE !== 'test') {
    return null
  }

  return (
    <DashboardCard
      data-test="dashboard-card-focus-headphone-guidelines"
      title="ILS Headphones Guidelines"
      id="focus-headphone-guidelines-card"
    >
      <Typography variant="body2" gutterBottom py={1}>
        Bone/Air conduction headphones and amplifier are required to successfully deliver the
        Integrated Listening System.
      </Typography>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: 'currentColor',
            flexShrink: 0,
            color: '#008080',
            margin: '1rem',
          }}
        />
        <Typography variant="body2">
          <span>Click</span>
          <a
            className="text-link underlined px-1 font-medium"
            href={links.support.focusHeadphonesUrl}
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>
          <span>to learn more!</span>
        </Typography>
      </Box>
    </DashboardCard>
  )
}
