import React from 'react'
import { Typography, Tooltip } from 'components'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  tooltip: {
    background: '#616161',
  },
})

export default ({ strong, product = 'ssp' }) => {
  const classes = useStyles()

  const strongText = product === 'ssp' ? 'SSP active' : 'client licenses'
  const tooltipMessage =
    product === 'ssp' ? (
      <>
        An <strong>SSP active client</strong> is a client who has listened to 30 minutes or more of
        any SSP music within your monthly billing cycle.
      </>
    ) : (
      <>
        A <strong>client license</strong> allows a client to listen to any amount of an ILS Program.
      </>
    )

  return (
    <Tooltip
      title={
        <Typography variant="body2" className="text-white">
          {tooltipMessage}
        </Typography>
      }
      classes={{ tooltip: classes.tooltip }}
      placement="top"
      arrow
    >
      <span className={`cursor-pointer underline ${strong ? 'font-medium' : 'font-normal'}`}>
        {strongText}
      </span>
    </Tooltip>
  )
}
