import React from 'react'

import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
} from 'components'

import useForm from 'components/form/useForm'

export default function({ open, user, onClose, _onSubmit }) {
  const { form, setFormValue, isValid, onSubmit, pending, errors, reset, trimmedForm } = useForm({
    data: {
      professionalCredentials: user.professionalCredentials,
    },
    validation: {
      professionalCredentials: {
        len: {
          args: [0, 100],
          msg: 'Professional credentials must be no longer than 100 characters',
        },
      },
    },
    onSubmit: _onSubmit,
  })

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose()
        reset()
      }}
    >
      <DialogTitle>Edit professional credentials</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <FormHelperText error className="py-2">
          {errors.none}
        </FormHelperText>
        <TextField
          value={form.professionalCredentials}
          fullWidth
          onChange={setFormValue('professionalCredentials')}
          error={errors['professionalCredentials']}
          label="Professional credentials"
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          disabled={pending}
          onClick={() => {
            onClose()
            reset()
          }}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="primary"
          loading={pending}
          disabled={!isValid}
          onClick={async () => {
            const { id } = user
            onSubmit({ id, ...trimmedForm })
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}
