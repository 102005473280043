import React, { useState } from 'react'
import {
  Button,
  Typography,
  Box,
  ButtonGroup,
  Hidden,
  IconButton,
  Tooltip,
  Stack,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'

import SendIcon from '@mui/icons-material/Send'
import SaveIcon from '@mui/icons-material/Save'
import CheckIcon from '@mui/icons-material/Check'
import PrintIcon from '@mui/icons-material/Print'
import { useDispatch, useSelector } from 'react-redux'
import ROLES from 'utils/constants/roles'

import AssessmentConfirmDialog from './AssessmentConfirmDialog'
import { ASSESSMENT_STATUS } from 'utils/constants/assessmentStatus'
import { resetFilter } from 'store/modules/assessments'

const useStyles = makeStyles({
  title: {
    fontSize: 'clamp(0.9rem, 2vw, 1.5rem)',
  },
})

const TooltipWrapper = ({ children, dataStatus }) => {
  if (!ASSESSMENT_STATUS.isCompleted(dataStatus)) {
    return (
      <Tooltip title="This assessment is incomplete. You can only print completed assessments.">
        <div>{children}</div>
      </Tooltip>
    )
  } else {
    return children
  }
}
export default function Header({ title, onSend, onSave, update, data, handlePrint = () => {} }) {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState('')
  const navigate = useNavigate()
  const classes = useStyles()
  const dataStatus = get(data, 'status', null)
  const email = useSelector((state) => get(state, 'assessments.email', ''))

  // check roles for going back
  const roles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isClient = roles.some((role) => ROLES.CLIENT_ROLES.includes(role))

  const { SENT_TO_CLIENT, isSent, isInProgress, isCompleted } = ASSESSMENT_STATUS

  // this is triggered when a user clicks back, so we will clear the filter..
  const handleClose = (type) => async () => {
    await dispatch(resetFilter())
    if (type === 'cancel') {
      setOpen(false)
    } else if (type === 'discard') {
      navigate(`/assessments`, { state: { hideGettingStarted: true } })
    }
  }
  const handleSaveBack = (statusPassIn, options) => async () => {
    await onSave(statusPassIn, options)
    await dispatch(resetFilter())
  }

  const handleCompleteLater = async () => {
    await onSave('save_back', { fromBack: true })
    await dispatch(resetFilter())
  }

  const ASSESSMENT_STATUSES = {
    SENT: {
      heading: 'Send Assessment to Client',
      body: 'An email will be sent to your client with details on how to access the assessment.',
      buttonText: 'Send Assessment',
      buttonFn: onSend,
    },
    COMPLETED: {
      // no confirmation required for assessment confirmed
    },
    BACK: {
      status: 'Back',
      heading: 'Are you sure you want to exit?',
      body: 'If you exit, your changes will be lost.',
      buttonText: 'Discard Changes',
      buttonFn: handleClose('discard'),
    },
    SAVE_BACK: {
      status: 'SaveBack',
      heading: 'You have unsaved changes.',
      body: 'Please save your progress before you leave.',
      buttonText: 'Save Changes & Exit',
      buttonFn: handleSaveBack('save_back', { fromBack: true }),
    },
  }

  // if it's a new assessment, then show prompt, otherwise autosave
  const dispatch = useDispatch()
  const handleBackButtonClick = () => {
    // data does not have status when first created
    if (!dataStatus) {
      // in this state, the assessment has not been created yet
      setOpen(true)
      setType('BACK')
    } else if (dataStatus) {
      if (isCompleted(dataStatus) || isSent(dataStatus)) {
        setOpen(false)
        if (isClient) {
          navigate('/')
        } else {
          dispatch(resetFilter())
          navigate(`/assessments`, { state: { hideGettingStarted: true } })
        }
      } else {
        dispatch(resetFilter())
        setOpen(true)
        setType('SAVE_BACK')
      }
    }
  }

  const handleSend = () => {
    setType('SENT')
    setOpen(true)
  }

  return (
    <>
      <Stack direction="row" spacing={3} justifyContent="space-between" alignItems="center">
        <Button
          variant="outlined"
          onClick={handleBackButtonClick}
          size="small"
          color="info"
          data-test="exit-assessment-button"
          sx={{ px: 4 }}
        >
          <Typography noWrap variant="body2">
            Back to Assessments
          </Typography>
        </Button>

        <Typography align="center" variant="h5" color="primary" className={classes.title}>
          {title}
        </Typography>
        <Stack direction="row">
          <Hidden smDown>
            {onSend && email && (!dataStatus || isInProgress(dataStatus)) && (
              <Button
                variant="contained"
                className="mr-3"
                color="primary"
                size="small"
                onClick={handleSend}
                endIcon={
                  <Hidden lgDown>
                    <SendIcon />
                  </Hidden>
                }
              >
                <Typography noWrap variant="body2" className="text-white">
                  Send to Client
                </Typography>
              </Button>
            )}
            {onSave && (!dataStatus || isInProgress(dataStatus)) && (
              <Button
                variant="contained"
                className="mr-3"
                color="primary"
                size="small"
                onClick={handleCompleteLater}
                data-test="complete-assessment-later-button"
                endIcon={
                  <Hidden lgDown>
                    <SaveIcon />
                  </Hidden>
                }
              >
                <Typography noWrap variant="body2" className="text-white">
                  Complete later
                </Typography>
              </Button>
            )}
            {!isClient && (
              <TooltipWrapper dataStatus={dataStatus}>
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handlePrint}
                  disabled={!isCompleted(dataStatus)}
                  endIcon={
                    <Hidden lgDown>
                      <PrintIcon />
                    </Hidden>
                  }
                >
                  <Typography noWrap variant="body2" className="text-white">
                    Print
                  </Typography>
                </Button>
              </TooltipWrapper>
            )}
          </Hidden>
          <Hidden smUp>
            <ButtonGroup color="primary" variant="text" aria-label="contained primary button group">
              {onSend && email && (!dataStatus || isInProgress(dataStatus)) && (
                <IconButton onClick={handleSend} color="primary" size="small">
                  <SendIcon className="mr-1" />
                </IconButton>
              )}
              {onSave && (!dataStatus || isInProgress(dataStatus)) && (
                <IconButton onClick={onSave} color="primary" size="small" className="ml-1">
                  <SaveIcon />
                </IconButton>
              )}
            </ButtonGroup>
          </Hidden>
          {!isClient && dataStatus === SENT_TO_CLIENT && (
            <Box display="flex">
              <CheckIcon color="primary" />
              <Typography variant="body1">Sent to Client</Typography>
            </Box>
          )}
          {update && (
            <Typography variant="body2" className="m-3">
              {update}
            </Typography>
          )}
        </Stack>
      </Stack>
      <AssessmentConfirmDialog
        dialogOpen={open}
        dialogTitle={ASSESSMENT_STATUSES[type]?.heading}
        dialogContentText={ASSESSMENT_STATUSES[type]?.body}
        cancelOnClick={handleClose('cancel')}
        cancelText={'Cancel'}
        confirmOnClick={ASSESSMENT_STATUSES[type]?.buttonFn}
        confirmText={ASSESSMENT_STATUSES[type]?.buttonText}
      />
    </>
  )
}
