import React from 'react'
import { Card as MuiCard } from '@mui/material'

export default function CardComponent({ children, backgroundColor }) {
  return (
    <MuiCard
      sx={{
        borderRadius: '8px',
        height: '100%',
        border: '1px solid var(--green)',
        backgroundColor,
      }}
      variant="outlined"
    >
      {children}
    </MuiCard>
  )
}
