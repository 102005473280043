export const multipleProductsText = ({ hasFocusProducts, hasSspProducts, hasRrpProducts }) => {
  // transform text based on license
  const activeSubscriptions = []
  hasFocusProducts && activeSubscriptions.push('the ILS')
  hasSspProducts && activeSubscriptions.push('the SSP')
  hasRrpProducts && activeSubscriptions.push('RRP')

  let separatedByOr
  switch (activeSubscriptions.length) {
    case 1:
      separatedByOr = activeSubscriptions[0]
      break
    case 2:
      separatedByOr = activeSubscriptions[0] + ' or ' + activeSubscriptions[1]
      break
    case 3:
      separatedByOr =
        activeSubscriptions[0] + ', ' + activeSubscriptions[1] + ' or ' + activeSubscriptions[2]
      break
    default:
      separatedByOr = 'your program(s)'
  }

  const separatedBySlash = activeSubscriptions
    .map((sub) => {
      return sub.replace('the ', '')
    })
    .join('/')

  return { separatedByOr, separatedBySlash }
}
