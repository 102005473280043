import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  Grid,
  FormHelperText,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
} from '@mui/material'

import { setCloseCurrentSession, setSaveCloseSession } from '../store/modules/updateNotification'

export default function NewUpdates({
  title,
  src,
  type,
  override,
  overrideClose = () => {},
  text = 'Please watch this short intro video to get started.',
  allowDoNotShowAgain = true,
}) {
  const dispatch = useDispatch()
  const currentSession = useSelector((state) => state.updateNotification.currentSession)
  const savedSession = useSelector((state) => state.updateNotification[type])
  const [doNotShowAgain, setDoNotShowAgain] = useState(false)
  const [openModal, setOpenModal] = useState(true)

  const handleClose = () => {
    if (!doNotShowAgain) {
      dispatch(setCloseCurrentSession())
    } else {
      dispatch(setSaveCloseSession(type))
    }
    overrideClose()
    setOpenModal(false)
  }

  const handleSwitch = () => {
    setDoNotShowAgain(!doNotShowAgain)
  }

  const showModal = (openModal && !currentSession && !savedSession) || override

  if (!src) {
    return null
  }

  return (
    <Dialog open={!!showModal} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: '#008080',
          textAlign: 'center',
          color: '#fff',
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent className="py-2" dividers>
        <Grid className="p-5" container justifyContent="center">
          <FormHelperText className="mb-5" style={{ fontSize: 14 }}>
            {text}
          </FormHelperText>
          <iframe
            title={title}
            src={src}
            width="100%"
            height="360"
            frameBorder="0"
            allow="autoplay; fullscreen"
            allowFullScreen
          />
        </Grid>
      </DialogContent>
      <DialogActions className={allowDoNotShowAgain ? 'justify-between' : ''}>
        {allowDoNotShowAgain && (
          <FormControlLabel
            control={
              <Checkbox
                checked={doNotShowAgain}
                onChange={handleSwitch}
                name="doNotShowAgain"
                color="primary"
              />
            }
            className="mx-5"
            label="Do not show again"
          />
        )}
        <Button color="primary" onClick={handleClose} data-test="close-modal" className="mx-5">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
