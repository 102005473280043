/**
 * This is currently only used in assessments table
 */
import React from 'react'
import { Divider, Stack, Typography } from '@mui/material'

export const FilterTable = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      direction="row"
      divider={<Divider orientation="vertical" flexItem />}
      spacing={1}
      alignItems="center"
      justifyContent="flex-end"
      width="100%"
    >
      <Typography variant="h6">Filter</Typography>
      <Stack direction="row" spacing={1} alignItems="center" width="100%">
        {children}
      </Stack>
    </Stack>
  )
}
