/**
 * This component will take up the whole page and use Mixer to blur page..
 */
import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { AnimatedLeafLogo } from './PageLoader'

export default function LoadingPage({
  text,
  children,
  loading,
  hideBackground,
  bringToFront,
  backgroundColor = '#eeeeee',
}) {
  const style = {
    mixer: {
      height: '100%',
      width: '100%',
      backgroundColor: 'whitesmoke',
      position: 'absolute',
      zIndex: 8000,
      display: 'grid',
      placeItems: 'center',
      opacity: hideBackground ? 1 : 0.5,
    },
    container: {
      minHeight: '100vh',
      width: '100%',
      backgroundColor,
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
    },
    internalContainer: {
      backgroundColor: 'white',
      minHeight: '100vh',
    },
    loadingContainer: {
      height: '100%',
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'absolute',
      zIndex: 9000,
    },
  }
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      sx={hideBackground || bringToFront ? style.internalContainer : style.container}
    >
      {loading && <Grid item xs sx={style.mixer}></Grid>}
      {loading && (
        <div style={style.loadingContainer}>
          <AnimatedLeafLogo />
          <Typography sx={{ color: '#2d3748', fontWeight: 400 }} textAlign="center" variant="h5">
            {text}
          </Typography>
        </div>
      )}
      {children}
    </Grid>
  )
}

export function LoadingModal({ text }) {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2, // Use a z-index value to ensure it's above the content
        flexDirection: 'column',
      }}
    >
      <AnimatedLeafLogo />
      <Typography sx={{ color: '#2d3748', fontWeight: 400 }} textAlign="center" variant="h5">
        {text}
      </Typography>
    </Box>
  )
}
