import { Container, Grid, Typography, Stack } from '@mui/material'
import ResourceGrid from 'components/ResourceGrid'
import React from 'react'
import { useOutletContext } from 'react-router'
import files from 'utils/constants/files'
import { FooterCard } from './components/cards'
import {
  BILLING_PROVIDER_FOCUS_CARDS,
  BILLING_PROVIDER_SSP_CARDS,
  PROVIDER_FOCUS_CARDS,
  PROVIDER_SSP_FOCUS_RRP_CARDS,
  PROVIDER_SSP_CARDS,
  PROVIDER_RRP_CARDS,
  PROVIDER_VOICE_PRO_CARDS,
  BILLING_PROVIDER_RRP_CARDS,
} from './constants/cards2'
import VerifyProfessionalCredentialsModal from './components/VerifyProfessionalCredentialsModal'
import isValidProfessionalCredentials from 'utils/isValidProfessionalCredentials'

export default function ProviderDashboard() {
  const {
    loading,
    isProvider,
    isBilling,
    otherProfessionalCredentials,
    professionalCredentials,
    hasRecentAssessments,
    hasAssessmentProducts,
    hasAssessmentSessions,
    showUserWelcomeCall,
    // ssp
    hasOrgSspCertificationCompleted,
    hasUnpaidSspSubscription,
    hasPastDueSspSubscription,
    hasCompletedSspCertification,
    hasPaidSspSubscription,
    hasSspSessions,
    hasSspProducts,
    hasAllCanceledSspSubscription,
    hasSspCertification,
    isSspCompletedUser,
    sspInTrainingOnly,
    // rrp
    hasOrgRrpCertificationCompleted,
    hasUnpaidRrpSubscription,
    hasPastDueRrpSubscription,
    hasCompletedRrpCertification,
    hasPaidRrpSubscription,
    hasRrpSessions,
    hasRrpProducts,
    hasAllCanceledRrpSubscription,
    hasRrpCertification,
    isRrpCompletedUser,
    rrpInTrainingOnly,
    hasDeferredRrpSubscription,
    // ils
    hasOrgFocusCertificationCompleted,
    hasUnpaidFocusSubscription,
    hasPastDueFocusSubscription,
    hasCompletedFocusCertification,
    hasPaidFocusSubscription,
    hasFocusSessions,
    hasFocusProducts,
    hasAllCanceledFocusSubscription,
    hasFocusCertification,
    isFocusCompletedUser,
    focusInTrainingOnly,
    // voice pro
    hasVoiceProCertification,
    hasCompletedVoiceProCertification,
    isProcessingPayment,
    numberOfProviders,
  } = useOutletContext()

  if (!isProvider) {
    return null
  }

  // Single Provider Orgs or
  const sspCard =
    !hasAllCanceledSspSubscription &&
    PROVIDER_SSP_CARDS.find(({ isActive }) =>
      isActive({
        hasUnpaidSspSubscription,
        hasPastDueSspSubscription,
        hasCompletedSspCertification,
        hasPaidSspSubscription,
        hasSspSessions,
        otherProfessionalCredentials,
        professionalCredentials,
        hasAllCanceledSspSubscription,
        isBilling,
        hasOrgSspCertificationCompleted,
        isSspCompletedUser,
        hasSspCertification,
        hasSspProducts,
        sspInTrainingOnly,
      })
    )

  // Single Provider Orgs or
  const rrpCard =
    !hasAllCanceledRrpSubscription &&
    PROVIDER_RRP_CARDS.find(({ isActive }) =>
      isActive({
        hasUnpaidRrpSubscription,
        hasPastDueRrpSubscription,
        hasCompletedRrpCertification,
        hasPaidRrpSubscription,
        hasRrpSessions,
        otherProfessionalCredentials,
        professionalCredentials,
        hasAllCanceledRrpSubscription,
        isBilling,
        hasOrgRrpCertificationCompleted,
        isRrpCompletedUser,
        hasRrpCertification,
        hasRrpProducts,
        rrpInTrainingOnly,
        hasDeferredRrpSubscription,
      })
    )

  // NON BILLING PROVIDER WITH ILS CARDS
  const focusCard =
    !hasAllCanceledFocusSubscription &&
    PROVIDER_FOCUS_CARDS.find((card) =>
      card.isActive({
        isBilling,
        hasUnpaidFocusSubscription,
        hasPastDueFocusSubscription,
        hasCompletedFocusCertification,
        hasPaidFocusSubscription,
        hasFocusSessions,
        otherProfessionalCredentials,
        professionalCredentials,
        hasOrgFocusCertificationCompleted,
        hasFocusCertification,
        hasFocusProducts,
        focusInTrainingOnly,
      })
    )

  // CARDS THAT BOTH BILLING AND NON-BILLING SEES
  const sspAndFocusCards = PROVIDER_SSP_FOCUS_RRP_CARDS.filter(({ isActive }) =>
    isActive({
      isBilling,
      hasOrgFocusCertificationCompleted,
      hasOrgSspCertificationCompleted,
      hasOrgRrpCertificationCompleted,
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasSspProducts,
      hasRrpProducts,
      hasFocusProducts,
      hasAllCanceledSspSubscription,
      hasAllCanceledRrpSubscription,
      hasAllCanceledFocusSubscription,
      hasRecentAssessments,
      hasAssessmentProducts,
      otherProfessionalCredentials,
      professionalCredentials,
      hasAssessmentSessions,
      sspInTrainingOnly,
      rrpInTrainingOnly,
      focusInTrainingOnly,
      isProcessingPayment,
      hasSspSessions,
      hasRrpSessions,
      hasFocusSessions,
      hasPastDueSspSubscription,
      hasPastDueRrpSubscription,
      hasPastDueFocusSubscription,
      hasUnpaidFocusSubscription,
      hasUnpaidSspSubscription,
      hasUnpaidRrpSubscription,
      hasPaidSspSubscription,
      hasPaidRrpSubscription,
      hasPaidFocusSubscription,
      showUserWelcomeCall,
    })
  )

  const voiceProCards = PROVIDER_VOICE_PRO_CARDS.find((card) =>
    card.isActive({
      hasVoiceProCertification,
      hasCompletedVoiceProCertification,
    })
  )

  // tutorial basics
  const cards = files.providerDashboard

  const hasActiveSspSubscription = hasPaidSspSubscription || hasPastDueSspSubscription
  const hasActiveFocusSubscription = hasPaidFocusSubscription || hasPastDueFocusSubscription
  const hasActiveRrpSubscription = hasPaidRrpSubscription || hasPastDueRrpSubscription

  const allCardProps = {
    hasCompletedSspCertification,
    hasCompletedFocusCertification,
    sspInTrainingOnly,
    isSspCompletedUser,
    isFocusCompletedUser,
    isBilling,
    hasActiveSspSubscription,
    hasActiveFocusSubscription,
    hasActiveRrpSubscription,
    showUserWelcomeCall,
    gridProps: {
      xs: 12,
      sm: 4,
      md: 4,
      lg: 4,
    },
  }

  const allSspFocusCard = sspAndFocusCards?.map(({ Card, priority, ...props }) => ({
    Card: () => <Card {...allCardProps} {...props} />,
    priority,
  }))

  // if we are a multi provider org, then we'll see these cards
  const billingCards = [
    ...BILLING_PROVIDER_FOCUS_CARDS,
    ...BILLING_PROVIDER_SSP_CARDS,
    ...BILLING_PROVIDER_RRP_CARDS,
  ]
    .filter(({ isActive }) =>
      isActive({
        hasAllCanceledFocusSubscription,
        hasAllCanceledRrpSubscription,
        hasAllCanceledSspSubscription,
        hasCompletedFocusCertification,
        hasCompletedRrpCertification,
        hasCompletedSspCertification,
        hasFocusProducts,
        hasFocusSessions,
        hasOrgFocusCertificationCompleted,
        hasOrgRrpCertificationCompleted,
        hasOrgSspCertificationCompleted,
        hasPaidFocusSubscription,
        hasPaidRrpSubscription,
        hasPaidSspSubscription,
        hasPastDueFocusSubscription,
        hasPastDueRrpSubscription,
        hasPastDueSspSubscription,
        hasSspProducts,
        hasSspSessions,
        hasUnpaidFocusSubscription,
        hasUnpaidRrpSubscription,
        hasUnpaidSspSubscription,
        focusInTrainingOnly,
        numberOfProviders,
        sspInTrainingOnly,
      })
    )
    .map(({ Card, priority, ...props }) => ({
      Card: () => <Card {...allCardProps} {...props} />,
      priority,
    }))

  const allSubscriptionCards = [
    ...allSspFocusCard,
    ...billingCards,
    sspCard && {
      Card: () => <sspCard.Card {...allCardProps} type="SSP" />,
      priority: sspCard.priority,
    },
    rrpCard && {
      Card: () => <rrpCard.Card {...allCardProps} type="RRP" />,
      priority: rrpCard.priority,
    },
    focusCard && {
      Card: () => <focusCard.Card {...allCardProps} type="ILS" />,
      priority: focusCard.priority,
    },
    voiceProCards && {
      Card: () => <voiceProCards.Card {...allCardProps} />,
      priority: voiceProCards.priority,
    },
  ]
    ?.filter(Boolean)
    ?.sort((a, b) => a.priority - b.priority)

  if (loading) {
    return null
  }

  return (
    <Container maxWidth="xl" py={0}>
      {!isValidProfessionalCredentials({
        otherProfessionalCredentials,
        professionalCredentials,
      }) && <VerifyProfessionalCredentialsModal />}
      <Grid container spacing={2} alignItems="stretch" pt={2}>
        {allSubscriptionCards?.map(({ Card, ...props }, index) => (
          <Card {...props} key={index} />
        ))}
      </Grid>
      {/* tutorial basics */}
      {((hasSspProducts && hasCompletedSspCertification && !sspInTrainingOnly) ||
        (hasRrpProducts && hasCompletedRrpCertification && !rrpInTrainingOnly) ||
        (hasFocusProducts && hasCompletedFocusCertification && !focusInTrainingOnly)) && (
        <Stack spacing={2} m={2} mt={6}>
          <Typography variant="h4">Tutorial Basics</Typography>
          <ResourceGrid gridProps={{ xs: 12, sm: 3 }} cards={cards} />
        </Stack>
      )}
      {/* footer goes here: only show to providers that has completed ssp / ils certification and have paid subscription */}
      <FooterCard
        showDownloadImage={
          ((hasSspProducts && hasCompletedSspCertification && !sspInTrainingOnly) ||
            (hasRrpProducts && hasCompletedRrpCertification && !rrpInTrainingOnly) ||
            (hasFocusProducts && hasCompletedFocusCertification && !focusInTrainingOnly)) &&
          !(hasSspSessions || hasFocusSessions || hasRrpSessions)
        }
      />
    </Container>
  )
}
