/**
 * This is the refactored component that prompts user before they create a client
 */
import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from 'components'
import CreateInvitation from '../modals/CreateInvitation'
import CreateUser from '../modals/CreateUser'
import AddIcon from '@mui/icons-material/Add'
import { useOutletContext } from 'react-router'
import { useSelector } from 'react-redux'
export default function CreateClient({ roles, onUpdate }) {
  const [showCreateUserModal, setCreateUserModal] = useState(false)
  const [showCreateInvitationModal, setShowCreateInvitationModal] = useState(false)
  const { hasSspProducts, hasFocusProducts, hasRrpProducts } = useSelector((state) => state.ff)
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const {
    slotsInfo,
    setOpenLicensePrompt,
    openCreateClientPrompt,
    setOpenCreateClientPrompt,
    hasCompletedSspCertification,
    hasCompletedFocusCertification,
    hasCompletedRrpCertification,
  } = useOutletContext()
  const handleClickOpen = () => {
    const noAvailableSlots = !(
      (hasSspProducts && hasCompletedSspCertification && slotsInfo.availableSspSlots?.length) ||
      (hasFocusProducts &&
        hasCompletedFocusCertification &&
        slotsInfo.availableFocusSlots?.length) ||
      (hasRrpProducts && hasCompletedRrpCertification && slotsInfo.availableRrpSlots?.length)
    )
    if (showNewSubscriptionPlan && noAvailableSlots) {
      setOpenLicensePrompt(true)
    } else {
      setOpenCreateClientPrompt(true)
    }
  }
  const handleClickClose = () => {
    setOpenCreateClientPrompt(false)
  }
  const handleClose = (prompt) => () => {
    if (prompt === 'yes') {
      setShowCreateInvitationModal(true)
    } else if (prompt === 'no') {
      setCreateUserModal(true)
    }
    setOpenCreateClientPrompt(false)
  }
  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen} startIcon={<AddIcon />}>
        Add Client
      </Button>
      <CreateInvitation
        roles={roles}
        showModal={showCreateInvitationModal}
        setModalState={setShowCreateInvitationModal}
      />
      <CreateUser
        text="add client"
        roles={roles}
        onUpdate={onUpdate}
        showModal={showCreateUserModal}
        setModalState={setCreateUserModal}
      />
      <Dialog
        open={openCreateClientPrompt}
        onClose={handleClickClose}
        aria-labelledby="create-client-dialog-title"
        aria-describedby="create-client-dialog-description"
      >
        <DialogTitle id="create-client-dialog-title">{'Add Client'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="create-client-dialog-description">
            When adding this client, do you want to enable{' '}
            <span className="font-semibold">Remote Delivery</span>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleClose('no')} color="primary">
            No
          </Button>
          <Button onClick={handleClose('yes')} color="primary">
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
