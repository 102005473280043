/**
 * [UW-6198] June 26: providers invited via polly will have a null field
 */
export default ({ professionalCredentials, otherProfessionalCredentials }) => {
  return (
    professionalCredentials &&
    (professionalCredentials !== 'Other' ||
      (professionalCredentials === 'Other' &&
        otherProfessionalCredentials &&
        otherProfessionalCredentials !== 'Unapproved'))
  )
}
