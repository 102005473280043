import React from 'react'
import moment from 'utils/moment'
import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import Tooltip from '@mui/material/Tooltip'

export default ({ format = 'MM/dd/yyyy', label, value = '', onChange = () => null, disabled }) => (
  <MuiPickersUtilsProvider key="dob" utils={DateFnsUtils}>
    <Tooltip placement={'bottom'} title={'mm/dd/yyyy'}>
      <KeyboardDatePicker
        className="my-0"
        // open
        disabled={disabled}
        fullWidth
        autoOk
        variant="inline"
        format={format}
        margin="normal"
        id="date-picker-inline"
        label={label}
        value={value ? moment(value).utcOffset(0) : null}
        onChange={(date) => {
          const next = date
            ? moment
                .withoutTimezone(date)
                .utcOffset(0)
                .format('MM/DD/YYYY')
            : ''
          onChange(next)
        }}
        KeyboardButtonProps={{
          'aria-label': 'change date',
        }}
      />
    </Tooltip>
  </MuiPickersUtilsProvider>
)
