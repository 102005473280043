/**
 * This component is used in the last step of the wizard and also in the learn more section
 */

import { Typography, Box, Stack } from '@mui/material'
import React from 'react'

export const Bullet = () => {
  return (
    <Box
      component="span"
      sx={{
        width: '0.5rem',
        height: '0.5rem',
        borderRadius: '50%',
        backgroundColor: 'currentColor',
        flexShrink: 0,
        color: '#008080',
        display: 'inline-block',
        margin: ' 0 0.5rem 0 0.75rem',
      }}
    />
  )
}

export default function LearnMore() {
  return (
    <Stack direction="column" spacing={1}>
      <Typography variant="body1">
        Welcome to Unyte Client Connections! Clients often contact us seeking certified providers.
        Unyte Client Connections aims to connect them to you. As a benefit to you, there is no
        charge for this service!
      </Typography>

      <Typography variant="body1">
        🔎 <b>Here's how it works:</b>
      </Typography>
      <ol>
        <li>
          <Typography variant="body1">Clients answer questions online.</Typography>
        </li>
        <li>
          <Typography variant="body1">
            We match them with you based on their answers and your MyUnyte profile.
          </Typography>
        </li>
        <li>
          <Typography variant="body1">
            If there's a potential match, you'll get an email with further instructions on how to
            review the match.
          </Typography>
        </li>
      </ol>

      <Typography variant="body1">⏰ Your prompt response is crucial!</Typography>
      <Typography variant="body1">
        🧑‍💼 Keep your MyUnyte profile updated. Set your Unyte Client Connections status as needed.
      </Typography>
      <Typography variant="body1">
        🚀 Please send feedback or questions to{' '}
        <a href="mailto:clientconnections@unyte.com" className="text-link">
          clientconnections@unyte.com
        </a>
        .
      </Typography>
    </Stack>
  )
}
