import { uniqWith, isEqual } from 'lodash'

const SET_DATA = 'SEATS/SET_DATA'
const SET_PRODUCT = 'SEATS/SET_PRODUCT'
const RESET_SEATS = 'SEATS/RESET_SEATS'

const initialState = {
  data: [],
  products: new Set(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    // keep a copy of seats in set view for sessions filter
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case SET_PRODUCT:
      return {
        ...state,
        products: uniqWith(action.payload, isEqual),
      }
    case RESET_SEATS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function setSeatsProduct(payload) {
  return {
    type: SET_PRODUCT,
    payload,
  }
}

export function setSeatsData(payload) {
  return {
    type: SET_DATA,
    payload,
  }
}

export function resetSeats() {
  return {
    type: RESET_SEATS,
  }
}
