/**
 * This is the placeholder component for hubspot Iframe
 * - it is separated because we want hubspot to be at the top of the app
 */
import React, { useEffect } from 'react'
import { Box, Button } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'

// Custom scripts
import { setOpenHubspot } from 'store/modules/hubspot'
import useScript from 'utils/hooks/useScript'

// CSS
import '../style/hubspot.css'
import roles from 'utils/constants/roles'

export const removeHubspotScripts = () => {
  const dataLoaderScripts = document.querySelectorAll(`script[data-loader="hs-scriptloader"]`)
  const hsHeadScripts = document.querySelectorAll(`script[id^="hs-"]`)
  const srcScripts = document.querySelectorAll(
    `script[src="${process.env.REACT_APP_HUBSPOT_SCRIPT_URL}"]`
  )

  dataLoaderScripts?.forEach((script) => document.head.removeChild(script))
  hsHeadScripts?.forEach((script) => document.head.removeChild(script))
  srcScripts?.forEach((script) => document.body.removeChild(script))

  // remove window variables

  window.hsConversationsSettings = {
    loadImmediately: false,
    inlineEmbedSelector: '#hubspot-chat-widget-id',
  }
  window.hsCookieBanner = undefined
  window.HubSpotConversations = undefined
}

export function HubSpotWidget() {
  const dispatch = useDispatch()
  const { openHubspot, loaded } = useSelector((state) => get(state, 'hubspot', {}))
  const [status, setLoad] = useScript(process.env.REACT_APP_HUBSPOT_SCRIPT_URL || '')

  const authState = window.localStorage.getItem('AuthState')
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isProvider = currentUserRoles?.some((role) => roles.PROVIDER_ROLES.includes(role))

  useEffect(() => {
    if (authState === 'success' && isProvider) {
      setLoad(true)
    }
    if (status === 'ready') {
      setLoad(false)
    }
  }, [authState, isProvider])

  const handleClose = () => {
    dispatch(setOpenHubspot(false))
  }

  return (
    <Box
      id="hubspot-container"
      sx={{
        display: openHubspot ? 'block' : 'none',
      }}
    >
      <div id="hubspot-chat-widget-id" />
      {loaded && (
        <Button
          sx={{
            position: 'fixed',
            zIndex: 9999,
            right: '2rem',
            bottom: '1rem',
            backgroundColor: 'var(--green)',
            color: 'white',
            borderRadius: '9999px',
            width: '4rem',
            height: '4rem',
          }}
          variant="contained"
          onClick={handleClose}
        >
          {<CloseIcon />}
        </Button>
      )}
    </Box>
  )
}
