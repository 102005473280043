import React from 'react'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  Stack,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { useOutletContext } from 'react-router'
import { AcademyContextType } from './academy-layout'
import { useSelector } from 'react-redux'
import { isIlsCertified, isRrpCertified, isSspCertified } from 'utils/permissions/permissionsLogic'

export default function OnDemand() {
  const {
    showTalentLMSSSOButton,
    hasSspCertification,
    hasRrpCertification,
    hasFocusCertification,
    hasVoiceProCertification,
    error,
    hasCompletedVoiceProCertification,
    talentURL,
  } = useOutletContext<AcademyContextType>()

  const authUser = useSelector((state: any) => state.auth?.user)
  const hasCompletedRrpCertification = isRrpCertified({ authUser })
  const hasCompletedSspCertification = isSspCertified({ authUser })
  const hasCompletedFocusCertification = isIlsCertified({ authUser })

  return (
    <Stack p={2}>
      {hasCompletedSspCertification && (
        <Card elevation={0}>
          <CardHeader
            title="You have completed your Foundational SSP Training."
            titleTypographyProps={{ variant: 'body1' }}
            avatar={<CheckCircleIcon color="primary" fontSize="medium" />}
          />
        </Card>
      )}
      {hasCompletedRrpCertification && (
        <Card elevation={0}>
          <CardHeader
            title="You have completed your Preliminary RRP Training."
            titleTypographyProps={{ variant: 'body1' }}
            avatar={<CheckCircleIcon color="primary" fontSize="medium" />}
          />
        </Card>
      )}
      {hasCompletedFocusCertification && (
        <Card elevation={0}>
          <CardHeader
            title="You have completed your Foundational ILS Training."
            titleTypographyProps={{ variant: 'body1' }}
            avatar={<CheckCircleIcon color="primary" fontSize="medium" />}
          />
        </Card>
      )}
      {hasCompletedVoiceProCertification && (
        <Card elevation={0}>
          <CardHeader
            title="You have completed your VoicePro training & certification."
            titleTypographyProps={{ variant: 'body1' }}
            avatar={<CheckCircleIcon color="primary" fontSize="medium" />}
          />
        </Card>
      )}
      {!showTalentLMSSSOButton && (
        <Grid container direction="column" className="p-5">
          <Typography variant="body1" className="mb-5 text-link">
            You currently do not have access to any On-demand training courses.
          </Typography>
          <Typography variant="body1" className="mb-5 text-link">
            If you have previously purchased On-demand training and require access, please contact
            <span className="font-semibold mx-1">success@unyte.com</span>for further assistance.
          </Typography>
        </Grid>
      )}
      {showTalentLMSSSOButton && (
        <Card
          elevation={0}
          sx={{
            minWidth: 275,
            maxWidth: 1024,
          }}
        >
          <CardContent>
            <CardHeader
              title="Access your On-demand Training"
              titleTypographyProps={{ variant: 'h4' }}
            />
            <Divider />
            <Stack p={2} direction="column" justifyContent="flex-start">
              {hasSspCertification && (
                <Typography py={3} variant="body2" gutterBottom>
                  To access the SSP subscription and facilitate the SSP program in-person or
                  remotely, you must be a qualified professional who is certified in the
                  Foundational SSP Training.
                </Typography>
              )}
              {hasRrpCertification && (
                <Typography py={3} variant="body2" gutterBottom>
                  To access your RRP subscription and facilitate RRP program in-person or remotely,
                  you must be a qualified professional who has completed Preliminary RRP Training.
                </Typography>
              )}
              {hasFocusCertification && (
                <Typography py={3} variant="body2" gutterBottom>
                  To facilitate the ILS programs in-person or remotely, you must be a qualified
                  professional who is certified in the Foundational ILS Training.
                </Typography>
              )}
              {hasVoiceProCertification && (
                <Typography py={3} variant="body2" gutterBottom>
                  To facilitate the VoicePro System, you must be certified by completing the
                  training.
                </Typography>
              )}

              {error && (
                <FormHelperText error sx={{ marginTop: 10, width: '100%' }}>
                  Sorry, we had an issue accessing the learning platform.
                  <br />
                  Please try again or contact support if this issue persists.
                  <br />
                  For reference: {error}
                  <br />
                </FormHelperText>
              )}
            </Stack>

            <Grid item container xs={12} sm={9}>
              {showTalentLMSSSOButton && (
                <CardActions className="pt-3 pb-2">
                  <Button disabled={!!error} href={talentURL} variant="contained" color="primary">
                    Access Your Training
                  </Button>
                </CardActions>
              )}
              {!showTalentLMSSSOButton && (
                <Typography variant="body2" component="h2" color="textSecondary" className="pt-3">
                  <b>Please note:</b> always access your On-demand training from this page within
                  MyUnyte
                </Typography>
              )}
            </Grid>
          </CardContent>
        </Card>
      )}
    </Stack>
  )
}
