import React, { useState } from 'react'
import { Button } from 'components'

import EditCredentialsModal from '../modals/EditCredentials'

export default function EditCredentialsButton({ user, updateUser }) {
  const [showModal, setModalState] = useState(false)
  return (
    <>
      <Button className="m-1" color="primary" onClick={() => setModalState(true)}>
        Edit professional credentials
      </Button>
      <EditCredentialsModal
        open={showModal}
        user={user}
        onClose={() => setModalState(false)}
        _onSubmit={async (data) => {
          await updateUser({ variables: { user: data } })
          setModalState(false)
        }}
      />
    </>
  )
}
