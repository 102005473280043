import React from 'react'
import { InputLabel, Select, FormControl, MenuItem, FormHelperText } from 'components'
import { getCountries, getStates } from 'utils/constants/getIsoRegion'

export default function SelectState({
  country,
  value: _value,
  onChange,
  disabled,
  showFullStateName,
  error,
  label = 'State/Province/Region*',
}) {
  const findCountryObject = getCountries().find(({ name }) => name === country)
  const states = getStates(showFullStateName ? findCountryObject?.code : country)

  const value = _value || states?.find(({ subCode }) => subCode === _value)?.name || ''

  return (
    <FormControl className="w-full" data-test="select-state">
      <InputLabel id="state-input-label" error={error}>
        {label}
      </InputLabel>
      <Select
        labelId="state-select-label"
        label={label}
        id="state-select-label"
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={error}
        data-test="client-state-select"
      >
        {country &&
          states &&
          states.map(({ subCode, name }, index) => (
            <MenuItem
              data-test="select-state-item"
              value={showFullStateName ? name : subCode}
              key={index}
            >
              {name}
            </MenuItem>
          ))}
        {value &&
          !(
            states?.find(({ name }) => name === value) ||
            states?.find(({ subCode }) => subCode === value)
          ) && (
            <MenuItem data-test="select-state-item" value={value} key={value}>
              {value}
            </MenuItem>
          )}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  )
}
