/**
 * Score Guide for Assessments Reports:
 * - there's a different scoring guide for each assessments
 *
 */
import React from 'react'
import { get } from 'lodash'
import {
  Box,
  Paper as MuiPaper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from 'components'
import { useSelector } from 'react-redux'
import { SCORING_GUIDE } from 'views/assessments/utils/scoringGuide'

// interfaces
interface ScoringGuideTableProps {
  data: any[] // Specify a more specific type if possible, instead of 'any'
  shortCode: string
  description?: string // The '?' makes it optional
  isPrintMode?: boolean
  type: 'response' | 'interpretation'
}

interface BoxColorProps {
  color?: string
}

// utils
function transformAssessmentNameToPrefix(assessmentName: string) {
  // Convert to lowercase
  let result = assessmentName.toLowerCase()

  // Remove all brackets
  result = result.replace(/[\]()]/g, '')

  // Replace all spaces with hyphens
  result = result.replace(/\s+/g, '-')

  // Replace multiple consecutive hyphens with a single hyphen
  result = result.replace(/-+/g, '-')

  return result
}

// components
const Paper = (props) => {
  return <MuiPaper elevation={0} variant="outlined" {...props} />
}

function BoxColor({ color }: BoxColorProps) {
  if (!color) {
    return null
  }

  return <Box width="15px" height="15px" sx={{ backgroundColor: color }} />
}

function ScoringGuideTable({
  data,
  shortCode,
  description,
  isPrintMode,
  type,
}: ScoringGuideTableProps) {
  // get keys for headers
  const headers = Object.keys(data[0]).filter((title) => !['color'].includes(title))

  const transformDescription = ({ values }: { values: string[] }) => {
    return (
      <Stack spacing={3}>
        {values?.map((item) => (
          <Typography variant="body2">{item}</Typography>
        ))}
      </Stack>
    )
  }
  return (
    <TableContainer
      component={Paper}
      sx={{ width: isPrintMode && type === 'response' ? '50%' : 'inherit' }}
    >
      <Table aria-label="simple table">
        {description && (
          <TableHead>
            <TableRow>
              <TableCell align="left" colSpan={headers.length}>
                {description}
              </TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableHead>
          <TableRow sx={{ p: isPrintMode ? 1 : 3 }}>
            {headers.map((name, index) => (
              <TableCell align="left">{name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow
              key={`${shortCode}-${index}`}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{ p: isPrintMode ? 1 : 3 }}>
                <Stack
                  direction="row"
                  spacing={1}
                  alignItems="center"
                  pl={isPrintMode && type === 'response' ? 3 : 0}
                >
                  <BoxColor color={row.color} />
                  <Typography variant="body2">{row[headers[0]]}</Typography>
                </Stack>
              </TableCell>
              <TableCell align="left" sx={{ p: isPrintMode ? 1 : 3 }}>
                {transformDescription({ values: row[headers[1]] })}
              </TableCell>
              {headers.length === 3 && (
                <TableCell
                  align="left"
                  sx={{
                    minWidth: '5rem',
                    p: isPrintMode ? 1 : 3,
                  }}
                >
                  {row[headers[2]]}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default function ScoringGuide({ isPrintMode }) {
  const { selectedAssessmentName } = useSelector((state) => get(state, 'assessments', {}))
  const assessmentDetails = SCORING_GUIDE.find(({ assessmentName }) =>
    assessmentName.includes(selectedAssessmentName)
  )

  if (!assessmentDetails) {
    return null
  }

  const keyPrefix = transformAssessmentNameToPrefix(selectedAssessmentName)

  return (
    <Stack spacing={1} mx={3}>
      {!isPrintMode && (
        <Typography variant="h5" py={2}>
          Scoring Guide
        </Typography>
      )}
      {isPrintMode && (
        <Typography variant="h3" className="print-h3" sx={{ my: 'auto' }}>
          Scoring Guide & Interpretation
        </Typography>
      )}
      {assessmentDetails.scoringInterpretation.description && (
        <Stack spacing={3} ml={2} mb={3}>
          {assessmentDetails.scoringInterpretation.description}
        </Stack>
      )}

      <Stack direction={{ xs: 'column', sm: isPrintMode ? 'column' : 'row' }} spacing={3}>
        <ScoringGuideTable
          shortCode={keyPrefix}
          data={assessmentDetails.itemResponses}
          isPrintMode={isPrintMode}
          type={'response'}
        />
        {assessmentDetails.scoringInterpretation.interpretation && (
          <ScoringGuideTable
            shortCode={keyPrefix}
            data={assessmentDetails.scoringInterpretation.interpretation}
            description={assessmentDetails.scoringInterpretation.tableDescription}
            isPrintMode={isPrintMode}
            type={'interpretation'}
          />
        )}
        {assessmentDetails.scoringInterpretation2?.interpretation && (
          <ScoringGuideTable
            shortCode={keyPrefix}
            data={assessmentDetails.scoringInterpretation2.interpretation}
            description={assessmentDetails.scoringInterpretation2.tableDescription}
            isPrintMode={isPrintMode}
            type={'interpretation'}
          />
        )}
      </Stack>
      {assessmentDetails.scoringInterpretation.notes && (
        <Typography variant="body1" ml={2}>
          {assessmentDetails.scoringInterpretation.notes}
        </Typography>
      )}
    </Stack>
  )
}
