import { get, pick, isObject } from 'lodash'
import { apolloFetch } from 'utils/apollo'
import { gql } from '@apollo/client'
import ROLES from 'utils/constants/roles'
import { TEMPORARY_SSP_SUBSCRIPTION_SKUS } from 'utils/constants/prices'
import { setSeatsData, setSeatsProduct } from './seats'

const STORAGE_KEY = 'unyteFF'
const SET_FF = 'FF/SET'
const RESET_FF = 'FF/RESET'

const getStorageOverrides = () => {
  let overrides = {}
  try {
    const storageFF = JSON.parse(localStorage.getItem(STORAGE_KEY))
    if (isObject(storageFF)) {
      overrides = storageFF
    }
  } catch (e) {
    console.error(e)
  }
  return overrides
}

const GET_SEATS = gql`
  query Get_Seats($filter: FilterSeatsInput) {
    getSeats(filter: $filter) {
      id
      status
      userId
      product {
        assessmentTemplate
        category
        createdAt
        description
        id
        isEnabled
        name
        platforms
        updatedAt
        metadata
        order
      }
    }
  }
`

const GET_LICENSES = gql`
  query FFGetLicenses($filter: FilterLicensesInput) {
    getLicenses(filter: $filter) {
      id
      productId
      isActive
      product {
        id
        name
        description
        category
        metadata
        order
      }
    }
  }
`

const FF_GET_ORGANIZATION = gql`
  query FFGetOrganization {
    getOrganization {
      id
      productsStatus
      settings
    }
  }
`

const useProductsQuery = async ({ id, roles }) => {
  const isClient = roles.some((role) => ROLES.CLIENT_ROLES.includes(role))
  // skip cannot be called inside query
  if (isClient) {
    const { data: seats } = await apolloFetch({
      query: GET_SEATS,
      variables: {
        filter: {
          userIds: id,
          status: 'active',
        },
      },
    })
    return get(seats, 'getSeats', [])
  } else {
    const { data: licenses } = await apolloFetch({
      query: GET_LICENSES,
      variables: {
        filter: {
          isActive: true,
          includeUncertifiedLicenses: true,
        },
      },
      fetchPolicy: 'network-only',
    })
    return get(licenses, 'getLicenses', [])
  }
}

// states here affects visibility of routes
const initialState = {
  hasFFLoaded: false,
  hasAssessmentProducts: false,
  hasSspProducts: false,
  hasRrpProducts: false,
  hasFocusProducts: false,
  showBillingHistory: false,
  showNewSSPSubscriptionPlan: false,
  showNewRRPSubscriptionPlan: false,
  showNewFocusSubscriptionPlan: false,
  showNewSubscriptionPlan: false,
  showSubscriptionSlotsAssignedMessage: false,
  showProcessingCard: process.env.REACT_APP_STAGE === 'test',
  showClientConnectionsBadge: false,
  showNewClientsPage: false,
  showRRPProduct: false,
  showRRPFreeSample: false,
  rrpSampleActivatedAt: null,
  ...getStorageOverrides(),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_FF:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_FF:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

/**
 * Items here are for unreleased features and routes
 */
export function loadFFValues() {
  return async (dispatch, getState) => {
    const {
      auth: { user },
    } = getState()

    const seats = await useProductsQuery(user)
    const products = seats.map(({ product }) => product)

    const { data: organization } = await apolloFetch({
      query: FF_GET_ORGANIZATION,
    })
    const productsStatus = get(organization, 'getOrganization.productsStatus', {})
    const showNewClientsPage = get(
      organization,
      'getOrganization.settings.showNewClientsPage',
      'Not Found'
    )
    const showNewSSPSubscriptionPlan = get(
      organization,
      'getOrganization.settings.newSSPSubscriptionPlan',
      'Not Found'
    )
    const showNewFocusSubscriptionPlan = get(
      organization,
      'getOrganization.settings.newFocusSubscriptionPlan',
      'Not Found'
    )

    const showSubscriptionSlotsAssignedMessage = get(
      organization,
      'getOrganization.settings.slotsAutoAssignedDate',
      false
    )
    const showRRPProduct = get(organization, 'getOrganization.settings.showRRPProduct', 'Not Found')
    const showRRPFreeSample = get(
      organization,
      'getOrganization.settings.showRRPFreeSample',
      'Not Found'
    )

    // new UI is shown whether the status of ILS, and will be hidden when we have specifically
    const showNewSubscriptionPlan =
      ((showNewFocusSubscriptionPlan === true || showNewFocusSubscriptionPlan === 'Not Found') &&
        (showNewSSPSubscriptionPlan === true || showNewSSPSubscriptionPlan === 'Not Found') &&
        !(
          showNewFocusSubscriptionPlan === 'Not Found' && showNewSSPSubscriptionPlan === 'Not Found'
        )) ||
      (showNewFocusSubscriptionPlan === true && showNewSSPSubscriptionPlan === true)

    // show billing history if user no products in legacy or custom subscriptions
    const isBillingUser = user.roles.includes(ROLES.BILLING)
    const showBillingHistory =
      isBillingUser &&
      !Object.keys(productsStatus).some((sku) => TEMPORARY_SSP_SUBSCRIPTION_SKUS.includes(sku))

    // assessments are enabled if user has assessments in products (seat/license) and has org settings enabled
    // assessments are already filtered true
    const hasAssessmentProducts = !!products.find(({ category }) => category === 'assessment')
    const hasSspProducts = !!products.find(({ category }) => category === 'ssp')
    const hasFocusProducts = !!products.find(({ category }) => category === 'focus')
    const hasFFLoaded = true
    const hasRrpProducts = !!products.find(({ category }) => category === 'rrp')

    dispatch(setSeatsData(seats))
    dispatch(setSeatsProduct(products))
    dispatch(
      setFF({
        hasFFLoaded,
        hasAssessmentProducts,
        hasSspProducts,
        hasFocusProducts,
        hasRrpProducts,
        showBillingHistory,
        showNewSSPSubscriptionPlan,
        showNewFocusSubscriptionPlan,
        showNewSubscriptionPlan,
        showSubscriptionSlotsAssignedMessage,
        showNewClientsPage: showNewClientsPage === 'Not Found' ? false : showNewClientsPage,
        showRRPProduct: showRRPProduct === 'Not Found' ? false : showRRPProduct,
        showRRPFreeSample: showRRPFreeSample === 'Not Found' ? false : showRRPFreeSample,
        ...getStorageOverrides(),
      })
    )
  }
}

export function setFF(_payload) {
  const payload = pick(_payload, Object.keys(initialState))

  return {
    type: SET_FF,
    payload,
  }
}

export function resetFF() {
  return {
    type: RESET_FF,
  }
}
