import { gql } from '@apollo/client'

export const GET_ACTIVE_CLIENTS_BY_IDS = gql`
  query getActiveClientsByUserIds {
    getActiveClientsByUserIds
  }
`

export const PRODUCTS_QUERY = gql`
  query getProductsForClientsTable {
    getProducts {
      id
      name
      order
      category
    }
  }
`

export const USER_QUERY_WITH_SLOTS = gql`
  query getUsersForClientsTableWithSlots(
    $filter: FilterUsersInput
    $sort: [[String!]]
    $limit: Int
    $offset: Int
    $includeCount: Boolean
  ) {
    getUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      offset: $offset
      includeCount: $includeCount
    ) {
      address1
      address2
      birthYear
      city
      country
      createdAt
      dob
      email
      firstName
      fullName
      gender
      id
      isArchived
      isSuspended
      isInvitationMode
      lastLoginAt
      lastName
      phone
      seats {
        id
        status
        productId
      }
      slots {
        id
        status
        category
        expiredAt
        assignedAt
        usedAt
      }
      state
      roles
      zip
      productPreferences
      provider {
        id
        fullName
      }
      uuid
    }
  }
`

export const GET_AVAILABLE_SLOTS = gql`
  query GetAvailableSlots_ClientTable($filter: FilterSlotsInput) {
    getSlots(filter: $filter) {
      assignedAt
      category
      createdAt
      expiredAt
      id
      organizationId
      status
      type
      usedAt
      usedBySessionId
      userId
      updatedAt
    }
  }
`

export const GET_USER = gql`
  query getClient($filter: FilterUsersInput) {
    getUsers(filter: $filter) {
      id
      firstName
      lastName
      fullName
      email
      country
      gender
      birthYear
      isArchived
      isSuspended
      isInvitationMode
      lastLoginAt
      createdAt
      roles
      productPreferences
      provider {
        id
        fullName
      }
    }
  }
`

export const CREATE_USER = gql`
  mutation CreateUser($user: CreateUserInput!) {
    createUser(user: $user) {
      id
      email
      firstName
      lastName
      createdAt
      isSuspended
      country
      gender
      birthYear
    }
  }
`

export const CREATE_INVITATION = gql`
  mutation createInvitation($invitation: CreateInvitationInput!) {
    createInvitation(invitation: $invitation) {
      firstName
      lastName
      toEmail
      roles
    }
  }
`

export const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      createdAt
      isSuspended
      country
      gender
      birthYear
      isInvitationMode
    }
  }
`

/**
 * displays total time, total sessions
 */
export const GET_TOTAL_INTERATION_TIME = gql`
  query GET_TOTAL_INTERATION_TIME($filter: FilterSessionsInput) {
    getSessions(filter: $filter) {
      totalInteractionTime
    }
  }
`
