import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink, gql } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { store } from 'store'
import { logout } from 'store/modules/auth'
import { setNotifications } from 'store/modules/app'

import { Auth } from 'aws-amplify'

const httpLink = createHttpLink({
  uri: new URL('auth', process.env.REACT_APP_PURCHASE_URL).toString(),
})

const authLink = setContext(async (_, { headers }) => {
  try {
    const currentSession = await Auth.currentSession()
    const accessToken = currentSession.getIdToken()
    const token = accessToken.getJwtToken()

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    }
  } catch (err) {
    return {
      headers,
    }
  }
})

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode > 500) {
    store.dispatch(setNotifications(['Server error, try again']))
  }

  if (networkError && networkError.statusCode === 401) {
    store.dispatch(logout())
  }

  if (
    graphQLErrors &&
    (graphQLErrors || []).some(({ message }) => message === 'Not Authorized: user was disabled')
  ) {
    store.dispatch(logout())
  }
})

export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: ApolloLink.from([authLink, logoutLink, httpLink]),
  cache: new InMemoryCache(),
})

export function apolloFetch(data) {
  return client.query(data)
}

export function apolloMutate(data) {
  return client.mutate(data)
}

export const AUTH_CREATE_PURCHASE = gql`
  mutation AUTH_CREATE_PURCHASE($params: AuthCreatePurchaseInput!) {
    auth_create_purchase(params: $params) {
      uuid
      stripe_invoice
      stripe_invoice_id
    }
  }
`

export const AUTH_UPDATE_PURCHASE = gql`
  mutation AUTH_UPDATE_PURCHASE($uuid: String!, $params: AuthUpdatePurchaseInput!) {
    auth_update_purchase(uuid: $uuid, params: $params) {
      uuid
      stripe_invoice
      stripe_invoice_id
    }
  }
`

export const AUTH_FINALIZE_PURCHASE = gql`
  mutation AUTH_FINALIZE_PURCHASE($uuid: String!, $params: AuthFinalizePurchaseInput!) {
    auth_finalize_purchase(uuid: $uuid, params: $params) {
      uuid
      stripe_invoice
      stripe_invoice_id
    }
  }
`

export const CREATE_FB_EVENT = gql`
  mutation CREATE_FB_EVENT($params: JSON, $email: String) {
    create_fb_event(params: $params, email: $email)
  }
`
