import { BannerCardMapProps, BannerState } from './rrp-banner-cards.d'
import { themeDefinition as theme } from '../../utils/theme/Theme'

const colors = theme.palette

export const RRP_BANNER_STATE_0_NO_SHOW: BannerState = 'RRP_BANNER_STATE_0_NO_SHOW'
export const RRP_BANNER_STATE_1_OFFER: BannerState = 'RRP_BANNER_STATE_1_OFFER'
export const RRP_BANNER_STATE_2_ACCEPTED: BannerState = 'RRP_BANNER_STATE_2_ACCEPTED'
export const RRP_BANNER_STATE_3_ACTIVE: BannerState = 'RRP_BANNER_STATE_3_ACTIVE'
export const RRP_BANNER_STATE_4_PURCHASED: BannerState = 'RRP_BANNER_STATE_4_PURCHASED'
export const RRP_BANNER_STATE_5_TRIAL_EXPIRED: BannerState = 'RRP_BANNER_STATE_5_TRIAL_EXPIRED'
export const RRP_BANNER_STATE_6_MULTIPROVIDER: BannerState = 'RRP_BANNER_STATE_6_MULTIPROVIDER'

export const bannerCardMap: BannerCardMapProps = {
  RRP_BANNER_STATE_1_OFFER: {
    id: RRP_BANNER_STATE_1_OFFER,
    headerSelectLabel: 'State 1 Offer',
    title: 'Explore Rest and Restore Protocol™',
    subTitle: 'with a complimentary 30-day trial for valued providers like you!',
    optionalInfo: 'Free 30-day trial with no obligation — purchase an RRP subscription anytime.',
    backgroundColor: colors.gray.light,
    imageSrc: `/images/banner/${RRP_BANNER_STATE_1_OFFER}.webp`,
    buttonLabel: 'Learn More',
    buttonColor: colors.yellow.dark,
    buttonLink: '/store/rrpFreeTrial',
  },
  RRP_BANNER_STATE_2_ACCEPTED: {
    id: RRP_BANNER_STATE_2_ACCEPTED,
    headerSelectLabel: 'State 2 Accepted',
    title: 'Your Rest and Restore Protocol™ 30-day free trial is now active!',
    subTitle: 'Complete the short preliminary training in Unyte Academy to access RRP.',
    backgroundColor: colors.mint.main,
    imageSrc: `/images/banner/${RRP_BANNER_STATE_2_ACCEPTED}.webp`,
    buttonLabel: 'Learn More',
    buttonColor: colors.primary.main,
    buttonLink: '/store/rrpFreeTrial',
  },
  RRP_BANNER_STATE_3_ACTIVE: {
    id: RRP_BANNER_STATE_3_ACTIVE,
    headerSelectLabel: 'State 3 Active',
    title:
      'Your Rest and Restore Protocol™ trial has started with {AMOUNT_OF_DAYS_LEFT} days remaining.',
    body:
      'Purchase your RRP subscription anytime — it will start after your 30-day free trial ends. Learn more.',
    backgroundColor: colors.gray.light,
    imageSrc: `/images/banner/${RRP_BANNER_STATE_3_ACTIVE}.webp`,
    buttonLabel: 'Explore RRP Purchase Options',
    buttonColor: colors.pink.main,
    buttonLink: '/store/rrp',
  },
  RRP_BANNER_STATE_4_PURCHASED: {
    id: RRP_BANNER_STATE_4_PURCHASED,
    headerSelectLabel: 'State 4 Purchased',
    title:
      'You’re on your way! Thank you for supporting healing, restoration and homeostasis with Rest & Restore Protocol™!',
    body: `Your RRP subscription will start in {AMOUNT_OF_DAYS_LEFT} days when your free trial ends.
      We invite you to share your RRP experience for continued improvement.`,
    backgroundColor: colors.mint.main,
    imageSrc: `/images/banner/${RRP_BANNER_STATE_4_PURCHASED}.webp`,
  },
  RRP_BANNER_STATE_5_TRIAL_EXPIRED: {
    id: RRP_BANNER_STATE_5_TRIAL_EXPIRED,
    headerSelectLabel: 'State 5 Trial Expired',
    title: 'Your Rest & Restore Protocol™ 30-day trial has ended.',
    subTitle: 'Purchase your RRP subscription anytime or reach out with any questions.',
    backgroundColor: colors.mint.main,
    imageSrc: `/images/banner/${RRP_BANNER_STATE_5_TRIAL_EXPIRED}.webp`,
    buttonLabel: 'Explore RRP Purchase Options',
    buttonColor: colors.pink.main,
    buttonLink: '/store/rrp',
  },
}
