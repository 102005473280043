/**
 * This component is used in my profile
 * It is different from wizard because we have margins and save automatically
 */

import React, { useContext, useState } from 'react'
import { ProviderProfileContext } from '../MyProfile'
import { getCountries, getStates } from 'utils/constants/getIsoRegion'
import { Box, Stack, Typography, Button, Autocomplete, TextField, Grid } from '@mui/material'
import { formatRestrictionAddress } from 'utils/formatAddress'
import { get } from 'lodash'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import { ScrollableComponent } from 'components/scrollable-component'

const COUNTRY_NAMES = getCountries().map((c) => c.name)

export default function RemoteDeliveryRestriction({ idx, restriction, currentIdx, setCurrentIdx }) {
  const {
    form,
    setWizardState,
    handleLists,
    upsertAddress,
    showErrors,
    setShowErrors,
    wizardErrors,
    setWizardErrors,
    handleSave,
    wizardState,
  } = useContext(ProviderProfileContext)
  // open here opens edit mode..
  const [open, setOpen] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false)
  const [isNewRemoteRestriction, setIsNewRemoteRestriction] = useState(false)

  const error = showErrors ? wizardErrors : {}

  const [currentRestriction, setCurrentRestriction] = useState({
    countries: [],
    states: [],
    ...restriction,
  })

  // handles
  const handleEdit = () => {
    setWizardState(`REMOTE_DELIVERY_INFO/EDIT_${idx}`)
    setOpen(true)
    setCurrentIdx(idx)
  }
  const handleAdd = async () => {
    // stop adding if we have error
    const currentErrors = {
      countries: !currentRestriction?.countries.length,
    }

    if (Object.values(currentErrors).find((val) => !!val)) {
      setShowErrors(true)
      setWizardErrors(currentErrors)
      return
    }

    try {
      upsertAddress(currentIdx, 'remoteRestrictions', currentRestriction)
      setWizardState()
      setOpen(false)
      setWizardErrors({})
      setShowErrors(false)

      // always reset after adding..
      setCurrentRestriction({ ...restriction, countries: [], states: [] })
    } finally {
      let newRemoteRestriction
      if (!form.remoteRestrictions.length) {
        newRemoteRestriction = [currentRestriction]
      } else if (currentIdx === form.remoteRestrictions.length) {
        newRemoteRestriction = [...form.remoteRestrictions, currentRestriction]
      } else {
        // insert into form..
        newRemoteRestriction = form.remoteRestrictions.map((restriction, index) =>
          index === currentIdx ? currentRestriction : restriction
        )
      }

      const _deliveryModels = get(form, 'deliveryModels', [])
      const deliveryModels = !_deliveryModels.includes('Remote')
        ? [..._deliveryModels, 'Remote']
        : [..._deliveryModels]

      handleSave({ remoteRestrictions: newRemoteRestriction, deliveryModels })
    }
  }

  /**
   * if we removed the only one, or we are cancelling the only one.. we need to reset back to the default state..
   */

  const handleRemove = () => {
    const newRemoteRestriction = form.remoteRestrictions.filter((_, index) => currentIdx !== index)
    handleLists('remoteRestrictions')('', newRemoteRestriction)
    handleSave({ remoteRestrictions: newRemoteRestriction })
    setCurrentRestriction({ ...restriction, countries: [], states: [] })
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }
  const handleCancel = () => {
    if (isNewRemoteRestriction) {
      setCurrentRestriction({ ...restriction, countries: [], states: [] })
    } else {
      setCurrentRestriction({ ...restriction })
    }
    setWizardState()
    setOpen(false)
    setWizardErrors({})
    setShowErrors(false)
  }
  const handleCountryChange = (_, val) => {
    const countries = val.map((countryName) => {
      const isoCode = getCountries().find((country) => country.name === countryName)?.code
      return {
        name: countryName,
        isoCode,
      }
    })
    // update current state
    setCurrentRestriction({ ...currentRestriction, countries, states: [] })
    // update context
    handleLists('countries')(countries)
    handleLists('states')([])
  }
  const handleStateChange = (_, val) => {
    const states = val.map((stateName) => {
      const isoCode = getStates(currentRestriction?.countries[0].isoCode).find(
        (state) => state.name === stateName
      )?.code
      return {
        name: stateName,
        isoCode,
      }
    })
    // update context
    setCurrentRestriction({ ...currentRestriction, states })
    handleLists('state')(states)
  }

  useOnValueChange(JSON.stringify({ wizardState }), () => {
    setIsNewRemoteRestriction(
      wizardState?.includes(`REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}`)
    )

    const _isEditMode = wizardState?.includes('REMOTE_DELIVERY_INFO/EDIT')
    setIsEditMode(_isEditMode)

    // if state has been updated to edit but we are still open, reset and turn off
    if (!wizardState?.includes('EDIT') && open) {
      setOpen(false)

      // to consider, what if we're from the wizard.......
    } else if (wizardState?.includes('REMOTE_DELIVERY_INFO/EDIT_0/OPEN') && idx === 0) {
      setOpen(true)
    }

    // if we are the last row and we are in edit mode, reload info into currentState
    if (_isEditMode && idx === form.remoteRestrictions.length) {
      const _restriction = get(form, `remoteRestrictions[${currentIdx}]`, {})
      setCurrentRestriction({
        countries: [],
        states: [],
        ..._restriction,
      })
    }
  })

  // todo, hide this entire row if we're trying to edit the current item..
  if (wizardState?.includes(`EDIT_${idx}`) && idx < form.remoteRestrictions.length) {
    return null
  }

  return (
    <>
      {// hide when we're editing current component
      !wizardState?.includes(`EDIT_${idx}`) && (
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          {idx !== form.remoteRestrictions.length && (
            <>
              <Grid item xs={3}>
                <Typography variant="body2">Remote Delivery Area {idx + 1}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="body2" sx={{ color: 'gray !important' }}>
                  I deliver remotely in {formatRestrictionAddress(currentRestriction)}
                </Typography>
              </Grid>
            </>
          )}
          {!isEditMode && (
            <Grid
              item
              container
              sx={{
                marginLeft: '-0.5rem',
              }}
              xs={3}
              justifyContent={idx === form.remoteRestrictions.length ? 'flex-start' : 'flex-end'}
            >
              <Button variant="text" onClick={handleEdit}>
                {idx === form.remoteRestrictions.length
                  ? 'Specify Another Remote Delivery Area'
                  : 'Edit Remote Delivery Area'}
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      {// the last row should not be OPENed
      isEditMode && idx === form.remoteRestrictions.length && (
        <Stack direction="column" alignItems="flex-start">
          <Stack sx={{ width: '100%' }} spacing={2}>
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>
              Specify your remote delivery areas. Consider your licensing restrictions (if any):
            </Typography>

            <Stack direction="column" className="w-full">
              <Stack direction="row" spacing={1} alignItems="center">
                <Autocomplete
                  fullWidth
                  multiple
                  options={COUNTRY_NAMES}
                  value={currentRestriction?.countries?.map((country) => country.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Countries"
                      placeholder="Countries"
                      error={error.countries}
                    />
                  )}
                  onChange={handleCountryChange}
                />
              </Stack>

              {currentRestriction?.countries.length === 1 && (
                <Autocomplete
                  fullWidth
                  multiple
                  options={
                    currentRestriction?.countries.length === 1
                      ? getStates(currentRestriction?.countries[0].isoCode).map(
                          (state) => state.name
                        )
                      : []
                  }
                  label="State/Province/Region (optional)"
                  value={currentRestriction.states.map((state) => state.name)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State (optional)"
                      placeholder="State (optional)"
                    />
                  )}
                  onChange={handleStateChange}
                />
              )}
            </Stack>
          </Stack>
        </Stack>
      )}

      {isEditMode && idx === form.remoteRestrictions.length && (
        <ScrollableComponent autoFocus={isEditMode && idx === form.remoteRestrictions.length}>
          <Stack direction="row" justifyContent="space-between" my={2}>
            <Box sx={{ marginLeft: '-0.5rem' }}>
              <Button onClick={handleAdd}>
                {wizardState === `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}`
                  ? 'Add Remote Delivery Area'
                  : 'Save'}
              </Button>
              <Button onClick={handleCancel}>Cancel</Button>
            </Box>
            {/* // only remove when this is a saved entry.. what is a saved entry*/}
            {wizardState !== `REMOTE_DELIVERY_INFO/EDIT_${form.remoteRestrictions.length}` &&
              form.remoteRestrictions.length > 0 && (
                <Button color="secondary" onClick={handleRemove}>
                  Remove
                </Button>
              )}
          </Stack>
        </ScrollableComponent>
      )}
    </>
  )
}
