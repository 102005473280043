import { useSnackbar } from 'notistack'
import { connect } from 'react-redux'

import { setNotifications } from 'store/modules/app'

export function Snakbars({ errors, setNotifications }) {
  const { enqueueSnackbar } = useSnackbar()

  if (errors && errors.length) {
    errors.forEach(console.error)
    errors.forEach(enqueueSnackbar)
    setNotifications([])
  }

  return null
}

export default connect(
  (state) => ({
    errors: state.app.errors,
  }),
  { setNotifications }
)(Snakbars)
