import React from 'react'
import { Grid, Card, CardHeader, CardContent, Typography } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import EMAILS from 'utils/constants/emails'

const useStyles = makeStyles({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
})

export default function VoiceProTrainingComplete({ gridProps }) {
  const classes = useStyles()

  return (
    <Grid item {...gridProps}>
      <Card
        data-test="dashboard-card-voicepro-certified-user"
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          title={'You are VoicePro certified!'}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Typography variant="body2" className="py-1">
            Congratulations on completing your VoicePro Training!
          </Typography>
          <Typography variant="body2" className="py-1">
            Please contact{' '}
            <a className="text-link" href={`mailto:${EMAILS.supportEmail}`}>
              {EMAILS.supportEmail}
            </a>{' '}
            for VoicePro System purchase options.
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
