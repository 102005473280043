import React, { useState } from 'react'
import { Button, Tooltip } from '@mui/material'

export default ({ user }) => {
  const [isUuidCopied, setsIsUuidCopied] = useState(false)

  const onCopy = () => {
    navigator.clipboard.writeText(user.uuid)
    setsIsUuidCopied(true)
  }

  return (
    <>
      <Tooltip
        title={
          isUuidCopied ? (
            <h3>
              Copied to clipboard:
              <br /> {user.uuid}{' '}
            </h3>
          ) : (
            <h3>
              Copy Client Id to clipboard:
              <br /> {user.uuid}
            </h3>
          )
        }
      >
        <Button className="m-1" data-test="copy-user-uuid-button" onClick={onCopy}>
          Copy Client ID
        </Button>
      </Tooltip>
    </>
  )
}
