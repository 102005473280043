import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import useForm from 'components/form/useForm'
import {
  PageLoader,
  CardContent,
  CardActions,
  TextField,
  Button,
  FormHelperText,
  PasswordInput,
  TermsOfUse,
} from 'components'

import styles from './InvitationAcceptNew.module.scss'
import queryString from 'query-string'

import { invitationAccept, login, logout } from 'store/modules/auth'

import CardWithBg from 'components/CardWithBg'

export function InvitationAcceptNew({ invitationAccept, match, logout }) {
  const navigate = useNavigate()
  const { id } = useParams()
  const [canRender, setCanRender] = useState(false)
  const [agreement, setAgreement] = useState(false)
  const queryURL = queryString.parse(window.location.search)
  const roles = (queryURL.roles || '').split(',')

  const {
    form,
    setFormValue,
    onSubmit,
    pending,
    errors,
    isValid,
    trimmedForm,
    validateField,
  } = useForm({
    data: {
      firstName: queryURL.firstName,
      lastName: queryURL.lastName,
      email: (queryURL.toEmail || '').replace(' ', '+'),
      password: '',
      confirmPassword: '',
    },
    validation: {
      firstName: {
        required: { msg: 'First name is required.' },
        len: {
          args: [null, 100],
          msg: 'First name must be no longer than 100 characters',
        },
      },
      lastName: {
        required: { msg: 'Last name is required.' },
        len: {
          args: [1, 100],
          msg: 'Last name must be present and no longer than 100 characters',
        },
      },
      email: {
        required: { msg: 'Email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
      },
      password: {
        required: { msg: 'Password is required.' },
        password: {
          msg:
            'Password must be 8 characters or longer and contain at least one number and lowercase letter.',
        },
      },
      confirmPassword: {
        required: { msg: 'Password is required.' },
        password: {
          msg:
            'Password confirmation must be 8 characters or longer and contain at least one number and lowercase letter.',
        },
        equal: { args: { field: 'password' }, msg: 'Passwords must match' },
      },
    },
    async onSubmit(e) {
      try {
        e.preventDefault()
        await invitationAccept({ ...trimmedForm, invitationId: parseInt(id) })
        await localStorage.setItem('unyteShowIntro', true)
        await localStorage.setItem('AuthState', 'success')
        await window.location.replace('/')
      } catch (error) {
        await logout()
        throw error
      }
    },
  })

  useEffect(() => {
    logout().then(() => setCanRender(true))
    // eslint-disable-next-line
  }, [logout])

  useEffect(() => {
    if (form.password.length && form.confirmPassword.length) {
      validateField('password')
      validateField('confirmPassword')
    }
    // eslint-disable-next-line
  }, [form.password, form.confirmPassword])

  if (!canRender) {
    return <PageLoader />
  }

  const onCancel = () => {
    navigate('/sign-in')
  }

  return (
    <CardWithBg>
      <form onSubmit={onSubmit} autoComplete="off">
        <CardContent>
          <h1 className={styles.title}>Accept Invitation</h1>
          <FormHelperText className="py-2" error>
            {errors.none}
          </FormHelperText>
          <TextField
            disabled
            id="invitationEmail"
            label="Email*"
            className="mb-3"
            fullWidth
            value={form.email}
            onChange={setFormValue('email')}
            error={errors.email}
            inputProps={{
              autoComplete: 'invitation-email',
            }}
          />
          <TextField
            id={`${Math.random()}`}
            disabled={pending}
            label="First name*"
            className="mb-3"
            fullWidth
            value={form.firstName}
            onChange={setFormValue('firstName')}
            error={errors.firstName}
            inputProps={{
              autoComplete: 'invitation-first-name',
            }}
          />
          <TextField
            id={`${Math.random()}`}
            disabled={pending}
            label="Last name*"
            className="mb-3"
            fullWidth
            value={form.lastName}
            onChange={setFormValue('lastName')}
            error={errors.lastName}
            inputProps={{
              autoComplete: 'invitation-last-name',
            }}
          />
          <FormHelperText sx={{ paddingBottom: '0.5rem' }}>
            Please choose a password:
          </FormHelperText>
          <PasswordInput
            disabled={pending}
            label="Password*"
            className="mb-3"
            fullWidth
            value={form.password}
            onChange={setFormValue('password')}
            error={errors.password}
            inputProps={{
              autoComplete: 'invitation-password-1',
            }}
          />
          <PasswordInput
            disabled={pending}
            label="Confirm password*"
            className="mb-3"
            fullWidth
            value={form.confirmPassword}
            onChange={setFormValue('confirmPassword')}
            error={errors.confirmPassword}
            inputProps={{
              autoComplete: 'invitation-password-2',
            }}
          />

          <TermsOfUse
            checked={agreement}
            onChange={setAgreement}
            roles={roles}
            disabled={pending}
          />
        </CardContent>
        <CardActions>
          <Button color="secondary" disabled={pending} onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" color="primary" loading={pending} disabled={!isValid || !agreement}>
            Accept Invitation
          </Button>
        </CardActions>
      </form>
    </CardWithBg>
  )
}

export default connect(null, {
  login,
  logout,
  invitationAccept,
})(InvitationAcceptNew)
