import React from 'react'
import { Box, Chip } from 'components'

export default function ChipList({ labels }) {
  return (
    <Box display="flex" flexWrap="wrap">
      {labels?.map((label) => (
        <Box key={label} m={0.5}>
          <Chip label={label} />
        </Box>
      ))}
    </Box>
  )
}
