import React, { useRef, useState, useEffect } from 'react'
import moment from 'utils/moment'
import { get, throttle, first, last } from 'lodash'
import { Grid, Card, Paper, FormHelperText, CircularProgress } from 'components'
import { formatSeconds } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import formats from 'utils/constants/formats'
import SessionDetails from './SessionDetails'

import {
  BarChart,
  ResponsiveContainer,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
  // LabelList,
} from 'recharts'

import FilterSessions from './Filter'
import ListCard from './ListCard'
import styles from './Sessions.module.scss'
import Loading from '../../../../components/Loading'
import { useGetSessions } from 'utils/hooks/useGetSessions'
import { useParams } from 'react-router'
import { setSession } from 'store/modules/clients'

/**
 * Get user Id from params, if no id in params, then use your own ID
 *
 */
export default function Sessions({ user }) {
  const { id, clientId } = useParams()
  const dispatch = useDispatch()
  const paramUserId = id || clientId
  const authUserId = useSelector((state) => state?.auth?.user?.id) // this is for clients
  const isOwnDashboard = !paramUserId
  const userIds = [paramUserId, !paramUserId && authUserId].filter(Boolean)
  const [filter, setFilter] = useState({})
  const { products, loading, finishedLoading } = useGetSessions({
    userIds,
    filter,
  })
  /**
   * Grab session from redux
   * - we cannot use hasSspSession / hasFocusSession from context because `ClientDashboard` does not
   * have access to this information
   */
  const sessions = useSelector((state) => get(state, 'clients.sessions', []))
  const hasSessions = !!sessions.length

  const [[brushStart, brushEnd], setBrushPosition] = useState([0, 6])
  const [topElementIndex, setTopElementIndex] = useState(0)
  const [selectedSession, selectSession] = useState(null)

  const onListScroll = useRef(
    throttle((e) => {
      const scrollTop = e.target.scrollTop
      const elementHeight = e.target.children[0].offsetHeight
      const topElementIndex = parseInt(scrollTop / elementHeight)
      setTopElementIndex(topElementIndex)
    }, 50)
  ).current

  useEffect(() => {
    const STEP = 5
    const lastElementIndex = sessions.length
    const min = Math.min(topElementIndex, Math.max(lastElementIndex - STEP, 0))
    const max = Math.min(topElementIndex + 5, Math.max(lastElementIndex, 0))
    setBrushPosition([min, max])
  }, [topElementIndex, sessions])

  const [form, setForm] = useState({
    selectedProducts: null,
    date: [undefined, undefined],
  })

  useEffect(() => {
    if (!form.selectedProducts && products.length) {
      setForm({ ...form, selectedProducts: products.map(({ id }) => id) })
    }
    // eslint-disable-next-line
  }, [products])

  useEffect(() => {
    return () => {
      dispatch(setSession([]))
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {/* no loading in cypress */}
      {loading && <Loading rows={1} columns={1} height={['300px']} width={['100%']} />}
      {!loading && (
        <>
          <SessionDetails
            user={user}
            session={selectedSession}
            onClose={() => selectSession(null)}
          />

          {/* Do not show table if user does not have sessions */}
          {(hasSessions || !isOwnDashboard) && (
            <div>
              <Paper className={`my-2 p-5 ${styles.headerPaper}`}>
                <Grid container justifyContent="space-around">
                  <Grid item xs={3} className={styles.headerItem}>
                    <div className={styles.headerItemValue}>
                      {finishedLoading &&
                        (() => {
                          const totalSeconds = sessions.reduce(
                            (total, item) => total + item.totalInteractionTime,
                            0
                          )
                          return totalSeconds ? formatSeconds(totalSeconds) : 0
                        })()}
                      {!finishedLoading && <CircularProgress style={{ color: 'white' }} />}
                    </div>
                    <div className={styles.headerItemTitle}>Total time</div>
                  </Grid>
                  <Grid item xs={3} className={styles.headerItem}>
                    <div className={styles.headerItemValue}>
                      {finishedLoading && sessions.filter((item) => item.type !== 'answers').length}
                      {!finishedLoading && <CircularProgress style={{ color: 'white' }} />}
                    </div>
                    <div className={styles.headerItemTitle}>Total sessions</div>
                  </Grid>
                </Grid>
              </Paper>

              <FilterSessions
                dateFrom={get(last(sessions), 'startedAt')}
                dateTo={get(first(sessions), 'startedAt')}
                setFilter={setFilter}
                filter={filter}
                form={form}
                setForm={setForm}
              />

              {!hasSessions && (
                <Card className="p-5">
                  <Grid
                    data-test="no-sessions-grid"
                    container
                    justifyContent="center"
                    alignItems="center"
                  >
                    {!isOwnDashboard && !hasSessions
                      ? 'Data will appear here once a session is completed'
                      : 'No sessions to display'}
                  </Grid>
                </Card>
              )}

              {hasSessions && (
                <Card className="p-2">
                  <Grid data-test="sessions-chart" container>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={4}
                      className={styles.list}
                      onScroll={(e) => onListScroll({ ...e })}
                    >
                      {sessions
                        .filter((item) => item.type !== 'answers')
                        .map((item, i) => (
                          <ListCard data={item} key={i} onClick={() => selectSession(item)} />
                        ))}
                    </Grid>
                    <Grid item xs={12} md={6} lg={8}>
                      <ResponsiveContainer height={300} width="100%">
                        <BarChart data={sessions.slice(brushStart, brushEnd)}>
                          <CartesianGrid vertical={false} />
                          <XAxis reversed={true} dataKey="x" />

                          <YAxis
                            yAxisId="left"
                            dataKey="y2"
                            orientation="left"
                            domain={[0, 'auto']}
                            tickFormatter={(val) => formatSeconds(val).replace(' ', ' ')}
                          />
                          <Bar yAxisId="left" dataKey="y2" isAnimationActive={false}>
                            {sessions
                              .slice(brushStart, brushEnd)
                              .filter(({ y2 }) => !!y2)
                              .map((entry, index) => (
                                <Cell stackId="stack" cursor="pointer" fill="#62c3d9" key={index} />
                              ))}
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                      <div className={styles.brushRelativeContainer}>
                        <div className={styles.brushAbsoluteContainerTop}>
                          <div
                            style={(() => {
                              const width =
                                (brushEnd * 100) / sessions.length -
                                (brushStart * 100) / sessions.length
                              const offset = (brushStart * 100) / sessions.length
                              return {
                                height: 100,
                                position: 'relative',
                                left: `${100 - offset - width}%`,
                                width: `${width}%`,
                                backgroundColor: 'rgba(0, 0, 0, .2)',
                              }
                            })()}
                          />
                        </div>
                        <div className={styles.brushAbsoluteContainer}>
                          <ResponsiveContainer height={100} width="100%">
                            <BarChart data={[].concat(sessions).reverse()}>
                              <Bar
                                dataKey="y2"
                                isAnimationActive={false}
                                data={[].concat(sessions).reverse()}
                                fill="#eee"
                              />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                      <Grid className={styles.brushDates}>
                        <FormHelperText>
                          {last(sessions) && moment(last(sessions).startedAt).format(formats.date)}
                        </FormHelperText>
                        <FormHelperText>
                          {first(sessions) &&
                            moment(first(sessions).startedAt).format(formats.date)}
                        </FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              )}
            </div>
          )}
        </>
      )}
    </>
  )
}
