import React from 'react'
import { InputLabel, Select, FormControl, MenuItem, ListSubheader, Divider } from '@mui/material'
import { getCountries } from 'utils/constants/getIsoRegion'

/**
 *  NOte: React Fragments will break the selection item
 */
export default function SelectCountry({
  value: _value,
  onChange,
  disabled,
  showFullName,
  error,
  label = 'Country*',
  formClassName = 'w-full mb-5',
  excludeNonShippableCountries = false,
}) {
  const allCountries = getCountries()
  const filteredCountries = excludeNonShippableCountries
    ? allCountries.filter((country) => !country.isNotShippable)
    : allCountries

  const frequentlySelected = filteredCountries.slice(0, 2)
  const rest = filteredCountries.slice(2, -1)

  const value = _value || filteredCountries?.find(({ code }) => code === _value)?.name || ''

  const wordWrapStyling = {
    whiteSpace: 'normal',
  }

  return (
    <FormControl disabled={disabled} className={formClassName} data-test="select-country">
      <InputLabel id="country-input-label" error={error}>
        {label}
      </InputLabel>
      <Select
        label={label}
        labelId="country-select-label"
        error={error}
        id="country-select-label"
        value={value}
        onChange={onChange}
        data-test="client-country-select"
      >
        <ListSubheader disableSticky>Frequently selected</ListSubheader>
        {frequentlySelected.map(({ code, name }) => (
          <MenuItem
            data-test="select-country-item"
            value={showFullName ? name : code}
            key={code}
            sx={wordWrapStyling}
          >
            {name}
          </MenuItem>
        ))}
        <Divider />
        {rest.map(({ code, name }) => (
          <MenuItem value={showFullName ? name : code} key={code} sx={wordWrapStyling}>
            {name}
          </MenuItem>
        ))}
        {value &&
          !(
            filteredCountries?.find(({ name }) => name === value) ||
            filteredCountries?.find(({ code }) => code === value)
          ) && (
            <MenuItem value={value} key={value} sx={wordWrapStyling}>
              {value}
            </MenuItem>
          )}
      </Select>
    </FormControl>
  )
}
