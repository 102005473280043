import React from 'react'
import makeStyles from '@mui/styles/makeStyles'

import { Box, Grid, Card, CardHeader, CardContent, Typography } from 'components'

const useStyles = makeStyles({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
  link: {
    color: '#616161',
  },
  circle: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
    marginTop: 3,
    flexShrink: 0,
  },
  action: {
    fontWeight: 500,
  },
})

export default function GettingStarted({ gridProps }) {
  const classes = useStyles()

  return (
    <Grid item {...gridProps}>
      <Card
        data-test="dashboard-card-enrolled-voicepro-training"
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          title={'Complete your VoicePro Training'}
          titleTypographyProps={{ variant: 'h5' }}
        />
        <CardContent>
          <Typography variant="body2" gutterBottom className="py-1">
            The VoicePro Training will provide you with the support and protocols for delivering the
            VoicePro System. You will learn to create individualized sessions for your clients to
            meet their auditory processing goals and more.
          </Typography>
          <Box display="flex" className="py-1">
            <div className={`${classes.circle} text-green`} />
            <Typography variant="body2" className={`px-1 ${classes.action}`}>
              Click on Academy in the left menu to access your VoicePro Training.
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  )
}
