import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { useSelector, useDispatch } from 'react-redux'
import { get, cloneDeep } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation } from 'utils/apollo'
import { gql } from '@apollo/client'

import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button,
  TextField,
  FormHelperText,
  Grid,
  Typography,
  Divider,
  Stack,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import { useUserForm } from 'hooks'
import { extendUser } from 'store/modules/auth'
import { includesSome } from 'utils/includes'
import tabs from 'utils/constants/tabs'
import ROLES from 'utils/constants/roles'
import AgeDropdown from '../../components/AgeDropdown'
import SelectCountry from '../../components/ecommerce/shipping/SelectCountry'
import SelectState from '../../components/ecommerce/shipping/SelectState'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const UPDATE_USER = gql`
  mutation updateUser($user: UpdateUserInput!) {
    updateUser(user: $user) {
      id
      email
      firstName
      lastName
      createdAt
      isSuspended
      address1
      address2
      city
      state
      country
      phone
      dob
      professionalCredentials
      zip
      gender
      birthYear
    }
  }
`

const useStyles = makeStyles({
  email: {
    fontWeight: 400,
  },
})

export default function EditContact() {
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const user = useSelector((state) => get(state, 'auth.user'))
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const [updateUser] = useMutation(UPDATE_USER)
  const {
    form,
    setFormValue,
    setFormData,
    onSubmit,
    pending,
    errors,
    isValid,
    trimmedForm,
    setErrors,
  } = useUserForm({
    user: cloneDeep(user),
    fields: [
      'id',
      'firstName',
      'lastName',
      'dob',
      'age',
      'birthYear',
      'address1',
      'address2',
      'city',
      'state',
      'zip',
      'country',
      'phone',
      'gender',
    ],
    async onSubmit(form) {
      const newForm = Object.keys(form).reduce((accumulator, k) => {
        if (k === 'age') {
          return accumulator
        }
        return form[k] ? { ...accumulator, [k]: form[k] } : { ...accumulator, [k]: null }
      }, {})

      const res = await updateUser({ variables: { user: newForm } })
      dispatch(extendUser(res.data.updateUser))
      setErrors({})
      enqueueSnackbar('Update successful', { variant: 'success', action: CloseSnackbarAction })
    },
  })

  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))
  const isProvider = includesSome(currentUserRoles, [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS])
  const isBilling = includesSome(currentUserRoles, [ROLES.BILLING])
  const TABS = tabs.filter(({ isEnabled }) =>
    isEnabled({
      isProvider,
      isBilling,
    })
  )

  return (
    <MainLayout title="My Account" tabs={TABS}>
      <Grid container spacing={2} className="px-5">
        <Grid item md={6}>
          <form className="py-4">
            <FormHelperText className="mb-2" error>
              {errors.none}
            </FormHelperText>
            {[
              { key: 'email', label: 'Email address', isActive: true },
              { key: 'firstName', label: 'First Name*', isActive: true },
              { key: 'lastName', label: 'Last Name*', isActive: true },
              { key: 'dob', label: 'Date of Birth (mm/dd/yyyy)', isActive: isClient },
              { key: 'gender', label: 'Gender', isActive: true },
              { key: 'address1', label: 'Address 1', isActive: true },
              { key: 'address2', label: 'Address 2', isActive: true },
              { key: 'country', label: 'Country', isActive: true },
              { key: 'state', label: 'State/Province/Region', isActive: true },
              { key: 'city', label: 'City', isActive: true },
              { key: 'zip', label: 'Zip', isActive: true },
              { key: 'phone', label: 'Phone', isActive: true },
            ]
              .filter(({ isActive }) => isActive)
              .map(({ key, label, isActive }) => {
                if (key === 'id') return null
                if (key === 'dob' && isClient) {
                  return (
                    <Grid container>
                      <AgeDropdown
                        isClientDetails
                        label={label}
                        formKey={key}
                        errors={errors}
                        form={form}
                        setErrors={setErrors}
                        setFormValue={setFormValue}
                        setFormData={setFormData}
                      />
                    </Grid>
                  )
                }
                if (key === 'email') {
                  return (
                    <div className="py-5">
                      <Typography className={classes.email} variant="h6" gutterBottom>
                        {form[key]}
                      </Typography>{' '}
                      <Divider />{' '}
                    </div>
                  )
                }
                if (key === 'gender') {
                  return (
                    <FormControl className="w-full">
                      <InputLabel id="select-gender-input-label">Gender</InputLabel>
                      <Select
                        label="Gender"
                        labelId="select-gender-label"
                        id="select-gender-id"
                        value={form.gender}
                        onChange={setFormValue('gender')}
                      >
                        <MenuItem value={'Woman/Girl'}>Woman/Girl</MenuItem>
                        <MenuItem value={'Man/Boy'}>Man/Boy</MenuItem>
                        <MenuItem value={'Non-Binary/Non-Conforming'}>
                          Non-Binary/Non-Conforming
                        </MenuItem>
                        <MenuItem value={'Prefer not to respond'}>Prefer not to respond</MenuItem>
                      </Select>
                    </FormControl>
                  )
                }
                if (key === 'country') {
                  return (
                    <SelectCountry
                      value={form.country}
                      onChange={(event) =>
                        setFormData({
                          ...form,
                          country: event.target.value,
                          state: null,
                          city: null,
                        })
                      }
                      disabled={!isActive}
                      showFullName
                      label={label}
                      formClassName="w-full my-3"
                    />
                  )
                }
                if (key === 'state') {
                  return (
                    <SelectState
                      country={form.country}
                      value={form.state}
                      onChange={setFormValue('state')}
                      disabled={!isActive || !form.country}
                      showFullStateName
                      label={label}
                      formClassName="w-full m-3"
                    />
                  )
                }
                return (
                  <TextField
                    key={key}
                    value={form[key] || ''}
                    fullWidth
                    onChange={setFormValue(key)}
                    label={label}
                    id={key}
                    error={errors[key]}
                    disabled={key === 'email'}
                    className={classes.root}
                  />
                )
              })
              .filter(Boolean)}
            {(errors.none || errors.dob || errors.birthYear) && (
              <Grid container justifyContent="flex-end">
                <FormHelperText error>
                  Sorry but one or more fields are invalid.
                  <br />
                  For reference: {errors.birthYear || errors.dob || errors.none}
                  <br />
                  Please try again or contact support if this issue persists.
                </FormHelperText>
              </Grid>
            )}
            <Stack justifyContent="flex-end">
              <Button
                sx={{ marginLeft: 'auto' }}
                color="primary"
                disabled={!isValid || errors.none}
                loading={pending}
                onClick={async (e) => {
                  e.preventDefault()
                  await onSubmit(trimmedForm)
                }}
              >
                Submit
              </Button>
            </Stack>
          </form>
        </Grid>
      </Grid>
    </MainLayout>
  )
}
