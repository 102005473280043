import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Grid, Typography } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import usePayments from './hooks/usePayments'
import useMagentoOrders from './hooks/useMagentoOrders'
import DATE_FORMAT from 'utils/constants/formats'
import moment from 'utils/moment'

import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelRoundedIcon from '@mui/icons-material/CancelRounded'
import DescriptionIcon from '@mui/icons-material/Description'
import { useSelector } from 'react-redux'
import formatMoney from 'views/new-purchase/utils/formatMoney'
import MagentoOrders from './MagentoOrders'
import LoadingPage from 'components/LoadingPage'
import { BILLING_TABS } from './tabs'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'

const useStyle = makeStyles({
  text: { color: '#696969' },
  table: {
    color: '#696969',
    fontSize: 14,
  },
  iconPaid: {
    color: '#008080',
  },
  iconUnpaid: {
    color: '#f50057',
  },
  status: {
    fontSize: 12,
  },
})

export default function BillingHistory() {
  const { charges, loading: loadingPayments } = usePayments()
  const { orders, loading: loadingMagentoOrders } = useMagentoOrders()
  const loading = loadingPayments || loadingMagentoOrders
  const classes = useStyle()
  const {
    hasSspProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)

  const {
    selectedUser: { hasCompletedFocusCertification, hasCompletedSspCertification },
  } = useGetUserWithoutRefresh()

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
    })
  )

  return (
    <LoadingPage loading={loading} text="loading billing history...">
      <MainLayout title="My Billing" tabs={tabs}>
        <div className="p-5">
          <Typography variant="h5" className="pb-2">
            Billing history
          </Typography>
          <table className={classes.table}>
            {charges.map((item) => (
              <tr>
                <td className="p-2">{moment(item.created).format(DATE_FORMAT.date)}</td>
                <td className="p-2">{formatMoney(item.amount / 100)}</td>
                <td className="p-2">{item.productName}</td>
                <td className="p-2">
                  <Grid container alignItems="center" wrap="nowrap">
                    <div>
                      {item.paid && <CheckCircleIcon className={classes.iconPaid} />}
                      {!item.paid && <CancelRoundedIcon className={classes.iconUnpaid} />}
                    </div>
                    <div className="ml-1">{item.status}</div>
                  </Grid>
                </td>
                <td className="p-2">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={item.invoice.hostedInvoiceUrl}
                    className={classes.text}
                  >
                    <DescriptionIcon />
                  </a>
                </td>
              </tr>
            ))}
          </table>
          <Typography variant="h5" className="pb-2">
            iLs Store Orders
          </Typography>
          {!loading && (
            <Typography variant="h5" className="pb-2">
              <MagentoOrders orders={orders} />
            </Typography>
          )}
        </div>
      </MainLayout>
    </LoadingPage>
  )
}
