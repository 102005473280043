import React, { useState } from 'react'
import { get, isEmpty, xor } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import ROLES from 'utils/constants/roles'
import deepTrim from 'utils/deepTrim'
import { useSnackbar } from 'notistack'
import moment from 'utils/moment'
import { useStateWatch } from 'hooks'

import { setEdit, setForm, selectUser, setData } from 'store/modules/providers'

import formats from 'utils/constants/formats'
import { useQuery } from 'utils/apollo'
import { gql } from '@apollo/client'

import { Button, Grid, UnyteTable } from 'components'
import { set } from 'utils'

import { UsersSearch } from '../components/Search'
import Avatar from '../components/Avatar'
import SortLabel from '../components/table/SortLabel'
import NameCol from '../components/table/NameCol'
import CreateInvitation from '../components/modals/CreateInvitation'
import UserDetails from './ProviderDetails'
import AddIcon from '@mui/icons-material/Add'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const USER_QUERY = gql`
  query getUsers($filter: FilterUsersInput, $sort: [[String!]], $offset: Int) {
    getUsers(filter: $filter, sort: $sort, offset: $offset) {
      address1
      address2
      city
      clientsCount
      country
      createdAt
      dob
      email
      firstName
      id
      isArchived
      isSuspended
      lastLoginAt
      lastName
      phone
      professionalCredentials
      state
      roles
      zip
    }
  }
`

export default () => {
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()
  const userRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const selectedUser = useSelector((state) => state.providers.selectedUser)
  const reduxData = useSelector((state) => state.providers.data)
  const [queryVars, setQueryVars] = useState({
    sort: [],
    filter: {
      anyRoles: [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS],
      any: '',
      isArchived: false,
    },
  })
  const { data, fetchMore, refetch, loading } = useQuery(USER_QUERY, {
    variables: deepTrim(queryVars),
  })
  const [users] = useStateWatch(() => get(data, 'getUsers', []), [data])

  const [pending, setPending] = useStateWatch(() => loading, [loading])
  const [roles] = useStateWatch(
    () =>
      userRoles.includes(ROLES.ADMIN_NO_CLIENTS)
        ? [ROLES.PROVIDER, ROLES.BILLING]
        : [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS, ROLES.BILLING],
    [userRoles]
  )

  const [openPrompt, setOpenPrompt] = useState(false)
  const handleClickOpen = () => {
    setOpenPrompt(true)
  }

  return (
    <div className="px-5 pb-5">
      <Grid container alignItems="center" className="pt-1 pb-1">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<AddIcon />}
          data-test="invite-provider-button"
        >
          Invite Provider
        </Button>
        <CreateInvitation
          roles={roles}
          text="Invite provider"
          showModal={openPrompt}
          setModalState={setOpenPrompt}
        />
      </Grid>
      <Grid container alignItems="center" className="pt-5 pb-1 pl-4">
        <UsersSearch
          onSearchChange={(val) => {
            setQueryVars(set(queryVars, 'filter.any', val))
          }}
          onSwitchChange={(val) => {
            setQueryVars(set(queryVars, 'filter.isArchived', val))
          }}
        />
      </Grid>
      <UnyteTable
        data={users}
        loading={pending}
        onLoadMore={() => {
          if (!isEmpty(xor(reduxData, users))) {
            setPending(true)
            setData(users)
            fetchMore({
              variables: { offset: users.length },
              updateQuery: (previousResult, { fetchMoreResult }) => {
                setPending(false)
                return {
                  getUsers: previousResult.getUsers.concat(fetchMoreResult.getUsers),
                }
              },
            })
          }
        }}
        detailsComponent={({ data: selectedUser }) => (
          <UserDetails
            user={selectedUser}
            onUpdate={(cache) => {
              cache.reset()
              refetch()
              enqueueSnackbar('Update successful', {
                variant: 'success',
                action: CloseSnackbarAction,
              })
            }}
          />
        )}
        sort={queryVars.sort}
        onSort={({ sort }) => setQueryVars((old) => ({ ...old, sort }))}
        selectedRow={selectedUser}
        onSelectRow={(index) => {
          dispatch(selectUser(index))
          dispatch(setEdit(false))
          dispatch(setForm(null))
        }}
        columns={[
          {
            width: '35%',
            header: ({ onSort, sort }) => (
              <>
                {' '}
                <NameCol onSort={onSort} sort={sort} />{' '}
              </>
            ),
            body: ({ data }) => <Avatar {...data} />,
          },
          {
            width: '15%',
            header: ({ onSort, sort }) => (
              <SortLabel sort={sort} title="Clients" sortKey="clientsCount" onChange={onSort} />
            ),
            body: ({ data }) => data.clientsCount,
          },
          {
            width: '25%',
            header: ({ onSort, sort }) => (
              <SortLabel
                sort={sort}
                title="Last signed in"
                sortKey="lastLoginAt"
                onChange={onSort}
              />
            ),
            body: ({ data }) =>
              data.lastLoginAt ? moment(data.lastLoginAt).format(formats.dateAndTime) : null,
          },
          {
            width: '25%',
            header: ({ onSort, sort }) => (
              <SortLabel sort={sort} title="Created" sortKey="createdAt" onChange={onSort} />
            ),
            body: ({ data }) => moment(data.createdAt).format(formats.dateAndTime),
          },
        ]}
      />
    </div>
  )
}
