import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { includesSome } from 'utils/includes'
import { get } from 'lodash'
import { Button, Typography } from '@mui/material'

import ROLES from 'utils/constants/roles'
import ConfirmPopup from './confirmPopup'
import { useOutletContext } from 'react-router'

export default ({ user, updateUser }) => {
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const currentUserId = useSelector((state) => get(state, 'auth.user.id'))
  const showNewSubscriptionPlan = useSelector((state) =>
    get(state, 'ff.showNewSubscriptionPlan', false)
  )
  const isRemoteUser = !!user.email
  const { refetchOne = () => {}, setRefetchCounter = () => {} } = useOutletContext()

  const [open, setOpen] = useState(false)

  const userIsProviderWithClients =
    includesSome(user.roles, [ROLES.PROVIDER, ROLES.PROVIDER_ALL_CLIENTS]) && user.clientsCount > 0

  const enabled =
    !currentUserRoles.includes(ROLES.ADMIN_NO_CLIENTS) &&
    user.id !== currentUserId &&
    !userIsProviderWithClients

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onArchive = async () => {
    // archive user
    await updateUser({
      variables: { user: { id: user.id, isArchived: !user.isArchived } },
    })
    await refetchOne({ filter: { ids: [user.id] } })

    await setRefetchCounter((prev) => prev + 1)
    await handleClose()
  }

  // Ensure all hooks are called before any conditional return
  if (!enabled) {
    return null
  }

  return (
    <>
      <Button
        data-test="archive-client-button"
        onClick={user.roles?.includes('client') && !user.isArchived ? handleClickOpen : onArchive}
        color="primary"
      >
        {user.isArchived ? 'Unarchive' : 'Archive'}
      </Button>
      <ConfirmPopup
        title="Archive this client?"
        body={
          <>
            <Typography variant="body2" gutterBottom>
              This will archive the Client and will hide them from the list of Clients on MyUnyte
              and in the App. To see archived Clients, you can use the “Archived” filter on the
              Client Filters. You can restore visibility by unarchiving the Client.
            </Typography>
            {isRemoteUser && (
              <Typography variant="body2" gutterBottom py={1}>
                Client won’t lose access to MyUnyte and Programs. If you need to disable access to
                MyUnyte and remove access to the Programs, please “Suspend Client” or manage access
                to the Programs through “Manage {!showNewSubscriptionPlan && 'Remote '}Delivery”
                feature.
              </Typography>
            )}
          </>
        }
        buttonText="Yes, Archive"
        open={open}
        onClose={handleClose}
        onConfirm={onArchive}
        user={user}
      />
    </>
  )
}
