const SET_ACTIVE_CLIENTS = 'ACTIVE_CLIENTS/SET_ACTIVE_CLIENTS'
const SET_DATA = 'ACTIVE_CLIENTS/SET_DATA'
const RESET_ACTIVE_CLIENTS = 'ACTIVE_CLIENTS/RESET_ACTIVE_CLIENTS'

const initialState = {
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_CLIENTS:
      return {
        ...state,
        ...action.payload,
      }
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case RESET_ACTIVE_CLIENTS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function setActiveClients(payload) {
  return {
    type: SET_ACTIVE_CLIENTS,
    payload,
  }
}

export function setActiveClientsData(payload) {
  return {
    type: SET_DATA,
    payload,
  }
}

export function resetActiveClients() {
  return {
    type: RESET_ACTIVE_CLIENTS,
  }
}
