import React from 'react'
import EditIcon from '@mui/icons-material/Edit'
import makeStyles from '@mui/styles/makeStyles'

const useStyles = makeStyles({
  edit: {
    fontSize: 18,
    marginBottom: -4,
    marginLeft: 5,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.54)',
    transition: '0.2s',
    '&:hover': {
      color: '#008080',
    },
  },
  editDisabled: {
    color: 'rgba(0, 0, 0, 0.1)',
    cursor: 'default',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.1)',
    },
  },
})

export default ({ disabled, onClick, ...props }) => {
  const styles = useStyles()
  return (
    <EditIcon
      {...props}
      className={`${styles.edit} ${disabled ? styles.editDisabled : ''}`}
      onClick={() => {
        if (disabled || !onClick) {
          return
        }
        onClick()
      }}
    />
  )
}
