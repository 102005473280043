import React from 'react'
import {
  Button,
  TextField,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
  Divider,
  CardActions,
  Stack,
  SelectChangeEvent,
  FormHelperText,
} from '@mui/material'
import { Controller, FieldValues, SubmitHandler, useFormContext } from 'react-hook-form'
import { PreviewCard } from '../components/preview-card'
import { getCountries } from 'utils/constants/getIsoRegion'
import { DemographicInfoValues } from '../(basic-info)/basic-info-constants'
import { isEmpty } from 'utils/isEmpty'

interface EditDemographicInfoProps {
  onSubmit: SubmitHandler<FieldValues | DemographicInfoValues>
  onReset: () => void
}
export const EditDemographicInfo: React.FC<EditDemographicInfoProps> = ({ onSubmit, onReset }) => {
  const { control, handleSubmit, watch, setValue, formState } = useFormContext()

  const allCountries = getCountries()
  const frequentlySelected = allCountries.slice(0, 2)
  const rest = allCountries.slice(2, -1)

  const handleSelect = (selectType: string) => (e: SelectChangeEvent<string>) => {
    setValue(selectType, e.target.value)
  }
  const handleReset = () => {
    setValue('viewState', 'PREVIEW')
    onReset()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
      <PreviewCard
        title="Demographic Info"
        tooltipTitle="Your client's information is fully protected. We follow strict privacy standards and never share client details. For more information, please review our privacy policy."
      >
        <CardContent>
          <Stack direction={{ sm: 'column', md: 'row' }} spacing={2}>
            <Controller
              name="age"
              control={control}
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    fullWidth
                    label={`Age in ${new Date().getFullYear()}`}
                    error={!!fieldState.error}
                    helperText={!!fieldState.error && 'Age must be between 1-100'}
                    onChange={(e) => {
                      const value = e.target.value === '' ? '' : Number(e.target.value) // Allow empty string as undefined
                      field.onChange(value)
                    }}
                    type="number"
                    value={field.value || ''} // Ensure controlled input
                  />
                )
              }}
            />
            <Controller
              name="gender"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl fullWidth variant="outlined" {...field} error={!!fieldState.error}>
                  <InputLabel id={`gender-label`}>Gender</InputLabel>
                  <Select label="Gender" value={watch('gender')} onChange={handleSelect('gender')}>
                    <MenuItem value={'Woman/Girl'}>Woman/Girl</MenuItem>
                    <MenuItem value={'Man/Boy'}>Man/Boy</MenuItem>
                    <MenuItem value={'Non-Binary/Non-Conforming'}>
                      Non-Binary/Non-Conforming
                    </MenuItem>
                    <MenuItem value={'Prefer not to respond'}>Prefer not to respond</MenuItem>
                  </Select>
                  {fieldState.error && (
                    <FormHelperText id="gender-helper-text">
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />

            <Controller
              name="country"
              control={control}
              render={({ field, fieldState }) => (
                <FormControl
                  data-test="select-country"
                  fullWidth
                  {...field}
                  error={!!fieldState.error}
                >
                  <InputLabel id="country-input-label">Country</InputLabel>
                  <Select
                    label="Country"
                    labelId="country-select-label"
                    id="country-select-label"
                    data-test="client-country-select"
                    value={watch('country')}
                    onChange={handleSelect('country')}
                  >
                    <ListSubheader disableSticky>Frequently selected</ListSubheader>
                    {frequentlySelected.map(({ code, name }) => (
                      <MenuItem data-test="select-country-item" value={name} key={code}>
                        {name}
                      </MenuItem>
                    ))}
                    <Divider />
                    {rest.map(({ code, name }) => (
                      <MenuItem value={name} key={code}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  {fieldState.error && (
                    <FormHelperText id="gender-helper-text">
                      {fieldState.error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Stack>
        </CardContent>
        <CardActions>
          <Button type="submit" disabled={!isEmpty(formState.errors)}>
            Save Demographic Info
          </Button>
          <Button type="reset">Cancel</Button>
        </CardActions>
      </PreviewCard>
    </form>
  )
}
