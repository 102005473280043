/**
 * This hook is used to get assessment ID for product preferences so we don't
 * have inconsistencies when migrating code
 *
 * we make assessmenProductId a string here because of how its being stored in database
 */
import { gql } from '@apollo/client'
import { get } from 'lodash'
import { useQuery } from 'utils/apollo'

const GET_PRODUCTS = gql`
  query GetAssessmentId {
    getProducts(filter: { category: "assessment" }) {
      id
      name
      isEnabled
    }
  }
`

const useGetAssessmentId = () => {
  const { data, loading } = useQuery(GET_PRODUCTS)
  const products = get(data, 'getProducts')
  const assessmentProductId = products?.find(({ name }) => name === 'Assessment')?.id

  return { assessmentProductId, loading }
}
export default useGetAssessmentId
