import React, { useState, useEffect } from 'react'
import { FormControl, InputLabel, Select, MenuItem, Grid, Typography } from '@mui/material'
import MyUnyteTimePicker from 'components/MyUnyteTimePicker'
import { differenceInYears, format } from 'date-fns'

function AgeDropdown({ label, formKey, errors, form, setFormData, setErrors, isClientDetails }) {
  const [dob, setDOB] = useState()
  const [age, setAge] = useState('')
  const [disableStates, setDisableStates] = useState({
    dob: false,
    age: false,
  })

  const onDOBChange = (event) => {
    const dobYear = new Date(event).getFullYear()
    const difference = differenceInYears(new Date(), new Date(event))

    if (!Number.isNaN(difference)) {
      setAge(difference)
    }

    const formattedEvent = event ? !isNaN(event) && format(event, 'yyyy-MM-dd') : undefined
    setDOB(event)
    const newForm = { ...form, dob: formattedEvent, birthYear: event ? dobYear : undefined }
    // no error checks here because button will be disabled on invalid date..
    setFormData(newForm)

    if (!event) {
      setAge()
      setDOB()
      setDisableStates({ ...disableStates, age: false })
    } else {
      setDisableStates({ ...disableStates, age: true })
      setErrors({})
    }
  }

  const onAgeChange = (event) => {
    // get states
    const selectedAge = event.target.value
    const currentYear = new Date().getFullYear()
    const dobYear = currentYear - selectedAge

    // update form
    const newForm = { ...form, birthYear: dobYear }
    setAge(selectedAge)
    setFormData(newForm)
  }

  useEffect(() => {
    // if dob is already enterred, autofill age
    if (form['dob']) {
      const difference = differenceInYears(new Date(), new Date(form['dob']))
      setDOB(form['dob'])
      setAge(form['age'] || difference)
      setDisableStates({ ...disableStates, age: true })
    }
    // if year is enterred, only fill out year
    else if (form['birthYear']) {
      const difference = differenceInYears(new Date(), new Date(form['birthYear'], 1, 1))
      if (!Number.isNaN(difference)) {
        setAge(difference)
      }
    }
    // eslint-disable-next-line
  }, [])

  // clean up
  useEffect(() => {
    return () => {
      setAge()
      setDOB()
      setDisableStates({
        dob: false,
        age: false,
      })
    }
  }, [])

  return (
    <>
      <Grid item xs key="agedropdown-1">
        <MyUnyteTimePicker
          label={label}
          key={formKey}
          formKey={formKey}
          error={errors[formKey]}
          errors={errors}
          value={dob}
          onChange={onDOBChange}
          setErrors={setErrors}
          disabled={!!disableStates.dob}
        />
      </Grid>
      <Grid
        container
        item
        xs={1}
        justifyContent="center"
        alignItems="center"
        className="p-2 m-2"
        key="agedropdown-2"
      >
        <Typography align="center" className="w-full">
          OR
        </Typography>
      </Grid>
      <Grid item xs={4} key="agedropdown-3" my={isClientDetails ? 1 : 2}>
        <FormControl fullWidth disabled={!!dob}>
          <InputLabel id="select-age-input-label">{`Age in ${new Date().getFullYear()}`}</InputLabel>
          <Select
            data-test="client-age-select"
            value={age}
            defaultValue={age || undefined}
            onChange={onAgeChange}
            label={`Age in ${new Date().getFullYear()}`}
          >
            {Array.from({ length: 100 }).map((_, i) => (
              <MenuItem key={`age-dropdown-value-${i}`} value={i}>
                {i}
              </MenuItem>
            ))}
            <MenuItem value="">0</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </>
  )
}

export default AgeDropdown
