import React from 'react'
import { Chip, Tooltip } from '@mui/material'
import { Done } from '@mui/icons-material'

export const FilterChip = ({
  dataTag,
  dataTest,
  isChecked,
  label,
  handleOnClick,
  isDisabled,
  tooltipMessage,
}) => {
  const chipColor = isChecked ? '#EEF7F3' : 'white'
  const ChipWrapper = ({ children }) =>
    tooltipMessage ? (
      <Tooltip title={tooltipMessage} placement="top">
        {children}
      </Tooltip>
    ) : (
      <>{children}</>
    )
  return (
    <ChipWrapper>
      <Chip
        data-tag={dataTag}
        data-test={dataTest}
        avatar={isChecked ? <Done /> : null}
        label={label}
        onClick={handleOnClick}
        variant="outlined"
        sx={{ backgroundColor: chipColor, m: 0.5 }}
        disabled={isDisabled}
      />
    </ChipWrapper>
  )
}
