/**
 * We are storing cart data in both redux and localstorage:
 * - localstorage: so when user gets redirected from ils, we have their information saved
 * - redux: so when a client adds an item to the cart, the change is immediately reflected
 *   - we cannot use localstorage here because windows cannot listen to localstorage changes
 *
 * TODO (TEST):
 * - pressing back will redirect
 */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getShipstationQuotes,
  initiateOnboarding,
  extendData,
  addPaymentInfo,
  setStep,
  getDearProducts,
} from 'store/modules/new-purchase'
import { logout } from 'store/modules/auth'
import { PageLoader } from 'components'
import useGetSteps from './utils/useGetSteps'
import { ALL_VALID_SKUS, ALL_CERTIFICATION_SKUS, INVALID_SKUS_COMBO } from 'utils/constants/prices'
import LINKS from 'utils/constants/links'
import { useNavigationType } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import getCookie from './utils/getCookie'

export default () => {
  const dispatch = useDispatch()
  const navigateType = useNavigationType()
  const store = useSelector((state) => state.newPurchase)
  const [canRender, setCanRender] = useState(false)
  const { address1, address2, toPostalCode, toState, toCountry, toCity } = store.data

  useEffect(() => {
    dispatch(logout()).then(() => setCanRender(true))
    // eslint-disable-next-line
  }, [])

  /**
   * GET PARAMS:
   * - skus : array of skus
   * - redirectUrl: string
   *
   * we are watching for if the search params have changed
   */
  const [searchParams] = useSearchParams()
  const skus = searchParams.get('skus')
  const _redirectUrl = searchParams.get('redirectUrl')

  const onboardingProducts = skus ? skus.split(',') : []
  useEffect(() => {
    const checkSkus = () => {
      const valid_skus = onboardingProducts.every((sku) => ALL_VALID_SKUS.includes(sku))

      // check if promo is in onboardingProducts, then if there exists an invalid combo
      const invalid_promo_combo = onboardingProducts.some((sku) =>
        INVALID_SKUS_COMBO[sku]?.some((key) => onboardingProducts.includes(key))
      )

      return valid_skus && !invalid_promo_combo
    }

    const allowedUrls = ['https://integratedlistening.com', 'https://my.unyte.com']
    const isRedirectUrlValid = () =>
      allowedUrls.some((allowedUrl) => _redirectUrl?.startsWith(allowedUrl))

    // when provided with &amp; in url, LINKS break
    const redirectUrl = isRedirectUrlValid()
      ? _redirectUrl || window.location.href
      : LINKS.newPurchase?.redirectUrl

    const hasOnlyCertification = onboardingProducts.every((sku) =>
      ALL_CERTIFICATION_SKUS.includes(sku)
    )

    const utmParameters = Object.entries(searchParams)
      .filter((key, _) => key.includes('utm_'))
      .reduce(([key, value], obj) => ({ ...obj, [key]: value }), {})

    const hsCookieName = 'hubspotutk='
    const hubspotUserToken = getCookie(hsCookieName)

    const newPurchase = {
      redirectUrl,
      onboardingProducts,
      selectedAddOns: {},
      selectedServiceCode: '',
      address1: hasOnlyCertification ? '' : address1,
      address2: hasOnlyCertification ? '' : address2,
      toPostalCode: hasOnlyCertification ? '' : toPostalCode,
      toState: hasOnlyCertification ? '' : toState,
      toCountry: hasOnlyCertification ? '' : toCountry,
      toCity: hasOnlyCertification ? '' : toCity,
      BIData: {
        utm_parameters: {
          ...utmParameters,
        },
        hubspotUserToken,
      },
    }
    dispatch(extendData({ ...newPurchase }))

    // if skus & url are not valid, redirect
    if (!checkSkus()) {
      // check if redirectUrl is valid
      if (isRedirectUrlValid()) {
        window.location.replace(redirectUrl)
      } else {
        window.location.replace(LINKS.newPurchase.redirectUrl)
      }
    } else if (!skus) {
      // user should not be able to select bundles
      dispatch(setStep(steps.length - 1))
    }
    // eslint-disable-next-line
  }, [skus])

  // on load add to GA4

  const steps = useGetSteps()
    .filter(({ isActive }) => Boolean(isActive))
    .map(({ component }) => component)
  const Component = steps[store.step]

  // pressing back on success will refresh page
  useEffect(() => {
    if (navigateType === 'POP' && store.step !== 0) {
      window.location.reload()
    }
    // eslint-disable-next-line
  }, [navigateType])

  // on load, ensure that hubspot widget is not shown
  useEffect(() => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.remove()
    }
  }, [])

  return (
    <>
      {!canRender && <PageLoader />}
      {canRender && (
        <Component
          store={store}
          step={store.step}
          extendData={(...args) => dispatch(extendData(...args))}
          initiateOnboarding={(...args) => dispatch(initiateOnboarding(...args))}
          addPaymentInfo={(...args) => dispatch(addPaymentInfo(...args))}
          getShipstationQuotes={(...args) => dispatch(getShipstationQuotes(...args))}
          getDearProducts={(...args) => dispatch(getDearProducts(...args))}
          next={(step) => {
            dispatch(setStep(Number.isInteger(step) ? step : store.step + 1))
          }}
        />
      )}
    </>
  )
}
