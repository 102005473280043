import { Box, keyframes, styled } from '@mui/material'
import React from 'react'
import LoadingPage from './LoadingPage'

const zoom = keyframes`
  from {
    transform: scale(0.5)
  }
  to {
    transform: scale(1)
  }
`

export default function PageLoader({ children }) {
  return (
    <LoadingPage text="loading..." loading>
      {children}
    </LoadingPage>
  )
}

const LeafLogo = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    width="100"
    height="100"
    viewBox="0 0 250.97 208.69"
  >
    <defs xmlns="http://www.w3.org/2000/svg"></defs>
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#008080"
      d="M135.91,208.69A126.46,126.46,0,0,0,251,98.24,126.46,126.46,0,0,0,135.91,208.69Z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#008080"
      d="M94.67,82.68a125.94,125.94,0,0,0,30.82,82.68A126.33,126.33,0,0,0,125.49,0,125.94,125.94,0,0,0,94.67,82.68Z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#008080"
      d="M115.06,208.69A126.46,126.46,0,0,0,0,98.24,126.46,126.46,0,0,0,115.06,208.69Z"
    />
  </svg>
)

export const AnimatedLeafLogo = () => (
  <Box
    sx={{
      animation: `${zoom} 2.5s cubic-bezier(0, 0.01, 0.51, 0.97) 0s infinite alternate`,
      width: '100px',
      transformOrigin: 'bottom center',
    }}
  >
    <LeafLogo />
  </Box>
)

const growWidth = keyframes`
  from {
    transform: scale(0.5)
  }
  to {
    transform: scale(1)
  }
`
const AnimatedSvg = styled('svg')({
  animation: `${growWidth} 2.5s cubic-bezier(0, 0.01, 0.51, 0.97) 0s infinite alternate`,
  transformOrigin: 'center',
})

export const AnimatedLeafLogoStartIcon = ({ fill = '#fff' }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '25px',
      height: '25px',
    }}
  >
    <AnimatedSvg
      xmlns="http://www.w3.org/2000/svg"
      width="25px" // This will be the final width after animation
      height="25px"
      viewBox="0 0 250.97 208.69"
    >
      <path
        fill={fill}
        d="M135.91,208.69A126.46,126.46,0,0,0,251,98.24,126.46,126.46,0,0,0,135.91,208.69Z"
      />
      <path
        fill={fill}
        d="M94.67,82.68a125.94,125.94,0,0,0,30.82,82.68A126.33,126.33,0,0,0,125.49,0,125.94,125.94,0,0,0,94.67,82.68Z"
      />
      <path
        fill={fill}
        d="M115.06,208.69A126.46,126.46,0,0,0,0,98.24,126.46,126.46,0,0,0,115.06,208.69Z"
      />
    </AnimatedSvg>
  </Box>
)
