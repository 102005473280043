import { matchPath } from 'react-router'

export const SENT_TO_PROVIDER = 'Sent to Provider'
export const ACCEPTED = 'Accepted' // Provider accepted the submission to get in contact with client, still can return the connectee submission
export const ENGAGED = 'Engaged' // Provider got in contact with connectee
export const CONNECTED = 'Connected' // Connectee is now a client and has a provider
export const DECLINED = 'Declined' // Provider is not interested in connectee
export const EXPIRED = 'Expired' // When provider didn't accept their connection in specified time.

export const STATUSES_FOR_HISTORY = [CONNECTED, DECLINED, EXPIRED]
export const STATUSES_IN_PROGRESS = [SENT_TO_PROVIDER, ACCEPTED, ENGAGED]

export const CONNECTIONS_TABS = [
  {
    text: 'Active Connections',
    url: '/connections/active',
    isActive: (pathname) => matchPath({ path: '/connections/active' }, pathname),
  },
  {
    text: 'History',
    url: '/connections/history',
    isActive: (pathname) => matchPath({ path: '/connections/history' }, pathname),
  },
]
