import React from 'react'
import { Button } from 'components'

const ADDRESSES = {
  Canada: {
    address1: '44 Lilian St',
    city: 'Toronto',
    state: 'ON',
    country: 'CA',
    postalCode: 'M4S0B7',
  },
  Colorado: {
    address1: '1 Main Street',
    city: 'Denver',
    state: 'CO',
    country: 'US',
    postalCode: '80206',
  },
  Massachussets: {
    address1: '1 Main Street',
    city: 'Cambridge',
    state: 'MA',
    country: 'US',
    postalCode: '02142',
  },
}

export default function({ setFormValue, addressType, clearShipping = () => {} }) {
  if (process.env.REACT_APP_STAGE !== 'test') {
    return null
  }
  const address = ADDRESSES[addressType]
  const fillOutForm = () => {
    const overrideValues = {
      address1: address.address1,
      toCity: address.city,
      toState: address.state,
      toCountry: address.country,
      toPostalCode: address.postalCode,
    }
    setFormValue('', 'target.value', overrideValues)()
    clearShipping()
  }
  return (
    <Button type="button" variant="contained" color="primary" onClick={fillOutForm}>
      Fill in address for {addressType}
    </Button>
  )
}
