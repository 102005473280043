// NOTE: If on the spreadsheet any of these fields are not available, set the field to 'N/A'
/* Activities Fields (Keys) Documentation:
 {
    activityName: ils activity name,
    activityVideoSrc: source of the ils activity video,
    category: ils activity category,
    steps: [ARRAY], steps of the ils activity
    easierOption: [ARRAY] modification to make the ils activity easier,
    harderOption: [ARRAY] modification to make the ils activity harder,
    cognitiveChallenge: [ARRAY] cognitive challenge for the ils activity,
  }
*/

export default [
  {
    activityName: 'Even Breathing',
    activityVideoSrc: '//player.vimeo.com/video/700946996',
    category: 'Regulation',
    steps: [
      'Stand comfortably with head upright, eyes open, feet planted in one spot on the floor, and shoulders back and relaxed.',
      'Inhale as you count to three, quietly in your head.',
      'Exhale at the same rate. Measure inhales with right hand on your thigh: tap, tap, tap.',
      'Measure exhales with left hand on your thigh: tap, tap, tap.',
      'Make sure you inhale and exhale at the same rate. ',
    ],
    easierOption: [
      '1. Sit upright and supported in a chair with both feet flat on the floor OR, lie still on a flat solid surface as you breathe in and out.',
    ],
    harderOption: [
      '1. Move feet close together so sides of feet are touching.',
      '2. Stand on balance board while completing this activity.',
      '3. Closing eyes in any position can make this task more challenging.',
    ],
    cognitiveChallenge: [
      '1. Count forward as you inhale.',
      '2. Count backward as you exhale.',
      '3. Keep track of how many sets of inhale-exhale you complete up to a count of 10 and start over.',
      '4. Repeat a sequence of something dictated to you before you begin & repeat the sequence backwards on the exhale I.e. red, blue, green...exhale green blue red.',
    ],
  },
  {
    activityName: 'Even Breathing on Balance Board',
    activityVideoSrc: '//player.vimeo.com/video/700947358',
    category: 'Regulation, Gross Motor',
    steps: [
      'Stand comfortably with head upright, eyes open, feet planted in one spot on the balance board with shoulders back and relaxed. Make sure the sides of the balance board do not touch the ground and attempt to not move your feet or fall off the board.',
      'Inhale as you count to three, quietly in your head.',
      'Exhale at the same rate. Measure inhales with right hand on your thigh: tap, tap, tap.',
      'Measure exhales with left hand on your thigh: tap, tap, tap.',
      'Make sure you inhale and exhale at the same rate. ',
    ],
    easierOption: [
      '1. Place the balance board on top of a pillow or use a towel wrapped around it for some stability.',
      '2. Hold the edge of a chair or touch a wall with your fingertip while balancing.',
      '3. Remove the balance board.',
    ],
    harderOption: ['1. Close eyes.', '2. Stand on one foot.'],
    cognitiveChallenge: [
      '1. Count forward as you inhale.',
      '2. Count backward as you exhale.',
      '3. Keep track of how many sets of inhale-exhale you complete up to a count of 10 and start over.',
      '4. Repeat a sequence of something dictated to you before you begin & repeat the sequence backwards on the exhale I.e. red, blue, green...exhale green blue red.',
    ],
  },
  {
    activityName: 'Helicopter Warm-up',
    activityVideoSrc: '//player.vimeo.com/video/700947944',
    category: 'Regulation, Gross Motor',
    steps: [
      'Stand with feet wide apart, knees slightly bent, arms straight out to the sides, palms facing forward.',
      'Rotate from the hip to the right then to the left; back and forth, gradually increasing speed of rotation.',
      'Keep the head in line with the trunk locating a visual spot on each respective wall when turning to each side.',
    ],
    easierOption: ['1. Sit on a stool or edge of a bed/sofa with feet planted on the floor.'],
    harderOption: [
      '1. Sit on an exercise ball with feet planted.',
      '2. Lift one foot and hold it lifted while you rotate.',
    ],
    cognitiveChallenge: [
      '1. Count up from one to 10 and then count backwards, with a new number being stated each time you turn to the right.',
      '2. Alternate numbers and letters so that you count up from one when you turn to the left and state the alphabet starting with a each time you turn to the right.',
    ],
  },
  {
    activityName: 'Standing Cross-crawl',
    activityVideoSrc: '//player.vimeo.com/video/700947559',
    category: 'Gross Motor',
    steps: [
      'Stand relaxed, hands on hips. Kick right leg to the left crossing mid-line. Do the same with left leg.',
      'Gradually increase the height of the kick, bringing foot higher each session.',
      'Keep an even rhythm.',
    ],
    easierOption: [
      "1. Make it fun by adding a target to kick toward (a favorite character, stuffed animal, or a spots of varying heights marked with an x made from painter's tape on the wall).",
      '2. Sit in a chair and hold the edge of the chair seat when lifting each leg.',
    ],
    harderOption: [
      "1. Hold hands up beside the ears and tap the opposite hand to each leg when completing the leg lift; keep the hand that's not tapping lifted by the ear.",
      '2. Sit on an exercise ball to complete.',
      '3. Go slowly which requires more strength than momentum.',
    ],
    cognitiveChallenge: [
      '1. Count up from one to 10 and then count backwards, with a new number being stated each time you turn to the right.',
      '2. Alternate numbers and letters so that you count up from one when you turn to the left and state the alphabet starting with a each time you turn to the right.',
    ],
  },
  {
    activityName: 'Crazy Count',
    activityVideoSrc: '//player.vimeo.com/video/700947608',
    category: 'Fine Motor',
    steps: [
      'Open right hand, palm up.',
      'Starting with right hand, touch thumb and index finger, then thumb and middle finger, continuing down to the pinky.',
      'Then reverse the order.',
      'Repeat 3 times. Repeat 3 times with eyes closed.',
      'Repeat with opposite hand 3x, then again with eyes closed 3x.',
      'Repeat with both hands 3x. Repeat with eyes closed 3x.',
    ],
    easierOption: [
      '1. Use fewer fingers ex. touch thumb to index finger and 2nd finger only and then repeat.',
      '2. Do one hand at a time switching between left and right.',
      '3. Put stickers on thumb and fingers.',
      '4. Slow down.',
    ],
    harderOption: [
      '1. Increase speed but attempt to maintain rythmicity and accuracy.',
      '2. Close your eyes and do it.',
      '3. Hold hands up beside the ears so hands are out of sight while completing the finger patterns.',
      '4. Stand on balance board and balance while doing this activity.',
    ],
    cognitiveChallenge: [
      '1. Count each finger out loud with counting in synch with the tap of each finger.',
      '2. Count forward and backward (as tapping order is reversed).',
      '3. Spell words as fingers are tapped.',
    ],
  },
  {
    activityName: 'Drop & Catch',
    activityVideoSrc: '//player.vimeo.com/video/700947198',
    category: 'Gross Motor',
    steps: [
      'Hold bean bag in one hand, stand upright with good posture.',
      'Hold at eye level and drop into other hand.',
      'Reverse hands and repeat.',
      'As child becomes more confident, increase the distance of the drop',
      'Remember to maintain good posture.',
      'Count 10 drops, take a 15-second break and repeat.',
    ],
    easierOption: [
      '1. Decrease dropping distance.',
      '2. Use an object bigger than a bean-bag, such as a stuffed animal or pair of socks.',
      '3. Sit down in a chair.',
    ],
    harderOption: [
      '1. Increase dropping distance.',
      '2. Try tossing and catching instead of dropping.',
      '3. Add balance board.',
      '4. Close your eyes.',
    ],
    cognitiveChallenge: [
      '1. Count each drop.',
      '2. Alternate counting and saying a letter of the alphabet.',
      '3. Keep the rhythm of the dropping in synch with a nursery rhyme.',
      '4. Say a pair of words that rhyme each time you drop and catch.',
    ],
  },
  {
    activityName: 'Toss & Catch w/ Partner',
    activityVideoSrc: '//player.vimeo.com/video/700947238',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Toss bean bag with partner.',
      'Start tossing straight, then diagonally.',
      'Complete 20 repetitions.',
    ],
    easierOption: [
      '1. Use an object bigger than a bean bag like a pillow or stuffed animal.',
      '2. Use both hands at midline.',
      '3. Stand closer together with your partner.',
    ],
    harderOption: [
      '1. Use a small ball instead of a bean bag.',
      '2. Stand further away from your partner.',
      '3. Add balance board.',
    ],
    cognitiveChallenge: [
      '1. Your partner calls out a letter and you say a word that starts with that letter.',
      '2. Your partner picks a word and you spell it by saying a letter each time someone catches.',
      '3. See if you can make it through the entire alphabet without dropping it.',
    ],
  },
  {
    activityName: 'Wall/Floor Bounce',
    activityVideoSrc: '//player.vimeo.com/video/700947272',
    category: 'Gross Motor, Visual Motor',
    steps: ['Toss ball against wall so it bounces on flow and returns.', 'Repeat 20-40 times.'],
    easierOption: [
      '1. Sit in a chair instead of standing.',
      '2. Use a bigger ball.',
      '3. Add a visual target on the wall to aim for.',
      '4. Roll the ball.',
    ],
    harderOption: [
      '1. Stand on one foot and balance while tossing.',
      '2. Sit on an exercise ball.',
      '3. Add the balance board.',
    ],
    cognitiveChallenge: [
      '1. Count up as high as you can go until you miss the catch and then start over trying to beat your score.',
      '2. Add a nursery rhyme and try to keep your rhythm in synch with the rhyme.',
    ],
  },
  {
    activityName: 'Cross Kick Warm-up',
    activityVideoSrc: '//player.vimeo.com/video/700947065',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Stand relaxed, hands on hips.',
      'Kick right leg to the left crossing mid-line.',
      'Do the same with right leg.',
      'Gradually increase the height of the kick, bringing foot higher each session.',
      'Keep an even rhythm and make it fun.',
    ],
    easierOption: [
      '1. Go slower.',
      '2. Sit instead of standing.',
      '3. Do only one side x 5, followed by the other x 5 instead of alternating.',
    ],
    harderOption: [
      '1. Add tapping your toe with your hand instead of doing legs only.',
      '2. Try to do it without use of momentum, keeping spine straight vs bending in the trunk.',
      '3. Close your eyes.',
    ],
    cognitiveChallenge: ['1. Count.', '2. Alphabet.', '3. Alternate counting and alphabet.'],
  },
  {
    activityName: 'Balance Board Standing',
    activityVideoSrc: '//player.vimeo.com/video/700947101',
    category: 'Gross Motor, Early Development',
    steps: [
      'Place folder towel or blanket under Balance Board for stability.',
      "Child stands on board, with adult facing child, and reaches across body to hold adult's hand (e.g. child's left hand and adult's left hand).",
      'As child becomes more comfortable, they switch hands, letting go for a second to grab the other hand.',
      'Switch hands every 10-15 seconds.',
    ],
    easierOption: [
      "1. Hold onto the edge of a chair or a partner's hand for balance.",
      '2. Put the balance board on a pillow or blanket for greater stability.',
      '3. Allow an edge to touch then switch to the opposite edge.',
      '4. Sit on the Balance Board vs. Stand.',
    ],
    harderOption: [
      "1. Let go of your partner's hand for longer periods of time.",
      '2. Use without a blanket and with both feet on center portion of the board (no edges touching the floor).',
      '3. Move your eyes from one visual target to another and back without losing balance.',
    ],
    cognitiveChallenge: ['1. Count.', '2. Alphabet.', '3. Alternate counting and alphabet.'],
  },
  {
    activityName: 'Scissor Legs',
    activityVideoSrc: '//player.vimeo.com/video/700947965',
    category: 'Gross Motor, Early Development',
    steps: [
      'Make a line on the floor, and place on floor on either side of the line.',
      'Jump and move feet to the opposite side of the line.',
      'Repeat 10x, catch breath and repeat.',
      'Start with 2 sets and gradually increase as strength builds.',
    ],
    easierOption: [
      '1. Step over the line with one foot at a time instead of jumping.',
      '2. Sit in a chair vs. standing.',
      "3. Hold a partner's hand while you jump.",
    ],
    harderOption: [
      '1. Increase sets of jumping as strength and coordination improve.',
      '2. Increase speed of jumping.',
      '3. Add a reverse lunge when you land.',
    ],
    cognitiveChallenge: ['1. Count.', '2. Alphabet.', '3. Alternate counting and alphabet.'],
  },
  {
    activityName: 'Sunrise Stretch',
    activityVideoSrc: '//player.vimeo.com/video/700947173',
    category: 'Regulation, Gross Motor, Early Development',
    steps: [
      'Inhale as you lift both arms up, reaching to the ceiling.',
      'Exhale as you bend down to touch your toes.',
      'Repeat 5-10 times, taking slow, big breaths and letting them out with a gusto.',
      'Increase number of sets with each session and try to get closer to the toes each time.',
    ],
    easierOption: [
      '1. Sit on a chair or on the floor.',
      '2. Sit on an exercise ball pressed up against a wall.',
      '3. Move feet just over hip width apart.',
    ],
    harderOption: [
      '1. Close your eyes.',
      '2. Look at your hands as you move them above your head.',
      '3. Try to synch your breath with your movement I.e. inhale as hands lift up, exhale as hands lower, repeat according to the rhythm of your breath.',
    ],
    cognitiveChallenge: [
      '1. Coordinate movement and breath as you say a chosen positive word looking up and a different chosen word moving/looking down ex. "happy day" or "I\'m great!".',
    ],
  },
  {
    activityName: 'Arm Leg Lift',
    activityVideoSrc: '//player.vimeo.com/video/700947141',
    category: 'Gross Motor, Early Development',
    steps: [
      'Lay down on back, arms fully extended above head on floor.',
      'Lift left leg and raise right arm.',
      'Touch hand as high on leg as possible then lower both limbs.',
      'Repeat opposite side.',
      'Do 10 times, rest and repeat (2 sets total).',
      'Increase the number of sets gradually, as increased stamina allows.',
    ],
    easierOption: [
      '1. Keep knees bent and toe tap on the floor instead of straight leg raise.',
      '2. Lift arm and leg into 90/90 vs. tapping arm on knee.',
    ],
    harderOption: ['1. Increase number of sets as strength improves.', '2. Increase speed.'],
    cognitiveChallenge: [
      '1. Count.',
      '2. Count backwards from a chosen number.',
      '3. Alternate counting and alphabet.',
      '4. Spell words by stating one letter each time you alternate sides.',
    ],
  },
  {
    activityName: 'Jumping Jacks',
    activityVideoSrc: '//player.vimeo.com/video/700947781',
    category: 'Regulation, Gross Motor, Early Development',
    steps: [
      'Stand with good posture and legs together.',
      'As you jump, legs go out and hands go up to touch above head.',
      'Count out loud. 10-20x, increase 10% each time.',
    ],
    easierOption: [
      '1. Step out with feet vs. jump and keep hands on hips.',
      '2. Add hands as coordination improves.',
      '3. Go slowly.',
    ],
    harderOption: [
      '1. Speed up.',
      '2. Increase number of rep/sets as strength improves.',
      '3. Clap hands above your head instead of just raising arms.',
    ],
    cognitiveChallenge: [
      '1. Count one number each time you jump.',
      '2. Say the alphabet one letter each time you jump.',
      '3. Alternate counting and alphabet.',
      '4. Do either or both backwards.',
    ],
  },
  {
    activityName: 'Floor Bounce & Catch',
    activityVideoSrc: '//player.vimeo.com/video/700947254',
    category: 'Gross Motor, Visual Motor',
    steps: ['Bounce ball on floor from left to right, right to left.'],
    easierOption: [
      '1. Catch with both hands vs. one or the other.',
      '2. Use a bigger ball.',
      '3. Sit in a chair instead of standing.',
    ],
    harderOption: [
      '1. Stand further away from your partner.',
      '2. Toss with one hand and catch with the other.',
      '3. Add the balance board.',
    ],
    cognitiveChallenge: [
      '1. Count one number each time you jump.',
      '2. Say the alphabet one letter each time you jump.',
      '3. Alternate counting and alphabet.',
      '4. Do either or both backwards.',
    ],
  },
  {
    activityName: 'Parallel Skiing',
    activityVideoSrc: '//player.vimeo.com/video/700947639',
    category: 'Gross Motor, Early Development',
    steps: [
      'Make a line on floor using fabric or marker.',
      'Stand with feet parallel to line and jump over line 10 times.',
    ],
    easierOption: [
      '1. Sit in a chair and move legs only.',
      '2. Step over the line one foot at a time instead of jumping.',
      '3. Hold hands with a partner who moves with you.',
      '4. Go slowly.',
    ],
    harderOption: [
      '1. Jump faster.',
      '2. Add a small object to jump over vs. just a flat line on the floor.',
      '3. Jump using one foot x 5 reps then switch feet x 5.',
    ],
    cognitiveChallenge: [
      '1. Count one number each time you jump.',
      '2. Say the alphabet one letter each time you jump.',
      '3. Alternate counting and alphabet.',
      '4. Do either or both backwards.',
    ],
  },
  {
    activityName: 'Under & Twirl',
    activityVideoSrc: '//player.vimeo.com/video/700947320',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Stand with back to wall, 5-10 feet from wall.',
      'Bounce ball under and between legs so it hits the ground and bounces off wall.',
      'Twirl around and catch.',
      'Repeat 10 times.',
    ],
    easierOption: [
      '1. Remove the under and only do twirl.',
      '2. Stick to turning toward only one side at a time for 3 reps then switch to the other side.',
      '3. Go slowly.',
    ],
    harderOption: [
      '1. Increase number of repetitions as coordination and strength improve.',
      '2. Increase speed as tolerance allows as long as person is able to maintain accuracy (stop when or just before feeling dizzy).',
    ],
    cognitiveChallenge: [
      '1. Say a letter of the alphabet each time you face forward between rep.',
      '2. Use a deck of cards and have the person call out the card they see each time they face forward in between sides.',
    ],
  },
  {
    activityName: 'Wall Push-ups',
    activityVideoSrc: '//player.vimeo.com/video/700947113',
    category: 'Regulation, Gross Motor',
    steps: [
      'Stand a few feet from wall.',
      'Reach hands out and fall towards the wall.',
      'With back straight, slowly bend arms, bringing head towards the wall.',
      'Push back and drop arms.',
      'Reach back out to wall and repeat.',
      'Develop rocking rhythm and count out loud.',
    ],
    easierOption: [
      '1. Use a visual target on the wall to signal location of hand placement.',
      '2. Hold still so it becomes a wall "plank" vs. a push up.',
      '3. Do fewer repetitions.',
    ],
    harderOption: [
      '1. Move feet further away from the wall.',
      '2. Increase number of repetitions as strength increases.',
      '3. Push away from the wall strong enough that you lift/pop away from the wall to do a clip in between repetitions.',
    ],
    cognitiveChallenge: [
      '1. Say a nursery rhyme or words of a favorite song out loud while you keep words and movements in synch I.e. keep a rhythm or move to the rhythm of the rhyme/song.',
      '2. Count.',
    ],
  },
  {
    activityName: 'Lazy 8',
    activityVideoSrc: '//player.vimeo.com/video/700947594',
    category: 'Regulation, Gross Motor, Visual Motor, Early Development',
    steps: [
      'Holding an object in hand with arm outstretched, draw an imaginary Figure 8.',
      'Make sure child tracks the 8 with both eyes and that the shape is large enough to require the child to cross the middle of his/her body.',
      'The goal is a complete figure 8 with full, smooth circular motion. Do with one hand then the other.',
    ],
    easierOption: [
      '1. Draw a lazy 8 on a dry erase board or window and trace with a dry-erase marker.',
      '2. Sit in a chair vs. standing.',
      '3. Have a partner face you and move along with you leading the motion for you to follow.',
    ],
    harderOption: ['1. Change direction every 3 complete loops.', '2. Add the balance board.'],
    cognitiveChallenge: [
      '1. Count every time you cross through the middle.',
      '2. Say a pair of rhyming words each time you complete a full loop.',
    ],
  },
  {
    activityName: 'Cross-crunch',
    activityVideoSrc: '//player.vimeo.com/video/700947585',
    category: 'Gross Motor',
    steps: [
      'Lay face up on floor with knees bent and feet flat on floor.',
      'Place fingertips, unclasped behind head.',
      'Do sit-up, bending left knee in to touch right elbow.',
      'Repeat on opposite side.',
      'Continue alternating elbow to knee rhythmically for 5-10 sit-ups.',
      'Increase 10% each session.',
    ],
    easierOption: [
      '1. Touch the lifted knee with your hand instead of your elbow.',
      "2. Lift alternating legs only and don't lift or cross with your trunk then do the opposite, lifting & crossing only upper body and not lower.",
    ],
    harderOption: [
      '1. Hold your trunk/shoulders and your legs off the ground throughout the entire exercise/set and gradually increase the number of repetitions as strength/endurance improves.',
      '2. Straighten arms and legs in between reps for bending them to crunch.',
      '3. Lift trunk to center then cross to crunch then back to center.',
    ],
    cognitiveChallenge: ['1. Count.', '2. Alphabet.', '3. Alternate counting and alphabet.'],
  },
  {
    activityName: 'Thumb Game',
    activityVideoSrc: '//player.vimeo.com/video/700947623',
    category: 'Gross Motor, Early Development',
    steps: [
      'Make fist with thumbs inside.',
      'Open fist and close with thumb on top of fist.',
      'The progression is to do the opposite on each hand--left hand thumb in, right hand thumb out.',
    ],
    easierOption: [
      '1. Sit in a chair with fists resting on a tabletop.',
      '2. Go slowly & do one hand at a time but alternate from side to side.',
      '3. Have your partner do it with you but facing you so you can see them as a guide.',
    ],
    harderOption: ['1. Add the balance board.', '2. Close your eyes.'],
    cognitiveChallenge: [
      '1. Count.',
      '2. Alphabet.',
      '3. Alternate counting and alphabet.',
      '4. Change directions randomly as instructed by play partner (keeps element of surprise and requires full attention).',
    ],
  },
  {
    activityName: 'Dog Walk',
    activityVideoSrc: '//player.vimeo.com/video/700947824',
    category: 'Gross Motor, Early Development',
    steps: [
      "Place bean bag on child's back and have child crawl across the room and return.",
      'If this is too difficult, begin without bean bag.',
      '2-5 minutes. Increase length every few weeks as strength builds.',
    ],
    easierOption: [
      '1. Remove beanbag and crawl without anything on your back.',
      '2. Decrease the crawling distance.',
    ],
    harderOption: [
      '1. Close your eyes while crawling (which means you need to count the steps involved).',
      '2. Place different items on the back which require more precision and stability while crawling ex. a book or an empty box.',
    ],
    cognitiveChallenge: [
      '1. Give verbal instructions for a sequence of locations in the room to crawl to and person has to remember the locations and the sequence; increase number of instructions/steps to increase complexity.',
      '2. Provide a sequence of words/objects for the person to remember before they crawl away; when they get to the end location ask them to recall the sequence of words/objects.',
      '3. Play a game of memory cards where the person crawls from one location to retrieve the cards and another to place them with their match.',
    ],
  },
  {
    activityName: 'Finger Lift',
    activityVideoSrc: '//player.vimeo.com/video/700947893',
    category: 'Fine Motor, Early Development',
    steps: [
      'Place both hands on a flat surface hands down with fingers extended.',
      'Begin with right hand: lift thumb up and down, then index finger up and down, then middle finger, then ring finger, etc.',
      'Then reverse sequence.',
      'Repeat with other hand, then do both hands together.',
    ],
    easierOption: [
      '1. Do one hand at a time. Switch to completing taps with the opposite hand, once the first hand is complete.',
      '2. Put stickers on the table to visually identify where each finger should tap.',
      '3. Have your partner provide a tactile cue by tapping each finger prior to lifting it.',
    ],
    harderOption: ['1. Increase speed of tapping.', '2. Close your eyes while tapping.'],
    cognitiveChallenge: [
      '1. Count to 5 forward and backward as fingers lift OR repeat 5 memorized objects "sun, moon, plants, stars, and earth" then reverse.',
    ],
  },
  {
    activityName: 'Ball Roll',
    activityVideoSrc: '//player.vimeo.com/video/700947383',
    category: 'Gross Motor, Early Development',
    steps: [
      'Using a 7"-12" diameter ball, sit across from child with legs spread wide and roll the ball back and forth to each other.',
      'For variation, alternate hands. i.e. push with left hand, then with right hand.',
      'Play for 2-5 minutes.',
    ],
    easierOption: [
      '1. Remove balance board and sit upright against a wall.',
      '2. Decrease the distance between you and your partner.',
    ],
    harderOption: ['1. Use balance board.', '2. Play bounce and catch game in the same position.'],
    cognitiveChallenge: [
      '1. One partner says a word as they roll and the other partner has to rhyme that word as they catch, then alternate.',
      '2. Some partner takes a sequence of words/objects/numbers and the other partner has to recite the sequence stating one item of the sequence each time they catch/receive the ball.',
      '3. Count and increasing counting to increase complexity.',
      '4. Say the alphabet.',
      '5. Reverse count or reverse say the alphabet',
    ],
  },
  {
    activityName: 'Balance Board Sitting',
    activityVideoSrc: '//player.vimeo.com/video/700947333',
    category: 'Gross Motor, Early Development',
    steps: [
      'Child sits on balance board without using hands for support.',
      'Keep good posture, sitting position may be legs crossed or out in front.',
      'If too difficult, place folder towel or blanket under board.',
    ],
    easierOption: [
      '1. Place balance board on a pillow or blanket for more stability.',
      '2. Lean to one side and touch the floor with your hand; lean to the opposite side and do the same.',
    ],
    harderOption: [
      '1. Place bean bag on the head.',
      '2. Lift one foot at a time, alternating which foot you lift as you balance on the board.',
    ],
    cognitiveChallenge: [
      '1. Sing your favorite song or repeat a nursery rhyme.',
      '2. Count how long you sit without any edge of the board or hands touching the ground.',
    ],
  },
  {
    activityName: 'Smart Dog',
    activityVideoSrc: '//player.vimeo.com/video/700947981',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Adult sets up 3 objects.',
      'Have child look at the order of the objects and crawl across the room.',
      'Meanwhile, parent changes the order of the object.',
      'Child crawls back to the starting point and puts the objects in the right order.',
      'For variety, use differently shaped objects (square, circle, triangle) or colors.',
    ],
    easierOption: [
      '1. Use familiar objects and decrease the number in the sequence.',
      '2. Repeat the sequence out loud to help you remember as you crawl.',
    ],
    harderOption: ['1. Increase the number of objects.', '2. Use new objects every time.'],
    cognitiveChallenge: [
      '1. Give verbal instructions for a sequence of locations in the room to crawl to and person has to remember the locations and the sequence; increase number of instructions/steps to increase complexity.',
      '2. Provide a sequence of words/objects for the person to remember before they crawl away; when they get to the end location ask them to recall the sequence of words/objects.',
      '3. Play a game of memory cards where the person crawls from one location to retrieve the cards and another to place them with their match.',
    ],
  },
  {
    activityName: 'Drop, Catch & Under Leg',
    activityVideoSrc: '//player.vimeo.com/video/700947873',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Drop bean bag from the left hand to the right.',
      'Catch bean bag, lift right leg up high and pass bean bag under the leg to the left hand.',
      'Repeat 10x, switch directions and repeat 10x.',
      'Do 2 full sets.',
    ],
    easierOption: [
      '1. Sit in a chair instead of standing.',
      '2. Place bean bag into left hand instead of dropping it.',
    ],
    harderOption: [
      '1. Increase dropping distance.',
      '2. Increase speed.',
      '3. Increase number of repetitions as you get stronger.',
      '4. Change direction every 5 drops.',
    ],
    cognitiveChallenge: ['1. Sing favorite song or repeat a nursery rhyme as the same time.'],
  },
  {
    activityName: 'Drop, Catch & Behind Back',
    activityVideoSrc: '//player.vimeo.com/video/700947854',
    category: 'Gross Motor',
    steps: [
      'Drop bean bag from the left hand to the right.',
      'Catch bean bag, pass around back to left hand and drop again.',
      'Repeat 10x, switch directions and repeat 10x.',
      'Do 2 full sets.',
    ],
    easierOption: [
      '1. Remove the drop and catch portion so you only pass the bean bag from left to right in the front of the body and right to left in behind the body.',
      '2. Sit in a chair instead of standing.',
    ],
    harderOption: [
      '1. Increase dropping distance.',
      '2. Try tossing instead of dropping.',
      '3. Change direction every 5 repetitions',
    ],
    cognitiveChallenge: [
      '1. Sing favorite song or repeat a nursery rhyme as the same time',
      '2. Count when moving clockwise around the body and say the alphabet when moving counter clockwise; pick up where you left off when switching back to the respective direction.',
    ],
  },
  {
    activityName: 'Funny Walk',
    activityVideoSrc: '//player.vimeo.com/video/700947913',
    category: 'Gross Motor',
    steps: [
      'Child begins in crouch position, hands on floor in front of body, knees and arms are bent.',
      'Walk on all fours, moving left side hand & foot together then right side hand & foot (camels and bears walk this way).',
      'This is an unnatural movement which requires focus and coordination. Try not to let feet get ahead of hands.',
      '2-5 minutes. Increase length every few weeks as strength builds.',
    ],
    easierOption: [
      '1. Remove the forward motion/stepping and stay in crouch position with rocking weight from side-to-side.',
      '2. Shorten the distance you travel.',
      '3. Go slowly.',
    ],
    harderOption: [
      '1. Increase distance you travel as strength increases.',
      '2. Add objects/obstacles to navigate around while traveling.',
    ],
    cognitiveChallenge: [
      '1. Turn this into a stop-start listening game by having the partner call out "stop" and "go".',
    ],
  },
  {
    activityName: 'Bump & Roll',
    activityVideoSrc: '//player.vimeo.com/video/700947396',
    category: 'Gross Motor',
    steps: [
      'Child is on all fours, using nose or forehead to roll a ball to one side of the room and back.',
      'A simple obstacle course (e.g. around chairs or tables) can make this fun.',
      'Use a ball which is at least 7 inches in diameter to avoid scraping face on ground.',
      '2-5 minutes. Increase length every few weeks as strength builds.',
    ],
    easierOption: [
      '1. Use a bigger ball.',
      '2. Shorten distance for bumping.',
      "3. Have partner stabilize ball so it rolls straight and doesn't go too far.",
    ],
    harderOption: [
      '1. Increase the distance traveled during activity.',
      '2. Create a set of "goal posts" in which to aim for when bumping and keep score for number of "goals" scored.',
    ],
    cognitiveChallenge: [
      '1. Sing a favorite song or say a nursery rhyme while bumping and make sure bumps are timed so they synch with the beat/rhythm of the song/rhyme',
      '2. Say a different object within a given category each time you bump; change categories every 3 bumps.',
    ],
  },
  {
    activityName: 'Dodge Ball',
    activityVideoSrc: '//player.vimeo.com/video/700947479',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Stand directly under plum line of the ball.',
      'Partner starts the hanging ball in a broad round arc around the client.',
      'Client must count the rotations and avoid contact with the ball.',
      'Moving head and body is o.k. but keep feet still (stand in place).',
      'Try to increase the number of full rotations the ball makes before it touches the client.',
    ],
    easierOption: [
      '1. Sit in a chair so that only the upper body has to avoid the ball and the client is supported.',
      '2. Only swing the ball toward one side vs. all the way around the client.',
      '3. Decrease the number of rotations so the expectations is only one rotation.',
    ],
    harderOption: [
      '1. Have client balance on one foot instead of standing on two (advanced option only).',
      '2. Hang the ball at varying heights each round.',
      '3. Cover the eye opposite the oncoming ball.',
      '4. Use balance board (advanced option only).',
    ],
    cognitiveChallenge: [
      '1. Sing a favorite song or repeat nursery rhymes at the same time as playing.',
      '2. Count backwards instead of forward.',
      '3. Instead of counting, say the alphabet and say a word that starts with each successive letter a - apple, b- boy, c - cat etc.',
    ],
  },
  {
    activityName: 'Straight Line Taps',
    activityVideoSrc: '//player.vimeo.com/video/700947410',
    category: 'Gross Motor, Visual Motor',
    steps: [
      'Stand just behind the plum line of the ball.',
      'Client taps the hanging ball gently in a straight line.',
      'After they are accustomed to the game, alternate hands right and left.',
      'The goal is to tap the ball with a controlled motion in a straight line.',
    ],
    easierOption: [
      '1. Sit vs. stand.',
      '2. Use only one hand vs. alternating.',
      '3. Have partner hold the ball vs. hang it thus being able to assist with accurate contact with the ball.',
    ],
    harderOption: [
      '1. Balance on one foot or the balance board vs. stand.',
      '2. Start with one hand and switch hands when the partner calls out "switch" (only do this after rhythmic alternating has been mastered).',
      '3. Demonstrate a pattern of tapping the ball i.e. L-R-L-L and have the client copy the pattern demonstrated.',
    ],
    cognitiveChallenge: ['Same as the "harder" modification'],
  },
]
