import React, { useEffect, useState } from 'react'
import { useForm } from 'hooks'
import {
  Grid,
  Button,
  Select,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  CardContent,
  CardHeader,
  Stack,
} from 'components'
import Layout from './Layout'
import useGTM from '../../../utils/hooks/useGTM'
import { useSelector } from 'react-redux'
import { PROFESSIONAL_CREDENTIALS } from 'utils/constants/professionalCredentials'
import { makeStyles } from '@mui/styles'
import links from '../../../utils/constants/links'

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
  },
})

export default ({ next, step, extendData }) => {
  const classes = useStyles()
  const verifyCredentialsForm = links.professionalCredentials.applicationForm
  const { providerProfessionalCredentials } = useSelector((state) => state.newPurchase.data)

  const { form, setFormValue, errors, isValid, pending } = useForm({
    data: {
      providerProfessionalCredentials: providerProfessionalCredentials || '',
    },
    validation: {
      providerProfessionalCredentials: {
        required: { msg: 'Provider professional credentials is required' },
      },
    },
  })

  const { addProfessionalCredentials } = useGTM()
  const onSubmit = (e) => {
    if (form.providerProfessionalCredentials === 'Other') {
      setOtherProfCredSelected(true)
    } else {
      e.preventDefault()
      addProfessionalCredentials({ professional_credentials: form.providerProfessionalCredentials })
      extendData({
        providerProfessionalCredentials: form.providerProfessionalCredentials,
      })
      next()
    }
  }

  const onBack = (e) => {
    if (form.providerProfessionalCredentials === 'Other') {
      setOtherProfCredSelected(false)
    } else {
      next(step - 1)
    }
  }

  const [otherProfCredSelected, setOtherProfCredSelected] = useState(false)
  const otherProfCredVerificationPage = (
    <>
      <CardHeader
        title={`2.1 Verify professional credentials`}
        classes={{ title: classes.cardHeader }}
      />
      <CardContent>
        <Typography className="mb-4" variant="body1">
          Thank you for your interest. Based on your selection, we need to verify your professional
          credentials.
        </Typography>
        <Typography className="mb-4" variant="body1">
          Please click the link below to complete our Provider Application for Exception.
        </Typography>
        <Typography className="mb-4" variant="body1">
          You will be notified of the status of your application within 5 business days following
          submission.
        </Typography>
        <Grid container justify="center">
          <Button
            color="primary"
            variant="contained"
            onClick={() => (window.location.href = verifyCredentialsForm)}
          >
            Application for Exception
          </Button>
        </Grid>
        <Grid className="mt-5">
          <Button color="primary" onClick={() => setOtherProfCredSelected(false)}>
            Back
          </Button>
        </Grid>
      </CardContent>
    </>
  )

  // on load: send virtual page load
  const { pageView } = useGTM()
  useEffect(() => {
    pageView({
      pageUrl: `${window.document.location.origin}${window.document.location.pathname}/professional-credentials`,
      pageTitle: 'New Purchase - Select professional credentials',
    })
    // eslint-disable-next-line
  }, [])

  return (
    <Layout next={next}>
      {otherProfCredSelected ? (
        otherProfCredVerificationPage
      ) : (
        <>
          <CardHeader
            title={`${step + 1}. Please select your professional credentials`}
            classes={{ title: classes.cardHeader }}
            data-test="card-header"
          />
          <CardContent>
            <Typography gutterBottom variant="body1">
              To enroll in the training and become eligible to use Unyte Health products, you must
              hold an accredited academic degree and a <b>valid and active license or credential</b>{' '}
              in one of the following listed professions:
            </Typography>
            <FormControl
              sx={{
                width: '100%',
                paddingBottom: '2.25rem',
                marginTop: '0.5rem',
              }}
              data-test="select-professional-credentials"
            >
              <InputLabel id="select-professional-credentials-input-label">
                Professional Credentials*
              </InputLabel>
              <Select
                label="Professional Credentials*"
                labelId="select-professional-credentials-label"
                id="select-professional-credentials"
                value={form.providerProfessionalCredentials}
                onChange={setFormValue('providerProfessionalCredentials')}
                error={errors.providerProfessionalCredentials}
              >
                {PROFESSIONAL_CREDENTIALS.map((value, index) => (
                  <MenuItem
                    key={`pc${index}`}
                    value={value}
                    data-test="select-professional-credentials-item"
                  >
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {form.providerProfessionalCredentials === 'Other' && (
              <Typography gutterBottom variant="body1" className="pb-5">
                Based on your selection, we need to verify your professional credentials.
              </Typography>
            )}

            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Button color="primary" onClick={onBack}>
                Back
              </Button>
              <Button
                disabled={!isValid}
                color="primary"
                variant="contained"
                loading={pending}
                onClick={onSubmit}
                data-test="submit-button"
              >
                Continue
              </Button>
            </Stack>
          </CardContent>
        </>
      )}
    </Layout>
  )
}
