export default {
  date: 'MMM D, YYYY',
  time: 'h:mm A',
  dateAndTime: 'MMM D, YYYY h:mm A',
}

// TODO: Change this to default and remove old one which is used in moments in the codebase
export const DATE_FORMATS = {
  date: 'MMM d, yyyy',
  time: 'h:mm a',
  dateAndTime: 'MMM d, yyyy h:mm a',
  dateAndTimeNoYear: 'MMM d h:mm a',
}
