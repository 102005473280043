import React from 'react'
import { useTheme } from '@mui/material/styles'
import ProductCard from './ProductCard'

export default function PathwayCards() {
  const theme = useTheme()

  const pathwayCards = [
    {
      name: 'Connect',
      id: 'connect',
      description:
        'Full spectrum, unfiltered music. ' +
        'SSP Connect can be offered as an introduction to the SSP to ' +
        'assess sensitivity to listening, and enhance overall readiness ' +
        'for SSP Core. These 5 hours of music can be listened to in any order.',
      metadata: {
        borderColor: theme.palette.yellow.dark,
        pictureUrl: '/images/paths/ConnectCard.png',
      },
    },
    {
      name: 'Core',
      id: 'core',
      description:
        'Progressive, dynamically filtered music. ' +
        'This pathway is the strongest and potentially most therapeutic of the ' +
        'three pathways. It is important to listen in order until the ' +
        'program is complete.',
      metadata: {
        borderColor: theme.palette.pink.dark,
        pictureUrl: '/images/paths/CoreCard.png',
      },
    },
    {
      name: 'Balance',
      id: 'balance',
      description:
        'Lightly filtered music. ' +
        'SSP Balance can be used to continue or extend benefits from ' +
        'SSP Core. This pathway can be repeated and listened to in any order.',
      metadata: {
        borderColor: theme.palette.mint.medium,
        pictureUrl: '/images/paths/BalanceCard.png',
      },
    },
  ]

  return (
    <>
      {pathwayCards.map(({ description, id, name, metadata }) => (
        <ProductCard
          name={name}
          id={id}
          key={id}
          description={description}
          metadata={metadata}
          cardType={'pathway'}
        />
      ))}
    </>
  )
}
