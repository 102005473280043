export default {
  emailValidation: {
    required: { msg: 'Email is required.' },
    email: { msg: 'Please enter a valid email address.' },
    len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
  },
  verifyPasswordValidation: {
    required: { msg: 'Password is required.' },
  },
  passwordValidation: {
    required: { msg: 'Password is required.' },
    password: {
      msg:
        'Password must be 8 characters or longer and contain at least one number and lowercase letter.',
    },
  },
}
