import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import CardActionArea from '@mui/material/CardActionArea'
import Box from '@mui/material/Box'
import makeStyles from '@mui/styles/makeStyles'
import { setIntroVideoState } from 'store/modules/app'
import { FormHelperText } from '@mui/material'
import { IntroDialog } from 'components'

const useStyles = makeStyles({
  card: {
    width: '100%',
  },
  cardMedia: {
    paddingTop: '56.25%',
    height: 0,
  },
  cardContent: {
    height: '8rem',
  },
  CardActionArea: {
    minWidth: '15rem',
  },
})

export default ({ cards }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [activeModal, setActiveModal] = useState(null)

  return (
    <Container className="py-4" maxWidth="lg">
      <Grid container spacing={2}>
        {cards.map((card, index) => {
          if (!card.modal) {
            return (
              <Grid item key={card.title} xs={12} sm={6} md={4} lg={3} zeroMinWidth>
                <CardActionArea
                  href={card.title === 'Intro Video' ? '' : card.src}
                  className={classes.CardActionArea}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => card.title === 'Intro Video' && dispatch(setIntroVideoState(true))}
                >
                  <Card className={`${classes.card} h-full`} elevation={0}>
                    <CardMedia className={classes.cardMedia} image={card.img} title={card.title} />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="body2" component="div">
                        <Box display="block" className="pb-2" fontWeight="fontWeightBold">
                          {card.title}
                        </Box>
                        <Box display="block" textOverflow="ellipsis">
                          {card.shortDescription}
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
              </Grid>
            )
          } else {
            return (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <CardActionArea
                  className={classes.cardActionArea}
                  onClick={() => setActiveModal(card.id)}
                >
                  <Card elevation={0} className={`${classes.card} h-full`}>
                    <CardMedia className={classes.cardMedia} image={card.img} title={card.title} />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="body2" component="div">
                        <Box display="block" className="pb-2" fontWeight="fontWeightBold">
                          {card.title}
                        </Box>
                        <Box display="block" textOverflow="ellipsis">
                          {card.shortDescription}
                        </Box>
                      </Typography>
                    </CardContent>
                  </Card>
                </CardActionArea>
                <IntroDialog
                  open={activeModal === card.id}
                  title={card.title}
                  content={
                    <FormHelperText
                      className="my-5 py-5"
                      style={{ fontSize: 14, textAlign: 'center' }}
                    >
                      <iframe
                        title="account video"
                        src={card.src}
                        width="100%"
                        height="360"
                        frameBorder="0"
                        allowFullScreen
                      ></iframe>
                    </FormHelperText>
                  }
                  onClose={() => setActiveModal(null)}
                />
              </Grid>
            )
          }
        })}
      </Grid>
    </Container>
  )
}
