/**
 * This file will host the services for Authenticator
 */
import { Auth } from 'aws-amplify'
import { client } from 'utils/apollo'

// redux
import { resetActiveClients } from '../../store/modules/activeClients'
import { resetAdmins } from '../../store/modules/admins'
import { setAssessmentsIntialState } from '../../store/modules/assessments'
import { setUserInfo } from '../../store/modules/auth'
import { resetClients } from '../../store/modules/clients'
import { resetFF } from '../../store/modules/ff'
import { resetLicenses } from '../../store/modules/licenses'
import { resetOrganization } from '../../store/modules/organization'
import { resetProviders } from '../../store/modules/providers'
import { resetSeats } from '../../store/modules/seats'
import { resetSessions } from '../../store/modules/sessions'
import { trimString } from '../../utils/deepTrim'
import { setOpenHubspot } from 'store/modules/hubspot'
import { removeHubspotScripts } from 'components/header/components/HubSpotWidget'

const services = {
  async handleSignIn(formData) {
    let { username, password } = formData
    // transform data
    const signInResponse = await Auth.signIn(trimString(username), trimString(password))
    return signInResponse
  },
}

function clearLocalStorage() {
  try {
    Object.keys(window.localStorage).forEach((key) => {
      if (key.startsWith('Cognito')) {
        window.localStorage.removeItem(key)
      }
    })
    window.localStorage.removeItem('AuthState')
    window.localStorage.removeItem('token')
  } catch (error) {
    console.error(error)
  }
}

export const otherServices = {
  signOut() {
    return async (dispatch) => {
      await Auth.signOut()

      // remove hubspot on logout
      if (window.HubSpotConversations) {
        window.HubSpotConversations.widget.remove()
        removeHubspotScripts()
      }
      await clearLocalStorage()
      await dispatch(setOpenHubspot(false))

      // clear redux
      await dispatch(setUserInfo({ token: null, email: null, username: null, user: null }))
      await dispatch(setAssessmentsIntialState())
      await dispatch(resetClients())
      await dispatch(resetActiveClients())
      await dispatch(resetSeats())
      await dispatch(resetLicenses())
      await dispatch(resetSessions())
      await dispatch(resetOrganization())
      await dispatch(resetFF())
      await dispatch(resetAdmins())
      await dispatch(resetProviders())
      await client.resetStore()
    }
  },
}

export default services
