/**
 *
 */
import { Box, Card, CardContent, CardHeader } from '@mui/material'
import React, { useState } from 'react'
import { titrationToolSurvey, QUESTIONS, RATING } from 'utils/constants/titrationToolSurvey'

export const getComplexityRating = (score) => {
  const sum = Object.values(score).reduce((a, c) => a + c, 0)
  if (0 <= sum && sum <= 10) {
    return {
      rate: RATING.low,
      title: `${RATING.low} Complexity`,
      caption: 'It sounds like your client is lower complexity than most.',
      colour: '#3784ac',
    }
  } else if (11 <= sum && sum <= 20) {
    return {
      rate: RATING.medium,
      title: `${RATING.medium} Complexity`,
      caption: 'It sounds like your client is somewhat complex in their presentation.',
      colour: '#ff914d',
    }
  } else if (21 <= sum && sum <= 30) {
    return {
      rate: RATING.high,
      title: `${RATING.high} Complexity`,
      caption: 'It sounds like your client is higher complexity than most.',
      colour: 'red',
    }
  }
}

export default function TitrationTool({ printLayoutEnabled, setHidePrintButton }) {
  const [currentPage, setCurrentPage] = useState(0)

  const initialScore = {}
  QUESTIONS.forEach((question) => {
    Object.assign(initialScore, { [question.id]: false })
  })

  const [score, setScore] = useState(initialScore)
  const complexity = getComplexityRating(score)

  const onPrevious = () => {
    setCurrentPage(Math.max(currentPage - 1), 0)
  }
  const onNext = () => {
    setCurrentPage(Math.min(currentPage + 1, 15))
  }

  const currentPageObject = titrationToolSurvey.find(({ page }) => page === currentPage)

  const onScoreSelection = (id, option) => (event) => {
    setScore({ ...score, [id]: option })
  }

  return (
    <Card
      variant="outlined"
      sx={{
        minHeight: '500px',
        display: 'flex',
        alignContent: 'space-between',
        flexDirection: 'column',
        marginTop: '3rem',
      }}
    >
      <Box>
        <CardHeader
          title={currentPageObject.title}
          titleTypographyProps={{ align: 'center', variant: 'h3', color: 'primary' }}
        />
        <CardContent>
          {currentPageObject && (
            <currentPageObject.Component
              {...currentPageObject}
              complexity={complexity}
              score={score}
              onScoreSelection={onScoreSelection}
              questions={QUESTIONS}
              onPrevious={onPrevious}
              onNext={onNext}
              printLayoutEnabled={printLayoutEnabled}
              setHidePrintButton={setHidePrintButton}
            />
          )}
        </CardContent>
      </Box>
    </Card>
  )
}
