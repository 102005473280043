import { Box, Button, Card, Grid, Stack, Typography } from 'components'
import React, { useState } from 'react'
import makeStyles from '@mui/styles/makeStyles'
import NewUpdates from 'components/NewUpdates'
import { ASSESSMENT_BEST_PRACTICES_VIDEO } from './utils/constants'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

const useStyles = makeStyles({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
  circle: {
    borderRadius: '50%',
    width: '60px',
    height: '60px',
    padding: '5px',
    border: '2px solid #000',
    textAlign: 'center',
  },
})

export default function GetStartedWithAssessments({ changeDisplay }) {
  const classes = useStyles()
  const [showVideo, setShowVideo] = useState(false)
  const authUserId = useSelector((state) => get(state, 'auth.user.id', ''))

  const cards = [
    {
      title: '1',
      subtitle: 'Add Client',
      content: 'Add your first client to get started.',
    },
    {
      title: '2',
      subtitle: 'Administer Assessment',
      content:
        'After your client is added, send an assessment by email or complete it with your client.',
    },
    {
      title: '3',
      subtitle: 'Review Results',
      content:
        'Once the assessment is complete, review their results and monitor changes over time.',
    },
  ]

  return (
    <>
      {showVideo && (
        <NewUpdates
          title="Unyte Assessments Best Practices"
          src={ASSESSMENT_BEST_PRACTICES_VIDEO}
          type={`assessment${authUserId}`}
          override={showVideo}
          overrideClose={() => setShowVideo(false)}
          text=""
          allowDoNotShowAgain={false}
        />
      )}

      <Box px={2}>
        <Typography className="pl-2 pb-5" variant="body1">
          Here's a quick overview on <span style={{ fontWeight: 500 }}>how to get started</span>{' '}
          with Unyte Assessments:
        </Typography>

        <Grid container spacing={2} alignItems="stretch">
          {cards.map(({ title, subtitle, content }, index) => (
            <Grid
              item
              key={`getting-started-with-assessment-${index}`}
              xs={12}
              sm={4}
              md={4}
              lg={4}
            >
              <Card className={`${classes.card} p-10`} elevation={0} sx={{ height: '300px' }}>
                <Typography className={`${classes.circle} p-2`} variant="h4">
                  {title}
                </Typography>
                <Typography className="pl-2 pt-5" variant="h5">
                  {subtitle}
                </Typography>
                <Typography className="p-2" variant="body2">
                  {content}
                </Typography>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Stack className="pt-10 pb-2" direction="row" justifyContent="center">
          <Button variant="contained" sx={{ width: '300px' }} onClick={() => changeDisplay()}>
            <Typography variant="button">Get Started Now</Typography>
          </Button>
        </Stack>

        <Stack className="pt-10" direction="row" justifyContent="center">
          <Typography variant="body2">
            Still feeling unsure? Watch this short video about Unyte Assessments best practices!
          </Typography>
        </Stack>

        <Stack className="p-2" direction="row" justifyContent="center">
          <Button
            variant="text"
            onClick={() => {
              setShowVideo(true)
            }}
          >
            <Typography variant="button">UNYTE ASSESSMENTS BEST PRACTICES VIDEO</Typography>
          </Button>
        </Stack>
      </Box>
    </>
  )
}
