import { useForm } from 'hooks'

const validation = {
  firstName: {
    required: { msg: 'First name is required.' },
    len: {
      args: [1, 100],
      msg: 'First name must be present and no longer than 100 characters',
    },
  },
  lastName: {
    required: { msg: 'Last name is required.' },
    len: {
      args: [1, 100],
      msg: 'Last name must be present and no longer than 100 characters',
    },
  },
  address1: {
    len: {
      args: [null, 100],
      msg: 'Address 1  must be no longer than 100 characters',
    },
  },
  address2: {
    len: {
      args: [null, 100],
      msg: 'Address 2 must be no longer than 100 characters',
    },
  },
  city: {
    len: {
      args: [null, 100],
      msg: 'City must be no longer than 100 characters',
    },
  },
  state: {
    len: {
      args: [null, 100],
      msg: 'State must be no longer than 100 characters',
    },
  },
  country: {
    len: {
      args: [null, 100],
      msg: 'Country must be no longer than 100 characters',
    },
  },
  zip: {
    len: {
      args: [null, 10],
      msg: 'Zip must be no longer than 10 characters',
    },
  },
  phone: {
    len: {
      args: [null, 18],
      msg: 'Phone must be no longer than 18 characters',
    },
    phone: {
      msg: 'Phone must contain only digits, spaces, dashes and parentheses',
    },
  },
}

const useUserForm = ({ user, fields = [], onSubmit }) => {
  const form = useForm({
    data: {
      id: user.id,
      ...fields.reduce((res, key) => ({ ...res, [key]: user[key] }), {}),
    },
    validation: fields.reduce((res, key) => ({ ...res, [key]: validation[key] }), {}),
    onSubmit,
  })

  return form
}

export default useUserForm
