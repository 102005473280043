import React from 'react'
import CardWithBg from 'components/CardWithBg'
import { Grid, Button } from 'components'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    console.error(error)
    return { hasError: true }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError === false && this.state.hasError === true) {
      window.addEventListener(
        'popstate',
        function(event) {
          if (!event) {
            return
          }
          window.location.reload()
        },
        false
      )
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <CardWithBg>
          <div
            data-test="error-boundary-message"
            style={{ fontWeight: 300, textAlign: 'center', fontSize: 24, marginBottom: 5 }}
          >
            Sorry, something went wrong.
          </div>
          <div style={{ fontWeight: 300, textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
            Let's take a breath and enjoy the view.
          </div>

          <Grid container justifyContent="center" className="my-5">
            <a href="/">
              <Button style={{ margin: 'auto', display: 'inline-block' }} color="primary">
                Open home page
              </Button>
            </a>
          </Grid>
        </CardWithBg>
      )
    }

    return this.props.children
  }
}
