const STORE_SESSIONS = 'SESSIONS/STORE_SESSIONS'
const STORE_SESSIONS_FROM_USE_GET_USER = 'SESSIONS/STORE_SESSIONS/USE_GET_USER'
const COMBINE_SESSIONS = 'SESSIONS/COMBINE_SESSIONS'
const CLIENT_UPDATE_STATUS = 'SESSIONS/CLIENT_UPDATE_STATUS'
const RESET_SESSIONS = 'SESSIONS/RESET_SESSIONS'

// dashboard
const SET_ASSESSMENTS_AND_SESSIONS = 'SESSIONS/SET_ASSESSMENTS_AND_SESSIONS'

const initialState = { sessions: [] }

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      }
    case STORE_SESSIONS_FROM_USE_GET_USER:
      return {
        ...state,
        sessions: action.payload,
      }

    case COMBINE_SESSIONS:
      return {
        ...state,
        sessions: action.payload,
      }

    // find session and update to status
    case CLIENT_UPDATE_STATUS: {
      const { sessionId, status } = action.payload
      const newState = state.sessions.map((session) => {
        if (session.id !== parseInt(sessionId)) {
          return { ...session }
        } else {
          const data = session.data
          return { ...session, data: { ...data, status } }
        }
      })

      return {
        sessions: [...newState],
      }
    }

    case SET_ASSESSMENTS_AND_SESSIONS:
      return {
        ...state,
        ...action.payload,
      }
    case RESET_SESSIONS:
      return {
        sessions: [],
      }

    default:
      return state
  }
}

// to do: change to initialize sessions
export function setStoreSessionsUseGetUser(payload) {
  return {
    type: STORE_SESSIONS_FROM_USE_GET_USER,
    payload,
  }
}

export function setStoreSessions(payload) {
  return {
    type: STORE_SESSIONS,
    payload,
  }
}

// combine sessions with product Event data
export function setCombineSessions(payload) {
  return {
    type: COMBINE_SESSIONS,
    payload,
  }
}

// set client assessment status
export function setAssessmentStatus(payload) {
  return {
    type: CLIENT_UPDATE_STATUS,
    payload,
  }
}

export function setAssessmentsAndSessions(payload) {
  return {
    type: SET_ASSESSMENTS_AND_SESSIONS,
    payload,
  }
}
export function resetSessions() {
  return {
    type: 'SESSIONS/RESET_SESSIONS',
  }
}
