/**
 * Products here are displayed in a grid
 */
import React, { useState } from 'react'
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Button,
  Collapse,
} from 'components'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import useCart from '../../new-purchase/utils/useCart'

export default function Product({ product: { SKU, PriceTier1, Attachments, Name, Description } }) {
  const imgSrc = get(Attachments, '[0]', '')

  // if quantity > 1 show cart
  const { selectedAddOns = {} } = useSelector((state) => state.newPurchase.data)
  const quantity = selectedAddOns[SKU] || 0
  const { handleEditProducts } = useCart()

  const onAdd = () => {
    handleEditProducts(SKU, 'add')
  }

  const onSubtract = () => {
    if (quantity > 0) {
      handleEditProducts(SKU, 'subtract')
    }
  }

  const [expanded, setExpanded] = useState(false)
  const onExpand = () => {
    setExpanded(!expanded)
  }

  return (
    <Grid item xs={12} md={6} lg={4} key={SKU} data-test={`sku-${SKU}`}>
      <Card elevation={0} variant="outlined" className="h-full">
        <CardMedia component="img" alt={Name} image={imgSrc} title={Name} height="300px" />
        <CardContent>
          <Typography gutterBottom variant="body1" component="h2">
            {Name}
          </Typography>
          <Grid container>
            <Button onClick={onExpand} className="w-full">
              <Typography variant="button">
                {expanded ? 'Hide Description' : ' View Description'}
              </Typography>
            </Button>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography gutterBottom variant="body1" component="h2">
                  {Description}
                </Typography>
              </CardContent>
            </Collapse>
          </Grid>
        </CardContent>
        <Grid
          aria-label="card-actions"
          container
          justifyContent="space-around"
          alignItems="center"
          className="pb-3"
        >
          <Grid item xs={4} className="m-auto">
            <Typography variant="h5" component="h2" className="w-10 m-auto" align="center">
              ${PriceTier1}
            </Typography>
          </Grid>
          <Grid item xs={8} container direction="row" justifyContent="center">
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={onSubtract}
              size="large"
            >
              <RemoveIcon />
            </IconButton>
            <Typography variant="h5" component="h2" className="w-10 my-auto" align="center">
              {quantity}
            </Typography>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              onClick={onAdd}
              size="large"
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  )
}
