const SELECT_USER = 'PROVIDERS/SELECT_USER'
const SET_EDIT = 'PROVIDERS/SET_EDIT'
const SET_FORM = 'PROVIDERS/SET_FORM'
const SET_DATA = 'PROVIDERS/SET_DATA'
const RESET_PROVIDERS = 'PROVIDERS/RESET_PROVIDERS'

const initialState = {
  selectedUser: null,
  edit: false,
  form: null,
  data: [],
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload,
      }
    case SET_EDIT:
      return {
        ...state,
        edit: action.payload,
      }
    case SET_FORM:
      return {
        ...state,
        form: action.payload,
      }
    case SET_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case RESET_PROVIDERS:
      return {
        ...initialState,
      }
    default:
      return state
  }
}

export function selectUser(payload) {
  return {
    type: SELECT_USER,
    payload,
  }
}

export function setEdit(payload) {
  return {
    type: SET_EDIT,
    payload,
  }
}
export function setForm(payload) {
  return {
    type: SET_FORM,
    payload,
  }
}
export function setData(payload) {
  return {
    type: SET_FORM,
    payload,
  }
}
export function resetProviders(payload) {
  return {
    type: RESET_PROVIDERS,
  }
}
