import React, { useMemo } from 'react'
import moment from 'utils/moment'
import formatSeconds from 'utils/formatSeconds'
import { Dialog, Grid, Tooltip } from 'components'
import formats from 'utils/constants/formats'
import { get, groupBy } from 'lodash'

export default ({ session, user, onClose }) => {
  const p = (t) => (t * 100) / get(session, 'productEvent.metadata.requiredInteractionTime')
  const { pauses, totalPausesCount, totalPausesTime } = useMemo(() => {
    const states = get(session, 'data.states', [])
    const pauses = states
      .reduce((res, item, index, arr) => {
        if (item.status !== 'paused') {
          return res
        }

        const next = arr[index + 1]
        const time = next
          ? (new Date(next.createdAt).getTime() - new Date(item.createdAt).getTime()) / 1000
          : 0

        return [
          ...res,
          {
            left: p(item.position),
            postion: item.position,
            time: time,
          },
        ]
      }, [])
      .map((item, index) => ({ ...item, index: index + 1 }))

    const groupedPauses = Object.values(
      groupBy(pauses, ({ left }) => Math.floor(left / 20))
    ).reduce((res, group) => {
      const first = group[0]
      const last = group[group.length - 1]
      return [
        ...res,
        {
          index: first.index === last.index ? `${first.index}` : `${first.index}-${last.index}`,
          left: (first.left + last.left) / 2,
          time: group.map(({ time }) => parseInt(time)),
          position: group.map(({ postion }) => postion),
        },
      ]
    }, [])

    return {
      pauses: groupedPauses,
      totalPausesCount: pauses.length,
      totalPausesTime: get(session, 'data.pauseTime'),
    }
    // eslint-disable-next-line
  }, [session])

  return (
    <Dialog open={!!session} onClose={onClose}>
      {session && (
        <div style={{ width: 600 }}>
          <Grid className="p-5" style={{ backgroundColor: 'var(--green)', color: '#fff' }}>
            <Grid container justifyContent="center" style={{ fontSize: 14 }}>
              {user.fullName}
            </Grid>
            <Grid className="mb-4" container justifyContent="center" style={{ fontSize: 24 }}>
              {session.productName}
            </Grid>
            <Grid container>
              <Grid item xs={6}>
                <Grid container justifyContent="center" style={{ fontSize: 12 }}>
                  Session start
                </Grid>
                <Grid container justifyContent="center">
                  {moment(session.startedAt).format(formats.dateAndTime)}
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container justifyContent="center" style={{ fontSize: 12 }}>
                  Session end
                </Grid>
                <Grid container justifyContent="center">
                  {moment(session.updatedAt).format(formats.dateAndTime)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid className="p-5" style={{ color: '#555' }}>
            <Grid container justifyContent="center" style={{ fontSize: 24 }}>
              Listening time: {formatSeconds(session.totalInteractionTime)}
            </Grid>
            <Grid container justifyContent="center" className="mb-5">
              Total time: {formatSeconds(session.totalTime)}
            </Grid>

            <div
              style={{
                height: 10,
                backgroundColor: '#eee',
                position: 'relative',
                marginTop: 50,
                marginBottom: 50,
              }}
            >
              {pauses.map((item) => (
                <Tooltip
                  title={
                    <>
                      <div>Position: {item.position.map((i) => formatSeconds(i)).join(', ')}</div>
                      <div>Pause time: {item.time.map((i) => formatSeconds(i)).join(', ')}</div>
                    </>
                  }
                  placement="bottom"
                >
                  <div
                    style={{
                      cursor: 'pointer',
                      position: 'absolute',
                      left: `${item.left}%`,
                      top: '50%',
                      zIndex: 2,
                      backgroundColor: 'var(--green)',
                      height: 25,
                      width: 25,
                      borderRadius: '50%',
                      color: '#fff',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transform: 'translate(-50%, -50%)',
                      fontSize: 8,
                      boxShadow: '1px 1px 5px 0px rgba(0,0,0,0.75)',
                    }}
                  >
                    {item.index}
                  </div>
                </Tooltip>
              ))}
              {((start, end) => (
                <>
                  <div
                    style={{
                      position: 'absolute',
                      left: `${p(start)}%`,
                      fontSize: 9,
                      transform: 'translate(-50%, -20px)',
                    }}
                  >
                    {formatSeconds(start)}
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      left: `${p(end)}%`,
                      fontSize: 9,
                      transform: 'translate(-50%, -20px)',
                    }}
                  >
                    {formatSeconds(end)}
                  </div>
                  <div
                    style={{
                      position: 'absolute',
                      height: 10,
                      backgroundColor: 'var(--green)',
                      left: `${p(start)}%`,
                      width: `${p(end) - p(start)}%`,
                    }}
                  />
                </>
              ))(get(session, 'data.states[0].position'), get(session, 'data.lastPosition'))}
            </div>

            <div>
              <div
                style={{
                  display: 'inline-block',
                  height: 30,
                  width: 30,
                  backgroundColor: 'var(--green)',
                  marginRight: 10,
                  borderRadius: '50%',
                }}
              />
              <div style={{ display: 'inline-block', fontSize: 12 }}>
                <div>Number of pauses: {totalPausesCount}</div>
                <div>
                  Total paused time: {totalPausesTime === 0 ? '0s' : formatSeconds(totalPausesTime)}
                </div>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </Dialog>
  )
}
