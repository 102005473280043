import React from 'react'
import { Grid, Card, CardHeader, CardContent, Typography } from 'components'
import makeStyles from '@mui/styles/makeStyles'
import WarningIcon from '@mui/icons-material/Warning'
import links from 'utils/constants/links'

const useStyles = makeStyles({
  card: {
    width: '100%',
    backgroundColor: '#edf2f7',
  },
})

export default function SSPHeadPhoneGuidelines({ gridProps }) {
  const classes = useStyles()

  return (
    <Grid item {...gridProps}>
      <Card
        data-test="dashboard-card-ssp-headphone-guidelines"
        className={`${classes.card} h-full p-4`}
        elevation={0}
      >
        <CardHeader
          avatar={<WarningIcon htmlColor="#0091bd" />}
          titleTypographyProps={{ variant: 'h5' }}
          title={'SSP Headphone Guidelines'}
        />
        <CardContent>
          <Typography variant="body2" gutterBottom className="py-1">
            Circumaural headphones are an important component of the successful delivery of the SSP.
            Click{' '}
            <a
              className="text-link text-semibold"
              target="_blank"
              rel="noopener noreferrer"
              href={links.support.sspHeadphonesUrl}
            >
              here
            </a>{' '}
            to learn more!
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  )
}
