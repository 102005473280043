import React from 'react'
import makeStyles from '@mui/styles/makeStyles'
import { Grid, Typography, Stepper, Step, StepLabel } from 'components'
import regulatingActivities from 'utils/constants/regulatingActivities'

const useStyles = makeStyles({
  root: {
    width: '100%',
    backgroundColor: '#FFF',
  },
  stepLabel: {
    minHeight: 48,
    display: 'flex',
    alignItems: 'flex-start',
  },
  icon: {
    display: 'block',
    width: '25px',
    margin: 'auto',
  },
})

export default ({ resource }) => {
  const classes = useStyles()
  const {
    activityName,
    activityVideoSrc,
    steps,
    rationale,
    additionalDescription,
  } = regulatingActivities.find(({ activityName }) => activityName === resource.title)

  let currentIconNumber = 1
  const renderSteps = steps.map((step) => {
    const icons = {
      leaf: <img className={classes.icon} src="/images/leaf.png" alt="" />,
      empty: <></>,
      default: null,
    }
    const label = typeof step === 'string' ? step : step.text

    if (step.title) {
      currentIconNumber = 1
      return {
        icon: <></>,
        label: (
          <Typography variant="body1" sx={{ fontWeight: 500 }}>
            {label}
          </Typography>
        ),
      }
    }
    return {
      icon: step.icon && icons[step.icon] ? icons[step.icon] : currentIconNumber++,
      label: <Typography variant="body1">{label}</Typography>,
    }
  })

  return (
    <Grid container spacing={2} pb="30px" m={0}>
      <Grid item xs={12} md={12}>
        <Typography variant="h4">{activityName}</Typography>
        <Typography variant="body2">{resource.shortDescription}</Typography>
        <Typography variant="body2" mb={4}>
          {additionalDescription}
        </Typography>
      </Grid>
      <Grid item xs={12} md={6}>
        <Stepper className={classes.root} orientation="vertical" connector={null}>
          {renderSteps.map(({ label, icon }, index) => (
            <Step key={index} active={true} className="pt-2 pb-2">
              <StepLabel className={classes.stepLabel} icon={icon}>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Grid>
      <Grid item xs={12} md={6} mt={2}>
        <iframe
          src={activityVideoSrc}
          width="100%"
          height="300px"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="focusActivityVideo"
        />
      </Grid>

      <Grid container direction="column" alignItems="flex-start" pt={5} pb={5}>
        <Typography variant="body2" mb={1}>
          <strong>Rationale</strong>
        </Typography>
        {rationale.map((option, key) => {
          return (
            <Typography key={key} variant="body2" mb={1}>
              {option}
            </Typography>
          )
        })}
      </Grid>
    </Grid>
  )
}
