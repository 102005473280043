/**
 * This component will house all the welcome cards
 */
import React from 'react'
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography as MuiTypography,
  Active,
  CardHeader,
  Stack,
} from 'components'
import { useSelector } from 'react-redux'
import { plural } from 'utils/client-pluralization'
import LINKS from 'utils/constants/links'
import AnimatedChatIcon from '../icons/AnimatedChatIcon'

const Typography = ({ children, ...props }) => {
  return (
    <MuiTypography sx={{ color: '#fff' }} variant="body2" {...props}>
      {children}
    </MuiTypography>
  )
}

export const WelcomeCard = ({ id, showUserWelcomeCall, dataTestLabel, title, children }) => {
  return (
    <Grid item xs={12} sm={4} lg={4} key={id}>
      <Card
        sx={{
          height: '100%',
          background: showUserWelcomeCall
            ? '#65A59B'
            : 'linear-gradient(90deg, rgba(38,70,83,1) 0%, rgba(42,157,143,1) 100%)',
        }}
        elevation={0}
        data-test={dataTestLabel}
      >
        <CardHeader
          title={title}
          titleTypographyProps={{ variant: 'h5' }}
          sx={{
            color: '#fff',
          }}
        />
        <CardContent data-test="dashboard-card-body2" sx={{ color: '#fff' }}>
          {children}
        </CardContent>
      </Card>
    </Grid>
  )
}

export const ProviderWelcomeCard = ({
  hasCompletedSspCertification,
  hasCompletedFocusCertification,
  sspInTrainingOnly,
  hasActiveSspSubscription,
  hasActiveFocusSubscription,
}) => {
  const { activeClients = 0, orgActiveClients, hasMultipleProviders } = useSelector(
    (state) => state.activeClients
  )
  const { showNewSubscriptionPlan } = useSelector((state) => state.ff)
  const { hasSspProducts } = useSelector((state) => state.ff)

  const showTrainingCompleteNoSubscriptionMessage =
    (hasCompletedSspCertification && !hasActiveSspSubscription) ||
    (hasCompletedFocusCertification && !hasActiveFocusSubscription)

  const headerText = showTrainingCompleteNoSubscriptionMessage
    ? 'Questions on next steps?'
    : "We're here to help!"

  return (
    <WelcomeCard id="welcome-card2" dataTestLabel="dashboard-card-welcome-card-provider">
      {hasSspProducts &&
        hasCompletedSspCertification &&
        !showNewSubscriptionPlan &&
        !sspInTrainingOnly && (
          <>
            <Typography>
              You have {activeClients} <Active /> client{plural(activeClients)}
            </Typography>
            {hasMultipleProviders && (
              <Typography>
                Your organization has {orgActiveClients} <Active /> client{plural(activeClients)}
              </Typography>
            )}
          </>
        )}
      <Stack alignItems="end" direction="row">
        <Typography variant="body1" fontWeight={600} pt={4}>
          {headerText}
        </Typography>
      </Stack>
      {showTrainingCompleteNoSubscriptionMessage && (
        <>
          <Typography>
            <a
              className="underline text-white font-semibold pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.postTrainingSales}
            >
              Schedule a short call
            </a>{' '}
            with our Programs Consultants to answer any remaining questions and ensure you have
            everything you need to get started with a subscription.
          </Typography>
        </>
      )}
      {!showTrainingCompleteNoSubscriptionMessage && (
        <>
          <Typography>
            Have questions, in need of resources, technical or clinical support?
          </Typography>
          <Typography>
            <a
              className="underline text-white font-semibold pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.youcanbookme}
            >
              Book a call
            </a>{' '}
            with our Client Success team or{' '}
            <a
              className="underline text-white pr-1 text-base"
              target="_blank"
              rel="noopener noreferrer"
              href={LINKS.support.homepageUrl}
            >
              access our Help Center
            </a>
            .
          </Typography>
        </>
      )}
    </WelcomeCard>
  )
}

export const ProviderWelcomeCardWithWelcomeMessage = () => {
  return (
    <WelcomeCard
      id="welcome-card2"
      dataTestLabel="dashboard-card-welcome-card-provider"
      showUserWelcomeCall
    >
      <Stack alignItems="center" justifyContent="center" direction="column">
        <Stack
          alignItems="end"
          direction="row"
          sx={{ marginLeft: '-55px' }} // move icon so Welcome text is centered
        >
          <AnimatedChatIcon />
          <Typography variant="h3" fontWeight={600} pt={4}>
            Welcome!
          </Typography>
        </Stack>
        <Typography align="center" pr={6} pl={6} pt={2}>
          Book your free <strong>Welcome Call</strong> now to fast-track your success, ensuring you
          get the support you need right from the start!
        </Typography>
        <Stack pt={5} pb={2} direction="column" justifyContent="center" alignItems="center">
          <Button
            variant="contained"
            color="pink"
            sx={{ width: '250px' }}
            onClick={() => {
              window.open(LINKS.support.welcomeCall, '_blank', 'noopener,noreferrer')
            }}
          >
            <Typography variant="button">Book My Welcome Call</Typography>
          </Button>
        </Stack>
      </Stack>
    </WelcomeCard>
  )
}

export const ClientWelcomeCard = () => {
  return (
    <WelcomeCard id="welcome-card2" dataTestLabel="dashboard-card-welcome-card-provider">
      <Typography>Welcome to MyUnyte, your access to all things for Unyte Health.</Typography>
    </WelcomeCard>
  )
}
