import { removeHubspotScripts } from 'components/header/components/HubSpotWidget'
import { useState, useEffect, Dispatch, SetStateAction } from 'react'

// Hook with explicit return type
export default function useScript(src: string): [string, Dispatch<SetStateAction<boolean>>] {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState<string>(src ? 'loading' : 'idle')
  // New state to manage loading or unloading the script
  const [load, setLoad] = useState<boolean>(false)

  useEffect(() => {
    if (!src) {
      setStatus('idle')
      return
    }

    let script = document.querySelector(`script[src="${src}"]`) as HTMLScriptElement
    if (!script && load) {
      script = document.createElement('script') as HTMLScriptElement
      script.src = src
      script.async = true
      script.setAttribute('data-status', 'loading')
      document.body.appendChild(script)

      const setAttributeFromEvent = (event: Event) => {
        script!.setAttribute('data-status', event.type === 'load' ? 'ready' : 'error')
      }

      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else if (script) {
      setStatus(script.getAttribute('data-status')!)
    }

    const setStateFromEvent = (event: Event) => {
      setStatus(event.type === 'load' ? 'ready' : 'error')
    }

    if (script) {
      script.addEventListener('load', setStateFromEvent)
      script.addEventListener('error', setStateFromEvent)
    }

    return () => {
      if (script) {
        script.removeEventListener('load', setStateFromEvent)
        script.removeEventListener('error', setStateFromEvent)
        removeHubspotScripts()
      }
    }
  }, [src, load]) // Re-run effect if script src or load state changes

  // Return both status and the setLoad function so the consumer can control loading/unloading
  return [status, setLoad]
}
