import React from 'react'
import { Grid, Button } from 'components'

import CardWithBg from 'components/CardWithBg'

export default () => (
  <CardWithBg>
    <div style={{ fontWeight: 300, textAlign: 'center', fontSize: 24, marginBottom: 5 }}>
      Sorry, page not found.
    </div>
    <div style={{ fontWeight: 300, textAlign: 'center', fontSize: 16, marginBottom: 15 }}>
      Let's take a breath and enjoy the view.
    </div>

    <Grid container justifyContent="center" className="my-5">
      <a href="/">
        <Button style={{ margin: 'auto', display: 'inline-block' }} color="primary">
          Open home page
        </Button>
      </a>
    </Grid>
  </CardWithBg>
)
