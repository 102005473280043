import React from 'react'
import {
  Box,
  Card,
  Chip,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from 'components'
import ChipList from './ChipList'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants/formats'
import formatAddress from 'utils/formatAddress'

const CERTIFICATION_MAP = {
  ssp: 'SSP',
  focus: 'ILS',
}

export default function ConnecteeSubmissionFields({ connecteeMatch }) {
  const CONNECTEE_SUBMISSION_FIELDS = [
    {
      type: 'chips',
      key:
        connecteeMatch.connecteeSubmission.providerClinicalSpecialties.length === 1
          ? 'Primary Reason for Seeking Support'
          : 'Reasons for Seeking Support',
      value: connecteeMatch.connecteeSubmission.providerClinicalSpecialties,
    },
    {
      key: 'Requested Population Specialty',
      value: connecteeMatch.connecteeSubmission.providerAgeGroups,
    },
    {
      key: 'Requested Delivery Model',
      value: connecteeMatch.connecteeSubmission.providerDeliveryOptions,
    },
    {
      key: 'Preferred Provider Modalities',
      value: connecteeMatch.connecteeSubmission.providerModalities,
    },
    {
      key: 'Preferred Provider Location',
      value: formatAddress(connecteeMatch.connecteeSubmission),
    },
    {
      key: 'Preferred Provider Certification',
      value: CERTIFICATION_MAP[connecteeMatch.connecteeSubmission.providerCertifications],
    },
    { key: 'Preferred Provider Gender', value: connecteeMatch.connecteeSubmission.providerGender },
    {
      key: 'Preferred Provider Language',
      value: connecteeMatch.connecteeSubmission.providerLanguage,
    },
    { key: 'Comments Shared by Client', value: connecteeMatch.connecteeSubmission.additionalInfo },
  ].filter(({ value }) => value)

  return (
    <Card>
      <Stack direction="column" padding={2}>
        <Stack mt={1} spacing={1} direction="row" justifyContent="space-between">
          <Typography variant="h5">Information submitted by Client</Typography>
          <Stack direction="column" alignItems="flex-end">
            <Typography variant="body2" color="#b1b1b1">
              Received: {format(new Date(connecteeMatch.createdAt), DATE_FORMATS.dateAndTime)}
            </Typography>
            <Typography variant="body2" color="#b1b1b1" fontSize="0.6rem">
              Reference ID: {connecteeMatch.connecteeSubmission.uuid}
            </Typography>
          </Stack>
        </Stack>
        <TableContainer>
          <Table>
            <TableBody>
              {CONNECTEE_SUBMISSION_FIELDS.map(({ type, key, value }) => {
                return (
                  <TableRow key={key}>
                    <TableCell width="40%">
                      <Box>
                        <Typography fontWeight={400}>{key}:</Typography>
                      </Box>
                    </TableCell>
                    <TableCell width="60%">
                      <Box>
                        {type === 'chips' ? (
                          <ChipList labels={value} />
                        ) : (
                          <Chip
                            sx={{
                              height: 'auto',
                              '& .MuiChip-label': {
                                display: 'block',
                                whiteSpace: 'normal',
                                marginBlock: '7px',
                                lineHeight: '20px',
                              },
                            }}
                            label={value}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Card>
  )
}
