import React, { useState } from 'react'

import makeStyles from '@mui/styles/makeStyles'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import Typography from '@mui/material/Typography'
import DATE_FORMAT from 'utils/constants/formats'
import moment from 'utils/moment'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import { TableContainer, Table, TableRow, TableCell, TableHead, TableBody, Paper } from 'components'

const useStyles = makeStyles((theme) => ({
  paperContent: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  content: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    textAlign: 'center',
  },
  row: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
}))

// Format the billing or shipping address based on which values are defined
const formatAddress = ({
  name = '',
  company,
  street,
  city,
  region,
  postcode,
  countryId,
  telephone,
  fax,
}) => {
  return (
    <>
      <Typography>{name}</Typography>
      <Typography>{company}</Typography>
      <Typography>{street}</Typography>
      <Typography>{[city, region, postcode].join(', ')}</Typography>
      <Typography>{countryId}</Typography>
      <Typography>{telephone}</Typography>
      <Typography>{fax}</Typography>
    </>
  )
}

function Row({ order }) {
  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const returnStatement = (
    <>
      <TableRow className={classes.row}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{order.orderNumber}</TableCell>
        <TableCell>{moment(order.orderDate).format(DATE_FORMAT.dateAndTime)}</TableCell>
        <TableCell>{order.status}</TableCell>
        <TableCell>{order.customer.fullName}</TableCell>
        <TableCell>{order.customer.email}</TableCell>
        <TableCell>{order.orderTotals.grandTotalInclTax}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h5" gutterBottom component="div">
                Additional Information
              </Typography>
              <Table size="small" aria-label="additional order information">
                <TableHead>
                  <TableRow>
                    <TableCell>Billing Address</TableCell>
                    <TableCell>Shipping Address</TableCell>
                    <TableCell>Items Ordered</TableCell>
                    <TableCell>Order Totals</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell>
                    {order.billingAddress.exists && formatAddress(order.billingAddress)}
                  </TableCell>
                  <TableCell>
                    {order.shippingAddress.exists && formatAddress(order.shippingAddress)}
                  </TableCell>
                  <TableCell>
                    {order.items.map((item) => (
                      <>
                        <TableRow>Name: {item['Name']}</TableRow>
                        <TableRow>SKU: {item['SKU']}</TableRow>
                        <TableRow>Status: {item['Status']}</TableRow>
                        <TableRow>Original Price: {item['Original_Price']}</TableRow>
                        <TableRow>Price: {item['Price']}</TableRow>
                        <TableRow>Quantity Ordered: {item['Qty']['Ordered']}</TableRow>
                        <TableRow>Quantity Invoiced: {item['Qty']['Invoiced']}</TableRow>
                        <br />
                      </>
                    ))}
                  </TableCell>
                  <TableCell>
                    {[
                      { title: 'Subtotal:', value: order.orderTotals.subtotal },
                      {
                        title: 'Shipping & Handling:',
                        value: order.shippingAddress.exists
                          ? order.orderTotals.shippingAmount
                          : null,
                      },
                      { title: 'Discount:', value: order.orderTotals.discount },
                      { title: 'Internal Credit:', value: order.orderTotals.internalCredit },
                      {
                        title: 'Grand Total (Excl. Tax):',
                        value: order.orderTotals.grandTotalExclTax,
                      },
                      { title: 'Tax:', value: order.orderTotals.taxAmount },
                      {
                        title: 'Grand Total (Incl. Tax):',
                        value: order.orderTotals.grandTotalInclTax,
                      },
                      { title: 'Total Paid:', value: order.orderTotals.totalPaid },
                      { title: 'Total Refunded:', value: order.orderTotals.totalRefunded },
                      { title: 'Total Due:', value: order.orderTotals.totalDue },
                    ].map(
                      (row) =>
                        row.value && (
                          <TableRow key={row.title}>
                            <TableCell>{row.title}</TableCell>
                            <TableCell align="right">{row.value}</TableCell>
                          </TableRow>
                        )
                    )}
                  </TableCell>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
  return returnStatement
}

export default function MagentoOrders({ orders }) {
  const classes = useStyles()

  return (
    <Paper elevation={6} className={classes.paperContent}>
      <div className={classes.content}>
        {orders.length > 0 ? (
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Order Number</TableCell>
                  <TableCell>Order Date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Customer Name</TableCell>
                  <TableCell>Customer Email</TableCell>
                  <TableCell>Grand Total (Incl. Tax)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders.map((order) => (
                  <Row key={order.orderNumber} order={order} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p>NO ITEMS</p>
        )}
      </div>
    </Paper>
  )
}
