import React from 'react'

export default ({ width = 55, height = 55 }) => {
  return (
    <svg xmlns="SVG namespace " width={width} height={height} viewBox="0 0 24 24">
      <circle cx="12" cy="12" r="0" fill="#D43790">
        <animate
          attributeName="r"
          calcMode="spline"
          dur="2.4s"
          keySplines=".52,.6,.25,.99"
          repeatCount="indefinite"
          values="0;11"
        />
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="2.4s"
          keySplines=".52,.6,.25,.99"
          repeatCount="indefinite"
          values="1;0"
        />
      </circle>
    </svg>
  )
}
