/**
 * this is the resource wrapper
 */
import React, { useRef, useState } from 'react'
import {
  Outlet,
  useParams,
  matchPath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useGetUserWithoutRefresh } from '../../utils/hooks/useGetUser'
import { useDispatch, useSelector } from 'react-redux'
import { get } from 'lodash'
import NewUpdates from '../../components/NewUpdates'
import { RESOURCE_INTRO_VIDEO } from '../assessments/utils/constants'
import { setOpenIntroVideo } from '../../store/modules/resources'
import { Container, Stack } from '@mui/material'
import ROLES from 'utils/constants/roles'
import { includesSome } from 'utils/includes'
import MainLayout from 'components/containers/main/Main'
import LoadingPage from 'components/LoadingPage'
import CloseSnackbarAction from 'components/CloseSnackbarAction'
import { useSnackbar } from 'notistack'

const TAGS = [
  {
    cardName: 'New',
    filterName: 'New',
    displayOnCard: false,
    chipColor: '#FFA3A3',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources' }, pathname),
  },
  {
    cardName: 'Getting Started',
    filterName: 'Getting Started',
    displayOnCard: false,
    chipColor: 1,
    isEnabled: ({ pathname }) => matchPath({ path: '/resources' }, pathname),
  },
  {
    cardName: 'Favourites',
    filterName: 'Favourites',
    displayOnCard: true,
    chipColor: '#F9A8D4',
    isEnabled: () => true,
  },
  {
    cardName: 'ILS',
    filterName: 'ILS',
    displayOnCard: true,
    chipColor: '#93C5FD',
    isEnabled: ({ pathname, hasFocusCertification, userRoles, hasFocusProducts }) =>
      matchPath({ path: '/resources' }, pathname) &&
      (hasFocusCertification ||
        (userRoles && includesSome(userRoles, ROLES.CLIENT) && hasFocusProducts)),
  },
  {
    cardName: 'SSP',
    filterName: 'SSP',
    displayOnCard: true,
    chipColor: '#6EE7B7',
    isEnabled: ({ pathname, hasSspCertification, userRoles, hasSspProducts }) =>
      matchPath({ path: '/resources' }, pathname) &&
      (hasSspCertification ||
        (userRoles && includesSome(userRoles, ROLES.CLIENT) && hasSspProducts)),
  },
  {
    cardName: 'Assessment',
    filterName: 'Assessments',
    displayOnCard: true,
    chipColor: '#E4BE9E',
    isEnabled: ({ pathname, hasSspCertification, userRoles, hasSspProducts }) =>
      matchPath({ path: '/resources' }, pathname) &&
      (hasSspCertification ||
        (userRoles && includesSome(userRoles, ROLES.CLIENT) && hasSspProducts)),
  },
  {
    cardName: 'VoicePro',
    filterName: 'VoicePro',
    displayOnCard: true,
    chipColor: '#EB9486',
    isEnabled: ({ pathname, hasVoiceProCertification }) =>
      matchPath({ path: '/resources' }, pathname) && hasVoiceProCertification,
  },
  {
    cardName: 'Video',
    filterName: 'Videos',
    displayOnCard: false,
    chipColor: '#B7B7A4',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources' }, pathname),
  },
  {
    cardName: 'Manual',
    filterName: 'Manuals',
    displayOnCard: false,
    chipColor: '#C0FDFF',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources' }, pathname),
  },
  {
    cardName: 'Provider Toolkit',
    filterName: 'Provider Toolkit',
    displayOnCard: true,
    chipColor: '#EB9486',
    isEnabled: ({ pathname, userRoles }) =>
      matchPath({ path: '/resources' }, pathname) && !includesSome(userRoles, ROLES.CLIENT),
  },
  {
    cardName: 'Delivery',
    filterName: 'Delivery',
    displayOnCard: true,
    chipColor: '#A5B4FC',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources' }, pathname),
  },
  {
    cardName: 'Client Education',
    filterName: 'Client Education',
    displayOnCard: true,
    chipColor: '#93C5FD',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/provider-toolkit' }, pathname),
  },
  {
    cardName: 'Marketing',
    filterName: 'Marketing',
    displayOnCard: true,
    chipColor: '#6EE7B7',
    isEnabled: ({ pathname, userRoles }) =>
      matchPath({ path: '/resources' }, pathname) &&
      !(userRoles && includesSome(userRoles, ROLES.CLIENT)),
  },
  {
    cardName: 'Pricing',
    filterName: 'Pricing',
    displayOnCard: true,
    chipColor: '#FCD34D',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/provider-toolkit' }, pathname),
  },
  {
    cardName: 'Tech Support',
    filterName: 'Tech Support',
    displayOnCard: true,
    chipColor: '#EB9486',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/provider-toolkit' }, pathname),
  },
  {
    cardName: 'ILS Activity',
    filterName: 'ILS Activity',
    displayOnCard: true,
    chipColor: '#B7B7A4',
    isEnabled: ({ pathname, hasFocusCertification, clientHasFocus }) =>
      matchPath({ path: '/resources/activity-library' }, pathname) &&
      (hasFocusCertification || clientHasFocus),
  },
  {
    cardName: 'Regulating Activity',
    filterName: 'Regulating Activity',
    displayOnCard: true,
    chipColor: '#C0FDFF',
    isEnabled: ({ pathname, hasFocusCertification, clientHasFocus }) =>
      matchPath({ path: '/resources/activity-library' }, pathname) &&
      (hasFocusCertification || clientHasFocus),
  },
  {
    cardName: 'Regulation',
    filterName: 'Regulation',
    displayOnCard: true,
    chipColor: '#A5B4FC',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Gross Motor',
    filterName: 'Gross Motor',
    displayOnCard: true,
    chipColor: '#93C5FD',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Fine Motor',
    filterName: 'Fine Motor',
    displayOnCard: true,
    chipColor: '#6EE7B7',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Visual Motor',
    filterName: 'Visual Motor',
    displayOnCard: true,
    chipColor: '#FCD34D',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Early Development',
    filterName: 'Early Development',
    displayOnCard: true,
    chipColor: '#EB9486',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Breathing',
    filterName: 'Breathing',
    displayOnCard: true,
    chipColor: '#F3E9C9',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Mindfulness',
    filterName: 'Mindfulness',
    displayOnCard: true,
    chipColor: '#F6D7E9',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Movement',
    filterName: 'Movement',
    displayOnCard: true,
    chipColor: '#EEF7F3',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
  {
    cardName: 'Vocalization',
    filterName: 'Vocalization',
    displayOnCard: true,
    chipColor: '#ACD8C4',
    isEnabled: ({ pathname }) => matchPath({ path: '/resources/activity-library' }, pathname),
  },
]

function Resources() {
  const focusRefs = useRef([])
  const params = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const { tag } = params
  const dispatch = useDispatch()
  const location = useLocation()
  const currentUserRoles = useSelector((state) => get(state, 'auth.user.roles', []))
  const isClient = currentUserRoles.some((role) => ROLES.CLIENT_ROLES.includes(role))
  const { hasFocusProducts, hasSspProducts } = useSelector((state) => state.ff)

  const {
    selectedUser: {
      hasSspCertification,
      hasFocusCertification,
      hasVoiceProCertification,
      hasAssessmentProducts,
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
      userRoles,
    },
    loading,
  } = useGetUserWithoutRefresh()

  // variables for new updates
  const userId = useSelector((state) => get(state, 'auth.user.id', ''))
  const override = useSelector((state) => get(state, 'resources.openIntroVideo', ''))
  const overrideClose = () => {
    dispatch(setOpenIntroVideo(false))
  }

  const hasPaidSspSubscription = useSelector((state) =>
    get(state, 'organization.hasPaidSspSubscription', false)
  )

  const hasPaidFocusSubscription = useSelector((state) =>
    get(state, 'organization.hasPaidFocusSubscription', false)
  )

  const mainLayoutTabs = [
    {
      id: null,
      pageTitle: 'Resources - General',
      text: 'General Resources',
      url: '/resources',
      isActive: (pathname) =>
        matchPath({ path: '/resources/*' }, pathname) &&
        !matchPath({ path: '/resources/activity-library/*' }, pathname),
    },
    {
      id: 'activity-library',
      pageTitle: 'Resources - Activity Library',
      text: 'Activity Library',
      url: '/resources/activity-library',
      isActive: (pathname) => matchPath({ path: '/resources/activity-library/*' }, pathname),
    },
  ]

  // if the current tag does not exist, move user to general resource tab..
  if (tag && !mainLayoutTabs.map(({ id }) => id).includes(tag)) {
    navigate('/resources')
  }

  const products = useSelector((state) => get(state, 'seats.products', []))
  const clientHasFocus = products.find(
    ({ category, isEnabled }) => isEnabled && category === 'focus'
  )

  const filteredTags = TAGS.filter(({ isEnabled }) =>
    isEnabled({
      pathname: location.pathname,
      userRoles,
      hasSspCertification,
      hasFocusCertification,
      hasVoiceProCertification,
      hasFocusProducts,
      hasSspProducts,
      clientHasFocus,
    })
  )

  // clients without any products are not allowed to enter this page..
  if (!loading && isClient && !(hasSspProducts || hasFocusProducts)) {
    enqueueSnackbar('Access to this page is not authorized', {
      variant: 'error',
      action: CloseSnackbarAction,
    })
    navigate('/')
  }
  const [cardIndex, setCardIndex] = useState()
  const [keywords, _setKeywords] = useState('')

  // also add to params
  const [searchParams, setSearchParams] = useSearchParams()
  const setKeywords = (searchInput) => {
    _setKeywords(searchInput)
    const newSearchParams = new URLSearchParams(searchParams)
    if (searchInput) {
      newSearchParams.set('q', searchInput)
    } else {
      newSearchParams.delete('q')
    }
    setSearchParams(newSearchParams)
  }

  return (
    <LoadingPage loading={loading} text="loading...">
      <MainLayout title="Resources - General" tabs={mainLayoutTabs}>
        {process.env.REACT_APP_FF_HIDE_POPUPS !== 'true' && !isClient && (
          <NewUpdates
            title="Welcome to Resources"
            src={RESOURCE_INTRO_VIDEO}
            type={`resources${userId}`}
            override={override}
            overrideClose={overrideClose}
          />
        )}
        <Container>
          <Stack justifyContent="center" alignItems="stretch">
            <Outlet
              context={{
                loading,
                filteredTags,
                chipTags: TAGS,
                hasSspCertification,
                hasCompletedSspCertification,
                hasSspProducts,
                hasPaidSspSubscription,
                hasFocusCertification,
                hasCompletedFocusCertification,
                hasFocusProducts,
                hasPaidFocusSubscription,
                clientHasFocus,
                hasAssessmentProducts,
                hasVoiceProCertification,
                userRoles,
                focusRefs,
                cardIndex,
                setCardIndex,
                keywords,
                setKeywords,
              }}
            />
          </Stack>
        </Container>
      </MainLayout>
    </LoadingPage>
  )
}

export default Resources
