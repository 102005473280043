import React from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useSnackbar } from 'notistack'
import { useMutation } from 'utils/apollo'
import { gql } from '@apollo/client'

import { Button, TextField, FormHelperText, Typography, Stack } from 'components'
import useForm from 'components/form/useForm'
import { useNavigate } from 'react-router'
import { ShowProviderProfileWrapper } from './ProfessionalCredentials'
import CloseSnackbarAction from 'components/CloseSnackbarAction'

const CHANGE_EMAIL_REQUEST = gql`
  mutation changeEmailRequest($newEmail: String!) {
    changeEmailRequest(newEmail: $newEmail) {
      newEmail
    }
  }
`

export default function EditContact() {
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()

  const user = useSelector((state) => get(state, 'auth.user'))
  const [changeEmailRequest] = useMutation(CHANGE_EMAIL_REQUEST, {
    async update(cache) {
      cache.reset()
      enqueueSnackbar(
        'Email change request received. Please follow the instructions in the email sent to your new address.',
        { variant: 'success', action: CloseSnackbarAction }
      )
    },
  })
  const { form, setFormValue, onSubmit, pending, errors, isValid, reset } = useForm({
    data: {
      currentEmail: user.email,
      newEmail: '',
      newEmailConfirmed: '',
    },
    validation: {
      newEmail: {
        required: { msg: 'New email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
        notEqual: {
          args: { field: 'currentEmail' },
          msg: 'New email must be distinct from old email',
        },
      },
      newEmailConfirmed: {
        required: { msg: 'New email is required.' },
        email: { msg: 'Please enter a valid email address.' },
        len: { args: [null, 100], msg: 'Email must be no longer than 100 characters.' },
        equal: { args: { field: 'newEmail' }, msg: 'New emails must match' },
      },
    },
    onSubmit: async () => {
      await changeEmailRequest({
        variables: {
          newEmail: form.newEmail,
        },
      })
      reset()
      navigate(-1)
    },
  })

  return (
    <ShowProviderProfileWrapper>
      <Stack direction="column" spacing={1} sx={{ p: 5 }}>
        <Typography gutterBottom variant="body2" className="pt-2">
          Enter the new email address you would like to associate with your MyUnyte account.
        </Typography>{' '}
        {errors.none && (
          <FormHelperText error className="py-2">
            {errors.none}
          </FormHelperText>
        )}
        <TextField
          disabled
          style={{ width: '60%' }}
          id="currentEmail"
          label="Current Email"
          className="mb-3"
          value={form.currentEmail}
        />
        <TextField
          style={{ width: '60%' }}
          label="New Email*"
          className="mb-3"
          value={form.newEmail}
          onChange={setFormValue('newEmail')}
          error={errors.newEmail}
        />
        <TextField
          style={{ width: '60%' }}
          label="Confirm New Email*"
          className="mb-3"
          value={form.newEmailConfirmed}
          onChange={setFormValue('newEmailConfirmed')}
          error={errors.newEmailConfirmed}
        />
        <div className="py-5">
          <Typography gutterBottom variant="body2" className="pt-2">
            Please note: you will receive an email at your new address with further instructions on
            how to complete this change.
          </Typography>{' '}
        </div>
        <Button
          disabled={!isValid}
          type="submit"
          color="primary"
          loading={pending}
          onClick={() => onSubmit()}
        >
          Request Email Change
        </Button>
      </Stack>
    </ShowProviderProfileWrapper>
  )
}
