import { React, useContext, useState } from 'react'

import {
  Divider,
  FormControlLabel,
  Card as MuiCard,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { get } from 'lodash'
import { format } from 'date-fns'
import { DATE_FORMATS } from 'utils/constants/formats'
import { ProviderProfileContext } from './MyProfile'
import RemoteDeliveryRestriction from './components/RemoteDeliveryRestriction'
import { useOnValueChange } from 'utils/hooks/useOnValueChange'
import RadioGroupMessage from './components/radio-group-message'

export function RemoteRestrictionsList() {
  // setting wizard state will enable update mode..
  const {
    form,
    handleLists,
    handleSave,
    wizardState,
    _loading,
    setWizardState,
    providerProfileInfo,
  } = useContext(ProviderProfileContext)
  const deliveryModels = get(form, 'deliveryModels', [])
  const _lastUpdatedAt = get(form, 'remoteRestrictionsUpdatedAt', null)
  const _remoteRestrictions = get(form, 'remoteRestrictions', [])
  const _selectedNoAt = get(form, 'remoteRestrictionsSelectedNoAt', null)
  const providerProfileRemoteRestrictionsLastUpdatedAt = get(
    providerProfileInfo,
    'remoteRestrictionUpdatedAt',
    null
  )

  // states
  const [worksRemote, setWorksRemote] = useState(null)
  const [remoteRestrictions, setRemoteRestrictions] = useState([])
  const [lastUpdatedAt, setLastUpdatedAt] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)

  useOnValueChange(
    JSON.stringify({
      deliveryModels,
      _lastUpdatedAt,
      _remoteRestrictions,
      _selectedNoAt,
      _loading,
      wizardState,
      providerProfileRemoteRestrictionsLastUpdatedAt,
    }),
    () => {
      if (_loading) {
        return
      }

      setRemoteRestrictions(_remoteRestrictions)
      setLastUpdatedAt(_lastUpdatedAt || providerProfileRemoteRestrictionsLastUpdatedAt)

      if (
        ((_lastUpdatedAt || providerProfileRemoteRestrictionsLastUpdatedAt) &&
          worksRemote !== 'Yes') ||
        !wizardState
      ) {
        if (deliveryModels.includes('Remote') && _remoteRestrictions.length > 0) {
          handleRadioGroup('hook')({ target: { value: 'Yes' } })
        } else if (_selectedNoAt && _remoteRestrictions.length === 0) {
          handleRadioGroup('hook')({ target: { value: 'No' } })
        } else {
          setWorksRemote(null)
        }
      }
    }
  )

  /**
   * from: "form" | "hook"
   */
  const handleRadioGroup = (from) => async (event) => {
    setWorksRemote(event.target.value)
    if (event.target.value === 'Yes') {
      if (from === 'form') {
        // note: the open suffix is NEW
        setWizardState('REMOTE_DELIVERY_INFO/EDIT_0/OPEN')
        setIsEditMode(true)
      }
    } else if (event.target.value === 'No') {
      // prevents updating on load
      if (from === 'form') {
        const newDeliveryModels = form.deliveryModels.filter((key) => key !== 'Remote')
        const currentTime = new Date()
        await handleLists('deliveryModels')('', newDeliveryModels)
        await handleSave({
          remoteRestrictions: [],
          deliveryModels: newDeliveryModels,
          remoteRestrictionsSelectedNoAt: currentTime.toISOString(),
        })
      }
    }
  }

  const worksRemoteContent =
    worksRemote === 'Yes' ? (
      'I currently work with clients remotely.'
    ) : (
      <>
        I do <span className="underline">not</span> currently work with clients remotely.
      </>
    )

  const [currentIdx, setCurrentIdx] = useState()

  return (
    <MuiCard variant="outlined" sx={{ pb: 4 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" m={2} p={2}>
        <Typography id="remote-delivery-restrictions-info" variant="h4">
          Remote Delivery Info
        </Typography>
        {lastUpdatedAt && (
          <Typography
            id="remote-restrictions-last-updated-at"
            variant="caption"
            sx={{ color: 'gray !important' }}
          >
            Last Updated: {format(new Date(lastUpdatedAt), DATE_FORMATS.dateAndTime)}
          </Typography>
        )}
      </Stack>

      <Stack direction="column" mx={4}>
        <Typography variant="body2">Do you work with clients remotely?</Typography>
        <Stack direction="row" spacing={2} alignItems="center" my={1}>
          <RadioGroup
            row
            name="is-working-remotely-radio-buttons-group"
            value={worksRemote}
            onChange={handleRadioGroup('form')}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>

          {worksRemote && <RadioGroupMessage content={worksRemoteContent} />}
        </Stack>
      </Stack>

      {worksRemote === 'Yes' && (
        <Stack direction="column" mx={2} p={2}>
          {!isEditMode && !remoteRestrictions.length && (
            <Typography variant="subtitle1" sx={{ fontWeight: 600, color: 'rgba(0, 0, 0, 0.87)' }}>
              Specify any location restrictions for remote services, such as limited licensing to
              certain states/provinces/regions.
            </Typography>
          )}
          {!isEditMode && remoteRestrictions.length > 0 && (
            <Typography variant="body2" component="h6" sx={{ fontWeight: '500 !important' }} pb={3}>
              Remote delivery area/s:
            </Typography>
          )}

          <Stack
            divider={<Divider />}
            spacing={remoteRestrictions.length > 1 && !isEditMode ? 3 : 2}
          >
            {// do not show if we're in edit mode, because we are using bottom component
            remoteRestrictions.map((restriction, idx) => {
              if (idx === currentIdx && wizardState) {
                return null
              }
              return (
                <RemoteDeliveryRestriction
                  idx={idx}
                  restriction={restriction}
                  setCurrentIdx={setCurrentIdx}
                  currentIdx={currentIdx}
                />
              )
            })}
            <RemoteDeliveryRestriction
              idx={remoteRestrictions.length}
              restriction={get(form, `remoteRestrictions[${currentIdx}]`, {})}
              setCurrentIdx={setCurrentIdx}
              currentIdx={currentIdx}
            />
          </Stack>
        </Stack>
      )}
    </MuiCard>
  )
}
