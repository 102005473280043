import React from 'react'
import { Button, CardActions, CardContent, Divider, Stack } from '@mui/material'
import { useFormContext } from 'react-hook-form'
import { CardContentItem, PreviewCard } from '../components/preview-card'
import { DEMOGRAPHIC_TOOLTIP_MESSAGE } from '../(basic-info)/basic-info-constants'


export const PreviewDemographicInfo: React.FC = () => {
  const { setValue, watch } = useFormContext()

  const handleEditDemographicInfo = () => {
    setValue('viewState', 'EDIT')
  }

  return (
    <PreviewCard
      title="Demographic Info"
      tooltipTitle={DEMOGRAPHIC_TOOLTIP_MESSAGE}
    >
      <CardContent>
        <Stack spacing={2} divider={<Divider />}>
          <CardContentItem label="Age" value={watch('age') || ''} />
          <CardContentItem label="Gender" value={watch('gender')} />
          <CardContentItem label="Country" value={watch('country')} />
        </Stack>
      </CardContent>
      <CardActions>
        <Button onClick={handleEditDemographicInfo}>Edit Demographic Info</Button>
      </CardActions>
    </PreviewCard>
  )
}
