const SET_BOOKMARK = 'RESOURCES/SET_BOOKMARK'
const SET_OPEN_INTRO_VIDEO = 'RESOURCES/SET_OPEN_INTRO_VIDEO'

const initialState = {
  openIntroVideo: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_OPEN_INTRO_VIDEO:
      return {
        ...state,
        openIntroVideo: action.payload,
      }
    case SET_BOOKMARK:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export function setOpenIntroVideo(payload) {
  return {
    type: SET_OPEN_INTRO_VIDEO,
    payload,
  }
}

export function setBookmark(payload) {
  return {
    type: SET_BOOKMARK,
    payload,
  }
}
