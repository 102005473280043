import React from 'react'
import { Grid } from 'components'
import Sessions from './sessions/Sessions'

export default function SessionsChart({ user }) {
  return (
    <Grid
      data-test="dashboard-card-sessions-chart"
      item
      xs={12}
      sx={{ overflow: 'hidden', p: 2, maxWidth: '95vw !important' }}
    >
      <Sessions user={user} />
    </Grid>
  )
}
