import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { store } from 'store'
import { logout } from 'store/modules/auth'
import { setNotifications } from 'store/modules/app'

import { Auth } from 'aws-amplify'

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
})

const authLink = setContext(async (_, { headers }) => {
  try {
    const currentSession = await Auth.currentSession()
    const accessToken = currentSession.getIdToken()
    const token = accessToken.getJwtToken()

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
      },
    }
  } catch (err) {
    return {
      headers,
    }
  }
})

const logoutLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError && networkError.statusCode > 500) {
    store.dispatch(setNotifications(['Server error, try again']))
  }

  if (networkError && networkError.statusCode === 401) {
    store.dispatch(logout())
  }

  if (graphQLErrors?.some(({ message }) => message === 'Not Authorized: user was disabled')) {
    store.dispatch(logout())
  }
})

export const client = new ApolloClient({
  // link: authLink.concat(httpLink),
  link: ApolloLink.from([authLink, logoutLink, httpLink]),
  cache: new InMemoryCache(),
})

export function apolloFetch(data) {
  return client.query(data)
}

export function apolloMutate(data) {
  return client.mutate(data)
}
