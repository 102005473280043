import React from 'react'
import MainLayout from 'components/containers/main/Main'
import { Container } from 'components'
import { useSelector } from 'react-redux'
import { BILLING_TABS } from './tabs'
import { useGetUserWithoutRefresh } from 'utils/hooks/useGetUser'
import { HowClientLicensesWorkMessage } from 'views/users/components/modals/content/HowClientLicensesWorkMessage'
import { multipleProductsText } from 'utils/multipleProductsText'

export default function HowClientLicensesWork() {
  const {
    hasSspProducts,
    hasRrpProducts,
    hasFocusProducts,
    showBillingHistory,
    showNewSSPSubscriptionPlan,
    showNewFocusSubscriptionPlan,
  } = useSelector((state) => state.ff)
  const {
    selectedUser: {
      hasCompletedFocusCertification,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
    },
  } = useGetUserWithoutRefresh()

  const tabs = BILLING_TABS.filter(({ isEnabled }) =>
    isEnabled({
      showBillingHistory,
      hasSspProducts,
      hasRrpProducts,
      hasFocusProducts,
      showNewSSPSubscriptionPlan,
      showNewFocusSubscriptionPlan,
      hasCompletedSspCertification,
      hasCompletedRrpCertification,
      hasCompletedFocusCertification,
    })
  )

  const { separatedByOr } = multipleProductsText({
    hasSspProducts,
    hasFocusProducts,
    hasRrpProducts,
  })

  return (
    <MainLayout title="My Billing" tabs={tabs}>
      <Container className="pt-5" maxWidth="md">
        {separatedByOr && <HowClientLicensesWorkMessage licenseText={separatedByOr} />}
      </Container>
    </MainLayout>
  )
}
