/**
 * Location: AddressForm.js, RemoteRestrictionsList.js
 * Purpose: Give providers a clearer understanding of what they are selecting..
 *
 */
import React from 'react'

import { Card, CardHeader } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

export default function RadioGroupMessage({ content }) {
  return (
    <Card sx={{ backgroundColor: 'var(--gray-200)' }} elevation={0}>
      <CardHeader
        subheader={content}
        subheaderTypographyProps={{
          sx: { fontWeight: '500 !important' },
          color: 'primary',
        }}
        avatar={<CheckCircleIcon color="primary" />}
      />
    </Card>
  )
}
