// converts  "2024-02-20T15:29:28.481Z" to "Feb 20, 2024 10:29 AM"
// this time object is found from createdAt and updatedAt in DB
// this function is currently used in assessmenttable
export const formatTimeString = (timeString: string): string => {
  if (!timeString) {
    return ''
  }

  // Convert the time string to a Date object
  const date = new Date(timeString)

  // Specify options for toLocaleString
  const options: Intl.DateTimeFormatOptions = {
    month: 'short', // abbreviated month name
    day: '2-digit', // two-digit day
    year: 'numeric', // four-digit year
  }

  return date.toLocaleString('en-US', options)
}
