import React from 'react'
import MaterialAvatar from '@mui/material/Avatar'

const COLORS = [
  '#8bc34a',
  '#00897b',
  '#ff9800',
  '#673ab7',
  '#3f51b5',
  '#e91e63',
  '#f44336',
  '#00bcd4',
  '#cddc39',
]
export default function Avatar({
  firstName: _firstName,
  lastName: _lastName,
  src: _src,
  suspended = false,
  isInvitationMode,
  isArchived = false,
  style = {},
  ...props
}) {
  const firstName = _firstName || ''
  const lastName = _lastName || ''
  const avatarText = `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase()
  const colorIndex = (firstName.charCodeAt(0) + lastName.charCodeAt(0)) % COLORS.length || 0

  // Do not give avatar a color if client is suspended, archived or invitation still not accepted.
  const backgroundColor = suspended || isInvitationMode || isArchived ? '' : COLORS[colorIndex]
  return (
    <>
      {!_src && (
        <MaterialAvatar style={{ ...style, backgroundColor }} {...props}>
          {avatarText}
        </MaterialAvatar>
      )}
      {_src && <MaterialAvatar style={{ ...style, backgroundColor }} {...props} src={_src} />}
    </>
  )
}
