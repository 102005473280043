/**
 * This component is being used in new purchase only
 *
 * TODO: refactor internal and new purchase into one component
 */
import React, { useEffect, useState } from 'react'
import { useForm } from 'hooks'
import {
  Card,
  CardHeader,
  Divider,
  Grid,
  Button,
  TextField,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Typography,
} from 'components'
import makeStyles from '@mui/styles/makeStyles'
import GenerateAddressButton from 'components/testFeatures/GenerateAddressButton'
import { useDispatch, useSelector } from 'react-redux'
import SelectCountry from './SelectCountry'
import SelectState from './SelectState'
import { setShipping, updatePurchase } from 'store/modules/new-purchase'
import useCart from 'views/new-purchase/utils/useCart'
import Layout from 'views/new-purchase/components/Layout'
import formatMoney from 'views/new-purchase/utils/formatMoney'
import useGTM from 'utils/hooks/useGTM'
import { InternationalMessage } from '../constants/InternationalShippingMessage'

const useStyles = makeStyles({
  cardHeader: {
    fontWeight: '500',
    padding: 0,
  },
  formControl: {
    width: '100%',
  },
})

export default ({ next, step, getShipstationQuotes, extendData }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showShippingError, setShowShippingError] = useState(false)
  const [lastUpdated, setLastUpdated] = useState('')
  const { weight, selectedAddOnsArray } = useCart()
  const { addShippingInfo, pageView } = useGTM({})

  // get shipping information from localstorage
  const {
    shipstationRates,
    shipstationError,
    address1,
    address2,
    toPostalCode,
    toState,
    toCountry,
    toCity,
    adminEmail,
    onboardingProducts,
    adminFirstName,
    adminLastName,
    uuid,
    providerProfessionalCredentials,
  } = useSelector((state) => state.newPurchase.data)

  const { form, setFormValue, pending, errors } = useForm({
    data: {
      address1,
      address2,
      toPostalCode,
      toState,
      toCountry,
      toCity,
    },
    validation: {
      address1: {
        required: { msg: 'Address is required.' },
      },
      toPostalCode: {
        required: { msg: 'Postal Code is required.' },
        len: {
          args: [null, 10],
          msg: 'Please enter a valid Zip/Postal code',
        },
      },
      toState: {
        required: { msg: 'State is required.' },
      },
      toCountry: {
        required: { msg: 'Country is required.' },
      },
      toCity: {
        required: { msg: 'City is required.' },
      },
    },
  })

  const [selectedServiceCode, setSelectedServiceCode] = useState(null)
  const [showShipping, setShowShipping] = useState(false)
  const [loadingText, setLoadingText] = useState('')

  const add = async () => {
    extendData({
      address1: form.address1,
      address2: form.address2,
      toPostalCode: form.toPostalCode,
      toState: form.toState,
      toCountry: form.toCountry,
      toCity: form.toCity,
    })
  }

  // always delete saved service code on load & back
  useEffect(() => {
    clearShipping()

    // on load: send virtual page load
    pageView({
      pageUrl: `${window.document.location.origin}${window.document.location.pathname}/shipping`,
      pageTitle: 'New Purchase - Enter shipping address',
    })
    // eslint-disable-next-line
  }, [])

  const onBack = async (e) => {
    e.preventDefault()
    clearShipping()
    await add()
    await next(step - 1)
  }

  // sample shipstation rates
  const onCalculate = async () => {
    setLoadingText('retrieving shipping rates...')
    setLoading(true)
    const products = [
      ...onboardingProducts.map((item) => ({ sku: item, quantity: 1 })),
      ...selectedAddOnsArray,
    ]

    // get total weight from all selected products..
    await getShipstationQuotes({
      toPostalCode: form.toPostalCode,
      toState: form.toState,
      toCountry: form.toCountry,
      toCity: form.toCity,
      weight,
      email: adminEmail,
      firstName: adminFirstName,
      lastName: adminLastName,
      products,
    })
    setShowShipping(true)
    setLoading(false)
  }

  const handleRadio = (e) => {
    setSelectedServiceCode(e.target.value)
    extendData({ selectedServiceCode: e.target.value })
    dispatch(setShipping(e.target.value))
  }

  // having 2 setFormValues break code (even with async/await)
  const onTextChange = (field) => async (e) => {
    setFormValue(field)(e)
    setLastUpdated(field)
    clearShipping()
  }

  const clearShipping = () => {
    setSelectedServiceCode(null)
    extendData({ selectedServiceCode: undefined, totalTax: 0 })
    dispatch(setShipping(''))
    setShowShipping(false)
  }

  // if country has been updated, remove state
  useEffect(() => {
    if (lastUpdated === 'toCountry') {
      setFormValue('toState')('')
    }
    // eslint-disable-next-line
  }, [lastUpdated])

  const handlePurchaseService = async () => {
    setLoadingText('calculating tax...')
    setLoading(true)
    setShowShippingError(false)
    try {
      //
      await add()
      const { error } = await dispatch(
        updatePurchase({
          uuid: uuid,
          shipping_address: {
            address1: form.address1,
            address2: form.address2,
            postal_code: form.toPostalCode,
            state: form.toState,
            country: form.toCountry,
            city: form.toCity,
          },
          professional_credentials: providerProfessionalCredentials,
          shipment_method: selectedServiceCode,
          items: [
            ...selectedAddOnsArray.map(({ sku, quantity }) => ({
              sku: sku,
              quantity: quantity,
            })),
            ...onboardingProducts.map((item) => ({
              sku: item,
              quantity: 1,
            })),
          ],
        })
      )

      // add shipping item to GTM
      await addShippingInfo({ shipping_tier: selectedServiceCode })

      if (!error) {
        await next()
      } else {
        setShowShippingError(true)
      }
    } catch (error) {
      console.log(error)
      setShowShippingError(true)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Layout loading={loading} loadingText={loadingText} next={next}>
      <Card elevation={0}>
        <CardHeader
          data-test="card-header"
          title={`${step + 1}. Please enter your shipping address`}
          classes={{ title: classes.cardHeader }}
        />
        <Divider />
        <CardContent>
          <TextField
            autoFocus={true}
            className="mb-5"
            label="Address 1*"
            value={form.address1}
            onChange={onTextChange('address1')}
            error={errors.address1}
            disabled={loading}
            name="address-1"
          />
          <TextField
            className="mb-5"
            label="Address 2"
            value={form.address2}
            onChange={onTextChange('address2')}
            disabled={loading}
            name="address-2"
          />
          <TextField
            className="mb-5"
            label="City*"
            value={form.toCity}
            error={errors.toCity}
            onChange={onTextChange('toCity')}
            disabled={loading}
            name="city"
          />
          <SelectCountry
            value={form.toCountry}
            onChange={onTextChange('toCountry')}
            disabled={loading}
            error={errors.toCountry}
            excludeNonShippableCountries
          />
          <SelectState
            disabled={loading}
            country={form.toCountry}
            onChange={onTextChange('toState')}
            value={form.toState}
            error={errors.toState}
          />
          <TextField
            className="mt-5"
            label="Zip/Postal Code*"
            value={form.toPostalCode}
            onChange={onTextChange('toPostalCode')}
            disabled={loading}
            error={errors.toPostalCode}
            name="to-postal-code"
          />
        </CardContent>

        {showShipping && !shipstationError && (
          <>
            <CardHeader
              title="Select your shipping option below"
              classes={{ title: classes.cardHeader }}
            />
            {form.toCountry !== 'US' && <InternationalMessage />}
            <Divider />
            <FormControl component="fieldset" className="px-4" data-test="shipping-option">
              <RadioGroup
                aria-label="Shipping Option"
                name="Shipping Option"
                value={selectedServiceCode}
                onChange={handleRadio}
              >
                {shipstationRates &&
                  shipstationRates.map(
                    ({ serviceCode, shipmentCost, serviceName, otherCost }, index) => (
                      <FormControlLabel
                        data-test={`shipping-option-${index}`}
                        key={`shippingRate${index}`}
                        disabled={loading}
                        value={serviceCode}
                        control={<Radio />}
                        label={`${serviceName} - ${formatMoney(shipmentCost + otherCost)}`}
                      />
                    )
                  )}
              </RadioGroup>
            </FormControl>
          </>
        )}

        {((showShipping && shipstationError) || showShippingError) && (
          <FormHelperText error>
            Sorry but we're unable to calculate shipping costs for the address you entered. Please
            edit the address and try again.
          </FormHelperText>
        )}

        <Grid className="mt-5" container justifyContent="space-between">
          <Button
            color="primary"
            onClick={onBack}
            disabled={loading}
            data-test="back-from-shipping-button"
          >
            Back
          </Button>
          <Button
            variant="contained"
            onClick={onCalculate}
            disabled={
              !form.address1 ||
              !form.toPostalCode ||
              !form.toState ||
              !form.toCountry ||
              !form.toCity ||
              loading
            }
            color={selectedServiceCode ? 'inherit' : 'primary'}
            data-test="calculate-shipping"
          >
            Calculate Shipping
          </Button>
          <Button
            variant="contained"
            color="primary"
            loading={pending}
            disabled={!selectedServiceCode || loading}
            onClick={handlePurchaseService}
            data-test="confirm-shipping-address"
          >
            Continue
          </Button>
        </Grid>
        <Grid className="mt-5" container justifyContent="space-between">
          <Typography>
            <GenerateAddressButton
              setFormValue={setFormValue}
              addressType="Colorado"
              clearShipping={clearShipping}
            />
          </Typography>
          <Typography>
            <GenerateAddressButton
              setFormValue={setFormValue}
              addressType="Canada"
              clearShipping={clearShipping}
            />
          </Typography>
          <Typography>
            <GenerateAddressButton
              setFormValue={setFormValue}
              addressType="Massachussets"
              clearShipping={clearShipping}
            />
          </Typography>
        </Grid>
      </Card>
    </Layout>
  )
}
