import React from 'react'
import { SvgIcon } from 'components'

export default () => (
  <SvgIcon viewBox="0 0 250.97 208.69" className="m-auto">
    <path
      className="text-green"
      d="M135.91,208.69A126.46,126.46,0,0,0,251,98.24,126.46,126.46,0,0,0,135.91,208.69Z"
    />
    <path
      className="text-green"
      d="M94.67,82.68a125.94,125.94,0,0,0,30.82,82.68A126.33,126.33,0,0,0,125.49,0,125.94,125.94,0,0,0,94.67,82.68Z"
    />
    <path
      className="text-green"
      d="M115.06,208.69A126.46,126.46,0,0,0,0,98.24,126.46,126.46,0,0,0,115.06,208.69Z"
    />
  </SvgIcon>
)
